import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="4" fill="white" />
    <path
      d="M20.6667 11.5H13.1667C12.2462 11.5 11.5 12.2462 11.5 13.1667V20.6667C11.5 21.5871 12.2462 22.3333 13.1667 22.3333H20.6667C21.5871 22.3333 22.3333 21.5871 22.3333 20.6667V13.1667C22.3333 12.2462 21.5871 11.5 20.6667 11.5Z"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.16602 16.5003H7.33268C6.89065 16.5003 6.46673 16.3247 6.15417 16.0122C5.84161 15.6996 5.66602 15.2757 5.66602 14.8337V7.33366C5.66602 6.89163 5.84161 6.46771 6.15417 6.15515C6.46673 5.84259 6.89065 5.66699 7.33268 5.66699H14.8327C15.2747 5.66699 15.6986 5.84259 16.0112 6.15515C16.3238 6.46771 16.4993 6.89163 16.4993 7.33366V8.16699"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgArrowUp;
