import { styled } from "@mui/material";

export const Container = styled('div')(({ theme }) => ({
    position: 'relative',
    boxShadow: `inset 0px -1px 0px ${theme.customText.icon}`,
    backgroundColor: theme.palette.common.white
}));

export const FlexBox = styled('div')({
    display: 'flex',
    width: '70%',
    padding: '8px 56px',
    flexWrap: 'wrap',
});

export const TitleContainer = styled('div')({
    flex: '0 0 100%',
    paddingTop: 8
});

export const InfoContainer = styled('div')({
    flex: '0 0 50%',
    display: 'inline-flex',
    padding: '8px 0',
    alignItems: 'flex-start'
});

export const InfoTitle = styled('div')(({ theme }) => ({
    flex: '0 0 92px',
    color: theme.palette.text.disabled
}));

export const InfoValue = styled('div')({
    paddingLeft: 8
});

export const BoxBreak = styled('div')({
    flex: '0 0 100%'
});