import styled from "styled-components";

interface Props {
  disabled?: boolean;
  scrollingTable?: boolean;
  verticalScroll?: boolean;
}

export const ComponentWrapper = styled.div({
  display: "block",
  marginTop: "12px",
});

export const ComponentContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "5px",
});

export const ActionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 1,
});


export const TableButton = styled.div<Props>((props) => ({
  border: "none",
  color: "#353C68",
  backgroundColor: props.disabled ? "#F0F2F5" : "#F0F2F5",
  padding: "4px 8px 4px 8px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  width: "90px",
  margin: "24px",
  lineHeight: "16px",
  borderRadius: "4px",
  gap: "10px",
  cursor: props.disabled ? "arrow" : "pointer",
  pointerEvents: props.disabled ? "none" : "auto",
  "& > p": {
    color: props.disabled ? "#B3BFCC" : "#353C68",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
}));

export const TableWrapper = styled.div<Props>((props) => ({
  display: 'flex',
  overflowX: props.scrollingTable ? "scroll" : "hidden",
  overflowY: props.verticalScroll ? "scroll" : "hidden",
  whiteSpace: "nowrap",
  maxWidth: "636px",
  maxHeight: "275px"
}));

export const ContentWrapper = styled.div<Props>((props) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const DescriptionWrapper = styled.div<Props>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: "12px"
}));

export const tableHeaderStyle: any = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F0F2F5",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "10px",
  letterSpacing: "1.5px",
  color: "#B3BFCC"
};

export const tableRowStyle: any = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "10px",
  letterSpacing: "1.5px",
  color: "#353C68"
};

export const tableRowStyleDisabled: any = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "10px",
  letterSpacing: "1.5px",
  color: "#353C68",
  background: '#FAFAFA'
};


