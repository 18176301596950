export const LOADING_FOLDER = 'LOADING_FOLDER';
export const FAILED_FOLDER = 'FAILED_FOLDER';
export const GET_DATA_FOLDER = 'GET_DATA_FOLDER';

export interface LoadingActionI {
  type: typeof LOADING_FOLDER;
  isLoading?: boolean;
}

export interface FailedActionI {
  type: typeof FAILED_FOLDER;
  message?: string;
  isLoading?: boolean;
}

export interface GetDataFolderI {
  type: typeof GET_DATA_FOLDER;
  data: Record<string, unknown>[];
}