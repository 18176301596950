import * as React from "react";
import { SVGProps } from "react";

const SvgRadioOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={12} fill="#118CFF" />
    <circle cx={12} cy={12} r={4} fill="#fff" />
  </svg>
);

export default SvgRadioOn;
