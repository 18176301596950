//TODO: determine which function could be here
import { Menu } from '@mui/material';
import { MenuBox } from '../../../pageElements/composer';
import { contentImage } from '../../../utils/constant';
import { capitalizeFirstLetter } from '../../../utils/helpersFunction';
import { IImageMenu } from './image-menu.type';

export const ImageMenu = ({
  open,
  handlerShowImageComponent,
  anchorPosition,
  handlerComponentImage
}: IImageMenu) => (
  <Menu
    open={open}
    onClose={() => handlerShowImageComponent('close')}
    anchorEl={anchorPosition}
    MenuListProps={{
      disablePadding: true
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
        mt: 0.5
      }
    }}
  >
    <MenuBox>
      {contentImage?.map((value: any, index: number) => (
        <div
          key={`image_menu_box_${index}`}
          onClick={(e: React.SyntheticEvent) => {
            handlerComponentImage(
              'SELECT_ALIGNMENT',
              { imageAlignment: value },
              e
            );
          }}
          data-testid={capitalizeFirstLetter(value)}
        >
          {capitalizeFirstLetter(value)}
        </div>
      ))}
    </MenuBox>
  </Menu>
);
