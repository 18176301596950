import styled from 'styled-components';

export const NavMenu = styled.ul({
  display: 'flex',
  padding: 0,
  margin: 0,
  listStyle: 'none',
  li: {
    display: 'block',
    padding: '0.3rem',
    color: '##292A31',
    fontSize: '12px',
    cursor: 'pointer',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
});

export const MenuWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  p: {
    fontSize: 12,
    letterSpacing: 0.4,
    fontFamily: 'Inter',
    margin: 0,
  },
});

export const NestedMenu = styled.div({
  width: 160,
});
