import styled from "styled-components";
import bgImage from "../assets/login/bg-login.png";
import backgeroundImage from "../assets/login/background-login.png";
interface Props {
  color?: string;
  fontColor?: string;
  border?: string;
  margin?: string;
  isMobile?: boolean;
}

interface ContainerProps {
  width: number;
  isMobile?: boolean;
}

export const Container = styled.div<Props>((props) => ({
  display: "flex",
  minHeight: props.isMobile ? "" : "100vh",
}));

export const HeaderTitle = styled.h5<Props>((props) => ({
  fontWeight: 700,
  fontSize: "1.5rem",
  lineHeight: "2.25rem",
  margin: props.margin,
}));

export const LeftSection = styled.div<ContainerProps>(({width, isMobile}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: isMobile ? '100%' : width,
}));

export const StaticSection = styled.div<ContainerProps>(({width}) => ({
  // flex: "0 0 48.3rem",
  backgroundImage: `url(${backgeroundImage})`,
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  padding: `1.625rem ${width * .15}px 0 8.875rem`,
  boxSizing: "border-box",
  webKitBoxSizing: "border-box",
  width: width * .66,
  "& > div:nth-child(1) ": {
    display: "flex",
    height: "2rem",
    alignItems: "center",
    gap: 13,
    "& > h5": {
      fontFamily: "Quicksand",
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  "& > div:nth-child(2)": {
    marginTop: "8rem",
    "& > h3": {
      margin: 0,
      fontSize: "3rem",
      lineHeight: "3.5rem",
      letterSpacing: "0.5px",
      color: "#FFFFFF",
    },
    "& > p": {
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "2rem",
      letterSpacing: "0.15px",
      color: "#FFFFFF",
      paddingBlockStart: 24,
    },
  },
}));

export const FormSection = styled.div({
  flex: 1,
  // display: "flex",
  width: 350,
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid rgba(255,255,255,.5)",
  "& > div": {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 20rem",
    "& > p": {
      lineHeight: "1.5rem",
      letterSpacing: "0.44px",
      margin: 0,
    },
    "& > p:nth-child(4)": {
      marginTop: "1.25rem",
    },
    "& > h5:nth-child(6)": {
      color: "#118CFF",
      cursor: "pointer",
      marginBottom: "2.25rem",
      fontWeight: 500,
    },
  },
});

export const ButtonWrapper = styled.div({
  display: "flex",
  gap: "1rem",
});

export const WrapperTextClickable = styled.div({
  '& > p': {
    display: 'inline'
  },
  '& > .clickable': {
    color: '#118CFF',
    cursor: 'pointer'
  },
});

export const WrapperCheckbox = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '1.5rem',
  '& > .MuiFormControlLabel-root': {
    marginRight: 0,
    display: 'inline'
  },
  '.MuiCheckbox-root': {
    paddingTop: '0'
  }
});
