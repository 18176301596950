import styled from 'styled-components';

export const TableContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
  flexDirection: 'column',
  marginTop: '1.5rem',
});

export const styledTable = {
  minWidth: 650,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
};

export const styledHeaderTable = {
  height: 48,
  '& .MuiTableCell-root:nth-child(1)': {
    paddingLeft: '32px',
    width: 260
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingRight: '60px'
  },
  '& .MuiTableCell-root': {
    padding: '8px 72px 8px 16px',
    borderBottom: '1px solid #E0E6EB',
  },
};

export const styledCellTable = {
  '& .MuiTableCell-root:nth-child(1)': {
    width: "60px",
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    paddingRight: '84px'
  },
  backgroundColor: '#E3F3FF',
};

export const cellStyle = {
  '& .MuiTableCell-root:nth-child(1)': {
    width: "60px"
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    paddingRight: '84px'
  },
  borderBottom: '1px solid #E0E6EB',
};

export const styledHeaderTableDrive = {
  height: 48,
  '& .MuiTableCell-root:nth-child(1)': {
    paddingLeft: '32px',
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingRight: '84px'
  },
  '& .MuiTableCell-root': {
    padding: '8px 0 8px 16px',
    borderBottom: '1px solid #E0E6EB',
  },
};

export const styledCellTableDrive = {
  '& .MuiTableCell-root:nth-child(1)': {
    width: "60px"
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    paddingRight: '84px'
  },
  backgroundColor: '#E3F3FF',
};

export const cellStyleDrive = {
  '& .MuiTableCell-root:nth-child(1)': {
    width: "60px"
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    paddingRight: '84px'
  },
  borderBottom: '1px solid #E0E6EB',
};
