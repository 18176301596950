import { Dispatch } from "redux";

import Service from "../../../service/generic/generic";
import { CalculationInfoI } from "./reducer";
import * as actions from "./type";

export const getBreadcrumb = (folderId: string, type: string, calculationInfo?: CalculationInfoI) => {
  const folderService = new Service(`folders/${folderId}/breadcrumbs`);
  return async (dispatch: Dispatch) => {
    dispatch(actions.GetBreadcrumb());

    const response = await folderService.get();
    const calculation = calculationInfo ?? null;
    const payload = { breadcrumb: response.data, type, calculation };
    dispatch(actions.SetBreadcrumb(payload));
  };
};
