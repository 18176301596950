import { useTheme } from '@mui/material';
import SvgEdit from '../../../../assets/icons/Edit';
import Text from '../../../atoms/Text';
import {
  HeadlineTitle,
  HeadlineWrapper,
  InfoPaneItemlWrapper,
} from '../elements';

interface PropsI {
  title: string;
  name: string;
  description: string;
  nameTitle?: any;
  onEdit: () => void;
  isEditable?: boolean;
  dataTestid?: string;
}
const InfoPanelHeadline = ({
  title,
  name,
  description,
  onEdit,
  isEditable,
  nameTitle,
  dataTestid = 'btnEditCalculationInfoIcon',
}: PropsI) => {
  const theme = useTheme();
  return (
    <InfoPaneItemlWrapper>
      <HeadlineWrapper>
        <HeadlineTitle>{title}</HeadlineTitle>
        {isEditable && <SvgEdit data-testid={dataTestid} onClick={onEdit} />}
      </HeadlineWrapper>
      {nameTitle?.title ? (
        <>
          <Text variant="body3" color={theme.palette.text.disabled}>
            {nameTitle.title}
          </Text>
          <Text variant="h5" sx={{ marginTop: '4px' }}>
            {nameTitle.nameInfoTitle}
          </Text>
        </>
      ) : (
        <>
          <Text variant="h5">{name}</Text>
          <Text
            variant="body3"
            sx={{ marginTop: '4px' }}
            color={theme.palette.text.disabled}
          >
            {description}
          </Text>
        </>
      )}
    </InfoPaneItemlWrapper>
  );
};

export default InfoPanelHeadline;
