import formula from '@jspreadsheet/formula-pro';

import jspreadsheet from 'jspreadsheet';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

import routes from '../src/routes';
import login from './pages/login';
import PrivateRoute from '../src/auth/privateRoute';
import Layout from './components/organisms/layouts';
import FormModal from './components/molecules/popUp/modalForm';
import Toast from './components/molecules/popUp/toast';
import HelperInfo from './components/molecules/popUp/helperInfo';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/ErrorHandler';
import ErrorFallback from './components/ErrorFallback';
import { JSPREADSHEET_LICENSE } from './utils/constant';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorTemplate from './pages/error/ErrorTemplate';

const queryClient = new QueryClient();

const ERROR_MESSAGE = `We couldn't find what you are looking for.`
const ERROR_STATUS = '404'

export const App = () => {
  jspreadsheet.setLicense(JSPREADSHEET_LICENSE);
  jspreadsheet.setExtensions({ formula });

  const actions = [
    {
      color: 'primary',
      variant: 'contained',
      label: 'Back to Home',
      onClick: () => window.location.href = '/'
    }
  ];

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route component={login} exact path="/" />
          <Route path="/register" exact>
            <ErrorTemplate message={ERROR_MESSAGE} status={ERROR_STATUS} actions={actions} />
          </Route>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ErrorHandler>
              <Layout>
                {routes.map((route, index) => (
                  <PrivateRoute
                    component={route.component}
                    exact
                    path={route.path}
                    key={index}
                  />
                ))}
              </Layout>
            </ErrorHandler>
          </ErrorBoundary>
        </Switch>
        <>
          <FormModal />
          <Toast />
          <HelperInfo />
        </>
      </Router>
    </QueryClientProvider>
  );
};
