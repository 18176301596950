import { Dispatch } from "redux";
import CalculationService from "../../service/CalculationServices";

import Service from "../../service/generic/generic";
import InputFileService from "../../service/InputFileService";
import * as actions from "../types/inputFileTypes";

export const fetchInputFileList = (calculationId: string) => {
  const calculationService = new Service('calculation');
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchInputFileByCalculationId());
    try {
      const data = await calculationService.get(`${calculationId}/input-files`);
      dispatch(actions.fetchInputFileByCalculationIdSuccess(data.data.items));
      return Promise.resolve(data.data.items);
    } catch (error: any) {
      dispatch(actions.fetchInputFileByCalculationIdFailed(error));
      return Promise.resolve(error);
    }
  };
};

export const fetchCalculationInputFileList = (calculationId: string) => {
  const calculationService = new Service('calculation');
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchCalculationInfoAndInputFileList());
    try {
      const data = await calculationService.get(`${calculationId}/input-files`);
      dispatch(actions.fetchCalculationInfoAndInputFileListSuccess(data.data));
      return Promise.resolve(data);
    } catch (error: any) {
      dispatch(actions.fetchCalculationInfoAndInputFileListFailed(error));
      return Promise.resolve(error);
    }
  };
};

export const fetchCalculation = (calculationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchCalculation());
    try {
      const response = await CalculationService.getCalculationById(calculationId);
      if (response.message === "Success") {
        dispatch(actions.fetchCalculationSuccess(response.data));
        return Promise.resolve(response.data);
      }
    } catch (error: any) {
      dispatch(actions.fetchCalculationFailed(error));
      return Promise.reject(error);
    }
  };
};

export function inputFileInfoRename(id: string, name: string) {
  return async () => {
    await InputFileService.rename(id, name).then(() => {
      return "Success Rename";
    });
  };
}

export const inputFileInfo = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setInputFile(data));
  };
};

export function inputFileInfoDelete(id: string) {
  return async () => {
    await InputFileService.softDelete(id);
    return "Success Delete";
  };
}

export function getInputFileById(inputfileId: any) {
  return async (dispatch: Dispatch) => {
    try {
      const data = await InputFileService.getById(inputfileId);
      dispatch(actions.setInputFileById(data));
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}

export function getReportByCalcInfoId(calculationInfoId: any) {
  const serviceReport = new Service("report");
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await serviceReport.getResource(calculationInfoId);

      dispatch(actions.setReportById(data));
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}

export const getLastUpdatedInputFile = (calculationId: string) => {
  return async () => {
    try {
      const data = await InputFileService.lastUpdated(calculationId);
      return Promise.resolve([data]);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const getCheckValueChange = (inputFileId: string, data: any) => {
  return async () => {
    try {
      const response = await InputFileService.checkValueChange(inputFileId, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};
