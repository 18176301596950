import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import styled from "styled-components";

interface Props {
  imageLeft?: string;
  imageRight?: string;
  justify?: string;
  backgroundColorBar?: string;
  valueBar?: string;
  error?: any;
}

export const Container = styled.div({
  padding: "24px 0",
  display: "flex",
  backgroundColor: "#F0F0F1",
  alignItems: "flex-start",
  gap: "10px",
  justifyContent: "center",
  position: "absolute",
  width: "calc(100vw - 18px)",
});

export const MainContent = styled.div({
  flexGrow: 1,
  display: "flex",
});

export const FlexWrapper = styled.div({
  display: "flex",
  flexDirection: 'column',
  minHeight: "21cm"
});

export const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  minWidth: "600px",
  backgroundColor: "#fff",
  minHeight: "100vh",
  alignItems: "center",
  "& .react-pdf__Document": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const HeaderMainContent = styled.div({
  padding: "0 1.5rem",
  display: "flex",
  alignItems: "center",
  position: "relative",
  boxShadow: "none",
  marginBottom: "12px",

  "& > div": {
    display: "flex",
    gap: "0.5rem",
    width: "100%",
    overflow: "hidden",
  },
  "& > .arrowIcon": {
    position: "absolute",
    cursor: "pointer",
    ":active": {
      transform: "scale(0.8)",
    },
  },
  "& > .left": {
    left: 0,
  },
  "& > .right": {
    right: 0,
  },
  "& > div > button": {
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 700,
    minWidth: "105px",
    letterSpacing: "0.4px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    height: "32px",
    padding: "8px",
  },
});

export const BodyMainContent = styled.div({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
});

export const HeadAtBodyMainContent = styled.div({
  borderBottom: "1px solid #E0E6EB",
  height: "108px",
});

export const HeadAtBodyMainContentWrapper = styled.div({
  padding: "16px",
  display: "flex",
  flexDirection: "row",
});

export const HeadAtBodyMainContentLeft = styled.div<Props>((props) => ({
  gap: "8px",
  display: "flex",
  flexDirection: "row",
  marginRight: "16px",
  paddingTop: "6px",
  paddingBottom: "6px",
  "& > #divImgLeft": {
    width: "64px",
    height: "64px",
    backgroundImage: `url("${props.imageLeft}")`,
    cursor: "pointer",
    borderRadius: "100%",
  },
  "& > #divImgRight": {
    width: "64px",
    height: "64px",
    backgroundImage: `url("${props.imageRight}")`,
    cursor: "pointer",
    borderRadius: "100%",
  },
}));

export const HeadAtBodyMainContentCenter = styled.div({
  width: "420px",
  "& > #topMainContentCenter": {
    fontFamily: "Inter",
    fontSize: "18px",
    letterSpacing: "0.44px",
    marginBottom: "12px",
  },
  "& > #botMainContentCenter": {
    color: "#9A9DB3",
    fontFamily: "Inter",
    fontSize: "10px",
    letterSpacing: "0.4px",
    "& > #botMainContentCenterWrapperTop": {
      display: "flex",
      flexDirection: "row",
      marginBottom: "8px",
      "& > div": {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        "& > .titleHeadBody": {
          width: "70px",
        },
        "& > .dotHeadBody": {
          marginLeft: "4px",
          marginRight: "4px",
        },
      },
    },
    "& > #botMainContentCenterWrapperBot": {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        "& > .titleHeadBody": {
          width: "70px",
        },
        "& > .dotHeadBody": {
          marginLeft: "4px",
          marginRight: "4px",
        },
      },
    },
  },
});

export const HeadAtBodyMainContentRight = styled.div({
  // empty div :)
});

export const SideBar = styled.div({
  width: "236px",
  backgroundColor: "#FFFFFF",
  padding: "24px",
  maxheight: "289px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0)",
  borderTopRightRadius: "4px",
  marginBottom: "8px",

  "& > p": {
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "15px",
    // textTransform: "uppercase",
    color: "#A3B3C2",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  "& > h1": {
    fontSize: "15px",
    // letterSpacing: "0.75px",
    lineHeight: "16.95px",
    textTransform: "capitalize",
    fontWeight: 700,
  },
  "& > h2": {
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
  },
  "& > h4": {
    paddingBottom: "24px",
    paddingTop: "4px",
    fontWeight: 700,
    lineHeight: "20px",
    fontSize: "14px",
  },

  "& > h3": {
    color: "#9A9DB3",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    margin: "4px 0px",
    paddingTop: "24px",
  },
});

export const ButtonSideBar = styled.button({
  border: "none",
  backgroundColor: "#F0F2F5",
  padding: "8.5px 8px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "14px",
  fontWeight: 700,
  width: "188px",
  margin: "24px",
  lineHeight: "14.52px",
  borderRadius: 5,
});

export const ButtonSideBar2 = styled.div({
  border: "none",
  color: "gray",
  backgroundColor: "#F0F2F5",
  padding: "8.5px 8px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "14px",
  fontWeight: 700,
  width: "188px",
  lineHeight: "14.52px",
  margin: "24px 0",
  borderRadius: 5,
});

export const SideBar2 = styled.div({
  width: "236px",
  backgroundColor: "#FFFFFF",
  padding: "24px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0)",
  borderTopRightRadius: "4px",

  "& > p": {
    color: "#9A9DB3",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    Bottom: "24px",
  },
  "& > h1": {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    paddingTop: "24px",
  },
  "& > h2": {
    color: "#118CFF",
    fontWeight: 700,
    height: "17px",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    margin: "8px 0",
  },
});

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: 4,
  padding: "2px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E3F3FF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#1a90ff",
  },
}));

export const BodyAtBodyMainContent = styled.div({
  display: "flex",
  flex: 1,
});

export const EmptyBody = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  pointerEvents: "none",
  userSelect: "none",
  flex: 1,
});

export const BodyAtBodyMainContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "11px",
  border: "1px dashed white",
  borderRadius: "4px",
  flex: 1,
  margin: "0px 20px",
});

export const formText = styled.div({
  maxWidth: "500px",
  margin: "0 auto",
});

export const LabelLogger = styled.label({
  padding: "5px",
  margin: "10px",
  maxWidth: "296px",
});

export const formLogger = styled.form({
  padding: "5px",
  margin: "10px",
  width: "160px",
});

export const MainLoggerDiv = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "5px",
});

export const RightMainTextLogger = styled.div({
  "& > p": {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "16px",
  },
});

export const LeftMainTextLogger = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& > p": {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "16px",
  },
});

export const InputLeftMainTextLogger = styled.input`
  padding: 5px;
  margin: 5px;
  width: 160px;
  font-size: 12px;
  border: 1px solid #e0e6eb;
  height: 25px;
  border-radius: 5px;

  &.danger {
    border: 1px solid red !important;
  }

  &.warning {
    border: 1px solid orange !important;
  }
`;

export const RadioButtonLogger = styled.div({
  paddingTop: "5px",
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
  fontSize: "12px",
  fontWeight: "normal",
});

export const OutRadioButton = styled.div({
  paddingTop: "10px",
  marginTop: "10px",
});

export const SidebarWrapper = styled.div({});

export const StickyWrapper = styled.div({
  flexDirection: 'column',
  position: 'fixed'
});

export const TextSize = styled.h1<Props>((props) => ({
  fontWeight: "bold",
  fontSize: "28px",
}));

export const TextHeading1 = styled.h1<Props>((props) => ({
  fontWeight: "bold",
  fontSize: "24px",
}));

export const TextHeading2 = styled.h1<Props>((props) => ({
  fontWeight: "bold",
  fontSize: "18px",
}));

export const TextHeading3 = styled.h1<Props>((props) => ({
  fontWeight: "bold",
  fontSize: "14px",
}));

export const TextNormal = styled.h1({
  fontSize: "12px",
  fontWeight: "normal",
});

export const TextLabel = styled.label({
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "16px",
});

export const ContainerFormStyled = styled.form({
  flexDirection: "column",
  display: "flex",
  flex: 1,
  select: {
    flex: 1,
  },
  input: {
    backgroundColor: "#FFFFFF",
    flex: "1",
    display: "flex",
  },
});

export const BarProgress = styled.div({
  display: "flex",
});

export const PerBarProgress = styled.div<Props>((props) => ({
  backgroundColor: props.backgroundColorBar,
  width: props.valueBar + "%",
  float: "left",
  height: "10px",
  "&:first-child": {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  "&:last-child": {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));
export const Notation = styled.p({
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "16px",
});

export const SidePanelButtonContainer = styled.div({
  padding: ".825rem 0 0",
});

export const ProgressWrapper = styled.div({
  gap: "2px",
  display: "flex",
  flexDirection: "row",
  paddingTop: "6px",
  paddingBottom: "6px",
});

export const InputText = styled.input<Props>((props) => ({
  height: "36px",
  border: props.error ? "1px solid #DE294C" : "1px solid #E0E6EB",
  borderRadius: "4px",
  marginBottom: props.error ? "0px" : "16px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  paddingLeft: "10px",
  paddingRight: "10px",
  letterSpacing: "0.4px",
  fontSize: "12px",
  "&:focus": {
    outline: "none",
    border: props.error ? "1px solid #DE294C" : "1px solid #118CFF",
  },
  "&:disabled": {
    backgroundColor: "#F0F2F5",
  },
  "&::placeholder": {
    color: "#B3BFCC",
    letterSpacing: "0.4px",
  },
}));

export const SideBarWrapper = styled.div({
  width: "236px",
  backgroundColor: "#FFFFFF",
  maxheight: "289px",
  borderTopRightRadius: "4px",
  marginBottom: "6px",

  "& > p": {
    color: "#9A9DB3",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  "& > h1": {
    fontSize: "10px",
    color: "#A3B3C2",
    lineHeight: "15px",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  "& > h4": {
    paddingBottom: "24px",
    paddingTop: "4px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  "& > h3": {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "18px",
    paddingTop: "24px",
    paddingBottom: "4px",
    color: "#9A9DB3",
    fontFamily: "Inter",
  },
  "& > span": {
    color: "red",
  },
});

export const ParentCheckbox = styled.div({
  marginTop: "-6px",
  display: "flex",
  flexDirection: "row",
  "& > p": {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    alignSelf: "center",
  },
});

export const ChildCheckbox = styled.div({
  marginTop: "-6px",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "18px",
  "& > p": {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    alignSelf: "center",
  },
});

export const CheckboxSection = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingTop: "6px",
  paddingBottom: "12px",
});

export const ActionsContent = styled.div({
  marginTop: "6px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  h1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0/75px",
    textTransform: "uppercase",
    width: "100%",
    marginBottom: "19.5px",
  },
  button: {
    width: "100%",
    marginBottom: "4px",
    marginTop: "4px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#118CFF",
      color: "#FFFFFF",
    },
  },
  "& div: nth-child(1)": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "> div:first-child": {
    cursor: "pointer",
  },
});

export const WrapperPageView = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "684px",
});

export const PageView = styled.div({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  minHeight: "250px",
  padding: "12px",
});

export const GeneralComponentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const ComponentWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
});

export const ImageDiv = styled.div<Props>((props) => ({
  display: "flex",
  padding: ".5rem 0",
  justifyContent:
    props.justify === "CENTER"
      ? "center"
      : props.justify === "RIGHT"
        ? "flex-end"
        : props.justify === "LEFT" || "JUSTIFY"
          ? "flex-start"
          : "center",
}));

export const ImageDesc = styled.div<Props>((props) => ({
  display: "flex",
  justifyContent: props.justify || "center",
  alignItems: "center",
  fontSize: "12px",
  lineHeight: "16px",
}));

export const SideBarTop = styled.div({
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  marginBottom: "25px",
  justifyContent: "start",

  // "& > img": {
  //   width: "20px",
  //   height: "20px",
  //   marginRight: "8px",
  //   cursor: "pointer",
  // },

  // "& > h2": {
  //   fontSize: "12px",
  //   lineHeight: "18px",
  //   fontWeight: 700,
  // },
});

//for subscript and superscript style
export const stylesheetSubSup = {
  "*": {
    fontFamily: "SourceCodePro",
    padding: 0,
    margin: 0,
  },
  body: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: 12,
    color: "#29333D",
  },
  div: {
    position: "relative",
    fontSize: 12,
    flexDirection: "row",
    // marginLeft: 7
  },
  p: {
    fontSize: "12px",
    fontFamily: "SourceCodePro",
    // position: "relative",
  },
  sup: {
    // marginTop: 1,
    fontSize: 6,
    // lineHeight: 1,
  },
  sub: {
    fontSize: 6,
    top: -3,
    left: -8,
    position: "absolute",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    // justifyContent: "space-between",
    paddingVertical: 2,

  },
  smallComp: {
    flexBasis: 64,
  },
  mediumComp: {
    flexBasis: 88,
  },
};


export const stylesheetSubSupImage = {
  "*": {
    // fontFamily: "Inconsolata",
    padding: 0,
    margin: 0,
  },
  // body: {
  //   fontFamily: "Inter",
  //   fontWeight: "normal",
  //   fontSize: 12,
  //   color: "#29333D",
  // },
  div: {
    position: "relative",
    fontSize: 12,
    flexDirection: "row",
    // marginLeft: 7
  },
  p: {
    fontSize: "12px",
    // fontFamily: "Inconsolata",
    // position: "relative",
  },
  sup: {
    // marginTop: 1,
    fontSize: 6,
    // lineHeight: 1,
  },
  sub: {
    fontSize: 6,
    top: -3,
    left: -8,
    position: "absolute",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    // justifyContent: "space-between",
    paddingVertical: 2,

  },
  smallComp: {
    flexBasis: 64,
  },
  mediumComp: {
    flexBasis: 88,
  },
};

export const stylesheetSubSupFont = {
  "*": {
    padding: 0,
    margin: 0,
  },
  body: {
    fontFamily: "Inter",
    color: "#29333D",
  },
  sup: {
    fontSize: 6,
  },
  sub: {
    fontSize: 6,
    top: -3,
    left: -8,
    position: "absolute",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  smallComp: {
    flexBasis: 64,
  },
  mediumComp: {
    flexBasis: 88,
  },
};

export const stylesheetSubSupFontNormal = {
  p: {
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: 1.5,
    paddingVertical: 4
  },
};

export const stylesheetSubSupFontHeading_1 = {
  p: {
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 2,
    paddingTop: 12,
  },
};

export const stylesheetSubSupFontHeading_2 = {
  p: {
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 1.75,
    paddingTop: 12,
  },
};

export const stylesheetSubSupFontHeading_3 = {
  p: {
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 1.75,
    paddingTop: 12,
  },
};

export const PrintArea = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  transform: 'translateY(-200%)',
  textAlign: 'center'
});