import * as userType from "../types/userTypes";

export interface UserLimitI {
  calculations: number;
  currentCalculationFilePerDrive: number;
  currentDriveMember: number;
  currentTeamDrive: number;
  driveMemberLimit: number;
  limit: null | number;
  ownedCalculationLimitPerDrive: number;
  teamDriveLimit: number;
}

export interface UserFileState {
  errorMessage: string;
  isLoading: boolean;
  dataUsers: Array<any>;
  isActiveUser: boolean;
  companies: Array<any>;
  industries: Array<any>;
  countries: Array<any>;
  profession: Array<any>;
  activeItem: Record<string, unknown>;
  userProfile: Array<any>;
  userLimit: Record<string, unknown>;
  userSoftwareLicense: Record<string, unknown>
  isHaveDrive: boolean;
  driveRole: string;
  changePlan: boolean;
  billingDetails: Array<any>;
  paymentStatus: any;
  billingMember: Array<any>;
  isHaveManageDrive: boolean;
}

const initialState: UserFileState = {
  errorMessage: "",
  isLoading: false,
  dataUsers: [],
  isActiveUser: false,
  companies: [],
  activeItem: {},
  industries: [],
  countries: [],
  profession: [],
  userProfile: [],
  userLimit: {},
  userSoftwareLicense: {},
  isHaveDrive: false,
  driveRole: '',
  changePlan: false,
  billingDetails: [],
  paymentStatus: {},
  billingMember: [],
  isHaveManageDrive: false,
};

export default function userReducer(
  state: UserFileState = initialState,
  action: any
) {
  switch (action.type) {
    case userType.FETCH_ALL_USER:
      return {
        ...state,
        dataUsers: action.data,
        isLoading: action.isLoading,
      };
    case userType.LOADING_CALCULATIONS:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case userType.IS_ACTIVE:
      return {
        ...state,
        isActiveUser: action.data,
      };
    case userType.FETCH_ALL_COMPANIES:
      return {
        ...state,
        companies: action.data,
      };
    case userType.FETCH_ALL_INDUSTRIES:
      return {
        ...state,
        industries: action.data,
      };
    case userType.FETCH_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.data,
      };
    case userType.FETCH_ALL_PROFESSION:
      return {
        ...state,
        profession: action.data,
      };
    case userType.IS_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: action.data,
      };
    case userType.FETCH_PROFILE_USER:
      return {
        ...state,
        userProfile: action.data
      };
    case userType.FETCH_USER_CALCULATION_LIMIT:
      return {
        ...state,
        userLimit: action.data
      };
    case userType.FETCH_SOFTWARE_LICENSE:
      return {
        ...state,
        userSoftwareLicense: action.data
      };
    case userType.GET_DRIVE_STATUS:
      return {
        ...state,
        isHaveDrive: action.data.isHaveDrive,
        isHaveManageDrive: action.data.isHaveManageDrive
      };
    case userType.SET_DRIVE_ROLE:
      return {
        ...state,
        driveRole: action.data
      };
    case userType.SET_CHANGE_PLAN:
      return {
        ...state,
        changePlan: action.data
      };
    case userType.GET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: action.data
      };
    case userType.SET_CHANGE_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.data
      };
    case userType.ADD_BILLING_MEMBER:
      return {
        ...state,
        billingMember: action.data
      };
    default:
      return state;
  }
}
