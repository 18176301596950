import * as React from "react";

export const DriveFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

<path
        fill={props.color}
        fillRule="evenodd"
        d="M2.5 11l2.95-5.89A2 2 0 017.24 4h9.52a2 2 0 011.79 1.11L21.5 11h-19zM2 13h20v5a2 2 0 01-2 2H4a2 2 0 01-2-2v-5zm3 3a1 1 0 011-1h.01a1 1 0 110 2H6a1 1 0 01-1-1zm5-1a1 1 0 100 2h.01a1 1 0 100-2H10z"
        clipRule="evenodd"
      ></path>
  </svg>
);
