import styled from "styled-components";

interface Props {
    isHeader: boolean;
    first: boolean;
    last: boolean;
    isSelected: boolean;
    isRoot: boolean;
}

export const Container = styled.div({
    flexDirection: 'column',
    display: 'flex'
});

export const Header = styled.div({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '40px',
    columnGap: '10px',
    justifyContent: 'center',
    alignItems: 'center'
});

export const ContentListWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    maxHeight: '500px',
    overflow: 'auto'
});

export const ListWrapper = styled('div')<Props>(({isHeader, first, last, isSelected, isRoot}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: "12px 18px 12px 18px",
    borderTop: '1px solid #E0E6EB',
    borderRight: '1px solid #E0E6EB',
    borderLeft: '1px solid #E0E6EB',
    ...(last && {borderBottom: '1px solid #E0E6EB', borderBottomLeftRadius: 16, borderBottomRightRadius: 16}),
    cursor: isHeader && isRoot? 'default' : 'pointer',
    backgroundColor: isHeader ? '#F0F2F5' : isSelected ? '#E3F3FF' : '#FFF',
    ...(first && {borderTopLeftRadius: 16, borderTopRightRadius: 16}),
    '&:hover': {
        backgroundColor: isHeader ? '#F0F2F5' : '#E3F3FF',
    }
}));

export const ButtonWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '42px',
    columnGap: '16px',
    justifyContent: 'space-between'
  });