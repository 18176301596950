function getIndicesOf(substring, string) {
	let startIndex = 0;
    let index;
    var indices = [];
  while((index = string.indexOf(substring, startIndex)) > -1) {
  indices.push(index);
  startIndex = index + substring.length;
  }
  return indices;
}

export function copyCellFormula(formula) {
    String.prototype.replaceAt = function(index, replacement, diff) {
      let nextIndex = index;
      if(diff) {
        nextIndex = index - 1;
      }
        return this.substring(0, index) + replacement + this.substring(nextIndex + replacement.length);
    };

    let text = formula;
    const wordbreak = /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\-|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/;

    const cell = /^[A-Z]{1,2}[1-9]?\d/;
    const column = /^[A-Z]{1,2}/;
    const row = /[1-9]?\d/;

    const tokens = text.split(wordbreak).filter((cellRef) => cellRef.match(cell));
    const result = [];

    tokens.map((item) => {
        let letter = item.match(column);
        let number = item.match(row);
      
        const increment = parseInt(number) + 1;
      
        const cellIncrement = letter + increment.toString();
      
      result.push(`${item}=${cellIncrement}`);
    });
    
    result.forEach((mapString) => {
      const stringArr = mapString.split("=");
      const indices = getIndicesOf(stringArr[0], text);
      indices.map((index) => {
        let stringBefore = text.charAt(index - 1);
        if(stringBefore !== "_") {
            text = text.replaceAt(index, stringArr[1], stringArr[0].length !== stringArr[1].length);
        }
      });
    });
    return text;

};

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function getDeepNestedKeybyValue(object, format, option) {
  for(const [key, value] of Object.entries(object)) {
    if(!!value && typeof value === "object") {
      if(value[0] === option && value[1] === format) return key;
    }
  }
}

export const META_NAME = {
  PREFIX: "prefix",
  SUFFIX: "suffix"
};