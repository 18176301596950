import styled from "styled-components";

export const SearchWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  '.MuiOutlinedInput-root': {
    paddingLeft: 0,
    paddingRight: 0,
    height: 33,
    width: 190,
  },
  '& > #searchError': {
    color: '#DE294C',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '30px',
    letterSpacing: '0.4px',
  },
});