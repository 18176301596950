import { toLocalDateTime } from "../../../utils/dateConvert";
import { ValueType } from "./components/InfoPanelDetailSection";

function getActorName(actor: any) {
  return getValue(actor ? `by ${actor.name}` : "-");
}

function getDate(dateTime: string) {
  return getValue(dateTime ? toLocalDateTime(dateTime) : "-");
}

function getLabel(flag: boolean) {
  const content = flag ? "YES" : "NO";
  return { type: ValueType.LABEL, content, flag };
}

function getValue(content: string | number) {
  return { type: ValueType.TEXT, content };
}

export { getActorName, getDate, getLabel, getValue };
