import { BodyInput, Section } from "../../../pageElements/printViewPage";
import parser from 'html-react-parser';
import { forwardRef } from "react";

export const InputText = forwardRef<any, any>((props, ref) => {
  return (
    <BodyInput ref={ref}>
      <Section>
        <div style={{ width: 180 }}>
          {parser(props.data.properties.description)}
        </div>
        <div style={{ width: 12 }} />
        <div style={{ width: 64 }}>
          {props.data.properties.notation ? parser(props.data.properties.notation) : ""}
        </div>
        <div style={{ width: 180, justifyContent: props.data.type === "NUMERICAL_INPUT" || props.data.type === "CURRENCY_INPUT" ? "flex-end" : "flex-start" }}>
          {props.data.value}
        </div>
        <div style={{ width: 8 }} />
        <div style={{ width: 64 }}>
          {props.data.properties.unit ? parser(props.data.properties.unit) : ""}
        </div>
      </Section>
    </BodyInput>
  );
});


