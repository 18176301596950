export const FETCH_FILE_BY_FOLDER_ID = "FETCH_INPUT_FILE_BY_CALCULATION_ID";
export const LOADING_CALCULATIONS = "LOADING_CALCULATIONS";

export const fetchInputFileByCalculationId = (data: any) => {
  return {
    type: FETCH_FILE_BY_FOLDER_ID,
    data,
  };
};

export function loadingAction(isLoading: boolean) {
  return { type: LOADING_CALCULATIONS, isLoading };
}
