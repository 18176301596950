import { styled } from "@mui/material";

const Container = styled('div')(({theme}) => ({
    color: theme.palette.text.disabled
}));

function Placeholder(props: any) {
    return <Container>{props?.children}</Container>;
}

export default Placeholder;