import { Divider, styled } from "@mui/material";

import { ImageComponentProps } from "../../logger.types";

export const InputWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '4px 0',
    alignItems: 'center',
});

export const FieldWrapper = styled('div')({
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    padding: '4px 0',
    justifyContent: 'flex-start',
    gridTemplateColumns: '358px 206px'
});

export const DescriptionBox = styled('div')({
    flex: '0 1 270px',
    paddingRight: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '> span': {
        overflowWrap: 'anywhere'
    }
});

export const DescriptionBoxField = styled('div')({
    flex: '0 1 354px',
    paddingRight: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '> span': {
        overflowWrap: 'anywhere'
    }
});

export const NotationBox = styled('div')({
    flex: '0 1 64px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

export const UnitBox = styled('div')({
    flex: '1 0 38px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 8,
});

export const InputBox = styled('div')({
    flex: '0 0 160px',
});

export const InputBoxField = styled('div')({
    flex: '0 0 206px',
});

export const Break = styled('div')({
    flex: '1 0 100%',
    overflow: 'hidden'
});

export const RadioBox = styled('div')({
    flex: '0 0 178px',
});

export const DividerComp = styled(Divider)({
    margin: '16px 0'
});

export const ImageBox = styled('div')<ImageComponentProps>(({ alignment }) => ({
    display: "flex",
    padding: ".5rem 0",
    justifyContent:
        alignment === "CENTER"
            ? "center"
            : alignment === "RIGHT"
                ? "flex-end"
                : alignment === "LEFT" || "JUSTIFY"
                    ? "flex-start"
                    : "center",
}));

export const ImageDesc = styled('div')<ImageComponentProps>(({ alignment }) => ({
    display: "flex",
    justifyContent: alignment || "center",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "16px",
}));

export const IconMore = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 18,
    transform: 'translateY(-50%)',
    right: theme.spacing(0),

    '& > div': {
        color: `${theme.palette.action.disabled} !important`,
    },
}));

export const TableWrapper = styled(InputWrapper)({
    padding: 4,
    flexDirection: 'column',
    alignItems: 'flex-start',
});

export const TableDescription = styled("div")({
    display: "flex",
    flexDirection: "row",
    padding: "4px 0",
    '> span:nth-of-type(2)': {
        paddingLeft: 8
    }
});

export const TableContent = styled("div")({
    wordBreak: "break-word",
    'table > tbody': {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#29333D',
    }
});

export const ButtonWrapper = styled('div')({
    display: 'flex',
    gap: 10,
    padding: '4px 0',
});

export const IconButton = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.light}`,

    '&:hover': {
        borderColor: theme.palette.primary.main
    }
}));

//MODAL EXPAND

export const TableBox = styled('div')({
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
});

export const TableBoxWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '1024px',
    backgroundColor: '#FFFFFF',
});


export const TableContentSection = styled('div')({
    display: 'flex',
    width: '1024px',
    height: '512px'
});

export const FooterButton = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '12px 16px 16px',
});

export const ButtonWrapperExpand = styled('div')({
    display: 'flex',
    gap: 10,
    padding: '12px 16px 16px',
});
