import { patchIsValidCalculation } from '../../../service/queueManager/queue';
import { ACTION_STATE, CALCULATION_TARGET, LOGGER_PREVIEW_IMAGE, PREVENT_CHANGES, RESET_IMAGE_STATE, } from './composerChangesTypes';

export const setPreventChanges = (payload: boolean) => {
  return {
    type: PREVENT_CHANGES,
    payload
  };
};

export const setActionState = (payload: any) => {
  return {
    type: ACTION_STATE,
    payload
  };
};

export const setCalculationTarget = (payload: any) => {
  return {
    type: CALCULATION_TARGET,
    payload
  };
};


export const setLoggerPreviewImage = (payload: any) => {
  return {
    type: LOGGER_PREVIEW_IMAGE,
    payload
  };
};

export const resetImageState = () => {
  return {
    type: RESET_IMAGE_STATE,
  };
};

export async function updateCalculationStatus(calculationId: string, payload: { isComplete: boolean }) {
  return new Promise((resolved, rejected) => {
    patchIsValidCalculation(calculationId, payload)
      .then((data: any) => {
        resolved(data);
      }).catch((error: any) => {
        rejected(error);
      });
  });
}