import { ButtonWrapper, Container, ContentListWrapper, Header, ListWrapper } from "./styles";

import { CalculationI } from "../../../../../../store/types/calculationsTypes";
import Text from '../../../../../atoms/Text';
import { useState } from "react";
import { useTheme } from "@mui/material";
import { DriveFilled } from "../../../../../../assets/icons/DriveFilled";
import StyledButton from "../../../../../atoms/Button";
import { getPersonalWorkspaceId } from "../../../../../../utils/storage";
import { DriveI, FolderDriveItemsI } from "../../../../../../pages/businessDrives/businessDrives.types";
import { LoadingSpinner } from "../../../../../atoms/loading";
import Icon from "../../../../../atoms/Icon";

interface CopyToDriveI {
    calculation: CalculationI;
    onClose: () => void;
    getDriveContent: (id?: string) => void;
    handleModal: (type: string, payload: any) => void;
}

interface ContentListI {
    id: string;
    title: string;
    type: string;
    isRoot: boolean;
    parentId: string | null
}

const ROOT_LIST: Array<ContentListI> = [
    {
        id: '1',
        title: 'Rekava',
        type: 'Header',
        isRoot: true,
        parentId: null
    },
    {
        id: getPersonalWorkspaceId() ?? '',
        title: 'Business Drives',
        type: 'Drive',
        isRoot: true,
        parentId: null
    },
];

export const CopyToDrives = ({ calculation, onClose, getDriveContent, handleModal }: CopyToDriveI) => {
    const [data, setData] = useState<Array<ContentListI>>(ROOT_LIST);
    const initialList: ContentListI = {
        id: '',
        title: '',
        type: '',
        isRoot: false,
        parentId: null
    };
    const [selectedList, setSelectedList] = useState<ContentListI>(initialList);
    const [isLoadingLoad, setLoadingLoad] = useState<boolean>(false);
    const theme = useTheme();

    const openFolder = async (content: ContentListI) => {
        try {
            setLoadingLoad(true);
            const isParentHeader = content.id !== getPersonalWorkspaceId();
            const isRoot = content.isRoot;
            const isHeader = content.type === 'Header';
            const isBackToDriveList = isHeader && isParentHeader && content.parentId === null;
            const isFolderContent = isHeader && isParentHeader && content.parentId;
            const result: any = await getDriveContent(isRoot ? '' : isBackToDriveList || isFolderContent ? content.parentId as string : content.id);
            if (isRoot || isBackToDriveList) {
                setData([...[{
                    id: getPersonalWorkspaceId(),
                    title: 'Business Drives',
                    type: 'Header',
                    isRoot: false,
                    parentId: null
                }], ...result.filter((el: DriveI) => !/VIEWER/.test(el.memberRole.type)).map((item: DriveI) => {
                    return {
                        id: item.id,
                        title: item.title,
                        type: 'Drive',
                        isRoot: false,
                        parentId: null
                    };
                })]);
                setSelectedList(initialList);
            } else {
                setSelectedList({
                    id: result.id,
                    title: result.title,
                    type: result.parentId ? 'Folder' : 'Drive',
                    isRoot: false,
                    parentId: result.parentId
                });
                const folderList: Array<FolderDriveItemsI> = result.items;
                setData([...[{
                    id: result.id,
                    title: result.title,
                    type: 'Header',
                    isRoot: false,
                    parentId: result.parentId
                }], ...folderList.filter((el: FolderDriveItemsI) => /FOLDER/.test(el.itemType)).map((item: FolderDriveItemsI) => {
                    return {
                        id: item.id,
                        title: item.title,
                        type: 'Folder',
                        isRoot: false,
                        parentId: null
                    };
                })]);
            }
            setLoadingLoad(false);
        } catch (error: any) {
            setLoadingLoad(false);
        }
    };

    const toRootList = () => {
        setData(ROOT_LIST);
        setSelectedList(initialList);
    };

    const handleCopyToDrives = () => {
        onClose();
        handleModal("COPY_CALCULATION_TO_BUSINESS_DRIVE_CONFIRMATION", {
            calculationIds: [calculation.id],
            destinationFolderId: selectedList.id,
            destinationFolderTitle: selectedList.title
        });

    };

    return (
        <Container>
            {isLoadingLoad && <LoadingSpinner />}
            <Header>
                <Text variant='h1' align={'center'}>
                    Copy To
                </Text>
            </Header>
            <ContentListWrapper>
                {Array.isArray(data) && data.map((list: ContentListI, idx: number) => {
                    const first = idx === 0;
                    const last = idx === data.length - 1;
                    const isSelected = selectedList.id === list.id;
                    const isHeader = list.type === 'Header';
                    const isRoot = list.isRoot;
                    const isDrive = list.type === 'Drive';
                    const isParentHeader = list.title !== 'Business Drives';
                    return (<ListWrapper
                        key={`list_${idx}_${list.title}`}
                        isHeader={isHeader}
                        first={first}
                        last={last}
                        onClick={() => isHeader && !isRoot && isParentHeader ? openFolder(list) : isHeader && !isRoot && !isParentHeader ? toRootList() : setSelectedList(isRoot ? { ...initialList, isRoot: true } : list.id === selectedList.id ? {
                            ...data[0],
                            type: data[0].parentId ? 'Folder' : 'Drive',
                        } : list)}
                        isSelected={isSelected}
                        onDoubleClick={() => isHeader && isRoot || isHeader && !isRoot ? undefined : openFolder(list)}
                        isRoot={isRoot}
                    >
                        <div style={{ flexDirection: 'row', display: 'flex', gap: 10 }}>
                            {isDrive && <DriveFilled color={theme.customText.icon_high} />}
                            {isHeader && !isRoot && <Icon icon='arrowLeft' color={theme.customText.icon_high} />}
                            <Text variant="body2">{list.title}</Text>
                        </div>
                        {(/Drive|Folder/).test(list.type) && !isHeader && <Icon icon='arrowRightSecondary' color={theme.customText.icon_high} />}
                    </ListWrapper>);
                })}
            </ContentListWrapper>
            <ButtonWrapper>
                <StyledButton
                    key={0}
                    type="button"
                    variant="outlined"
                    data-testid={'submit'}
                    onClick={onClose}
                    sx={{ width: 194 }}
                >
                    Cancel
                </StyledButton>
                <StyledButton
                    key={0}
                    type="button"
                    variant="contained"
                    data-testid={'submit'}
                    onClick={handleCopyToDrives}
                    sx={{ width: 194 }}
                    disabled={!(/Drive|Folder/).test(selectedList.type) || selectedList.id === getPersonalWorkspaceId()}
                >
                    Copy Here
                </StyledButton>
            </ButtonWrapper>

        </Container>
    );
};