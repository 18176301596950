import { InputProps } from '../../logger.types';
import { RadioGroup, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import { Break, DescriptionBox, InputWrapper, NotationBox } from './styles';
import { theme } from '../../../../utils/theme';
import StyledRadioButton from '../../../../components/atoms/RadioButton';

function RadioInput({ name, control, properties, defaultValue }: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    defaultValue
  });

  return (
    <>
      <InputWrapper>
        <DescriptionBox contentEditable={false}>
          <Typography variant="body3" component={'span'}>
            {properties.description
              ? parser(properties.description)
              : properties.description}
          </Typography>
        </DescriptionBox>

        <NotationBox>
          <Typography variant="body3">
            {properties.notation
              ? parser(properties.notation)
              : properties.notation}
          </Typography>
        </NotationBox>

        <Break />

        <RadioGroup
          onChange={onChange}
          onBlur={onBlur}
          value={value ?? ''}
          name={name}
        >
          {properties?.options.map((item: any, idx: number) => (
            <StyledRadioButton
              key={`radio-button-menu-${idx}`}
              value={item}
              label={parser(item)}
              size="small"
            />
          ))}
        </RadioGroup>
      </InputWrapper>
    </>
  );
}

export default RadioInput;
