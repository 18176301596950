import { Box, LinearProgress } from '@mui/material';
import Text from '../../components/atoms/Text';
import HelpIcon from '@mui/icons-material/Help';
import { BUSINESS, BUSINESS_PLUS, FREE, PRO } from '../../utils/constant';
import { SubscriptionFooterNote } from './SubscriptionFooterNote';
import dayjs from 'dayjs';
import { handleSubscriptionUsage } from '../../utils/helpersFunction';

export default function SubscriptionFooter(props: any) {
  const { subscription, userLimit } = props;
  const { plan, pretty } = subscription ?? { plan: FREE };

  if (plan === BUSINESS || plan === BUSINESS_PLUS)
    return (
      <Box style={{ width: '100%' }}>
        <Text variant="h2" style={{ paddingBottom: 16 }}>
          Usage Business
        </Text>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{
              display: 'flex',
              flex: '1 1 1px',
              flexDirection: 'row',
              paddingBottom: 18,
              gap: 32
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: 100,
                minHeight: 18,
                flex: 1
              }}
            >
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'baseLine',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'baseLine'
                  }}
                >
                  <Text
                    variant="h4"
                    style={{ paddingBottom: 16, paddingRight: 8 }}
                  >
                    Team Drives
                  </Text>
                </div>
                <div>
                  <Text variant="h4" style={{ paddingBottom: 16 }}>
                    {userLimit?.teamDriveLimit === null
                      ? 'Unlimited'
                      : `${userLimit.currentTeamDrive ?? 0} out of ${
                          userLimit.teamDriveLimit ?? 0
                        }`}
                  </Text>
                </div>
              </Box>
              <LinearProgress
                value={handleSubscriptionUsage(
                  userLimit.currentTeamDrive,
                  userLimit.teamDriveLimit
                )}
                variant="determinate"
                style={{ height: 8, borderRadius: 12, width: '100%' }}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: 100,
                flex: 1
              }}
            >
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'baseLine',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'baseLine'
                  }}
                >
                  <Text
                    variant="h4"
                    style={{ paddingBottom: 16, paddingRight: 8 }}
                  >
                    Business Collaborators
                  </Text>
                </div>
                <div>
                  <Text variant="h4" style={{ paddingBottom: 16 }}>
                    {userLimit?.driveMemberLimit === null
                      ? 'Unlimited'
                      : `${userLimit.currentDriveMember ?? 0} out of ${
                          userLimit.driveMemberLimit ?? 0
                        }`}
                  </Text>
                </div>
              </Box>
              <LinearProgress
                value={handleSubscriptionUsage(
                  userLimit.currentDriveMember,
                  userLimit.driveMemberLimit
                )}
                variant="determinate"
                style={{ height: 8, borderRadius: 12, width: '100%' }}
              />
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              flex: '1 1 1px',
              flexDirection: 'row',
              paddingBottom: 18,
              gap: 32
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: 100,
                flex: 1
              }}
            >
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'baseLine',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'baseLine'
                  }}
                >
                  <Text
                    variant="h4"
                    style={{ paddingBottom: 16, paddingRight: 8 }}
                  >
                    Calculation files
                  </Text>
                </div>
                <div>
                  <Text variant="h4" style={{ paddingBottom: 16 }}>
                    {userLimit?.limit === null
                      ? 'Unlimited'
                      : `${userLimit.calculations ?? 0} out of ${
                          userLimit.limit ?? 0
                        }`}
                  </Text>
                </div>
              </Box>
              <LinearProgress
                value={handleSubscriptionUsage(
                  userLimit.calculations,
                  userLimit.limit
                )}
                variant="determinate"
                style={{
                  height: 8,
                  borderRadius: 12,
                  width: '100%',
                  padding: 0
                }}
              />
            </Box>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: 100,
                flex: 1
              }}
            >
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'baseLine',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'baseLine'
                  }}
                >
                  <Text
                    variant="h4"
                    style={{ paddingBottom: 16, paddingRight: 8 }}
                  >
                    Calculations files per Drive
                  </Text>
                </div>
                <div>
                  <Text variant="h4">
                    {userLimit?.ownedCalculationLimitPerDrive === null
                      ? 'Unlimited'
                      : `${
                          userLimit.currentCalculationFilePerDrive ?? 0
                        } out of ${
                          userLimit.ownedCalculationLimitPerDrive ?? 0
                        }`}
                  </Text>
                </div>
              </Box>
              <LinearProgress
                value={handleSubscriptionUsage(
                  userLimit.currentCalculationFilePerDrive,
                  userLimit.ownedCalculationLimitPerDrive
                )}
                variant="determinate"
                style={{ height: 8, borderRadius: 12, width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
        {plan === BUSINESS &&
        userLimit?.currentCalculationFilePerDrive >=
          userLimit.ownedCalculationLimitPerDrive ? (
          <SubscriptionFooterNote plan={pretty} />
        ) : (
          <></>
        )}
      </Box>
    );

  return (
    <div
      style={{
        width: '100%',
        borderTop: '1px solid #D6DFEB',
        paddingTop: 32,
        paddingBottom: 60
      }}
    >
      <Text variant="h2" style={{ paddingBottom: 16 }}>
        Usage
      </Text>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'baseLine'
          }}
        >
          <Text variant="h4" style={{ paddingBottom: 16, paddingRight: 8 }}>
            Calculation files
          </Text>
        </div>
        <div>
          <Text variant="h4" style={{ paddingBottom: 16 }}>
            {userLimit?.limit === null
              ? 'Unlimited'
              : `${userLimit.calculations ?? 0} out of ${userLimit.limit ?? 0}`}
          </Text>
        </div>
      </div>
      <LinearProgress
        value={handleSubscriptionUsage(userLimit.calculations, userLimit.limit)}
        variant="determinate"
        style={{ height: 8, borderRadius: 12, width: '100%' }}
      />
      {plan === PRO && (
        <>
          <div
            style={{
              display: 'flex',
              padding: '16px 0px',
              alignItems: 'center'
            }}
          >
            {userLimit.calculations < userLimit.limit ? (
              <>
                <Text variant="h4" style={{ paddingRight: 8 }}>
                  Pro until{' '}
                  {dayjs(subscription?.pro_TRIAL_EXPIRED_TIME).format(
                    'D MMMM YYYY'
                  )}
                </Text>
              </>
            ) : (
              <SubscriptionFooterNote plan={pretty} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
