import React from 'react'
import { ChatWrapper } from '../styles'
import Icon from '../../../components/atoms/Icon'
import './loading.css'
import { Spinner } from '../../../components/atoms/loading/elements'
import { AceFetchingWrapper } from './styles'
import Text from '../../../components/atoms/Text'
import { useTheme } from '@mui/material'

export const AceFetching = () => {
  const theme = useTheme();
  return (
    <AceFetchingWrapper>
      <Spinner size='large' style={{ marginBottom: 24 }} />
      <Text variant='h5' style={{ marginBottom: 8 }}>Please wait while we retrieve the data...</Text>
      <div>
        <Text variant='body2' color={theme.customText.low}>If you’re not automatically redirected, </Text>
        <Text onClick={() => window.location.reload()} variant='h5' style={{ cursor: 'pointer' }} color={theme.palette.primary.main}>refresh this page</Text>
      </div>
    </AceFetchingWrapper>
  )
}
