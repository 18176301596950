import {
  Add,
  FormatListBulletedOutlined,
  GridViewOutlined,
  KeyboardArrowDown,
  MenuOutlined
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  OWNER,
  VIEWER
} from '../../../pages/businessDrives/businessDrives.types';
import React, { ChangeEvent } from 'react';
import {
  businessDrivesItems,
  businessDrivesItemsI,
  menuCalcItems,
  menuCommunituItems
} from '../../../utils/constant';
import {
  checkPath,
  checkSubscriptionAndDate
} from '../../../utils/helpersFunction';
import {
  getBusinessDriveRole,
  getPersonalWorkspaceId,
  getUserProfile,
  getUserType
} from '../../../utils/storage';
import { getExtension, uploadSpreadSheet } from '../../../utils/fileHandler';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CalculationsGrid } from './elements';
import Icon from '../../atoms/Icon';
import { LoadingSpinner } from '../../atoms/loading';
import { ReduxState } from '../../../store';
import Text from '../../atoms/Text';
import { encalDataAction } from '../../../store/actions/encalActions';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import { menuStyle } from '../../../utils/theme';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';
import { useDispatch } from 'react-redux';

interface CalculationsMenuI {
  styleViewEnabled?: boolean;
  styleView: string;
  location: any;
  handleSelectStyle: (params: string) => void;
  handleMinimizeSidebar: () => void;
  handleModal: (type: string, payload?: any) => void;
  handleShowMobileSidebar: () => void;
  handleShowMobileInfoPanel: () => void;
}

//TO DO HANDLE ALL FUNCTION
export default function CalculationsMenu(props: any) {
  const {
    styleViewEnabled = true,
    styleView,
    handleSelectStyle,
    handleModal,
    location,
    handleShowMobileSidebar,
    handleShowMobileInfoPanel
  }: CalculationsMenuI = props;

  const personalCalculation = checkPath(
    props.location.pathname,
    'my-calculation'
  );
  const theme = useTheme();
  const communityCalculation = checkPath(props.location.pathname, 'community');
  const history = useHistory();
  const businessDrives = checkPath(props.location.pathname, 'businessDrives');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { personalWorkspaceId, subscription } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  const { folderId } = useParams<{ folderId: string }>();
  const subscriptionDetail: any = subscription;
  const isMobile = !useMediaQuery('(min-width:1080px)');

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { driveItems } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  let refInputFile: HTMLInputElement | null;
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handelOnclickListNewCalc = async (key: string, type?: string) => {
    if (key === 'addFolder') {
      handleModal('ADD_FOLDER', businessDrives ? driveItems : type);
    }
    if (key === 'createCalculation') {
      if (businessDrives) {
        dispatch(
          formModalSetupActionPopUp('NEW_CALCULATION_TEAM_DRIVES', {
            folderId,
            history,
            ...(props.location.state.driveId && { driveId: props.location.state.driveId })
          })
        );
      } else {
        if (checkSubscriptionAndDate(subscription, userLimit, dispatch))
          dispatch(
            formModalSetupActionPopUp('NEW_CALCULATION_FROM_COMPOSER', {
              folderIdCalculation: folderId ?? getPersonalWorkspaceId()
            })
          );
      }
    }
    if (key === 'uploadSpreadSheet') {
      refInputFile?.click();
    }
    setAnchorEl(null);
  };

  const renderMenuList = () => (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl?.id === 'modeView'}
      onClose={handleCloseMenu}
      onClick={handleCloseMenu}
      PaperProps={{
        style: {
          marginLeft: 5,
          padding: 8
        },
        elevation: 0,
        sx: menuStyle
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        selected={styleView === 'Tiles' || styleView === ''}
        onClick={() => handleSelectStyle('Tiles')}
        data-testid="gridViewIcon"
      >
        <ListItemIcon>
          <GridViewOutlined fontSize="small" />
        </ListItemIcon>
        Tiles
      </MenuItem>
      <MenuItem
        selected={styleView === 'List'}
        onClick={() => handleSelectStyle('List')}
        data-testid="bulleteViewIcon"
      >
        <ListItemIcon>
          <FormatListBulletedOutlined fontSize="small" />
        </ListItemIcon>
        List
      </MenuItem>
    </Menu>
  );

  const handlerUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const id = folderId ? folderId : personalWorkspaceId;
    const files = e.currentTarget.files;
    if (files?.length) {
      // cek if extension file is xls or xlsx
      const extension = getExtension(files[0].name);
      if (extension === 'xls' || extension === 'xlsx' || extension === 'xlsm') {
        // upload file if less than 20 mb
        if (files[0].size < 2e7) {
          try {
            const resultUpload = await uploadSpreadSheet(files[0], id);
            if (resultUpload?.message === 'Upload Complete') {
              dispatch(encalDataAction(id));
              dispatch(toastSetupActionPopUp('INFO', 'Upload Complete'));
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          dispatch(toastSetupActionPopUp('ERROR', 'File Size is too large'));
        }
      } else {
        dispatch(
          toastSetupActionPopUp(
            'ERROR',
            'Incompatible File Format (Examples of compatible files type are .xls, .xlsx, .xlsm)'
          )
        );
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isMobile && /ACE/.test(getUserType()) ? <Divider /> :
        <CalculationsGrid>
          <div>
            {isMobile && <MenuOutlined
              onClick={handleShowMobileSidebar}
              data-testid="menuViewIcon"
            />}
            {isMobile && <Text variant="body1">{/public/.test(location.pathname) ? 'Ace Public' : /enterprise/.test(location.pathname) ? 'Ace Enterprise' : /accountSettings/.test(location.pathname) ? 'Account' : ''}</Text>}
            {personalCalculation && (
              <>
                <Button
                  onClick={handleOpenMenu}
                  id="newCalc"
                  variant="contained"
                  endIcon={<KeyboardArrowDown />}
                  size="small"
                  data-testid="btnNew"
                >
                  NEW
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={anchorEl?.id === 'newCalc'}
                  onClose={handleCloseMenu}
                >
                  {menuCalcItems.map((listMenu: any, menuindex: number) => (
                    <div style={{ width: 192, height: '100%' }} key={menuindex}>
                      <MenuItem
                        onClick={() => {
                          handelOnclickListNewCalc(listMenu.key, 'personal');
                        }}
                        data-testid={listMenu.dataTestid}
                      >
                        {listMenu.title}
                      </MenuItem>
                      {listMenu.divider && <Divider />}
                    </div>
                  ))}
                </Menu>

                <input
                  ref={refParam => (refInputFile = refParam)}
                  style={{ display: 'none' }}
                  onChange={handlerUploadFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type={'file'}
                  data-testid="uploadFileSpreadsheet"
                ></input>
              </>
            )}
            {communityCalculation &&
              /REKAVA_ADMIN/.test(getUserProfile() as string) && (
                <>
                  <Button
                    onClick={handleOpenMenu}
                    id="newCalc"
                    variant="contained"
                    endIcon={<KeyboardArrowDown />}
                    size="small"
                    data-testid="btnNew"
                  >
                    NEW
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={anchorEl?.id === 'newCalc'}
                    onClose={handleCloseMenu}
                  >
                    {menuCommunituItems.map(
                      (listMenu: any, menuindex: number) => (
                        <div
                          style={{ width: 192, height: '100%' }}
                          key={menuindex}
                        >
                          <MenuItem
                            onClick={() => {
                              handelOnclickListNewCalc(listMenu.key, 'community');
                            }}
                            data-testid={listMenu.dataTestid}
                          >
                            {listMenu.title}
                          </MenuItem>
                          {listMenu.divider && <Divider />}
                        </div>
                      )
                    )}
                  </Menu>
                </>
              )}

            {businessDrives &&
              !folderId &&
              /BUSINESS_PLUS/.test(subscriptionDetail.plan) && (
                <>
                  <Button
                    startIcon={<Add />}
                    onClick={() => handleModal('CREATE_TEAM_DRIVES')}
                    variant="contained"
                    size="small"
                  >
                    New Drive
                  </Button>
                </>
              )}

            {businessDrives && folderId && (
              <>
                {getBusinessDriveRole() !== VIEWER && (
                  <Button
                    onClick={handleOpenMenu}
                    id="newCalc"
                    variant="contained"
                    endIcon={<KeyboardArrowDown />}
                    size="small"
                    data-testid="btnNew"
                  >
                    NEW
                  </Button>
                )}

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={anchorEl?.id === 'newCalc'}
                  onClose={handleCloseMenu}
                >
                  {businessDrivesItems
                    .filter((item: businessDrivesItemsI) =>
                      /(OWNER|CONTENT_MANAGER)/.test(getBusinessDriveRole())
                        ? item.key
                        : item.key !== 'addFolder'
                    )
                    .map((listMenu: any, menuindex: number) => (
                      <div style={{ width: 192, height: '100%' }} key={menuindex}>
                        <MenuItem
                          onClick={() => {
                            handelOnclickListNewCalc(listMenu.key);
                          }}
                          data-testid={listMenu.dataTestid}
                        >
                          {listMenu.title}
                        </MenuItem>
                        {listMenu.divider && <Divider />}
                      </div>
                    ))}
                </Menu>
              </>
            )}
          </div>
          {businessDrives && folderId && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() =>
                dispatch(
                  formModalSetupActionPopUp('MANAGE_MEMBERS', {
                    folderId
                  })
                )
              }
            >
              <Text variant="h4" color={theme.palette.primary.main}>
                {getBusinessDriveRole() === OWNER
                  ? 'Manage Members'
                  : 'View Members'}
              </Text>
            </div>
          )}
          {isMobile && !/accountSettings/.test(location.pathname) && (<Icon icon="information" onClick={handleShowMobileInfoPanel} />)}
          {/* KPD-1961 HIDE FEAUTURE COMUNITY AND MY CALCULATION */}
          {styleViewEnabled && window.location.pathname.includes('community') && (
            <div>
              <Text variant="body1">View:</Text>
              <IconButton
                onClick={handleOpenMenu}
                id="modeView"
                size="small"
                data-testid="gridViewIcon"
              >
                {styleView === 'List' ? (
                  <FormatListBulletedOutlined fontSize="small" />
                ) : (
                  <GridViewOutlined fontSize="small" />
                )}
              </IconButton>
              {renderMenuList()}
            </div>
          )}
        </CalculationsGrid>}
    </>
  );
}
