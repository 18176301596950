import { styled, TextareaAutosize } from '@mui/material';
import {
  VanillaTextInputStylePropsI,
  FormulaStylePropsI,
  SelectWrapperStylePropsI
} from './type';

export const TwoValueWrapper = styled('div')({
  display: 'flex',
  minHeight: '28px'
});

export const TwoValueDisabledInput = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '57px',
  border: '1px solid #D6DFEB',
  borderBottomLeftRadius: '6px',
  borderTopLeftRadius: '6px',
  backgroundColor: theme.palette.action.disabledBackground,
  boxSizing: 'border-box'
}));

export const SelectWrapper = styled('div')<SelectWrapperStylePropsI>(
  ({ theme, typeInput }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '16px',
    width: typeInput === 'FIELD' ? 220 : '142px',
    padding: '5px 8px',
    border: '1px solid #D6DFEB',
    borderRadius: '6px',
    backgroundColor: theme.palette.info.light
  })
);

export const SelectMenuBox = styled('div')(({ theme }) => ({
  maxHeight: '240px',
  width: '160px',
  borderRadius: '6px',
  overflowY: 'auto'
}));

export const SelectMenuList = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 'normal',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.info.light
  }
}));

export const RadioWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const RadioAddWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '18px',
  width: '99px',
  padding: '4px 16px',
  color: '#118CFF',
  cursor: 'pointer',
  border: '1px solid #118CFF',
  borderRadius: '6px'
}));

export const RadioListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const RadioList = styled('div')<SelectWrapperStylePropsI>(
  ({ typeInput }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    height: '14px',
    width: typeInput === 'FIELD' ? 370 : 294,
    marginBottom: '4px',
    padding: '6px 8px',
    border: '1px solid #D6DFEB',
    borderRadius: '6px',
    '&:focus-within': {
      border: '1px solid #118CFF'
    }
  })
);

export const VanillaInput = styled('input')<VanillaTextInputStylePropsI>(
  ({ theme, typeInput, errorState }) => ({
    minHeight: '18px',
    width:
      typeInput === 'inputValue'
        ? '142px'
        : typeInput === 'notation'
        ? '54px'
        : typeInput === 'transparant'
        ? '100%'
        : typeInput === 'inputField'
        ? '220px'
        : '',
    padding: '4px 8px',
    border:
      typeInput === 'transparant'
        ? 'none'
        : errorState
        ? '1px solid #DE294C'
        : '1px solid #E0E6EB',
    borderRadius: '6px',
    backgroundColor:
      typeInput === 'inputValue' || typeInput === 'inputField'
        ? '#E3F3FF'
        : 'inherit',
    lineHeight: 1.5,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'left',
    '&:focus': {
      outline: 'none',
      border:
        typeInput === 'transparant'
          ? '1px solid transparant'
          : '1px solid #118CFF'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  })
);

export const VanillaTextArea = styled(
  TextareaAutosize
)<VanillaTextInputStylePropsI>(() => ({
  minHeight: '18px',
  width: '100%',
  resize: 'vertical',
  padding: '4px 8px',
  border: 'none',
  borderTopRightRadius: '6px',
  borderBottomRightRadius: '6px',
  lineHeight: 1.5,
  fontFamily: 'Inconsolata',
  fontSize: '12px',
  fontWeight: 400,
  textAlign: 'left',
  '&:focus': {
    outline: 'none'
  },
  '&::placeholder': {
    color: '#B3BFCC'
  }
}));

export const FormulaWrapper = styled('div')<FormulaStylePropsI>(
  ({ theme, error }) => ({
    display: 'flex',
    flexDirection: 'row',
    border: error ? '1px solid #D51F3C' : '1px solid #E0E6EB',
    borderRadius: '6px',
    '&:focus-within': {
      border: '1px solid #118CFF !important'
    }
  })
);

export const LineWrapper = styled('div')<FormulaStylePropsI>(({ error }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: error ? '1px solid #D51F3C' : '1px solid #E0E6EB',
  borderRadius: '6px',
  '&:focus-within': {
    border: '1px solid #118CFF !important'
  },
  width: '100%'
}));

export const FormulaIconBox = styled('div')<FormulaStylePropsI>(
  ({ theme, error }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    backgroundColor: error ? '#DE294C' : '#F0F2F5',
    color: error ? '#52667A' : '#FFFFFF'
  })
);
