import React from 'react';
import { ActionsWrapper } from './elements';
import Text from '../../components/atoms/Text';
import Button from '../../components/atoms/Button';


interface PropsI {
  actions: Array<any>;
}

const ErrorActionButton = ({
  actions,
}: PropsI) => {
  return (
    <ActionsWrapper>
      {React.Children.toArray(
        actions.map((item: any, i: number) => {
          return (
            <Button
              key={i}
              color={item.color}
              variant={item.variant}
              {...(item.onClick && { onClick: item.onClick })}
              sx={{ margin: '0 4px' }}
            >
              <Text variant="h5" color="inherit">{item.label}</Text>
            </Button>
          );
        })
      )}
    </ActionsWrapper>
  );
};

export default ErrorActionButton;
