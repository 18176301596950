import { forwardRef } from 'react';
import Text from '../../atoms/Text';

export const Link = forwardRef<any, any>((props, ref) => {
  const { url, description } = props.properties;
  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        overflowWrap: 'anywhere',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10
      }}
    >
      <a href={url} target="_blank" style={{ textDecoration: 'none' }}>
        <Text variant="body3" color={'primary'}>
          {description ? description : url}
        </Text>
      </a>
    </div>
  );
});
