import * as React from "react";

const SvgYen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.513 14.885H7.641v-1.539h3.846l.026 1.539Zm1.333-1.539h3.718v1.539h-3.718v-1.539Zm-1.333 4.564H7.641v-1.513h3.872v1.513Zm1.333-1.513h3.718v1.513h-3.718v-1.513Zm.385 4.103h-2.103v-7.461L6.333 4.5h2.385l3.487 6.487 3.18-6.461h2.282l-4.436 8.461V20.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgYen;
