import { WorkpagesProps } from "../../logger.types";
import { WorkpagesContainer } from "./styles";

export default function Workpages({pageNav, pageHeader, pageContent}: WorkpagesProps) {
    return (
        <WorkpagesContainer>
            {!!pageNav && pageNav}
            {!!pageHeader && pageHeader}
            {!!pageContent && pageContent}
        </WorkpagesContainer>
    );
}