import { InputProps } from '../../logger.types';
import { Tooltip, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import { StyledTextArea } from '../../../../components/atoms/TextArea';
import { DescriptionBox, InputBox, InputWrapper, NotationBox } from './styles';

function TextInput({ name, control, properties, defaultValue }: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules: {
      validate: {
        required: value => {
          if (!value) return `${properties.description} is required`;
          return true;
        }
      }
    },
    defaultValue
  });

  return (
    <InputWrapper>
      <DescriptionBox contentEditable={false}>
        <Typography variant="body3" component={'span'}>
          {properties.description
            ? parser(properties.description)
            : properties.description}
        </Typography>
      </DescriptionBox>

      <NotationBox>
        <Typography variant="body3">
          {properties.notation
            ? parser(properties.notation)
            : properties.notation}
        </Typography>
      </NotationBox>

      <InputBox>
        <Tooltip title={properties.tooltip === null ? '' : properties.tooltip}>
          <StyledTextArea
            fullWidth
            type={'text'}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
            inputRef={ref}
            error={Boolean(error)}
            size="small"
            placeholder={'Input value here'}
          />
        </Tooltip>
      </InputBox>
    </InputWrapper>
  );
}

export default TextInput;
