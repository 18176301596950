import { Typography } from '@mui/material';
import { CommonProps } from '../../logger.types';
import { mapLevelToVariant } from '../../utils';
import parser from 'html-react-parser';
import RichText from '../../../../components/molecules/RichText/RichText';

export default function Textlabel({ properties }: CommonProps) {
  return (
    <>
      {properties?.level === 'NORMAL' ? (
        <RichText
          source={properties?.description}
          readonly={true}
          style={{ border: 'none', padding: '4px 0' }}
        />
      ) : (
        <div style={{ padding: '4px 0' }}>
          <Typography
            variant={mapLevelToVariant[properties?.level]}
            component={'span'}
          >
            {parser(properties.description)}
          </Typography>
        </div>
      )}
    </>
  );
}
