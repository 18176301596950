import parser from 'html-react-parser';
import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { BodyInput, Section } from '../../../pageElements/printViewPage';
import Text from '../../atoms/Text';

export const FieldText = forwardRef<any, any>((props, ref) => {
  const isPdf: boolean = props.printType === 'PDF';
  return (
    <BodyInput ref={ref}>
      <Section>
        <div style={{ width: isPdf ? 256 : 320 }}>
          {parser(props.data.properties.description)}
        </div>
        <div
          style={{
            width: 405,
            justifyContent:
              props.data.properties.fieldType === 'NUMERICAL'
                ? 'flex-end'
                : 'flex-start'
          }}
        >
          <Text
            style={{
              fontFamily: 'SourceCodePro',
              fontSize: '12px',
              lineHeight: 1.5,
              color: '#29333D',
              textAlign:
                props.data.properties.fieldType === 'NUMERICAL'
                  ? 'right'
                  : 'justify',
              wordBreak: 'break-word'
            }}
          >
            {props.data.properties.fieldType === 'DATE'
              ? dayjs(props.data.value).format('DD/MM/YYYY')
              : props.data.value}
          </Text>
        </div>
      </Section>
    </BodyInput>
  );
});
