import { Divider, styled } from "@mui/material";
import { ImageComponentProps } from "../../../pages/loggerRefactor/logger.types";



export const InputWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '4px 0',
    alignItems: 'center',
});

export const DescriptionBox = styled('div')({
    flex: '0 1 286px',
    paddingRight: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '> span': {
        overflowWrap: 'anywhere'
    }
});

export const NotationBox = styled('div')({
    flex: '0 1 64px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

export const UnitBox = styled('div')({
    flex: '1 0 28px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 8,
});

export const InputBox = styled('div')({
    flex: '0 0 160px',
});

export const Break = styled('div')({
    flex: '1 0 100%',
    overflow: 'hidden'
});

export const RadioBox = styled('div')({
    flex: '0 0 178px',
});

export const DividerComp = styled(Divider)({
    margin: '16px 0'
});

export const ImageBox = styled('div')<ImageComponentProps>(({ alignment }) => ({
    display: "flex",
    padding: ".5rem 0",
    justifyContent:
        alignment === "CENTER"
            ? "center"
            : alignment === "RIGHT"
                ? "flex-end"
                : alignment === "LEFT" || "JUSTIFY"
                    ? "flex-start"
                    : "center",
}));

export const ImageDesc = styled('div')<ImageComponentProps>(({ alignment }) => ({
    display: "flex",
    justifyContent: alignment || "center",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "16px",
}));

export const IconMore = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 18,
    transform: 'translateY(-50%)',
    right: theme.spacing(0),

    '& > div': {
        color: `${theme.palette.action.disabled} !important`,
    },
}));
export const TableWrapper = styled(InputWrapper)({
    display: 'block',
    padding: 4,
    pageBreakInside: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start'
});

export const TableDescription = styled("div")({
    display: "flex",
    flexDirection: "row",
    padding: "4px 0",
    '> span:nth-of-type(2)': {
        paddingLeft: 8
    },
});

export const TableContent = styled("div")({
    display: 'block',
    wordBreak: "break-word",
    pageBreakInside: 'avoid',
    'table': {
        borderRight: "0px solid transparent"
      }
    ,
    'table > tbody > tr > td:last-child': {
        borderRight: '1px solid #ccc',
      }
    ,
    'table > thead > tr > td': {
        backgroundColor: 'transparent',
        color: 'transparent',
        position: 'absolute',
        borderColor: 'transparent',
        transform: 'translateX(-4000%)',
      },
});

export const ButtonWrapper = styled('div')({
    display: 'flex',
    gap: 10,
    padding: '4px 0',
});

export const IconButton = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: 10,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.light}`,

    '&:hover': {
        borderColor: theme.palette.primary.main
    }
}));

export const TextBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 8,
    paddingBottom: 8,
    // paddingLeft: 10,
    overflowWrap: 'anywhere'
});
