import { Dispatch } from "redux";
import {
  LOADING_FOLDER,
  FAILED_FOLDER,
  GET_DATA_FOLDER,
} from "../types/folderTypes";
import Service from "../../service/generic/generic";

interface newFolderI {
  parentId: string;
  title: string;
  type: string;
}

interface renameFolderI {
  id: string;
  title: string;
}

interface moveFolderI {
  folderId: string;
  destinationFolderId: string;
}

const service = new Service("folders");
export function addFolder(data: newFolderI) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      await service.post(data);
      dispatch(loadingAction(false));
    } catch (error: any) {
      // dispatch(failedAction("failed", false));
      return Promise.reject(error);
    }
  };
}

export function deleteFolder(folderId: string) {
  return async (dispatch: Dispatch) => {
    try {
      await service.deleteResource("delete", folderId);
    } catch (error: any) {
      // dispatch(failedAction("failed", false));
      return Promise.reject(error);
    }
  };
}

export function renameFolder(data: renameFolderI) {
  return async (dispatch: Dispatch) => {
    try {
      const serviceFolder = new Service("folder");
      dispatch(loadingAction(true));
      await serviceFolder.putResource(data.id, 'title', {
        title: data.title,
      });
      dispatch(loadingAction(false));
    } catch (error: any) {
      // dispatch(failedAction("failed", false));
      return Promise.reject(error);
    }
  };
}

export function getFolders(folderId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      const result = await service.getResource(`${folderId}/view?type=FOLDER`);
      dispatch({
        type: GET_DATA_FOLDER,
        data: result.data.items,
      });
      dispatch(loadingAction(false));
    } catch (error: any) {
      return Promise.reject(error.response.data?.message);
    }
  };
}

export function moveFolder(data: moveFolderI) {
  return async (dispatch: Dispatch) => {
    try {
      await service.put("move", data);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function reorderCal(folderId: string, destinationId: string, sourceId: Array<any>) {
  return async (dispatch: Dispatch) => {
    try {
      const calculation = await service.putSubResource(folderId, "calculations", "orders", {
        "sourceIndexes": sourceId,
        "destinationIndex": destinationId
      });

      return calculation.data.map((element: any) => {
        return element.index;
      });
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      return Promise.reject(error);
    }
  };
}

function loadingAction(isLoading: boolean) {
  return { type: LOADING_FOLDER, isLoading };
}

function failedAction(message: any, isLoading: boolean) {
  return { type: FAILED_FOLDER, message, isLoading };
}
