import React, { useEffect, useState } from 'react';
import { sanitizeString } from '../../../../utils/helpersFunction';

import Icon from '../../../atoms/Icon';
import Text from '../../../atoms/Text';
import RichTextInput from '../../RichText';
import {
  RadioAddWrapper,
  RadioList,
  RadioListWrapper,
  RadioWrapper
} from './styles';

import { RadioPropsI } from './type';

const Radio = ({
  payload,
  addNewRadio,
  deleteRadio,
  selectRadio,
  dataChanges
}: RadioPropsI) => {
  const [radioIndex, setRadioIndex] = useState<number | null>(null);

  useEffect(() => {
    setRadioIndex(null);
  }, [payload.options?.length]);

  function onBlurInputHandler(
    event: React.SyntheticEvent,
    data: string,
    indexRadio: number
  ) {
    if (data.replace(/\n/g, '') !== payload.options[indexRadio]) {
      setRadioIndex(null);
      dataChanges(event, data, indexRadio);
    }
  }

  function deleteRadioHandler(event: React.SyntheticEvent, indexRadio: number) {
    deleteRadio(event, indexRadio);
  }

  function radioIndexHandler(
    event: React.SyntheticEvent,
    indexRadio: number,
    value: string
  ) {
    setRadioIndex(indexRadio);
    selectRadio(event, value);
  }

  return (
    <RadioWrapper>
      <RadioListWrapper>
        {payload.options?.map((el: string, index: number) => (
          <RadioList
            typeInput={payload.typeInput}
            key={`radio-option-list-${index}`}
          >
            <Icon
              icon={radioIndex === index ? 'radioOn' : 'radioOff'}
              onClick={(e: React.SyntheticEvent) =>
                radioIndexHandler(e, index, el)
              }
            />
            <RichTextInput.RichTextToolbar
              component="options"
              placeholder={`Option ${index + 1}`}
              source={el}
              handleSaveChange={(e: React.SyntheticEvent, data) =>
                onBlurInputHandler(e, sanitizeString(data), index)
              }
            />
            <Icon
              icon="closeNoBorder"
              onClick={(e: React.SyntheticEvent) =>
                deleteRadioHandler(e, index)
              }
            />
          </RadioList>
        ))}
      </RadioListWrapper>
      <RadioAddWrapper onClick={addNewRadio}>
        <Text variant="h6" color="#118CFF">
          Add Option
        </Text>
      </RadioAddWrapper>
    </RadioWrapper>
  );
};

export default Radio;
