import * as faqAction from "../types/faqActionTypes";

export interface FaqfileState {
  dataFaq: Array<any>;
  isLoading: boolean;
}

const initialState: FaqfileState = {
  dataFaq: [],
  isLoading: true,
};

const faqReducer = (state: FaqfileState = initialState, action: any) => {
  switch (action.type) {
    case faqAction.SHOW_FAQ_ACTION:
      return {
        ...state,
        dataFaq: action.data.data,
      };
    case faqAction.LOADING_FAQ:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default faqReducer;
