import StyledButton from '../../../../components/atoms/Button';
import Loading from '../../../../components/atoms/loading';
import ProgressBar from '../../../../components/atoms/ProgressBar';
import { LogprogressPanelProps } from '../../logger.types';
import { PanelSection, SideBar } from '../../styles';
import List from '../List';

export default function LogProgressPanel({
  totalInputs,
  userInputs,
  listErrors,
  handlePrintButton,
  isCreatingReport,
  printEnable
}: LogprogressPanelProps) {
  return (
    <SideBar>
      <h2>LOG PROGRESS</h2>

      <PanelSection>
        <ProgressBar
          variant="determinate"
          current={userInputs}
          total={totalInputs}
        />
      </PanelSection>

      <PanelSection>
        <h4>
          {userInputs} out of {totalInputs}
        </h4>
        <h4
          style={{
            fontWeight: 700,
            color: listErrors.total > 0 ? '#DE294C' : '#118CFF'
          }}
        >
          {listErrors.total} Errors
        </h4>
        <List items={listErrors.errors} keyValue="message" />
      </PanelSection>

      <StyledButton
        variant="contained"
        fullWidth
        disabled={!printEnable}
        onClick={() => !isCreatingReport && handlePrintButton()}
        data-testid="btnReadyToPrint"
      >
        {isCreatingReport ? <Loading /> : 'Ready to Print'}
      </StyledButton>
    </SideBar>
  );
}
