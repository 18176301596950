import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface TwoValuePropsI {
  leftValue?: string;
  rightValue?: string;
  rightIsRequired?: boolean;
  rightPlaceholder?: string;
  rightHandleSaveChange?: (event: React.SyntheticEvent, data: string) => void;
}

export interface SelectPropsI {
  payload: {
    options: string[];
    inputValue: string;
    typeInput?: string;
  };
  dataSelect: (event: React.SyntheticEvent, data: string) => void;
}

export interface RadioPropsI {
  payload: {
    options: string[];
    inputValue: string;
    typeInput?: string;
  };
  addNewRadio: (event: React.SyntheticEvent) => void;
  deleteRadio: (event: React.SyntheticEvent, indexRadio: number) => void;
  selectRadio: (event: React.SyntheticEvent, data: string) => void;
  dataChanges: (
    event: React.SyntheticEvent,
    data: string,
    indexRadio: number
  ) => void;
}

export interface TextPropsI {
  register?: UseFormRegister<FieldValues>;
  id?: string;
  typeInput?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: boolean;
  onFocus?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

export interface ILinkProps {
  register?: UseFormRegister<FieldValues>;
  id?: string;
  typeInput?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: boolean;
  dataLink?: any;
  active?: boolean;
  onFocus?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
  handleOpenNewTab: (props: string) => void;
}

export interface FormulaPropsI {
  register?: UseFormRegister<FieldValues>;
  id?: string;
  defaultValue?: string;
  error?: boolean;
  onFocus?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

// STYLE INTERFACE

export interface VanillaTextInputStylePropsI {
  typeInput?: string;
  errorState?: boolean;
}

export interface SelectWrapperStylePropsI {
  typeInput?: string;
}

export interface FormulaStylePropsI {
  error?: boolean;
}

export enum IColorText {
  Blue = '#118CFF',
  Black = 'BLACK'
}
