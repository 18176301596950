import Text from "../../../atoms/Text";
import { HeadSection, Legend, LegendItem, LegendSection, ToolkitWrapper } from "./styles";

const TableLegend = () => {
  const legends = [
    { borderWeight: '3px', borderColor: '#52667A', color: '#FFFFFF', text: 'Shown to Logger' },
    { color: '#BFC9D9', text: 'Header' },
    { color: '#E3F3FF', text: 'Logger Input' },
    { borderWeight: '1px', borderColor: '#D6DFEB', color: '#FFFFFF', text: 'Read Only' },
  ];
  return (
    <ToolkitWrapper type='LEGEND'>
      <HeadSection>
        <Text variant="smallBold" color="#A3B3C2">
          LEGEND
        </Text>
      </HeadSection>
      <LegendSection>
        {legends.map(legend => (
          <Legend>
            <LegendItem borderWeight={legend.borderWeight} borderColor={legend.borderColor ?? null} color={legend.color} />
            <Text variant="body3">{legend.text}</Text>
          </Legend>
        ))}
      </LegendSection>
    </ToolkitWrapper>
  );
};

export default TableLegend;