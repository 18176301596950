import React, { useState, useEffect, createContext } from "react";
import LazyLoad from "vanilla-lazyload";

const Context = createContext<null>(null);
const options = { elements_selector: ".lazyImage__img" };

export const LazyImageProvider = ({ children }: any) => {
  const [lazyLoad, setLazyLoad] = useState<any>(null);

  useEffect(() => {
    if (!lazyLoad) setLazyLoad(new LazyLoad(options));
    return () => lazyLoad && lazyLoad.destroy();
  }, [lazyLoad]);

  return <Context.Provider value={lazyLoad}>{children}</Context.Provider>;
};

export const withLazyImageContext = (Component: any ) => (props: any )=> (
  <Context.Consumer>
    {context => <Component {...props} lazyLoad={context} />}
  </Context.Consumer>
);
