import { BodyInput, Section } from '../../../pageElements/printViewPage';
import { valueConvertNew } from '../../../utils/helpersFunction';
import LatexPrintView from '../LatexFormula/LatexPrintView';
import parser from 'html-react-parser';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const InputFormulaLatex = forwardRef<any, any>((props, ref) => {
  const { isDisplayed } = props.data.properties;

  const theme = useTheme();
  const addLatexRef = (payload: any) => {};
  return (
    <BodyInput ref={ref}>
      <Section>
        <div style={{ width: 180 }}>
          {parser(props.data.properties.description)}
        </div>
        <div style={{ width: 12 }} />

        <div style={{ width: 64 }}>
          {props.data.properties.notation
            ? parser(props.data.properties.notation)
            : ''}
        </div>
        <div style={{ width: 180, justifyContent: props.data.type === "NUMERICAL_INPUT" || props.data.type === "CURRENCY_INPUT" ? "flex-end" : "flex-start" }}>
          {(valueConvertNew(props.pages, props.data.properties.notation)).toString()}
        </div>
        <div style={{ width: 8 }} />
        <div style={{ width: 64 }}>
          {props.data.properties.unit ? parser(props.data.properties.unit) : ""}
        </div>
      </Section>
      {props.showLatex && isDisplayed && (
        <div
          style={{
            display: 'inline-block',
            paddingLeft: 24,
            pageBreakInside: 'avoid',
            textAlign: 'left',
            breakInside: 'avoid'
          }}
        >
          <LatexPrintView
            columnId={props.columnId}
            properties={props.data.properties}
            theme={theme}
            index={props.index}
            loggerValues={props.pages}
            isCompleted={true}
            multiLine={props.multiLine}
            addLatexRef={addLatexRef}
          />
        </div>
      )}
    </BodyInput>
  );
});

