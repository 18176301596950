import { Dialog, Slide } from "@mui/material";
import { getUserDetails, getUserType } from "../../../utils/storage";

import DashboardPanel from "../sidebar/DashboardPanel";
import { FolderTreeItem } from "../../../store/appState/sidebar/reducer";
import Icon from "../../atoms/Icon";
import React, { useState } from "react";
import { SidebarWrapper } from "./styles";
import Text from "../../atoms/Text";
import { TransitionProps } from "@mui/material/transitions";
import TreeNode from "../sidebar/TreeNode";
import images from "../../../assets";
import { setSelectedTreeItem } from "../../../store/appState/sidebar/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DisclaimerBox } from "../../../pages/askAce/components/rightSectionAce/DisclaimerBox";
import { DisclaimerModal } from "../../../pages/askAce/components/rightSectionAce/DisclaimerModal";

interface MobileSidebarProps {
  isOpen: boolean;
  handleClose: () => void;
  headerTitle: string;
  data: FolderTreeItem;
  selectedItem: string;
  userData: {
    userLimit: Record<string, unknown>;
    userDetails: any;
    userPhoto: string;
    loading: boolean;
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide style={{ width: "85%" }} direction="right" ref={ref} {...props} />
  );
});

export const MobileSidebar = ({ isOpen, handleClose, headerTitle, data, selectedItem, userData }: MobileSidebarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAceUser = /ACE/.test(getUserType());
  const [modalVisible, setModalVisible] = useState(false);
  const handleToggleClick = () => {
    setModalVisible(!modalVisible);
  };
  const setSelectedItem = (id: string) => {
    dispatch(setSelectedTreeItem(id));
    if (id == 'acePublic') {
      return history.push(`/ace/public`);
    } else if (id == 'aceEnterprise') {
      return history.push(`/ace/enterprise`);
    } else if (id == 'helpPage') {
      return history.push(`/ace/helpPage`);
    }
  };
  const userProfile = getUserDetails();
  const { subscription } = userProfile;
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <SidebarWrapper>
        <div id="logo">
          <img
            src={images.logoIcon}
            width={32}
            alt="icon"
            onClick={() => undefined}
          />
          <img
            src={images.logoText}
            width={85}
            onClick={() => undefined}
            alt="name"
          />
        </div>
        <div id="header">
          <Icon
            icon='arrowLeft'
            onClick={handleClose}
            data-testid="menuViewIcon"
            size={24}
            styles={{ marginRight: 8 }}
          />
          <Text variant="body1">{headerTitle}</Text>
        </div>
        {
          Array.isArray(data) && data.map((child: any) => {
            return (
              <TreeNode
                key={child.id}
                item={child}
                indentLevel={1}
                isMinimize={false}
                isSelected={selectedItem === child.id}
                selectedItem={selectedItem}
                onClick={() => setSelectedItem(child.id)}
                dataTestid={child.dataTestid}
                isLoading={false}
                isMobile={true}
              />
            );
          })
        }
        {isAceUser ?
          (<DisclaimerBox onToggleClick={handleToggleClick} isMobile={true} />)
          :
          <DashboardPanel
            isMinimize={false}
            userPhoto={userData.userPhoto}
            userDetails={userData.userDetails}
            loading={userData.loading}
            userName={userProfile.name}
            userLimit={userData.userLimit}
            subscription={subscription}
            userType={getUserType() ?? 'REGULAR'}
            isMobile={true}
          />}
        <DisclaimerModal
          modalVisible={modalVisible}
          onCloseModal={handleToggleClick}
          isMobile={true}
        />
      </SidebarWrapper>
    </Dialog >
  )
}