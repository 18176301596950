import { Dispatch } from "redux";
import {
  setExpiredToken,
  setToken,
  setEnterpriseWorkspaceId,
  setPersonalWorkspaceId,
  setProfile,
  setUserDetails,
  setUserId,
  setCommunityWorkspaceId,
  setUserTutorials,
  getUserDetails,
  setIsConsents,
  setIsMobile,
  setUserType,
} from "../../utils/storage";
import {
  SIGN_IN,
  ERROR_EMAIL,
  ERROR_PASS,
  FLUSH_EMAIL,
  FLUSH_PASSWORD,
  UPDATE_USER_NAME,
  UPDATE_USER_PHOTO,
  ERROR_VALIDATE_EMAIL,
  IS_LOADING_VALIDATE_EMAIL,
  SUCCESS_VALIDATE_EMAIL,
  FLUSH_MESSAGE_VALIDATE_EMAIL,
  ERROR_RECOVER_PASSWORD,
  IS_LOADING_RECOVER_PASSWORD,
  SUCCESS_RECOVER_PASSWORD,
  FLUSH_MESSAGE_RECOVER_PASSWORD,
  IS_LOADING_UPDATE_USER_PHOTO,
  UPDATE_SUBSCRIPTION
} from "../types/loginTypes";
import Service from "../../service/generic/generic";
import { setHelperPanel } from "../appState/global/globalActions";
import { handleCheckScreen } from "../../utils/helpersFunction";
import { useApi } from "../../utils/useAPI";
import { getUserProfileAction } from "./userAction";
import { errorSubscription } from "../../utils/genericHandler";
import TagManagerService from "../../service/tagManagerService";

const service = new Service("/auth/signin");
const userService = new Service("user");
const tagManagerService = TagManagerService();

function proceedLogin(dataLogin: any, dispatch: Dispatch) {
  const {
    jwtToken,
    expiredAt,
    communityWorkspaceId,
    enterpriseWorkspaceId,
    personalWorkspaceId,
    name,
    id,
    roles,
    profilePictureUrl,
    tutorials,
    email,
    subscription,
    userConsents,
    // Ace Enterprise
    companyId,
  } = dataLogin;
  setToken(jwtToken);
  setProfile(roles);
  setUserId(id);
  setUserDetails({ name, profilePictureUrl, email, subscription, companyId });
  setCommunityWorkspaceId(communityWorkspaceId);
  setEnterpriseWorkspaceId(enterpriseWorkspaceId);
  setPersonalWorkspaceId(personalWorkspaceId);
  setCommunityWorkspaceId(communityWorkspaceId);
  setIsMobile(handleCheckScreen());
  setExpiredToken(expiredAt);
  setCommunityWorkspaceId(communityWorkspaceId);
  setUserTutorials(tutorials);
  setIsConsents(userConsents.softwareLicenseAgreement);
  setUserType(/ACE/.test(subscription?.plan) ? subscription?.plan : 'REGULAR');
  getUserProfileAction();

  tagManagerService.login();

  dispatch({
    type: SIGN_IN,
    payload: {
      token: jwtToken,
      enterpriseWorkspaceId: enterpriseWorkspaceId,
      personalWorkspaceId: personalWorkspaceId,
      communityWorkspaceId: communityWorkspaceId,
      isErrorPass: false,
      isErrorEmail: false,
      failedEmailMessage: '',
      failedPasswordMessage: '',
      userDetails: name,
      userPhoto: profilePictureUrl ? profilePictureUrl +
        '?timestamp=' +
        new Date().getTime()
        : '',
      email: email,
      subscription: subscription,
      userType: /ACE/.test(subscription?.plan) ? subscription?.plan : 'REGULAR',
      companyId: companyId,
    },
  });
}

export function signIn(email: string, password: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await service.post({ email, password });
      proceedLogin(response, dispatch);
    } catch (err: any) {
      const { message, error } = err?.response?.data;
      if (message?.indexOf("password") >= 0) {
        dispatch(errorPassword(error?.password, true));
        dispatch(flushStateEmail("", false));
      } else if (message?.indexOf("email") >= 0) {
        dispatch(errorEmail(error?.email, true));
        dispatch(flushStatePassword("", false));
      }
    }
  };
}

export function updateUserName(name: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_USER_NAME,
      name,
    });
  };
}

export function UpdateSubscription(subscription: Record<string, unknown>) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      subscription,
    });
  };
}

export function updateProfilePhoto(url: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_USER_PHOTO,
      url,
    });
  };
}

export function loadingProfilePhoto(loading: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: IS_LOADING_UPDATE_USER_PHOTO,
      loading
    });
  };
}

export function recoverPassword(email: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IS_LOADING_RECOVER_PASSWORD,
        isLoading: true
      });
      const serviceRecover = new Service("/user/forgot-password");
      const { message } = await serviceRecover.put('', { email });
      dispatch({
        type: SUCCESS_RECOVER_PASSWORD,
        messageSuccess: 'New Password has been sent to your email'
      });
    } catch (err: any) {
      const { message } = err?.response?.data;
      dispatch({
        type: ERROR_RECOVER_PASSWORD,
        messageError: message
      });
    }
  };
}

export const updateGradeAccountAction = (plan: Record<string, unknown>) => {
  return async (dispatch: Dispatch) => {
    try {
      const userDetail = getUserDetails()
      const serviceSubscription = new Service("/subscription");
      await serviceSubscription.put('', plan);
      const response = await useApi(
        `user_profile`,
        () => userService.get("profile"),
        true
      );
      dispatch({
        type: UPDATE_SUBSCRIPTION,
        subscription: (response?.data as any)?.subscription,
      });
      setUserDetails({
        ...userDetail,
        subscription: {
          ...userDetail.subscription,
          ...(response?.data as any).subscription
        }
      });
    } catch (error: any) {
      console.error(error);
      errorSubscription(error);
    }
  };
};

export function validateEmail(email: string, existsTrue = true) {
  return async (dispatch: Dispatch) => {
    try {
      const url = `/users/email?existsTrue=${existsTrue}`;
      dispatch({
        type: IS_LOADING_VALIDATE_EMAIL,
        isLoading: true
      });
      const serviceValidateEmail = new Service(url);
      const { message } = await serviceValidateEmail.post({ email });
      dispatch({
        type: SUCCESS_VALIDATE_EMAIL,
        messageSuccess: message
      });
    } catch (err: any) {
      const { message } = err?.response?.data;
      dispatch({
        type: ERROR_VALIDATE_EMAIL,
        messageError: message
      });
    }
  };
}

function errorEmail(message: string, isErrorEmail: boolean) {
  return { type: ERROR_EMAIL, message, isErrorEmail };
}

function errorPassword(message: string, isErrorPass: boolean) {
  return { type: ERROR_PASS, message, isErrorPass };
}

function flushStateEmail(message: string, isErrorEmail: boolean) {
  return { type: FLUSH_EMAIL, message, isErrorEmail };
}

function flushStatePassword(message: string, isErrorPass: boolean) {
  return { type: FLUSH_PASSWORD, message, isErrorPass };
}

export function flushMessageValidateEmail() {
  return { type: FLUSH_MESSAGE_VALIDATE_EMAIL };
}

export function flushMessageRecoverPassword() {
  return { type: FLUSH_MESSAGE_RECOVER_PASSWORD };
}

export const showSoftwareLicenseAgreement = async (dispatch: Dispatch, policyId: string) => {
  try {
    const service = new Service(`policy/latest`);
    const response: any = await service.get();
    const payload: any = response.data;
    const data = {
      isOpen: true,
      type: "SLA",
      payload: {
        ...payload,
        policyId: policyId
      }
    };
    return dispatch(setHelperPanel(data));
  } catch (error: any) {
    return Promise.resolve(error);
  }
};

export const getCountryCode = async () => {
  try {
    const countryCodeService = new Service(`phone-country-codes`);
    const response = await countryCodeService.getCountryCode();
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.resolve(error);
  }
};
