import styled from "styled-components";

interface IContentProps {
  isExpanded?: boolean;
  isLast?: boolean;
}

export const InfoPanelWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  // alignItems: "flex-start",
  // padding: "24px",
  // width: "256px",
  width: "280px",
  background: "white",
  borderLeft: "1px solid #E0E6EB",
  wordWrap: "break-word",
  overflow: "auto",
  height: "100%",
});

export const HeadlineWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  marginBottom: "24px",
  justifyContent: "space-between",
  svg: {
    cursor: 'pointer',
    color: '#B3BFCC'
  }
});

export const InfoPaneItemlWrapper = styled.div({
  padding: "24px",
  borderBottom: "1px solid #E0E6EB",
});

export const DividerInfoPanel = styled.div({
  width: "100%",
  borderTop: "1px solid  #D6DFEB",
});

export const HeadlineTitle = styled.div({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "10px",
  color: "#A3B3C2",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  lineHeight: "16px",
});

export const HeadlineDetail = styled.div({
  fontFamily: "inter",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "17px",
  marginTop: "4px",
  width: "100%",
  svg: {
    marginLeft: "9px",
  },
  p: {
    fontWeight: "normal",
    letterSpacing: "0.25px",
    lineHeight: "20px",
    marginTop: "9.5px",
    textTransform: "none",
  },
  h1: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#A3B3C2",
  },
});

export const Content = styled.div<IContentProps>(
  ({ isExpanded = true, isLast = false }) => ({
    // marginTop: "27.5px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    h1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0/75px",
      // textTransform: "uppercase",
      width: "100%",
      marginBottom: "19.5px",
    },
    button: {
      width: "100%",
      marginBottom: "4px",
      marginTop: "4px",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#118CFF",
        color: "#FFFFFF",
      },
    },
    "& div: nth-of-type(1)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "> div:first-child": {
      cursor: "pointer",
    },
    borderBottom: isLast ? "none" : "1px solid #E0E6EB",
    boxSizing: "border-box",
  })
);

export const ContentDetail = styled.div({
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  
});

export const ContentDetailItem = styled.span({
  marginBottom: "1.5rem",
});


interface IValueLabelProps {
  isBool: boolean;
}
export const ContentDetailValueLabel = styled.span<IValueLabelProps>(
  (props) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4px 8px",

    position: "static",
    width: "fit-content",
    height: "24px",
    left: "0px",
    top: "20px",

    background: props.isBool ? "#E3F3FF" : "#FBE5EA",
    borderRadius: "15px",

    flex: "none",
    order: 1,
    flexGrow: 0,
    margin: "4px 0px",

    color: props.isBool ? "#118CFF" : "#DE294C",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "20px",
  })
);

export const InfoPanelHelperWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "24px",
  width: "292px",
  background: "white",
  borderLeft: "1px solid #E0E6EB",
  wordWrap: "break-word",
  overflow: "auto",
  height: "100%",
  borderRadius: "4px",
});

export const HelperContent = styled.div({
  marginTop: "24px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "& > .subContent": {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    padding: "16px 6px",
    height: "32px",
  },
  "& > .subContent .number": {
    paddingTop: "24px",
  },
  "& > #article": {
    display: "flex",
    padding: "32px 6px",
  },
  h1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0/75px",
    textTransform: "uppercase",
    color: "#353C68",
    width: "100%",
    marginBottom: "19.5px",
  },
  " > img": {
    height: 162,
    width: 278,
    objectFit: "cover",
    borderRadius: "8px",
    alignSelf: "center",
  },
  "& div: nth-of-type(1)": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "> div:first-child": {
    cursor: "pointer",
  },
  h2: {
    width: "full",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    color: "#9A9DB3",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    marginBottom: "24px",
    lineHeight: "16px",
    alignSelf: "center",
    top: "12px",
  },
});

export const ArticleCount = styled.div({
  width: "full",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  color: "#9A9DB3",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  marginBottom: "24px",
  lineHeight: "16px",
  alignSelf: "center",
});

export const HelperTitle = styled.div({
  width: "full",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "10px",
  color: "#9A9DB3",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  lineHeight: "16px",
});

export const ActionsWrapper = styled.div({
  marginTop: "1.5rem"
});
