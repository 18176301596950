import { createToken, Lexer, TokenType } from "chevrotain";

enum TokenName {
    String = "String",
    Superscript = "Superscript",
    Subscript = "Subscript",
    WhiteSpace = "WhiteSpace",
    Symbol = "Symbol",
}

const String = createToken({
    name: TokenName.String,
    pattern: /[a-zA-Z0-9]+[0-9]*/,
});

const Superscript = createToken({
    name: TokenName.Superscript,
    pattern: /<sup>(.*?)<\/sup>/
});

const Subscript = createToken({
    name: TokenName.Subscript,
    pattern: /<sub>(.*?)<\/sub>/
});

const WhiteSpace = createToken({
    name: TokenName.WhiteSpace,
    pattern: /\s+/,
    group: Lexer.SKIPPED
});

const allTokens = [
    WhiteSpace,
    Superscript,
    Subscript,
    String,
];

export type TokenTypeDict = { [key in any]: TokenType };

export const generateToken = (data: any) => {
    const symbols = data.map((symbol: any) => symbol.glyph).join('');
    const Symbol: any = createToken({
        name: TokenName.Symbol,
        pattern: new RegExp(`[\\${symbols}]+`),
    });
    if (!allTokens.find(token => token.name == TokenName.Symbol)) {
        allTokens.push(Symbol);
    }
    return allTokens;
};

export const getTokenDict = (tokens: any) => {
    const dict: TokenTypeDict = tokens.reduce(
        (acc: any, tokenType: any) => {
            acc[tokenType.name] = tokenType;
            return acc;
        },
        {} as any
    );
    return dict;
};

export const getLexer = (tokens: TokenType[]) => {
    return new Lexer(tokens, {
        ensureOptimizations: true
    });
};