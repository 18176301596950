import {
  InfoPanelActionSection,
  InfoPanelDetailSection,
  InfoPanelHeadline,
} from "../molecules/infoPanel/components";
import { getActorName, getDate, getValue } from "../molecules/infoPanel/utils";
import { getPersonalWorkspaceId, getUserDetails, getUserProfile } from "../../utils/storage";

import { InfoPanelWrapper } from "../molecules/infoPanel/elements";
import { checkPath } from "../../utils/helpersFunction";
import { useState } from "react";

type StateShow = {
  [key: string]: boolean;
};

export default function CommunityInfoPanel({
  activeItem,
  location,
  handleDelete,
  handleCopyToMyCalc,
  handlePreviewCalculation
}: any) {
  const isPersonalCalculation = checkPath(location.pathname, "my-calculation");
  const userRole = getUserProfile();
  const userDetail = getUserDetails();
  const [isDetailShow, setIsDetailShow] = useState<StateShow>({
    openAction: true,
    openData: true,
    openOrganize: true,
    openDetail: true,
  });

  const handleEdit = () => { };

  const handleToggleDetailShow = (name: string) => () => {
    setIsDetailShow((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  async function deleteCommunityHandler() {
    handleDelete([activeItem.id]);
  }

  async function handleCommunityCopy() {
    handleCopyToMyCalc(activeItem);
  }

  async function previewCalculation() {
    handlePreviewCalculation({
      ...activeItem,
      location: getPersonalWorkspaceId()
    });
  }

  const isCreator = activeItem?.createdBy?.name == userDetail?.name;
  const basicActions = /REKAVA_ADMIN|REKAVA/.test(String(userRole)) || isCreator
    ? [
      {
        color: "primary",
        variant: "contained",
        label: "COPY TO MY CALC",
        onClick: handleCommunityCopy,
        dataTestid: "btnCopyToMyCalc"
      },
      {
        color: "secondary",
        variant: "contained",
        label: "Preview Calculation",
        onClick: previewCalculation,
        dataTestid: "btnCopyToMyCalc"
      },
      {
        color: "error",
        variant: "contained",
        label: "DELETE",
        onClick: deleteCommunityHandler,
        dataTestid: "btnDelete"
      },
    ]
    : [
      {
        color: "primary",
        variant: "contained",
        label: "COPY TO MY CALC",
        onClick: handleCommunityCopy,
        dataTestid: "btnCopyToMyCalc"
      },
      {
        color: "secondary",
        variant: "contained",
        label: "Preview Calculation",
        onClick: previewCalculation,
        dataTestid: "btnCopyToMyCalc"
      },
    ];

  const detailInfo = [
    { key: "Originator", value: [getValue(activeItem?.createdBy?.name)] },
    {
      key: "Last Modified",
      value: [
        getDate(activeItem?.updatedAt),
        getActorName(activeItem?.updatedBy),
      ],
    },
  ];

  return (
    <div>
      <InfoPanelWrapper>
        <InfoPanelHeadline
          title="CALCULATION INFO"
          onEdit={handleEdit}
          name={activeItem ? activeItem.title : activeItem.title}
          description={activeItem ? activeItem.description : ''}
          isEditable={isPersonalCalculation}
        />
        <InfoPanelActionSection
          expanded={isDetailShow.openAction}
          onToggle={handleToggleDetailShow('openAction')}
          title="Actions"
          actions={basicActions}
        />
        <InfoPanelDetailSection
          expanded={isDetailShow.openDetail}
          onToggle={handleToggleDetailShow('openDetail')}
          title="DETAILS"
          info={detailInfo}
        />
      </InfoPanelWrapper>
    </div>
  );
}
