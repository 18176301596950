import React, { useState } from "react";
import { Divider, Menu, MenuItem } from "@mui/material";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import StyledToggle from "../../atoms/Toggle";
import { InlineMenuItem, StyledMenuList } from "./styles";

interface MenuListPropsI {
    content: any[];
    open: boolean;
    handleClose: () => void;
    handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function MenuList({ content, open, handleClose, handleClick }: MenuListPropsI) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openNested = Boolean(anchorEl);
    const handleClickNested = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseNested = () => {
        setAnchorEl(null);
    };
    return (
        <StyledMenuList
            id="composition-menu"
            aria-labelledby="composition-button"
        >
            {content.map((item: any) => (
                item.type === "normal" ? (
                    <MenuItem key={item.name} onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleClose();
                        handleClick && handleClick(e);
                    }}>
                        <Text variant='body3' sx={{lineHeight: 2}}>{item.label}</Text>
                    </MenuItem>
                ) : item.type === "divider" ? (
                    <Divider key={item.name}/>
                ) : item.type === "radio" ? (
                    <MenuItem key={item.name}>
                        <InlineMenuItem>
                            <Text variant='body3'>{item.label}</Text>
                            <StyledToggle />
                        </InlineMenuItem>
                    </MenuItem>
                ) : item.type === 'extend' ? (
                    <div key={item.name}>
                        <div
                            id="nested-button"
                            aria-controls={openNested ? 'nested-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClickNested}
                        >
                            <MenuItem>
                                <InlineMenuItem>
                                    <Text variant='body3'>{item.label}</Text>
                                    <Icon icon="chevronRight" />
                                </InlineMenuItem>
                            </MenuItem>
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openNested}
                            onClose={handleCloseNested}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
                                    mt: -1,
                                    ml: .5
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuList content={item.content} open={openNested} handleClose={handleCloseNested} handleClick={(e: React.MouseEvent<HTMLElement>) => {
                                handleClose();
                                handleClick && handleClick(e);
                            }}/>
                        </Menu>     
                    </div>
                ) : null
            ))}
        </StyledMenuList>
    );
}