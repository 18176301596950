import { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { PrintArea } from '../../pageElements/printView';
import { ComponentToPrint } from '../../pages/printView/components/ComponentToPrint';
import ReportService from '../../service/ReportServices';
import { ReduxState } from '../../store';
import { loadReport } from '../../store/actions/reportAction';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { isoToLocalDateTime } from '../../utils/dateConvert';
import { checkSubscriptionAndDate } from '../../utils/helpersFunction';
import { getUserId } from '../../utils/storage';
import { LoadingSpinner } from '../atoms/loading';
import InfoPanelActionSection from '../molecules/infoPanel/components/InfoPanelActionSection';
import InfoPanelDetailSection from '../molecules/infoPanel/components/InfoPanelDetailSection';
import InfoPanelHeadline from '../molecules/infoPanel/components/InfoPanelHeadline';
import { InfoPanelWrapper } from '../molecules/infoPanel/elements';
import { getActorName, getValue } from '../molecules/infoPanel/utils';

type StateShow = {
  [key: string]: boolean;
};

interface PrintDataI {
  data: any;
  reportFormat: Array<string>;
  selectedPage: any;
}

interface IProps {
  info: any;
  calculation: any;
  handleRename: (payload: any) => void;
  handleDelete: (payload: any) => void;
  handleDownload: (payload: any) => void;
  gtmService: any
}
export default function ReportInfoPanel(props: IProps) {
  const { calculation, info, handleRename, handleDelete, handleDownload } =
    props;
  const history = useHistory();
  const dispatch = useDispatch();
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
  location.state?.calculationType === 'BUSINESS';
  const isUserReport = getUserId() === info.inputFileInfo.creator.id;
  const [isDetailShow, setIsDetailShow] = useState<StateShow>({
    details: true,
    actions: true,
    inputFile: true,
    calculationInfo: true
  });
  const [printData, setPrintData] = useState<PrintDataI>({
    data: {},
    reportFormat: [],
    selectedPage: []
  });
  const [printLoading, setPrintLoading] = useState<boolean>(false);
  const componentRef = useRef<any>();
  const { subscription } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const handleToggleDetail = (name: string) => () => {
    setIsDetailShow(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const handleEdit = () => { };

  const handleToPrintView = () => {
    const isUserReport = Boolean(getUserId() === info?.inputFileInfo.creator.id);
    if (isUserReport || !isDriveCalculation) {
      history.push({
        pathname:  `/print-view/${calculation.calculationId}/${info?.inputFileInfo?.inputFileId}/${info?.id}`,
        state: {
          ...(isDriveCalculation && { calculationType: 'BUSINESS' })
        }
      });
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      dispatch(formModalSetupActionPopUp("NOT_ALLOWED_OPEN_REPORT"));
    }
  };

  const handleRenameReport = () => {
    const payload = {
      reportId: info.id,
      reportTitle: info.title,
      calculationId: calculation.calculationId
    };
    handleRename(payload);
  };

  const handleDeleteReport = () => {
    const payload = {
      reportId: info.id,
      reportTitle: info.title,
      calculationId: calculation.calculationId
    };
    handleDelete(payload);
  };

  const handlerPdfDownload = async () => {
    try {
      if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
        setPrintLoading(true);
        const dataReport: any = await dispatch(loadReport(info.id));
        const pages: any = dataReport.inputFile.pages.map(
          (item: any, idx: number) => {
            return {
              ...item,
              idx
            };
          }
        );
        setPrintData({
          data: dataReport,
          reportFormat: dataReport.format.latex ? ['Latex Formulas'] : [],
          selectedPage: dataReport.format.pages.map(
            (item: number, idx: number) => {
              const page: any = pages.find((el: any, i: number) => item === i);
              return `${page.pageTitle} ${page.idx}`;
            }
          )
        });
        setPrintLoading(false);
        setTimeout(() => {
          !printLoading && printHandler();
        }, 1000);
        ReportService.reportDownloaded(info.id);
      }
    } catch (error: any) {
      console.error(error);
    }
  };


  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  const basicActions = [
    {
      color: 'primary',
      variant: 'contained',
      label: 'OPEN REPORT',
      subLabel: 'Go to Print View',
      onClick: handleToPrintView,
      dataTestid: 'btnOpenReport'
    },
    {
      color: 'secondary',
      variant: 'contained',
      label: 'RENAME',
      onClick: handleRenameReport,
      dataTestid: 'btnRename',
      disabled: isDriveCalculation && !isUserReport,
    },
    // { color: "secondary", variant: "contained", label: "SHARE", dataTestid: "btnShare" },
    // { color: "secondary", variant: "contained", label: "PUBLISH", dataTestid: "btnPublish" },
    {
      color: 'error',
      variant: 'contained',
      label: 'DELETE',
      onClick: handleDeleteReport,
      dataTestid: 'btnDelete',
      disabled: isDriveCalculation && !isUserReport
    }
  ];

  // have its own here since dates in report info response (only) has been updated to iso
  // can adapt this function to InfoPanel utils when all dates used in info panels been updated to iso as well
  function getDate(dateTime: string) {
    return getValue(dateTime ? isoToLocalDateTime(dateTime) : '-');
  }

  const detailInfo = [
    { key: 'Report', value: [getValue(info.title)] },
    {
      key: 'Printed Date'
      // value: [getDate(info.printedAt), getActorName(info.printedBy)],
    },
    // TODO: use the one with actual data
    // { key: 'Comments', value: [getValue(info.comments)] },
    // { key: 'Approved', value: [getLabel(info.isApproved)] }

    // note: Product's request to hardcode them, so QA know the related features are under development
    { key: 'Comments', value: [getValue('-')] },
    { key: 'Approved', value: [getValue('-')] }
  ];

  const inputFileInfo = [
    {
      key: 'Input File Name',
      value: [getValue(info.inputFileInfo.inputFileTitle)],
      isBoldValue: true
    },
    {
      key: 'Created Date',
      value: [
        getDate(info.inputFileInfo?.createdTime),
        getActorName(info.inputFileInfo?.creator)
      ]
    },
    {
      key: 'Last Modified',
      value: [
        info.inputFileInfo?.updatedTime
          ? getDate(info.inputFileInfo?.updatedTime)
          : getDate(info.inputFileInfo?.createdTime),
        info.inputFileInfo?.updater
          ? getActorName(info.inputFileInfo?.updater)
          : getActorName(info.inputFileInfo?.creator)
      ]
    }
    // { key: "Completed", value: [getLabel(info.inputFileInfo.isComplete)] },
  ];

  const calculationInfo = [
    // {
    // key: "Originator",
    // value: [getActorName(info.calculationInfo.createdBy)],
    // },
    // { key: "Principal", value: [getActorName(info.calculationInfo.principal)] },
    // {
    //   key: "Last Modified",
    //   value: [
    //     getDate(info.calculationInfo.updatedAt),
    //     getActorName(info.calculationInfo.updatedBy),
    //   ],
    // },
  ];

  return (
    <div>
      {printLoading && <LoadingSpinner />}
      <InfoPanelWrapper>
        <InfoPanelHeadline
          title="REPORT INFO"
          onEdit={handleEdit}
          name="Reports Name"
          description={info.title}
          isEditable={false}
          nameTitle={{
            title: 'Reports Name',
            nameInfoTitle: info.title,
            isBoldNameInfo: true
          }}
        />
        {/* <InfoPanelDetailSection
          expanded={isDetailShow.details}
          onToggle={handleToggleDetail("details")}
          title="DETAILS"
          info={detailInfo}
        /> */}
        <InfoPanelActionSection
          expanded={isDetailShow.actions}
          onToggle={handleToggleDetail('actions')}
          title="ACTIONS"
          actions={basicActions}
        />
        <InfoPanelDetailSection
          expanded={isDetailShow.inputFile}
          onToggle={handleToggleDetail('inputFile')}
          title="INPUT FILES INFO"
          info={inputFileInfo}
        />
        {/* <InfoPanelDetailSection
          expanded={isDetailShow.calculationInfo}
          onToggle={handleToggleDetail("calculationInfo")}
          title="CALCULATION INFO"
          info={calculationInfo}
        /> */}
      </InfoPanelWrapper>
      <PrintArea>
        <ComponentToPrint
          ref={componentRef}
          data={printData.data}
          reportFormat={printData.reportFormat}
          selectedPage={printData.selectedPage}
          componentDimensionAll={[]}
          setReadyToPrint={() => undefined}
        />
      </PrintArea>
    </div>
  );
}
