import { Body, Section } from "../../../pageElements/printViewPage";

import images from "../../../assets";
import parser from 'html-react-parser';
import { forwardRef } from "react";

export const InputImage = forwardRef<any, any>((props, ref) => {
  return (
    <Body ref={ref}>
      <Section>
        <div
          style={{
            display: "block",
            flexDirection: "column",
            alignItems: props.data.properties.alignment?.toLowerCase() === "right"
              ? "flex-end"
              : props.data.properties.alignment?.toLowerCase() === "left"
                ? "flex-start" : "center",
            padding: "20px 0",
          }}
        >
          <img
            style={{
              width: props.data.properties.width === null ? 400
                : props.data.properties.width > 500
                  ? 'auto'
                  : props.data.properties.width,
              height: props.data.properties.height === null ? 264
                : props.data.properties.height,

            }}
            src={props.data.properties.imageUrl === null ? images.noImage : props.data.properties.imageUrl + `?noCache=` + Math.random().toString()} />

          <section style={{ display: 'flex' }}>
            {props.data.properties.description ? parser(props.data.properties.description) : ""}
          </section>

        </div>
      </Section>
    </Body>
  );
});
