import { useLayoutEffect, useRef } from 'react'

export default function useResizeObserver<T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void
) {
  const elementRef = useRef<T>(null);

  useLayoutEffect(() => {
    const element = elementRef?.current
    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0])
    })

    observer.observe(element);

    return () => {
      observer.disconnect();
    }
  }, [callback, elementRef]);

  return elementRef;
}
