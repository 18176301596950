export const SIGN_IN = "LOGIN";
export const SIGN_OUT = "LOGOUT";
export const ERROR_EMAIL = "ERROR_EMAIL";
export const ERROR_PASS = "ERROR_PASSWORD";
export const FLUSH_EMAIL = "FLUSH_EMAIL";
export const FLUSH_PASSWORD = "FLUSH_PASSWORD";
export const UPDATE_USER_NAME = "USER_NAME";
export const UPDATE_USER_PHOTO = "USER_PHOTO";
export const IS_LOADING_UPDATE_USER_PHOTO = "IS_LOADING_UPDATE_USER_PHOTO";
export const IS_LOADING_RECOVER_PASSWORD = "IS_LOADING_RECOVER_PASSWORD";
export const ERROR_RECOVER_PASSWORD = "ERROR_RECOVER_PASSWORD";
export const SUCCESS_RECOVER_PASSWORD = "SUCCESS_RECOVER_PASSWORD";
export const FLUSH_MESSAGE_RECOVER_PASSWORD = "FLUSH_MESSAGE_RECOVER_PASSWORD";
export const IS_LOADING_VALIDATE_EMAIL = "IS_LOADING_VALIDATE_EMAIL";
export const ERROR_VALIDATE_EMAIL = "ERRROR_VALIDATE_EMAIL";
export const SUCCESS_VALIDATE_EMAIL = "SUCCESS_VALIDATE_EMAIL";
export const FLUSH_MESSAGE_VALIDATE_EMAIL = "FLUSH_MESSAGE_VALIDATE_EMAIL";
export const IS_LOADING_REGISTER = "IS_LOADING_REGISTER";
export const ERROR_REGISTER = "ERROR_REGISTER";
export const SUCCESS_REGISTER = "SUCCESS_REGISTER";
export const FLUSH_MESSAGE_REGISTER = "FLUSH_MESSAGE_REGISTER";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export interface LoginAction {
  type: typeof SIGN_IN;
  payload?: {
    token: any;
    personalWorkspaceId: any;
    enterpriseWorkspaceId: any;
    communityWorkspaceId: any;
    isErrorEmail: boolean;
    isErrorPass: boolean;
    failedEmailMessage: string;
    failedPasswordMessage: string;
    userDetails: string;
    userPhoto: string;
    email: string,
    subscription: Record<string, unknown>;
    userType: string;
  };
}

export interface LogOutAction {
  type: typeof SIGN_OUT;
  payload?: string;
}

export interface LogOutAction {
  type: typeof SIGN_OUT;
  payload?: string;
}
export interface ErrorEmail {
  type: typeof ERROR_EMAIL;
  message: string;
  isErrorEmail: boolean;
}

export interface ErrorPassword {
  type: typeof ERROR_PASS;
  message: string;
  isErrorPass: boolean;
}

export interface FlushEmail {
  type: typeof FLUSH_EMAIL;
  message: string;
  isErrorEmail: boolean;
}

export interface FlushPassword {
  type: typeof FLUSH_PASSWORD;
  message: string;
  isErrorPass: boolean;
}

export interface UpdateUserName {
  type: typeof UPDATE_USER_NAME;
  name: string;
}

export interface UpdateUserPhoto {
  type: typeof UPDATE_USER_PHOTO;
  url: string;
  loading: boolean
}

export interface LoadingUpdateUserPhoto {
  type: typeof IS_LOADING_UPDATE_USER_PHOTO
  loading: boolean
}


export interface LoadingValidateEmail {
  type: typeof IS_LOADING_VALIDATE_EMAIL;
  isLoading: boolean;
}

export interface ErrorValidateEmail {
  type: typeof ERROR_VALIDATE_EMAIL;
  messageError: string;
}

export interface SuccessValidateEmail {
  type: typeof SUCCESS_VALIDATE_EMAIL;
  messageSuccess: string;
}

export interface FlushMessageValidateEmail {
  type: typeof FLUSH_MESSAGE_VALIDATE_EMAIL;
}

export interface LoadingRecoverPassword {
  type: typeof IS_LOADING_RECOVER_PASSWORD;
  isLoading: boolean;
}

export interface ErrorRecoverPassword {
  type: typeof ERROR_RECOVER_PASSWORD;
  messageError: string;
}

export interface SuccessRecoverPassword {
  type: typeof SUCCESS_RECOVER_PASSWORD;
  messageSuccess: string;
}

export interface FlushMessageRecoverPassword {
  type: typeof FLUSH_MESSAGE_RECOVER_PASSWORD;
}

export interface LoadingRegister {
  type: typeof IS_LOADING_REGISTER;
  isLoading: boolean;
}

export interface ErrorRegister {
  type: typeof ERROR_REGISTER;
  messageError: string;
}

export interface SuccessRegister {
  type: typeof SUCCESS_REGISTER;
  messageSuccess: string;
}

export interface FlushMessageRegister {
  type: typeof FLUSH_MESSAGE_REGISTER;
}

export interface UpdateSubscription {
  type: typeof UPDATE_SUBSCRIPTION;
  subscription: any
}

