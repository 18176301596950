import Text from '../../atoms/Text';
import parser from 'html-react-parser';
import { mapLevelToVariant } from '../../../pages/loggerRefactor/utils';
import { TextBox } from './styles';
import { forwardRef } from 'react';
import RichText from '../RichText/RichText';

export const LabelText = forwardRef<any, any>((props, ref) => {
  const { level, description } = props.data.properties;
  return (
    <TextBox ref={ref}>
      {level === 'NORMAL' ? (
        <RichText
          source={description}
          readonly={true}
          style={{ border: 'none', padding: '4px 0' }}
        />
      ) : (
        <Text variant={mapLevelToVariant[level]}>
          {description ? parser(description) : description}
        </Text>
      )}
    </TextBox>
  );
});
