import { Lexer } from "chevrotain";
import { generateToken, getLexer } from "./lexer";

export class sliceEngine {
  private lexer: Lexer;

  constructor(data: Array<symbol>) {
    const tokens = generateToken(data);
    this.lexer = getLexer(tokens);
  }

  exec(formula: string) {
    const lexRes: { [key: string]: any } = this.lexer.tokenize(formula);
    if (lexRes.errors.length > 0) return "ERROR";
    return lexRes.tokens.map((value: { [key: string]: any }) => {
      return value.image;
    });
  }
}