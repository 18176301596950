import React from "react";
import { Container } from './elements';

interface TooltipIconPropsI extends React.HTMLAttributes<HTMLDivElement>{
  symbol: string
}

const TooltipIcon = React.forwardRef(function CustomTooltipIcon(
  props: TooltipIconPropsI,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Container ref={ref} {...props}>
      <div>{props.symbol}</div>
    </Container>
  );
});

export default TooltipIcon;