import { useEffect, useState, KeyboardEvent } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ReduxState } from '../store';
import images from '../assets';
import {
  Container,
  StaticSection,
  FormSection,
  ButtonWrapper,
  LeftSection,
  HeaderTitle,
} from '../pageElements/login';
import {
  recoverPassword,
  signIn,
  validateEmail,
  flushMessageValidateEmail,
  flushMessageRecoverPassword
} from '../store/actions/loginActions';
import { AnimatePresence, motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema, recoverSchema } from '../utils/validation';
import Loading from '../components/atoms/loading';
import StyledButton from '../components/atoms/Button';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material';
import Icon from '../components/atoms/Icon';
import Input from '../components/atoms/Input';
import { toastSetupActionPopUp } from '../store/appState/popUp/toast/toastAction';
import { getToken, getUserType } from '../utils/storage';
import UseWindowDimensions from '../utils/getWindows';

interface FormI {
  email: string;
}
interface LoginFormI extends FormI {
  password: string;
}

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isErrorPass,
    isErrorEmail,
    failedEmailMessage,
    failedPasswordMessage,
    isLoadingValidateEmail,
    messageSuccessValidateEmail,
    messageErrorValidateEmail,
    isLoadingRecoverPassword,
    messageSuccessRecoverPassword
  } = useSelector((state: ReduxState) => state.login, shallowEqual);
  const token = getToken();
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting }
  } = useForm<LoginFormI>({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit'
  });

  const {
    handleSubmit: handleRecover,
    control: controlRecover,
    setError: setErrorRecover,
    watch: watchRecover,
    formState: { errors: errorRecovers }
  } = useForm<FormI>({
    resolver: yupResolver(recoverSchema),
    reValidateMode: 'onChange'
  });

  const location = useLocation();

  const { width } = UseWindowDimensions();

  const [activePage, setActivePage] = useState<number>(0);
  const [isPasswordVisible, togglePasswordVisible] = useState<boolean>(false);
  const isMobile = !useMediaQuery('(min-width:1080px)');

  useEffect(() => {
    if (location.pathname.includes('/register')) {
      setActivePage(2);
    }
  }, []);

  useEffect(() => {
    if (token) {
      getUserType() === 'REGULAR' ? history.push('/dashboard') : history.push('/ace/enterprise');
    }
  }, [token, history]);

  const handleSignIn = () => {
    dispatch(signIn(watch('email'), watch('password')));
  };

  const onSubmit = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  const handleRecoverButton = (data: FormI) => {
    dispatch(validateEmail(data.email));
  };

  const renderStaticSection = (isMobile: boolean) =>
    !isMobile && (
      <StaticSection width={width}>
        <Link style={{ width: 120 }} href={'https://rekava.build'}>
          <img
            width={120}
            src={images.iconRekavaWhite}
            alt="rekava-logo"
            loading="lazy"
          />
        </Link>
        <div>
          <h3>Transform your construction journey.</h3>
          <p>ChatGPT for Construction.</p>
        </div>
      </StaticSection>
    );

  const renderLoginForm = (isMobile: boolean) => (
    <div style={{ padding: '0 20px' }}>
      {isMobile && (
        <div style={{ paddingTop: 20 }}>
          <img
            width={120}
            src={images.rekavaLogo}
            alt="rekava-logo"
            loading="lazy"
          />
        </div>
      )}
      <HeaderTitle margin={isMobile ? '2rem 0 1rem 0' : '6rem 0 1.5rem'}>
        Login
      </HeaderTitle>
      <InputLabel>Email</InputLabel>
      <Controller
        defaultValue=""
        name="email"
        control={control}
        render={({ field }) => {
          return (
            <Input
              {...field}
              type="email"
              variant="outlined"
              size="small"
              error={!!errors.email || isErrorEmail}
              helperText={
                (errors.email && errors.email?.message) ||
                (isErrorEmail && failedEmailMessage)
              }
              inputProps={{ 'data-testid': 'inputEmail' }}
            />
          );
        }}
      />
      <InputLabel>Password</InputLabel>
      <Controller
        defaultValue=""
        name="password"
        control={control}
        render={({ field }) => {
          return (
            <Input
              {...field}
              type={isPasswordVisible ? 'type' : 'password'}
              variant="outlined"
              size="small"
              style={{ marginBottom: '1.5rem' }}
              error={!!errors.password || isErrorPass}
              helperText={
                (errors.password && errors.password?.message) ||
                (isErrorPass && failedPasswordMessage)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => togglePasswordVisible(!isPasswordVisible)}
                      style={{ marginRight: '-.8rem' }}
                    >
                      {isPasswordVisible ? (
                        <Icon icon="eyeOn" />
                      ) : (
                        <Icon icon="eyeOff" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ 'data-testid': 'inputPassword' }}
            />
          );
        }}
      />
      <Typography
        variant="h5"
        onClick={() => setActivePage(1)}
        color="primary"
        style={{ paddingBottom: isMobile ? 30 : 0 }}
      >
        Forgot Password?
      </Typography>
      <ButtonWrapper>
        {/* <StyledButton
          color="primary"
          variant="outlined"
          fullWidth
          data-testid="btnSignUp"
        >
          REGISTER
        </StyledButton> */}
        <StyledButton
          color="primary"
          variant="contained"
          fullWidth
          disabled={isSubmitting}
          onClick={handleSubmit(handleSignIn)}
          data-testid="btnLogin"
        >
          {isSubmitting ? <Loading /> : 'LOGIN'}
        </StyledButton>
      </ButtonWrapper>
    </div>
  );

  const renderForgotPasswordForm = () => (
    <div style={{ padding: '0 20px' }}>
      {isMobile && (
        <div style={{ paddingTop: 20 }}>
          <img
            width={120}
            src={images.rekavaLogo}
            alt="rekava-logo"
            loading="lazy"
          />
        </div>
      )}
      <HeaderTitle margin={isMobile ? '2rem 0 1rem 0' : '-2.5rem 0 1rem'}>
        Forgot Password?
      </HeaderTitle>
      <Typography variant="body1">
        We will send you a link to change your password via email.
      </Typography>
      <InputLabel sx={{ marginTop: '2.25rem' }}>Email</InputLabel>
      <FormSection>
        <Controller
          defaultValue=""
          name="email"
          control={controlRecover}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ width: 310 }}
                type="email"
                variant="outlined"
                size="small"
                error={!!errorRecovers.email}
                helperText={errorRecovers.email && errorRecovers.email?.message}
              />
            );
          }}
        />
      </FormSection>
      <ButtonWrapper style={{ paddingTop: '3rem' }}>
        <StyledButton
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => setActivePage(0)}
          disabled={isLoadingValidateEmail || isLoadingRecoverPassword}
        >
          CANCEL
        </StyledButton>
        <StyledButton
          color="primary"
          variant="contained"
          fullWidth
          disabled={isLoadingValidateEmail || isLoadingRecoverPassword}
          onClick={handleRecover(handleRecoverButton)}
        >
          {isLoadingValidateEmail || isLoadingRecoverPassword ? (
            <Loading />
          ) : (
            'RECOVER'
          )}
        </StyledButton>
      </ButtonWrapper>
    </div>
  );

  useEffect(() => {
    if (messageErrorValidateEmail !== '' && activePage === 1) {
      setErrorRecover('email', {
        type: 'custom',
        message: messageErrorValidateEmail
      });
      dispatch(flushMessageValidateEmail());
    }
  }, [messageErrorValidateEmail]);

  useEffect(() => {
    if (messageSuccessValidateEmail !== '' && activePage === 1) {
      dispatch(recoverPassword(watchRecover('email')));
      dispatch(flushMessageValidateEmail());
    }
  }, [messageSuccessValidateEmail]);

  useEffect(() => {
    if (messageSuccessRecoverPassword !== '') {
      setActivePage(0);
      dispatch(
        toastSetupActionPopUp('INFO', messageSuccessRecoverPassword, {
          vertical: 'top',
          horizontal: 'right'
        })
      );
      dispatch(flushMessageRecoverPassword());
    }
  }, [messageSuccessRecoverPassword]);

  return (
    <Container isMobile={isMobile} onKeyPress={e => onSubmit(e)}>
      {renderStaticSection(isMobile)}
      <LeftSection width={width * .34} isMobile={isMobile}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={activePage}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.15 }}
          >
            {activePage === 0 && (
              <FormSection>{renderLoginForm(isMobile)}</FormSection>
            )}
            {activePage === 1 && (
              <FormSection>{renderForgotPasswordForm()}</FormSection>
            )}
          </motion.div>
        </AnimatePresence>
      </LeftSection>
    </Container>
  );
}
