import * as actions from "./type";

export interface BreadcrumbStateI {
  breadcrumb: Array<any>;
}

export const initialState: BreadcrumbStateI = {
  breadcrumb: []
};

export interface CalculationInfoI {
  id: string;
  title: string;
}

export const BREADCRUMB_PAGE_TYPE = {
  INPUT_FILE: 'INPUT_FILE',
  REPORT: 'REPORT',
  TEAM: 'TEAM',
  OTHER: 'OTHER'
};

const breadcrumbReducer = (state: BreadcrumbStateI = initialState, action: any) => {
  switch (action.type) {
    case actions.SET_BREADCRUMB:
      const { breadcrumb, type, calculation } = action.data;

      if (calculation !== null) {
        breadcrumb.push({
          id: calculation.id,
          name: calculation.title,
        });

        switch (type) {
          case BREADCRUMB_PAGE_TYPE.REPORT:
            breadcrumb.push({ id: '', name: 'Reports' });
            break;
          case BREADCRUMB_PAGE_TYPE.INPUT_FILE:
            breadcrumb.push({ id: '', name: 'Input Files' });
            break;
        }
      } else if (type === 'TEAM')  breadcrumb.unshift({ id: '', name: 'Business Drive' });

      return {
        ...state,
        breadcrumb,
      };

    default:
      return state;
  }
};

export default breadcrumbReducer;
