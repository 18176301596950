import React from 'react';
import { Skeleton } from '@mui/material';

interface ShimmerI {
    type: 'text';
    size: 'xs' | 's' | 'm' | 'l' | 'xl'
}

const mapSize: any = {
    xs: {
        height: "1em",
        width: "62px"
    },
    s: {
        height: "1em",
        width: "124px"
    },
    m: {
        height: "20px",
        width: "248px"
    },
    l: {
        height: "2em",
        width: "496px"
    },
    xl: {
        height: "3em",
        width: "992px"
    }
};

const Shimmer = ({ type, size }: ShimmerI) => {
    return (
        <>
            {type === 'text' ?
                <Skeleton variant="text" sx={{
                    fontSize: mapSize[size as keyof typeof mapSize].height,
                    width: mapSize[size as keyof typeof mapSize].width
                }} />
                :
                <></>}
        </>
    );
};

export default Shimmer;


