import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import TypographyComponent from '../../atoms/Typography';
import { MoreVert } from '@mui/icons-material';
import {
  headerIconStyle,
  moreVertStyle,
  tableRowStyle,
  triangleShapeUp,
  triangleShapeDown,
  TextRole,
  WrapperCenterCell
} from './element';
import { colorPaymentStatus } from '../../../utils/helpersFunction';

export default function AccountTable(props: any) {
  const {
    basicHeader,
    tableData,
    onRowClick,
    arrowOnClick,
    onMouseOut,
    onMouseOver,
    errMessage,
    page
  } = props;
  const createMarkup = (html: any) => {
    return { __html: html };
  };
  return (
    <div style={{ overflow: 'auto' }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={tableRowStyle}>
            {basicHeader.map((header: any, i: number) => {
              return (
                <TableCell
                  style={{
                    width: header.width + '%',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'auto',
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  }}
                >
                  <TypographyComponent variant="HEADING_6">
                    {header.label}
                  </TypographyComponent>
                  {header.icon === 'ARROW' && (
                    <Box sx={headerIconStyle}>
                      <div
                        style={triangleShapeUp}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onClick={e => arrowOnClick(e, 'ASC', basicHeader[i])}
                      />
                      <div
                        style={triangleShapeDown}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onClick={e => arrowOnClick(e, 'DESC', basicHeader[i])}
                      />
                    </Box>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData?.map((row: any, i: number) => {
              return (
                <TableRow
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  key={i}
                >
                  {Object.keys(row)
                    .splice(4, 7)
                    .map((key: any, j: number) => {
                      return (
                        <TableCell
                          style={{
                            width: basicHeader[j].width + '%',
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'auto',
                            overflowWrap: 'anywhere'
                          }}
                          key={j}
                        >
                          {basicHeader[j].label === 'Payment Status' ? (
                            <TypographyComponent
                              variant={'SUBTITLE_2'}
                              style={{ color: colorPaymentStatus(row[key]) }}
                            >
                              {row[key]}
                            </TypographyComponent>
                          ) : (
                            <TypographyComponent
                              variant={'SUBTITLE_2'}
                              dangerouslySetInnerHTML={createMarkup(row[key])}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  <TableCell
                    style={{
                      width: basicHeader[basicHeader.length - 1]?.width + '%',
                      justifyContent: 'center',
                      alignItems: ' center'
                    }}
                    onClick={e => onRowClick(e, i, row, page)}
                  >
                    <WrapperCenterCell type="ICON">
                      <Box style={moreVertStyle}>
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </Box>
                    </WrapperCenterCell>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableCell
              style={{
                border: '1px solid #E0E6EB',
                display: 'flex'
              }}
            >
              <WrapperCenterCell type="NO_RESULT">
                <TypographyComponent variant={'SUBTITLE_2'}>
                  {errMessage}
                </TypographyComponent>
              </WrapperCenterCell>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
