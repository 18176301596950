import React, { Fragment } from 'react';
import {
  getPages,
  loadReport,
  saveReport,
  summaryReport
} from '../../../store/actions/reportAction';

import { ReduxState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchDataInputFile } from '../../../store/actions/loggerAction';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';

// These props will be injected into the wrapped component
interface ConnectedProps {
  handleModalPopup: (type: string, payload?: any) => void;
  handleLoadReport: (reportId: string) => void;
  handleToast: (type: string, payload?: any) => void;
  handleGetPages: (inputFileId: string) => void;
  handleSummaryReport: (reportId: string) => void;
  handleSaveReport: (reportId: string, payload: any, file: any) => void;
  handleGetInputFileInfo: (inputFileId: string) => void;
}

export const printViewHOC = <BaseProps extends ConnectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: ReduxState) => ({
    report: state.reportManager,
    isLoadingSubmit: state.global.isLoadingSubmit
  });

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): ConnectedProps => {
    return {
      handleModalPopup: (type: string, payload?: any) =>
        dispatch(formModalSetupActionPopUp(type, payload)),
      handleToast: (type: string, payload?: any) =>
        dispatch(toastSetupActionPopUp(type, payload)),
      handleLoadReport: (reportId: string) => dispatch(loadReport(reportId)),
      handleGetPages: (inputFileId: string) => dispatch(getPages(inputFileId)),
      handleSummaryReport: (reportId: string) =>
        dispatch(summaryReport(reportId)),
      handleSaveReport: (reportId: string, payload?: any, file?: any) =>
        dispatch(saveReport(reportId, payload, file)),
      handleGetInputFileInfo: (inputFileId: string) =>
        dispatch(fetchDataInputFile(inputFileId))
    };
  };

  type HocProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
      // extend ConnectedHoc with new loading state props
      // no need to access to logger.isloading to trigger page loading
      loading?: boolean;
      // state active item need to be injected because the modal dialog component need it
      activeItem?: any;
    };

  class Hoc extends React.Component<HocProps> {
    // Enhance component name for debugging and React-Dev-Tools
    static displayName = `printViewHOC(${BaseComponent.name})`;
    // reference to original wrapped component
    static readonly WrappedComponent = BaseComponent;

    state = {
      reportData: {
        currentReport: {},
        executiveSummary: []
      }
    };

    fetchReport = async (reportId: string) => {
      Promise.all([
        this.props.handleLoadReport(reportId),
      ])
        .then((response: any) => {
          this.setState({
            reportData: {
              currentReport: response[0],
              executiveSummary: response[0].summary
            }
          });
        })
        .catch((error: any) => console.error(error?.response?.data));
    };

    render() {
      const { report, isLoadingSubmit, ...restProps } = this.props;
      const { reportData } = this.state;

      return (
        <Fragment>
          <BaseComponent
            report={report}
            reportData={reportData}
            isLoadingSubmit={isLoadingSubmit}
            fetchReport={this.fetchReport}
            {...(restProps as BaseProps)}
          />
        </Fragment>
      );
    }
  }

  const ConnectedHoc = connect(mapStateToProps, mapDispatchToProps)(Hoc);

  return ConnectedHoc;
};
