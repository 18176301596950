import { Theme, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";


interface StyleProps {
    next: string;
    previous: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        "& .MuiPagination-ul": {
            "& > li:first-child": {
                "& button": {
                    borderRadius: "50%",
                    border: "transparent",
                    backgroundColor: '#F0F2F5',
                    color: ({ next }) => next
                },
            },
            "& > li:last-child": {
                "& button": {
                    borderRadius: "50%",
                    border: "transparent",
                    backgroundColor: '#F0F2F5',
                    color: ({ previous }) => previous
                },
            },
            '& .Mui-selected': {
                backgroundColor: 'transparent',
                color: '#118CFF',
            },
        },
    },
}));

export const InputText = styled('input')(({ theme }) => ({
    width: "220px",
    alignItems: "center",
    justifyContent: "center",
    borderTop: theme.component.lineDefault,
    borderBottom: theme.component.lineDefault,
    borderLeft: theme.component.lineDefault,
    borderRightWidth: 0,
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
    padding: "4px 8px 4px 12px",
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    "&::placeholder": {
        color: theme.customText.disable,
        letterSpacing: "0.4px",
    },
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#E0E6EB",
    justifyContent: "center",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    borderTop: theme.component.lineDefault,
    borderBottom: theme.component.lineDefault,
    borderRight: theme.component.lineDefault,
}));