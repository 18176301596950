import {
    ACTION_STATE,
    ActionStateI,
    CALCULATION_TARGET,
    CalculationTargetI,
    LOGGER_PREVIEW_IMAGE,
    LoggerPreviewImageI,
    PREVENT_CHANGES,
    PreventChangesI,
    RESET_IMAGE_STATE,
    ResetImageStateI,
} from './composerChangesTypes';

export interface ComposerChangesI {
    preventChanges: boolean,
    actionState: any;
    calculationTarget: any;
    loggerPreviewImage: Array<any>;
}

const ComposerChangesState: ComposerChangesI = {
    preventChanges: false,
    actionState: {},
    calculationTarget: {},
    loggerPreviewImage: []
};

type Action =
    | PreventChangesI
    | ActionStateI
    | CalculationTargetI
    | LoggerPreviewImageI
    | ResetImageStateI;


const ComposerChangesReducer = (state = ComposerChangesState, action: Action) => {
    switch (action.type) {
        case PREVENT_CHANGES:
            return {
                ...state,
                preventChanges: action.payload
            };
        case ACTION_STATE:
            return {
                ...state,
                actionState: action.payload
            };
        case CALCULATION_TARGET:
            return {
                ...state,
                calculationTarget: action.payload
            };
        case LOGGER_PREVIEW_IMAGE:
            let existedImage = state.loggerPreviewImage.find((item: any) => item.columnId === action.payload.columnId);
            if (existedImage) {
                existedImage = action.payload;
                return {
                    ...state,
                    loggerPreviewImage: [...state.loggerPreviewImage.filter((el: any) => el.columnId !== existedImage.columnId), existedImage]
                };
            } else {
                return {
                    ...state,
                    loggerPreviewImage: [...state.loggerPreviewImage, action.payload]
                };
            }
        case RESET_IMAGE_STATE:
            return {
                ...state,
                loggerPreviewImage: []
            };

        default:
            return state;
    }
};

export default ComposerChangesReducer;
