import { styled } from "@mui/material";
interface SubscriptionProps {
    type: string;
}

interface RoleProps {
    disabled: boolean;
}

export const Container = styled('div')({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8,
});

export const MemberInfo = styled('div')({
    flexDirection: 'column',
    display: 'flex',
    marginBottom: 8,
    rowGap: 2,
});

export const InfoWrapper = styled('div')({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
});

export const Subscription = styled('p')<SubscriptionProps>(({ theme, type }) => ({
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 700,
    color: type === 'Business' ? theme.subscription.business : type === 'Pro' ? theme.subscription.pro : theme.subscription.basic
}));

export const Divider = styled('div')({
    display: 'flex',
    borderTop: '1px solid #D6DFEB',
    marginBottom: 6,
    marginTop: 2,
    marginRight: 8
});

export const RolePills = styled('div')<RoleProps>(({ theme, disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 6,
    columnGap: 10,
    cursor: disabled ? 'auto' : 'pointer'
}));