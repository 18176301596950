import { ChangeMemberRoleI, MemberPropsI, MemberRolesI, PayloadAddMemberI } from "../components/molecules/popUp/modalForm/components/manageMembers/types";
import { UserLimitI } from "../store/reducers/userReducer";
import { APIResponse } from "../utils/interfaces";
import api from "./api";

const Folder_URL = 'folder';
const DriveMembersUrl = 'folder-members';
const roles = 'member-roles';
const calculationUrl = 'calculation';
const UserUrl = 'user';
const DriveService = {
  getMember: async (folderId: string): Promise<Array<MemberPropsI>> => {
    const response = await api.get(`${Folder_URL}/${folderId}/members`);
    return response.data.data;
  },
  addMember: async (data: PayloadAddMemberI) => {
    const response = await api.post(`${DriveMembersUrl}`, data);
    return response.data.data;
  },
  getMemberRoles: async (): Promise<Array<MemberRolesI>> => {
    const response = await api.get(`${roles}`);
    return response.data.data;
  },
  changeMemberRole: async (data: ChangeMemberRoleI) => {
    const response = await api.put(`${DriveMembersUrl}/role`, data);
    return response.data.data;
  },
  checkAccess: async (calculationId: string) => {
    const response = await api.get(`${calculationUrl}/${calculationId}/access`);
    return response.data.data;
  },
  checkLimit: async (): Promise<APIResponse<UserLimitI>> => {
    const response = await api.get(`${UserUrl}/calculation-limit`);
    return response.data;
  },
};

export default DriveService;
