import dayjs from "dayjs";
import images from "../../../../assets";
import Logo from "../../../../components/atoms/Logo";
import Text from "../../../../components/atoms/Text";
import { theme } from "../../../../utils/theme";
import { BoxBreak, Container, FlexBox, InfoContainer, InfoTitle, InfoValue, TitleContainer } from "./styles";

export default function PageHeader(props: any) {
    const {source: data} = props;

    return (
        <Container>
            <FlexBox>
                <TitleContainer>
                    <Text variant="h4">{data?.title}</Text>
                </TitleContainer>
                <InfoContainer>
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Originator</Text>
                    </InfoTitle>
                    <div>
                    <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{data?.createdBy?.name}</Text>
                    </InfoValue>
                </InfoContainer>
                <InfoContainer style={{flexWrap: 'wrap'}}>
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Date Created</Text>
                    </InfoTitle>
                    <div>
                        <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{dayjs(data?.createdAt).format("DD/MM/YYYY")}</Text>
                    </InfoValue>
                    <BoxBreak />
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Date Printed</Text>
                    </InfoTitle>
                    <div>
                        <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{data?.datePrinted ? dayjs(data?.datePrinted).format("DD/MM/YYYY") : ''}</Text>
                    </InfoValue>
                </InfoContainer>
            </FlexBox>
            <Logo src={images.textLogoGrey} width={64} style={{
                position: 'absolute',
                top: 10,
                right: 28
            }}/>
        </Container>
    );
}