import { Box, Grid } from '@mui/material';
import images from '../../../assets';
import { composerMainMenuList, loggerMenu, printMenu } from '../../../utils/constant';
import Icon from '../../atoms/Icon';
import Logo from '../../atoms/Logo';
import Pill from '../../atoms/Pill';
import Text from '../../atoms/Text';
import BasicMenu from '../../molecules/Menu';
import ProfileMenu from '../../molecules/ProfileMenu';
import { MenuContainer } from './styles';

type TopbarPropsI = {
  role: string;
  page: "composer" | "logger" | "print" ;
};

const mapMenu = {
  composer: composerMainMenuList,
  logger: loggerMenu,
  print: printMenu
};

const Topbar = ({ role , page}: TopbarPropsI) => {
  const menuList = mapMenu[page as keyof typeof mapMenu];
  return (
    <Grid container>
      <Grid item xs={4}>
        <div style={{display:"flex", flexDirection: 'row', alignItems: "center"}}>
          <Logo src={images.logoIcon} width={32} />
          <div style={{ justifyContent: "space-between", paddingLeft: 8 }}>
              <Text variant='h2' sx={{paddingBottom: 0}}>Calculation Name</Text>
              <MenuContainer>
                {Array.isArray(menuList) && menuList.map((value: any) => (
                <BasicMenu menu={value} />
              ))}
              </MenuContainer>
              </div>
          </div>
      </Grid>
      <Grid item xs={8} style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }} >
        {page === "composer" ? (
          <>
            <Box component="span" sx={{p:2}}>
              <Icon icon="save" size={18} />
            </Box>
            <Box component="span" sx={{p:2}}>
              <Icon icon="play" size={18} />
            </Box>
          </>
        ) : page === "logger" ? (
          <>
            <Box component="span" sx={{p:2}}>
              <Icon icon="edit" size={18} />
            </Box>
            <Box component="span" sx={{p:2}}>
              <Icon icon="printer" size={18} />
            </Box>
          </>
        ) : null }
        <Box component="span" sx={{p:2}}>
          <Pill role={role} />
        </Box>
        
        <ProfileMenu />
      </Grid>
    </Grid>
  );
};

export default Topbar;

Topbar.defaultProps = {
  page: "composer"
};