export interface RenameDrivePayloadI {
    id: string
    countMember: number;
    createdTime: string;
    isDrive: boolean;
    title: string;
}

interface CreatedByI {
    id: string;
    name: string;
}

interface UpdatedByI {
    id: string;
    name: string;
}

export interface RenameFolderPayloadI {
    id: string;
    createdBy: CreatedByI
    description: null | string;
    folderId: string;
    isActive: boolean;
    isDrive: boolean;
    itemType: string;
    preview: null | string;
    title: string;
    type: string;
    updatedAt: string;
    updatedBy: UpdatedByI;

}

export  interface locationI {
    pathname: string;
    state: PageStateI | undefined
}

export interface PageStateI {
    calculationId: string;
    from: string;
}

export interface FolderDriveItemsI {
    id: string;
    createdBy: CreatedByI
    description: null | string;
    folderId: string;
    isActive: boolean;
    isDrive: boolean;
    itemType: string;
    preview: null | string;
    title: string;
    type: string;
    updatedAt: string;
    updatedBy: UpdatedByI;
    memberRole: {
        id: string;
        type: string;
    };
    isComplete: boolean;
    countMember?: number;
}

export interface DriveI {
    countMember: number;
    createdTime: string;
    id: string;
    title: string;
    memberRole: {
        id: string;
        type: string;
    }
    itemType?: string;
    isComplete: boolean;
}

export interface FolderDriveI {
    createdAt: string;
    createdBy: CreatedByI;
    id: string;
    isEditing: boolean;
    items: Array<FolderDriveItemsI>;
    parentId: string | null;
    title: string;
    type: string;
    updatedAt: string | null;
    updatedby: UpdatedByI
}

export interface APIResponse<T> {
    data: T,
    status: number,
    message: string
}

export interface CheckAccessI {
    isAllowed: boolean;
}

export interface UserDriveStatusI {
    isHaveDrive: boolean,
    isHaveManageDrive: boolean
}

export const OWNER = 'OWNER';
export const CONTENT_MANAGER = 'CONTENT_MANAGER';
export const CONTRIBUTOR = 'CONTRIBUTOR';
export const VIEWER = 'VIEWER';
