import {
  Body,
  CloseButton,
  Container,
  Content,
  FlyingContainer,
  Footer,
  Head,
  XButton
} from './elements';
import { HelperTitle, InfoPanelHelperWrapper } from '../../infoPanel/elements';
import { Popover, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  fetchTutorial,
  formHelperPanel,
  setTutorialDone
} from '../../../../store/appState/global/globalActions';
import {
  clearStorage,
  getIsConsents,
  getUserDetails,
  getUserId,
  getUserTutorials,
  getUserType,
  setIsConsents,
  setUserDetails,
  setUserTutorials,
  setUserType
} from '../../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Carousel from './Carousel/Carousel';
import HTMLContent from '../../HTMLContent';
import InfoPanelHelperSection from '../../infoPanel/components/InfoPanelHelperSection';
import LazyImage from './LazyImage/LazyImage';
import { LazyImageProvider } from './LazyImage/LazyImageContext';
import Modal from '@mui/material/Modal';
import { ReduxState } from '../../../../store';
import Service from '../../../../service/generic/generic';
import { Spinner } from '../../../atoms/loading/elements';
import StyledCheckbox from '../../../atoms/Checkbox';
import Text from '../../../atoms/Text';
import images from '../../../../assets';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../../atoms/Icon';
import { handleCheckScreen } from '../../../../utils/helpersFunction';
import UseWindowDimensions from '../../../../utils/getWindows';
import { HelperPanelType } from '../../../../store/appState/global/globalReducer';
import { ContainerTitleStyled } from '../../modal/modalInfo/elements';
import { MODAL_CONTENT, MODAL_CONVERT, MODAL_TITLE, TEXT_I_AGREE } from '../../../../utils/constant';
import { Foot, Label } from '../modalForm/elements';
import StyledButton from '../../../atoms/Button';
import { UPDATE_SUBSCRIPTION } from '../../../../store/types/loginTypes';

export default function HelperInfo() {
  const { pathname } = useLocation();
  const { helperPanel } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [panelInfo, setPanelInfo] = useState<boolean>(false);
  const { height, width } = UseWindowDimensions();
  const [isDetailShow, setIsDetailShow] = useState<any>({});

  const userDetail = getUserDetails();
  const subscriptionService = new Service('subscription')

  const openHelperTutorial = Boolean(
    helperPanel.isOpen && helperPanel.type === HelperPanelType.TUTORIAL
  );
  const popUpSLA = Boolean(
    helperPanel.isOpen && helperPanel.type === HelperPanelType.SLA
  );
  const popUpSLARegister = Boolean(
    helperPanel.isOpen && helperPanel.type === HelperPanelType.SLA_REGISTER
  );
  const popupAnnouncement = Boolean(
    helperPanel.isOpen && helperPanel.type === HelperPanelType.ANNOUNCEMENT
  );
  const convertAnnouncement = Boolean(
    helperPanel.isOpen && helperPanel.type === HelperPanelType.CONVERT_ACE
  );
  const history = useHistory();
  const [checkSLA, setCheckSLA] = useState<boolean>(false);
  const id = helperPanel.isOpen ? 'panel-info' : undefined;
  const handleClosePanel = () => {
    setPanelInfo(false);
    dispatch(formHelperPanel(false, ''));
  };

  const panelState = async () => {
    try {
      const result = helperPanel?.payload?.map((item: any, i: number) => {
        return {
          index: i,
          expand: i === 0 ? true : false
        };
      });
      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (helperPanel.isOpen && helperPanel.type === HelperPanelType.PANEL) {
      const unsubscribe = detailState(helperPanel);
      return unsubscribe;
    }
  }, [helperPanel]);

  const detailState = (payload: any) => {
    try {
      Promise.all([panelState()])
        .then(response => {
          setIsDetailShow(response[0]);
        })
        .finally(() => {
          setPanelInfo(true);
        });
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleLoadHelperStep = async (payload: any) => {
    try {
      setPanelInfo(false);
      const result: any = await dispatch(fetchTutorial(payload));
      dispatch(formHelperPanel(true, 'TUTORIAL', result.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleToggleDetail = (index: number) => () => {
    if (index === helperPanel?.payload.length + 1) {
      dispatch(formHelperPanel(false, ''));
      history.push(`/ace/helpPage`);
    } else {
      setIsDetailShow(
        isDetailShow.map((el: any, i: number) => {
          if (i === index) {
            el.expand = !el.expand;
          }
          return el;
        })
      );
    }
  };

  const setDivider = (index: number) => {
    const dataPanel: number = helperPanel.payload.length;
    return index + 1 === dataPanel ? false : true;
  };

  const UserTutorials: any = getUserTutorials();

  const handleOnDone = async () => {
    try {
      Promise.all([dispatch(setTutorialDone(helperPanel.payload.id))]).then(
        async response => {
          const doneTutorial: any = await UserTutorials.hasOwnProperty(
            helperPanel.payload.id
          );
          if (!doneTutorial) {
            setUserTutorials({
              ...UserTutorials,
              [helperPanel.payload.id]: true
            });
          }
          dispatch(formHelperPanel(false, ''));
          if (pathname.includes('/dashboard')) {
            dispatch(formHelperPanel(true, HelperPanelType.ANNOUNCEMENT));
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopUp = async () => {
    setLoading(true);
    try {
      const service = new Service(`user-consent`);
      await service.post({
        userId: getUserId,
        policyId: helperPanel.payload.policyId
      });
      setIsConsents({ ...getIsConsents(), isConsent: true });
      setLoading(false);
      dispatch(formHelperPanel(false, ''));
      // redirect user to main page if agree to our Privacy Policy & Software License Agreement
      getUserType() === 'REGULAR' ? history.push('/dashboard') : history.push('/ace/enterprise');
      setTimeout(() => {
        window.location.reload();
      }, 100)
    } catch (error: any) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleClosePopUpRegister = () => {
    dispatch(formHelperPanel(false, ''));
  };

  const handleConvertToAce = () => {
    setLoading(true)
    subscriptionService.putResource(userDetail?.subscription?.id, 'ace').then(response => {
      dispatch({
        type: UPDATE_SUBSCRIPTION,
        subscription: response?.data,
      });
      setUserDetails({
        ...userDetail,
        subscription: response?.data
      });
      setUserType(response?.data?.plan)
      dispatch(formHelperPanel(false, ''))
      history.push(`/ace/public`);
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleLogout = () => {
    clearStorage();
    window.location.href = '/';
  }

  return (
    <>
      {helperPanel.type === HelperPanelType.PANEL && (
        <Popover
          id={id}
          open={panelInfo}
          onClose={handleClosePanel}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 260, left: (window.innerWidth / 10) * 8 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <InfoPanelHelperWrapper>
            <XButton>
              <img
                onClick={handleClosePanel}
                src={images.xButtonModal}
                width={8}
              />
            </XButton>
            <HelperTitle>ONBOARDING</HelperTitle>
            {Object.getOwnPropertyNames(isDetailShow).length > 1 &&
              React.Children.toArray(
                helperPanel?.payload?.map((item: any, i: number) => (
                  <InfoPanelHelperSection
                    key={i}
                    expanded={isDetailShow[i].expand}
                    onToggle={handleToggleDetail(i)}
                    data={item}
                    loadHelperStep={handleLoadHelperStep}
                    isDivider={true}
                    type={'tutorial'}
                  />
                ))
              )}
            <InfoPanelHelperSection
              key={helperPanel?.payload.length + 1}
              expanded={false}
              onToggle={handleToggleDetail(helperPanel?.payload.length + 1)}
              data={{ title: 'Go to Help to learn more' }}
              loadHelperStep={handleLoadHelperStep}
              isDivider={false}
              type={'faq'}
            />
          </InfoPanelHelperWrapper>
        </Popover>
      )}

      {helperPanel.type === HelperPanelType.TUTORIAL && (
        <Modal open={openHelperTutorial} onClose={handleOnDone}>
          <FlyingContainer>
            <Container>
              <XButton>
                <img
                  onClick={handleOnDone}
                  src={images.xButtonModal}
                  width={8}
                />
              </XButton>
              <Head>{helperPanel?.payload?.title}</Head>
              <Body>
                <LazyImageProvider>
                  <Carousel onDone={handleOnDone}>
                    {helperPanel?.payload?.contents.map(
                      (tutorial: any, i: number) => (
                        <LazyImage
                          aspectRatio={[10, 7]}
                          src={tutorial.imageUrl}
                          description={tutorial.description}
                          key={i}
                        />
                      )
                    )}
                  </Carousel>
                </LazyImageProvider>
              </Body>
            </Container>
          </FlyingContainer>
        </Modal>
      )}

      {helperPanel.type === HelperPanelType.SLA && (
        <Modal open={popUpSLA}>
          <FlyingContainer>
            <Container footerForm height={height * .9} width={width * .46}>
              <Content height={height * .9 - 56}>
                <HTMLContent text={helperPanel?.payload?.content} />
              </Content>
              <Footer>
                <div id="form">
                  <StyledCheckbox
                    label="I agree to the Rekava Terms of Service and Privacy Policy."
                    checked={checkSLA}
                    onClick={() => setCheckSLA(!checkSLA)}
                  />
                  <CloseButton
                    disabled={!checkSLA}
                    onClick={() => checkSLA && handleClosePopUp()}
                  >
                    {loading ? (
                      <Spinner size='SMALL' />
                    ) : (
                      <Text
                        variant="h6"
                        color={
                          checkSLA
                            ? theme?.palette.common.white
                            : theme.customText.disable
                        }
                      >
                        {TEXT_I_AGREE}
                      </Text>
                    )}
                  </CloseButton>
                </div>
              </Footer>
            </Container>
          </FlyingContainer>
        </Modal>
      )}

      {helperPanel.type === HelperPanelType.SLA_REGISTER && (
        <Modal open={popUpSLARegister}>
          <FlyingContainer>
            <Container footerForm isMobile={handleCheckScreen()}>
              <div
                style={{
                  justifyContent: 'end',
                  alignItems: 'end',
                  display: 'flex',
                  padding: '0 24px 12px'
                }}
              >
                <Icon
                  icon={'closeNoBorder'}
                  color={'#B3BFCC'}
                  size={30}
                  onClick={() => handleClosePopUpRegister()}
                  cursor={'pointer'}
                />
              </div>
              <Content isMobile={handleCheckScreen()} height={height}>
                <div>
                  <HTMLContent text={helperPanel?.payload?.content} />
                </div>
              </Content>
            </Container>
          </FlyingContainer>
        </Modal>
      )}

      {helperPanel.type === HelperPanelType.ANNOUNCEMENT && (
        <Modal open={popupAnnouncement} onClose={handleClosePopUpRegister}>
          <FlyingContainer>
            <Container>
              <ContainerTitleStyled>
                <p>📢 &nbsp; {MODAL_TITLE}</p>
                {/* <CloseIcon onClick={handleCloseModal} /> */}
                <Icon icon="times" onClick={handleClosePopUpRegister} />
              </ContainerTitleStyled>
              <Content isMobile={handleCheckScreen()} height={height} style={{ padding: 0 }}>
                <div>
                  <HTMLContent
                    text={MODAL_CONTENT}
                    style={{ whiteSpace: 'pre-line' }}
                  />
                </div>
              </Content>
            </Container>
          </FlyingContainer>
        </Modal>
      )}

      {helperPanel.type === HelperPanelType.CONVERT_ACE && (
        <Modal open={convertAnnouncement} disableEscapeKeyDown>
          <FlyingContainer>
            <Container style={{ width: 400, padding: '40px 32px 32px 32px', display: 'flex', justifyContent: 'space-between' }}>
              <>
                <Text
                  variant="h1"
                  style={{ textAlign: 'center' }}
                >
                  Convert To Ace
                </Text>
                <Label align='center' style={{ marginBottom: 40 }}>
                  {MODAL_CONVERT}
                </Label>
              </>
              <Foot>
                <StyledButton type='button' variant='outlined' fullWidth onClick={handleLogout} style={{ textTransform: 'none', fontSize: 14, lineHeight: '24px' }}>
                  Logout
                </StyledButton>
                <StyledButton type='button' variant='contained' fullWidth onClick={handleConvertToAce} disabled={loading} style={{ textTransform: 'none', fontSize: 14, lineHeight: '24px' }}>
                  {loading ? <Spinner size='SMALL' /> : 'Convert Now'}
                </StyledButton>
              </Foot>
            </Container>
          </FlyingContainer>
        </Modal>
      )}
    </>
  );
}
