export const CLOSEEVENTTYPEPOPUP = 'CLOSE_EVENT_TYPE_POPUP';
export const DATAFORMMODALTYPEPOPUP = 'DATA_FORM_MODAL_TYPE_POPUP';
export const REFACTOR_SAVE_CONFIRMATION_LOGGER = 'REFACTOR_SAVE_CONFIRMATION_LOGGER';

export interface CloseEventTypePopUp {
  type: typeof CLOSEEVENTTYPEPOPUP;
  data: boolean;
};

export interface DataFormModalTypePopUp {
  type: typeof DATAFORMMODALTYPEPOPUP;
  data: any;
};