import {
  BackCircleator,
  Container,
  Content,
  ContentCircleator,
  ContentCircleatorText,
  ParentContentCircleator,
  Wrapper,
  WrapperCircleator
} from './styles';
import {
  checkSubscriptionAndDate,
  checkValidationDataBodyOfCalculation,
  containsNumbers
} from '../../../utils/helpersFunction';
import {
  clearRecentInputFile,
  setLoggerVariableInput
} from '../../../store/actions/loggerAction';
import {
  getBusinessDriveRole,
  getPersonalWorkspaceId,
  setPreventChangesCalculation
} from '../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import Icon from '../../atoms/Icon';
import { LoadingSpinner } from '../../atoms/loading';
import { ReduxState } from '../../../store';
import { Spinner } from '../../atoms/loading/elements';
import Text from '../../atoms/Text';
import { changePositionActionCircleatorBreadcrumb } from '../../../store/breadCrumb/circleator/circleatorAction';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import images from '../../../assets';
import { queue } from '../../../service/queueManager/queue';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';
import { setActions } from '../../../store/actions/logger.actions';
import { updateCalculationStatus } from '../../../store/composer/composerChanges/composerChangesActions';
import { uploadLoggerPreview } from '../../../store/appState/global/globalActions';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material';

interface BreadcrumbsPropsI {
  dataSource: { id: string; name: string }[];
  type?: string;
  handleStartCalculation?: any;
  handleSaveReport?: any;
  customLocation?: boolean;
}

export const BreadcrumbsV2 = ({
  dataSource,
  type,
  handleSaveReport,
  customLocation
}: BreadcrumbsPropsI) => {
  const location: Record<string, any> = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const dataLength = dataSource.length;
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
  const [loadingBack, setLoadingBack] = useState<boolean>(false);
  const [loadingUploadPreview, setLoadingUploadPreview] =
    useState<boolean>(false);
  const isDriveViewer =
    isDriveCalculation && /VIEWER/.test(getBusinessDriveRole());

  if (!type) {
    type = 'normal';
  }

  // CIRCLEATOR LOGIC

  const { position } = useSelector(
    (state: ReduxState) => state.circleator,
    shallowEqual
  );

  const { dataBodyOfCalculation, isFetching } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  const { userLimit, userProfile } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  // const { subscription } = useSelector((state: ReduxState) => state.login);
  const { subscription } = userProfile;

  const { currentReport, reportChanges, reportSaveFormat } = useSelector(
    (state: ReduxState) => state.reportManager,
    shallowEqual
  );

  const { loggerPreview } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  const { isLoading, calculationInfo, inputFile, enablePrint } = useSelector(
    (state: ReduxState) => state.loggerReducer,
    shallowEqual
  );

  const folderId =
    position === 'composer'
      ? window.location.pathname.split('/').reverse()[0]
      : position === 'logger'
      ? calculationInfo?.folderId
      : position === 'print'
      ? currentReport?.inputFile?.folderId
      : null;
  const calculationId =
    position === 'composer'
      ? window.location.pathname.split('/').reverse()[1]
      : position === 'logger'
      ? calculationInfo.calculationID
      : position === 'print'
      ? window.location.pathname.split('/').reverse()[2]
      : '';

  const [loggerGateLogicDetector, setLoggerGateLogicDetector] =
    useState<any>(false);
  const [isComposerErrorFormula, setIsComposerErrorFormula] =
    useState<boolean>(false);
  const [readyToPrint, setReadyToPrint] = useState<boolean>(false);

  useEffect(() => {
    const { detect, detectObj, detectErrorFormula } =
      checkValidationDataBodyOfCalculation();
    const isErrorCalculation = detect || detectObj.length === 0;
    if (!customLocation) {
      setIsComposerErrorFormula(detectErrorFormula);
      setLoggerGateLogicDetector(isErrorCalculation);
      handleValidateStartCalculation(isErrorCalculation);
    }
  }, [JSON.stringify(dataBodyOfCalculation)]);

  const handleValidateStartCalculation = useCallback(
    (isError: boolean) => {
      if (position !== 'composer' || !containsNumbers(calculationId)) return;
      updateCalculationStatus(calculationId, {
        isComplete: !isError
      });
      if (location?.state?.isStartCalculation) {
        if (!isError) circleatorHandler('LOGGER_FROM_COMPOSER');
        else
          dispatch(
            formModalSetupActionPopUp('ERROR_CALCULATION', {
              calculationId: calculationId,
              history,
              folderId: folderId,
              position: position,
              isDriveCalculation,
              // added drive id to router state
              driveId: location.state.driveId ?? folderId
            })
          );
      }
    },
    [JSON.stringify(dataBodyOfCalculation), loggerGateLogicDetector]
  );

  useEffect(() => {
    if (position === 'logger') {
      setTimeout(() => {
        setLoggerGateLogicDetector(false);
      }, 100);
    }

    if (position === 'composer') {
      dispatch(
        setLoggerVariableInput({
          totalVariable: 0,
          totalInput: 0,
          error: 0
        })
      );
    }
  }, [position]);

  useEffect(() => {
    setReadyToPrint(enablePrint);
  }, [enablePrint]);

  const circleatorHandler = (type: any, payload?: any) => {
    switch (type) {
      case 'BACK_FROM_COMPOSER':
        if (queue.size !== 0) {
          dispatch(
            toastSetupActionPopUp(
              'ERROR',
              'Please wait a minute before you try again'
            )
          );
        } else {
          setLoadingUploadPreview(true);
          Promise.all([
            dispatch(uploadLoggerPreview(calculationId, loggerPreview))
          ])
            .then(() => {
              dispatch(changePositionActionCircleatorBreadcrumb('back'));
              if (isDriveCalculation) {
                history.push({
                  pathname: `/calculations/businessDrives/${folderId}`,
                  state: {
                    from: position,
                    calculationId: calculationId,
                    ...(location.state?.driveId && {
                      driveId: location.state.driveId
                    })
                  }
                });
              } else {
                history.push({
                  pathname:
                    payload === undefined
                      ? `/calculations/my-calculation`
                      : `/calculations/my-calculation/${payload}`,
                  state: {
                    from: position,
                    calculationId: calculationId
                  }
                });
              }
            })
            .catch(error => console.error(error))
            .finally(() => {
              setLoadingUploadPreview(false);
            });
        }
        break;
      case 'BACK_FROM_LOGGER':
        dispatch(changePositionActionCircleatorBreadcrumb('back'));
        history.push({
          pathname: isDriveCalculation
            ? `/calculations/businessDrives/${folderId}`
            : payload === undefined
            ? `/calculations/my-calculation`
            : `/calculations/my-calculation/${payload}`,
          state: {
            from: position,
            calculationId: calculationId,
            ...(location.state?.driveId && { driveId: location.state.driveId })
          }
        });
        break;
      case 'BACK_FROM_PRINT':
        dispatch(changePositionActionCircleatorBreadcrumb('back'));
        history.push({
          pathname: isDriveCalculation
            ? `/calculations/businessDrives/${folderId}`
            : payload === undefined
            ? `/calculations/my-calculation`
            : `/calculations/my-calculation/${payload}`,
          state: {
            from: position,
            calculationId: calculationId,
            ...(location.state?.driveId && { driveId: location.state.driveId })
          }
        });
        break;
      case 'LOGGER_FROM_COMPOSER':
        if (!loggerGateLogicDetector) {
          if (queue.size !== 0) {
            dispatch(
              toastSetupActionPopUp(
                'ERROR',
                'Please wait a minute before you try again'
              )
            );
          } else {
            if (isComposerErrorFormula) {
              dispatch(
                formModalSetupActionPopUp(
                  'START_CALCULATION_ERROR_CONFIRMATION',
                  {
                    calculationId,
                    history,
                    isDriveCalculation,
                    loggerPreview
                  }
                )
              );
            } else {
              setLoadingUploadPreview(true);
              Promise.all([
                dispatch(clearRecentInputFile()),
                dispatch(uploadLoggerPreview(calculationId, loggerPreview))
              ])
                .then(() => {
                  dispatch(changePositionActionCircleatorBreadcrumb('logger'));
                  setLoadingUploadPreview(false);
                  history.push({
                    pathname: `/logger/${calculationId}`,
                    state: {
                      ...(isDriveCalculation && {
                        calculationType: 'BUSINESS'
                      }),
                      ...(location?.state?.driveId && {
                        driveId: location?.state?.driveId
                      })
                    }
                  });
                })
                .catch(error => console.error(error));
            }
          }
        }
        break;
      case 'COMPOSER_FROM_LOGGER':
        //KPD-2750
        // if (Boolean(currentInputFile?.calculationFromCommunity) && !/REKAVA/.test(String(userRole))) return;
        dispatch(changePositionActionCircleatorBreadcrumb('composer'));
        setPreventChangesCalculation({
          calculationId: calculationId,
          status: true
        });
        history.push({
          pathname: `/composer/${payload.calculationId}/${folderId}`,
          state: {
            ...(isDriveCalculation && { calculationType: 'BUSINESS' })
          }
        });
        break;
      case 'PRINT_TO_COMPOSER':
        //KPD-2750
        // if (Boolean(currentInputFile?.calculationFromCommunity) && !/REKAVA/.test(String(userRole))) return;
        setPreventChangesCalculation({
          calculationId: calculationId,
          status: true
        });
        handleSaveReport(
          payload.calculationId,
          folderId,
          'composer',
          isDriveCalculation
        );
        break;
      case 'PRINTVIEW_FROM_LOGGER':
        if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
          dispatch(changePositionActionCircleatorBreadcrumb('print'));
          history.push({
            pathname: `/print-view/${payload.calculationId}/${payload.inputFileId}/${payload.reportId}`,
            state: {
              ...(isDriveCalculation && { calculationType: 'BUSINESS' })
            }
          });
        }
        break;
      case 'PRINT_TO_LOGGER':
        handleSaveReport(
          window.location.pathname.split('/')[2],
          window.location.pathname.split('/')[3],
          'logger',
          isDriveCalculation
        );
        break;
    }
  };

  const backToFolder = async (payload: any) => {
    switch (payload.position) {
      case 'composer':
        if (folderId === getPersonalWorkspaceId()) {
          circleatorHandler('BACK_FROM_COMPOSER');
        } else {
          circleatorHandler('BACK_FROM_COMPOSER', folderId);
        }
        setLoadingBack(false);
        break;
      case 'logger':
        if (inputFile.isModified) {
          dispatch(
            formModalSetupActionPopUp('SAVE_CONFIRMATION_LOGGER_CHANGES', {
              id: calculationInfo?.selectedInputFile?.id,
              pages: inputFile?.fields,
              calculationId: calculationInfo?.calculationID,
              title: calculationInfo?.selectedInputFile.title,
              folderId: folderId,
              history
            })
          );
          setLoadingBack(false);
        } else {
          if (folderId === getPersonalWorkspaceId()) {
            circleatorHandler('BACK_FROM_LOGGER');
          } else {
            circleatorHandler('BACK_FROM_LOGGER', folderId);
          }
          setLoadingBack(false);
        }
        break;

      case 'print':
        if (reportChanges) {
          dispatch(
            formModalSetupActionPopUp(
              'SAVE_REPORT_PRINT_VIEW_CONFIRMATION_TO_MYCALCULATION',
              {
                ...currentReport,
                title: reportSaveFormat.reportName,
                notes: '',
                isActive: true,
                format: {
                  pages: reportSaveFormat.pages,
                  summary: reportSaveFormat.summary,
                  latex: reportSaveFormat.latex
                },
                folderId: folderId
              }
            )
          );
        } else {
          if (folderId === getPersonalWorkspaceId()) {
            circleatorHandler('BACK_FROM_PRINT');
          } else {
            circleatorHandler('BACK_FROM_PRINT', folderId);
          }
        }
        setLoadingBack(false);
        break;

      default:
        // console.log(`Wrong typeAction clickMenu`);
        break;
    }
  };

  const handleReadyToPrint = async () => {
    dispatch(setActions('PRINT'));
  };

  return (
    <>
      {loadingUploadPreview && <LoadingSpinner />}
      {type === 'circleator' ? (
        <WrapperCircleator>
          {loadingBack ? (
            <Spinner />
          ) : (
            <BackCircleator
              src={images.back}
              onClick={() => backToFolder({ position, calculationId })}
            />
          )}
          <ParentContentCircleator>
            {Array.isArray(dataSource) &&
              dataSource.map((item, i) => {
                return i === 1 && item.id === 'composer' ? (
                  // COMPOSER ACTION
                  <ContentCircleator
                    key={`content_circle_creator_${i}`}
                    nonClickable={
                      loadingUploadPreview || isLoading || isDriveViewer
                    }
                    onClick={() =>
                      !isDriveViewer &&
                      position === 'logger' &&
                      !loadingUploadPreview &&
                      !isLoading
                        ? circleatorHandler('COMPOSER_FROM_LOGGER', {
                            calculationId
                          })
                        : position === 'print' && !isDriveViewer
                        ? circleatorHandler('PRINT_TO_COMPOSER', {
                            calculationId
                          })
                        : null
                    }
                    position="LEFT"
                    color={
                      position === 'composer'
                        ? '#118CFF'
                        : position === 'logger'
                        ? '#E3F3FF'
                        : position === 'print'
                        ? '#E3F3FF'
                        : ''
                    }
                    disabled={isDriveViewer}
                    //KPD-2750
                    // disabled={(position === 'logger' || position === 'print') && Boolean(currentInputFile?.calculationFromCommunity) && !/REKAVA/.test(String(userRole))}
                  >
                    <Icon
                      //KPD-2750
                      // icon={Boolean(currentInputFile?.calculationFromCommunity) && !/REKAVA/.test(String(userRole)) ? "lock" : "edit"}
                      icon={isDriveViewer ? 'lock' : 'edit'}
                      color={
                        //KPD-2750
                        // position === 'composer' ? '#FFFFFF' :
                        //   position === 'logger' && (!currentInputFile.calculationFromCommunity || (currentInputFile.calculationFromCommunity && /REKAVA/.test(String(userRole)))) ? '#118CFF' :
                        //     position === 'logger' && currentInputFile.calculationFromCommunity ? '#B3BFCC' :
                        //       position === 'print' && currentInputFile.calculationFromCommunity && /REKAVA/.test(String(userRole)) ? '#118CFF' :
                        //         position === 'print' && currentInputFile.calculationFromCommunity && !/REKAVA/.test(String(userRole)) ? '#B3BFCC' :
                        //           position === 'print' && !currentInputFile.calculationFromCommunity ? '#118CFF' : ""} />
                        position === 'composer'
                          ? '#FFFFFF'
                          : position === 'logger' && !isDriveViewer
                          ? '#118CFF'
                          : position === 'logger' && isDriveViewer
                          ? '#B3BFCC'
                          : position === 'print' && !isDriveViewer
                          ? '#118CFF'
                          : position === 'print' && isDriveViewer
                          ? '#B3BFCC'
                          : ''
                      }
                    />
                    <ContentCircleatorText
                      color={
                        //KPD-2750
                        // position === 'composer' ? '#FFFFFF' :
                        //   position === 'logger' && (!currentInputFile.calculationFromCommunity || (currentInputFile.calculationFromCommunity && /REKAVA/.test(String(userRole)))) ? '#118CFF' :
                        //     position === 'logger' && currentInputFile.calculationFromCommunity ? '#B3BFCC' :
                        //       position === 'print' && currentInputFile.calculationFromCommunity && /REKAVA/.test(String(userRole)) ? '#118CFF' :
                        //         position === 'print' && currentInputFile.calculationFromCommunity && !/REKAVA/.test(String(userRole)) ? '#B3BFCC' :
                        //           position === 'print' && !currentInputFile.calculationFromCommunity ? '#118CFF' : ""}>{item.name}</ContentCircleatorText>
                        position === 'composer'
                          ? '#FFFFFF'
                          : position === 'logger' && !isDriveViewer
                          ? '#118CFF'
                          : position === 'logger' && isDriveViewer
                          ? '#B3BFCC'
                          : position === 'print' && !isDriveViewer
                          ? '#118CFF'
                          : position === 'print' && isDriveViewer
                          ? '#B3BFCC'
                          : ''
                      }
                    >
                      {item.name}
                    </ContentCircleatorText>
                  </ContentCircleator>
                ) : i === dataSource.length - 1 && item.id === 'print' ? (
                  // PRINT ACTION
                  <ContentCircleator
                    key={`content_circle_creator_${i}`}
                    nonClickable={position === 'print' || !readyToPrint}
                    onClick={() =>
                      readyToPrint && position !== 'print'
                        ? handleReadyToPrint()
                        : ''
                    }
                    color={
                      position === 'composer'
                        ? '#F0F2F5'
                        : position === 'logger'
                        ? '#F0F2F5'
                        : '#118CFF'
                    }
                    position="RIGHT"
                  >
                    {position === 'composer' ? (
                      <>
                        <Icon
                          icon="lock"
                          color={position === 'composer' ? '#B3BFCC' : ''}
                        />
                        <ContentCircleatorText
                          color={position === 'composer' ? '#B3BFCC' : ''}
                        >
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : position === 'logger' ? (
                      <>
                        <Icon
                          icon={readyToPrint ? 'printer' : 'lock'}
                          color={readyToPrint ? '#47596B' : '#B3BFCC'}
                          cursor={readyToPrint ? 'pointer' : 'default'}
                        />
                        <ContentCircleatorText
                          color={readyToPrint ? '#47596B' : '#B3BFCC'}
                        >
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : position === 'print' ? (
                      <>
                        <Icon
                          icon={'printer'}
                          color={'#F0F2F5'}
                          cursor={position === 'print' ? 'default' : 'pointer'}
                        />
                        <ContentCircleatorText color={'#F0F2F5'}>
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : null}
                  </ContentCircleator>
                ) : i !== 0 && item.id === 'logger' ? (
                  // LOGER ACTION
                  <ContentCircleator
                    key={`content_circle_creator_${i}`}
                    nonClickable={isFetching || loadingUploadPreview}
                    onClick={() =>
                      position === 'composer' &&
                      !isFetching &&
                      !loadingUploadPreview
                        ? circleatorHandler('LOGGER_FROM_COMPOSER')
                        : position === 'print'
                        ? circleatorHandler('PRINT_TO_LOGGER', {
                            calculationId,
                            inputFileId: inputFile?.selectedInputFile?.id
                          })
                        : null
                    }
                    color={
                      position === 'composer'
                        ? '#F0F2F5'
                        : position === 'logger'
                        ? '#118CFF'
                        : position === 'print'
                        ? '#E3F3FF'
                        : '#F0F2F5'
                    }
                  >
                    {position === 'composer' ? (
                      <>
                        <Icon
                          icon={
                            position === 'composer' && !loggerGateLogicDetector
                              ? 'play'
                              : position === 'composer' &&
                                loggerGateLogicDetector
                              ? 'lock'
                              : ''
                          }
                          color={
                            position === 'composer' && !loggerGateLogicDetector
                              ? '#47596B'
                              : position === 'composer' &&
                                loggerGateLogicDetector
                              ? '#A3B3C2'
                              : ''
                          }
                          cursor={
                            isFetching || loadingUploadPreview
                              ? 'auto'
                              : 'pointer'
                          }
                        />
                        <ContentCircleatorText
                          color={
                            position === 'composer' && !loggerGateLogicDetector
                              ? '#47596B'
                              : position === 'composer' &&
                                loggerGateLogicDetector
                              ? '#A3B3C2'
                              : ''
                          }
                        >
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : position === 'logger' ? (
                      <>
                        <Icon
                          icon={
                            position === 'logger' && !loggerGateLogicDetector
                              ? 'play'
                              : position === 'logger' && loggerGateLogicDetector
                              ? 'lock'
                              : ''
                          }
                          color={
                            position === 'logger' && !loggerGateLogicDetector
                              ? '#ffffff'
                              : position === 'logger' && loggerGateLogicDetector
                              ? '#A3B3C2'
                              : ''
                          }
                          cursor={
                            isFetching || loadingUploadPreview
                              ? 'auto'
                              : 'pointer'
                          }
                        />
                        <ContentCircleatorText
                          color={
                            position === 'logger' && !loggerGateLogicDetector
                              ? '#ffffff'
                              : position === 'logger' && loggerGateLogicDetector
                              ? '#A3B3C2'
                              : ''
                          }
                        >
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : position === 'print' ? (
                      <>
                        <Icon icon={'play'} color={'#118CFF'} />
                        <ContentCircleatorText color={'#118CFF'}>
                          {item.name}
                        </ContentCircleatorText>
                      </>
                    ) : null}
                  </ContentCircleator>
                ) : null;
              })}
          </ParentContentCircleator>
        </WrapperCircleator>
      ) : (
        <Wrapper>
          {Array.isArray(dataSource) &&
            dataSource.map((item, i) => {
              return (
                <Container key={`breadcrumbs-${item.id}-${item.name}`}>
                  <Text
                    variant="subtitle2"
                    color={
                      dataLength - 1 === i
                        ? theme.customText.high
                        : theme.customText.low
                    }
                  >
                    {item.name}
                  </Text>
                  {dataLength - 1 !== i && (
                    <Content>
                      <Icon icon="chevronRight" size={24} color="#B3BFCC" />
                    </Content>
                  )}
                </Container>
              );
            })}
        </Wrapper>
      )}
    </>
  );
};
