import styled from 'styled-components';

export const Container = styled.div({
  width: '13px',
  height: '13px',
  backgroundColor: '#E1E1E3',
  border: '1.5px solid #E1E1E3',
  position: 'relative',
  borderRadius: '20px',
  cursor: 'pointer',
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    minHeight: '16px',
    minWidth: '16px',
    fontWeight: 'bold',
    fontSize: '11px',
    margin: 0,
    transform: 'translate(-50%, -50%)',
    color: '#9696A0',
  },
});
