import { useRef } from 'react';
import StyledButton from '../../../../components/atoms/Button';
import Icon from '../../../../components/atoms/Icon';
import { theme } from '../../../../utils/theme';
import { PageNavigationProps } from '../../logger.types';
import { PageButtonContainer, PageNavContainer } from './styles';

export default function PageNavigation({
  pages,
  selected,
  onClickHandler
}: PageNavigationProps) {
  /**
   * TODO:
   * create REUSABLE SCROLL HOOK then add this scroll logic on it
   * so this component will act as a DUMMY COMPONENT
   */
  const headerRef = useRef<HTMLDivElement>(null);

  const handleSwipe = (direction: string) => () => {
    if (!headerRef.current) return false;
    if (direction === 'left') {
      headerRef.current.scrollLeft -= 100;
    } else headerRef.current.scrollLeft += 100;
  };

  return (
    <PageNavContainer>
      <Icon
        icon="chevronLeft"
        className="arrowIcon left"
        onClick={handleSwipe('left')}
        data-testid="btnPrefPage"
      />
      <PageButtonContainer ref={headerRef}>
        {pages?.map((page: any) => {
          return (
            <StyledButton
              variant="contained"
              key={`tab-pages-${page.idx}`}
              style={{
                backgroundColor:
                  selected === page.idx
                    ? theme.palette.primary.main
                    : theme.palette.common.white,
                color:
                  selected === page.idx
                    ? theme.palette.common.white
                    : theme.palette.text.secondary,
                boxShadow: 'none',
                minWidth: 105,
                textTransform: 'none'
              }}
              sx={{
                ':hover': {
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.common.white
                }
              }}
              onClick={() => onClickHandler(page.idx)}
              data-testid={page.pageTitle}
              size={'small'}
            >
              {page.pageTitle}
            </StyledButton>
          );
        })}
      </PageButtonContainer>
      <Icon
        icon="chevronRight"
        className="arrowIcon right"
        onClick={handleSwipe('right')}
        data-testid="btnNextPage"
      />
    </PageNavContainer>
  );
}
