export const SHOW_HIDE_MODAL_ACTION = 'SHOW_HIDE_MODAL_ACTION';
export const SHOW_HIDE_MODAL_INFO = 'SHOW_HIDE_MODAL_INFO';
export const SHOW_HIDE_MODAL_EXPLORER = 'SHOW_HIDE_MODAL_EXPLORER';
export const CHANGE_TYPE_VIEW = 'CHANGE_TYPE_VIEW';

export interface ShowHideModalActionI {
  type: typeof SHOW_HIDE_MODAL_ACTION;
  value: boolean;
  typeAction: string;
}

export interface ShowHideModalInfoI {
  type: typeof SHOW_HIDE_MODAL_INFO;
  value: boolean;
  messageInfo: string;
}

export interface ShowHideModalExplorerI {
  type: typeof SHOW_HIDE_MODAL_EXPLORER;
  value: boolean;
  typeAction: string;
}

export interface ChangeTypeViewI {
  type: typeof CHANGE_TYPE_VIEW;
  typeView: string;
}
