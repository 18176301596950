import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { toPng } from "html-to-image";
import parser from 'html-react-parser';
import { HyperFormula } from "hyperformula";

import { toLocalDateTime } from "./dateConvert";
import Service from '../service/generic/generic';
import { formModalSetupActionPopUp } from "../store/appState/popUp/formModal/formModalAction";
import { checkDecimalPercentage, countDecimal, roundFunction } from "../pages/loggerRefactor/utils";

import { getValue } from "../components/molecules/infoPanel/utils";
import { indexToChar } from "../components/molecules/FormulaTable/utils";
import { BUSINESS, BUSINESS_PLUS, ENTERPRISE, expandTableDefaultCellStyle, FREE, PRO, PRO_PLUS } from "./constant";
import {
  getDataBodyOfCalculationComposer,
  getDataHyperFormulaComposer,
  getDataVariableManagerMenuComposer,
  getSymbol,
} from "./reduxOutside";
import { isString } from "lodash";
import dayjs from "dayjs";
import { CellAlignment } from "../components/molecules/FormulaTable";
import { UserDataI } from "../service/UserService";
import DOMPurify from "dompurify";
import { META_NAME } from "../pages/loggerRefactor/helper";
import { getUserDetails } from "./storage";

// START OF COMPOSER

//// INSIDE

function isPercentage(str: string) {
  return /^\d+(.\d+)*\s%$/.test(str);
};

function convertToDecimal(int: string) {
  const number = Number(int.slice(0, -2));
  return number / 100;
};

function excelStringConverter(str: string) {
  return `"${str}"`;
}

function reverseString(str: any) {
  const splitString = str.split("");
  const reverseArray = splitString.reverse();
  const joinArray = reverseArray.join("");
  return joinArray;
}

function variableFromStringDetector(formula: string) {
  const glyphList: string = getSymbol().map(el => el.glyph).join('');

  const arrayOfSymbol = formula.match(new RegExp('[^\\w\\s!' + glyphList + ']', 'gm'));

  let convertedDataString: string = formula;
  let arrayOfData: string[] = [];
  if (arrayOfSymbol && formula && formula[0] === '=') {
    for (let i = 0; i < arrayOfSymbol.length; i++) {
      convertedDataString = convertedDataString.replaceAll(arrayOfSymbol[i], '™',);
    }
    arrayOfData = convertedDataString.split('™').filter((item: any) => item !== '');
  }

  return arrayOfData;
}

//// OUTSIDE

export function findComponent(id: any) {
  const dataBodyOfCalculation: { [key: string]: any }[][] = copyArray(getDataBodyOfCalculationComposer());
  let payload: { [key: string]: any } = {};

  dataBodyOfCalculation.forEach((dataPage: any, indexPage: number) => {
    dataPage.forEach((data: any, indexData: number) => {
      if (data.id === id) {
        payload = {
          idComponent: data.id,
          typeComponent: data.type,
          indexComponent: indexData,
          activatedPage: indexPage,
        };
      };
    });
  });;

  return payload;
}

export function concatStringHTML(currentFocus: string, startInputFocus: number | null, endInputFocus: number | null, payload: string | number, getValues: UseFormGetValues<any>, setValue: UseFormSetValue<any>) {
  if (typeof payload === 'string') {
    const concatString: string = getValues(currentFocus).substring(0, startInputFocus) + payload + getValues(currentFocus).substring(endInputFocus, getValues(currentFocus).length);
    const target: HTMLInputElement = document.getElementById(currentFocus) as HTMLInputElement;
    let moveCaret: number;

    setValue(currentFocus, concatString);
    if (typeof startInputFocus === 'number') {
      moveCaret = startInputFocus + payload.length;
      target.setSelectionRange(moveCaret, moveCaret);
    }
  }
}

export function filterVariableManagerArray(array: { [key: string]: string }[], payload: string) {
  const includedTypes: string[] = ['NUMERICAL_INPUT', 'TEXT_INPUT', 'DROPDOWN_INPUT', 'RADIO_BUTTON_INPUT', 'FORMULA', 'FIELD', 'TABLE'];
  return array.filter((element) => {
    const mandatoryFilled = Boolean(element.description && element.variable && element.notation);
    const search = Boolean(element.description?.includes(payload) || element.variable?.includes(payload) || element.notation?.includes(payload));
    if (includedTypes.includes(element.type) && mandatoryFilled && search) {
      return element;
    };
  });
}

export function checkValidationDataBodyOfCalculation() {
  const dataBodyOfCalculation: any = Array.from(getDataBodyOfCalculationComposer());

  let detect: any = false;
  let detectErrorFormula = false;
  const detectObj: any = [];

  for (let i = 0; i < dataBodyOfCalculation.length; i++) {
    for (let j = 0; j < dataBodyOfCalculation[i].length; j++) {
      detectObj.push(dataBodyOfCalculation[i][j]);

      // MEMORY LOAD REDUCER
      if (detect) {
        continue;
      }

      if ('description' in dataBodyOfCalculation[i][j] && dataBodyOfCalculation[i][j].type !== 'IMAGE') {
        if (isEmptyString(dataBodyOfCalculation[i][j].description)) {
          detect = true;
          continue;
        }
      }

      if ('variable' in dataBodyOfCalculation[i][j]) {
        if (!dataBodyOfCalculation[i][j].variable || !notContainSpecialCharacterAndSpace(dataBodyOfCalculation[i][j].variable, 'variable')) {
          detect = true;
          continue;
        }

        // DETECT DUPLICATION
        for (let k = 0; k < dataBodyOfCalculation.length; k++) {
          for (let l = 0; l < dataBodyOfCalculation[k].length; l++) {
            if (dataBodyOfCalculation[i][j].id !== dataBodyOfCalculation[k][l].id) {
              if (dataBodyOfCalculation[i][j].variable === dataBodyOfCalculation[k][l].variable && 'variable' in dataBodyOfCalculation[k][l]) {
                detect = true;
                continue;
              } else if (dataBodyOfCalculation[i][j].tableName === dataBodyOfCalculation[k][l].variable && 'variable' in dataBodyOfCalculation[k][l]) {
                detect = true;
                continue;
              }
            } else {
              if (dataBodyOfCalculation[k][l].tableName === dataBodyOfCalculation[k][l].variable) {
                detect = true;
                continue;
              }
            }
          }
        }
      }

      if ('notation' in dataBodyOfCalculation[i][j]) {
        if (!dataBodyOfCalculation[i][j].notation || !notContainSpecialCharacterAndSpace(dataBodyOfCalculation[i][j].notation)) {
          detect = true;
          continue;
        }

        // DETECT DUPLICATION
        for (let k = 0; k < dataBodyOfCalculation.length; k++) {
          for (let l = 0; l < dataBodyOfCalculation[k].length; l++) {
            if (dataBodyOfCalculation[i][j].id !== dataBodyOfCalculation[k][l].id) {
              if (dataBodyOfCalculation[i][j].notation === dataBodyOfCalculation[k][l].notation && 'notation' in dataBodyOfCalculation[k][l]) {
                detect = true;
                continue;
              }
            }
          }
        }
      }

      if ('notation' in dataBodyOfCalculation[i][j]) {
        if (!dataBodyOfCalculation[i][j].notation) {
          detect = true;
          continue;
        }
      }

      if ('url' in dataBodyOfCalculation[i][j]) {
        if (!dataBodyOfCalculation[i][j].url) {
          detect = true;
          continue;
        }
        if (!isValidURL(dataBodyOfCalculation[i][j].url)) {
          detect = true;
          continue;
        }
      }

      if ('options' in dataBodyOfCalculation[i][j]) {
        // DETECT OPTIONS NULL
        if (dataBodyOfCalculation[i][j].options === null) {
          detect = true;
          continue;
        }

        // DETECT OPTIONS LENGTH
        if (dataBodyOfCalculation[i][j].options.length < 1) {
          detect = true;
          continue;
        }

        // DETECT DESCRPTION INSIDE OPTION
        for (let k = 0; k < dataBodyOfCalculation[i][j].options.length; k++) {
          if (!dataBodyOfCalculation[i][j].options[k]) {
            detect = true;
            continue;
          }
        }
      }

      if ('error' in dataBodyOfCalculation[i][j]) {
        if (errorFormulaCheck(dataBodyOfCalculation[i][j].resultHyperFormula) && errorFormulaCheck(dataBodyOfCalculation[i][j].error) || dataBodyOfCalculation[i][j].error === "Formula must be filled" || dataBodyOfCalculation[i][j].error === 'Please input with the format of Excel operations' || dataBodyOfCalculation[i][j].error === 'Cannot call itself') {
          detect = true;
          continue;
        }
        if (!dataBodyOfCalculation[i][j].error && errorFormulaCheck(dataBodyOfCalculation[i][j].resultHyperFormula)) {
          detectErrorFormula = true;
          continue;
        }
      }

      if ('imageUrl' in dataBodyOfCalculation[i][j]) {
        if (!dataBodyOfCalculation[i][j].imageUrl) {
          detect = true;
          continue;
        }
      }
    }
  }

  return { detect, detectObj, detectErrorFormula };

}

export function checkDuplication(type: string, string: any, id: any) {
  const dataBodyOfCalculation: any = getDataBodyOfCalculationComposer();

  for (let i = 0; i < dataBodyOfCalculation.length; i++) {
    for (let j = 0; j < dataBodyOfCalculation[i].length; j++) {
      if (dataBodyOfCalculation[i][j].id !== id && dataBodyOfCalculation[i][j][type] === string) {
        return true;
      }
    }
  }
}

export function checkOptionsArray(array: any) {
  // FIRST VALIDATION ARRAY
  if (array === null) {
    return true;
  }

  // SECOND VALIDATION ARRAY
  if (array.length < 1) {
    return true;
  }

  // THIRD VALIDATION ARRAY
  for (let i = 0; i < array.length; i++) {
    if (!array[i]) {
      return true;
    }
  }
}

export function copyArray(array: any) {
  return JSON.parse(JSON.stringify(array));
}

export function convertTextLevel(string: any) {
  const upperLower: string = string
    .toLowerCase()
    .replace(/\b./g, function (a: any) {
      return a.toUpperCase();
    });
  const removeUnderline: string = upperLower.replace("_", " ");
  return removeUnderline;
}

export function capitalizeFirstLetter(string: any) {
  const upperLower: string = string
    .toLowerCase()
    .replace(/\b./g, function (a: any) {
      return a.toUpperCase();
    });
  return upperLower;
}

export function getFolderIdFromComposer(string: any) {
  let splittedString: any = "";
  for (let i = string.length - 1; i >= 0; i--) {
    if (string[i] === "/") {
      break;
    }
    splittedString = splittedString + string[i];
  }
  return reverseString(splittedString);
}

export function getCalculationIdFromComposer(string: any) {
  let splittedString: any = "";
  let flag = false;
  for (let i = string.length - 1; i >= 0; i--) {
    if (flag === false && string[i] === "/") {
      flag = true;
      continue;
    } else if (flag === true) {
      if (flag === true && string[i] === "/") {
        break;
      }
      splittedString = splittedString + string[i];
    }
  }
  return reverseString(splittedString);
}

export function checkValidationHyperFormula(
  dataString: any,
  activePage: any,
) {
  // IMPORT STATE
  return new Promise((resolve: any, reject: any) => {
    const dataBodyOfCalculation: any = Array.from(getDataBodyOfCalculationComposer());
    const dataVariableManager: any = Array.from(getDataVariableManagerMenuComposer());
    const dataHyperFormula: any = getDataHyperFormulaComposer();

    const option: any = { licenseKey: 'gpl-v3' };
    const hfInstance: any = HyperFormula.buildFromSheets(dataHyperFormula, option);

    let convertedDataVariableManager: any = [];

    if (dataString === '') {
      reject("Formula must be filled");
    }

    try {
      // SORTING
      for (let i = 0; i < dataVariableManager.length; i++) {
        for (let j = 0; j < dataVariableManager[i].length; j++) {
          convertedDataVariableManager.push({
            variable: dataVariableManager[i][j].variable,
            inputValue: dataVariableManager[i][j].inputValue
          });
        }
      }

      convertedDataVariableManager = convertedDataVariableManager.sort((a: any, b: any) => (a.variable.length < b.variable.length) ? 1 : ((b.variable.length < a.variable.length) ? -1 : 0));

      // REGEX EXCLUDING TABLE NAME
      const glyphList: string = getSymbol().map(el => el.glyph).join('');
      const arrayOfSymbol = dataString.match(new RegExp('[^\\w\\s!' + glyphList + ']', 'gm'));
      let convertedDataString: string = dataString;
      for (let i = 0; i < arrayOfSymbol.length; i++) {
        convertedDataString = convertedDataString.replaceAll(arrayOfSymbol[i], '™',);
      }
      let arrayOfData: any = convertedDataString.split('™');
      arrayOfData = arrayOfData.filter((item: any) => item !== '');

      // INJECT VARIABLE
      for (let i = 0; i < convertedDataVariableManager.length; i++) {
        if (
          dataString.includes(convertedDataVariableManager[i].variable) && arrayOfData.includes(convertedDataVariableManager[i].variable)
        ) {
          dataString = dataString.replaceAll(
            convertedDataVariableManager[i].variable,
            isNumeric(convertedDataVariableManager[i].inputValue)
              ? convertedDataVariableManager[i].inputValue
              : isPercentage(convertedDataVariableManager[i].inputValue)
                ? convertToDecimal(convertedDataVariableManager[i].inputValue)
                : excelStringConverter(convertedDataVariableManager[i].inputValue)
          );
        }
      }

      const result = hfInstance.calculateFormula(dataString, activePage);
      const excludedTypeResult = [
        'NUM', 'VALUE', 'DIV_BY_ZERO'
      ];
      if (typeof result === 'object' && !excludedTypeResult.includes(result.type)) {
        /**
         * #NUM! error are allow!
         * This error arises when your formula contains an invalid number
         * i.e: the first parameter of SQRT function is negative but it should be positive or zero
         * then the validation will allow above error
         * see: https://hyperformula.handsontable.com/guide/types-of-errors.html)
         */
        reject(result.value);
      }


      resolve(result);
    } catch (error) {
      reject("Please input with the format of Excel operations");
    }
  });
}

export function checkVariableManagerCalculateFormula(formula: any) {
  const dataVariableManager: any = Array.from(getDataVariableManagerMenuComposer());

  for (let i = 0; i < dataVariableManager.length; i++) {
    for (let j = 0; j < dataVariableManager[i].length; j++) {
      if (
        formula.includes(dataVariableManager[i][j].variable)
      ) {
        formula = formula.replaceAll(
          dataVariableManager[i][j].variable,
          dataVariableManager[i][j].inputValue
        );
      }
    }
  }

  return formula;

}

export function checkVariablePrefix(variable: string) {
  if (
    variable[0] === 'v' &&
    variable[1] === 'a' &&
    variable[2] === 'r' &&
    variable[3] === '_'
  ) {
    return true;
  } else {
    return false;
  }
}

export function dynamicFormulaConverter(dataBodyOfCalculation: { [key: string]: any }[][], oldVariable: string, newVariable: string, setValue?: UseFormSetValue<any>) {
  const asyncMemory: { [key: string]: string | number }[] = [];

  dataBodyOfCalculation.forEach((page: { [key: string]: any }[], pageIndex: number) => {
    page.forEach((component: { [key: string]: any }, componentIndex: number) => {
      if (component.type === 'FORMULA' && component.formula) {
        const arrayOfData: string[] = variableFromStringDetector(component.formula);
        const checkOldVariable: boolean = arrayOfData.includes(oldVariable);
        const checkSameVariable: boolean = component.formula !== component.formula.replaceAll(oldVariable, newVariable);

        if (newVariable !== '' && oldVariable !== '' && checkOldVariable && checkSameVariable) {
          component.formula = component.formula.replaceAll(oldVariable, newVariable);
          if (setValue) setValue(`formula_${component.id}`, component.formula);
          asyncMemory.push({
            pageIndex: pageIndex,
            rowIndex: componentIndex,
            formula: component.formula
          });
        };
      } else if (component.type === 'TABLE' && component.tableBody.length > 0) {
        component.tableBody.forEach((tableRow: { [key: string]: any }[]) => {
          tableRow.forEach((tableCell: { [key: string]: any }) => {
            if (tableCell.value && isString(tableCell.value)) {
              const arrayOfData: string[] = variableFromStringDetector(tableCell.value);

              let checkOldVariable = false;
              let checkSameVariable = false;
              if (oldVariable) {
                console.log(arrayOfData, oldVariable.toUpperCase());

                checkOldVariable = arrayOfData.includes(oldVariable.toUpperCase());
                checkSameVariable = tableCell.value.toUpperCase() !== tableCell.value.replaceAll(oldVariable.toUpperCase(), newVariable.toUpperCase());
              }

              if (newVariable !== '' && oldVariable !== '' && checkOldVariable && checkSameVariable) {
                tableCell.value = tableCell.value.replaceAll(oldVariable.toUpperCase(), newVariable.toUpperCase());
              };
            };
          });
        });

        const tableCell: any = tableToCellsConverter(component.tableBody);
        asyncMemory.push({
          type: 'table',
          pageIndex: pageIndex,
          rowIndex: componentIndex,
          cells: tableCell,
        });
      };
    });
  });

  return {
    dataBodyOfCalculation,
    asyncMemory,
  };
}

export function arrangeTableName(array: { [key: string]: string }[][]) {
  let indexTable = 1;

  const resultPage = array.map((page) => {
    const resultComponent = page.map((component) => {
      if ('tableName' in component) {
        component.tableName = `Table ${indexTable}`;
        indexTable++;
      }
      return component;
    });
    return resultComponent;
  });

  return resultPage;
}

export function tableGenerator(row = 40, column = 40) {
  const table = [];
  for (let i = 0; i < row; i++) {
    const row = [];
    for (let j = 0; j < column; j++) {
      row.push({
        value: '',
        type: 'default'
      });
    }
    table.push(row);
  }
  return table;
}

export function tableHeaderGenerator(column = 40) {
  const tableHeader = [];
  for (let i = 0; i < column; i++) {
    tableHeader.push({
      width: 150,
    });
  };
  return tableHeader;
}

export function headerToTableHeaderConverter(data: { [key: string]: any }[]) {
  let maxCol = 40;

  data.forEach(item => {
    if (maxCol <= item.colIdx) maxCol = item.colIdx + 1;
  });

  const tableHeader: { [key: string]: any }[] = tableHeaderGenerator(maxCol);
  data.forEach(element => {
    tableHeader[element.colIdx].width = element.width;
  });

  return tableHeader;
}

export function tableHeaderToHeaderConverter(data: { [key: string]: any }[]) {
  const header: { [key: string]: any }[] = [];
  data.forEach((element, elementIndex) => {
    if (element.width !== 150) {
      header.push({
        colIdx: elementIndex,
        width: element.width,
      });
    };
  });
  return header;
}

export function cellsToTableConverter(data: { [key: string]: any }[], row?: number, column?: number) {
  let maxRow = 40;
  let maxCol = 40;

  data.forEach(item => {
    if (maxRow <= item.rowIdx) maxRow = item.rowIdx + 1;
    if (maxCol <= item.colIdx) maxCol = item.colIdx + 1;
  });

  const table: any = tableGenerator(maxRow, maxCol);
  data.forEach(element => {
    const { alignment, value } = element;
    table[element.rowIdx][element.colIdx] = {
      alignment: isNumeric(value) && (!alignment || alignment === CellAlignment.DEFAULT) ? CellAlignment.RIGHT : alignment,
      value: element.value || isNumeric(element.value) ? element.value : '',
      type: element.isHeader ? 'header' : element.readOnly ? 'default' : 'input'
    };
  });

  return table;
}

export function tableToCellsConverter(data: { [key: string]: any }[][]) {
  const cells: { [key: string]: any }[] = [];
  data.forEach((row, indexRow) => {
    row.forEach((col, indexCol) => {
      if (col.value || isNumeric(col.value) || col.type !== 'default') {
        cells.push({
          rowIdx: indexRow,
          colIdx: indexCol,
          value: col.value || isNumeric(col.value) ? col.value : '',
          alignment: col.alignment,
          showToLogger: false,
          readOnly:
            col.type == 'default' ? true : false,
          isHeader:
            col.type == 'header' ? true : false,
        });
      }
    });
  });
  return cells;
}

export function displayedContentConverter(data: { [key: string]: any }[]) {
  return {
    row: {
      start: data[0].y,
      end: data[data.length - 1].y
    },
    column: {
      start: data[0].x,
      end: data[data.length - 1].x
    }
  };
}

export function generateFormulaTableCellStyle(worksheet: any, type: string) {
  const selectedCell = worksheet.getSelected();
  const allCellStyle = Object.assign({}, worksheet.getStyle());
  const oldStyle: any = {};
  const newStyle: any = {};
  selectedCell.forEach((element: any) => {
    const cell = indexToChar(element.x) + (element.y + 1);
    newStyle[cell] = expandTableDefaultCellStyle[type as keyof typeof expandTableDefaultCellStyle]; //'background-color: #BFC9D9;' 
    for (const key in allCellStyle) {
      if (key === cell) {
        const singleStyle = allCellStyle[key].split(';');
        let finalStyle = '';
        singleStyle.forEach((cssStyle: any) => {
          if (cssStyle.includes('background-color')) finalStyle = cssStyle;
        });
        oldStyle[cell] = finalStyle;
        break;
      };
    };
  });

  return {
    oldStyle,
    newStyle,
  };
}

export const valueParser = (inputValue: string) => {
  if (inputValue) {
    if (inputValue.toString().includes('%')) {
      return `"${(Number(inputValue.split(" ")[0]) / 100).toString()}"`;
    } else {
      return `"${inputValue}"`;
    };
  } else {
    return "";
  };
};

// END OF COMPOSER

export function isNumeric(str: any) {
  return /^-?\d+(.\d+)*$/.test(str);
}

export function checkPath(pathname: string, value: string) {
  return pathname.includes(value);
}

export const findError = (error: string, errorMessage: string) => {
  if (error.includes(errorMessage)) {
    return error;
  }
};

// FUNCTION ACCOUNT MANAGER

function getDate(dateTime: string) {
  return getValue(dateTime ? toLocalDateTime(dateTime) : "-");
}

export const mappingUser = (payload: Array<UserDataI>) => {
  const result: any = payload.map((item: UserDataI) => {
    return {
      id: item.id,
      subscriptionId: item.subscription?.id,
      isActive: item.isActive,
      companyId: item?.companyId ?? item.company?.id,
      name: item.name,
      email: item.email,
      // company: item.company?.name,
      organization: item.personalUserCompany,
      plan: item.subscription.pretty,
      paymentStatus: item.paymentStatus.pretty,
      lastActive: getDate(item.activeAt).content,
    };
  });
  return result;
};

//FUNCTION BILLING DETAILS

export const convertDate = (date: string) => {
  const INVALID_DATE = 'Invalid Date';
  const convertedDate = dayjs(date).format('DD MMM YYYY');
  if (convertedDate != INVALID_DATE) {
    return convertedDate;
  } else {
    return '-';
  }
};

export const mappingBillingDetails = (payload: any) => {
  const result: any = payload.map((item: any) => {
    const formatter = new Intl.NumberFormat('id', {
      style: 'currency',
      currency: item.currency
    });

    const invoice = formatter.format(item.amount).replace(/\D00(?=\D*$)/, '');;
    return {
      id: item.id,
      date: item.startDate ?? '-',
      email: item.user?.email ?? '-',
      plan: item.subscriptionPlan.pretty,
      invoiceTotal: invoice,
      paymentStatus: item.status?.status ?? '-',
      paymentStatusPretty: item.status?.pretty ?? '-'
    };
  });
  return result;
};

export const billingSelectAll = (props: any) => {
  const {
    e,
    data,
    totalData,
    setData,
    setNumSelected,
    setCheckAll
  } = props;
  const selectAll = e.target.checked;
  setData(
    data.map((item: any) => {
      item.select = selectAll;
      if (e.target.checked) {
        setNumSelected(totalData);
        setCheckAll(true);
      } else {
        setNumSelected(0);
        setCheckAll(false);
      }
      return item;
    })
  );
};

export const billingSelect = (props: any) => {
  const {
    e,
    row,
    data,
    numSelected,
    setData,
    setNumSelected
  } = props;
  const select = e.target.checked;
  setData(
    data.map((item: any) => {
      if (row.id === item.id) {
        item.select = select;
        e.target.checked
          ? setNumSelected(numSelected + 1)
          : setNumSelected(numSelected - 1);
      }
      return item;
    })
  );
};

export const handleChangeBillingPaymentStatus = (props: any) => {
  const {
    dispatch,
    selectedData
  } = props;
  try {
    dispatch(
      formModalSetupActionPopUp('CHANGE_BILLING_PAYMENT_STATUS', selectedData)
    );
  } catch (error: any) {
    console.error(error);
  }
};

export const handleAddBillingMember = (props: any) => {
  const {
    dispatch,
    id
  } = props;
  try {
    dispatch(
      formModalSetupActionPopUp('ADD_BILLING_MEMBER', { id })
    );
  } catch (error: any) {
    console.error(error);
  }
};

export const findMatch = (str: string, payload: any) => {
  const result = payload?.replace(
    new RegExp(str, "gi"),
    (match: any) =>
      `<mark style="background: #ffffff;font-weight: 700">${match}</mark>`
  );
  return result;
};

export const replaceMark = async (payload: any) => {
  Object.keys(payload).forEach(key => {
    if (typeof payload[key] === 'string') {
      const val = payload[key].toString();
      payload[key] = val.replace(/<mark style="background: #ffffff;font-weight: 700">/g, '').replace(/<\/mark>/g, '');
    }
  });

  return payload;
};
// LOGGER FUNCTION

export const totalFormula = (arr: any) => {
  const formulaData: any = [];
  arr?.forEach((el: any, index: number) => {
    el.rows.map((a: any, idx: number) => {
      if (a.columns[0].type === "FORMULA") {
        formulaData.push({ ...a.columns[0], page: index, rowIndex: idx });
      }
    });
  });
  return formulaData.length;
};

export const insertLatex = async (latexData: any, loggerFormValues: any) => {
  try {
    loggerFormValues.map((item: any, index: number) => {
      const findPage = latexData.filter(
        (el: any, i: number) => el.page === index
      );
      findPage.map((a: any, i: number) => {
        return {
          pageTitle: item.pageTitle,
          rows: [
            ...item.rows,
            (item.rows[a.rowIndex].columns[0].properties.latex = a.latex),
          ],
        };
      });
    });
    return Promise.resolve(loggerFormValues);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export async function convertBase64(payload: any) {
  return new Promise((resolved, rejected) => {
    toPng(payload, {
      backgroundColor: "#ffffff",
      cacheBust: true,
      quality: 1,
    }).then((dataUrl: any) => {
      setTimeout(() => {
        resolved(dataUrl);
      }, Math.random() * 500);
    });
  });
}

export const convertLatex = async (arr: any) => {
  try {
    const finalData: any = [];
    const mappingLatex = new Promise<void>((resolve) => {
      arr.forEach(async (item: any, index: number) => {
        const base64: any = await convertBase64(item.element);
        finalData.push({
          latex: base64,
          columnId: item.columnId,
          page: item.page,
          rowIndex: item.rowIndex,
        });
        if (finalData.length == arr.length) resolve();
      });
    });
    await Promise.all([mappingLatex]); //.then((response) => { });
    return Promise.resolve(finalData);
  } catch (error: any) { }
};

export const htmlParserHandler = (data: string) => {
  return data ? parser(data) : data;
};

export const dataCurrency = ['IDR', 'USD', 'EUR', 'GBP', 'JPY', 'CNY'];

export const mapCurrencyToSymbol = {
  IDR: 'rupiah',
  USD: 'dollar',
  EUR: 'euro',
  GBP: 'pound',
  JPY: 'yen',
  CNY: 'yen'
};

export const mapCurrencyToHtmlEntities = {
  IDR: 'Rp',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CNY: '¥',
};

export const reverseMapCurrencyToHtmlEntities = {
  'Rp': 'IDR',
  '$': 'USD',
  '€': 'EUR',
  '£': 'GBP',
  '¥': 'JPY',
};


export const changeStringtoPdf = (payload: string) => {
  let strFormatted = "<p>".concat(payload);
  strFormatted = strFormatted.replaceAll("<sup>", "</p><sup>");
  strFormatted = strFormatted.replaceAll("</sup>", "</sup><p>");
  strFormatted = strFormatted.concat("</p>");
  return strFormatted;
};

export const changeValueNumb = (number: any) => {
  const regExp = /[a-zA-Z]/g;
  if (regExp.test(number)) return number;

  if (number.toString().includes('.')) {
    const countComa = number.toString().split('.');
    if (countComa[0].length >= 15) return parseInt(number).toExponential(1);
    if (countComa[1].length > 0) return parseFloat(number).toFixed(countComa[1].length > 3 ? 3 : countComa[1].length);
  }
  if (number.toString().length >= 15) return parseInt(number).toExponential(1);

  return number;
};

export const findRecentCalculation = (arr: any, id: string) => {
  const index: any = arr.findIndex((item: any, index: number) => item.id === id);
  const recentCalculation: any = arr.find((item: any, index: number) => item.id === id);
  return {
    ...recentCalculation,
    index: index
  };
};

export const duplicateValidation = (arr: any[], id: string, value: string) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (id === arr[i][j].id) {
        continue;
      }

      if (value === arr[i][j].variable || value === arr[i][j].notation) {
        return false;
      }
    }
  }

  return true;
};

// check if input have special character "!@#\$%\^\&*\)\(+=._-"and space "\s"
export const notContainSpecialCharacterAndSpace = (value: string, type?: string) => {
  const textString = removeHTMLTag(value);
  const glyphList: string = getSymbol().map(el => el.glyph).join('');
  let regExp = '';

  switch (type) {
    case 'variable':
      regExp = '^([\\w' + glyphList + '])*$';
      break;
    case 'page':
      regExp = '^((?!_)[\\w' + glyphList + '\\s])*$';
      break;
    default:
      regExp = '^((?!_)[\\w' + glyphList + '\'",.!/\\\\])*$';
      break;
  }

  return new RegExp(regExp, 'g').test(textString);
};

export const validateCharAfterNumber = (value: string) => {
  const text = removeHTMLTag(value);
  return new RegExp('^\\D*\\d*$', 'g').test(text);
};

export const formulaConvert = (arr: any, formula: any) => { //change variable with value in latex second line
  const data: any = [];
  const logic = isIf(formula.toLowerCase()) && formula.split(',')[0];
  const trueCondition = isIf(formula.toLowerCase()) && formula.split(',')[1];
  const falseCondition = isIf(formula.toLowerCase()) && formula.split(',')[2].slice(0, -1);

  arr.forEach((el: any) => {
    el.rows.map((a: any) => {
      if (a.columns[0].type !== 'IMAGE' && a.columns[0].type !== 'TEXT' && a.columns[0].type !== 'DIVIDER') {
        data.push({ ...a });
      }
    });
  });

  const variableData = data.map((item: any, i: number) => {
    return {
      variable: item.columns[0].properties.variable,
      value: item.columns[0].properties?.format?.value?.percentage && item.columns[0].value > 1 ? item.columns[0].value / 100 : item.columns[0].value === '' ? null : item.columns[0].value
    };
  }).sort((a: any, b: any) => {
    return b.variable.length - a.variable.length;
  });

  const variable: any = variableData.map((el: any) => {
    return el.variable;
  });

  const value: any = variableData.map((el: any) => {
    return el.value === '0.000' ? undefined : el.value;
  });


  const cmb = (variable: any, value: any) => {
    return variable.reduce((acc: any, val: any, ind: number) => {
      acc[val] = value[ind];
      return acc;
    }, {});
  };

  const re: any = new RegExp(Object.keys(cmb(variable, value)).join("|"), "g");
  let stringFormula: any = "";
  let addedString: any = "";
  let addedIndex = 0;
  if (!isIf(formula.toLowerCase()) || isIf(formula.toLowerCase()) && !trueCondition.includes('"') && !falseCondition.includes('"')) {
    stringFormula = formula;
  } else if (isIf(formula.toLowerCase()) && trueCondition.includes('"') && falseCondition.includes('"')) {
    stringFormula = logic;
    addedString = ',' + trueCondition + ',' + falseCondition + ')';
  } else if (isIf(formula.toLowerCase()) && !trueCondition.includes('"') && falseCondition.includes('"')) {
    stringFormula = logic + ',' + trueCondition + ',';
    addedString = falseCondition + ')';
  } else if (isIf(formula.toLowerCase()) && trueCondition.includes('"') && !falseCondition.includes('"')) {
    stringFormula = logic + ',,' + falseCondition + ')';
  }

  const str: any = stringFormula.replace(re, function (matched: any) {
    return cmb(variable, value)[matched];
  });

  addedIndex = isIf(formula.toLowerCase()) && trueCondition.includes('"') && !falseCondition.includes('"') && str.indexOf(',');

  function spliceFormula(str: string, index: number, count: number, add: string) {
    const array = str.split('');
    array.splice(index, count, add);
    return array.join('');
  }

  return addedIndex ? spliceFormula(str, addedIndex + 1, 0, trueCondition) : str + addedString;
};

export const valueConvert = (arr: any, value: any) => { //get value from notation
  const data: any = [];
  arr.forEach((el: any) => {
    el.rows.map((a: any) => {
      if (a.columns[0].type !== 'IMAGE' && a.columns[0].type !== 'TEXT' && a.columns[0].type !== 'DIVIDER') {
        data.push({ ...a });
      }
    });
  });

  const variableData = data.map((item: any, i: number) => {

    return {
      variable: item.columns[0].properties.variable,
      value: typeof item.columns[0].value === 'string' ? item.columns[0].value : item.columns[0].properties?.format?.value?.percentage && item.columns[0].value > 1 ? item.columns[0].value / 100 : Math.floor(item.columns[0].value) === item.columns[0].value ? (Math.round(item.columns[0].value * 100) / 100) : Math.round(item.columns[0].value * 1000) / 1000
    };
  }).sort((a: any, b: any) => {
    return b.variable.length - a.variable.length;
  });

  const result = variableData.find((item: any) => item.variable === value);
  return result ? result.value : 'Not Found';
};

export const getWindowSize = () => {
  const { width, height } = window.screen;
  return {
    innerWidth: width,
    innerHeight: height
  };
};

export const loggerInputValue = (arr: any) => {
  const data: any = [];
  Array.isArray(arr) && arr.forEach((el: any) => {
    el.rows.map((a: any) => {
      if (a.columns[0].type !== 'IMAGE' && a.columns[0].type !== 'TEXT' && a.columns[0].type !== 'DIVIDER' && a.columns[0].type !== 'TABLE') {
        const convertValue = { ...a.columns[0], ...{ value: a.columns[0].value === null || a.columns[0].value === "" ? 0 : a.columns[0].value } };
        data.push({
          columns: [
            { ...convertValue }
          ]
        });
      }
    });
  });
  return data;
};

export const outsideTable = (data: any, formula: any) => { //change variable with value in latex second line

  const variableData = data.map((item: any, i: number) => {
    return {
      variable: item.columns[0].properties.variable,
      value: item.columns[0].value
    };
  }).sort((a: any, b: any) => {
    return b.variable.length - a.variable.length;
  });

  const variable: any = variableData.map((el: any) => {
    return el.variable;
  });

  const value: any = variableData.map((el: any) => {
    return el.value;
  });

  const cmb = (variable: any, value: any) => {
    return variable.reduce((acc: any, val: any, ind: number) => {
      acc[val] = value[ind];
      return acc;
    }, {});
  };

  const re: any = new RegExp(Object.keys(cmb(variable, value)).join("|"), "g");

  const str: any = formula.replace(re, function (matched: any) {
    return cmb(variable, value)[matched];
  });

  return str;
};

export const checkMultiLine = (arr: any, columnId: string) => {
  const response: any = arr.find((item: any) => item.columnId === columnId);
  const multiLine: boolean = response?.multiLine;
  return multiLine;
};

export const checkLatexProps = (arr: any) => {
  const data: any = [];
  Array.isArray(arr) && arr.forEach((el: any) => {
    el.rows.map((a: any) => {
      if (a.columns[0].type === "FORMULA" && a.columns[0].properties.latex === null) {
        data.push(a);
      }
    });
  });
  return data;
};

export const isNestedIf = (formula: string) => {
  let result = false;
  const items = formula.substring(1).split(",");
  items.forEach((item, index) => {
    if (index > 0 && item.match(/\b(IF\()/g)) { result = true; return; }
  });
  return result;
};

// ETC

export function multiDimensionalBreaker(array: any[][]) {
  const result: any[] = [];

  for (const child of array) {
    for (const grandChild of child) {
      result.push(grandChild);
    }
  }

  return result;
}

// SORTING CALCULATION 

export const sortingByDate = (arr: Array<any>) => {
  const newArray = arr.map((item: any) => {
    return {
      ...item,
      date: new Date(item.updatedAt)
    };
  }).sort((a, b) => b.date - a.date);

  return newArray;
};

export const sortMapping = (arr: Array<any>) => {
  const folderData: any = sortingByDate(arr.filter((item: any) => item.itemType === 'FOLDER'));
  const calcData: any = sortingByDate(arr.filter((item: any) => item.itemType === 'CALCULATION'));

  return [...folderData, ...calcData];
};

export const checkFormulaValue = (arr: any) => {
  const data: any = [];
  Array.isArray(arr) && arr?.forEach((el: any) => {
    el?.rows?.map((a: any) => {
      if (a.columns[0].type === "FORMULA") {
        data.push(a.columns[0]);
      }
    });
  });

  const result = data.map((item: any) => {
    return {
      columnId: item.columnId,
      formula: formulaConvert(arr, item.properties.formula),
      isComplete: formulaConvert(arr, item.properties.formula).includes('null') || formulaConvert(arr, item.properties.formula).includes(undefined) || formulaConvert(arr, item.properties.formula).includes('#DIV/0!') || formulaConvert(arr, item.properties.formula).includes('NaN') || valueConvert(arr, item.properties.variable).toString().includes('NaN') ? false : true
    };
  });
  return Object.assign({}, ...(result.map((el: any) => ({ [el.columnId]: el.isComplete }))));
};

export const getRandomNumber = (count: number) => {
  const crypto = window.crypto;
  const typedArray = new Uint8Array(count);
  crypto.getRandomValues(typedArray);
  return typedArray;
};

export const loggerPreviewFormulaConvert = (data: any, formula: any) => {

  const variableData = data.map((item: any, i: number) => {
    return {
      variable: item.variable,
      notation: item.notation
    };
  });

  const variable: any = variableData.map((el: any) => {
    return el.variable;
  });

  const notation: any = variableData.map((el: any) => {
    return el.notation;
  });

  const cmb = (variable: any, notation: any) => {
    return variable.reduce((acc: any, val: any, ind: number) => {
      acc[val] = notation[ind];
      return acc;
    }, {});
  };

  const re: any = new RegExp(Object.keys(cmb(variable, notation)).join("|"), "g");

  const str: any = formula.replace(re, function (matched: any) {
    return cmb(variable, notation)[matched];
  });

  return str;
};

export const handleDisabled = (currentPlan: string, type: string) => {
  switch (type) {
    case FREE: {
      return true;
    }
    case PRO: {
      if ([PRO, BUSINESS, BUSINESS_PLUS, ENTERPRISE, PRO_PLUS].indexOf(currentPlan) >= 0) {
        return true;
      }
      return false;
    }
    case BUSINESS: {
      if ([BUSINESS, BUSINESS_PLUS, ENTERPRISE].indexOf(currentPlan) >= 0) {
        return true;
      }
      return false;
    }
    case ENTERPRISE: {
      return currentPlan === ENTERPRISE;
    }
  }
};


export function handleSubscriptionUsage(current: number, limit: number) {
  const dataLimit = (current ?? 0) / (limit ?? 0) * 100;
  return dataLimit > 100 ? 100 : dataLimit;
}

export const fixDecimal = (arr: any) => {
  return arr.map((a: any) => {
    return {
      ...a,
      rows: a.rows.map((b: any) => {
        return {
          ...b,
          columns: b.columns.map((c: any) => {
            return {
              ...c,
              value: c.type !== 'NUMERICAL_INPUT' ? c.value : c.value === undefined ? undefined : parseFloat(c.value)
            };
          })

        };
      })
    };
  });
};

export const isIf = (formula: string) => {
  if (formula?.includes('if(')) {
    return true;
  } else {
    return false;
  }
};


export const formatPercentage = (value: number, decimalPlaces: number, locale = "en-GB") => {
  return Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(value);
};

export const handleChangePercentage = (values: any, callback: any) => {
  if (values?.floatValue == 0 || !values?.floatValue) {
    /**
     * need to execute callback when user delete/reset input percentage
     * so it will trigger input field changes and error
     */
    return callback('');
  } else {
    return callback(values?.floatValue / 100);
  }
};


export const checkSubscriptionAndDate = (subscription?: any, userLimit?: any, dispatch?: any, bypassFunction?: any) => {
  if (userLimit.calculations >= userLimit.limit && userLimit.limit !== null) {
    dispatch(formModalSetupActionPopUp('UPGRADE_PLANS_POPUP', subscription));
  } else {
    return true;
  }


};

export const checkSubscriptionMultipleSelect = (subscription?: any, userLimit?: any, totalItems?: any, dispatch?: any) => {
  if (userLimit.limit !== null) {
    const remainingCalculations = userLimit.limit - userLimit.calculations;
    if (totalItems > remainingCalculations) {
      dispatch(formModalSetupActionPopUp('UPGRADE_PLANS_POPUP', subscription));
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const handleCheckScreen = () => {
  const { innerWidth: width, innerHeight: height } = window;
  if (width < 768) {
    return true;
  } else {
    return false;
  }
};


export const toggleSelection = (taskId: any, selectedTaskIds: any) => {
  if (selectedTaskIds !== undefined) {
    const wasSelected = selectedTaskIds.includes(taskId);
    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }
      // Task was part of a selected group and become the only selected item
      if (selectedTaskIds?.length > 1) {
        return [taskId];
      }
      // task was previously selected but not in a group
      // clear the selection
      return [];
    })();
    return newTaskIds;

  }
};

//check windows or mac
export const wasToggleInSelectionGroupKeyUsed = (e: any) => {
  const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
  return isUsingWindows ? e.ctrlKey : e.metaKey;
};

/**
 * Toggle selection in group
 */
export const toggleSelectionInGroup = (taskId: any, selectedTaskIds: any) => {
  if (selectedTaskIds !== undefined || selectedTaskIds !== null) {
    const index = selectedTaskIds.indexOf(taskId);
    // if not selected - add it to the selected items
    if (index === -1) {
      return [...selectedTaskIds, taskId];
    }
    // previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds];
    shallow.splice(index, 1);
    return shallow;

  }
};


export const isSelected = (id: number, selectedTaskIds: any) => {
  if (id !== undefined && selectedTaskIds !== undefined) {
    return selectedTaskIds.indexOf(id) !== -1;
  }
};

export const checkIndex = (selectedTaskIds: any, destination: any) => {
  return selectedTaskIds.find((id: any) => id === destination.index);
};

// Latex Section
export const checkFormulaValueNew = (arr: any) => {
  const data: any = [];
  Array.isArray(arr) && arr.forEach((el: any) => {
    el.components.map((a: any) => {
      if (a.type === "FORMULA") {
        data.push({ ...a });
      }
    });
  });

  const result = data.map((item: any) => {
    return {
      columnId: item.columnId,
      formula: formulaConvertNew(arr, item.properties.formula),
      isComplete: formulaConvertNew(arr, item.properties.formula).includes('null') || formulaConvertNew(arr, item.properties.formula).includes(undefined) || formulaConvertNew(arr, item.properties.formula).includes('#DIV/0!') || formulaConvertNew(arr, item.properties.formula).includes('NaN') ? false : true
    };
  });
  return Object.assign({}, ...(result.map((el: any) => ({ [el.columnId]: el.isComplete }))));
};


export const valueConvertNew = (arr: any, value: any) => { //get value from notation
  const data: any = [];
  Array.isArray(arr) && arr.length > 0 && arr?.forEach((el: any) => {
    el.components.map((a: any) => {
      if (a.type === 'FORMULA') {
        data.push({ ...a });
      }
    });
  });

  const variableData = data.map((item: any, i: number) => {
    const decimalDigit: number = item.value === '' || item.value === null || item.value === undefined || typeof item.value === 'string' ? 0 : item.properties.format?.value?.decimal !== undefined ? item.properties.format?.value?.decimal : 3;
    return {
      variable: item.properties.notation,
      value: item.properties?.format?.value?.percentage && item.value > 1 ? item.value / 100 : item.value === '' || item.value === null ? 0 : typeof item.value === 'string' ? item.value : roundFunction(item.value, decimalDigit)
    };
  }).sort((a: any, b: any) => {
    return b.variable.length - a.variable.length;
  });

  const result = variableData.find((item: any) => item.variable === value);
  return result ? result.value : 'Not Found';
};

export const formulaConvertNew = (arr: any, formula: any) => { //change variable with value in latex second line
  const data: any = [];
  const logic = isIf(formula?.toLowerCase()) && formula?.split(',')[0];
  const trueCondition = isIf(formula?.toLowerCase()) && formula.split(',')[1];
  const falseCondition = isIf(formula?.toLowerCase()) && formula?.split(',')[2].slice(0, -1);

  Array.isArray(arr) && arr.length > 0 && arr?.forEach((el: any) => {
    el.components.map((a: any) => {
      if (a.type !== 'IMAGE' && a.type !== 'TEXT' && a.type !== 'DIVIDER' && a.type !== 'DATE_INPUT') {
        data.push({ ...a });
      }
    });
  });

  const variableData = data.map((item: any, i: number) => {
    const sessionStorageDecimal = sessionStorage.getItem(`input_numerical__id_${item.columnId}_decimal_number`);
    const decimalDigit: number = item.value === '' || item.value === null || item.value === undefined || !isNumeric(item.value) ? 0 : sessionStorageDecimal ? parseInt(sessionStorageDecimal) : isNumeric(item.properties.format?.value?.decimal) ? item.properties.format?.value?.decimal : 3;
    const decimalPercentage: any = checkDecimalPercentage(item);
    return {
      variable: item.properties.variable,
      value: item.type === 'FORMULA' ? roundFunction(item.value, item.properties.format?.value?.decimal === undefined ? countDecimal(item.value) : item.properties.format?.value?.decimal) : item.properties?.format?.value?.percentage && item.value === null ? null : item.properties?.format?.value?.percentage && item.value !== null ? parseFloat(roundFunction(item.value, decimalPercentage)) : item.value === '' || item.value === null ? null : !isNumeric(item.value) ? item.value : roundFunction(item.value, decimalDigit)
    };
  }).sort((a: any, b: any) => {
    return b.variable?.length - a.variable?.length;
  });

  const variable: any = variableData.map((el: any) => {
    return el.variable;
  });

  const value: any = variableData.map((el: any) => {
    return el.value;
  });


  const cmb = (variable: any, value: any) => {
    return variable.reduce((acc: any, val: any, ind: number) => {
      acc[val] = value[ind];
      return acc;
    }, {});
  };

  const re: any = new RegExp(Object.keys(cmb(variable, value)).join("|"), "g");
  let stringFormula: any = "";
  let addedString: any = "";
  let addedIndex = 0;
  if (!isIf(formula?.toLowerCase()) || isIf(formula?.toLowerCase()) && !trueCondition.includes('"') && !falseCondition.includes('"')) {
    stringFormula = formula;
  } else if (isIf(formula?.toLowerCase()) && trueCondition.includes('"') && falseCondition.includes('"')) {
    stringFormula = logic;
    addedString = ',' + trueCondition + ',' + falseCondition + ')';
  } else if (isIf(formula?.toLowerCase()) && !trueCondition.includes('"') && falseCondition.includes('"')) {
    stringFormula = logic + ',' + trueCondition + ',';
    addedString = falseCondition + ')';
  } else if (isIf(formula?.toLowerCase()) && trueCondition.includes('"') && !falseCondition.includes('"')) {
    stringFormula = logic + ',,' + falseCondition + ')';
  }

  const str: any = stringFormula?.replace(re, function (matched: any) {
    return cmb(variable, value)[matched];
  });

  addedIndex = isIf(formula?.toLowerCase()) && trueCondition.includes('"') && !falseCondition.includes('"') && str.indexOf(',');

  function spliceFormula(str: string, index: number, count: number, add: string) {
    const array = str.split('');
    array.splice(index, count, add);
    return array.join('');
  }

  return addedIndex ? spliceFormula(str, addedIndex + 1, 0, trueCondition) : str + addedString;
};


export function isValidURL(string: string) {
  if (!string) return;
  const res = string.match(
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export const removeHTMLTag = (text: string) => {
  return text.replace(/<[^>]*>/g, '').trim();
};

export const handleSearchCalculation = async (
  data: string[],
  value: string,
  id: string,
  setFilteredData: React.Dispatch<React.SetStateAction<string[]>>,
  setSearchResult: React.Dispatch<React.SetStateAction<string>>,
  setSearchError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const CHAR_ZERO = 0;
  const MIN_SEARCH_CHAR = 3;
  const service = new Service('calculations');
  if (value.length >= MIN_SEARCH_CHAR) {
    try {
      const searchFilter = await service.getSearchCalculation(value, id);

      setFilteredData(searchFilter);
      setSearchError(false);
      setSearchResult(value);
    } catch {
      setSearchError(true);
    }
  }

  if (value.length <= CHAR_ZERO) {
    setFilteredData(data);
    setSearchError(false);
    setSearchResult('');
  }
  return;
};

export function fieldTypeActiveCheck(dataBodyOfCalculationCloned: any, activePage: any, activeId: any) {
  return dataBodyOfCalculationCloned[activePage].filter(
    (el: any) => {
      if (
        (el.id === activeId &&
          el.fieldType === 'RADIO_BUTTON') ||
        (el.id === activeId &&
          el.fieldType === 'DROPDOWN')
      ) {
        return true;
      }
    }
  ).length > 0;

}

export function containsNumbers(str: string) {
  return /\d/.test(str);
};

export function colorPaymentStatus(paymentStatus: string) {
  switch (paymentStatus) {
    case 'Paid':
      return '#2CC077';
    case 'Waiting for Payment':
      return '#F67650';
    case 'Failed':
      return '#DE294C';
    default:
      return null;
  }
}

const errorFormulaCheck = (error: string) => {
  return Boolean(/(#NUM!|#ERROR!|#DIV\/0|#N\/A|#NAME?|#REF|#VALUE!)/.test(error));
};

export const handleSelectCountryCode = (
  row: number,
  countryCode: any,
  setCountryCode: any,
  setSelectedCountryCode: any
) => {
  setCountryCode(countryCode.map((item: any, index: number) => {
    if (row === index) {
      setSelectedCountryCode(item.countryCode);
      return {
        index: index,
        checked: true,
        regionCode: item.regionCode,
        countryCode: item.countryCode,
        displayCountry: item.displayCountry
      };
    } else {
      return {
        index: index,
        checked: false,
        regionCode: item.regionCode,
        countryCode: item.countryCode,
        displayCountry: item.displayCountry
      };
    }
  }));
};

export const removeDuplicates = (array: Array<any>, key: string) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i: any) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

export const validatePassword = (password: string) => {
  const pattern = /(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?\W).*$/;
  return pattern.test(password);
};

export const sanitizeString = (string: string) => {
  // todo: dynamic allowed tag
  return DOMPurify.sanitize(string, {
    ALLOWED_TAGS: ['sub', 'sup']
  });
};

export const extractNumberFormat = (string: string) => {
  if (!!string) {
    const format = string.match(/[#|0]+([,.][#|0]+)+([,.][#|0]+)?/);
    const unit = string.replace(/[#|0]+([,.][#|0]+)+([,.][#|0]+)?/, "");
    if (format) {
      return {
        format: format[0],
        currency: unit
      };
    }
    return null;
  } else {
    return null;
  }
};

export const currencyCellFormatter = (existingFormat: {
  format: string;
  currency: string;
} | null, option: string | null | undefined, metaName: string | null) => {
  // existingFormat : currenct cell masking : string | undefined
  // format: option | null

  // type = currency & select = null => reset currency format
  // type = currency & select = option => apply currency format
  switch (Boolean(existingFormat)) {
    case true:
      if (!!option && (metaName === META_NAME.PREFIX || !metaName)) return `${option}${existingFormat?.format}`;
      else if (!!option && (metaName === META_NAME.SUFFIX)) return `${existingFormat?.format}${option}`;
      else return existingFormat?.format;
    case false:
      if (!!option) return `${option}#,##`;
      else return null;
  }
};

export const numericCellformatter = (existingFormat: {
  format: string;
  currency: string;
} | null, option1: string | null | undefined, option2: string | null | undefined) => {
  // existingFormat : currenct masking : string | undefined
  // option1 : suffix | prefix
  // option2: w/ decimal place | w/o decimal place

  // type = number & select = null => reset number format
  // type = number & select = option => apply number format
  switch (Boolean(!!existingFormat)) {
    case true:
      if (!!option1 || !!option2) {
        if (option1 === "prefix") return `${existingFormat?.currency}${option2}`;
        else if (option1 === "suffix") return `${option2}${existingFormat?.currency}`;
        else return null;
      } else {
        if (existingFormat?.currency !== "") return `${existingFormat?.currency}#,##`;
        else return null;
      }
    case false:
      if (!!option1 || !!option2) return option2;
      else return null;
  }
};

function insertAtIndex(str: string, substring: string, index: number) {
  return str.slice(0, index) + substring + str.slice(index);
}

export const setDecimalMask = (decimal: any) => {

  const defaultMask = '#,##0.';
  return insertAtIndex(defaultMask, Array.from(Array(decimal).keys()).map(() => 0).join().replace(/,/g, "").toString(), defaultMask.length + 1);;
};

export const isEmptyString = (value: string) => !Boolean(value?.replace(/<p>|[</p>]/g, ''));

export const getCellDecimalLength = (value1: number, value2: number) => {
  if (value1 > value2) {
    return value1;
  } else return value2;
};

export const validateDecimalFormat = (value: string) => {
  const stringLength = value.length;
  if (value.charAt(stringLength - 1) === '.') return value.slice(0, -1);
  else return value;
};

export const getKnowledgeBaseUrlParams = () => {
  return new Promise((resolve) => {
    const { subscription, companyId } = getUserDetails();
    const currentPage = window.location.pathname

    if (currentPage.split('/')[2] === 'enterprise') {
      if (subscription.plan === 'ACE_ENTERPRISE' && companyId) {
        resolve({
          params: {
            companyId
          }
        })
      } else {
        resolve({})
      }
    } else {
      resolve({})
    }
  })
}

export function getWordStr(str: string, count: number) {
  return str.split(/\s+/).slice(0, count).join(" ");
}
