import React from 'react';
import { styled, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const TextArea = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    '& fieldset': {
      borderColor: '#E0E6EB'
    },
    '&:hover input, &:hover fieldset': {
      borderColor: '#E0E6EB'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#E0E6EB',
      backgroundColor: 'transparent'
    },
    '&.Mui-disabled input': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: '#E0E6EB',
      '::placeholder': {
        color: theme.palette.text.disabled
      }
    }
  },
  '& textarea, & input': {
    fontFamily: 'Inter',
    fontSize: 12,
    color: 'rgba(53, 60, 104, 1)',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      borderColor: theme.palette.primary.main
    },
    lineHeight: 1.5,
    borderRadius: 6
  }
}));

export const useStyles = makeStyles(() => ({
  textarea: {
    resize: 'both'
  },
  fixedTextarea: {
    resize: 'none'
  }
}));

export const StyledTextArea = React.forwardRef(function CustomInput(
  props: TextFieldProps | any,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const classes = useStyles();
  return (
    <TextArea
      {...props}
      ref={ref}
      inputProps={{
        className: props.resize ? classes.fixedTextarea : classes.textarea
      }}
      style={props.style}
    />
  );
});
