import {
  CALIDNAME,
  CALNAME,
  UPDATE_CAL_INFO,
  CalIdAction,
  CalNameAction,
  UpdateCalInfoI,
} from "../types/calInfoTypes";

interface initialStateI {
  calId: string;
  calName: string;
  calInputFiles: number;
  calApproval: number;
  calDescription: string;
  id: string;
}

const initialState: initialStateI = {
  calId: "",
  calName: "",
  calInputFiles: 0,
  calApproval: 0,
  calDescription: "",
  id: "",
};

type Action = CalIdAction | CalNameAction | UpdateCalInfoI;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case CALIDNAME:
      return {
        ...state,
        calId: action.calId,
        calName: action.calName,
        calInputFiles: action.calInputFiles,
        calApproval: action.calApproval,
        calDescription: action.calDescription,
        id: action.id,
      };
    case CALNAME:
      return { ...state, calName: action.calName };
    case UPDATE_CAL_INFO:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
