import { Switch, SwitchProps, styled } from "@mui/material";

interface ToggleProps extends SwitchProps {
  size: 'small' | 'medium'
}

const DefaultToggle = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
), {
  shouldForwardProp: (props) => props !== "size"
})<SwitchProps>(({ theme, size }) => ({
  width: size === "small" ? 28 : 48,
  height: size === "small" ? 16 : 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: size === "small" ? 3 : 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: size === "small" ?'translateX(12px)' : 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary,
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary,
      border: `6px solid ${theme.palette.common}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: "#FFFFFF"
    },
    '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#D1D9E0',
        opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: size === "small" ? 10 : 22,
    height: size === "small" ? 10 : 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: size === "small" ? 16 /2 : 26 / 2,
    backgroundColor: '#8B98B1',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function StyledToggle({size, ...props}: ToggleProps) {
  return <DefaultToggle size={size} {...props}/>;
}

StyledToggle.defaultProps = {
  size: "small"
};