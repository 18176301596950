import { IAppConfig } from "../service/AppConfigService";

export const errorSubscription = async (error?: any) => {
  const appConfigString = localStorage.getItem("appConfig");
  if (appConfigString) {
    const { rekavaAdminWA, rekavaWAMessage }: IAppConfig =
      JSON.parse(appConfigString);
    const url = `https://wa.me/${rekavaAdminWA}?text=${rekavaWAMessage}`;
    window.open(url, "_blank", "noreferrer");
  }
  console.error(error);
};