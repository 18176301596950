import { FormControl, MenuItem, Tooltip } from '@mui/material';
import Icon from '../../../../../components/atoms/Icon';

import { HorizontalList } from './styles';

type ToolbarProps = {
  anchor: HTMLDivElement | null;
  actions: any[];
  onCloseMenu: () => void;
};

const InputToolbar = ({ anchor, actions, onCloseMenu }: ToolbarProps) => {
  return (
    <HorizontalList
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onCloseMenu}
    >
      {actions.map((action: any, index: number) => {
        return action.type === 'default' ? (
          <Tooltip
            title={action.title}
            key={`toolbar-menu-${action.title}-${index}`}
          >
            <MenuItem onClick={e => action.onClick(e, action.title)}>
              <Icon icon={action.icon} />
            </MenuItem>
          </Tooltip>
        ) : (
          <></>
        );
      })}
    </HorizontalList>
  );
};

export default InputToolbar;
