import { styled } from "@mui/material";

export const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '11px 44px',
  border: '1px dashed white',
  borderRadius: 4,
  backgroundColor: theme.palette.common.white,
  minHeight: "854px",
  // borderBottom: "2px dashed #F0F2F5",
  marginBottom: "12px",
}));

export const ReferenceWrapper = styled('div')({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  marginTop: "4px",
  // padding : "80px 0",

  "& > .reference": {
    width: "58px"
  },
  "& > .referenceValue": {
    width: "304"
  },
});