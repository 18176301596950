import * as React from "react";
import { SVGProps } from "react";

export const HelpOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
      <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3"></path>
      <path d="M12 17h.01"></path>
    </g>
  </svg>
);

