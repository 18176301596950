import { styled } from "@mui/material";

export const MenuWrapper = {
    backgroundColor: "#E3F3FF",
    borderRadius: 12,
    padding: 16,
};

export const UserProfile = {
    display: "flex",
    paddingBottom: 16,
    alignItems: "center"
};

export const ImageUserProfile = {
    height: 35,
    width: 35,
    objectFit: "scale-down",
    borderRadius: 50,
};

export const UserDetails = {
    fontWeight: 400,
    color: "inherit",
    paddingLeft: 16,
    justifyContent: 'center',
    paddingTop: 3,
    overflowWrap: 'anywhere' as const
};

export const SidebarContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& > #newFeature": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F67650',
        borderRadius: 6,
        padding: '4px 8px',
        alignItems: 'center',
    }
});