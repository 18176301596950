import * as actions from './type';

import FolderService, { FOLDER_ITEM_TYPE } from "../../../service/FolderService";

import { Dispatch } from "redux";
import { initialState } from "./reducer";

export const getFolderTree = (folderId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.GetTreeChild());
    try {
      const data = await FolderService.view(folderId, FOLDER_ITEM_TYPE.FOLDER, true);
      dispatch(actions.GetTreeChildSuccess(data.items));
    } catch (error: any) {
      dispatch(actions.GetTreeChildFailed(error));
    }
  };
};

export const getFolderTreeView = (folderId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.GetTreeChild());
    try {
      const data = await FolderService.view(folderId, FOLDER_ITEM_TYPE.FOLDER, true);
      dispatch(actions.GetTreeChildViewSuccess(data.items));
    } catch (error: any) {
      dispatch(actions.GetTreeChildFailed(error));
    }
  };
};

export const setSelectedTreeItem = (folderId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.SetSelectedTreeItem(folderId));
  };
};

export const getTreeNodes = () => {
  const data = localStorage.getItem('sidebar');
  const { folderTree, selectedItem } = data ? JSON.parse(data) : initialState;

  return { folderTree, selectedItem };
};

export const initTreeState = (userType: string, pathname?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.InitTreeState(userType, pathname));
  };
};

export const updateTreeState = (newState: any, id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.UpdateTreeState(newState, id));
  };
};
