export const INIT_TREE_STATE = 'INIT_TREE_STATE';
export const GET_TREE_CHILD = 'GET_TREE_CHILD';
export const GET_TREE_CHILD_SUCCESS = 'GET_TREE_CHILD_SUCCESS';
export const GET_TREE_CHILD_FAILED = 'GET_TREE_CHILD_FAILED';
export const SET_SELECTED_TREE_ITEM = 'SET_SELECTED_TREE_ITEM';
export const UPDATE_TREE_STATE = 'UPDATE_TREE_STATE';
export const GET_TREE_CHILD_VIEW_SUCCESS = 'GET_TREE_CHILD_VIEW_SUCCESS';


export const InitTreeState = (userType: string, pathname?: string) => {
  return {
    type: INIT_TREE_STATE,
    userType,
    pathname
  };
};

export const GetTreeChild = () => {
  return {
    type: GET_TREE_CHILD,
  };
};

export const GetTreeChildSuccess = (items: any) => {
  return {
    type: GET_TREE_CHILD_SUCCESS,
    items
  };
};

export const GetTreeChildViewSuccess = (items: any) => {
  return {
    type: GET_TREE_CHILD_VIEW_SUCCESS,
    items
  };
};

export const GetTreeChildFailed = (error: string) => {
  return {
    type: GET_TREE_CHILD_FAILED,
    error,
  };
};

export const SetSelectedTreeItem = (id: string) => {
  return {
    type: SET_SELECTED_TREE_ITEM,
    id
  };
};

export const UpdateTreeState = (newState: any, id: string) => {
  return {
    type: UPDATE_TREE_STATE,
    newState,
    id
  };
};
