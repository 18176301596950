import {
  ACE,
  BUSINESS,
  BUSINESS_PLUS,
  FREE,
  PRO,
  PRO_PLUS
} from '../../../utils/constant';
import { Box, Divider } from '@mui/material';
import { MenuWrapper, UserDetails, UserProfile } from './elements';
import {
  getUserCalculationLimit,
  getUserProfileAction
} from '../../../store/actions/userAction';

import Button from '../../atoms/Button';
import Loading from '../../atoms/loading';
import { LoadingContainer } from '../../organisms/layouts/elements';
import Logo from '../../atoms/Logo';
import Pill from '../../atoms/Pill';
import Text from '../../atoms/Text';
import { UsageBar } from './UsageBar';
import dayjs from 'dayjs';
import images from '../../../assets';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getIsConsents } from '../../../utils/storage';

export default function DashboardPanel(props: any) {
  const {
    isMinimize,
    userPhoto,
    userDetails,
    loading,
    userName,
    userLimit,
    subscription,
    userType,
    isMobile
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const expiredTime = dayjs(subscription?.pro_TRIAL_EXPIRED_TIME).format(
    'DD MMMM YYYY'
  );
  const now = dayjs().utc().local().format('DD MMMM YYYY');
  const isExpired = dayjs(now).isAfter(expiredTime);
  const isAceSubscriber = /ACE/.test(userType);
  const isConsent = getIsConsents().isConsent;

  const handleAccountSettings = () => {
    history.push('/calculations/accountSettings');
  };

  const handleSubscription = () => {
    history.push('/calculations/subscriptionPage');
  };

  const getPillBackgroundColor = (plan: string): string => {
    switch (plan) {
      case BUSINESS:
      case BUSINESS_PLUS:
      case ACE:
        return '#7A5CED';
      case PRO:
      case PRO_PLUS:
        return '#03C56F';
      case FREE:
        return '#B1E3FF';
      default:
        return 'transparent';
    }
  };

  const getPillColor = (plan: string): string => {
    switch (plan) {
      case BUSINESS:
      case BUSINESS_PLUS:
      case PRO:
      case PRO_PLUS:
      case ACE:
        return '#F6F5FE';
      case FREE:
        return '#028BFF';
      default:
        return '#000000';
    }
  };

  const subscriptionPlanName = () => {
    switch (subscription?.plan) {
      case PRO_PLUS:
        return 'PRO';
      case BUSINESS_PLUS:
        return 'BUSINESS';
      default:
        return subscription?.pretty.toUpperCase();
    }
  };

  const dashboardPanelMenuAction = [
    {
      title: 'Account Settings',
      icon: images.settingIcon,
      onClick: handleAccountSettings
    },
    {
      title: 'Subscription',
      icon: images.diamondIcon,
      onClick: handleSubscription,
      pill: (
        <Pill
          text={subscriptionPlanName()}
          role={subscription?.plan}
          style={{
            height: 20,
            padding: '4px 8px',
            borderRadius: 10,
            backgroundColor: getPillBackgroundColor(subscription?.plan),
            color: getPillColor(subscription?.plan)
          }}
        />
      )
    }
  ];
  useEffect(() => {
    if (isConsent) {
      dispatch(getUserProfileAction());
      dispatch(getUserCalculationLimit());
    }
  }, [userDetails?.name, userName]);

  const progressMenuAction = [
    {
      title: 'Calculation files',
      color: 'inherit',
      variant: 'body3'
    },
    {
      title:
        subscription && subscription?.plan === 'PRO'
          ? 'Unlimited'
          : `${userLimit?.calculations ?? 0}/${userLimit?.limit ?? 0}`,
      color: 'inherit',
      variant: 'body3'
    }
  ];

  const handleUpgradePlan = () => {
    history.push('/calculations/subscriptionPage');
  };

  if (!subscription?.plan) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <>
      {isMinimize ? (
        <> </>
      ) : (
        <Box
          style={{
            marginTop: 'auto',
            minWidth: isMinimize ? 68 : 270,
            width: isMobile ? '90%' : 270,
            padding: 12,
            ...(isMobile && { alignSelf: 'center' })
          }}
        >
          <Box style={MenuWrapper}>
            <Box style={UserProfile}>
              <img
                style={{
                  height: 35,
                  width: 35,
                  objectFit: 'scale-down',
                  borderRadius: 50
                }}
                src={
                  userPhoto ? (loading ? userPhoto : userPhoto) : images.avatar
                }
                alt="avatar"
              />
              <Text style={UserDetails} variant="body1">
                {userName}
              </Text>
            </Box>
            <Box>
              <Divider />
              <Box style={{ paddingBottom: 17 }}>
                {(isAceSubscriber ? dashboardPanelMenuAction.filter(el => el.title !== 'Subscription') : dashboardPanelMenuAction).map(item => {
                  return (
                    <Box
                      key={item.title}
                      style={{
                        display: 'flex',
                        paddingTop: 17,
                        cursor: 'pointer'
                      }}
                      onClick={e => item.onClick()}
                    >
                      <Logo src={item.icon} width={20} />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flex: 1
                        }}
                      >
                        <Text
                          style={{ color: 'inherit', paddingLeft: 10 }}
                          variant="body1"
                        >
                          {item.title}
                        </Text>
                        <div style={{ paddingLeft: 16 }}>{item.pill}</div>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Divider />
              <Box style={{ paddingBottom: 16, paddingTop: 16 }}>
                {!isAceSubscriber &&
                  <>
                    <Box style={{ paddingBottom: 8 }}>
                      <Text
                        style={{
                          paddingTop: 17,
                          paddingBottom: 18,
                          color: '#A3B3C2'
                        }}
                        variant="smallBold"
                      >
                        USAGE
                      </Text>
                    </Box>
                    <UsageBar
                      personal={{
                        limit: userLimit?.limit ?? 0,
                        current: userLimit?.calculations ?? 0
                      }}
                      plan={subscription?.plan}
                      teamDriveMember={{
                        limit: userLimit?.driveMemberLimit ?? 0,
                        current: userLimit?.currentDriveMember ?? 0
                      }}
                      calcPerDrive={{
                        limit: userLimit?.ownedCalculationLimitPerDrive ?? 0,
                        current: userLimit?.currentCalculationFilePerDrive ?? 0
                      }}
                    />
                  </>}
              </Box>
              {isAceSubscriber ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => undefined}
                >
                  <Text variant="h5" color="inherit" textTransform="capitalize">
                    Train Ace
                  </Text>
                </Button>
              ) :
                subscription?.plan === PRO && !isExpired ? (
                  <>
                    <Text variant="h4" color="inherit" textAlign="center">
                      Pro Until {expiredTime}
                    </Text>
                  </>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={handleSubscription}
                  >
                    <Text variant="h5" color="inherit" textTransform="capitalize">
                      View Plans
                    </Text>
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
