import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
  } from '@mui/material';
  import TypographyComponent from '../../atoms/Typography';
  import {
    headerIconStyle,
    triangleShapeUp,
    triangleShapeDown,
    WrapperCenterCell,
    HeaderTableRow,
    BodyTableRow,
    TableWrapper,
    HeaderTableCell,
    BodyTableCell,
    ErrorTableCell
  } from './elements';
  import Checkbox from '../../atoms/Checkbox';
  import { useEffect, useState } from 'react';
  import {
    convertDate,
    billingSelect,
    billingSelectAll
  } from '../../../utils/helpersFunction';
  import dayjs from 'dayjs';
  
  export default function BillingTable(props: any) {
    const {
      basicHeader,
      tableData,
      arrowOnClick,
      onMouseOut,
      onMouseOver,
      errMessage,
      numSelected,
      setNumSelected,
      setSelectedData
    } = props;
    const createMarkup = (html: any) => {
      return { __html: html };
    };
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const totalData = tableData.length;
    const PAID = 'Paid';
    const WAITING_FOR_PAYMENT = 'Waiting for Payment';
    const FAILED = 'Failed';
    const colors = {
      default: { color: '#29333D' },
      paid: { color: '#2CC077', fontWeight: 'bold' },
      waiting: { color: '#F67650', fontWeight: 'bold' },
      failed: { color: '#DE294C', fontWeight: 'bold' }
    };
  
    useEffect(() => {
      setCheckAll(false);
      setNumSelected(0);
      setData(
        tableData
          .map((item: any, index: number) => {
            return {
              select: false,
              id: item.id,
              date: item.date,
              datePretty: convertDate(item.date),
              email: item.email,
              plan: item?.plan,
              invoiceTotal: item.invoiceTotal,
              paymentStatusPretty: item.paymentStatusPretty,
              paymentStatus: item.paymentStatus
            };
          })
          .sort((a: any, b: any) => {
            if (a.date > b.date) return -1;
            else if (a.date < b.date) return 1;
            return 0;
          })
      );
    }, [tableData]);
  
    useEffect(() => {
      const filter = data.filter((item: any) => item.select);
      setSelectedData(filter);
    }, [numSelected]);
  
    return (
      <TableWrapper>
        <Table size="small" style={{ flex: 2 }}>
          <TableHead>
            <TableRow sx={HeaderTableRow}>
              <TableCell padding="checkbox">
                <Checkbox
                  label=""
                  onChange={e =>
                    billingSelectAll({
                      e,
                      data,
                      totalData,
                      setData,
                      setNumSelected,
                      setCheckAll
                    })
                  }
                  id="selectAll"
                  checked={checkAll}
                />
              </TableCell>
              {basicHeader.map((header: any, i: number) => {
                return (
                  <HeaderTableCell 
                  headerWidth={header.width}
                  key={i}>
                    <TypographyComponent
                      variant={'SUBTITLE_2'}
                      style={{ fontWeight: 'bold' }}
                    >
                      {header.label}
                    </TypographyComponent>
                    {header.icon === 'ARROW' && (
                      <Box sx={headerIconStyle}>
                        <div
                          style={triangleShapeUp}
                          onMouseOver={onMouseOver}
                          onMouseOut={onMouseOut}
                          onClick={e => arrowOnClick(e, 'ASC', basicHeader[i])}
                        />
                        <div
                          style={triangleShapeDown}
                          onMouseOver={onMouseOver}
                          onMouseOut={onMouseOut}
                          onClick={e => arrowOnClick(e, 'DESC', basicHeader[i])}
                        />
                      </Box>
                    )}
                  </HeaderTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data?.map((row: any, i: number) => {
                return (
                  <TableRow sx={BodyTableRow} key={i}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        label=""
                        id={row.id}
                        onChange={e =>
                          billingSelect({
                            e,
                            row,
                            data,
                            numSelected,
                            setData,
                            setNumSelected
                          })
                        }
                        checked={row.select}
                      />
                    </TableCell>
                    {Object.keys(row)
                      .splice(3, 5)
                      .map((key: any, j: number) => {
                        return (
                          <BodyTableCell 
                          bodyWidth={basicHeader[j].width}
                          key={j}>
                            <TypographyComponent
                              variant={'SUBTITLE_2'}
                              dangerouslySetInnerHTML={createMarkup(row[key])}
                              style={
                                row[key] === PAID
                                  ? colors.paid
                                  : row[key] === WAITING_FOR_PAYMENT
                                  ? colors.waiting
                                  : row[key] === FAILED
                                  ? colors.failed
                                  : colors.default
                              }
                            />
                          </BodyTableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <ErrorTableCell>
                <WrapperCenterCell type="NO_RESULT">
                  <TypographyComponent variant={'SUBTITLE_2'}>
                    {errMessage}
                  </TypographyComponent>
                </WrapperCenterCell>
              </ErrorTableCell>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }  