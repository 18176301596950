import {
  ISDRAGGINGTYPECOMPOSER,
  ISEDITINGTYPECOMPOSER,
  ACTIVEPAGETYPECOMPOSER,
  DATAVARIABLEMANAGERMENUTYPECOMPOSER,
  DATAHYPERFORMULATYPECOMPOSER,
  DATAPAGESTYPECOMPOSER,
  DATAPROPERTIESTYPECOMPOSER,
  DATACHEADOFCALCULATIONTYPECOMPOSER,
  DATABODYOFCALCULATIONTYPECOMPOSER,
  EXPORTEVENTCOMPOSER,
  DATASYMBOLCOMPOSER,
  ISFETCHINGDATACOMPOSER,
} from './composerTypes';

import { v4 as uuidv4 } from 'uuid';
import { UseFormSetValue } from 'react-hook-form';
import { Dispatch } from 'redux';

import Service from '../../../service/generic/generic';
import { ReduxState } from '../..';
import { addQueueComposerComponent } from '../../../service/queueManager/queue';
import { toastSetupActionPopUp } from '../../appState/popUp/toast/toastAction';
import { setLoggerPreviewImage } from '../composerChanges/composerChangesActions';
import {
  duplicateValidation,
  notContainSpecialCharacterAndSpace,
  dynamicFormulaConverter,
  arrangeTableName,
  copyArray,
  cellsToTableConverter,
  tableToCellsConverter,
  displayedContentConverter,
  generateFormulaTableCellStyle,
  headerToTableHeaderConverter,
  tableHeaderGenerator,
  tableHeaderToHeaderConverter,
  validateCharAfterNumber,
  valueParser,
  isNumeric
} from '../../../utils/helpersFunction';
import { defaultFormulaTableCellComposer, expandTableDefaultCellStyle } from '../../../utils/constant';
import { CellAlignment } from '../../../components/molecules/FormulaTable';
import { NotationEngine } from '../../../utils/notation-parser';
import { mapGlyphToString } from '../../../utils/notation-parser/glyph-mapper';
import { FROZEN_COLUMN_DEFAULT_WIDTH, FROZEN_COLUMN_LIMIT } from '../../../components/molecules/FormulaTable/toolbar';
import { getCellName } from '../../../components/molecules/FormulaTable/utils';
import { sliceEngine } from '../../../utils/sliceEngine';
import { getUtilSymbol } from '../../../utils/reduxOutside';
import FormulaHandler from '../../../utils/formulaHandler';
import { cleanupCurrencyFormat, formatTableData } from '../../../utils/tableFunction';
import CalculationService from '../../../service/CalculationServices';

const slice = new sliceEngine(getUtilSymbol());

const service = new Service('calculations');
/**
 * Parser
 * do Syntactic analysis of notation input 
 * then return new Variable string based on Define Grammar
 * 
 * TODO:
 * [REVAMP] separate logic into small function (each function have single responsibility)
 */
const notationParserEngine = new NotationEngine(mapGlyphToString);

export function reducerModifierIsDraggingActionComposer(data: boolean) {
  return { type: ISDRAGGINGTYPECOMPOSER, data };
}

export function reducerModifierIsEditingActionComposer(data: boolean) {
  return { type: ISEDITINGTYPECOMPOSER, data };
}

export function reducerModifierActivePageActionComposer(data: number) {
  return { type: ACTIVEPAGETYPECOMPOSER, data };
}

export function reducerModifierDataVariableManagerMenuActionComposer(data: any) {
  return { type: DATAVARIABLEMANAGERMENUTYPECOMPOSER, data };
}

export function reducerModifierDataHyperFormulaActionComposer(data: any) {
  return { type: DATAHYPERFORMULATYPECOMPOSER, data };
}

export function reducerModifierDataPagesActionComposer(data: any) {
  return { type: DATAPAGESTYPECOMPOSER, data };
}

export function reducerModifierDataPropertiesActionComposer(data: any) {
  return { type: DATAPROPERTIESTYPECOMPOSER, data };
}

export function reducerModifierDataHeadOfCalculationActionComposer(data: any) {
  return { type: DATACHEADOFCALCULATIONTYPECOMPOSER, data };
}

export function reducerModifierDataBodyOfCalculationActionComposer(data: any) {
  return { type: DATABODYOFCALCULATIONTYPECOMPOSER, data };
}

export function reducerModifierDataSymbolActionComposer(data: { [key: string]: string | number }[]) {
  return { type: DATASYMBOLCOMPOSER, data };
}

export function reducerModifierExportEventActionComposer(data: any) {
  return { type: EXPORTEVENTCOMPOSER, data };
}

export function reducerModifierIsFetchingActionComposer(data: boolean) {
  return { type: ISFETCHINGDATACOMPOSER, data };
}

// MAIN CONTAINER FUNCTION

export function mainContainerActionComposer() {
  return async (dispatch: any, getState: any): Promise<unknown> => {
    try {
      const resultObj = getState().composer.dataProperties;
      await dispatch(reducerModifierDataPropertiesActionComposer({
        ...resultObj,
        showProperties: false,
        activePage: null,
        indexComponent: null,
        propertiesType: null,
        idComponent: null,

        // USED BY MANY COMPONENT
        description: null,
        variable: null,
        notation: null,
        tooltip: null,
        notes: null,

        // inSummary: null,
        inputValue: null,

        // NUMERICAL
        hardMax: null,
        hardMin: null,
        softMax: null,
        softMin: null,

        // FORMULA
        formula: null,
        reference: null,
        error: null,
        resultHyperFormula: null,
        isDisplayed: null,

        // TABLE
        tableName: null,
        tableHeader: null,
        tableBody: null,
        displayedContent: null,
        freeze: null,
        isAllowedToAddRow: null,
        format: {
          cells: null
        },

        // TEXT
        level: null,

        // IMAGE
        isLocked: true,
        imageUrl: null,
        imageWidth: null,
        imageHeight: null,
        imageType: null,
        imageAlignment: null,

        // DROPDOWN AND RADIOBUTTON
        options: null,

        // NUMERICAL AND FORMULA
        unit: null,

        //LINK
        title: null,
        url: null,
      }));
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  };
}


const ONBLUR_TITLE = 'ONBLUR_TITLE';
const ONBLUR_URL = 'ONBLUR_URL';

// PROPERTIES FUNCTION

export function exportPropertiesActionComposer(payload?: any) {
  return (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const activePage: any = getState().composer.activePage;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const finalArray: any = resultArray[payload.activatedPage ? payload.activatedPage : activePage][payload.indexComponent];

    dispatch(reducerModifierDataPropertiesActionComposer({
      ...resultObj,

      showProperties: payload.typeComponent === 'DIVIDER' ? false : true,
      activePage: payload.activatedPage ? payload.activatedPage : activePage,
      indexComponent: payload.indexComponent,
      propertiesType: payload.typeComponent,
      idComponent: payload.idComponent,

      // USED BY MANY COMPONENT
      description: Boolean(finalArray.description) ? finalArray.description : null,
      variable: Boolean(finalArray.variable) ? finalArray.variable : null,
      notation: Boolean(finalArray.notation) ? finalArray.notation : null,
      tooltip: Boolean(finalArray.tooltip) ? finalArray.tooltip : null,
      notes: Boolean(finalArray.notes) ? finalArray.notes : null,
      // inSummary: Boolean(finalArray.inSummary) ? finalArray.inSummary : null,
      inputValue: Boolean(finalArray.inputValue) ? finalArray.inputValue : null,

      // NUMERICAL
      hardMax: Boolean(finalArray.hardMax) ? finalArray.hardMax : null,
      hardMin: Boolean(finalArray.hardMin) ? finalArray.hardMin : null,
      softMax: Boolean(finalArray.softMax) ? finalArray.softMax : null,
      softMin: Boolean(finalArray.softMin) ? finalArray.softMin : null,

      // FORMULA
      formula: Boolean(finalArray.formula) ? finalArray.formula : null,
      reference: Boolean(finalArray.reference) ? finalArray.reference : null,
      error: Boolean(finalArray.error) ? finalArray.error : null,
      resultHyperFormula: Boolean(finalArray.resultHyperFormula) ? finalArray.resultHyperFormula : null,
      isDisplayed: Boolean(finalArray.isDisplayed) ? finalArray.isDisplayed : null,

      // TABLE
      tableName: Boolean(finalArray.tableName) ? finalArray.tableName : null,
      tableHeader: Boolean(finalArray.tableHeader) ? finalArray.tableHeader : null,
      tableBody: Boolean(finalArray.tableBody) ? finalArray.tableBody : null,
      displayedContent: Boolean(finalArray.displayedContent) ? finalArray.displayedContent : null,
      freeze: Boolean(finalArray.freeze) ? {
        column: finalArray.freeze.column > FROZEN_COLUMN_LIMIT ? 0 : finalArray.freeze.column,
        row: finalArray.freeze.row
      } : null,
      isAllowedToAddRow: Boolean(finalArray.isAllowedToAddRow) ? finalArray.isAllowedToAddRow : null,
      format: { cells: finalArray?.format?.cells ?? null },

      // TEXT
      level: Boolean(finalArray.level) ? finalArray.level : null,

      // IMAGE
      isLocked: true,
      imageUrl: Boolean(finalArray.imageUrl) ? finalArray.imageUrl : null,
      imageWidth: Boolean(finalArray.imageWidth) ? finalArray.imageWidth : null,
      imageHeight: Boolean(finalArray.imageHeight) ? finalArray.imageHeight : null,
      imageType: Boolean(finalArray.imageType) ? finalArray.imageType : null,
      imageAlignment: Boolean(finalArray.imageAlignment) ? finalArray.imageAlignment : null,

      // DROPDOWN AND RADIOBUTTON
      options: Boolean(finalArray.options) ? finalArray.options : null,

      // NUMERICAL AND FORMULA
      unit: Boolean(finalArray.unit) ? finalArray.unit : null,

      // LINK
      title: Boolean(finalArray.title) ? finalArray.title : null,
      url: Boolean(finalArray.url) ? finalArray.url : null
    }));

    dispatch(reducerModifierExportEventActionComposer({
      value: true,
      type: payload.typeComponent
    }));
  };
}

export function importPropertiesActionComposer(actionType: string, payload?: any, payloadAPI?: any, setValue?: UseFormSetValue<any> | null, ref?: any, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    try {
      const resultObj: any = getState().composer.dataProperties;
      let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
      const oldVariable: string = resultArray[resultObj.activePage][resultObj.indexComponent].variable;
      let dynamicFormulaConverterResult: { [key: string]: any } = {};
      let asyncMemory: { [key: string]: string | number }[] = [];

      const formDataPayload = new FormData();
      let size: any;

      /*  check duplicate validation
      *   validation will only called in ONBLUR NOTATION and VARIABLE function
      */

      switch (actionType) {
        case 'ONBLUR_DESCRIPTION':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            description: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            description: payload
          }));

          // BE INTEGRATION
          if (payloadAPI.typeAPI === 'static-image') {
            formDataPayload.append('dto', JSON.stringify({
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              description: payload,
            }, null, 1));
            await addQueueComposerComponent(
              {
                idCalculation: payloadAPI.idCalculation,
                masterType: 'component',
                componentType: payloadAPI.typeAPI,
              },
              formDataPayload,
            );
          } else {
            await addQueueComposerComponent(
              {
                idCalculation: payloadAPI.idCalculation,
                masterType: 'component',
                componentType: payloadAPI.typeAPI,
              },
              {
                pageIndex: resultObj.activePage,
                rowIndex: resultObj.indexComponent,
                description: payload
              }
            );
          }
          break;
        case 'ONBLUR_NOTATION':
          // FE INTEGRATION
          let variableVal = '';
          if (payload) {
            variableVal = notationParserEngine.toVariable(payload);
            variableVal = variableVal.replace(/^/, 'var_');
          }

          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            notation: payload,
            variable: variableVal,
          };

          if (setValue && (fields && fields?.length > 0)) {
            fields.forEach((fieldId: string) =>
              // update properties panel
              setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload : variableVal)
            );
          }

          if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
          resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
          asyncMemory = dynamicFormulaConverterResult.asyncMemory;
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            notation: payload,
            variable: variableVal,
          }));
          if (!validateCharAfterNumber(payload)) {
            dispatch(toastSetupActionPopUp('ERROR', 'Can not input letter or special characters after number value'));
            return;
          }
          if (!duplicateValidation(resultArray, resultObj.idComponent, payload) || !notContainSpecialCharacterAndSpace(payload)) return null;

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              notation: payload,
              variable: variableVal,
            },
            {
              asyncMemory,
            }
          );
          break;
        case 'ONBLUR_VARIABLE':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            variable: payload,
          };
          if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, payload ? payload : '', setValue);
          resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
          asyncMemory = dynamicFormulaConverterResult.asyncMemory;
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            variable: payload,
          }));
          if (!duplicateValidation(resultArray, resultObj.idComponent, payload) || !notContainSpecialCharacterAndSpace(payload, 'variable')) return;

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              variable: payload,
            },
            {
              asyncMemory,
            }
          );
          break;
        case 'ONBLUR_OPTIONS':
          // FE INTEGRATION
          if (resultArray[resultObj.activePage][resultObj.indexComponent].inputValue) resultArray[resultObj.activePage][resultObj.indexComponent].inputValue = null;
          if (resultArray[resultObj.activePage][resultObj.indexComponent].options == null) {
            resultArray[resultObj.activePage][resultObj.indexComponent] = {
              ...resultArray[resultObj.activePage][resultObj.indexComponent],
              options: [payload],
            };
          } else {
            resultArray[resultObj.activePage][resultObj.indexComponent] = {
              ...resultArray[resultObj.activePage][resultObj.indexComponent],
              options: [...resultArray[resultObj.activePage][resultObj.indexComponent].options, payload],
            };
          }
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            options: resultArray[resultObj.activePage][resultObj.indexComponent].options
          }));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              options: resultArray[resultObj.activePage][resultObj.indexComponent].options,
            }
          );
          break;
        case 'REMOVE_OPTIONS':
          // FE INTEGRATION
          if (resultArray[resultObj.activePage][resultObj.indexComponent].inputValue) resultArray[resultObj.activePage][resultObj.indexComponent].inputValue = null;
          resultArray[resultObj.activePage][resultObj.indexComponent].options.splice(payload, 1);
          if (resultArray[resultObj.activePage][resultObj.indexComponent].options.length === 0 && resultObj.propertiesType === 'DROPDOWN_INPUT') {
            resultArray[resultObj.activePage][resultObj.indexComponent].options = null;
          }
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            options: resultArray[resultObj.activePage][resultObj.indexComponent].options
          }));
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              options: resultArray[resultObj.activePage][resultObj.indexComponent].options,
            }
          );
          break;
        case 'ONBLUR_TOOLTIP':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            tooltip: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              tooltip: payload,
            }
          );
          break;
        case 'ONBLUR_NOTES':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            notes: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              notes: payload,
            }
          );
          break;
        case 'ONBLUR_UNIT':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            unit: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              unit: payload,
            }
          );
          break;
        case 'ONBLUR_HARDMAX':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            hardMax: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              hardMax: payload,
            }
          );
          break;
        case 'ONBLUR_HARDMIN':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            hardMin: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              hardMin: payload,
            }
          );
          break;
        case 'ONBLUR_SOFTMAX':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            softMax: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              softMax: payload,
            }
          );
          break;
        case 'ONBLUR_SOFTMIN':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            softMin: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              softMin: payload,
            }
          );
          break;
        case 'ONBLUR_FORMULA':
          let errorMessage = '';
          const sources: Record<string, any> = {};
          let result = '';

          if (!payload) {
            errorMessage = 'Formula must be filled';
          } else if (payload[0] !== '=') {
            errorMessage = 'Please input with the format of Excel operations';
          } else if (slice.exec(payload.formula).includes(resultArray[resultObj.activePage][resultObj.indexComponent].variable)) {
            errorMessage = 'Cannot call itself';
          } else {
            resultArray.forEach((dataPages: { [key: string]: any }[]) => {
              dataPages.forEach((data: { [key: string]: any }) => {
                if (data.type === 'TABLE') {
                  const tableData = ref[data.id].getData(true);
                  sources[data.tableName.split(' ').join('')] = formatTableData(tableData);
                } else {
                  const value: string = data.type === 'FORMULA' ? valueParser(data.resultHyperFormula) : valueParser(data.inputValue);
                  if (data.variable) sources[data.variable] = value;
                }
              });
            });
            result = FormulaHandler.calculate(payload.substring(1), sources);
            if (!result || result === '#ERROR!') errorMessage = '#ERROR!';
          }

          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            error: errorMessage ? errorMessage : false,
            resultHyperFormula: errorMessage ? '#ERROR!' : result,
            formula: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            error: resultArray[resultObj.activePage][resultObj.indexComponent].error
          }));

          // BE INTEGRATION
          if (!errorMessage) {
            await addQueueComposerComponent(
              {
                idCalculation: payloadAPI.idCalculation,
                masterType: 'component',
                componentType: payloadAPI.typeAPI,
              },
              {
                pageIndex: resultObj.activePage,
                rowIndex: resultObj.indexComponent,
                formula: payload,
              }
            );
          }
          break;
        case 'ONBLUR_REFERENCE':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            reference: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              reference: payload,
            }
          );
          break;
        case 'FORMULA_DISPLAY':
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            isDisplayed: !payload
          };
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            isDisplayed: !payload
          }));
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              isDisplayed: !payload,
            }
          );
          break;

        case 'FORMULA_DISPLAY_ON':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            isDisplayed: true
          };
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            isDisplayed: true
          }));
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              isDisplayed: true,
            }
          );
          break;
        case 'FORMULA_DISPLAY_OFF':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            isDisplayed: false
          };
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            isDisplayed: false
          }));
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              isDisplayed: false,
            }
          );
          break;
        case 'ONBLUR_WIDTH':
          size = {};

          // ALGORITHM HEIGHT AND WIDTH
          if (resultObj.isLocked === true) {
            size.width = payload > 620 ? 620 : payload < 100 ? 100 : payload;
            size.height = payload > 620 ? Math.round((620 / resultObj.imageWidth) * resultObj.imageHeight) : payload < 100 ? Math.round((100 / resultObj.imageWidth) * resultObj.imageHeight) : Math.round((payload / resultObj.imageWidth) * resultObj.imageHeight);
          } else {
            size.width = payload > 620 ? 620 : payload < 100 ? 100 : payload;
            size.height = resultObj.imageHeight;
          }

          size.height = +size.height;
          size.width = +size.width;

          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            imageWidth: size.width,
            imageHeight: size.height,
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            imageWidth: size.width,
            imageHeight: size.height,
          }));
          dispatch(reducerModifierExportEventActionComposer({
            value: true,
            type: 'IMAGE'
          }));

          // BE INTEGRATION
          formDataPayload.append('dto', JSON.stringify({
            pageIndex: resultObj.activePage,
            rowIndex: resultObj.indexComponent,
            width: size.width,
            height: size.height
          }, null, 1));
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            formDataPayload,
          );
          break;
        case 'ONBLUR_HEIGHT':
          size = {};

          // ALGORITHM HEIGHT AND WIDTH
          if (resultObj.isLocked === true) {
            const targetWidth = (payload / resultObj.imageHeight) * resultObj.imageWidth;
            size.width = targetWidth > 620 ? 620 : targetWidth < 100 ? 100 : Math.round((payload / resultObj.imageHeight) * resultObj.imageWidth);
            size.height = targetWidth > 620 ? Math.round((620 / resultObj.imageWidth) * resultObj.imageHeight) : targetWidth < 100 ? Math.round((100 / resultObj.imageWidth) * resultObj.imageHeight) : payload;
          } else {
            size.width = resultObj.imageWidth;
            size.height = payload < 1 ? 1 : payload;
          }

          size.height = +size.height;
          size.width = +size.width;

          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            imageWidth: size.width,
            imageHeight: size.height,
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            imageWidth: size.width,
            imageHeight: size.height,
          }));
          dispatch(reducerModifierExportEventActionComposer({
            value: true,
            type: 'IMAGE'
          }));

          // BE INTEGRATION
          formDataPayload.append('dto', JSON.stringify({
            pageIndex: resultObj.activePage,
            rowIndex: resultObj.indexComponent,
            width: size.width,
            height: size.height
          }, null, 1));
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            formDataPayload,
          );
          break;
        case 'IMAGE_LOCK':
          setTimeout(() => {
            if (resultObj.isLocked === true) {
              dispatch(reducerModifierDataPropertiesActionComposer({
                ...resultObj,
                isLocked: false
              }));
            } else {
              dispatch(reducerModifierDataPropertiesActionComposer({
                ...resultObj,
                isLocked: true
              }));
            }
          }, 10);
          break;
        case 'TOGGLE_ADD_ROW':
          // FE INTEGRATION
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            isAllowedToAddRow: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            isAllowedToAddRow: payload
          }));


          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: payloadAPI.typeAPI,
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              isAllowedToAddRow: payload,
            }
          );
          break;
        case ONBLUR_URL:
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            url: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            url: payload
          }));


          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: 'link',
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              url: payload,
            }
          );
          break;
        case ONBLUR_TITLE:
          resultArray[resultObj.activePage][resultObj.indexComponent] = {
            ...resultArray[resultObj.activePage][resultObj.indexComponent],
            title: payload
          };
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
          dispatch(reducerModifierDataPropertiesActionComposer({
            ...resultObj,
            title: payload
          }));


          await addQueueComposerComponent(
            {
              idCalculation: payloadAPI.idCalculation,
              masterType: 'component',
              componentType: 'link',
            },
            {
              pageIndex: resultObj.activePage,
              rowIndex: resultObj.indexComponent,
              description: payload,
            }
          );
          break;
        // case 'SUMMARY_CHECK':
        //   setTimeout( async () => {
        //     if(resultObj.inSummary === true) {
        //       //// FE INTEGRATION
        //       resultArray[resultObj.activePage][resultObj.indexComponent] = {      
        //         ...resultArray[resultObj.activePage][resultObj.indexComponent],
        //         inSummary: false
        //       }
        //       dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray))
        //       dispatch(reducerModifierDataPropertiesActionComposer({
        //         ...resultObj,
        //         inSummary: false
        //       }))

        //       //// BACK END INTEGRATION
        //       if (resultObj.propertiesType === 'IMAGE') {
        //         formDataPayload.append('dto', JSON.stringify({
        //           pageIndex: resultObj.activePage,
        //           rowIndex: resultObj.indexComponent,
        //           inSummary: false
        //         }, null, 1))
        //         backendResponse = await service.putSubResource(payloadAPI.idCalculation, 'component', payloadAPI.typeAPI, formDataPayload)  
        //       } else {
        //         backendResponse = await service.putSubResource(payloadAPI.idCalculation, 'component', payloadAPI.typeAPI, {
        //           pageIndex: resultObj.activePage,
        //           rowIndex: resultObj.indexComponent,    
        //           inSummary: false
        //         })  
        //       }
        //     } else {
        //       //// FE INTEGRATION
        //       resultArray[resultObj.activePage][resultObj.indexComponent] = {      
        //         ...resultArray[resultObj.activePage][resultObj.indexComponent],
        //         inSummary: true
        //       }
        //       dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray))
        //       dispatch(reducerModifierDataPropertiesActionComposer({
        //         ...resultObj,
        //         inSummary: true
        //       }))

        //       //// BACK END INTEGRATION
        //       if (resultObj.propertiesType === 'IMAGE') {
        //         formDataPayload.append('dto', JSON.stringify({
        //           pageIndex: resultObj.activePage,
        //           rowIndex: resultObj.indexComponent,
        //           inSummary: true
        //         }, null, 1))
        //         backendResponse = await service.putSubResource(payloadAPI.idCalculation, 'component', payloadAPI.typeAPI, formDataPayload)  
        //       } else {
        //         backendResponse = await service.putSubResource(payloadAPI.idCalculation, 'component', payloadAPI.typeAPI, {
        //           pageIndex: resultObj.activePage,
        //           rowIndex: resultObj.indexComponent,    
        //           inSummary: true
        //         })  
        //       }
        //     }
        //   }, 50);
        // break;

      }

    } catch (error) {
      return error;
    }
  };
}

// COMPONENT FUNCTION

export function textInputActionComposer(actionType: string, payload?: any, setValue?: UseFormSetValue<any>, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const oldVariable: string = resultArray[activePage][payload.indexComponent].variable;
    let dynamicFormulaConverterResult: { [key: string]: any } = {};
    let asyncMemory: { [key: string]: string | number }[] = [];

    switch (actionType) {
      // FE INTEGRATION
      case 'ONBLUR_DESCRIPTION':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'text-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_NOTATION':
        // FE INTEGRATION
        let variableVal = '';
        if (payload.notation) {
          variableVal = notationParserEngine.toVariable(payload.notation);
          variableVal = variableVal.replace(/^/, 'var_');
        }

        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          notation: payload.notation,
          variable: variableVal,
        };

        if (setValue && (fields && fields?.length > 0)) {
          fields.forEach((fieldId: string) =>
            // update properties panel
            setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload.notation : variableVal)
          );
        }

        if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
        resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
        asyncMemory = dynamicFormulaConverterResult.asyncMemory;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          notation: payload.notation,
          variable: variableVal,
        }));
        if (!duplicateValidation(resultArray, resultObj.idComponent, payload.notation) || !notContainSpecialCharacterAndSpace(payload.notation)) return;

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'text-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            notation: payload.notation,
            variable: variableVal,
          },
          {
            asyncMemory,
          }
        );
        break;
      case 'ONBLUR_INPUT_VALUE':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        break;
    }
  };
}

export function dropdownInputActionComposer(actionType: string, payload?: any, setValue?: UseFormSetValue<any>, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const oldVariable: string = resultArray[activePage][payload.indexComponent].variable;
    let dynamicFormulaConverterResult: { [key: string]: any } = {};
    let asyncMemory: { [key: string]: string | number }[] = [];

    switch (actionType) {
      // FE INTEGRATION
      case 'ONBLUR_DESCRIPTION':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'dropdown-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_NOTATION':
        // FE INTEGRATION
        let variableVal = '';
        if (payload.notation) {
          variableVal = notationParserEngine.toVariable(payload.notation);
          variableVal = variableVal.replace(/^/, 'var_');
        }

        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          notation: payload.notation,
          variable: variableVal,
        };

        if (setValue && (fields && fields?.length > 0)) {
          fields.forEach((fieldId: string) =>
            // update properties panel
            setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload.notation : variableVal)
          );
        }

        if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
        resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
        asyncMemory = dynamicFormulaConverterResult.asyncMemory;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          notation: payload.notation,
          variable: variableVal,
        }));
        if (!validateCharAfterNumber(payload.notation)) {
          dispatch(toastSetupActionPopUp('ERROR', 'Can not input letter or special characters after number value'));
          return;
        }
        if (!duplicateValidation(resultArray, resultObj.idComponent, payload.notation) || !notContainSpecialCharacterAndSpace(payload.notation)) return;

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'dropdown-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            notation: payload.notation,
            variable: variableVal,
          },
          {
            asyncMemory,
          }
        );
        break;
      case 'SELECT_OPTION':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        break;
    }
  };
}

export function radioButtonInputActionComposer(actionType: string, payload?: any, setValue?: UseFormSetValue<any>, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const oldVariable: string = resultArray[activePage][payload.indexComponent].variable;
    let dynamicFormulaConverterResult: { [key: string]: any } = {};
    let asyncMemory: { [key: string]: string | number }[] = [];

    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'radio-button-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_NOTATION':
        let variableVal = '';
        if (payload.notation) {
          variableVal = notationParserEngine.toVariable(payload.notation);
          variableVal = variableVal.replace(/^/, 'var_');
        }

        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          notation: payload.notation,
          variable: variableVal,
        };

        if (setValue && (fields && fields?.length > 0)) {
          fields.forEach((fieldId: string) =>
            // update properties panel
            setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload.notation : variableVal)
          );
        }

        if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
        resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
        asyncMemory = dynamicFormulaConverterResult.asyncMemory;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          notation: payload.notation,
          variable: variableVal,
        }));
        if (!validateCharAfterNumber(payload.notation)) {
          dispatch(toastSetupActionPopUp('ERROR', 'Can not input letter or special characters after number value'));
          return;
        }
        if (!duplicateValidation(resultArray, resultObj.idComponent, payload.notation) || !notContainSpecialCharacterAndSpace(payload.notation)) return;

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'radio-button-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            notation: payload.notation,
            variable: variableVal,
          },
          {
            asyncMemory,
          }
        );
        break;
      case 'ADD_OPTION':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options = [
          ...resultArray[activePage][payload.indexComponent].options,
          ''
        ];
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options,
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'radio-button-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
      case 'REMOVE_OPTIONS':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options.splice(payload.indexRadio, 1);
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options
        }));
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'radio-button-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
      case 'SELECT_RADIO':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        break;
      case 'ONBLUR_OPTIONS':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options[payload.indexRadio] = payload.option;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'radio-button-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
    }
  };
}

export function numericalInputActionComposer(actionType: string, payload?: any, setValue?: UseFormSetValue<any>, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const oldVariable: string = resultArray[activePage][payload.indexComponent].variable;
    // let backendResponse: any;
    let dynamicFormulaConverterResult: { [key: string]: any } = {};
    let asyncMemory: { [key: string]: string | number }[] = [];
    let formatPayload: { [key: string]: any };

    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'numerical',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_NOTATION':
        // FE INTEGRATION
        let variableVal = '';
        if (payload.notation) {
          variableVal = notationParserEngine.toVariable(payload.notation);
          variableVal = variableVal.replace(/^/, 'var_');
        }

        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          notation: payload.notation,
          variable: variableVal,
        };

        if (setValue && (fields && fields?.length > 0)) {
          fields.forEach((fieldId: string) =>
            // update properties panel
            setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload.notation : variableVal)
          );
        }

        if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
        resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
        asyncMemory = dynamicFormulaConverterResult.asyncMemory;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          notation: payload.notation,
          variable: variableVal,
        }));
        if (!validateCharAfterNumber(payload.notation)) {
          dispatch(toastSetupActionPopUp('ERROR', 'Can not input letter or special characters after number value'));
          return;
        }
        if (!duplicateValidation(resultArray, resultObj.idComponent, payload.notation) || !notContainSpecialCharacterAndSpace(payload.notation)) return;

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'numerical',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            notation: payload.notation,
            variable: variableVal,
          },
          {
            asyncMemory,
          }
        );
        break;
      case 'ONBLUR_UNIT':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          unit: payload.unit
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          unit: payload.unit
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'numerical',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            unit: payload.unit,
          }
        );
        break;
      case 'ONBLUR_INPUT_VALUE':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        break;
      case 'ONCHANGE_DECIMAL':
        formatPayload = {
          value: {
            ...payload.format.value,
            percentage: resultArray[activePage][payload.indexComponent].format?.value?.percentage,
          }
        };

        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          format: formatPayload
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          format: formatPayload
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'numerical',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            format: formatPayload,
          }
        );
        break;
      case 'ONCHANGE_PERCENTAGE':
        formatPayload = {
          value: {
            ...payload.format.value,
            decimal: resultArray[activePage][payload.indexComponent].format?.value?.decimal,
          }
        };

        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.format.value.percentage === true ? `${resultArray[activePage][payload.indexComponent].inputValue} %` : resultArray[activePage][payload.indexComponent].inputValue.slice(0, -2),
          format: formatPayload
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          inputValue: payload.format.value.percentage === true ? `${resultArray[activePage][payload.indexComponent].inputValue} %` : resultArray[activePage][payload.indexComponent].inputValue.slice(0, -2),
          format: formatPayload
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'numerical',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            format: formatPayload,
          }
        );
        break;
    }
  };
}

export function dateInputActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'date-input',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
    }
  };
}

export function formulaActionComposer(actionType: string, payload?: any, setValue?: UseFormSetValue<any>, fields?: string[]) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const oldVariable: string = resultArray[activePage][payload.indexComponent].variable;
    let dynamicFormulaConverterResult: { [key: string]: any } = {};
    let asyncMemory: { [key: string]: string | number }[] = [];

    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'formula',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_NOTATION':
        // FE INTEGRATIONlet variableVal = '';
        let variableVal = '';
        if (payload.notation) {
          variableVal = notationParserEngine.toVariable(payload.notation);
          variableVal = variableVal.replace(/^/, 'var_');
        }

        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          notation: payload.notation,
          variable: variableVal,
        };

        if (setValue && (fields && fields?.length > 0)) {
          fields.forEach((fieldId: string) =>
            // update properties panel
            setValue(fieldId, fieldId.indexOf('notation') >= 0 ? payload.notation : variableVal)
          );
        }

        if (setValue) dynamicFormulaConverterResult = dynamicFormulaConverter(resultArray, oldVariable, variableVal, setValue);
        resultArray = dynamicFormulaConverterResult.dataBodyOfCalculation;
        asyncMemory = dynamicFormulaConverterResult.asyncMemory;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          notation: payload.notation,
          variable: variableVal,
        }));
        if (!validateCharAfterNumber(payload.notation)) {
          dispatch(toastSetupActionPopUp('ERROR', 'Can not input letter or special characters after number value'));
          return;
        }
        if (!duplicateValidation(resultArray, resultObj.idComponent, payload.notation) || !notContainSpecialCharacterAndSpace(payload.notation)) return;

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'formula',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            notation: payload.notation,
            variable: variableVal,
          },
          {
            asyncMemory,
          }
        );
        break;
      case 'ONBLUR_UNIT':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          unit: payload.unit
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          unit: payload.unit
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'formula',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            unit: payload.unit,
          }
        );
        break;
      case 'ONBLUR_FORMULA':
        let errorMessage = '';
        const sources: Record<string, any> = {};
        let result = '';
        if (!payload.formula) {
          errorMessage = 'Formula must be filled';
        } else if (payload.formula[0] !== '=') {
          errorMessage = 'Please input with the format of Excel operations';
        } else if (slice.exec(payload.formula).includes(resultArray[activePage][payload.indexComponent].variable)) {
          errorMessage = 'Cannot call itself';
        } else {
          resultArray.forEach((dataPages: { [key: string]: any }[]) => {
            dataPages.forEach((data: { [key: string]: any }) => {
              if (data.type === 'TABLE') {
                const tableData = payload.workpageTableRef[data.id].getData(true);
                sources[data.tableName.split(' ').join('')] = formatTableData(tableData);
              } else {
                const value: string = data.type === 'FORMULA' ? valueParser(data.resultHyperFormula) : valueParser(data.inputValue);
                if (data.variable) sources[data.variable] = value;
              };
            });
          });
          result = FormulaHandler.calculate(payload.formula.substring(1), sources);
          if (!result || result === '#ERROR!') errorMessage = '#ERROR!';
        }

        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          error: errorMessage ? errorMessage : false,
          resultHyperFormula: errorMessage ? '#ERROR!' : result,
          formula: payload.formula
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          error: resultArray[resultObj.activePage][resultObj.indexComponent].error
        }));

        // BE INTEGRATION
        if (!errorMessage) {
          await addQueueComposerComponent(
            {
              idCalculation: payload.idCalculation,
              masterType: 'component',
              componentType: 'formula',
            },
            {
              pageIndex: activePage,
              rowIndex: payload.indexComponent,
              formula: payload.formula,
            }
          );
        }
        break;
      default:
        console.log('Not yet implemented');
    }
  };
}

export function tableActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'table',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          },
        );
        break;
    }
  };
}

export function textActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'text',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'SELECT_LEVEL':
        resultArray[resultObj.activePage][resultObj.indexComponent] = {
          ...resultArray[resultObj.activePage][resultObj.indexComponent],
          level: payload.level
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          level: payload.level
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'text',
          },
          {
            pageIndex: resultObj.activePage,
            rowIndex: resultObj.indexComponent,
            level: payload.level,
          },
        );
        break;
    }
  };
}

export function LinkActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    switch (actionType) {
      case ONBLUR_TITLE:
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          title: payload.title
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          title: payload.title
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'link',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.title,
          }
        );
        break;
      case ONBLUR_URL:
        resultArray[resultObj.activePage][resultObj.indexComponent] = {
          ...resultArray[resultObj.activePage][resultObj.indexComponent],
          url: payload.url
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          url: payload.url
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'link',
          },
          {
            pageIndex: resultObj.activePage,
            rowIndex: resultObj.indexComponent,
            url: payload.url,
          },
        );
        break;
      default:
        console.log('Not yet implemented');
    }
  };
}

export function imageActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const formDataPayload = new FormData();
    let backendResponse: any;

    switch (actionType) {
      case 'ONBLUR_DESCRIPTION':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        // BE INTEGRATION
        formDataPayload.append('dto', JSON.stringify({
          pageIndex: activePage,
          rowIndex: payload.indexComponent,
          description: payload.description,
        }, null, 1));
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'static-image',
          },
          formDataPayload,
        );
        break;
      case 'DELETE_IMAGE':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          imageUrl: null,
          imageWidth: null,
          imageHeight: null,
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          imageUrl: null,
          imageWidth: null,
          imageHeight: null,
        }));
        dispatch(reducerModifierExportEventActionComposer({
          value: true,
          type: payload.typeComponent
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'static-image/image',
            serviceType: 'delete',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
          },
        );
        break;
      case 'UPLOAD_IMAGE':
        const img = new Image();
        const size: any = {};
        img.src = window.URL.createObjectURL(payload.image);

        img.onload = () => {
          size.width = img.width;
          size.height = img.height;
        };

        const waitForLoaded = async () => {
          if (Object.keys(size).length !== 0) {
            // RESIZING IMAGE
            if (img.width > 620) {
              size.width = 620;
              size.height = Math.round((620 / img.width) * img.height);
            } else if (img.width < 100) {
              size.width = 100;
              size.height = Math.round((100 / img.width) * img.height);
            }

            // FE INTEGRATION
            resultArray[activePage][payload.indexComponent] = {
              ...resultArray[activePage][payload.indexComponent],
              imageUrl: URL.createObjectURL(payload.image),
              imageWidth: size.width,
              imageHeight: size.height,
            };
            dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
            dispatch(reducerModifierDataPropertiesActionComposer({
              ...resultObj,
              imageUrl: URL.createObjectURL(payload.image),
              imageWidth: size.width,
              imageHeight: size.height,
            }));
            dispatch(reducerModifierExportEventActionComposer({
              value: true,
              type: payload.typeComponent
            }));

            // BE INTEGRATION
            formDataPayload.append('file', payload.image);
            formDataPayload.append('dto', JSON.stringify({
              pageIndex: activePage,
              rowIndex: payload.indexComponent,
              width: size.width,
              height: size.height,
            }, null, 1));
            backendResponse = await addQueueComposerComponent(
              {
                idCalculation: payload.idCalculation,
                masterType: 'component',
                componentType: 'static-image',
              },
              formDataPayload,
            );

            // LOGGER PREVIEW
            dispatch(setLoggerPreviewImage({
              columnId: resultArray[activePage][payload.indexComponent].id,
              imageUrl: backendResponse?.data.properties.imageUrl,
            }));
          } else {
            setTimeout(waitForLoaded, 100);
          }
        };
        setTimeout(waitForLoaded, 100);
        break;
      case 'SELECT_ALIGNMENT':
        // FE INTEGRATION
        resultArray[resultObj.activePage][resultObj.indexComponent] = {
          ...resultArray[resultObj.activePage][resultObj.indexComponent],
          imageAlignment: payload.imageAlignment,
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          imageAlignment: payload.imageAlignment,
        }));

        // BE INTEGRATION
        formDataPayload.append('dto', JSON.stringify({
          pageIndex: resultObj.activePage,
          rowIndex: resultObj.indexComponent,
          alignment: payload.imageAlignment,
        }, null, 1));
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'static-image',
          },
          formDataPayload,
        );
        break;
    }
  };
}

export function fieldActionComposer(actionType: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    const resultObj: any = getState().composer.dataProperties;
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;

    switch (actionType) {
      // FE INTEGRATION
      case 'ONBLUR_DESCRIPTION':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          description: payload.description
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          description: payload.description
        }));

        //   // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            description: payload.description,
          }
        );
        break;
      case 'ONBLUR_FIELD_VALUE':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

        break;
      case 'ONCHANGE_PERCENTAGE':
        let formatPayload = {
          value: {
            ...payload.format.value,
            decimal: resultArray[activePage][payload.indexComponent].format?.value?.decimal,
          }
        };

        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.format.value.percentage === true ? `${resultArray[activePage][payload.indexComponent].inputValue} %` : resultArray[activePage][payload.indexComponent].inputValue.slice(0, -2),
          format: formatPayload
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          inputValue: payload.format.value.percentage === true ? `${resultArray[activePage][payload.indexComponent].inputValue} %` : resultArray[activePage][payload.indexComponent].inputValue.slice(0, -2),
          format: formatPayload
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            format: formatPayload,
          }
        );
        break;
      case 'ONCHANGE_DECIMAL':
        formatPayload = {
          value: {
            ...payload.format.value,
            percentage: resultArray[activePage][payload.indexComponent].format?.value?.percentage,
          }
        };

        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          format: formatPayload
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          format: formatPayload
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            format: formatPayload,
          }
        );
        break;
      case 'ONBLUR_UNIT':
        // FE INTEGRATION
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          unit: payload.unit
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          unit: payload.unit
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            unit: payload.unit,
          }
        );
        break;
      case 'ADD_OPTION':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options = [
          ...resultArray[activePage][payload.indexComponent].options,
          ''
        ];
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options,
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
      case 'REMOVE_OPTIONS':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options.splice(payload.indexRadio, 1);
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options
        }));
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
      case 'SELECT_RADIO':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        break;
      case 'ONBLUR_OPTIONS':
        // FE INTEGRATION
        if (resultArray[activePage][payload.indexComponent].inputValue) resultArray[activePage][payload.indexComponent].inputValue = null;
        resultArray[activePage][payload.indexComponent].options[payload.indexRadio] = payload.option;
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          options: resultArray[activePage][payload.indexComponent].options
        }));

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'field',
          },
          {
            pageIndex: activePage,
            rowIndex: payload.indexComponent,
            options: resultArray[activePage][payload.indexComponent].options,
          }
        );
        break;
      case 'SELECT_OPTION':
        resultArray[activePage][payload.indexComponent] = {
          ...resultArray[activePage][payload.indexComponent],
          inputValue: payload.inputValue
        };
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

    }
  };
}

// PAGE FUNCTION

export function addPageActionComposer(idCalculation: string, payload: any) {
  return async (dispatch: any, getState: any) => {
    const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
    const dataPages: any = Array.from(getState().composer.dataPages);
    try {
      //// FE INTEGRATION
      resultArray.push([]);
      dataPages.push({
        hidden: false,
        name: payload,
      });
      dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));
      dispatch(reducerModifierDataPagesActionComposer(dataPages));

      //// BE INTEGRATION
      await service.postSubResource(idCalculation, 'page', {
        pageTitle: payload
      });
      // console.log(backendResponse);
    } catch (error) {
      console.error(error);
    }
  };
}

export function renamePageActionComposer(idCalculation: string, payload: any) {
  return async (dispatch: any, getState: any) => {
    const dataPages: any = Array.from(getState().composer.dataPages);
    const activePage: any = getState().composer.activePage;
    try {
      //// FE INTEGRATION
      dataPages[activePage] = {
        ...dataPages[activePage],
        name: payload,
      };
      dispatch(reducerModifierDataPagesActionComposer(dataPages));

      if (!notContainSpecialCharacterAndSpace(payload, 'page')) {
        dispatch(toastSetupActionPopUp("ERROR", "Please do not use special character"));
        return null;
      };

      //// BE INTEGRATION
      await service.putSubResource(idCalculation, 'page', 'rename', {
        pageIndex: activePage,
        pageTitle: payload,
      });
      // console.log(backendResponse);
    } catch (error) {
      console.error(error);
    }
  };
}

export function unhidePageActionComposer(idCalculation: string) {
  return async (dispatch: any, getState: any) => {
    const dataPages: any = Array.from(getState().composer.dataPages);
    const activePage: any = getState().composer.activePage;
    try {
      //// FE INTEGRATION
      dataPages[activePage] = {
        ...dataPages[activePage],
        hidden: false,
      };
      dispatch(reducerModifierDataPagesActionComposer(dataPages));

      //// BE INTEGRATION
      await service.putSubResource(idCalculation, 'page', activePage, {
        isHidden: false
      });
    } catch (error) {
      console.error(error);
    }
  };
}

// DUPLICATE DELETE COMPONENT FUNCTION

export function twoMenuActionComposer(id: string) {
  return (dispatch: any, getState: any) => {
    const resultArray = Array.from(getState().composer.dataBodyOfCalculation);
    const finalArray = resultArray.map((arr: any) => {
      const insideArray = arr.map((obj: any) => {
        if (obj.id === id) {
          if (obj.showMenu === true) {
            return { ...obj, showMenu: false };
          } else {
            return { ...obj, showMenu: true };
          }
        } else {
          return obj;
        }
      });
      return insideArray;
    });
    dispatch(reducerModifierDataBodyOfCalculationActionComposer(finalArray));
  };
}

export function twoMenuHandlerActionComposer(idCalculation: string, typeHandler: string, idComponent: string | string[], indexComponent?: number | number[], typeComponent?: string | string[], workpageTableRef?: any, setValue?: UseFormSetValue<any>) {
  return async (dispatch: any, getState: any) => {
    let resultArray: any = copyArray(getState().composer.dataBodyOfCalculation);
    const activePage: any = getState().composer.activePage;
    const asyncMemory: any = [];
    if (typeHandler === 'DELETE') {
      try {
        const finalArray: { [key: string]: any }[] = [];
        if (Array.isArray(typeComponent) && Array.isArray(indexComponent) && Array.isArray(idComponent)) {
          //// FE INTEGRATION
          const filterArray: { [key: string]: any }[] = [];
          resultArray[activePage].forEach((element: { [key: string]: any }, index: number) => {
            if (workpageTableRef[element.id] && element.type === 'TABLE') workpageTableRef[element.id].deleteTable();
            if (!indexComponent.includes(index)) {
              filterArray.push(element);
            } else {
              finalArray.push({
                pageIndex: activePage,
                rowIndex: index,
              });
            };
          });
          resultArray[activePage] = filterArray;

          resultArray = arrangeTableName(resultArray);

          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: idCalculation,
              masterType: 'components',
              serviceType: 'delete',
            },
            {
              rows: finalArray,
            },
          );
        } else if (typeof indexComponent === 'number' && typeof idComponent === 'string' && typeof typeComponent === 'string') {
          //// FE INTEGRATION
          let finalArray = resultArray.map((arr: any) => {
            const tempArray = arr.filter((obj: any) => {
              if (obj.id !== idComponent) {
                return obj;
              }
            });
            return tempArray;
          });

          if (workpageTableRef[idComponent] && typeComponent === 'TABLE') {
            workpageTableRef[idComponent].deleteTable();
            const tableIndex = resultArray[activePage][indexComponent].tableName.split(' ')[1];
            finalArray.forEach(async (pages: any, indexPage: number) => {
              pages.forEach(async (component: any, indexComponent: number) => {
                if (component.type === 'FORMULA') {
                  let formula: any = component.formula;
                  const regexp = /table[\d]+/gi;
                  const tablesName = formula.match(regexp) ? [...formula.match(regexp)] : [];
                  if (tablesName.length > 0) {
                    tablesName.forEach((tableName: string) => {
                      const thenum: any = tableName.replace(/^\D+/g, '');
                      if (thenum > tableIndex) {
                        formula = formula.replaceAll(thenum, (+thenum - 1));
                      };
                    });
                  };
                  component.formula = formula;
                  if (setValue) setValue(`formula_${component.id}`, formula);
                  asyncMemory.push({
                    pageIndex: indexPage,
                    rowIndex: indexComponent,
                    formula: formula,
                  });
                };
              });
            });
          };

          finalArray = arrangeTableName(finalArray);

          dispatch(reducerModifierDataBodyOfCalculationActionComposer(finalArray));

          // BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: idCalculation,
              masterType: 'component',
              serviceType: 'delete',
            },
            {
              pageIndex: activePage,
              rowIndex: indexComponent
            },
            {
              asyncMemory,
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    } else if (typeHandler === 'COPY') {
      if (Array.isArray(typeComponent) && Array.isArray(indexComponent) && Array.isArray(idComponent)) {
        //// FE INTEGRATION
        try {
          let newResultArray: any = [];
          const lastIndex = Math.max(...indexComponent);
          indexComponent.sort(function (a: number, b: number) {
            return b - a;
          }).forEach((item: any, idx: number) => {
            newResultArray = mappingDuplicateComponent(lastIndex, idx, newResultArray.length ? newResultArray : resultArray, item, typeComponent[idx], idComponent[idx], activePage, workpageTableRef, asyncMemory, setValue);
          });
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(newResultArray));
          //// BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: idCalculation,
              masterType: 'components',
              serviceType: 'duplicate',
            },
            {
              components: indexComponent.map((item: number) => {
                return {
                  pageIndex: activePage,
                  rowIndex: item,
                };
              })
            },
            {
              asyncMemory
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else if (typeof indexComponent === 'number' && typeof idComponent === 'string' && typeof typeComponent === 'string') {
        //// FE INTEGRATION
        try {
          resultArray[activePage].splice(indexComponent + 1, 0, {
            ...resultArray[activePage][indexComponent],
            id: uuidv4(),
            showMenu: false,
            ...(
              'options' in resultArray[activePage][indexComponent] && {
                options: Boolean(resultArray[activePage][indexComponent].options) ? copyArray(resultArray[activePage][indexComponent].options) : null,
              }
            ),
            ...(
              'tableBody' in resultArray[activePage][indexComponent] && {
                tableBody: Boolean(resultArray[activePage][indexComponent].tableBody) ? copyArray(resultArray[activePage][indexComponent].tableBody) : null,
              }
            ),
            ...(
              'variable' in resultArray[activePage][indexComponent] && {
                variable: null,
              }
            ),
            ...(
              'notation' in resultArray[activePage][indexComponent] && {
                notation: null,
              }
            )
          });

          if (workpageTableRef[idComponent] && typeComponent === 'TABLE') {
            const tableIndex = resultArray[activePage][indexComponent].tableName.split(' ')[1];
            resultArray.forEach(async (pages: any, indexPage: number) => {
              pages.forEach(async (component: any, indexComponent: number) => {
                if (component.type === 'FORMULA') {
                  let formula: any = component.formula;
                  const regexp = /table[\d]+/gi;
                  const tablesName = formula.match(regexp) ? [...formula.match(regexp)] : [];
                  if (tablesName.length > 0) {
                    tablesName.forEach((tableName: string) => {
                      const thenum: any = tableName.replace(/^\D+/g, '');
                      if (thenum > tableIndex) {
                        formula = formula.replaceAll(thenum, (+thenum + 1));
                      };
                    });
                  };
                  component.formula = formula;
                  if (setValue) setValue(`formula_${component.id}`, formula);
                  asyncMemory.push({
                    pageIndex: indexPage,
                    rowIndex: indexComponent,
                    formula: formula,
                  });
                };
              });
            });
          };

          resultArray = arrangeTableName(copyArray(resultArray));
          dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

          //// BE INTEGRATION
          await addQueueComposerComponent(
            {
              idCalculation: idCalculation,
              masterType: 'component',
              serviceType: 'duplicate',
            },
            {
              sourcePageIndex: activePage,
              sourceRowIndex: indexComponent,
            },
            {
              asyncMemory
            }
          );
          // console.log(backendResponse);          
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
}

// CHANGE INPUT FUNCTION

export function changeInputActionComposer(idCalculation: any, idComponent: any, indexComponent: any, typeComponent: any) {
  return async (dispatch: any, getState: any) => {
    try {
      const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
      const activePage: any = getState().composer.activePage;
      const idGenerated: any = uuidv4();

      switch (typeComponent) {
        case 'TEXT_INPUT':
          resultArray[activePage][indexComponent] = {
            id: idGenerated,
            type: typeComponent,
            description: null,
            variable: null,
            notation: null,
            tooltip: null,
            notes: null,
            inputValue: "",
            // inSummary: null,
            showMenu: false,
          };
          break;
        case 'DROPDOWN_INPUT':
          resultArray[activePage][indexComponent] = {
            id: idGenerated,
            type: typeComponent,
            description: null,
            variable: null,
            notation: null,
            options: null,
            tooltip: null,
            notes: null,
            inputValue: "",
            // inSummary: null,
            showMenu: false,
          };
          break;
        case 'RADIO_BUTTON_INPUT':
          resultArray[activePage][indexComponent] = {
            id: idGenerated,
            type: typeComponent,
            description: null,
            variable: null,
            notation: null,
            options: [''],
            tooltip: null,
            notes: null,
            inputValue: "",
            // inSummary: null,
            showMenu: false,
          };
          break;
        case 'NUMERICAL_INPUT':
          resultArray[activePage][indexComponent] = {
            id: idGenerated,
            type: typeComponent,
            description: null,
            variable: null,
            notation: null,
            unit: null,
            hardMax: null,
            hardMin: null,
            softMax: null,
            softMin: null,
            tooltip: null,
            notes: null,
            inputValue: 1,
            // inSummary: null,
            showMenu: false,
          };
          break;
        case 'DATE_INPUT':
          resultArray[activePage][indexComponent] = {
            id: idGenerated,
            type: typeComponent,
            description: null,
            variable: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            showMenu: false,
          };
          break;
      }

      dispatch(reducerModifierDataPropertiesActionComposer({
        // SUPER IMPORTANT
        showProperties: true,
        activePage: activePage,
        indexComponent: indexComponent,
        propertiesType: typeComponent,
        idComponent: idGenerated,

        description: null,
        variable: null,
        notation: null,
        tooltip: null,
        notes: null,
        // inSummary: null,
        inputValue: null,
        hardMax: null,
        hardMin: null,
        softMax: null,
        softMin: null,
        formula: null,
        reference: null,
        error: null,
        isDisplayed: null,
        tableName: null,
        level: null,
        isLocked: true,
        imageUrl: null,
        imageWidth: null,
        imageHeight: null,
        imageType: null,
        imageAlignment: null,
        options: typeComponent === 'RADIO_BUTTON_INPUT' ? [''] : null,
        unit: null,
      }));

      dispatch(reducerModifierExportEventActionComposer({
        value: true,
        type: 'RESET'
      }));

      await service.delete(idCalculation, 'component', {
        pageIndex: activePage,
        rowIndex: indexComponent
      });

      await service.postSubResource(idCalculation, 'component', {
        pageIndex: activePage,
        rowIndex: indexComponent,
        type: typeComponent,
      });

    } catch (error) {
      console.error(error);
    }
  };
}

// CHANGE PAGE FUNCTION

export function changePageActionComposer(page: number, workpageTableRef: any) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const dataBodyOfCalculation: { [key: string]: any }[][] = copyArray(getState().composer.dataBodyOfCalculation);

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'TABLE' && workpageTableRef[data.id]) {
          workpageTableRef[data.id].deleteTable();
        }
      });
    });

    dispatch(reducerModifierActivePageActionComposer(page));
  };
}

// CHANGE FIELD FUNCTION

export function changeFieldActionComposer(idCalculation: any, idComponent: any, indexComponent: any, typeComponent: any) {
  return async (dispatch: any, getState: any) => {
    try {
      const resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
      const activePage: any = getState().composer.activePage;
      switch (typeComponent) {
        case 'TEXT':
        case 'NUMERICAL':
        case 'DATE':
          resultArray[activePage][indexComponent] = {
            id: idComponent,
            type: 'FIELD',
            fieldType: typeComponent,
            description: null,
            tooltip: null,
            notes: null,
            showMenu: false,
          };
          break;
        case 'DROPDOWN':
          resultArray[activePage][indexComponent] = {
            id: idComponent,
            type: 'FIELD',
            fieldType: typeComponent,
            description: null,
            options: null,
            tooltip: null,
            notes: null,
            showMenu: false,
          };
          break;
        case 'RADIO_BUTTON':
          resultArray[activePage][indexComponent] = {
            id: idComponent,
            type: 'FIELD',
            fieldType: typeComponent,
            description: null,
            options: [''],
            tooltip: null,
            notes: null,
            showMenu: false,
          };
          break;
      }

      dispatch(reducerModifierDataPropertiesActionComposer({
        // SUPER IMPORTANT
        showProperties: true,
        activePage: activePage,
        indexComponent: indexComponent,
        propertiesType: 'FIELD',
        idComponent: idComponent,

        description: null,
        variable: null,
        notation: null,
        tooltip: null,
        notes: null,
        fieldType: typeComponent,
        inSummary: null,
        inputValue: null,
        hardMax: null,
        hardMin: null,
        softMax: null,
        softMin: null,
        formula: null,
        reference: null,
        error: null,
        isDisplayed: null,
        tableName: null,
        level: null,
        isLocked: true,
        imageUrl: null,
        imageWidth: null,
        imageHeight: null,
        imageType: null,
        imageAlignment: null,
        options: typeComponent === 'RADIO_BUTTON' ? [''] : null,
        unit: null,
      }));

      dispatch(reducerModifierExportEventActionComposer({
        value: true,
        type: 'RESET'
      }));
      await service.putSubResource(idCalculation, 'component', 'field', {
        pageIndex: activePage,
        rowIndex: indexComponent,
        fieldType: typeComponent
      });

    } catch (error) {
      console.error(error);
    }
  };
}
// DRAG AND DROP FUNCTION

//// PURE DND

export function copyActionComposer(id: string, type: string, destinationIndex: number) {
  return async (dispatch: any, getState: any) => {
    try {
      let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
      const activePage: any = getState().composer.activePage;

      // FE INTEGRATION
      switch (type) {
        case 'TEXT_INPUT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            notation: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'FIELD':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            tooltip: null,
            notes: null,
            fieldType: 'TEXT',
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'DROPDOWN_INPUT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            notation: null,
            options: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'RADIO_BUTTON_INPUT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            notation: null,
            options: [''],
            tooltip: null,
            notes: null,
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'NUMERICAL_INPUT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            notation: null,
            unit: null,
            hardMax: null,
            hardMin: null,
            softMax: null,
            softMin: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            inputValue: "1.000",
            showMenu: false,
          });
          break;
        case 'NUMERICAL_FIELD':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            tooltip: null,
            notes: null,
            inputValue: null,
            showMenu: false,
          });
          break;
        case 'DATE_INPUT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'FORMULA':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            variable: null,
            notation: null,
            unit: null,
            formula: null,
            reference: null,
            isDisplayed: null,
            tooltip: null,
            notes: null,
            // inSummary: null,
            error: 'Formula must be filled',
            showMenu: false
          });
          break;
        case 'TABLE':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            tooltip: null,
            notes: null,
            tableName: null,
            tableHeader: tableHeaderGenerator(),
            tableBody: cellsToTableConverter(defaultFormulaTableCellComposer),
            displayedContent: {
              row: { start: 0, end: 2 },
              column: { start: 0, end: 2 },
            },
            freeze: {
              column: 0,
              row: 0
            },
            isAllowedToAddRow: false,
            // inSummary: null,
            showMenu: false,
            format: {
              cells: null
            }
          });
          break;
        case 'TEXT':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            level: 'NORMAL',
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'DIVIDER':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            showMenu: false,
          });
          break;
        case 'IMAGE':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            description: null,
            imageUrl: null,
            imageWidth: null,
            imageHeight: null,
            imageType: 'STATIC',
            imageAlignment: 'CENTER',
            // inSummary: null,
            showMenu: false,
          });
          break;
        case 'LINK':
          resultArray[activePage].splice(destinationIndex, 0, {
            id: uuidv4(),
            type: type,
            title: null,
            link: null,
            showMenu: false,
            level: 'NORMAL',
          });
          break;
        default:
          console.log(`?`);
      }
      resultArray = arrangeTableName(copyArray(resultArray));
      dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

      // BE INTEGRATION
      await addQueueComposerComponent(
        {
          idCalculation: id,
          masterType: 'component',
          serviceType: 'add',
        },
        {
          pageIndex: activePage,
          rowIndex: destinationIndex,
          type: type,
        }
      );
      return 'Success';
    } catch (error) {
      return error;
    }
  };
}

export function reorderActionComposer(id: string, sourceIndex: number, destinationIndex: number) {
  return async (dispatch: any, getState: any) => {
    try {
      // FE INTEGRATION
      let resultArray: any = Array.from(getState().composer.dataBodyOfCalculation);
      const activePage: any = getState().composer.activePage;
      const [removed] = resultArray[activePage].splice(sourceIndex, 1);
      resultArray[activePage].splice(destinationIndex, 0, removed);

      resultArray = arrangeTableName(copyArray(resultArray));
      dispatch(reducerModifierDataBodyOfCalculationActionComposer(resultArray));

      // BE INTEGRATION
      await addQueueComposerComponent(
        {
          idCalculation: id,
          masterType: 'component',
          serviceType: 'move'
        },
        {
          sourcePageIndex: activePage,
          sourceRowIndex: sourceIndex,
          destinationPageIndex: activePage,
          destinationRowIndex: destinationIndex
        }
      );
      return 'Success';
    } catch (error) {
      return error;
    }
  };
}

export function reorderMultipleActionComposer(params: any) {

  return async (dispatch: any, getState: any) => {
    try {
      const {
        idCalculation,
        components,
        idDestination,
        isMoveDown,
        actualDestinationIndex,
        sources,
      } = params;
      // FE INTEGRATION
      const dataBodyOfCalculation: any = Array.from(
        getState().composer.dataBodyOfCalculation
      );
      const activePage: any = getState().composer.activePage;
      dataBodyOfCalculation[activePage] = dataBodyOfCalculation[
        activePage
      ].filter(
        (e: any) => components.findIndex((i: any) => i.id === e.id) === -1
      );
      let destinationIndex = dataBodyOfCalculation[activePage].findIndex(
        (e: any) => e.id === idDestination
      );
      if (destinationIndex === -1) {
        return;
      }
      if (isMoveDown) {
        destinationIndex += 1;
      }
      dataBodyOfCalculation[activePage].splice(
        destinationIndex,
        0,
        ...components
      );
      const resultArray = arrangeTableName(copyArray(dataBodyOfCalculation));
      dispatch(
        reducerModifierDataBodyOfCalculationActionComposer(resultArray)
      );
      // BE INTEGRATION
      await service.putSubResource(idCalculation, 'component', 'move-many', {
        destinationPageIndex: activePage,
        destinationRowIndex: actualDestinationIndex,
        sources
      });
      return 'Success';
    } catch (error) {
      return error;
    }
  };
}

export function moveToOtherPage(params: any) {

  return async (dispatch: any, getState: any) => {
    try {
      const {
        idCalculation,
        components,
        sources,
        sourceIndex,
        pageIndexDestination
      } = params;
      // FE INTEGRATION
      const dataBodyOfCalculation: any = Array.from(
        getState().composer.dataBodyOfCalculation
      );
      const activePage: any = getState().composer.activePage;
      if (components.length === 0) {
        params.components = [dataBodyOfCalculation[activePage][sourceIndex]];
        params.sources = [{
          pageIndex: activePage,
          rowIndex: sourceIndex,
        }];
      }
      // remove from current page
      dataBodyOfCalculation[activePage] = dataBodyOfCalculation[
        activePage
      ].filter(
        (e: any) => components.findIndex((i: any) => i.id === e.id) === -1
      );
      // move to other page
      dataBodyOfCalculation[pageIndexDestination].push(...components);
      const resultArray = arrangeTableName(copyArray(dataBodyOfCalculation));
      dispatch(
        reducerModifierDataBodyOfCalculationActionComposer(resultArray)
      );
      // BE INTEGRATION
      await service.putSubResource(idCalculation, 'component', 'move-many', {
        destinationPageIndex: pageIndexDestination,
        destinationRowIndex: dataBodyOfCalculation[pageIndexDestination].length - 1,
        sources
      });
      return 'Success';
    } catch (error) {
      return error;
    }
  };
}

// TABLE FUNCTION

export function patchDataExpandTableActionComposer(payload: { [key: string]: any }) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const newTableBody: { [key: string]: any }[][] = copyArray(resultObj.tableBody);

    let alignment = newTableBody[payload.row][payload.col].alignment;

    const cellName = getCellName(parseInt(payload.col), parseInt(payload.row));
    const isNumericValue = isNumeric(payload.newValue) || /=/.test(payload.newValue);

    alignment = (isNumericValue && (!alignment || alignment === CellAlignment.DEFAULT)) ? CellAlignment.RIGHT : CellAlignment.LEFT;

    payload.worksheet.setStyle(cellName, 'text-align', alignment.toLowerCase());

    newTableBody[payload.row][payload.col] = {
      ...newTableBody[payload.row][payload.col],
      value: payload.newValue,
    };

    dispatch(reducerModifierDataPropertiesActionComposer({
      ...resultObj,
      tableBody: newTableBody,
    }));
  };
}

export function toolbarExpandTableActionComposer(typeAction: string, payload: { [key: string]: any }) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const newTableBody: { [key: string]: any }[][] = copyArray(resultObj.tableBody);

    switch (typeAction) {
      case 'TABLE_TOOLBAR_ALIGNMENT':
        const { selectedCells, alignment: newAlignment } = payload;
        selectedCells.forEach((cell: any) => {
          const { x: column, y: row } = cell;
          const currentAlignment = newTableBody[row][column].alignment;
          newTableBody[row][column] = {
            ...newTableBody[row][column],
            alignment: currentAlignment == newAlignment ? CellAlignment.DEFAULT : newAlignment,
          };
        });

        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: newTableBody,
        }));
        break;

      case 'TABLE_TOOLBAR_FREEZE_ROW':
        const { numberOfRow } = payload;
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          freeze: {
            ...resultObj.freeze,
            row: numberOfRow
          }
        }));
        break;

      case 'TABLE_TOOLBAR_FREEZE_COLUMN':
        const { numberOfColumn } = payload;

        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          freeze: {
            ...resultObj.freeze,
            column: numberOfColumn
          }
        }));
        break;

      case 'TABLE_TOOLBAR_NUMBER_FORMAT':
        const { cellProperties } = payload;
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          format: {
            cells: cellProperties
          }
        }));
        break;

      case 'TABLE_TOOLBAR_INCREASE_DECIMAL':
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          format: {
            cells: cellProperties
          }
        }));
        break;

      case 'TABLE_TOOLBAR_DECREASE_DECIMAL':
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          format: {
            cells: cellProperties
          }
        }));
        break;
    }
  };
}

export function actionButtonExpandTableActionComposer(typeAction: string, payload: { [key: string]: any }, workpageTableRef?: any, expandTableRef?: any) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const resultArray: { [key: string]: any }[][] = getState().composer.dataBodyOfCalculation;
    const totalColumn = resultObj.tableBody[0].length;
    const totalRow = resultObj.tableBody.length;
    expandTableRef.deleteTable();
    switch (typeAction) {
      case 'CANCEL':
        // FE INTEGRATION
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: resultArray[resultObj.activePage][resultObj.indexComponent].tableBody,
          displayedContent: resultArray[resultObj.activePage][resultObj.indexComponent].displayedContent,
          freeze: resultArray[resultObj.activePage][resultObj.indexComponent].freeze,
        }));
        break;
      case 'SAVE_AND_CLOSE':
        // TABLE INTEGRATION
        //// DATA SYNC
        /// SYNC EXPAND TABLE CELL PROPERTIES TO WORKPAGES
        if (!!resultObj?.format?.cells) {
          for (const [key, value] of Object.entries(resultObj.format.cells)) {
            workpageTableRef[resultObj.idComponent].setCellProperties(key, value);
          }
        }
        workpageTableRef[resultObj.idComponent].setData(copyArray(resultObj.tableBody));
        //// COLUMN WIDTH SYNC
        resultObj.tableHeader.forEach((element: { [key: string]: any }, index: number) => {
          workpageTableRef[resultObj.idComponent].setColumnWidth(index, element.width);
        });
        //// DISPLAYED CONTENT SYNC
        for (let i = 0; i < totalColumn; i++) {
          workpageTableRef[resultObj.idComponent].showColumn(i);
        }
        for (let i = 0; i < totalRow; i++) {
          workpageTableRef[resultObj.idComponent].showRow(i);
        }

        for (let i = 0; i < totalColumn; i++) {
          if (i >= resultObj.displayedContent?.column.start && i <= resultObj.displayedContent?.column.end) {
          } else {
            workpageTableRef[resultObj.idComponent].hideColumn(i);
          }
        }
        for (let i = 0; i < totalRow; i++) {
          if (i >= resultObj.displayedContent?.row.start && i <= resultObj.displayedContent?.row.end) {
          } else {
            workpageTableRef[resultObj.idComponent].hideRow(i);
          }
        }

        if (resultObj.freeze) {
          const { row, column } = resultObj.freeze;
          workpageTableRef[resultObj.idComponent].setFreeze(row, column);
        }

        // FE INTEGRATION
        const newDataBodyOfCalculation: { [key: string]: any }[][] = copyArray(resultArray);
        newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].tableHeader = copyArray(resultObj.tableHeader);
        newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].tableBody = copyArray(resultObj.tableBody);
        newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].displayedContent = resultObj.displayedContent;
        newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].freeze = resultObj.freeze;
        // SAVE CELL PROPERTIES IF EXIST
        if (!!resultObj?.format?.cells) {
          newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].format["cells"] = resultObj.format.cells;
        }
        dispatch(reducerModifierDataBodyOfCalculationActionComposer(newDataBodyOfCalculation));
        const headers = tableHeaderToHeaderConverter(newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].tableHeader);
        const cells = tableToCellsConverter(newDataBodyOfCalculation[resultObj.activePage][resultObj.indexComponent].tableBody);

        // BE INTEGRATION
        await addQueueComposerComponent(
          {
            idCalculation: payload.idCalculation,
            masterType: 'component',
            componentType: 'table',
          },
          {
            pageIndex: resultObj.activePage,
            rowIndex: resultObj.indexComponent,
            headers: headers,
            cells: cells,
            displayedContent: resultObj.displayedContent,
            freeze: resultObj.freeze,
            format: {
              cells: resultObj?.format?.cells
            }
          },
        );
        break;
    }
  };
}

export function updateDataAfterCellChanges(worksheet: any, updatedCells: Array<any>) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const newTableBody: { [key: string]: any }[][] = copyArray(resultObj.tableBody);

    const cellType = Object.keys(expandTableDefaultCellStyle);
    const cellStyle = Object.values(expandTableDefaultCellStyle);
    updatedCells.forEach((record: any) => {
      const { x, y } = record;
      const cellName = worksheet.helpers.getColumnNameFromCoords(x, y);
      const copiedStyle = worksheet.getStyle(cellName).split(';');
      const background = copiedStyle.find((style: string) => style.includes('background-color'));
      const index = cellStyle.findIndex(style => style.includes(background));
      newTableBody[y][x].type = cellType[index];
    });
    dispatch(reducerModifierDataPropertiesActionComposer({
      ...resultObj,
      tableBody: newTableBody,
    }));
  };
}

export function contextMenuExpandTableActionComposer(typeAction: string, payload: { [key: string]: any }, tableRef?: any) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const selectedCell = payload.worksheet.getSelected();
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const newTableBody: { [key: string]: any }[][] = copyArray(resultObj.tableBody);
    let resultGenerateFormulaTableCellStyle: { [key: string]: any };
    const { tableBody } = resultObj;
    switch (typeAction) {
      case 'SHOW_TO_LOGGER':
        const displayedContent = displayedContentConverter(payload.worksheet.getSelected());
        tableRef?.setBorder(displayedContent);
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          displayedContent: displayedContent,
        }));
        break;
      case 'CLEAR_SELECTION':
        tableRef?.resetBorder(resultObj.displayedContent);
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          displayedContent: null,
        }));
        break;
      case 'SET_AS_HEADER':
        // TABLE INTEGRATION
        resultGenerateFormulaTableCellStyle = generateFormulaTableCellStyle(payload.worksheet, 'header');
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.oldStyle);
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.newStyle);

        // FE INTEGRATION
        selectedCell.forEach((element: { [key: string]: any }) => {
          newTableBody[element.y][element.x].type = 'header';
        });
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: newTableBody,
        }));
        break;
      case 'SET_AS_INPUT_TO_LOGGER':
        // TABLE INTEGRATION
        resultGenerateFormulaTableCellStyle = generateFormulaTableCellStyle(payload.worksheet, 'input');
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.oldStyle);
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.newStyle);

        // FE INTEGRATION
        selectedCell.forEach((element: { [key: string]: any }) => {
          newTableBody[element.y][element.x].type = 'input';
        });
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: newTableBody,
        }));
        break;
      case 'SET_AS_READ_ONLY':
        // TABLE INTEGRATION
        resultGenerateFormulaTableCellStyle = generateFormulaTableCellStyle(payload.worksheet, 'default');
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.oldStyle);
        tableRef?.setStyle(resultGenerateFormulaTableCellStyle.newStyle);

        // FE INTEGRATION
        selectedCell.forEach((element: { [key: string]: any }) => {
          newTableBody[element.y][element.x].type = 'default';
        });
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: newTableBody,
        }));
        break;
      case 'ADD_ONE_ROW':
        payload.worksheet.insertRow();

        const newRow = [];
        for (let c = 0; c < newTableBody[0].length; c++) {
          newRow.push({ value: '', type: 'default' });
        }
        newTableBody.push(newRow);
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody,
        }));
        break;
      case 'ADD_ONE_COLUMN':
        payload.worksheet.insertColumn();

        for (let r = 0; r < tableBody.length; r++) {
          tableBody[r].push({ value: '', type: 'default' });
        }
        dispatch(reducerModifierDataPropertiesActionComposer({
          ...resultObj,
          tableBody: newTableBody,
        }));
        break;
    }
  };
}

export function patchColumnSizeExpandTableActionComposer(payload: { [key: string]: any }) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const resultObj: { [key: string]: any } = getState().composer.dataProperties;
    const tableHeader = resultObj.tableHeader;
    const { column: frozenColumn } = resultObj.freeze;
    payload.columnResized.forEach((columnIdx: number) => {
      if (columnIdx < frozenColumn) {
        tableHeader[columnIdx].width = FROZEN_COLUMN_DEFAULT_WIDTH;
        payload.worksheet.setProperties(columnIdx, { width: FROZEN_COLUMN_DEFAULT_WIDTH });
      }
      tableHeader[columnIdx].width = payload.newWidth;
    });
    dispatch(reducerModifierDataPropertiesActionComposer({
      ...resultObj,
      tableHeader: tableHeader
    }));
  };
}

// VARIABLE MANAGER FUNCTION

export function patchDataVariableManagerMenuActionComposer(vlcRef: any, workpageTableRef: any) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const dataBodyOfCalculation: { [key: string]: any }[][] = copyArray(getState().composer.dataBodyOfCalculation);
    const variableList: { name: string, cell: string, value: string | number }[] = [];
    const dataVariableManager: { id: string, type: string, description: string, variable: string, notation: string, unit: string, inputValue: string | number }[] = [];
    const sources: Record<string, any> = {};
    let calculatedSource: Record<string, any> = {};
    let afterCalculation: Record<string, any> = {};
    let getVariableList: any = '';
    let count = 1;

    FormulaHandler.resetVarList();

    dataBodyOfCalculation.forEach(async (dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        const componentTypeCheck: boolean = data.type === "NUMERICAL_INPUT" || data.type === "TEXT_INPUT" || data.type === "DROPDOWN_INPUT" || data.type === "RADIO_BUTTON_INPUT" || data.type === "FORMULA";
        const componentTruthyCheck: boolean = Boolean(data.description) && Boolean(data.variable) && Boolean(data.notation);
        if (componentTypeCheck && componentTruthyCheck) {
          dataVariableManager.push({
            id: data.id,
            type: data.type,
            description: data.description,
            variable: data.variable,
            notation: data.notation,
            unit: data.unit,
            inputValue: data.type === "FORMULA" && data.resultHyperFormula ? data.resultHyperFormula : data.type !== "FORMULA" && data.inputValue ? data.inputValue : 0,
          });
        };

        if (data.type !== 'TABLE' && data.type !== 'FORMULA' && data.variable) {
          if (data.type === 'NUMERICAL_INPUT' && !data.inputValue.includes('%')) {
            FormulaHandler.setVar(data.variable, data.inputValue);
          } else {
            variableList.push({
              name: data.variable ? data.variable : '',
              cell: 'formula!A' + count,
              value: valueParser(data.inputValue),
            });
            count++;
          }
        } else if (data.type === 'TABLE') {
          if (workpageTableRef[data.id]) {
            setTimeout(async () => {
              await workpageTableRef[data.id].renameTableName(uuidv4());
            }, 10);
          }
        }
      });
    });

    getVariableList = vlcRef.setVariableList(variableList);

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'TABLE' && workpageTableRef[data.id]) {
          workpageTableRef[data.id].setDefinedNames(getVariableList);
          sources[data.tableName.split(' ').join('')] = cleanupCurrencyFormat(formatTableData(workpageTableRef[data.id].getData(true)));
        };
      });
    });

    calculatedSource = sources;
    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'FORMULA' && data.formula && data.variable) {
          const result = FormulaHandler.calculate(data.formula.substring(1), sources);
          calculatedSource[data.variable] = result;
        } else if (data.type !== 'FORMULA' && data.type !== 'TABLE' && data.variable) {
          calculatedSource[data.variable] = valueParser(data.inputValue);
        }
      });
    });

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        let result: any = '';
        if (data.type === 'FORMULA') {
          result = FormulaHandler.calculate(data.formula?.substring(1), calculatedSource) ?? null;
          variableList.push({
            name: data.variable ? data.variable : '',
            cell: 'formula!A' + count,
            value: result ? result.toString() : '#ERROR!',
          });
          count++;
        };
      });
    });

    getVariableList = vlcRef.setVariableList(variableList);

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'TABLE' && workpageTableRef[data.id]) {
          setTimeout(async () => {
            await workpageTableRef[data.id].renameTableName(data.tableName.split(' ').join(''));
          }, 10);
        }
      });
    });

    afterCalculation = calculatedSource;
    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'FORMULA' && data.formula && data.variable) {
          const result = FormulaHandler.calculate(data.formula.substring(1), calculatedSource);
          afterCalculation[data.variable] = result;
        } else if (data.type !== 'FORMULA' && data.type !== 'TABLE' && data.variable) {
          afterCalculation[data.variable] = valueParser(data.inputValue);
        }
      });
    });

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[]) => {
      dataPages.forEach((data: { [key: string]: any }) => {
        if (data.type === 'TABLE' && workpageTableRef[data.id]) {
          workpageTableRef[data.id].setDefinedNames(getVariableList);
          workpageTableRef[data.id].setData(data.tableBody);
          afterCalculation[data.tableName.split(' ').join('')] = cleanupCurrencyFormat(formatTableData(workpageTableRef[data.id].getData(true)));
        };
      });
    });

    dataBodyOfCalculation.forEach((dataPages: { [key: string]: any }[], indexPage: number) => {
      dataPages.forEach((data: { [key: string]: any }, indexData: number) => {
        let result: any = '';
        let errorMessage = '';
        if (data.type === 'FORMULA') {
          if (!data.formula) {
            errorMessage = 'Formula must be filled';
          } else if (data.formula[0] !== '=') {
            errorMessage = 'Please input with the format of Excel operations';
          } else if (slice.exec(data.formula).includes(data.variable)) {
            errorMessage = 'Cannot call itself';
          } else {
            result = FormulaHandler.calculate(data.formula?.substring(1), afterCalculation) ?? null;
            if (result === '#ERROR!') errorMessage = '#ERROR!';
            dataBodyOfCalculation[indexPage][indexData] = {
              ...dataBodyOfCalculation[indexPage][indexData],
              error: errorMessage ? errorMessage : false,
              resultHyperFormula: errorMessage ? '#ERROR!' : result,
            };
          };
        };
      });
    });

    dispatch(reducerModifierDataBodyOfCalculationActionComposer(dataBodyOfCalculation));
    dispatch(reducerModifierDataVariableManagerMenuActionComposer(dataVariableManager));

  };
};

// GET DATA FUNCTION

export function getSymbolActionComposer() {
  return async (dispatch: Dispatch) => {
    try {
      const symbolsService = new Service('symbols');
      const { data } = await symbolsService.get();
      dispatch(reducerModifierDataSymbolActionComposer(data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getDataActionComposer(id: string) {
  return async (dispatch: any) => {
    try {
      dispatch(reducerModifierIsFetchingActionComposer(true));
      const response = await CalculationService.getCalculationById(id);
      const resultArray: any = Array.from(response.pages);
      const finalArray: any = [];
      const dataPages: any = [];

      let indexTable = 1;

      for (let i = 0; i < resultArray.length; i++) {
        dataPages.push({
          name: resultArray[i].pageTitle,
          hidden: resultArray[i].isHidden,
        });
        const tempArray: any = [];
        for (let j = 0; j < resultArray[i].components.length; j++) {
          const { type, properties, columnId } = resultArray[i].components[j];
          switch (type) {
            case 'TEXT_INPUT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                notation: properties.notation,
                tooltip: properties.tooltip,
                notes: properties.notes,
                inputValue: "",
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'DROPDOWN_INPUT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                notation: properties.notation,
                options: properties.options,
                tooltip: properties.tooltip,
                notes: properties.notes,
                inputValue: "",
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'RADIO_BUTTON_INPUT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                notation: properties.notation,
                options: properties.options,
                tooltip: properties.tooltip,
                notes: properties.notes,
                inputValue: "",
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'NUMERICAL_INPUT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                notation: properties.notation,
                unit: properties.unit,
                hardMax: properties.hardMax,
                hardMin: properties.hardMin,
                softMax: properties.softMax,
                softMin: properties.softMin,
                tooltip: properties.tooltip,
                notes: properties.notes,
                // inSummary: properties.inSummary,
                inputValue: properties.format?.value?.percentage === true ? '1 %' : "1.000",
                showMenu: false,
                format: properties.format
              });
              break;
            case 'DATE_INPUT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                tooltip: properties.tooltip,
                notes: properties.notes,
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'FORMULA':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                variable: properties.variable,
                notation: properties.notation,
                unit: properties.unit,
                formula: properties.formula,
                reference: properties.reference,
                isDisplayed: properties.isDisplayed,
                tooltip: properties.tooltip,
                notes: properties.notes,
                // inSummary: properties.inSummary,
                error: properties.formula ? false : 'Formula must be filled',
                showMenu: false
              });
              break;
            case 'TABLE':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                tooltip: properties.tooltip,
                notes: properties.notes,
                tableName: `Table ${indexTable}`,
                tableHeader: headerToTableHeaderConverter(properties.headers),
                tableBody: cellsToTableConverter(properties.cells),
                displayedContent: properties.displayedContent,
                freeze: properties.freeze,
                isAllowedToAddRow: properties.isAllowedToAddRow,
                // inSummary: properties.inSummary,
                showMenu: false,
                format: {
                  cells: properties.format.cells
                }
              });
              indexTable++;
              break;
            case 'TEXT':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                level: properties.level,
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'LINK':
              tempArray.push({
                id: columnId,
                type: type,
                url: properties.url,
                title: properties.description,
                showMenu: false
              });
              break;
            case 'DIVIDER':
              tempArray.push({
                id: columnId,
                type: type,
                showMenu: false
              });
              break;
            case 'IMAGE':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                imageUrl: properties.imageUrl,
                imageWidth: properties.width,
                imageHeight: properties.height,
                imageType: properties.imageType,
                imageAlignment: properties.alignment,
                // inSummary: properties.inSummary,
                showMenu: false
              });
              break;
            case 'FIELD':
              tempArray.push({
                id: columnId,
                type: type,
                description: properties.description,
                tooltip: properties.tooltip,
                notes: properties.notes,
                fieldType: properties.fieldType,
                ...(properties.fieldType === 'DROPDOWN' || properties.fieldType === 'RADIO_BUTTON') && {
                  options: properties.options,
                }
              });
          }
        }
        finalArray.push(tempArray);
      }
      dispatch(reducerModifierDataPagesActionComposer(dataPages));
      dispatch(reducerModifierDataHeadOfCalculationActionComposer({
        idCalculation: id,
        title: response.title,
        description: response.description,
        createdBy: response.createdBy.name,
        createdAt: response.createdAt,
      }));
      dispatch(reducerModifierDataBodyOfCalculationActionComposer(finalArray));
      dispatch(reducerModifierIsFetchingActionComposer(false));
    } catch (error) {
      console.error(error);
    }
  };
}

const mappingDuplicateComponent = (lastIndex: number, count: number, arr: any, index: number, type: string, id: string, pageActive: number, workpageTableRef: any, asyncMemory: any, setValue?: UseFormSetValue<any>) => {
  arr[pageActive].splice(lastIndex + 1, 0, {
    ...arr[pageActive][index],
    id: uuidv4(),
    showMenu: false,
    ...(
      'options' in arr[pageActive][index] && {
        options: Boolean(arr[pageActive][index].options) ? copyArray(arr[pageActive][index].options) : null,
      }
    ),
    ...(
      'tableBody' in arr[pageActive][index] && {
        tableBody: Boolean(arr[pageActive][index].tableBody) ? copyArray(arr[pageActive][index].tableBody) : null,
      }
    ),
    ...(
      'variable' in arr[pageActive][index] && {
        variable: null,
      }
    ),
    ...(
      'notation' in arr[pageActive][index] && {
        notation: null,
      }
    )
  });

  if (workpageTableRef[id] && type === 'TABLE') {
    const tableIndex = arr[pageActive][index].tableName.split(' ')[1];
    arr.forEach(async (pages: any, indexPage: number) => {
      pages.forEach(async (component: any, indexComponent: number) => {
        if (component.type === 'FORMULA') {
          let formula: any = component.formula;
          const regexp = /table[\d]+/gi;
          const tablesName = formula.match(regexp) ? [...formula.match(regexp)] : [];
          if (tablesName.length > 0) {
            tablesName.forEach((tableName: string) => {
              const thenum: any = tableName.replace(/^\D+/g, '');
              if (thenum > tableIndex) {
                formula = formula.replaceAll(thenum, (+thenum + 1));
              };
            });
          };
          component.formula = formula;
          if (setValue) setValue(`formula_${component.id}`, formula);
          asyncMemory.push({
            pageIndex: indexPage,
            rowIndex: indexComponent,
            formula: formula,
          });
        };
      });
    });
  };

  return arrangeTableName(copyArray(arr));
};