import { ListItemIcon } from '@mui/material';
import Text from '../../components/atoms/Text';
import StyledButton from '../../components/atoms/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StyledCardV2 } from '../../components/molecules/Card-v2';

export default function SubscriptionBody(props: any) {
  const { item, subscription, handleUpgradeAccount, hoverEl, setHoverEl } =
    props;
  const { plan } = subscription ?? { plan: '' };

  const isSelectedPlan = () => plan.includes(item.currentPlan);

  return (
    <StyledCardV2
      height={522}
      width={356}
      padding={'2rem'}
      currentPlan={isSelectedPlan()}
      alignItems={'stretch'}
      colorBorder={item.colorBorder}
      selected={hoverEl === item.name}
      onMouseEnter={() => setHoverEl(item.name)}
      onMouseLeave={() => setHoverEl('')}
      style={{
        backgroundColor: isSelectedPlan() ? '#F0F2F5' : '',
        minWidth: 300
      }}
    >
      <div style={{ padding: 0, margin: 0, textAlign: 'left' }}>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div>
            <Text variant="h1" style={{ paddingBottom: 24 }}>
              {item.name}
            </Text>
          </div>
          <div
            style={{ width: 122, position: 'absolute', right: -20, top: -20 }}
          >
            {item.pill}
          </div>
        </div>
        <Text
          variant="body2"
          style={{ paddingBottom: 24, height: 66, boxSizing: 'border-box' }}
          color={'#5C738A'}
        >
          {item.text}
        </Text>
        <div
          style={{
            width: 292,
            height: 64,
            alignItems: 'end',
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 24
          }}
        >
          <div style={{ paddingRight: 36 }}>
            {item.title.map((title: any, index: number) => (
              <div
                style={{ color: title.disabled ? '#A3B3C2' : '' }}
                key={`subs_body_div_${index}`}
              >
                <Text variant="body3">{title.subTitle}</Text>
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                  <Text
                    variant="h1"
                    style={{
                      fontSize: 36,
                      textDecoration: title.textDecoration
                        ? 'line-through'
                        : '',
                      textDecorationColor: title.textDecoration ? 'red' : '',
                      color: title.disabled ? '#A3B3C2' : '#29333D'
                    }}
                  >
                    {title.title}
                  </Text>
                  <Text
                    variant="h5"
                    style={{
                      alignItems: 'start',
                      justifyContent: 'end',
                      // textAlign: 'end',
                      color: title.secondTitle ? '#47596B' : '',
                      minHeight: 24
                    }}
                  >
                    {title.secondTitle}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div style={{ color: '#118CFF' }}>
            <Text variant="body3">{item.secondSubTitle}</Text>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Text variant="h1" style={{ fontSize: 36, color: '#118CFF' }}>
                {item.secondTitle}
              </Text>
            </div>
          </div>
        </div>
        <Text variant="h5" style={{ paddingBottom: 16 }}>
          {item.subText}
        </Text>
        <div
          style={{
            width: 292,
            height: 156,
            flexDirection: 'column',
            display: 'flex'
          }}
        >
          {item.included.map((include: any, index: number) => (
            <ListItemIcon
              style={{ paddingBottom: 16 }}
              key={`list_item_body_${index}`}
            >
              <CheckCircleIcon
                sx={{
                  width: 16,
                  height: 16,
                  color: isSelectedPlan() ? '#D1D9E0' : '#118CFF'
                }}
              />
              <Text
                variant="body3"
                color="#29333D"
                style={{ paddingLeft: 14, textAlign: 'start' }}
              >
                {include}
              </Text>
            </ListItemIcon>
          ))}
        </div>
        <StyledButton
          variant="contained"
          fullWidth
          data-testid="btnUpgradeSubscription"
          style={{
            flexDirection: 'column',
            textTransform: 'none',
            padding: '12px 16px 12px 16px',
            height: 48,
            backgroundColor: item.disabled ? '#D6DFEB' : 'primary',
            color: item.disabled ? '#A3B3C2' : 'white'
          }}
          disabled={item.disabled}
          onClick={() => handleUpgradeAccount(item.currentPlan)}
        >
          <Text color="inherit" variant="h4">
            {item.buttonText}
          </Text>
        </StyledButton>
      </div>
    </StyledCardV2>
  );
}
