/**
 * @params table ID: string
 * @params table Description: string
 * @params table Data: any {
 *  data: Array<Array<CellContent>>
 *  cellStyles: CellStyle
 * }
 * @params cellStyle
 * @params dimensions
 * @params displayedContent (properties displayedContent not yet available in pre-launch endpoint)
 * @params onValueChange
 */

import {
  ComponentDimensionsI,
  PrintFormationI,
  TableDimensionI
} from '../../../pages/printView/print.types';
import { TableContent, TableDescription, TableWrapper } from './styles';
import { forwardRef, useEffect, useState } from 'react';
import {
  isEven,
  splitTableByColumn,
  TableHeaderI
} from '../../../pages/printView/utils';
import { FormulaTable } from '../FormulaTable';
import Loading from '../../atoms/loading';
import Text from '../../atoms/Text';
import { expandTableDefaultCellStyle } from '../../../utils/constant';
import { generateSpreadsheetData } from '../../../pages/loggerRefactor/utils';
import parser from 'html-react-parser';
import { theme } from '../../../utils/theme';

interface PropsI {
  properties: any;
  columnId: string;
  setTableDimension: (data: TableDimensionI) => void;
  isPrint: boolean;
}

export const Table = forwardRef<any, PropsI>(
  ({ properties, columnId, setTableDimension, isPrint }, ref) => {
    const { cells, variable } = properties;
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tableSplit, setTableSplit] = useState<any>([]);

    async function onInit() {
      const { cells, displayedContent, freeze, headers } = properties;
      const dataTable: any = await generateSpreadsheetData(
        cells,
        displayedContent,
        freeze,
        false,
        headers
      );

      const dataSplit = splitTableByColumn(
        dataTable.data,
        dataTable.headers,
        dataTable.indexRows,
        freeze
      );
      setTableSplit(dataSplit);
    }

    useEffect(() => {
      Array.isArray(properties.cells) &&
        properties.cells?.length > 0 &&
        onInit();
    }, [null]);

    if (tableSplit?.length === 0 || tableSplit === undefined)
      return <Loading />;
    return (
      <TableWrapper ref={ref}>
        {isLoading ? (
          <Loading />
        ) : (
          tableSplit.length &&
          tableSplit
            .filter((el: any) => el.data.length)
            ?.map((item: any, idx: number) => {
              const rows: any = Array.from(
                { length: item.rowEnd },
                (v: any, k: number) => {
                  const oddIndex = tableSplit[idx + 1]?.data?.length + k + 1;
                  const evenIndex = tableSplit[idx - 1]?.data?.length + k + 1;
                  return {
                    title: isEven(idx)
                      ? (k + 1).toString()
                      : Boolean(oddIndex)
                      ? oddIndex.toString()
                      : evenIndex.toString()
                  };
                }
              );

              return (
                <TableContent
                  key={`table_content_${idx}`}
                  ref={(el: HTMLDivElement) =>
                    setTableDimension({
                      element: el,
                      columnId,
                      height: el ? el.offsetHeight : 0,
                      key: `table_${columnId}_${idx}`
                    })
                  }
                >
                  {item.indexRows[0].title === 1 && idx === 0 && (
                    <TableDescription>
                      <Text variant="body3" color={theme.customText.disable}>
                        {properties?.tableNum}
                        {properties?.tableName}
                      </Text>
                      <Text variant="body3">
                        {properties.description
                          ? parser(properties.description)
                          : properties.description}
                      </Text>
                    </TableDescription>
                  )}
                  <FormulaTable
                    name={properties.variable}
                    data={item.data}
                    dimensions={[item.columnEnd, item.rowEnd]}
                    cellStyle={expandTableDefaultCellStyle}
                    hideHiddenCells={true}
                    columnSetting={item.headers}
                    displayedContent={{
                      row: {
                        start: 0,
                        end: item.rowEnd
                      },
                      column: {
                        start: 0,
                        end: item.columnEnd
                      }
                    }}
                    editable={false}
                    rows={isPrint ? item.indexRows : []}
                    contextMenu={() => {
                      return false;
                    }}
                    showHeader={true}
                    showIndex={false}
                    cells={properties?.format?.cells}
                  />
                </TableContent>
              );
            })
        )}
      </TableWrapper>
    );
  }
);
