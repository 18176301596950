import { ACTION_REPORT, CHANGE_TYPE_VIEW, GET_DRIVE_ITEMS, GET_TEAM_DRIVES, LOGGER_PREVIEW, SET_HELPER_PANEL, SET_WORKSPACE, SHOW_HIDE_MODAL_ACTION, SHOW_HIDE_MODAL_EXPLORER, SHOW_HIDE_MODAL_INFO, SUBMIT_LOADING } from './globalTypes';

import { Dispatch } from 'redux';
import GenericService from "../../../service/generic/generic";
import { convertBase64 } from '../../../utils/helpersFunction';
import { getDriveStatus } from '../../types/userTypes';
import { DriveI } from '../../../pages/businessDrives/businessDrives.types';

export const showHideModalAction = (value: boolean, typeAction: string) => {
  return {
    type: SHOW_HIDE_MODAL_ACTION,
    value,
    typeAction
  };
};

export const showHideModalInfo = (value: boolean, messageInfo: string) => {
  return {
    type: SHOW_HIDE_MODAL_INFO,
    value,
    messageInfo
  };
};

export const showHideModalExplorer = (value: boolean, typeAction: string) => {
  return {
    type: SHOW_HIDE_MODAL_EXPLORER,
    value,
    typeAction
  };
};

export const changeTypeView = (typeView: string) => {
  return {
    type: CHANGE_TYPE_VIEW,
    typeView: typeView
  };
};

export const submitLoading = (payload: boolean) => {
  return {
    type: SUBMIT_LOADING,
    payload
  };
};

export const setWorkspace = (payload: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SET_WORKSPACE,
        payload
      });
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};


export function setBreadcumbs(id: string) {
  return async () => {
    try {
      const service = new GenericService(`folders/${id}/breadcrumbs`);
      const result = await service.get();
      return Promise.resolve(result.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export const setHelperPanel = (data: any) => {
  return {
    type: SET_HELPER_PANEL,
    data
  };
};

export const formHelperPanel = (isOpen: boolean, type?: string, payload?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setHelperPanel({
        isOpen: isOpen,
        type: isOpen ? type : "",
        payload: isOpen ? payload : []
      }));
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const fetchPanelInfoList = () => {
  return async () => {
    try {
      const service = new GenericService(`tutorials/list`);
      const response = await service.get();
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const fetchTutorial = (idTutorial: string) => {
  return async () => {
    try {
      const tutorialService = new GenericService(`tutorial/${idTutorial}`);
      const response = await tutorialService.get();
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const setTutorialDone = (idTutorial: string) => {
  return async () => {
    try {
      const tutorialService = new GenericService(`user/tutorial`);
      const response = await tutorialService.putCompletedTutorial(idTutorial);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const setActionReport = (types: string, payload?: any) => {
  return {
    type: ACTION_REPORT,
    types,
    payload
  };
};

export const setLoggerPreview = (payload: any) => {
  return {
    type: LOGGER_PREVIEW,
    payload
  };
};

export const uploadLoggerPreview = (calculationId: string, payload: string) => {
  return async () => {
    try {
      const latexImage: any = await convertBase64(payload);
      fetch(latexImage)
        .then(res => res.blob())
        .then(async blob => {
          const data = new FormData();
          const file = new File([blob], `${calculationId}.png`);
          data.append('file', file);
          const service = new GenericService(`calculation`);
          await service.putResource(calculationId, 'preview', data);
        });
      return Promise.resolve(latexImage);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const getTeamDrives = (data: any) => {
  return {
    type: GET_TEAM_DRIVES,
    data
  };
};

export const fetchTeamDrives = (id?: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const type = 'FOLDER,CALCULATION';
      dispatch(submitLoading(true));
      const drivesService = new GenericService(`drives?isActive=true`);
      const folderService = new GenericService(`folders`);
      const response = id ? await folderService.getSubresource(id, `view?type=${type}&isActive=true`) : await drivesService.get();
      id ? dispatch(getDriveItems(response.data)) : dispatch(getTeamDrives(response.data));
      !id && dispatch(getDriveStatus({
        isHaveDrive: Boolean(response.data),
        isHaveManageDrive: Boolean(response.data?.filter((item: DriveI) => !/VIEWER/.test(item.memberRole.type)).length)
      }));
      dispatch(submitLoading(false));
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const createTeamDrive = (title: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(submitLoading(true));
      const drivesService = new GenericService(`folders`);
      const response = await drivesService.post({
        title: title,
        type: 'TEAM'
      });
      dispatch(submitLoading(false));
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const renameTeamDrive = (payload: any, isDrive: boolean) => {
  return async () => {
    try {
      const drivesService = new GenericService(`folders`);
      const folderService = new GenericService(`folder`);
      const response = isDrive ? await drivesService.putAction('rename', payload) : await folderService.putAction(`${payload.id}/title`, { title: payload.title });
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const getDriveItems = (data: any) => {
  return {
    type: GET_DRIVE_ITEMS,
    data
  };
};
