import { InputProps } from '../../logger.types';
import { Tooltip, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import { StyledTextArea } from '../../../../components/atoms/TextArea';
import {
  DescriptionBoxField,
  InputBoxField,
  InputWrapper,
  FieldWrapper,
  NotationBox
} from './styles';

function TextField({
  name,
  control,
  properties,
  defaultValue,
  multiLine,
  resize,
  style
}: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules: {
      validate: {
        required: value => {
          if (!value) return `${properties.description} is required`;
          return true;
        }
      }
    },
    defaultValue
  });

  return (
    <FieldWrapper>
      <DescriptionBoxField contentEditable={false}>
        <Typography variant="body3" component={'span'}>
          {properties.description
            ? parser(properties.description)
            : properties.description}
        </Typography>
      </DescriptionBoxField>

      <InputBoxField>
        <Tooltip title={properties.tooltip === null ? '' : properties.tooltip}>
          <StyledTextArea
            fullWidth
            type={'text'}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
            inputRef={ref}
            error={Boolean(error) || value === null || value === ''}
            size="small"
            placeholder={'Input text here'}
            multiline={multiLine}
            resize={resize}
          />
        </Tooltip>
      </InputBoxField>
    </FieldWrapper>
  );
}

export default TextField;
