import { Snackbar } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ReduxState } from '../../../../store';
import { toastSetupActionPopUp } from '../../../../store/appState/popUp/toast/toastAction';
import Icon from '../../../atoms/Icon';

import { ToastBox, TextButton } from './elements';

export default function Toast() {
  // USESELECTOR AND USEDISPATCH

  const { dataToast } = useSelector(
    (state: ReduxState) => state.toastPopUp,
    shallowEqual
  );

  const dispatch = useDispatch();

  // FUNCTION

  const closeToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason !== 'clickaway') {
      dispatch(toastSetupActionPopUp('CLOSE'));
    }
  };

  return (
    <Snackbar
      open={dataToast.isOpen}
      autoHideDuration={dataToast.type === 'REFRESH' ? null : 2500}
      anchorOrigin={{
        vertical: dataToast.position.vertical,
        horizontal: dataToast.position.horizontal
      }}
      onClose={closeToast}
    >
      <ToastBox type={dataToast.type}>
        {dataToast.label}

        {dataToast.type === 'REFRESH' && (
          <TextButton
            type={'REFRESH'}
            onClick={() => location.reload()}
          >
            Refresh
          </TextButton>
        )}

        {dataToast.type === 'ERROR_TERTIARY' && (
          <TextButton
          type={'ERROR_TERTIARY'}  
          onClick={closeToast}
          >
            OK
          </TextButton>
        )}  

        {dataToast.type === 'ERROR_SECONDARY' && (
          <Icon icon="closeNoBorder" color="#DE294C" onClick={closeToast} />
        )}
        {dataToast.type === 'REFRESH' && (
          <Icon icon="closeNoBorder" color="#FFFFF" onClick={closeToast} />
        )}
      </ToastBox>
    </Snackbar>
  );
}
