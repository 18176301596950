import {
  DATASWITCHTYPEMENUCOMPOSER,
  DataSwitchTypeMenuComposer,
} from './composerMenuTypes';

interface initialStateI {
  dataSwitch: any;
};

const initialState: initialStateI = {
  dataSwitch: {
    components: true,
    variableNetwork: false,
    variableManager: false,
    symbol: false,
    comments: false
  }
};

type Action = DataSwitchTypeMenuComposer;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case DATASWITCHTYPEMENUCOMPOSER:
      return { ...state, dataSwitch : action.data };
    default:
      return state;
    }
}