import { LinearProgressProps } from "@mui/material";
import { StyledProgressBar } from "./styles";

type ProgresBarProps = {
    current: number
    total: number
} & LinearProgressProps;

export default function ProgressBar({current, total, ...restProps}: ProgresBarProps) {
    const value = (current / total) * 100;
    return (
        <StyledProgressBar {...restProps} value={value} />
    );
}