import * as Icon from '../../../assets/icons';

export const mapIconType = {
  area: <Icon.Area />,
  arrowDown: <Icon.ArrowDown />,
  arrowLeft: <Icon.ArrowLeft />,
  arrowRight: <Icon.ArrowRight />,
  arrowUp: <Icon.ArrowUp />,
  borderCopy: <Icon.BorderCopy />,
  borderDelete: <Icon.BorderDelete />,
  calendar: <Icon.Calendar />,
  check: <Icon.Check />,
  chevronDown: <Icon.ChevronDown />,
  chevronLeft: <Icon.ChevronLeft />,
  chevronRight: <Icon.ChevronRight />,
  chevronUp: <Icon.ChevronUp />,
  close: <Icon.Close />,
  closeNoBorder: <Icon.CloseNoBorder />,
  decreaseDecimal: <Icon.DecreaseDecimal />,
  diagram: <Icon.Diagram />,
  divider: <Icon.Divider />,
  dollar: <Icon.Dollar />,
  dollarSign: <Icon.DollarSign />,
  drag: <Icon.Drag />,
  edit: <Icon.Edit />,
  eyeOff: <Icon.EyeOff />,
  eyeOn: <Icon.Eye />,
  euro: <Icon.Euro />,
  expand: <Icon.Expand />,
  file: <Icon.File />,
  folder: <Icon.Folder />,
  formula: <Icon.Formula />,
  Grid: <Icon.Grid />,
  height: <Icon.Height />,
  help: <Icon.Help />,
  home: <Icon.Home />,
  image: <Icon.Image />,
  increaseDecimal: <Icon.IncreaseDecimal />,
  input: <Icon.Input />,
  link: <Icon.Link />,
  list: <Icon.List />,
  lock: <Icon.Lock />,
  maximize: <Icon.Maximize2 />,
  menu: <Icon.Menu />,
  moreHorizontal: <Icon.MoreHorizontal />,
  moreVertical: <Icon.MoreVertical />,
  percent: <Icon.Percent />,
  phyton: <Icon.Phyton />,
  placeholder: <Icon.Placeholder />,
  play: <Icon.Play />,
  plus: <Icon.Plus />,
  pound: <Icon.Pound />,
  printer: <Icon.Printer />,
  python: <Icon.Phyton />,
  radioOff: <Icon.RadioOff />,
  radioOn: <Icon.RadioOn />,
  rupiah: <Icon.Rupiah />,
  save: <Icon.Save />,
  search: <Icon.Search />,
  settings: <Icon.Settings />,
  subscript: <Icon.Subscript />,
  superscript: <Icon.Superscript />,
  symbol: <Icon.Symbol />,
  table: <Icon.Table />,
  text: <Icon.Text />,
  times: <Icon.Times />,
  uncheck: <Icon.Uncheck />,
  unlink: <Icon.Unlink />,
  upload: <Icon.Upload />,
  user: <Icon.User />,
  width: <Icon.Width />,
  yen: <Icon.Yen />,
  fx: <Icon.Fx />,
  calc: <Icon.Calc />,
  driveFilled: <Icon.DriveFilled />,
  driveOutlined: <Icon.DriveOutlined />,
  homeOutlined: <Icon.HomeOutlined />,
  field: <Icon.Field />,
  dot: <Icon.Dot />,
  arrowRightSecondary: <Icon.ArrowRightSecondary />,
  talkTaylor: <Icon.TalkTaylor />,
  sendChat: <Icon.SendChat />,
  taylorOff: <Icon.TaylorOff />,
  askAce: <Icon.AskAce />,
  copy: <Icon.Copy />,
  like: <Icon.Like />,
  dislike: <Icon.Dislike />,
  information: <Icon.Information />,
  externalLink: <Icon.ExternalLink />,
  logOut: <Icon.LogOut />,
  typeBook: <Icon.TypeBook />,
  refresh: <Icon.Refresh />,
  drawing: <Icon.Drawing />,
  playOutlined: <Icon.PlayOutlined />,
  helpOutlined: <Icon.HelpOutlined />,
  hcss: <Icon.HcssLogo />,
};
