import React, { useEffect } from 'react';
import {
  MenuOutlined,
  KeyboardArrowDown,
  GridViewOutlined,
  FormatListBulletedOutlined
} from '@mui/icons-material';
import {
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton
} from '@mui/material';
import { CalculationsGrid } from './elements';
import { menuStyle } from '../../../utils/theme';
import { menuInputFileItems } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxState } from '../../../store';
import { clearRecentInputFile } from '../../../store/actions/loggerAction';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';
import { getBusinessDriveStatus, getUserDetails } from '../../../utils/storage';

interface InputFileMenuI {
  styleViewEnabled?: boolean;
  styleView: string;
  handleSelectStyle: (params: string) => void;
  handleMinimizeSidebar: () => void;
  location: any;
  setDriveStatus: (isHaveDrive: boolean) => void;
}

//TO DO HANDLE ALL FUNCTION

export default function InputFileMenu(props: any) {
  const {
    styleViewEnabled = true,
    styleView,
    handleSelectStyle,
    handleMinimizeSidebar,
    setDriveStatus
  }: InputFileMenuI = props;

  function checkPath2(pathname: string, value: string) {
    return pathname === '/input-file/' + value;
  }

  const seeInput = checkPath2(
    props.location.pathname,
    props.match.params.calculationId
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const { calculationInfo } = useSelector(
    (state: ReduxState) => state.inputFileManager,
    shallowEqual
  );

  const { isHaveDrive } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const userProfile = getUserDetails();

  const { subscription } = userProfile;

  const { calculationId } = useParams<{ calculationId: string }>();

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = setDriveStatus(
      isHaveDrive ||
        getBusinessDriveStatus().isHaveDrive ||
        /(BUSINESS|BUSINESS_PLUS)/.test(subscription?.plan)
    );
    return unsubscribe;
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handelOnclickListNewCalc = async (key: string) => {
    if (key === 'createInputFile') {
      if (!calculationInfo.isComplete) {
        dispatch(
          toastSetupActionPopUp(
            'ERROR',
            'This calculation is incomplete! Please edit the calculation first'
          )
        );
        return;
      }

      try {
        createNewInputFile();
      } catch (err) {
        console.error(err);
      }
    }
    setAnchorEl(null);
  };

  const createNewInputFile = async () => {
    try {
      await Promise.all([dispatch(clearRecentInputFile())])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(_ => {
          history.push(`/logger/${calculationId}`);
        })
        .catch(error => console.error(error));
    } catch (error: any) {
      console.error(error);
    }
  };

  const renderMenuList = () => (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl?.id === 'modeView'}
      onClose={handleCloseMenu}
      onClick={handleCloseMenu}
      PaperProps={{
        style: {
          marginLeft: 5,
          padding: 8
        },
        elevation: 0,
        sx: menuStyle
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        selected={styleView === 'Tiles' || styleView === ''}
        onClick={() => handleSelectStyle('Tiles')}
      >
        <ListItemIcon>
          <GridViewOutlined fontSize="small" />
        </ListItemIcon>
        Tiles
      </MenuItem>
      <MenuItem
        selected={styleView === 'List'}
        onClick={() => handleSelectStyle('List')}
      >
        <ListItemIcon>
          <FormatListBulletedOutlined fontSize="small" />
        </ListItemIcon>
        List
      </MenuItem>
    </Menu>
  );

  return (
    <CalculationsGrid>
      <div>
        <MenuOutlined onClick={() => handleMinimizeSidebar()} />
        {seeInput && (
          <>
            <Button
              onClick={handleOpenMenu}
              id="newCalc"
              variant="contained"
              endIcon={<KeyboardArrowDown />}
              size="small"
              data-testid="btnNew"
            >
              NEW
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={anchorEl?.id === 'newCalc'}
              onClose={handleCloseMenu}
            >
              {menuInputFileItems.map((listMenu: any, menuindex: number) => (
                <div style={{ width: 192, height: '100%' }} key={menuindex}>
                  <MenuItem
                    onClick={() => {
                      handelOnclickListNewCalc(listMenu.key);
                    }}
                    data-testid={listMenu.dataTestid}
                  >
                    {listMenu.title}
                  </MenuItem>
                  {listMenu.divider && <Divider />}
                </div>
              ))}
            </Menu>
            <Divider orientation="vertical" flexItem variant="middle" />
          </>
        )}
      </div>
      {styleViewEnabled && (
        <div>
          <p>View:</p>
          <IconButton onClick={handleOpenMenu} id="modeView" size="small">
            {styleView === 'List' ? (
              <FormatListBulletedOutlined fontSize="small" />
            ) : (
              <GridViewOutlined fontSize="small" />
            )}
          </IconButton>
          {renderMenuList()}
        </div>
      )}
    </CalculationsGrid>
  );
}
