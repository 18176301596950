import { Editor as DefaultEditor, EditorState, Modifier } from 'draft-js';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import createInlineToolbarPlugins from '@draft-js-plugins/inline-toolbar';
import draftToHtml from 'draftjs-to-html';
import Editor from '@draft-js-plugins/editor';
import { SubButton, SupButton } from '@draft-js-plugins/buttons';
import editorStyles from './editorStyles.module.css';
import buttonStyles from './buttonStyles.module.css';
import toolbarStyles from './toolbarStyles.module.css';
import {
  draftBlockToHtml,
  getContentLength,
  getLengthOfSelectedText,
  htmlToDraftBlocks
} from '../../../utils/rich-text-editor.utils';
import { RichTextPropsI } from './RichTextToolbar';

const RichTextToolbar = (
  {
    source,
    onChange,
    handleSaveChange,
    headingLevel,
    isRequired,
    maxLength,
    ...rest
  }: RichTextPropsI,
  ref?: any
) => {
  useImperativeHandle(ref, () => ({
    insertSymbol(symbol: string) {
      const currentContent = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      const neweditorState = EditorState.forceSelection(
        editorState,
        selectionState
      );

      const newcontentState = Modifier.replaceText(
        currentContent,
        selectionState,
        symbol
      );

      const finalEditorState = EditorState.push(
        neweditorState,
        newcontentState,
        'insert-characters'
      );

      setEditorState(finalEditorState);
    }
  }));

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [plugins, InlineToolbar] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugins({
      theme: { buttonStyles, toolbarStyles }
    });
    return [[inlineToolbarPlugin], inlineToolbarPlugin.InlineToolbar];
  }, []);

  useEffect(() => {
    let editorState;
    if (!source) {
      editorState = EditorState.createEmpty();
    } else {
      editorState = htmlToDraftBlocks(source);
    }
    setEditorState(editorState);
  }, [source]);

  const editor = useRef<DefaultEditor>(null);

  const onChangeHandler = (editorState: EditorState): void => {
    setEditorState(editorState);
    if (onChange) {
      const html = draftBlockToHtml(editorState);
      onChange(draftToHtml(html as any));
    }
  };

  const onBlur = (e: React.SyntheticEvent) => {
    if (handleSaveChange) {
      const html = draftBlockToHtml(editorState);
      handleSaveChange(e, html);
    }
  };

  const handleBeforeInput = () => {
    if (maxLength) {
      const currentContentLength = getContentLength(editorState);
      const selectedTextLength = getLengthOfSelectedText(editorState);

      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return 'handled';
      }
    }
  };

  const handlePastedText = (pastedText: string) => {
    if (maxLength) {
      const currentContentLength = getContentLength(editorState);
      const selectedTextLength = getLengthOfSelectedText(editorState);

      if (
        currentContentLength + pastedText.length - selectedTextLength >
        maxLength
      ) {
        return 'handled';
      }
    }
  };

  return (
    <div
      className={editorStyles.editor}
      data-text-heading={headingLevel}
      data-empty={isRequired && !source}
      onBlur={onBlur}
      onClick={() => editor.current?.focus()}
      {...rest}
    >
      <Editor
        editorState={editorState}
        onChange={onChangeHandler}
        plugins={plugins}
        ref={editor}
        // superscript and subscript config
        customStyleMap={{
          SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
          SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
        }}
        placeholder={rest.placeholder ?? ''}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
      />
      <InlineToolbar>
        {(externalProps: any) => (
          <React.Fragment>
            <SubButton {...externalProps} />
            <SupButton {...externalProps} />
          </React.Fragment>
        )}
      </InlineToolbar>
    </div>
  );
};

export default forwardRef(RichTextToolbar);
