import { styled, Select as MuiSelect, SelectProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	paper: {
		borderRadius: 4,
		marginTop: 4,
		boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
		maxHeight: 300,
		width: 232,
	},
	selectIcon: {
		fontSize: 16
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		"& .MuiMenuItem-root": {
			fontWeight: 400,
			padding: '12px 16px',
			fontSize: 12,
			boxShadow: `inset 0px -1px 0px #D6DFEB`,
			whiteSpace: 'normal',
			wordWrap: 'break-word',
			lineHeight: 1.5,
		},
		"& .MuiMenuItem-root:hover": {
			borderRadius: 0,
		},
		"& .MuiMenuItem-root.Mui-focusVisible": {
			backgroundColor: 'transparent'
		},
		"& .MuiMenuItem-root.Mui-focusVisible:hover": {
			backgroundColor: '#E3F3FF'
		},
		/**
		 * styling for create new button
		 */
		"& .MuiMenuItem-root.create-new": {
			fontWeight: 700,
			color: '#118CFF',
			paddingTop: 16,
			paddingBottom: 16,
		}
	}
}));

export const InputSelect = styled(MuiSelect, {
	shouldForwardProp: (props) => props !== 'disableUnderline'
})<SelectProps>(({ theme }) => ({
	'& .MuiInputBase-root': {
		borderRadius: 6,
		'& fieldset': {
			borderColor: '#E0E6EB',
		},
		'&:hover fieldset': {
			borderColor: '#E0E6EB',
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiInputBase-input': {
		fontFamily: 'Inter',
		fontSize: 12,
		color: 'rgba(53, 60, 104, 1)',
		position: 'relative',
		backgroundColor: theme.palette.common.white,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:focus': {
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiSelect-select': {
		borderRadius: 6,
		minWidth: '7.35rem',
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #E0E6EB",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #E0E6EB",
	},
}));