import Text from '../../atoms/Text';
import Logo from '../../atoms/Logo';
import images from '../../../assets';
import Icon from '../../atoms/Icon';
import { MenuItem } from '@mui/material';
import { StyledMenu, StyledMenuList } from './styles';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import BoxCircle from '../../atoms/Circle';

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { userPhoto } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  return (
    <div>
        <div
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <BoxCircle size={32}>
                <Logo src={userPhoto ?? images.avatar} width={32} />
            </BoxCircle>
            {open ? <Icon icon="chevronDown" size={12}/> : <Icon icon="chevronUp" size={12}/>}
        </div>
        <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
                style: {
                    marginLeft: 5,
                    padding: 4,
                },
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
                    mt: 1.5,
                },
            }}
        >
            <StyledMenuList
                id="profile-menu"
                aria-labelledby="profile-button"
            >
                <MenuItem onClick={handleClose}>
                    <Icon icon="user" />
                    <Text variant='body2'>Profile</Text>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Icon icon="times" />
                    <Text variant='body2'>Logout</Text>
                </MenuItem>
            </StyledMenuList>
        </StyledMenu>
    </div>
  );
}