import * as React from "react";
import { SVGProps } from "react";

export const LogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 21.5H5a2 2 0 01-2-2v-14a2 2 0 012-2h4M16 17.5l5-5-5-5M21 12.5H9"
    ></path>
  </svg>
);

