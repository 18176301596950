import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface PropsI {
  expanded: boolean;
  style?: any;
}
const ExpandIcon = (props: PropsI) => {
  const { expanded } = props;
  const style = props.style ?? {
    margin: '5px',
  };
  return (
    expanded ? <ExpandMoreIcon style={style} /> : <ExpandLessIcon style={style} />
  );
};

export default ExpandIcon;
