import { DetailedCellError, ExpectedValueOfTypeError, HyperFormula } from "hyperformula";

const FORMULA_LICENSE_KEY = 'gpl-v3';
const options = {
  licenseKey: FORMULA_LICENSE_KEY
};

const hf = HyperFormula.buildEmpty(options);
type ID = number | undefined;

const addSheet = (name: string): ID => {
  const sheet = hf.addSheet(name);
  const id = hf.getSheetId(sheet);

  return id;
};

const getSheetId = (sheetName: string): ID => {
  return hf.getSheetId(sheetName);
};

const doesSheetExist = (sheetName: string): boolean => {
  return hf.doesSheetExist(sheetName);
};

const validate = (expr: string) => {
  return hf.validateFormula(expr);
};

const calculate = (id: ID, expr: string) => {
  if (id == undefined) {
    console.error('[ERROR] FormulaHandler', 'Sheet has no ID');
    return expr;
  }

  const result = hf.calculateFormula(expr, id);
  if (typeof result == 'number' || typeof result == 'string') return result;

  if (result instanceof DetailedCellError) {
    console.error('[ERROR] FormulaHandler', result.message, result);
    return result.value;
  }
};

const setSheet = (id: ID, values: any) => {
  if (id == undefined) {
    console.error('[ERROR] FormulaHandler', 'Sheet has no ID');
    return;
  }

  hf.setSheetContent(id, values);
};

const getCellFormula = (addr: any) => {
  return hf.getCellFormula(addr);
};

const copy = (start: any, end: any) => {
  hf.copy({ start, end });
};

const paste = (dest: any) => {
  const changes = hf.paste(dest);
  return changes;
};

const setCellContent = (id: any, col: number, row: number, value: any) => {
  hf.setCellContents({ col, row, sheet: id }, [[value]]);
};

const FormulaHandler = {
  addSheet,
  calculate,
  copy,
  getCellFormula,
  doesSheetExist,
  getSheetId,
  paste,
  setCellContent,
  setSheet,
  validate
};

export default FormulaHandler;
