import AccountSetting from "../pages/accountSetting/AccountSettings";
import AtomicPage from "../pages/atomic";
import BusinessDrives from "../pages/businessDrives";
import { CompposerCreate } from "../pages/composer/create.page";
import LoggerPage from "../pages/loggerRefactor";
import PrintView from "../pages/printView";
import SubscriptionPage from "../pages/subscriptionPage/SubscriptionPage";
import accountManager from "../pages/accountManager/accountManager";
import billingDetails from "../pages/billingDetails/billingDetails";
import community from "../pages/calculations/community";
import dashboard from "../pages/dashboard/";
import enterprise from "../pages/calculations/enterprise";
import helpPage from "../pages/calculations/faqPage";
import inputFile from "../pages/inputFile/inputFile";
import login from "../pages/login2";
import myCalculations from "../pages/calculations/myCalculations";
import report from "../pages/report/report";
import AcePublic from "../pages/askAce/AcePublic";
import AceEnterprise from "../pages/askAce/AceEnterprise";
import AceTable from "../pages/askAce/AceTable";

const privateRoute = [
  { path: "/dashboard", component: dashboard },
  { path: "/calculations/my-calculation/:folderId?", component: myCalculations },
  { path: "/calculations/community/:folderId?", component: community },
  { path: "/calculations/enterprise", component: enterprise },
  { path: "/calculations/accountManager", component: accountManager },
  { path: "/calculations/billingDetails/:id", component: billingDetails },
  { path: "/input-file/:calculationId", component: inputFile },
  { path: "/report/:calculationId", component: report },
  { path: "/composer/:id/:folderId", component: CompposerCreate },
  // start of Logger Refactoring
  // { path: "/logger/:id", component: Logger },
  // { path: "/logger/:id/:inputFileId", component: Logger },
  { path: "/logger/:calculationId/:inputFileId?", component: LoggerPage },
  // end of Logger Refactoring
  { path: "/formula", component: login },
  { path: "/print-view/:calculationId/:inputFileId/:id", component: PrintView },

  { path: "/atomic-design", component: AtomicPage },
  { path: "/ace/helpPage", component: helpPage },
  { path: "/calculations/accountSettings", component: AccountSetting },
  { path: "/calculations/subscriptionPage", component: SubscriptionPage },
  { path: "/calculations/businessDrives/:folderId?", component: BusinessDrives },
  { path: "/ace/public", component: AcePublic },
  { path: "/ace/enterprise", component: AceEnterprise },
  { path: "/ace/citation/:id?", component: AceTable },

];

const routes = [...privateRoute];
export default routes;
