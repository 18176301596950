import api from "./api";

const URL = 'folders';

// TODO: define structure data for the entity
type Folder = any;

export const FOLDER_ITEM_TYPE = {
  FOLDER: 'FOLDER'
};

const FolderService = {
  view: async (id: string, type: string, isActive: boolean): Promise<Folder> => {
    const response = await api.get(`${URL}/${id}/view`, { params: { type, isActive } });
    return response.data.data;
  }
};

export default FolderService;
