import styled from 'styled-components';

type ContainerFolderStyledI = {
  isInputFile?: boolean;
};

type FolderStyledI = {
  active?: boolean;
} & ContainerFolderStyledI;

export const ContainerModalStyled = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '22.5rem',
  backgroundColor: 'white',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '24',
  height: '23.8rem',
  display: 'flex',
  borderRadius: '8px',
  outline: 'none'
});

export const ContainerTitleStyled = styled.div({
  padding: '1rem',
  flexDirection: 'row',
  justifyContent: 'normal',
  display: 'flex',
  p: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    flex: 1,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  '&:hover svg': {
    cursor: 'pointer',
  },
});

export const ContainerButtonStyled = styled.div({
  borderTop: '1px solid #E0E6EB',
  height: '2.3rem',
  padding: '1rem',
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
  button: {
    height: "2rem",
    width: "47%",
    borderRadius: "4px",
    padding: "0px, 0.87rem, 0px, 0.87rem",
    fontSize: 12,
    fontFamily: "Inter",
    fontWeight: "bold",
  },
  "& button:nth-child(1)": {
    backgroundColor: "#F0F2F5",
  },
});

export const ContainerFolderStyled = styled.div<ContainerFolderStyledI>((props: ContainerFolderStyledI) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  padding: props.isInputFile ? '.25rem .5rem' : '1rem'
}));


export const FolderStyled= styled.div<FolderStyledI>((props: FolderStyledI) => ({
  borderRadius: props.isInputFile ? '4px' : '0px',
  padding: props.isInputFile ? '.5rem .325rem' : '0px',
  marginBottom: props.isInputFile ? '.25rem' : '1rem',
  flexDirection: 'row',
  display: 'flex',
  cursor: 'pointer',
  div : {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px'
  },
  backgroundColor: props.active ? "#E3F3FF": ""
}));

export const LoadingWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1
});
