import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { BreadCrumbs } from '../../../atoms/breadCrumbs';
import images from '../../../../assets';
import { getFolders } from '../../../../store/actions/folderActions';
import { ReduxState } from '../../../../store';
import { LoadingSpinner } from '../../../atoms/loading';
import {
  ContainerButtonStyled,
  ContainerFolderStyled,
  FolderStyled
} from './elements';

interface PropsI {
  type: string;
  onSubmit: (e: any) => Promise<any>;
  handleClose: () => void;
}

interface StateReduxI {
  data: Record<string, unknown>[];
  isLoading: boolean;
}

interface LocationI {
  id: string;
  name: string;
}

export default function Explorer({ type, onSubmit, handleClose }: PropsI) {
  const dispatch = useDispatch();
  const { data, isLoading }: StateReduxI = useSelector(
    (state: ReduxState) => state.folders,
    shallowEqual
  );
  const { personalWorkspaceId, enterpriseWorkspaceId } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );
  const parentName = type === 'myCalculation' ? 'My Calculation' : 'Enterprise';
  const parentId =
    type === 'myCalculation' ? personalWorkspaceId : enterpriseWorkspaceId;
  const [location, setLocation] = useState<LocationI>({
    id: parentId,
    name: parentName
  });
  const [historyLocation, setHistoryLocation] = useState<LocationI[]>([
    { id: parentId, name: parentName }
  ]);

  const handleClickItem = (e: any) => {
    const tempLocation = {
      id: e.id,
      name: e.title
    };
    setHistoryLocation((prev: LocationI[]) => {
      return [...prev, tempLocation];
    });
    setLocation(tempLocation);
  };

  const handleOnclickBreadCrumb = () => {
    if (historyLocation.length !== 1) {
      setLocation(historyLocation[historyLocation.length - 2]);
      const copyHistory = historyLocation.slice();
      copyHistory.splice(-1);
      setHistoryLocation(copyHistory);
    }
  };

  const handleOnSubmit = () => {
    onSubmit({
      id: location.id,
      name: location.name
    });
  };

  useEffect(() => {
    dispatch(getFolders(location.id));
  }, [dispatch, location]);

  return (
    <>
      <BreadCrumbs
        locations={[location]}
        isFromModal={true}
        onClick={handleOnclickBreadCrumb}
      />
      {isLoading && <LoadingSpinner />}
      <>
        <ContainerFolderStyled>
          {!isLoading &&
            data.map((e: any) => {
              return (
                <FolderStyled key={e.id} onClick={() => handleClickItem(e)}>
                  <img src={images.calcFolder} width="25px" alt="img" />
                  <div>
                    <p>{e.title}</p>
                  </div>
                </FolderStyled>
              );
            })}
        </ContainerFolderStyled>
        <ContainerButtonStyled>
          <Button variant="outlined" onClick={handleClose}>
            CANCEL
          </Button>
          <Button variant="contained" onClick={handleOnSubmit}>
            SELECT
          </Button>
        </ContainerButtonStyled>
      </>
    </>
  );
}
