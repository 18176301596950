import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="4" fill="white" />
    <path
      d="M6.5 9H8.16667H21.5"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.666 9.00033V7.33366C10.666 6.89163 10.8416 6.46771 11.1542 6.15515C11.4667 5.84259 11.8907 5.66699 12.3327 5.66699H15.666C16.108 5.66699 16.532 5.84259 16.8445 6.15515C17.1571 6.46771 17.3327 6.89163 17.3327 7.33366V9.00033M19.8327 9.00033V20.667C19.8327 21.109 19.6571 21.5329 19.3445 21.8455C19.032 22.1581 18.608 22.3337 18.166 22.3337H9.83268C9.39065 22.3337 8.96673 22.1581 8.65417 21.8455C8.34161 21.5329 8.16602 21.109 8.16602 20.667V9.00033H19.8327Z"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.334 13.167V18.167"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.666 13.167V18.167"
      stroke="#52667A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgArrowUp;
