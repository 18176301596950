import { styled } from "@mui/material";

export interface CirclePropsI extends HTMLDivElement {
    size: number,
}

export const Container = styled("div", {
    shouldForwardProp: (props) => props !== "size"
})<CirclePropsI>(({ size }) => ({
    width: size,
    height: size,
    borderRadius: '50%',
    overflow: "hidden",
    margin: "0 .25rem"
}));