import { LatexItemWrapper, LatexUnitFont, LatextUnitDiv } from '../element';
import {
  formulaConvert,
  formulaConvertNew,
  valueConvert,
  valueConvertNew
} from '../../../../utils/helpersFunction';

import Latex from '../../latex';
import LatexVar from '../../latex/latexVar';
import Shimmer from '../../../atoms/Shimmer';
import parser from 'html-react-parser';
import { NotationEngine } from '../../../../utils/notation-parser';
import { mapGlyphToString } from '../../../../utils/notation-parser/glyph-mapper';

interface LatexItemProps {
  properties: any;
  line: number;
  loggerValues: any;
  columnId: string;
  isCompleted: boolean;
  multiLine: boolean;
  addLatexRef: (payload: any) => void;
  containIf?: boolean;
  isPrintView?: boolean;
  isRefactor?: boolean;
}

export const LatexItem = ({
  properties,
  line,
  loggerValues,
  isCompleted,
  multiLine,
  addLatexRef,
  columnId,
  containIf,
  isPrintView,
  isRefactor
}: LatexItemProps) => {
  const notationEngine = new NotationEngine(mapGlyphToString);
  const { formula, formulaWithNotations, notation, variable, unit } =
    properties;
  const currentNotation = notationEngine.toLatex(notation);

  return (
    <LatexItemWrapper
      multiLine={multiLine}
      ref={(el: any) => addLatexRef({ element: el, columnId: columnId })}
    >
      {containIf && !isCompleted ? (
        <Shimmer type="text" size="m" />
      ) : (
        <>
          <LatexVar formula={currentNotation + '='} />
          {line === 1 ? (
            <Latex formula={formulaWithNotations} />
          ) : line === 2 ? (
            <Latex
              formula={
                isPrintView || isRefactor
                  ? formulaConvertNew(loggerValues, formula)
                  : formulaConvert(loggerValues, formula)
              }
            />
          ) : (
            <LatexVar
              formula={
                isPrintView || isRefactor
                  ? valueConvertNew(loggerValues, notation).toString()
                  : valueConvert(loggerValues, variable).toString()
              }
            />
          )}
          {line === 3 && unit && (
            <LatextUnitDiv id="latex">
              <LatexUnitFont>{parser(unit)}</LatexUnitFont>
            </LatextUnitDiv>
          )}
        </>
      )}
    </LatexItemWrapper>
  );
};
