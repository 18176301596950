import { styled } from "@mui/material";

import { VMCardTextStylePropsI } from "./types";

export const VMCardWrapper = styled('button')<any>( ({ theme, currentFocus, expandTableCellEditing }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '10px 12px',
  border: 'none',
  borderRadius: '6px',
  backgroundColor: '#F0F2F5',
  userSelect: 'none',
  cursor: (currentFocus?.includes('formula') || expandTableCellEditing) ? 'pointer' : 'default',
  transition: 'box-shadow 1s',
  ...((currentFocus?.includes('formula') || expandTableCellEditing) && 
    {
      "&:hover": {
        backgroundColor: '#E3F3FF',
      },
      "&:active": {
        boxShadow: '0 0 10px #6495ED',
        transitionDuration: '0.05s',
      },
    }
  )
}));

export const VMCardSection = styled('div')( ({ theme }) => ({
  zIndex: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

export const VMCardText = styled('p')<VMCardTextStylePropsI>(( { theme, position } ) => ({
  width: position === 'left' ? '100px' : position === 'right' ? '37px' : position === 'foot' ? '100%' : position === 'rightIcon' ? '17px' : '',
  wordWrap: 'break-word',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: (position === 'left' || position === 'foot') ? 400 : position === 'right' ? 800 : 0,
  fontSize: '12px',
  textAlign: 'left',
  letterSpacing: '0.4px',
  color: position === 'foot' ? '#9A9DB3' : '',
}));

export const VMTitleWrapper = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  userSelect: 'none',
}));

export const VMTitleText = styled('p')( ({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '12px',
  textAlign: 'left',
  letterSpacing: '0.4px',
  color: theme.palette.common.white,
}));

export const VMTitleLink = styled('p')(({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 800,
  textAlign: 'left',
  letterSpacing: '0.4px',
  color: '#0071CD',
  cursor: 'pointer',
}));