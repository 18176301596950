
import { CalculationContent } from '../../components/organisms/hoc/elements';
import { aceNav } from '../../components/organisms/hoc/aceNav';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AceServices from '../../service/AceService';
import Loading from '../../components/atoms/loading';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import TypographyComponent from '../../components/atoms/Typography';
import { ChatDataT } from '../../store/reducers/aceReducers';
import { tableRowStyle } from './styles';
import Text from '../../components/atoms/Text';

const AceTable = () => {
  const location: Record<string, any> = useLocation();

  const id = location.pathname.split('/').reverse()[0];
  const [dataSource, setDataSource] = useState<ChatDataT | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchDatasource = async (id: string) => {
    try {
      const service = new AceServices();
      setIsLoading(true);
      const response = await service.getCitationByHistory(id)
      setDataSource(response.data);
      setIsLoading(false);
    } catch (error: any) {

    }
  }
  useEffect(() => {
    fetchDatasource(id);
  }, []);

  const columnWidth = 100 / (dataSource?.sqlCitations?.length ? dataSource?.sqlCitations[0]?.columnNames.length : 1) + "%"

  return (
    <CalculationContent>
      {isLoading && <Loading />}
      <div id="wrapper">
        <Text variant='h2' style={{ padding: "16px 0px" }}>
          HCSS
        </Text>
        {dataSource?.sqlCitations?.length &&
          <section id="table-wrapper">
            <Table size="medium">
              <TableHead>
                <TableRow sx={tableRowStyle}>
                  {dataSource?.sqlCitations[0]?.columnNames.map((label: any, i: number) => {
                    const headerLength = dataSource?.sqlCitations?.length && dataSource?.sqlCitations[0]?.columnNames?.length;
                    return (
                      <TableCell
                        style={{
                          width: columnWidth,
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'auto',
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          borderRight: "1px solid #BFC9D9",
                          ...(i === 0 && { borderLeft: "1px solid #BFC9D9", borderTopLeftRadius: 8 }),
                          ...(i + 1 === headerLength && { borderTopRightRadius: 8 }),
                          borderTop: "1px solid #BFC9D9",
                          borderBottom: "1px solid #BFC9D9"
                        }}
                        key={`table-header-${i}`}
                      >
                        <TypographyComponent variant="HEADING_5">
                          {label}
                        </TypographyComponent>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.sqlCitations[0].data.length > 0 &&
                  dataSource.sqlCitations[0].data?.map((row: any, i: number) => {
                    const rowLength = dataSource.sqlCitations?.length && dataSource.sqlCitations[0].data.length;
                    return (<TableRow
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                      key={`table-row-${i}`}
                    >
                      {row.map((cell: string, j: number) => {
                        const columnLength = row.length;
                        return (
                          <TableCell
                            style={{
                              width: columnWidth,
                              display: 'flex',
                              alignItems: 'center',
                              overflow: 'auto',
                              overflowWrap: 'anywhere',
                              borderRight: "1px solid #BFC9D9",
                              paddingBottom: 8,
                              paddingTop: 8,
                              ...(j === 0 && { borderLeft: "1px solid #BFC9D9" }),
                              ...(j === 0 && i + 1 === rowLength && { borderBottomLeftRadius: 8 }),
                              ...(j + 1 === columnLength && i + 1 === rowLength && { borderBottomRightRadius: 8 }),
                            }}
                            key={j}
                          >
                            <TypographyComponent>
                              {cell}
                            </TypographyComponent>
                          </TableCell>
                        )
                      })}
                    </TableRow>)
                  }
                  )}
              </TableBody>
            </Table>
          </section>}
      </div>
    </CalculationContent>
  )
}

export default aceNav(AceTable)