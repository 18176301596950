import { RESET_ACTION, SET_ACTION, SET_ACTIVE_PAGE, SET_DATA_CALCULATION_INFO, SET_DATA_INPUT_FILE, SET_DATA_LIST_INPUT_FILES, SET_DATA_LOADING, loggerActionType, TOGGLE_PRINT_BUTTON } from "../types/logger.types";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { AnyAction } from "redux";
import { FormChangeDecimalI } from "../../pages/loggerRefactor/logger.types";
import InputFileService from "../../service/InputFileService";
import { ReduxState } from "..";
import { modifyDecimalInputFileFields, validateDecimalChanges } from "../../pages/loggerRefactor/utils";
import { localDatetime } from "../../utils/dateConvert";

export const storeInputFile = (payload: any) => {
    return {
        type: SET_DATA_INPUT_FILE,
        payload
    };
};

export const storeCalculationInfo = (payload: any) => {
    return {
        type: SET_DATA_CALCULATION_INFO,
        payload
    };
};

export const storeListInputFiles = (payload: Array<any>) => {
    return {
        type: SET_DATA_LIST_INPUT_FILES,
        payload
    };
};

export const storeLoading = (payload: boolean) => {
    return {
        type: SET_DATA_LOADING,
        payload
    };
};


export const saveInputFile = (calculationTitle?: string | null, inputFileId?: string | null): ThunkAction<Promise<void>, ReduxState, Record<string, unknown>, AnyAction> => {
    return async (dispatch: ThunkDispatch<any, any, any>, getState): Promise<void> => {
        dispatch(storeLoading(true));
        const { inputFile, calculationInfo } = getState().loggerReducer;
        const {fields} = inputFile;
        const newFields = await validateDecimalChanges(fields);
        const response = await InputFileService.saveInputFile({
            id: inputFileId ?? calculationInfo.selectedInputFile.id,
            calculationId: calculationInfo.calculationID,
            title: calculationTitle ?? calculationInfo.selectedInputFile.title,
            pages: newFields,
        });
        dispatch(storeCalculationInfo({
            ...calculationInfo,
            lastSaved: localDatetime(response?.updatedAt)
        }));
        dispatch(storeLoading(false));
        dispatch(setActions("SAVE"));
        return response;
    };
};

export const saveAsInputFile = (calculationTitle?: string): ThunkAction<Promise<void>, ReduxState, Record<string, unknown>, AnyAction> => {
    return async (dispatch: ThunkDispatch<any, any, any>, getState): Promise<void> => {
        dispatch(storeLoading(true));
        const { inputFile, calculationInfo } = getState().loggerReducer;
        const payload = {
            id: calculationInfo.selectedInputFile.id,
            calculationId: calculationInfo.calculationID,
            title: calculationTitle,
            pages: inputFile.fields
        };
        const response = await InputFileService.saveAsInputFile(payload);
        dispatch(storeLoading(false));
        dispatch(setActions("SAVE"));
        return response;
    };
};

export const setActivePage = (payload: number) => {
    return {
        type: SET_ACTIVE_PAGE,
        payload
    };
};

export const setActions = (payload: loggerActionType | null) => {
    return {
        type: SET_ACTION,
        payload
    };
};

export const resetAction = () => {
    return {
        type: RESET_ACTION
    };
};

export const changeDecimalInputFile = (payload: FormChangeDecimalI): ThunkAction<Promise<void>, ReduxState, Record<string, unknown>, AnyAction> => {
    return async (dispatch: ThunkDispatch<any, any, any>, getState): Promise<void> => {
        const { inputFile } = getState().loggerReducer;
        const response = await modifyDecimalInputFileFields(payload, inputFile.fields);

        setTimeout(() => {
            dispatch(storeInputFile({
                ...inputFile,
                fields: response
            }));
        }, 1000);
    };
};

export const saveInputFileAndPrint = (
    calculationTitle?: string
): ThunkAction<Promise<void>, ReduxState, Record<string, unknown>, AnyAction> => {
    return async (dispatch: ThunkDispatch<any, any, any>, getState): Promise<void> => {
        return new Promise<void>(async (resolved, reject) => {
            dispatch(storeLoading(true));
            const { inputFile, calculationInfo } = getState().loggerReducer;
            await InputFileService.saveInputFile({
                id: calculationInfo.selectedInputFile.id,
                calculationId: calculationInfo.calculationID,
                title: calculationTitle ?? calculationInfo.selectedInputFile.title,
                pages: inputFile.fields
            })
                .then((data) => {
                    dispatch(storeLoading(false));
                    dispatch(setActions("SAVE"));
                    resolved(data);
                })
                .catch((error) => reject(error));
        });
    };
};

export const togglePrintButton = (payload: boolean) => {
    return {
        type: TOGGLE_PRINT_BUTTON,
        payload
    };
};
