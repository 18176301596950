import { InputFieldFormula } from '../../styles';
import React, { useEffect, useRef, useState } from 'react';

import Icon from '../../../../components/atoms/Icon';
import { IconMore } from './styles';
import { InputField } from '../../../../components/molecules/FormattedInput/styles';
import InputToolbar from './InputToolbar';
import { UseFormRegister } from 'react-hook-form';
import { countDecimal } from '../../utils';

interface FormattedFormulaPropsI
  extends React.HTMLAttributes<HTMLInputElement> {
  value: any;
  onToolbarMenuSelected: (e: any, item?: any, data?: any) => void;
  properties: any;
  placeholder: string;
  register: UseFormRegister<any>;
}

export const Formula = (props: FormattedFormulaPropsI) => {
  const { value, properties, onToolbarMenuSelected, placeholder } = props;
  const [anchor, setAnchor] = useState<null | HTMLInputElement>(null);
  const toolbarRef = useRef<HTMLInputElement>(null);

  const [decimals, setDecimals] = useState(0);
  const handleClickIcon = () => {
    const { current: el } = toolbarRef;
    setAnchor(el);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  useEffect(() => {
    value &&
      setDecimals(properties?.format?.value?.decimal ?? 3);
  }, [value]);

  const handleClickMenu = (e: any, item: string, data?: any) => {
    switch (item) {
      case 'increment':
        const newValue = decimals + 1;
        setDecimals(newValue);
        onToolbarMenuSelected(e, 'decimal', newValue);
        break;
      case 'decrement':
        if (decimals !== 0) {
          const newValue = decimals - 1;
          setDecimals(newValue);
          onToolbarMenuSelected(e, 'decimal', newValue);
        }
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      type: 'default',
      title: 'decrement',
      onClick: (e: React.SyntheticEvent, item?: any) =>
        handleClickMenu(e, item),
      icon: 'decreaseDecimal'
    },
    {
      type: 'default',
      title: 'increment',
      onClick: (e: React.SyntheticEvent, item?: any) =>
        handleClickMenu(e, item),
      icon: 'increaseDecimal'
    }
  ];

  return (
    <React.Fragment>
      <InputField>
        {
          <InputFieldFormula
            value={value === 0 ? 0 : value ? value : placeholder}
            type="text"
            displayType="input"
            decimalScale={decimals}
            decimalSeparator="."
            fixedDecimalScale={true}
            placeholder={placeholder}
            disabled={true}
          />
        }
        <IconMore onClick={handleClickIcon} ref={toolbarRef}>
          <Icon icon="moreVertical" />
        </IconMore>
      </InputField>
      <InputToolbar
        anchor={anchor}
        onCloseMenu={handleCloseMenu}
        actions={actions}
      />
    </React.Fragment>
  );
};
