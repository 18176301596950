import store from '../store';

export function getRenameValue() {
  const stateOne = store.getState().calInfo.calName;
  const stateTwo = store.getState().calInfo.calId;
  return {
    title: stateOne,
    id: stateTwo
  };
}

export function getPayloadModal() {
  const modalPayload = store.getState();
  return {
    payload: modalPayload,
  };
}


export function getRenameInputFile() {
  const stateOne = store.getState().logger?.currentInputFile?.title;
  const stateTwo = store.getState().logger?.currentInputFile?.id;
  return {
    title: stateOne,
    id: stateTwo
  };
}

export function getDataValue() {
  const state = store.getState().encal.data;
  return state;
}

export function getInputFileValue() {
  const state = store.getState().inputFileManager.data;
  return state;
}

export function getDataBodyOfCalculationComposer() {
  const state = store.getState().composer.dataBodyOfCalculation;
  return state;
}

export function getDataPropertiesComposer() {
  const state = store.getState().composer.dataProperties;
  return state;
}

export function getDataHyperFormulaComposer() {
  const state = store.getState().composer.dataHyperFormula;
  return state;
}

export function getDataVariableManagerMenuComposer() {
  const state = store.getState().composer.dataVariableManagerMenu;
  return state;
}

export function getActivePageComposer() {
  const state = store.getState().composer.activePage;
  return state;
}

export function getLoggerValue() {
  const state = store.getState().logger.loggerFormValues.length > 0 ? store.getState().logger.loggerFormValues : store.getState().logger.currentInputFile.pages;
  return state;
}

export function getSymbol() {
  const state = store.getState().composer.dataSymbol;
  return state;
}

export function getCurrentReport() {
  const state = store.getState().reportManager.currentReport;
  return state;
}

export function getInputFileValueLogger() {
  const state = store.getState().loggerReducer.inputFile;
  return state;
}

export function getUtilSymbol() {
  const state = store.getState().utils.symbols;
  return state;
}

export function getLatestChatHistory() {
  const state = (store.getState().aceReducer as any).chatHistories[(store.getState().aceReducer as any).chatHistories.length - 1];
  return state;
}

export function getChatSessions() {
  const state = (store.getState().aceReducer as any).chatSessions;
  return state;
}

export function getSessionId() {
  const state = (store.getState().aceReducer as any).sessionId;
  return state;
}

export function getAllChatHistories() {
  const state = (store.getState().aceReducer as any).chatHistories;
  return state;
}
