import styled from "styled-components";
import { withStyles } from "@mui/styles";

export const EmptyResultsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: 20,
  overflow: "auto",
  alignItems: "center",
  justifyContent: "center"
});