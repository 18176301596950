import * as React from "react";
import { SVGProps } from "react";

const SvgFormula = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.828 19.675c-.521.536-1.274.803-2.26.803H1.2l.196-1.933h2.868l.717-7.062H2.22l.218-2.021h2.737l.435-4.172c.087-.855.391-1.55.913-2.086.521-.536 1.274-.804 2.26-.804h2.52l-.217 1.934H8.088l-.543 5.128h3.28l-.195 2.02H7.35l-.61 6.128c-.086.84-.39 1.529-.912 2.065ZM17.39 13.46l-2.717 4.107h-2.52l3.976-5.715-3.868-5.584H15l2.543 3.933h.065l2.607-3.933h2.52l-3.867 5.563 3.933 5.736h-2.738l-2.607-4.107h-.065Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFormula;
