import { styled } from "@mui/material";

export const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '28px',
  border: '1px dashed white',
  borderRadius: 4,
  flex: '1 1 0%',
  backgroundColor: theme.palette.common.white,
  minHeight: 854,
  wordBreak: 'break-word'
}));

export const ReferenceWrapper = styled('div')({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
  marginTop: "4px",
  // padding : "80px 0",

  "& > .reference": {
    width: "58px"
  },
  "& > .referenceValue": {
    width: "304"
  },
});