import { Box } from '@mui/material';
import Input from '../../components/atoms/Input';
import Text from '../../components/atoms/Text';
import { downloadFile } from '../../utils/fileHandler';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { useDispatch } from 'react-redux';
import { LAST_UPDATE_DATE, LAST_UPDATE_TITLE, TERM_OF_SERVICE_FILENAME, TERM_OF_SERVICE_SUCCESS_DOWNLOAD, TERM_OF_SERVICE_TITLE, TEXT_CHANGE, TEXT_DOWNLOAD } from '../../utils/constant';

function PersonalInformation(props: any) {
  const {
    errors,
    handleEditInformation,
    password,
    email,
    userProfile,
    isMobile
  } = props;

  const dispatch = useDispatch();

  const downloadLicenseAgreement = () => {
    try {
      downloadFile(userProfile.softwareLicenseAgreementUrl, TERM_OF_SERVICE_FILENAME);
    } finally {
      dispatch(toastSetupActionPopUp('INFO', TERM_OF_SERVICE_SUCCESS_DOWNLOAD));
    }
  };

  return (
    <>
      <Box style={{ paddingTop: 16, paddingBottom: 32 }}>
        <Text variant='h4' style={{ paddingBottom: 16 }}>Personal Information</Text>
        <Text variant='body3' style={{ paddingTop: 32, paddingBottom: 4 }}>Email Address</Text>
        <Box style={{ display: "flex" }}>
          <Input
            sx={{ width: 400, height: 50 }}
            value={email ?? userProfile.email}
            disabled={true}
            type="text"
            variant="outlined"
            size="small"
            error={!!errors?.email}
            helperText={errors?.email && errors?.email?.message}
          />
        </Box>
        <Text variant='body3' style={{ paddingTop: 32, paddingBottom: 4 }}>Password</Text>
        <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row' }}>
          <Input
            sx={{ width: isMobile ? '100%' : 400 }}
            value={password}
            disabled={true}
            type="text"
            variant="outlined"
            size="small"
            error={!!errors?.password}
            helperText={errors?.password && errors?.password?.message}
          />
          <Text variant='h5' style={{ paddingLeft: isMobile ? 0 : 16, paddingTop: 8, cursor: "pointer" }} color="#118CFF"
            onClick={() => handleEditInformation()}>
            {TEXT_CHANGE}
          </Text>
        </Box>
      </Box>

      <Box style={{ display: "flex", flexDirection: isMobile ? 'column' : 'row' }}>
        <Box style={{ width: isMobile ? '100%' : 400, height: 18, display: "flex", justifyContent: 'space-between' }}>
          <Text variant='h6'>{TERM_OF_SERVICE_TITLE}</Text>
          <Text variant='body3' color="#5C738A">{LAST_UPDATE_TITLE} {LAST_UPDATE_DATE}</Text>
        </Box>
        <Text variant='h5' style={{ paddingLeft: isMobile ? 0 : 16, marginTop: isMobile ? 8 : -2, cursor: "pointer" }} color="#118CFF" onClick={downloadLicenseAgreement}>
          {TEXT_DOWNLOAD}
        </Text>
      </Box>
    </>
  );
}

export default PersonalInformation;