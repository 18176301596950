import { styled } from "@mui/material";

interface Props {
  position?: string;
  color?: any;
  disabled?: any;
  nonClickable?: boolean;
}

export const Wrapper = styled("div")(({ theme }) => ({
  border: '1px solid #E0E6EB',
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.5rem',
  backgroundColor: '#F9F9F9',
  borderRadius: '4px',
  gap: '1.1rem',
  '& > div:nth-of-type(n)': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    p: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
  paddingLeft: '1rem',
  paddingRight: '1rem'
}));

export const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: 'center',
  justifyContent: 'center',
});

export const Content = styled("div")({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const WrapperCircleator = styled('div')<Props>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  height: '38px',
  gap: '18px',
  userSelect: 'none',
}));

export const BackCircleator = styled('img')({
  width: '102px',
  height: '18px',
  cursor: 'pointer',
});

export const ParentContentCircleator = styled('div')<Props>((props) => ({
  display: 'flex',
  gap: '2px',
}));

export const ContentCircleator = styled('div')<Props>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  height: '34px',
  backgroundColor: props.disabled ? "#F0F2F5" : props.color,
  borderRadius: props.position === 'LEFT' ? '20px 0px 0px 20px' : props.position === 'RIGHT' ? '0px 20px 20px 0px' : '0px 0px 0px 0px',
  cursor: props.disabled ? 'not-allowed' : props.nonClickable? 'auto' : 'pointer',
}));

export const ContentCircleatorText = styled('p')<Props>((props) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  color: props.color,
}));