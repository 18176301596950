import styled from 'styled-components';

export const CalculationsGrid = styled.div({
  height: '3.5rem',
  backgroundColor: '#F0F2F5',
  display: 'flex',
  padding: '0 1.6rem',
  justifyContent: 'space-between',
  p: {
    lineHeight: 24,
    letterSpacing: '0.44px',
    color: '#353C68',
  },
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.3rem',
    '& > div': {
      gap: 5,
    },
  },
  '& > div:nth-child(2)': {
    gap: 5,
  },
  svg: {
    cursor: 'pointer',
  },
});
