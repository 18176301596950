import { DATATOASTTYPETYPEPOPUP } from './toastType';

function reducerModifierDataToastTypePopUp(data: any) {
  return { type: DATATOASTTYPETYPEPOPUP, data };
}

// EXAMPLE
// dispatch(toastSetupActionPopUp("SUCCESS // ERROR // INFO", "TEXT HERE"))

export function toastSetupActionPopUp(
  type: string,
  payload?: any,
  position = {
    horizontal: 'center',
    vertical: 'top',
  }
) {
  return (dispatch: any, getState: any) => {
    if (type === 'CLOSE') {
      dispatch(
        reducerModifierDataToastTypePopUp({
          isOpen: false,
          type: null,
          label: null,
          position: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
      );
    } else {
      dispatch(
        reducerModifierDataToastTypePopUp({
          isOpen: true,
          type: type,
          label: payload,
          position: position,
        })
      );
    }
  };
}
