import * as React from "react";

const Refresh = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_3334_171)">
      <path d="M4.66669 6.66667V10.6667H8.66669" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.3333 17.3333V13.3333H15.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.66 9.99999C17.3219 9.04452 16.7473 8.19026 15.9897 7.51694C15.2322 6.84362 14.3164 6.37317 13.3278 6.1495C12.3393 5.92583 11.3102 5.95622 10.3366 6.23784C9.36294 6.51947 8.47652 7.04314 7.76002 7.75999L4.66669 10.6667M19.3334 13.3333L16.24 16.24C15.5235 16.9569 14.6371 17.4805 13.6635 17.7621C12.6899 18.0438 11.6608 18.0742 10.6722 17.8505C9.68366 17.6268 8.76789 17.1564 8.01033 16.483C7.25278 15.8097 6.67813 14.9555 6.34002 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_3334_171">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default Refresh;
