import React from 'react';
import Icon from '../../../atoms/Icon';

import TextArea from './TextArea';
import { FormulaIconBox, FormulaWrapper } from './styles';

import { FormulaPropsI } from './type';

export const Formula = ({
  register,
  id,
  defaultValue,
  error = false,
  onFocus,
  onBlur
}: FormulaPropsI) => {
  return (
    <FormulaWrapper error={error}>
      <FormulaIconBox error={error}>
        <Icon icon="formula" size={12} color={error ? '#FFF' : '#52667A'} />
      </FormulaIconBox>
      <TextArea
        register={register}
        id={id}
        defaultValue={defaultValue}
        placeholder="Formula"
        error={error}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </FormulaWrapper>
  );
};
