import sidebarFormula from "./sidebar/formula.png";
import sidebarImage from "./sidebar/image.png";
import sidebarText from "./sidebar/text.png";
import sidebarTable from "./sidebar/table.png";
import sidebarDivider from "./sidebar/divider.png";
import sidebarFormulaWhite from "./sidebar/formulaWhite.png";
import sidebarImageWhite from "./sidebar/imageWhite.png";
import sidebarTextWhite from "./sidebar/textWhite.png";
import sidebarTableWhite from "./sidebar/tableWhite.png";
import sidebarDividerWhite from "./sidebar/dividerWhite.png";
import edit from "./sidebar/edit.png";
import printerIcon from "./sidebar/printer.png";
import loginBg from "./login/bg-login.png";
import backgroundLogin from "./login/background-login.png";
import iconRekavaWhite from "./images/global/icon-rekava-white.svg";
import loginOut from "./login/log-out.png";
import loginUser from "./login/user.png";
import landingIc from "./landingPage/landing-ic.png";
import landingUser from "./landingPage/user.png";
import landingFile from "./landingPage/file.png";
import landingPlus from "./landingPage/plus.png";
import footerIc from "./footer/ic-footer.png";
import calcFolder from "./calculations/folder.png";
import calcSub from "./calculations/subtract.png";
import calcSm from "./calculations/folder-sm.png";
import calKal from "./calculations/kal.png";
import calFile from "./calculations/file.png";
import calKalSm from "./calculations/kal-sm.png";
import calFileSm from "./calculations/file-sm.png";
import editIcon from "./calculations/edit.png";
import avatar from "./login/placeholder.jpeg";
import fileXls from "./calculations/fileXls.png";
import fileXlsx from "./calculations/fileXlsx.png";
import fileXlsm from "./calculations/fileXlsm.png";
import noImgComposer from "./composer/noImgComposer.png";
import rekavaIconComposer from "./composer/rekavaIconComposer.png";
import arrowDownComposer from "./composer/arrowDownComposer.png";
import arrowRightComposer from "./composer/arrowRightComposer.png";
import radioOnComposer from "./composer/radioOnComposer.png";
import radioOffComposer from "./composer/radioOffComposer.png";
import checkBoxCheckComposer from "./composer/checkBoxCheckComposer.png";
import checkBoxUncheckComposer from "./composer/checkBoxUncheckComposer.png";
import imageTopComposer from "./composer/imageTopComposer.png";
import emptyStateComposer from "./composer/emptyStateComposer.png";
import helpIconComposer from "./composer/helpIconComposer.png";
import closeIconComposer from "./composer/closeIconComposer.png";
import linkIconComposerLock from "./composer/linkIconComposerLock.png";
import linkIconComposerUnlock from "./composer/linkIconComposerUnlock.png";
import editHeaderIconComposer from "./composer/editHeaderIconComposer.png";
import pageDownIconComposerDark from "./composer/pageDownIconComposerDark.png";
import pageDownIconComposerLight from "./composer/pageDownIconComposerLight.png";
import addPageIconComposer from "./composer/addPageIconComposer.png";
import xButtonComposer from "./composer/xButtonComposer.png";
import searchComposer from "./composer/searchComposer.png";
import maximizeTableComposer from "./composer/maximizeTableComposer.png";
import checkButtonTableComposer from "./composer/checkButtonTableComposer.png";
import fxButtonTableComposer from "./composer/fxButtonTableComposer.png";
import xButtonTableComposer from "./composer/xButtonTableComposer.png";
import rekavaIconNameComposer from "./composer/rekavaIconNameComposer.png";
import fileIconInputFile from "./logger/file.svg";
import mathFx from "./logger/math-fx.png";
import backBigPathDarkModal from "./modal/backBigPathDarkModal.png";
import backBigPathLightModal from "./modal/backBigPathLightModal.png";
import xButtonModal from "./modal/xButtonModal.png";
import selectModal from "./modal/selectModal.png";
import folderIconModal from "./modal/folderIconModal.png";
import iconCalendar from "./printView/icon-calendar.svg";
import icCalendar from "./printView/calendar.png";
import rekavaLogo from "./images/global/logo-main.svg";
import logoIcon from "./images/global/rekava-icon-navy.svg";
import logoText from "./images/global/rekava-text-navy.svg";
import noImage from "./logger/noImage.jpg";
import filePlus from "./landingPage/file-plus.png";
import filePlusHover from "./landingPage/file-plus-hover.png";
import folder from "./landingPage/folder.png";
import folderHover from "./landingPage/folder-hover.png";
import userHover from "./landingPage/user-hover.png";
import playButtonComposer from "./composer/playButtonComposer.png";
import editIconLogger from "./logger/editIconLogger.png";
import arrowIcon from "./printView/arrow.png";
import logoGreyscale from "./images/global/rekava-logo-grey.svg";
import textLogoGrey from "./images/global/text-logo-grey.svg";
import iconUpload from "./images/global/icon-upload.svg";
import errorBackground from "./images/global/error-bg.svg";
import helpCircleIcon from "./landingPage/helpCircleIcon.png";
import tableIconFile from "./calculations/file.svg";
import tableIconFolder from "./calculations/folder.svg";
import back from "./breadcrumbs/back.png";
import mathFxBold from "./logger/math-fxBold.png";
import arrowUp from "./logger/ArrowUp.png";
import arrowDown from "./logger/ArrowDown.png";
import rekavaIconPrint from "./printView/rekavaIconPrint.png";
import iconCommunity from "./calculations/community.svg";
import diamondIcon from './sidebar/Diamond.svg';
import settingIcon from './sidebar/Settings.svg';
import eyePasswordIcon from './modal/eyePasswordIcon.png';
import subscriptionSuccess from './modal/subscriptionSuccess.png';
import freeSubscription from './modal/freeSubscription.png';
import spotMobile from './images/global/spot-mobile.svg';
import helpCircle from './modal/helpCircle.png';
import thick from './modal/thick.png';
import star from './calculations/star.png';


const images = {
  sidebarFormula,
  sidebarImage,
  sidebarText,
  sidebarTable,
  sidebarDivider,
  sidebarFormulaWhite,
  sidebarImageWhite,
  sidebarTextWhite,
  sidebarTableWhite,
  sidebarDividerWhite,
  loginBg,
  loginOut,
  loginUser,
  landingIc,
  landingUser,
  landingFile,
  landingPlus,
  footerIc,
  calcFolder,
  calcSub,
  calcSm,
  calKal,
  calFile,
  calKalSm,
  calFileSm,
  editIcon,
  avatar,
  fileXls,
  fileXlsx,
  fileXlsm,
  noImgComposer,
  rekavaIconComposer,
  arrowDownComposer,
  arrowRightComposer,
  radioOnComposer,
  radioOffComposer,
  checkBoxCheckComposer,
  checkBoxUncheckComposer,
  imageTopComposer,
  emptyStateComposer,
  helpIconComposer,
  closeIconComposer,
  linkIconComposerLock,
  linkIconComposerUnlock,
  editHeaderIconComposer,
  pageDownIconComposerDark,
  pageDownIconComposerLight,
  addPageIconComposer,
  xButtonComposer,
  searchComposer,
  maximizeTableComposer,
  checkButtonTableComposer,
  fxButtonTableComposer,
  xButtonTableComposer,
  fileIconInputFile,
  mathFx,
  backBigPathDarkModal,
  backBigPathLightModal,
  xButtonModal,
  selectModal,
  folderIconModal,
  iconCalendar,
  icCalendar,
  rekavaLogo,
  logoIcon,
  logoText,
  errorBackground,
  noImage,
  edit,
  printerIcon,
  filePlus,
  filePlusHover,
  folder,
  folderHover,
  userHover,
  playButtonComposer,
  editIconLogger,
  arrowIcon,
  logoGreyscale,
  rekavaIconNameComposer,
  textLogoGrey,
  iconUpload,
  helpCircleIcon,
  tableIconFile,
  tableIconFolder,
  back,
  mathFxBold,
  arrowUp,
  arrowDown,
  rekavaIconPrint,
  backgroundLogin,
  iconRekavaWhite,
  iconCommunity,
  diamondIcon,
  settingIcon,
  eyePasswordIcon,
  subscriptionSuccess,
  freeSubscription,
  spotMobile,
  helpCircle,
  thick,
  star
};

export default images;
