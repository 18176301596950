import { Menu, styled } from "@mui/material";

export const HorizontalList = styled(Menu)(({theme}) => ({
    '.MuiList-root': {
        display: 'flex',
        padding: `${theme.spacing(1)}`,
        gap: 8,
    },

    '.MuiMenuItem-root': {
        padding: `${theme.spacing(0.75)}`,
        border: `1px solid ${theme.customText.icon}`,
        borderRadius: 4,
        height: 32,
    }
}));