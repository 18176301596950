import React from 'react';
import Text from '../../../components/atoms/Text';
import { Box, Container } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';
export default function HeaderUserDasboard() {
    const history = useHistory();
    const location =  window.location.pathname.split("/")[2];

    const handleAccount = () => {
      history.push('/calculations/accountSettings');
    };
  
    const handleSubscription = () => {
      history.push('/calculations/subscriptionPage');
    };
  
  return (
    <>
    <Box style={{width : '100%', height:44}}>
        <Box style={{display : "flex"}}>
            <Box style={{padding : '12px 24px', cursor: "pointer"}} onClick ={(e)=> handleAccount()}>
              <Text color={location === 'accountSettings' ? 'primary' : ''}>Account</Text>
            </Box>
            <Box style={{padding : '12px 24px', cursor: "pointer"}} onClick ={(e)=> handleSubscription()}>
              <Text color={location === 'subscriptionPage'?'primary' : ''}>Subscription</Text>
            </Box>
        </Box>
    </Box>
</>
  );
}
