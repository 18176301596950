import { Menu, MenuList, MenuListProps, MenuProps, styled } from "@mui/material";

export const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
    '& .MuiList-root': {
        padding: 0,
        minWidth: '10rem',
        maxWidth: 'calc(100% - 32px)',
    },
    '& .MuiMenu-List': {
        padding: 0
    },
}));

export const StyledMenuList = styled(MenuList)<MenuListProps>(({ theme }) => ({
    padding: 0,
    minWidth: '10rem',
    maxWidth: 'calc(100% - 32px)',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #fff',
        top: -10,
        right: 8,
        zIndex: 1,
    }
}));

export const InlineMenuItem = styled("div")({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});