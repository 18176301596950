export class FartexEngine {
  state = {
    errorDetector: false,
  };

  exec(formula: string, functionList: any, tableName: any) {
    const result = (formula: any) => {
      const tempCst: any = [];
      let vanishingFormula: any = formula;

      if (vanishingFormula[0] === '=') {
        vanishingFormula = vanishingFormula.slice(1);        
        tempCst.push('=');
      } else {
        this.state.errorDetector = true;
      }

      let tempLoop: any = '';
      for (let i = 0; i < vanishingFormula.length; i++) {
        if (vanishingFormula[i] !== "(" && vanishingFormula[vanishingFormula.length - 1] == ")") {
          tempLoop = tempLoop + vanishingFormula[i];
        } else {
          break;
        }
      }

      for (let i = 0; i < functionList.length; i++) {
        if (functionList[i] === tempLoop) {
          vanishingFormula = vanishingFormula.slice(tempLoop.length);
          tempCst.push(tempLoop);
        }
      }

      if (tempCst.length < 2) {
        this.state.errorDetector = true;
      }

      if (vanishingFormula[0] === "(" && vanishingFormula[vanishingFormula.length - 1] === ")") {
        vanishingFormula = vanishingFormula.slice(1);
        vanishingFormula = vanishingFormula.slice(0, vanishingFormula.length - 1);
        tempCst.push(vanishingFormula);
      } else {
        this.state.errorDetector = true;
      }

      if (this.state.errorDetector === true || tableName === undefined) {
        return { type: 'error' };
      } else {
        switch (tempCst[1]) {
          case 'VLOOKUP':
            return { type: 'FUNCTION_VLOOKUP' };
          case 'HLOOKUP':
            return { type: 'FUNCTION_HLOOKUP' };
          case 'INDEX':
            return { type: 'FUNCTION_INDEX' };
          case 'IF':
            if (vanishingFormula.includes('IF')) {
              return { type: 'nested error' };
            } else {
              const pattern = /(IF\([\w+-:\/*()\'\"]+[><=!]{1,2}[\w+-:\/*()\'\"]+,[\w+-:\/*()\'\"><=!]+,[\w+-:\/*()\'\"><=!]+\))|(IF\([\w+-:\/*()\'\"]+,[\w+-:\/*()\'\"><=!]+,[\w+-:\/*()\'\"><=!]+\))/gi;
              const trimmedFormula = formula.replaceAll(' ', '');
              const valid = pattern.test(trimmedFormula);
          
              if (!valid) {
                return { type: 'error' };
              }
          
              const args = formula.substring(4, formula.length - 1)
                .replaceAll('\'', '')
                .replaceAll('\"', '')
                .split(',');
              const trueCase = `as ${args[0]}, ${args[1]}`;
              const falseCase = args[2];
          
              return { type: 'FUNCTION_IF', trueCase, falseCase };          
            }
        }
      }

    };

    return result(formula);
    
  }

  convertSubSup(formula: string) {
    const arraySubSup = [
      '<sup>',
      '<sub>',
      '</sup>',
      '</sub>',
    ];

    for (let i = 0; i < arraySubSup.length; i++) {
      if (formula.includes(arraySubSup[i])) {
        switch (arraySubSup[i]) {
          case '<sup>':
            formula = formula.replaceAll('<sup>', '^{');
          break;
          case '<sub>':
            formula = formula.replaceAll('<sub>', '_{');
          break;
          case '</sup>':
            formula = formula.replaceAll('</sup>', '}');
          break;
          case '</sub>':
            formula = formula.replaceAll('</sub>', '}');
          break;
        }
      }
    }

    return formula[0] === '=' ? formula.substring(1) : formula;

  }
}
