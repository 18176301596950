import images from '../../assets';
import Logo from '../../components/atoms/Logo';
import Text from '../../components/atoms/Text';
import { Container, Content, Description, Header, MainContent, Title, Wrapper } from './elements';
import ErrorActionButton from './ErrorActionButton';

export interface IErrorProps {
  status?: string;
  message: string;
  errorMessage?: string;
  actions: Array<any>;
};

const ErrorTemplate = (props: IErrorProps) => {
  const { status, message, errorMessage, actions } = props;
  return (
    <Wrapper>
      <Container>
        <Header>
          <Logo src={images.rekavaLogo} width={120} />
        </Header>
        <Content>
          <MainContent>
            {status && <Title>{status}</Title>}
            <Description status={status ? true : false}>{message}</Description>
            {errorMessage && <Text variant="body1" color="#143055" sx={{ marginBottom: '21px' }}>{errorMessage}</Text>}
            <ErrorActionButton actions={actions} />
          </MainContent>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ErrorTemplate;
