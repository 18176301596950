import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import * as DOMPurify from 'dompurify';

export const htmlToDraftBlocks = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
};

export const draftBlockToHtml = (blocks: EditorState) => {
    const editorState = convertToRaw(blocks.getCurrentContent());
    const htmlContent = draftToHtml(editorState);
    /**
     * draftToHtml will always wrap content in <p> tag
     * I use sanitize html to only allow sub & sub html
     */
    const cleanHtml = DOMPurify.sanitize(htmlContent, {
        ALLOWED_TAGS: ['sup', 'sub']
    });

    return cleanHtml;
};

export const getContentLength = (editorState: EditorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    return currentContentLength;
};

export const getLengthOfSelectedText = (editorState: EditorState) => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
        const currentContent = editorState.getCurrentContent();
        const startKey = currentSelection.getStartKey();
        const endKey = currentSelection.getEndKey();
        const startBlock = currentContent.getBlockForKey(startKey);
        const isStartAndEndBlockAreTheSame = startKey === endKey;
        const startBlockTextLength = startBlock.getLength();
        const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
        const endSelectedTextLength = currentSelection.getEndOffset();
        const keyAfterEnd = currentContent.getKeyAfter(endKey);
        if (isStartAndEndBlockAreTheSame) {
            length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
        } else {
            let currentKey = startKey;

            while (currentKey && currentKey !== keyAfterEnd) {
                if (currentKey === startKey) {
                    length += startSelectedTextLength + 1;
                } else if (currentKey === endKey) {
                    length += endSelectedTextLength;
                } else {
                    length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                }

                currentKey = currentContent.getKeyAfter(currentKey);
            };
        }
    }

    return length;
};