import { styled, Typography, TypographyProps } from "@mui/material";

// this component will change TypographyComponent
// to easily parse Mui Typography variant as variant without mapping 

const StyledText = styled(Typography)<TypographyProps>(({ theme }) => ({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
}));

export default function Text(props: TypographyProps) {
    return <StyledText {...props} />;
}