import { AppDispatch } from '../../../store';
import {
    contentNull,
    reducerModifierDataFormModalActionPopUp
} from '../../../store/appState/popUp/formModal/formModalAction';
import { initialStateI } from '../../../store/appState/popUp/formModal/formModalReducer';

export function LoggerFormModalAction(type: any, payload?: any) {
    return async (dispatch: AppDispatch, state: initialStateI) => {
        switch (type) {
            case "CLOSE":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: false,
                        type: null,
                        content: contentNull,
                    })
                );
                break;
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "To Start Logging Your Calculation, Select Existing or Create a New Input File",
                            headAlign: "center",
                            body: [
                                {
                                    type: "SELECT",
                                    name: "inputFile",
                                    placeholder: "Select file or create new",
                                    value: null,
                                    createNew: true,
                                    contentValue: payload?.inputFileList
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL",
                                    label: "CANCEL",
                                },
                                {
                                    type: "SUBMIT",
                                    label: "START",
                                },
                            ],
                            storage: payload,
                        },
                    })
                );
                break;

            case "LOAD_INPUT_FILE":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "Load",
                            headAlign: "center",
                            body: [
                                {
                                    type: "SELECT",
                                    name: "inputFile",
                                    placeholder: "Input File",
                                    value: null,
                                    createNew: false,
                                    // custom content for load input file
                                    loadNew: true,
                                    staticTitle: payload.calculationTitle,
                                    contentValue: payload?.inputFileList
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL_OUTLINED",
                                    label: "CANCEL",
                                },
                                {
                                    type: "SUBMIT",
                                    label: "SELECT",
                                },
                            ],
                            storage: payload,
                        },
                    })
                );
                break;

            case "REFACTOR_SAVE_AS_INPUT_FILE":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "Save As",
                            headAlign: "center",
                            body: [
                                {
                                    type: "TEXT",
                                    name: "inputFile",
                                    placeholder: "Input File Name",
                                    limit: 20,
                                    defaultValue: "",
                                    isDisabled: false,
                                    dataTestid: "inputCalculationName",
                                    inputPlaceHolder: true,
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL_OUTLINED",
                                    label: "CANCEL",
                                },
                                {
                                    type: "SUBMIT",
                                    label: "SAVE",
                                },
                            ],
                            storage: payload,
                        },
                    })
                );
                break;

            case "REFACTOR_EXIT_TO_INPUT_FILE_MANAGER":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "Exit to Input File Manager",
                            headAlign: "center",
                            body: [
                                {
                                    type: "LABEL",
                                    label: "Save changes before exit?",
                                    labelAlign: "center",
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL",
                                    label: "Exit Without Save",
                                    followup: true,
                                },
                                {
                                    type: "SUBMIT",
                                    label: "Save & Exit",
                                },
                            ],
                            storage: payload,
                        },
                    })
                );
                break;

            case "MODAL_CLEAR_ALL_INPUTS":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "Clear All Inputs?",
                            headAlign: "center",
                            body: [
                                {
                                    type: "LABEL",
                                    label: "Are you sure you want to clear all pages?",
                                    labelAlign: "center",
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL_OUTLINED",
                                    label: "Cancel",
                                },
                                {
                                    type: "SUBMIT",
                                    label: "Clear"
                                }
                            ],
                            storage: payload
                        }
                    })
                );
                break;

            case "MODAL_CLEAR_INPUTS_AT_PAGE":
                dispatch(
                    reducerModifierDataFormModalActionPopUp({
                        isOpen: true,
                        type: type,
                        content: {
                            head: "Clear All Inputs At Page?",
                            headAlign: "center",
                            body: [
                                {
                                    type: "LABEL",
                                    label: "Are you sure you want to clear all inputs at this active page?",
                                    labelAlign: "center",
                                },
                            ],
                            foot: [
                                {
                                    type: "CANCEL",
                                    label: "Cancel",
                                },
                                {
                                    type: "SUBMIT",
                                    label: "Clear"
                                }
                            ]
                        }
                    })
                );
                break;

            default:
                return state;
        }
    };
}