import {
  ExploreOutlined,
  FolderOutlined,
  HelpOutline,
  PersonOutline
} from '@mui/icons-material';
import {
  FolderTreeItem,
  TreeLogo
} from '../../../store/appState/sidebar/reducer';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import {
  getFolderTreeView,
  updateTreeState
} from '../../../store/appState/sidebar/action';
import { useState } from 'react';

import { ExpandIcon } from '../infoPanel/components';
import FolderTree from './FolderTree';
import Icon from '../../atoms/Icon';
import { SidebarContentWrapper } from './elements';
import Text from '../../atoms/Text';
import { useDispatch } from 'react-redux';

interface IProps {
  isMinimize: boolean;
  isSelected: boolean;
  selectedItem: string;
  item: FolderTreeItem;
  indentLevel: number;
  isLoading?: boolean;
  onClick: any;
  dataTestid: string;
  isMobile?: boolean;
}
const TreeNode = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    indentLevel,
    isSelected,
    isMinimize,
    item,
    onClick,
    selectedItem,
    dataTestid,
    isLoading,
    isMobile
  } = props;

  let expandIconStyle = {
    fontSize: '16px',
    marginRight: '4px'
  };

  const [expand, setExpand] = useState<boolean>(item.isExpanded);
  const nonExpandableSidebar: boolean = item.id === 'businessDrives' || item.id === 'account' || item.id === 'helpPage';
  const [showChildren, setShowChildren] = useState<boolean>(expand && item.isExpanded && item.children);

  if (!expand) {
    expandIconStyle = Object.assign(expandIconStyle, {
      transform: 'rotate(90deg)'
    });
  }

  const toggleExpand = (flag: boolean) => {
    sessionStorage.setItem('expanded_tree_node', item.id);
    if (!item.children && !isLoading) {
      Promise.all([
        dispatch(getFolderTreeView(item.id)),
      ]).then(() => {
        dispatch(updateTreeState({ isExpanded: flag }, item.id));
        setExpand(flag);
        setShowChildren(flag);
      }).catch((err) => {
        console.error(err);
      });
    } else {
      dispatch(updateTreeState({ isExpanded: flag }, item.id));
      setExpand(flag);
      setShowChildren(flag);
    }
  };

  const hasNoChild = item.children != null && item.children.length == 0;

  const getIcon = (type: string | undefined) => {
    switch (type) {
      case TreeLogo.PEOPLE:
        return <PersonOutline />;
      case TreeLogo.ACEPUBLIC:
      case TreeLogo.ACEENTERPRISE:
      case TreeLogo.ASKACE:
        return <Icon icon="talkTaylor" color={isMobile && item.id === selectedItem ? '#189EFF' : 'currentcolor'} />;
      case TreeLogo.COMMUNITY:
        return <ExploreOutlined />;
      case TreeLogo.HELP:
        return <HelpOutline />;
      case TreeLogo.TEAMDRIVES:
        return <Icon icon="driveOutlined" color="currentColor" />;
      case TreeLogo.FOLDER:
      default:
        return <FolderOutlined />;
    }
  };

  const getIndentation = (indentLevel: number) => {
    const indentation = [];
    if (indentLevel > 0) {
      indentation.push(<div style={{ width: '6px' }} />);
    }
    for (let i = 0; i < indentLevel; i++) {
      indentation.push(<div style={{ width: '16px' }} />);
    }
    return indentation;
  };
  const getItem = (indentLevel: number) => {
    return (
      <ListItem
        disablePadding
        selected={isSelected}
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          pointerEvents: isLoading ? 'none' : 'auto',
          // right: isMobile ? 24 : undefined
        }}
        // onClick={onClick}
        data-testid={dataTestid}
      >
        <ListItemButton
          // onClick={() => (isLoading || nonExpandableSidebar? null : toggleExpand(!expand))}
          style={{ pointerEvents: isLoading ? 'none' : 'auto' }}
          disableGutters={isMobile ? true : false}
        >
          {getIndentation(indentLevel)}
          {!isMinimize && (
            <div style={{ width: '16px', marginRight: '6px' }}>
              {!hasNoChild && !nonExpandableSidebar && (
                <a
                  onClick={() => toggleExpand(!expand)}
                >
                  <ExpandIcon expanded={expand} style={expandIconStyle} />
                </a>
              )}
            </div>
          )}
          <div>
            <ListItemIcon onClick={onClick} style={{ transform: 'translateX(-20px)' }} >{getIcon(item.icon)} </ListItemIcon>
          </div>
          {!isMinimize ? (
            <ListItemText
              onClick={onClick}
              sx={{ marginLeft: isMobile ? -2 : undefined }}
              primary={
                <SidebarContentWrapper>
                  <Typography
                    color={isMobile && item.id === selectedItem ? '#189EFF' : 'currentcolor'}
                    style={{
                      width:
                        item.title === 'My Calculations' ||
                          item.title === 'Community Calculations'
                          ? '200px'
                          : '175px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      transform: 'translateX(-20px)'
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.id === 'askAce' && (<div id="newFeature">
                    <Text variant="h6" color='#fff'>⭐️ NEW</Text>
                  </div>)}
                </SidebarContentWrapper>
              }
            />
          ) : (
            ''
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      {isMinimize ? (
        <Tooltip title={item.title}>{getItem(indentLevel)}</Tooltip>
      ) : (
        getItem(indentLevel)
      )}

      {showChildren && !isMinimize ? (
        <div
          style={{
            background: '#fff',
            width: '100%'
          }}
        >
          <FolderTree
            children={item.children}
            isMinimize={isMinimize}
            selectedItem={selectedItem}
            indentLevel={indentLevel + 1}
            isLoading={isLoading}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TreeNode;
