import React from 'react'
import { ChatWrapper } from '../styles'
import Icon from '../../../components/atoms/Icon'
import './loading.css'

export const AceLoading = () => {
  return (
    <ChatWrapper key={`chat_index_isWriting`} isAce={true}>
      <div id="chatbox">
        <div id="taylor">
          <Icon icon="talkTaylor" color="#118CFF" size={16} cursor="auto"/>
        </div>
        <div id="message">
          <div className="loading">Ace is writing</div>
        </div>
      </div>
    </ChatWrapper>
  )
}
