export const ISDRAGGINGTYPECOMPOSER = 'IS_DRAGGING_TYPE_COMPOSER';
export const ISEDITINGTYPECOMPOSER = 'IS_EDITING_TYPE_COMPOSER';
export const ACTIVEPAGETYPECOMPOSER = 'ACTIVE_PAGE_TYPE_COMPOSER';
export const DATAVARIABLEMANAGERMENUTYPECOMPOSER = 'DATA_VARIABLE_MANAGER_MENU_TYPE_COMPOSER';
export const DATAHYPERFORMULATYPECOMPOSER = 'DATA_HYPER_FORMULA_TYPE_COMPOSER';
export const DATAPAGESTYPECOMPOSER = 'DATA_PAGES_TYPE_COMPOSE';
export const DATAPROPERTIESTYPECOMPOSER = 'DATA_PROPERTIES_TYPE_COMPOSER';
export const DATACHEADOFCALCULATIONTYPECOMPOSER = 'DATA_HEAD_OF_CALCULATION_TYPE_COMPOSER';
export const DATABODYOFCALCULATIONTYPECOMPOSER = 'DATA_BODY_OF_CALCULATION_TYPE_COMPOSER';
export const DATACURRENCYCOMPOSER = 'DATA_CURRENCY_COMPOSER';
export const DATASYMBOLCOMPOSER = 'DATA_SYMBOL_COMPOSER';
export const EXPORTEVENTCOMPOSER = 'EXPORT_EVENT_COMPOSER';
export const ISFETCHINGDATACOMPOSER = 'IF_FETCHING_DATA_COMPOSER';

export interface IsDraggingTypeComposer {
  type: typeof ISDRAGGINGTYPECOMPOSER;
  data: boolean;
}

export interface IsEditingTypeComposer {
  type: typeof ISEDITINGTYPECOMPOSER;
  data: boolean;
}

export interface ActivePageTypeComposer {
  type: typeof ACTIVEPAGETYPECOMPOSER;
  data: number;
}

export interface DataVariableManagerMenuTypeComposer {
  type: typeof DATAVARIABLEMANAGERMENUTYPECOMPOSER;
  data: any;
}

export interface DataHyperFormulaTypeComposer {
  type: typeof DATAHYPERFORMULATYPECOMPOSER;
  data: any;
}

export interface DataPagesTypeComposer {
  type: typeof DATAPAGESTYPECOMPOSER;
  data: any;
}

export interface DataPropertiesTypeComposer {
  type: typeof DATAPROPERTIESTYPECOMPOSER;
  data: any;
}

export interface DataHeadOfCalculationTypeComposer {
  type: typeof DATACHEADOFCALCULATIONTYPECOMPOSER;
  data: any;
}

export interface DataBodyOfCalculationTypeComposer {
  type: typeof DATABODYOFCALCULATIONTYPECOMPOSER;
  data: any;
}

export interface DataCurrencyComposer {
  type: typeof DATACURRENCYCOMPOSER;
  data: any;
}

export interface DataSymbolComposer {
  type: typeof DATASYMBOLCOMPOSER;
  data: {[key: string]: string | number}[]
}

export interface ExportEventComposer {
  type: typeof EXPORTEVENTCOMPOSER;
  data: any;
}

export interface IsFetchingDataComposer {
  type: typeof ISFETCHINGDATACOMPOSER;
  data: boolean;
}
