import { styled } from "@mui/material";

export const ListItems = styled('ul')({
    listStyle: 'none',
    padding: '0px !important',
    margin: '8px 0 .825rem !important'
});

export const Item = styled('li')(({ theme }) => ({
    fontSize: 12,
    lineHeight: 1.4,
    color: theme.customText.high
}));