import { createEvalVisitor, IVisitor } from './visitor';
import { generateToken, getLexer, getTokenDict } from './lexer';
import { FormulaParser } from './parser';
import { Lexer } from 'chevrotain';
import { SymbolType } from '../../service/SymbolService';
import { NotationEngine } from '../notation-parser';
import { mapGlyphToString } from '../notation-parser/glyph-mapper';

export type ReferenceResolver = (names: string[]) => Record<string, any>;
export type ReferenceResolverAsync = (
  names: string[]
) => Promise<Record<string, any>>;

export type SymbolDict = { [key in any]: string };

export class LatexEngine {

  private parser: FormulaParser;
  private evalVisitor: IVisitor;
  private lexer: Lexer;
  private notationEngine: NotationEngine;

  constructor(data: Array<SymbolType>) {
    const tokens = generateToken(data);
    const dict = getTokenDict(tokens);

    this.parser = new FormulaParser(dict);
    this.evalVisitor = createEvalVisitor(this.parser, dict);
    this.lexer = getLexer(tokens);

    this.notationEngine = new NotationEngine(mapGlyphToString);
  }

  state = {
    errorDetector: false,
  };

  exec(formula: string) {
    const cst = this.getCst(formula);

    const result = this.evalVisitor.visit(cst);

    if (this.state.errorDetector === true) {
      return 'error';
    } else {
      return result;
    }
  }

  private getCst(formula: string) {
    const lexRes = this.tokenize(formula);
    const cst = this.parse(lexRes);
    return cst;
  }

  private tokenize(formula: string) {
    const lexRes = this.lexer.tokenize(formula);
    let newTokens = [];

    if (lexRes.errors.length > 0) {
      this.state.errorDetector = true;
    }

    // modify token
    newTokens = this.convertLatex(lexRes.tokens);

    return {
      ...lexRes,
      tokens: newTokens
    };
  }

  private parse(lexRes: any) {
    this.parser.reset();
    this.parser.input = lexRes.tokens;
    const cst = this.parser.expression();

    if (!cst || this.parser.errors.length > 0) {
      this.state.errorDetector = true;
    }

    return cst;
  }

  private convertLatex(tokens: any) {
    const result = tokens.map((item: any) => ({
      ...item,
      image: item.tokenType.name === 'Identifier' ? (item.image.includes('-') ? '-' + this.notationEngine.toLatex(item.image) : this.notationEngine.toLatex(item.image)) : item.image
    }));

    return result;
  }
}
