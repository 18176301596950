import styled from "styled-components";

interface ButtonPropsI {
  isOwner: boolean;
}

export const Container = styled.div({
  flexDirection: 'column',
  display: 'flex'
});

export const InputWrapper = styled.div({
  marginTop: '4px',
  flexDirection: 'row',
  display: 'flex',
  paddingRight: "12px"
});

export const MemberWrapper = styled.div({
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '428px',
  overflowX: 'hidden',
  overflowY: 'auto'
});

export const ButtonWrapper = styled.div<ButtonPropsI>(({isOwner}) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '42px',
  columnGap: '16px',
  justifyContent: isOwner ? 'space-between' : 'flex-end'
}));

export const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '42px',
  columnGap: '10px',
  justifyContent: 'center',
  alignItems: 'center'
});



