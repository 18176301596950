import * as React from "react";
import { SVGProps } from "react";

const SvgWidth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.034 19.546 3 5h2.308l2.834 11.264h.135L11.224 5h2.287l2.948 11.271h.135L19.42 5h2.316l-4.041 14.546h-2.21L12.426 8.65h-.114L9.25 19.546H7.034Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWidth;
