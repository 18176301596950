import styled from "styled-components";

export const Body = styled.div({
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: 12,
    color: "#29333D",
    paddingHorizontal: 56,
    paddingTop: 8,
    paddingBottom: 18,
    breakInside: 'avoid'
});

export const BodyInput = styled.div({
    fontFamily: "SourceCodePro",
    fontSize: 12,
    color: "#29333D",
    paddingHorizontal: 56,
    paddingTop: 6,
    paddingBottom: 6,
    breakInside: 'avoid'
});

export const Section = styled.div({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    // justifyContent: "space-between"
    paddingVertical: 8,
    paddingLeft: 10,
});

export const Divider = styled.div(
    { height: 1, marginVertical: 16, backgroundColor: "#B3BFCC" }
);

