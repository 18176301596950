import * as reportAction from "../types/reportTypes";

import { localDatetimeTable } from "../../utils/dateConvert";

interface TableDataPropsI {
  headers: Array<string>;
  data: Array<Record<string, unknown>>;
}

interface actionReportPropsI {
  types: string;
  payload: any;
}

export interface ReportStateI {
  isLoading: boolean;
  data: Array<any>;
  tableData: TableDataPropsI;
  errorMessage: string;
  currentReport: any;
  pages: Array<any>;
  summary: Array<any>;
  actionReport: actionReportPropsI;
  primaryLogo: string | null;
  secondaryLogo: string | null;
  reportChanges: boolean;
  reportSaveFormat: any;
  loadingReport: boolean;
  reportName: string
  calculationInfo: any;
}

const initialState: ReportStateI = {
  isLoading: false,
  loadingReport: false,
  data: [],
  tableData: {
    headers: [],
    data: [],
  },
  errorMessage: "",
  currentReport: {},
  pages: [],
  summary: [],
  actionReport: {
    types: "",
    payload: {},
  },
  primaryLogo: null,
  secondaryLogo: null,
  reportChanges: false,
  reportSaveFormat: {
    pages: [],
    latex: false,
    summary: false,
    pdfFile: {},
    reportName: "",
  },
  reportName: "",
  calculationInfo: {
    calculationId: '',
    folderId: '',
    title: ''
  }
};

const reportReducer = (state: ReportStateI = initialState, action: any) => {
  switch (action.type) {
    case reportAction.FETCH_REPORT_BY_CALCULATION_ID:
      return {
        ...state,
        isLoading: true,
      };
    case reportAction.FETCH_REPORT_BY_CALCULATION_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // data: action.data
      };
    case reportAction.FETCH_REPORT_BY_CALCULATION_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case reportAction.FETCH_CALCULATION_REPORT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case reportAction.FETCH_CALCULATION_REPORT_LIST_SUCCESS:
      const payload = action.data;
      const { items } = payload;

      // generate table data
      const headers = ["Name", "Date Created", "Notes"];
      const content = items.map((item: any) => {
        return [item.title, localDatetimeTable(item.createdTime), item.notes];
      });
      const tableData = { headers, data: content };

      delete payload.items;

      return {
        ...state,
        isLoading: false,
        data: items,
        tableData,
        calculationInfo: payload
      };
    case reportAction.FETCH_CALCULATION_REPORT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case reportAction.CREATE_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case reportAction.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        currentReport: action.payload,
        isLoading: false,
        reportChanges: false,
        reportSaveFormat: {
          pages: action.payload.format.pages,
          latex: action.payload.format.latex,
          summary: action.payload.format.summary,
          pdfFile: {},
          reportName: action.payload.title,
        },
      };
    case reportAction.CREATE_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case reportAction.LOAD_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case reportAction.LOAD_REPORT_SUCCESS:
      return {
        ...state,
        currentReport: action.payload,
        isLoading: false,
        reportChanges: false,
        reportSaveFormat: {
          pages: action.payload.format.pages,
          latex: action.payload.format.latex,
          summary: action.payload.format.summary,
          pdfFile: {},
          reportName: action.payload.title,
        },
      };
    case reportAction.LOAD_REPORT_FAILED:
      return {
        ...state,
        errorMessage: action.error,
        isLoading: false,
      };
    case reportAction.RENAME_REPORT:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          title: action.payload,
        },
      };
    case reportAction.UPDATED_REPORT:
      return {
        ...state,
        currentReport: {
          ...state.currentReport,
          updatedAt: action.payload,
        },
      };
    case reportAction.FETCH_PAGES:
      return {
        ...state,
        pages: action.pages,
        primaryLogo: action.primaryLogo,
        secondaryLogo: action.secondaryLogo,
      };
    case reportAction.FETCH_SUMMARY_REPORT:
      return {
        ...state,
        summary: action.payload,
      };
    case reportAction.ACTION_REPORT:
      return {
        ...state,
        actionReport: {
          types: action.types,
          payload: action.payload,
        },
      };
    case reportAction.REPORT_CHANGES:
      return {
        ...state,
        reportChanges: action.payload,
      };
    case reportAction.REPORT_SAVE_FORMAT:
      return {
        ...state,
        reportSaveFormat: {
          pages: action.payload.pages,
          latex: action.payload.latex,
          summary: action.payload.summary,
          pdfFile: action.payload.pdfFile,
          reportName: action.payload.title,
        },
      };
    case reportAction.REPORT_LOADING:
      return {
        ...state,
        loadingReport: action.payload,
      };
    case reportAction.UPDATE_REPORT_NAME:
      return {
        ...state,
        reportName: action.payload
      };
    default:
      return state;
  }
};

export default reportReducer;
