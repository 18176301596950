import PQueue from 'p-queue';
import CalculationService from '../CalculationServices';
import Service from '../generic/generic';

export const queue = new PQueue({ concurrency: 1 });

export async function addQueueComposerComponent(serviceExtension: { [key: string]: any }, payload: { [key: string]: any }, extension?: any) {
  let backendResponse: any;
  const service = new Service('calculations');
  const { idCalculation, masterType, componentType, serviceType } = serviceExtension;

  await queue.add(async () => {
    switch (serviceType) {
      case 'add':
        backendResponse = await CalculationService.addNewComponent(idCalculation, masterType, payload);
        break;
      case 'delete':
        backendResponse = await CalculationService.deleteComponent(idCalculation, componentType ? `${masterType}/${componentType}` : masterType, payload);
        break;
      case 'duplicate':
        backendResponse = payload.length ?  await CalculationService.duplicateMultipleComponent(idCalculation, masterType, serviceType, payload) : await CalculationService.duplicateComponent(idCalculation, masterType, serviceType, payload);
        break;
      case 'move':
        backendResponse = await CalculationService.moveComponent(idCalculation, masterType, serviceType, payload);
        break;
      default:
        // update
        backendResponse = await CalculationService.updateComponent(idCalculation, masterType, componentType, payload);
        break;
    }

    if (extension && extension.asyncMemory) {
      for (const payload of extension.asyncMemory) {
        switch (payload.type) {
          case 'table':
            await service.putSubResource(idCalculation, masterType, 'table', payload);
            break;
          default:
            await service.putSubResource(idCalculation, masterType, 'formula', payload);
            break;
        }
      }
    };
  });

  return backendResponse;
}

export async function patchIsValidCalculation(calculationId: string, payload: { isComplete: boolean }) {
  await queue.onEmpty();
  const result = await queue.add(async () => {
    return await CalculationService.updateCalculationStatus(calculationId, payload);
  });
  return result;
}
