import { styled } from "@mui/material";

export const ToolbarContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
  padding: '10px 0',
  "& > #leftSection": {
    display: 'flex',
  },
  "& > #rightSection": {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
  },
});
