import React from "react";

import ExpandIcon from "./ExpandIcon";
import { HelperContent } from "../elements";
import StyledButton from "../../../atoms/Button";
import { Divider, useTheme } from "@mui/material";
import Text from "../../../atoms/Text";
import Icon from "../../../atoms/Icon";

interface PropsI {
  data: any;
  expanded: boolean;
  onToggle: () => void;
  loadHelperStep: (payload: any) => void;
  isDivider: boolean;
  type: string;
}

const InfoPanelHelperSection = ({
  data,
  expanded,
  onToggle,
  loadHelperStep,
  isDivider,
  type

}: PropsI) => {
  const theme = useTheme();
  return (
    <HelperContent>
      <div onClick={onToggle}>
        <Text variant="h5" color={type === 'tutorial'? theme.customText.high : "primary"}>{data.title}</Text>
        {type === 'tutorial' ? <ExpandIcon expanded={expanded} /> : <div style={{ paddingRight: 10 }}><Icon icon="arrowRight"  color={theme.palette.primary.main}/></div>}
      </div>
      {expanded && (
        <>
          <img src={data.imageUrl} />
          <div className="subContent">
            <div className="number">
              <h2>1 OF {data.total}</h2>
            </div>
            <StyledButton variant='contained' size='small' onClick={() => loadHelperStep(data.id)}>SHOW ME</StyledButton>
          </div>
        </>
      )}
      {isDivider && <Divider />}
    </HelperContent>
  );
};

export default InfoPanelHelperSection;
