import { SVGProps } from 'react';

const TaylofOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_384_315)">
      <path
        d="M6.34533 14.6667C6.01866 13.6017 5.52599 12.8073 4.86699 12.2843C3.87866 11.5 2.30833 11.9877 1.72833 11.1783C1.14833 10.369 2.13466 8.88101 2.48066 8.00301C2.82699 7.12501 1.15399 6.81468 1.34933 6.56534C1.47933 6.39901 2.32499 5.91968 3.88566 5.12668C4.32899 2.59801 5.96666 1.33334 8.79933 1.33334C13.048 1.33334 14.6667 4.93534 14.6667 7.22668C14.6667 9.51734 12.7067 11.9853 9.91466 12.5177C9.66499 12.881 10.025 13.5977 10.9947 14.6667"
        stroke="#118CFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_384_315">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TaylofOff;
