import { SymbolType } from "../../../service/SymbolService";
import * as actions from "./type";

export interface UtilStateI {
  symbols: Array<SymbolType>;
}

export const initialState: UtilStateI = {
  symbols: [],
};

const utilReducer = (state: UtilStateI = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_SYMBOLS_SUCCESS:
      return {
        ...state,
        symbols: action.items
      };

    default:
      return state;
  }
};

export default utilReducer;
