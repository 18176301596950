import { useState, MouseEvent, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { TableContentSection } from '../../pageElements/calculations';
import { ReduxState } from '../../store';
import { LoadingSpinner } from '../../components/atoms/loading';
import inputFileNav from '../../components/organisms/hoc/inputFileManager';
import {
  getInputFileById,
  getReportByCalcInfoId
} from '../../store/actions/inputFileAction';
import ContentTable from '../../components/molecules/ContentTable';
import { BreadCrumbs } from '../../components/atoms/breadCrumbs';
import { ChildWrapper } from '../../components/organisms/hoc/elements';
import {
  fetchCalculationReportList,
  putReportAfterDownload
} from '../../store/actions/reportAction';
import ReportInfoPanel from '../../components/organisms/ReportInfoPanel';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { downloadPdf, createFileFromUrl } from '../../utils/fileHandler';
import HelperButton from '../../components/molecules/HelperButton';
import { getBreadcrumb } from '../../store/breadCrumb/managerNavigation/action';
import { BREADCRUMB_PAGE_TYPE } from '../../store/breadCrumb/managerNavigation/reducer';
import { getUserId } from '../../utils/storage';
import TagManagerService from "../../service/tagManagerService";

const OUT_OF_RANGE_INDEX = -1;

const ReportList = () => {
  const dispatch = useDispatch();
  const { calculationId } = useParams<{ calculationId: string }>();
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
    const history = useHistory();
  // state
  const [selectedItemIndex, setSelectedItemIndex] =
    useState<number>(OUT_OF_RANGE_INDEX);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const pathName = location.state?.pathName;

  // selector
  const { report } = useSelector(
    (state: ReduxState) => state.inputFileManager,
    shallowEqual
  );

  const { breadcrumb } = useSelector(
    (state: ReduxState) => state.managerNavigation,
    shallowEqual
  );

  const { calculationInfo, data, tableData, isLoading, actionReport } =
    useSelector((state: ReduxState) => state.reportManager, shallowEqual);
  const tagManagerService = TagManagerService();

  // effect
  useEffect(() => {
    const unsubscribe = handleFetchResources(calculationId);
    return unsubscribe;
  }, [calculationId, report]);

  useEffect(() => {
    if (!calculationInfo.folderId) return;
    dispatch(
      getBreadcrumb(calculationInfo.folderId, BREADCRUMB_PAGE_TYPE.REPORT, {
        id: calculationInfo.calculationId,
        title: calculationInfo.title
      })
    );
  }, [calculationInfo]);

  const handleFetchResources = (calculationId: string) => {
    try {
      Promise.all([dispatch(fetchCalculationReportList(calculationId))]).then(
        _ => _
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    const unsubscribe = handleActionReport(actionReport);
    return unsubscribe;
  }, [actionReport.types]);

  const handleActionReport = (actionReport: any) => {
    if (actionReport.types === 'DELETE_REPORT_SUCCESS') {
      setSelectedItemIndex(OUT_OF_RANGE_INDEX);
      setSelectedItem(null);
    } else if (actionReport.types === 'RENAME_REPORT_SUCCESS') {
      setSelectedItem({ ...selectedItem, title: actionReport.payload?.title });
    }
  };

  // handler
  const handleSelectItem = (e: MouseEvent, itemIndex: number) => {
    const isUserReport = Boolean(getUserId() === data[itemIndex].inputFileInfo.creator.id);
    e.stopPropagation();
    setSelectedItemIndex(itemIndex);
    setSelectedItem(data[itemIndex]);
    if (isDriveCalculation && isUserReport || !isDriveCalculation) {
      dispatch(getInputFileById(data[itemIndex]?.inputFileInfo?.inputFileId));
      dispatch(getReportByCalcInfoId(data[itemIndex]?.id));
    }
  };

  const handleDoubleClick = (itemIndex: number) => {
    const isUserReport = Boolean(getUserId() === data[itemIndex].inputFileInfo.creator.id);
    if (isUserReport || !isDriveCalculation) {
      history.push({
        pathname:  `/print-view/${calculationId}/${data[itemIndex]?.inputFileInfo?.inputFileId}/${data[itemIndex]?.id}`,
        state: {
          ...(isDriveCalculation && { calculationType: 'BUSINESS' })
        }
      });
    } else {
      dispatch(formModalSetupActionPopUp("NOT_ALLOWED_OPEN_REPORT"));
    }
  };

  const handleUnselectItem = () => {
    setSelectedItemIndex(OUT_OF_RANGE_INDEX);
    setSelectedItem(null);
  };

  const handleRename = (payload: any) => {
    dispatch(formModalSetupActionPopUp('RENAME_REPORT', payload));
  };

  const handleDelete = (payload: any) => {
    dispatch(formModalSetupActionPopUp('CONFIRMATION_DELETE_REPORT', payload));
  };

  // useEffect(() => {}, [selectedItem, report]);

  const handleDownload = async (payload: any) => {
    if (!payload.printedTime) {
      dispatch(
        toastSetupActionPopUp(
          'ERROR',
          'Cannot download because the report is not finished yet'
        )
      );
    } else {
      putReportAfterDownload(payload.id, 'printed');

      const pdf: File = await createFileFromUrl(report.file);
      downloadPdf(window.URL.createObjectURL(pdf), `${report?.title}.pdf`);
      tagManagerService.downloadPdf_success_fromReportManager();
    }
  };

  return (
    <>
      <ChildWrapper>
        <BreadCrumbs locations={breadcrumb} pathName={pathName} />
        {isLoading && <LoadingSpinner />}
        <TableContentSection onClick={handleUnselectItem}>
          <ContentTable
            headers={tableData.headers}
            data={tableData.data}
            selectedRow={selectedItemIndex}
            onRowClick={handleSelectItem}
            onDoubleClick={handleDoubleClick}
          />
        </TableContentSection>
      </ChildWrapper>
      {selectedItem && (
        <ReportInfoPanel
          info={selectedItem}
          calculation={calculationInfo}
          handleRename={handleRename}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          gtmService={tagManagerService}
        />
      )}
      <HelperButton />
    </>
  );
};

export default inputFileNav(ReportList);
