import React from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  ContainerModalStyled,
  ContainerTitleStyled,
  MessageInfoStyled
} from './elements';
import UseWindowDimensions from '../../../../utils/getWindows';

interface PropsModalI {
  modalVisible: boolean;
  onCloseModal: () => void;
  title?: React.ReactElement;
  body?: React.ReactElement;
  isMobile?: boolean;
}

export const ModalInfo = ({
  modalVisible,
  onCloseModal,
  title,
  body,
  isMobile
}: PropsModalI) => {
  const { width } = UseWindowDimensions();
  return (
    <Modal
      open={modalVisible}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ContainerModalStyled isMobile={isMobile} width={width * 0.46}>
        <ContainerTitleStyled>
          {title}
          <CloseIcon onClick={onCloseModal} />
        </ContainerTitleStyled>
        <MessageInfoStyled>{body}</MessageInfoStyled>
      </ContainerModalStyled>
    </Modal>
  );
};
