import {
  CLOSEEVENTTYPEPOPUP,
  DATAFORMMODALTYPEPOPUP,
  CloseEventTypePopUp,
  DataFormModalTypePopUp,
} from "./formModalType";

export interface initialStateI {
  /**
   * need to define more specific interface name
   * i.e: formModalStateI
   */
  closeEvent: boolean;
  dataFormModal: any;
}

export const initialState: initialStateI = {
  /**
   * need to define more specific interface name
   * i.e: formModalState
   */
  closeEvent: false,
  dataFormModal: {
    isOpen: false,
    type: null,
    content: {
      head: null,
      body: [],
      foot: [],
      storage: null,
    },
  },
};

type Action = DataFormModalTypePopUp | CloseEventTypePopUp;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case CLOSEEVENTTYPEPOPUP:
      return { ...state, closeEvent: action.data };
    case DATAFORMMODALTYPEPOPUP:
      return { ...state, dataFormModal: action.data };
    default:
      return state;
  }
}
