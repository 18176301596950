import { Dispatch } from "redux";
import GenericService from "../../service/generic/generic";
import { fetchFAQ, LOADING_FAQ } from "../types/faqActionTypes";

export const handlefetchFAQ = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      const service = new GenericService(`faq`);
      const response = await service.get();
      dispatch(loadingAction(false));
      dispatch(fetchFAQ(response.data));
      return Promise.resolve(response.data);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

function loadingAction(isLoading: boolean) {
  return { type: LOADING_FAQ, isLoading };
}
