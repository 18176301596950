import { UseSliderHiddenInputOwnProps } from "@mui/base";

export const CREATE_NEW_CALC = 'CREATE_NEW_CALC';
export const LOADING_CALCULATIONS = 'LOADING_CALCULATIONS';
export const FAILED_CALCULATIONS = 'FAILED_CALCULATIONS';
export const TRIGGER_SAVE_CALCULATIONS = 'TRIGGER_SAVE_CALCULATIONS';
export const SAVE_CALCULATIONS = 'SAVE_CALCULATIONS';
export const SET_CALCULATIONS = 'SET_CALCULATIONS';

export interface LoadingActionI {
  type: typeof LOADING_CALCULATIONS;
  isLoading?: boolean;
}

export interface FailedActionI {
  type: typeof FAILED_CALCULATIONS;
  message?: string;
  isLoading?: boolean;
}

export interface CreateNewActionI {
  type: typeof CREATE_NEW_CALC;
  id: string;
}

export interface TriggerSaveActionI {
  type: typeof TRIGGER_SAVE_CALCULATIONS;
  isSaveCalculation: boolean;
}

export interface SaveActionI {
  type: typeof SAVE_CALCULATIONS;
}

export interface SetActionI {
  type: typeof SET_CALCULATIONS;
  dataCalculation: Record<string, unknown>;//Object;
}

export interface CreateCalculationI {
  folderId: string;
  title: string;
  description: string;
}

export interface UserI {
  id: string;
  name: string;
}

export interface RenameCalculationI {
  id: string;
  title: string;
}

export interface CalculationI {
  createdAt: string;
  createdBt: UserI;
  deletedAt: string | null;
  deletedBy: UserI;
  description: string | null;
  folderId: string;
  folderName: string;
  id: string;
  isComplete: boolean;
  pages: Array<any>;
  principal: UseSliderHiddenInputOwnProps;
  principalId: string | null;
  status: string;
  title: string;
  updatedAt: string | null;
  updatedBy: UserI;
}

export interface CopyCalculationsI {
  calculationIds: Array<string>;
  destinationFolderId: string;
}
