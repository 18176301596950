import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import React from 'react';

import {
  cellStyle,
  Container,
  headerStyle,
  outerStyle,
  selectedCellStyle
} from './elements';

interface PropsI {
  headers: Array<string>;
  data: Array<Record<string, unknown>>;
  selectedRow: number;
  onRowClick: (e: any, i: number) => void;
}

// TODO: update to use defined PropsI
export default function ContentTable(props: any) {
  return (
    <Container>
      <Table sx={outerStyle}>
        <TableHead>
          <TableRow sx={headerStyle}>
            {React.Children.toArray(
              props.headers.map((item: any) => {
                return <TableCell>{item}</TableCell>;
              })
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {React.Children.toArray(
            props.data.map((row: any, i: number) => (
              <TableRow
                sx={props.selectedRow === i ? selectedCellStyle : cellStyle}
                onClick={e => props.onRowClick(e, i)}
                data-testid={row[0]}
                onDoubleClick={() => props.onDoubleClick(i)}
              >
                {React.Children.toArray(
                  row.map((item: any) => {
                    return <TableCell>{item}</TableCell>;
                  })
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
