import React from "react";

import CuppertinoSwitch from "../../../atoms/cupertinoSwitch/cupertinoSwitch";
import { SwitchText, SwitchWrapper } from "./styles";

import { SwitchPropsI } from "./type";

const Switch = ({value, text, clicked}: SwitchPropsI) => {
  function click (e: React.SyntheticEvent) {
    value === true ? clicked(e, false) : clicked(e, true);
  }

  return (
    <SwitchWrapper>
      <SwitchText onClick={(e: React.SyntheticEvent) => click(e)}>
        {text}
      </SwitchText>
      <CuppertinoSwitch 
        onClick={(e: React.SyntheticEvent) => click(e)}
        checked={value}
      />
    </SwitchWrapper>
  );
};

export default Switch;