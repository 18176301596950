import { Container, LatexExpand, LatexLine, LatexSection } from './element';
import { isIf, isNestedIf } from '../../../utils/helpersFunction';
import { includeString } from '../../../utils/tableFunction';
import Shimmer from '../../atoms/Shimmer';
import { formulaNonExpand } from '../../../utils/constant';
import { LatexItem } from './components/LatexItem';

interface LatexFormulaProps {
  columnId: string;
  multiLine: boolean;
  properties: any;
  loggerValues: Array<any>;
  isCompleted: boolean;
  theme: any;

  index: number;
  addLatexRef: (payload: any) => void;
}

const LatexPrintView = ({
  columnId,
  properties,
  loggerValues,
  isCompleted,
  multiLine,
  addLatexRef
}: LatexFormulaProps) => {
  const { formulaWithNotations } = properties;
  const containIf: boolean =
    isNestedIf(formulaWithNotations) ||
    isIf(formulaWithNotations.toLowerCase());

  return (
    <>
      <Container>
        <LatexLine>
          <LatexSection>
            {containIf ? (
              <LatexItem
                properties={properties}
                line={3}
                loggerValues={loggerValues}
                columnId={columnId}
                isCompleted={true}
                multiLine={multiLine}
                addLatexRef={addLatexRef}
                containIf
                isPrintView
              />
            ) : (
              <LatexItem
                properties={properties}
                line={1}
                loggerValues={loggerValues}
                columnId={columnId}
                isCompleted={true}
                multiLine={multiLine}
                addLatexRef={addLatexRef}
                isPrintView
              />
            )}

            {!includeString(formulaWithNotations, formulaNonExpand) &&
              !containIf && (
                <>
                  {!isCompleted ? (
                    <Shimmer type="text" size="m" />
                  ) : (
                    <LatexExpand visible={true}>
                      <LatexItem
                        properties={properties}
                        line={2}
                        loggerValues={loggerValues}
                        columnId={columnId}
                        isCompleted={true}
                        multiLine={multiLine}
                        addLatexRef={addLatexRef}
                        isPrintView
                      />
                      <LatexItem
                        properties={properties}
                        line={3}
                        loggerValues={loggerValues}
                        columnId={columnId}
                        isCompleted={true}
                        multiLine={multiLine}
                        addLatexRef={addLatexRef}
                        isPrintView
                      />
                    </LatexExpand>
                  )}
                </>
              )}
          </LatexSection>
        </LatexLine>
      </Container>
    </>
  );
};

export default LatexPrintView;
