import styled from 'styled-components';

export const Container = styled.div({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
  flexDirection: 'column',
  marginTop: '1.5rem',
});

export const outerStyle = {
  minWidth: 650,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
};

export const headerStyle = {
  height: 48,
  '& .MuiTableCell-root:nth-child(1)': {
    borderRight: 'none',
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '8px',
  },
  '& .MuiTableCell-root': {
    padding: '8px 0 8px 16px',
    borderRight: '2px solid #F0F2F5',
    borderBottom: 'none',
  },
};

export const cellStyle = {
  '& .MuiTableCell-root:nth-child(1)': {
    padding: '3px 0',
    paddingLeft: '16px'
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '8px',
  }
};

export const selectedCellStyle = {
  '& .MuiTableCell-root:nth-child(1)': {
    padding: '3px 0',
    paddingLeft: '16px'
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '8px',
  },
  backgroundColor: '#E3F3FF'
};
