import React from 'react';
import { SearchWrapper } from './elements';
import { IconTextField } from '../IconTextField';
import Icon from '../../atoms/Icon';

type SearchProps = {
  placeholder: string;
  value: string;
  error: boolean;
  onChange: any;
  onKeyDown: any;
  onClick: any;
  isDrive?: boolean;
};

export const CalculationsSearchBox = ({
  placeholder,
  value,
  error,
  onChange,
  onKeyDown,
  onClick,
  isDrive
}: SearchProps) => {
  const CHAR_ZERO = 0;
  const MIN_SEARCH_CHAR = 3;
  const invalidSearch = value.length > CHAR_ZERO && value.length < MIN_SEARCH_CHAR;
  return (
    <SearchWrapper style={{ height: 40, paddingTop: isDrive ? 14 : 0 }}>
      <IconTextField
        placeholder={placeholder}
        value={value}
        error={error || invalidSearch}
        onChange={onChange}
        onKeyDown={onKeyDown}
        iconEnd={
          <Icon icon="search" styles={{ margin: -10 }} onClick={onClick} />
        }
      />
      {invalidSearch ? (
        <p id="searchError">Minimum length is 3 characters</p>
      ) : error ? (
        <p id="searchError">Failed to search, try again later.</p>
      ) : null}
    </SearchWrapper>
  );
};
