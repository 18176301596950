import {
  SHOWHIDETOAST,
  ShowHideToastAction,
} from '../types/toastTypes';

export interface initialStateI {
  status: boolean;
  color: string;
  message: string;
}

const initialState: initialStateI = {
  status: false,
  color: 'black',
  message: 'empty'
};

type Action = ShowHideToastAction;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case SHOWHIDETOAST:
      return { ...state, status: action.status, color: action.color, message: action.message };
    default:
      return state;
  }
}