import './styles.css';

import { ComponentDimensionsI, PrintFormationI } from '../../print.types';
import {
  FieldText,
  InputFormulaLatex,
  InputImage,
  InputNumeric,
  InputText,
  LabelText,
  Link,
  Table,
} from '../../../../components/molecules/PrintView';
import React, { useEffect } from 'react';

import { Divider } from '../../../../pageElements/printViewPage';
import Logo from '../../../../components/atoms/Logo';
import Text from '../../../../components/atoms/Text';
import dayjs from 'dayjs';
import { generatePrintFormation, cellsFilter } from '../../utils';
import images from '../../../../assets';
import { useTheme } from '@mui/material';

interface PropsI {
  data: any;
  reportFormat: any;
  selectedPage: Array<string>;
  componentDimensionAll: Array<ComponentDimensionsI>;
  setReadyToPrint: (value: boolean) => void;
}

export const ComponentToPrint = React.forwardRef<any, PropsI>((props, ref) => {
  const printComponent: Array<PrintFormationI> = generatePrintFormation(props.componentDimensionAll);

  const info: any = props.data;
  const pageElements: any = props.data?.inputFile?.pages
    .map((a: any, i: number) => {
      return {
        pageTitle: a.pageTitle,
        pageAlias: a.pageTitle + ' ' + i,
        components: a.components
      };
    })
    .filter(
      (page: any, i: number) =>
        props.selectedPage.includes(page.pageAlias) ||
        page.pageAlias === 'Executive Summary'
    );
  const reportFormat: any = props.reportFormat;

  const theme = useTheme();
  return (
    <div ref={ref}>
      {!pageElements
        ? null
        : pageElements.map((el: any) => {
          const newData: any = [];
          el.components?.forEach((item: any, index: number) => {
            const findComponent = printComponent.filter((el: PrintFormationI) => el.columnId === item.columnId);
            if (findComponent.length === 1) {
              newData.push({
                ...item,
                ...(item.properties && {properties: item.type === 'TABLE' ?  cellsFilter(item.properties) : item.properties})
              });
            } else {
              const allEqual = (arr: any) => arr.every((val: any) => val === arr[0]);
              if (findComponent.length && allEqual(findComponent.map((a: any) => a.page))) {
                newData.push({
                  ...item,
                  ...(item.properties && {properties: item.type === 'TABLE' ?  cellsFilter(item.properties) : item.properties})
                });
              } else {
                findComponent.forEach((a: any, idx: number) => {
                  const tableRows = a.tableRows ? parseInt(a.tableRows?.toFixed(0)) : 0;
                  if (idx === 0) {
                    newData.push({
                      ...item,
                      properties: {
                        ...item.properties,
                        cells: item.properties?.cells?.filter((el: any) => el.rowIdx <= (tableRows - 1)),
                        displayedContent: {
                          ...item.properties?.displayedContent,
                          row: {
                            ...item.properties?.displayedContent?.row,
                            end: tableRows - 1
                          }
                        }
                      }
                    });
                  } else {
                    newData.push({
                      ...item,
                      properties: {
                        ...item.properties,
                        cells: item.properties?.cells?.filter((el: any) => el.rowIdx > newData[newData.length - 1].properties.displayedContent.row.end),
                        displayedContent: {
                          ...item.properties?.displayedContent,
                          row: {
                            ...item.properties?.displayedContent?.row,
                            end: item.properties?.displayedContent?.row.end - newData[newData.length - 1].properties?.displayedContent.row.end
                          }
                        }
                      }
                    });
                  }
                });
              }
            }
          });

          if (!Boolean(el.components.find((item: any) => item.type === 'TABLE'))) {
            props.setReadyToPrint(true);
          } else {
            if (Boolean(newData.find((item: any) => item.type === 'TABLE'))) {
              props.setReadyToPrint(true);
            }
          };
          return (
            <div className="page">
              <table>
                <thead>
                  <th>
                    <td>
                      <div className="empty-header"></div>
                    </td>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          padding: '8px 56px'
                        }}
                      >
                        {Array.isArray(newData) &&
                          newData?.map((item: any, index: number) => {
                            const { columnId, value, type, properties } = item;
                            switch (type) {
                              case 'TEXT':
                                return (
                                  <LabelText data={item} />
                                );
                              case 'TEXT_INPUT':
                                return <InputText data={item} />;
                              case 'NUMERICAL_INPUT':
                              case 'CURRENCY_INPUT':
                              case 'DROPDOWN_INPUT':
                              case 'DATE_INPUT':
                              case 'RADIO_BUTTON_INPUT':
                                return <InputNumeric data={item} />;
                              case 'FIELD':
                                return (
                                  <FieldText data={item} printType="PDF" />
                                );
                              case 'FORMULA':
                                return (
                                  <InputFormulaLatex
                                    index={index}
                                    data={item}
                                    columnId={columnId}
                                    pages={pageElements}
                                    showLatex={Boolean(
                                      reportFormat.includes('Latex Formulas')
                                    )}
                                    multiLine={true}
                                    theme={theme}
                                  />
                                );
                              case 'DIVIDER':
                                return <Divider />;
                              case 'IMAGE':
                                return <InputImage data={item} />;
                              case 'TABLE':
                                return <Table properties={properties} columnId={columnId} setTableDimension={() => undefined} isPrint={true} />;
                              case 'LINK':
                                return <Link properties={properties} />;

                              default:
                            }
                          })}
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <div className="empty-footer"></div>
                </tfoot>
              </table>
              <div className="headerArea">
                <div
                  style={{
                    borderBottom: '2px solid #F0F2F5',
                    padding: '8px 56px'
                  }}
                >
                  <div className="headerTitle">
                    <p>{info.calculationTitle}</p>
                  </div>

                  <div className="inline">
                    <div id="numbers" />
                    <div
                      style={{
                        flexBasis: 300,
                        flexDirection: 'row',
                        display: 'flex'
                      }}
                    >
                      <div style={{ flexBasis: 60, color: '#A3B3C2' }}>
                        <p>Originator</p>
                      </div>
                      <div style={{ flexBasis: 8 }}>
                        <p>:</p>
                      </div>
                      <div style={{ color: '#29333D' }}>
                        <p>{info.createdBy?.name}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        flexBasis: 200,
                        flexDirection: 'row',
                        display: 'flex'
                      }}
                    >
                      <div style={{ width: 92, color: '#A3B3C2' }}>
                        <p>Date Created</p>
                      </div>
                      <div style={{ flexBasis: 8 }}>
                        <p>:</p>
                      </div>
                      <div style={{ color: '#29333D' }}>
                        <p>{dayjs(info.createdAt).format('DD/MM/YY')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="inline">
                    <div
                      style={{
                        flexBasis: 300,
                        flexDirection: 'row',
                        display: 'flex'
                      }}
                    ></div>

                    <div
                      style={{
                        flexBasis: 200,
                        flexDirection: 'row',
                        display: 'flex'
                      }}
                    >
                      <div style={{ width: 92, color: '#A3B3C2' }}>
                        <p>Date Printed</p>
                      </div>
                      <div style={{ flexBasis: 8 }}>
                        <p>:</p>
                      </div>
                      <div style={{ color: '#29333D' }}>
                        <p>{dayjs(new Date()).format('DD/MM/YY')}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 56
                    }}
                  >
                    <Logo src={images.textLogoGrey} width={64} />
                  </div>
                </div>
              </div>
              <div className="footerArea">
                <Text variant="smallLight" color={theme.customText.high}>
                  Powered by Rekava
                </Text>
              </div>
              <div className="pageBreak"></div>
            </div>
          );
        })}
    </div>
  );
});
