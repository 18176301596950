import { Divider, Menu, MenuItem } from '@mui/material';
import { Fragment, useState } from 'react';
import { MenuWrapper, NavMenu } from './elements';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { MenuLabel } from '../LoggerMenu/elements';
import { PrintViewTopBarMenuItems } from '../../../utils/constant';
import { ReduxState } from '../../../store';
import { fetchReportList } from '../../../store/actions/reportAction';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';

type PrintViewMenuI = {
  title:
    | 'Open Other Report'
    | 'Save'
    | 'Share'
    | 'Publish'
    | 'Comment'
    | 'Exit to Report';
};

const PrintViewMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<any | HTMLElement>(null);
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
  location.state?.calculationType === 'BUSINESS';

  const handleShowNestedMenu = (e: any, index: number) => {
    setAnchorEl({ [index]: e.currentTarget });
  };

  const { currentReport, data, reportSaveFormat } = useSelector(
    (state: ReduxState) => state.reportManager
  );

  const { pdfFile } = reportSaveFormat;

  const handleExitToReport = async () => {
    try {
      await dispatch(
        formModalSetupActionPopUp('CONFIRMATION_PRINT_VIEW_TO_REPORT', {
          currentReport,
          title: reportSaveFormat.reportName,
          notes: '',
          isActive: true,
          format: {
            pages: reportSaveFormat.pages,
            summary: reportSaveFormat.summary,
            latex: reportSaveFormat.latex
          },
          history,
          isDriveReportManager: isDriveCalculation,
        })
      );
    } catch (error: any) {
      console.error(error?.response?.data);
    }
  };

  const handleSaveReport = async () => {
    try {
      dispatch(
        formModalSetupActionPopUp('SAVE_REPORT_PRINT_VIEW', {
          currentReport,
          title: reportSaveFormat.reportName,
          isActive: true,
          format: {
            pages: reportSaveFormat.pages,
            summary: reportSaveFormat.summary,
            latex: reportSaveFormat.latex
          }
        })
      );
    } catch (error: any) {
      console.error(error?.response?.data);
    }
  };

  const openOtherReport = async () => {
    try {
      dispatch(
        formModalSetupActionPopUp('OPEN_OTHER_REPORT', {
          data,
          history,
          currentReport
        })
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const NestedMenuItemActionHandler = (e: any, menu: PrintViewMenuI) => {
    e.preventDefault();
    const { title } = menu;
    switch (title) {
      case 'Open Other Report':
        handleCloseMenu();
        openOtherReport();
        break;
      case 'Save':
        handleCloseMenu();
        break;
      case 'Share':
        handleCloseMenu();
        break;
      case 'Publish':
        handleCloseMenu();
        break;
      case 'Comment':
        handleCloseMenu();
        break;
      case 'Exit to Report':
        handleCloseMenu();
        handleExitToReport();
        break;

      default:
        handleCloseMenu();
        break;
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <NavMenu>
      {PrintViewTopBarMenuItems.map((menu, index) => {
        return (
          <Fragment key={index}>
            <MenuLabel
              onClick={e => handleShowNestedMenu(e, index)}
              data-testid={menu.dataTestid}
            >
              <p>{menu.title}</p>
              <div className="navMenuStrip" />
              {anchorEl && Boolean(anchorEl[index]) && (
                <div className="navMenuStripActivated" />
              )}
            </MenuLabel>
            <Menu
              anchorEl={anchorEl && anchorEl[index]}
              keepMounted
              open={anchorEl && Boolean(anchorEl[index])}
              onClose={handleCloseMenu}
              style={{ top: '-22px' }}
            >
              {menu.content.map((listMenu: any, menuindex: number) => (
                <div style={{ width: 192, height: '100%' }} key={menuindex}>
                  <MenuItem
                    onClick={e => NestedMenuItemActionHandler(e, listMenu)}
                    data-testid={listMenu.dataTestid}
                  >
                    <MenuWrapper>
                      <p>{listMenu.title}</p>
                    </MenuWrapper>
                  </MenuItem>
                  {listMenu.divider && <Divider />}
                </div>
              ))}
            </Menu>
          </Fragment>
        );
      })}
    </NavMenu>
  );
};

export default PrintViewMenu;
