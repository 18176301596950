import { styled } from "@mui/material";

export const SwitchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const SwitchText = styled('div')(({ theme }) => ({
  width: 140,
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 1.5,
  cursor: 'pointer',
  color: theme.palette.text.primary,
  userSelect: 'none',
}));

export const BooleanWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
}));

export const BooleanCheck = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const BooleanText = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 1.5,
  cursor: 'pointer',
  color: theme.palette.text.primary,
  userSelect: 'none',
}));