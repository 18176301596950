import { SidePanelProps } from "../../logger.types";
import { SidebarContainer, StickyWrapper } from "./styles";

const SidePanel = ({sticky = false, top, bottom}: SidePanelProps) => {
    return (
        <SidebarContainer>
            <StickyWrapper sticky={sticky}>
                {top && top}
                {bottom && bottom}
           </StickyWrapper>
        </SidebarContainer>
    );
};

export default SidePanel;