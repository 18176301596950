export const GET_SYMBOLS = 'GET_SYMBOLS';
export const GET_SYMBOLS_SUCCESS = 'GET_SYMBOLS_SUCCESS';
export const GET_SYMBOLS_FAILED = 'GET_SYMBOLS_FAILED';

export const GetSymbols = () => {
  return {
    type: GET_SYMBOLS,
  };
};

export const GetSymbolsSuccess = (items: any) => {
  return {
    type: GET_SYMBOLS_SUCCESS,
    items
  };
};

export const GetSymbolsFailed = (error: string) => {
  return {
    type: GET_SYMBOLS_FAILED,
    error,
  };
};
