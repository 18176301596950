import { useTheme } from '@mui/material'
import Text from '../../../components/atoms/Text'
import { TEXT_ACE_GET_STARTED, TEXT_ACE_GET_STARTED_DESC, TEXT_CONTACT_US } from '../../../utils/constant'
import { GetStartedContainer } from './styles';
import StyledButton from '../../../components/atoms/Button';

export const AceGetStarted = () => {
	const theme = useTheme();
	return (
		<GetStartedContainer>
			<Text variant='h1' color={theme.customText.high}>
				{TEXT_ACE_GET_STARTED}
			</Text>
			<Text variant='body1' color={theme.customText.low}>
				{TEXT_ACE_GET_STARTED_DESC}
			</Text>
			<StyledButton
				type="button"
				variant="outlined"
				onClick={e => undefined}
				sx={{ textTransform: 'none', width: 120, alignSelf: 'center', marginTop: '24px' }}

			>{TEXT_CONTACT_US}
			</StyledButton>
		</GetStartedContainer>
	)
}
