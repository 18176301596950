import React from 'react'
import { RightSection, SourceContentWrapper } from '../../styles';
import UseWindowDimensions from '../../../../utils/getWindows';
import { Divider } from '@mui/material';
import { SourceDisplay } from './SourceDisplay';
import { SessionList } from '../aceInfoPanel/SessionList';

interface SourceDisplayPropsI {
  isMobile?: boolean;
  isConsent?: boolean;
}

export const RightSectionWrapper = ({ isMobile, isConsent }: SourceDisplayPropsI) => {
  const { height } = UseWindowDimensions();

  return (
    <RightSection isMobile={isMobile}>
      {
        isConsent ? <SourceContentWrapper isMobile={isMobile} height={(height - 224) / 2}>
          <SourceDisplay />
          <Divider style={{ marginBottom: 4 }} />
          <SessionList />
        </SourceContentWrapper> : null
      }
    </RightSection>
  )
}
