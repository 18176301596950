import { Box } from '@mui/material';
import Text from '../../../../components/atoms/Text';
import { IColorText } from '../../../../components/molecules/composer/composerInput/type';
import { isValidURL } from '../../../../utils/helpersFunction';
import { LinkProps } from '../../logger.types';

export function LinkComponent({ properties, handleOpenNewTab }: LinkProps) {
  const { url, description } = properties;

  return (
    <Box
      onClick={() => (isValidURL(url) ? handleOpenNewTab(url) : null)}
      style={{ padding: '8px 0' }}
    >
      <Text
        variant="body3"
        style={{
          color: isValidURL(url) ? IColorText.Blue : IColorText.Black,
          cursor: isValidURL(url) ? 'pointer' : '',
          overflowWrap: 'anywhere'
        }}
      >
        {description ? description : url}
      </Text>
    </Box>
  );
}
