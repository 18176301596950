import FormulaTable from "./FormulaTable";

export const CellType = {
  DEFAULT: 'default',
  HEADER: 'header',
  INPUT: 'input',
  READONLY: 'readonly'
};

export const CellAlignment = {
  CENTER: 'CENTER',
  DEFAULT: 'DEFAULT',
  JUSTIFY: 'JUSTIFY',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

export const CellCurrencies = {
  Currency: null,
  IDR: 'Rp',
  USD: '$',
  CNY: 'CN¥',
  EUR: '€',
  GBP: '£',
  JPY: '¥'
};

export const CellNumberFormat = {
  Format: null,
  '$1,000.00': ['prefix', '#,##.00'],
  '$1,000': ['prefix', '#,##'],
  '1,000.00$': ['suffix', '#,##.00'],
  '1,000$': ['suffix', '#,##'],
};

export const DISPLAYED_CONTENT_BORDER_NAME = 'displayed-content';

export const FillHandlerDirection = {
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  UP: 'UP'
};

export const ALIGNMENTS: Map<string, string> = new Map([
  ['format_align_left', CellAlignment.LEFT],
  ['format_align_center', CellAlignment.CENTER],
  ['format_align_right', CellAlignment.RIGHT]
]);

export const CURRENCIES: Map<string, string | null> = new Map([
  ['Currency', CellCurrencies.Currency],
  ['IDR', CellCurrencies.IDR],
  ['USD', CellCurrencies.USD],
  ['CNY', CellCurrencies.CNY],
  ['EUR', CellCurrencies.EUR],
  ['GBP', CellCurrencies.GBP],
  ['JPY', CellCurrencies.JPY]
]);

export const NUMBER_FORMAT: Map<string, any[]> = new Map([
  ['Format', [null, null]],
  ['$1,000.00', ['prefix', '#,##.00']],
  ['$1,000', ['prefix', '#,##']],
  ['1,000.00$', ['suffix', '#,##.00']],
  ['1,000$', ['suffix', '#,##']],
]);

export type ToolbarItem = {
  item: string,
  action: any,
  payload?: any
};

export const ToolbarOptions = {
  FreezeColumn: 'FREEZE_COLUMN',
  FreezeRow: 'FREEZE_ROW',
  TextAlignment: 'TEXT_ALIGNMENT',
  Currency: 'CURRENCY_FORMAT',
  NumberFormat: 'NUMBER_FORMAT',
  IncreaseDecimal: 'INCREASE_DECIMAL',
  DecreaseDecimal: 'DECREASE_DECIMAL'
};

export { FormulaTable };
