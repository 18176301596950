import styled from "styled-components";

interface PropsI {
  isActive?: boolean;
  isShowButton?: boolean;
  isList?: boolean;
}

export const FolderContainer = styled.div<PropsI>((props: PropsI) => ({
  width: props.isList ? "16px" : "12rem",
  marginTop: props.isList ? "4px" : "1.5rem",
  padding: props.isList ? 0 : "0.5rem",
  textAlign: "center",
  cursor: "pointer",
  paddingLeft: props.isList ? "16px" : 0,
  backgroundColor: props.isActive ? "#E3F3FF" : "none",
  "& > div:nth-of-type(2)": {
    display: "flex",
    alignItems: "center",
    height: "3rem",
    justifyContent: "center",
  },
}));

export const Wrap = styled.div({
  height: "9rem",
  border: "1px solid #E0E6EB",
  boxSizing: "border-box",
  WebkitBoxSizing: "border-box",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#FFFFFF",
  flexDirection: "column",
  position: "relative",
});

export const ActionButton = styled.div<PropsI>((props: PropsI) => ({
  position: "absolute",
  top: 0,
  display: "flex",
  visibility: props.isShowButton ? "visible" : "hidden",
  alignItems: "center",
  justifyContent: "space-between",
  right: 0,
  left: 0,
}));

export const ActionButtonList = styled.div<PropsI>((props: PropsI) => ({
  display: "flex",
  visibility: props.isShowButton ? "visible" : "hidden",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const WrapList = styled.div<PropsI>((props: PropsI) => ({
  // border: props.isActive ? "1px solid #E0E6EB" : "none",
  border: 'none',
  boxSizing: "border-box",
  cursor: "default",
  WebkitBoxSizing: "border-box",
  borderRadius: "4px",
}));

export const ContentSection = styled.div({
  display: "flex",
  columnGap: ".5rem",
  flexWrap: "wrap",
  paddingBottom: 20
});

export const TableContentSection = styled.div({
  display: "flex",
  columnGap: ".5rem",
  cursor: "default",
  flexWrap: "wrap",
  height: "100%"
});

