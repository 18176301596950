import React from "react";
import { Spinner, ContainerLoading } from "./elements";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading() {
  return <Spinner size="SMALL" />;
}

export const LoadingSpinner = () => {
  return (
    <ContainerLoading>
      <CircularProgress />
    </ContainerLoading>
  );
};
