import {
  Container,
  Expand,
  LatexExpand,
  LatexLine,
  LatexSection
} from './element';
import { isIf, isNestedIf } from '../../../utils/helpersFunction';

import Icon from '../../atoms/Icon';
import { includeString } from '../../../utils/tableFunction';
import Shimmer from '../../atoms/Shimmer';
import { formulaNonExpand } from '../../../utils/constant';
import { LatexItem } from './components/LatexItem';

interface LatexFormulaProps {
  columnId: string;
  multiLine: boolean;
  properties: any;
  loggerValues: Array<any>;
  isCompleted: boolean;
  theme: any;
  handleExpandLatex: (e: any, index: number) => void;
  expandLatex: any;
  index: number;
  addLatexRef: (payload: any) => void;
  isRefactor?: boolean;
}

export const LatexFormula = ({
  columnId,
  properties,
  loggerValues,
  isCompleted,
  theme,
  handleExpandLatex,
  expandLatex,
  index,
  multiLine,
  addLatexRef,
  isRefactor
}: LatexFormulaProps) => {
  const { formulaWithNotations, isDisplayed } = properties;
  const containIf: boolean =
    isNestedIf(formulaWithNotations) ||
    isIf(formulaWithNotations.toLowerCase());

  return (
    <>
      {isDisplayed ? (
        <Container>
          <LatexLine>
            <Expand
              latex
              expandFormula={
                !includeString(formulaWithNotations, formulaNonExpand)
              }
            >
              {!includeString(formulaWithNotations, formulaNonExpand) &&
                !containIf && (
                  <Icon
                    icon={expandLatex[index]?.expand ? 'arrowDown' : 'arrowUp'}
                    onClick={(e: any) => handleExpandLatex(e, index)}
                    color={theme.customText.default}
                  />
                )}
              <Icon icon="fx" color={theme.customText.default} />
            </Expand>
            <LatexSection>
              {containIf ? (
                <LatexItem
                  properties={properties}
                  line={3}
                  loggerValues={loggerValues}
                  columnId={columnId}
                  isCompleted={isCompleted}
                  multiLine={multiLine}
                  addLatexRef={addLatexRef}
                  containIf
                  isRefactor={isRefactor}
                />
              ) : (
                <LatexItem
                  properties={properties}
                  line={1}
                  loggerValues={loggerValues}
                  columnId={columnId}
                  isCompleted={isCompleted}
                  multiLine={multiLine}
                  addLatexRef={addLatexRef}
                  isRefactor={isRefactor}
                />
              )}

              {!includeString(formulaWithNotations, formulaNonExpand) &&
                !containIf && (
                  <>
                    {!isCompleted ? (
                      <Shimmer type="text" size="m" />
                    ) : (
                      <LatexExpand visible={expandLatex[index]?.expand}>
                        <LatexItem
                          properties={properties}
                          line={2}
                          loggerValues={loggerValues}
                          columnId={columnId}
                          isCompleted={isCompleted}
                          multiLine={multiLine}
                          addLatexRef={addLatexRef}
                          isRefactor={isRefactor}
                        />
                        <LatexItem
                          properties={properties}
                          line={3}
                          loggerValues={loggerValues}
                          columnId={columnId}
                          isCompleted={isCompleted}
                          multiLine={multiLine}
                          addLatexRef={addLatexRef}
                          isRefactor={isRefactor}
                        />
                      </LatexExpand>
                    )}
                  </>
                )}
            </LatexSection>
          </LatexLine>
        </Container>
      ) : null}
    </>
  );
};

export default LatexFormula;