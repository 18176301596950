import { ActionButton, Container, Item, Wrap } from './elements';
import { Checkbox, IconButton, ListItemText } from '@mui/material';
import React, { MouseEvent, useEffect, useState } from 'react';
import { downloadFile, getExtension } from '../../../utils/fileHandler';

import Icon from '../../atoms/Icon';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Text from '../../atoms/Text';
import images from '../../../assets';
import { sortMapping } from '../../../utils/helpersFunction';
import { useLocation } from 'react-router-dom';

interface PropsI {
  data: Array<Record<string, unknown>>;
  typeView: string;
  isActiveFolder: any;
  handleActiveFocus: (
    e: MouseEvent,
    i: number,
    itemType: string,
    calIdH: string,
    title: string,
    inputFile: number,
    approval: number,
    description: string,
    item: any,
    id: string,
    selectedTask?: any,
    selectedCommIds?: any
  ) => void;
  handleContextMenu: (
    e: any,
    item: any,
    i: number,
    itemType: string,
    id: string
  ) => void;
  handleDoubleClickItem: (item: any) => void;
  styleView: string;
  id: string;
  selectedTask?: any;
  selectedCommIds?: any;
}

// TODO: update to use defined PropsI
export default function GridView({
  data,
  typeView,
  isActiveFolder,
  styleView,
  handleActiveFocus,
  handleContextMenu,
  handleDoubleClickItem,
  id,
  ...restProps
}: PropsI) {
  const { pathname } = useLocation();
  const renderContent = (idx: number, itemType: string, fileName: string) => (
    <Wrap>
      <ActionButton isShowButton={isActiveFolder === idx}>
        {/* <Checkbox size="small" /> */}
        <IconButton style={{ color: '#9A9DB3' }} component="span">
          {/* <SettingsOutlinedIcon fontSize="small" /> */}
        </IconButton>
      </ActionButton>
      {itemType === 'CALCULATION' ? (
        <img src={images.calKal} alt="img" />
      ) : itemType === 'FOLDER' ? (
        <img src={images.calcFolder} alt="img" />
      ) : itemType === 'FILE' ? (
        <img src={getImageFile(fileName)} alt="img" />
      ) : null}
    </Wrap>
  );

  const getImageFile = (fileName: string) => {
    const extension = getExtension(fileName);
    if (extension === 'xls') {
      return images.fileXls;
    }
    if (extension === 'xlsx') {
      return images.fileXlsx;
    }
    if (extension === 'xlsm') {
      return images.fileXlsm;
    }
  };

  return (
    <Container>
      {(styleView === 'Tiles' || styleView === '') &&
        React.Children.toArray(
          sortMapping(data)?.map((value: any, i: number) =>
            value.itemType === 'FOLDER' ||
            value.itemType === 'CALCULATION' ||
            value.itemType === 'FILE' ? (
              <Item
                key={i}
                isActive={isActiveFolder === i}
                onClick={e =>
                  handleActiveFocus(
                    e,
                    i,
                    value.itemType,
                    pathname.includes('/enterprise')
                      ? value.lastVersionId
                      : value.id,
                    value.title,
                    +value.numberOfInputFiles,
                    +value.numberOfApproval,
                    value.description,
                    value,
                    id,
                    [i],
                    [value.id]
                  )
                }
                onContextMenu={e =>
                  handleContextMenu(e, value, i, value.itemType, id)
                }
                onDoubleClick={() => handleDoubleClickItem(value)}
                data-testid={
                  value.itemType === 'FILE' ? value.fileName : value.title
                }
              >
                <div id="body">
                  {value.itemType === 'FOLDER' ? (
                    <img id="image" src={images.tableIconFolder} alt="img" />
                  ) : (
                    <Icon icon="calc" color="currentColor" />
                  )}
                </div>
                <div id="footer">
                  <Text noWrap variant="body2">
                    {value.itemType === 'FILE' ? value.fileName : value.title}
                  </Text>
                </div>
              </Item>
            ) : null
          )
        )}
    </Container>
  );
}
