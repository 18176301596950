import {
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    useTheme,
} from "@mui/material";
import {
    styledCellTable,
    styledTable,
    styledHeaderTable,
    TableContainer,
    cellStyle,
    styledHeaderTableDrive,
    styledCellTableDrive,
} from "./styles";

import Text from "../../../../components/atoms/Text";
import { FolderContainer, WrapList } from "../../../../pageElements/calculations";
import { DriveFilled } from "../../../../assets/icons";
import { localDatetimeTable } from "../../../../utils/dateConvert";
import images from "../../../../assets";
import { getBusinessDriveRole } from "../../../../utils/storage";
import { CONTRIBUTOR } from "../../businessDrives.types";

interface ListViewI {
  data: Array<any>;
  onSelect: (e: any, i: number, row: any, folderId: string) => void;
  isActive: number;
  handleContextMenuTeamDrives: (e: any, row: any, i: number) => void;
  onDoubleClick: (row: any) => void;
  isDrive: boolean;
  folderId: string;
  isManageFolder: boolean;
  isManageCalc: boolean;
  handleCalculationMenuTeamDrives: (e: any, row: any, i: number) => void;
}

export const ListView = ({
  data,
  onSelect,
  isActive,
  handleContextMenuTeamDrives,
  onDoubleClick,
  isDrive,
  folderId,
  isManageFolder,
  handleCalculationMenuTeamDrives,
  isManageCalc
}: ListViewI) => {
  const theme = useTheme();
  const isComposer = getBusinessDriveRole() === CONTRIBUTOR;

  const renderItem = (row: any, i: number) => {
    return (
      <FolderContainer
        onClick={(e: any) => onSelect(e, i, row, folderId)}
        onContextMenu={(e: any) =>
          isComposer && row.itemType === 'FOLDER' ? undefined :
          (row.itemType === 'FOLDER' && isManageFolder) || isDrive
            ? handleContextMenuTeamDrives(
                e,
                isDrive ? row : { ...row, folderId: folderId },
                i
              )
            : isManageCalc
            ? handleCalculationMenuTeamDrives(
                e,
                isDrive ? row : { ...row, folderId: folderId },
                i
              )
            : null
        }
        isActive={isActive === i}
        isList={true}
      >
        <WrapList isActive={isActive === i}>
          {isDrive ? (
            <DriveFilled color={theme.customText.icon_high} />
          ) : row.itemType === 'CALCULATION' ? (
            <img src={images.tableIconFile} alt="img" />
          ) : (
            <img src={images.tableIconFolder} alt="img" />
          )}
        </WrapList>
      </FolderContainer>
    );
  };

  return (
    <TableContainer>
      <Table sx={styledTable}>
        <TableHead>
          <TableRow sx={isDrive ? styledHeaderTableDrive : styledHeaderTable}>
            <TableCell colSpan={2}>
              <Text variant="h5" color={theme.customText.low}>
                Name
              </Text>
            </TableCell>

            {!isDrive && (
              <TableCell>
                <Text variant="h5" color={theme.customText.low}>
                  Originator
                </Text>
              </TableCell>
            )}

            <TableCell align="right">
              <Text variant="h5" color={theme.customText.low}>
                {isDrive ? 'Member' : 'Last Modified'}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) &&
            data.map((row: any, i: number) => (
              <TableRow
                key={`drive_${i}_${row.title}`}
                sx={
                  isActive === i && isDrive
                    ? styledCellTableDrive
                    : isActive === i && !isDrive
                    ? styledCellTable
                    : cellStyle
                }
                onClick={(e: any) => onSelect(e, i, row, folderId)}
                onDoubleClick={() => onDoubleClick(row)}
                onContextMenu={(e: any) =>
                  isComposer && row.itemType === 'FOLDER' ? undefined :
                  (row.itemType === 'FOLDER' && isManageFolder) || isDrive
                    ? handleContextMenuTeamDrives(
                        e,
                        isDrive ? row : { ...row, folderId: folderId },
                        i
                      )
                    : isManageCalc
                    ? handleCalculationMenuTeamDrives(
                        e,
                        isDrive ? row : { ...row, folderId: folderId },
                        i
                      )
                    : null
                }
              >
                <TableCell>{renderItem(row, i)}</TableCell>
                <TableCell component="th" scope="row">
                  <Text variant="h5" color={theme.customText.high}>
                    {row.title}
                  </Text>
                </TableCell>

                {!isDrive && (
                  <TableCell>
                    <Text variant="body2" color={theme.customText.high}>
                      {isDrive
                        ? `${row.countMember} person`
                        : row.createdBy.name}
                    </Text>
                  </TableCell>
                )}

                <TableCell align="right">
                  <Text variant="body2" color={theme.customText.high}>
                    {isDrive
                      ? `${row.countMember} person`
                      : localDatetimeTable(row.updatedAt)}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
