import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FolderContainer,
  WrapList,
} from '../../../pageElements/calculations';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
// KPD-1527 hide feature checkbox and setting in list view
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  TableContainer,
  cellStyle,
  styledCellTable,
  styledHeaderTable,
  styledTable,
} from './elements';

import Text from '../../atoms/Text';
import images from '../../../assets';
import { localDatetimeTable } from '../../../utils/dateConvert';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isSelected, toggleSelection, toggleSelectionInGroup, wasToggleInSelectionGroupKeyUsed } from '../../../utils/helpersFunction';

export default function BasicTable(props: any) {
  const { 
    handlerDragEnd = () => { },
    isAllowDrag = true,
    onBeforeCapture = () => { },
    selectedTask,
    onContextMenu,
    selectedCommIds,
    handleActiveFocusFolder = () => {}
  } = props;
  const [selectedCommId , setSelectedCommId ] = useState(selectedCommIds);
  const [selectedTaskIds, setSelectedTaskIds] = useState(selectedTask);
  const theme = useTheme();
  const { pathname } = useLocation();
  const PRIMARY_BUTTON_NUMBER = 0;


  const onWindowClick = async (e: any) => {
    if (e.defaultPrevented) {
      return;
    }
      // let contexMenuPop = await checkInsideMenu(e)
    if (checkInsideMenu(e)) {
      return;
    }

    setSelectedTaskIds([]);
    setSelectedCommId([]);
  };

  const checkInsideMenu = (e: any) => {
    if (e.target.innerText === 'Delete' ||
        e.target.innerText === 'Move' || 
        e.target.innerText === 'Copy to My Calculation' ||
        e.target.innerText === 'CANCEL') {
      return true;
    } else {
      return false;
    }
  };



  useEffect(() => {
    
    window.addEventListener("click", onWindowClick);
    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, [onWindowClick]);


  useEffect(() => {
   setSelectedTaskIds(selectedTask);
   setSelectedCommId(selectedCommIds);
  }, [selectedTask]);


  const onClickRow = (e : any, id: string , index: number, row: any) => {
    if (e.defaultPrevented) {
      return;
    }
    //primmary button = left click
    if (e.button !== PRIMARY_BUTTON_NUMBER) {
      return;
    }
    // marking the event as used
    e.preventDefault();
    performAction(e, {id, index}, row);
  };

  /**
   * Perform action
   */
  const performAction = async (e: any, record: any, row: any) => {

    //use command in mac or control in windows
    let selectedIndex;
    let selectedIds;
      if (wasToggleInSelectionGroupKeyUsed(e)) {
        selectedIndex = toggleSelectionInGroup(record.index, selectedTaskIds);
        selectedIds = toggleSelectionInGroup(record.id, selectedCommId);
        setSelectedTaskIds(selectedIndex);
        setSelectedCommId(selectedIds);
      } else {
        selectedIndex = toggleSelection(record.index, selectedTaskIds);
        selectedIds = toggleSelection(record.id, selectedCommId);
        setSelectedTaskIds(selectedIndex);
        setSelectedCommId(selectedIds);
      }

      props.onClick(
        e,
        record.index,
        row.itemType,
        row.id,
        row.title,
        +row.numberOfInputFiles,
        +row.numberOfApproval,
        row.description,
        row,
        props.id,
        selectedIndex,
        selectedIds
      );
  };


  
  const renderItem = (row: any, i: number) => {
    return (
      <FolderContainer
        onClick={(e) =>
          props.onClick(
            e,
            i,
            row.itemType,
            pathname.includes('/enterprise') ? row.lastVersionId : row.id,
            row.title,
            +row.numberOfInputFiles,
            +row.numberOfApproval,
            row.description,
            row,
            props.id
          )
        }
        onContextMenu={(e) =>
          props.onContextMenu(e, row, i, row.itemType, props.id)
        }
        // isActive={props.isActiveFolder === i}
        isList={true}
      >
        <WrapList isActive={props.isActiveFolder === i}
        >
          {/* 
            // KPD-1527 hide feature checkbox and setting in list view
            {props.isActiveFolder === i && (
            <ActionButtonList isShowButton={true}>
              <Checkbox size="small" />
              <IconButton style={{ color: '#9A9DB3' }} component="span">
                <SettingsOutlinedIcon fontSize="small" />
              </IconButton>
            </ActionButtonList>
          )} */}
          {row.itemType === 'CALCULATION' ? (
            <img src={images.tableIconFile} alt="img" />
          ) : row.itemType === 'FOLDER' ? (
            <img src={images.tableIconFolder} alt="img" />
          ) : row.itemType === 'FILE' ? (
            <img src={images.tableIconFile} alt="img" />
          ) : null}
        </WrapList>
      </FolderContainer>
    );
  };

  

  return (
    <DragDropContext 
    onBeforeCapture={onBeforeCapture}
     onDragEnd={handlerDragEnd}
     >
      <Droppable droppableId="container" isDropDisabled>
        {(provided) => (
          <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
            <Table sx={styledTable}>
              <TableHead>
                <TableRow sx={styledHeaderTable}>
                  <TableCell colSpan={2}>
                    <Text variant="h5" color={theme.customText.low}>
                      Name
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="h5" color={theme.customText.low}>
                      Originator
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text variant="h5" color={theme.customText.low}>
                      Last Modified
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              {props.data.map((row: any, i: number) => (
                <>
                  {row.itemType === 'FOLDER' && (
                    <Droppable droppableId={`${row.itemType}-${row.id}-${row.title}-${row.type}`}>
                      {(provided) => (
                        <TableBody>
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            key={`${i}_${row.title}`}
                            sx={
                              props.isActiveFolder === i
                                ? styledCellTable
                                : cellStyle
                            }
                            onClick={(e) =>
                              handleActiveFocusFolder(e, i, row.id, row,)
                            }
                            onDoubleClick={() => props.onDoubleClick(row)}
                            onContextMenu={(e) =>
                              props.onContextMenu(e, row, i, row.itemType, props.id)}
                          >

                            <TableCell>{renderItem(row, i)}</TableCell>
                            <TableCell component="th" scope="row">
                              <Text variant="h5" color={theme.customText.high} sx={{ cursor: 'default' }}>
                                {row.title}
                              </Text>
                            </TableCell>
                            <TableCell>{row.createdBy?.name}</TableCell>
                            <TableCell>
                              {row.updatedAt === null
                                ? '-'
                                : localDatetimeTable(row.updatedAt)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Droppable>
                  )}
                  {row.itemType !== 'FOLDER' && (
                    <Droppable droppableId={`${row.itemType}-${row.id}-${row.title}-${row.type}`}>
                      {(provided) => (
                        <>
                          <TableBody
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            <Draggable
                              key={`${row.itemType}-${row.id}-${row.title}-${i}`}
                              draggableId={`${row.itemType}-${row.id}-${row.title}-${i}`}
                              index={i}
                              isDragDisabled={!isAllowDrag}
                            >
                              {(provided, snapshot) => (
                                <>
                                  <TableRow
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    key={`${i}_${row.title}`}
                                    sx={
                                      snapshot.isDragging
                                        ? {
                                          ...styledCellTable,
                                          backgroundColor: 'transparent',
                                        }
                                        : cellStyle
                                    }
                                    selected={isSelected(row.id, selectedCommId)}
                                    onClick={(event) => onClickRow(event, row.id, i, row)}
                                    onDoubleClick={() => props.onDoubleClick(row)}
                                    onContextMenu={(e) =>
                                      props.onContextMenu(
                                        e,
                                        row,
                                        i,
                                        row.itemType,
                                        props.id
                                      )
                                    }
                                  >
                                    <TableCell
                                      sx={{
                                        backgroundColor: snapshot.isDragging  || props.isActiveFolder === i
                                          ? '#E3F3FF'
                                          : 'transparent',
                                      }}
                                    >
                                      {renderItem(row, i)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        backgroundColor: snapshot.isDragging || props.isActiveFolder === i
                                          ? '#E3F3FF'
                                          : 'transparent',
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      <Text
                                        variant="h5"
                                        color={theme.customText.high}
                                      >
                                        {row.title}
                                      </Text>
                                    </TableCell>
                                    {!snapshot.isDragging && (
                                      <TableCell sx={{
                                        backgroundColor: props.isActiveFolder === i
                                          ? '#E3F3FF'
                                          : 'transparent',
                                      }}>{row.createdBy?.name}</TableCell>
                                    )}
                                    {!snapshot.isDragging && (
                                      <TableCell sx={{
                                        backgroundColor: props.isActiveFolder === i
                                          ? '#E3F3FF'
                                          : 'transparent',
                                      }}> 
                                        {row.updatedAt === null
                                          ? '-'
                                          : localDatetimeTable(row.updatedAt)}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  {snapshot.isDragging && (
                                    <TableRow
                                      key={`${i}_${row.title}`}
                                      sx={
                                        props.isActiveFolder === i
                                          ? styledCellTable
                                          : cellStyle
                                      }
                                    >
                                      <TableCell>{renderItem(row, i)}</TableCell>
                                      <TableCell component="th" scope="row">
                                        <Text variant="h5" color={theme.customText.high} >
                                          {row.title}
                                        </Text>
                                      </TableCell>
                                      <TableCell>{row.createdBy?.name}</TableCell>
                                      <TableCell>
                                        {row.updatedAt === null ? '-' : localDatetimeTable(row.updatedAt)}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              )}
                            </Draggable>
                          </TableBody>
                        </>
                      )}
                    </Droppable>
                  )}
                </>
              ))}
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}
