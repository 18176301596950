import { Box, Button, Pagination, useTheme } from "@mui/material";
import TypographyComponent from "../../components/atoms/Typography";
import {
  buttonStyle,
  boxContainerStyle,
  boxInnerStyle,
  boxInputStyle,
  boxHeader,
  boxButtonContainer,
} from "../../pageElements/accountManager";
import { ADD_COMPANY } from "../../utils/constant";
import Icon from "../../components/atoms/Icon";
import { InputText, SearchWrapper, useStyles } from "./styles";

export default function ContentHeaderSection(props: any) {
  const theme = useTheme();
  const {
    value,
    searchAccount,
    page,
    totalPage,
    handlePageChange,
    imageOnClick,
    handleAddUser,
    size,
    data,
    total,
    handleAddCompany,
  } = props;

  const colorProps = {
    next: page === 1 ? '#D1D9E0' : '#52667A',
    previous: page === props.totalPage ? '#D1D9E0' : '#52667A'
  }
  const classes = useStyles(colorProps);


  return (
    <>
      <Box sx={boxContainerStyle}>
        <TypographyComponent variant="HEADING_1">
          Account Manager
        </TypographyComponent>
        <Box sx={boxButtonContainer}>
          <Button
            sx={buttonStyle}
            variant="contained"
            onClick={() => {
              handleAddCompany();
            }}
          >
            {ADD_COMPANY}
          </Button>
          <Button
            sx={buttonStyle}
            variant="contained"
            onClick={() => {
              handleAddUser();
            }}
          >
            Add user
          </Button>
        </Box>
      </Box>
      <Box sx={boxInnerStyle}>
        <Box sx={boxInputStyle}>
          <InputText
            type="text"
            placeholder="Search name, email"
            value={value}
            onChange={searchAccount}
          />
          <SearchWrapper>
            <Icon icon="search" color={theme.customText.icon_high} onClick={() => imageOnClick()} />
          </SearchWrapper>
        </Box>
        <Box sx={boxHeader}>
          <TypographyComponent
            variant={"SUBTITLE_2"}
            style={{ paddingRight: "15px" }}
          >
            Showing{" "}
            {data.length === 0 ? 0 : page === 1 ? page : (page - 1) * size + 1}{" "}
            - {size > data.length ? total : size * page}
          </TypographyComponent>
          <Pagination
            color="primary"
            variant="outlined"
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            classes={classes}
          />
        </Box>
      </Box>
    </>
  );
}
