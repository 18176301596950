import { Badge, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import Icon from '../../atoms/Icon';
import { ComponentWrapper, StyledMenu } from './styles';

const WorkpageItem = React.forwardRef(function DragableWorkpage(
  props: any,
  ref: React.ForwardedRef<any>
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopy = () => {
    if (props.handleCopy) {
      props.handleCopy();
      handleClose();
    };
  };
  const handleDelete = () => {
    if (props.handleDelete) {
      props.handleDelete();
      handleClose();
    };
  };
  return (
    <ComponentWrapper
      ref={ref}
      {...props.provided?.draggableProps}
      showMenu={Boolean(anchorEl || props.isDragging)}
    >
      <Icon
        icon="drag"
        className="dragButton"
        provided={props.provided}
        onClick={props.onClickHandler}
      />
      {/**
       * render composer workpage input
       */}
      <Badge
        badgeContent={props.isDragging ? props.totalActiveComponent : 0}
        color="primary"
        sx={{ flex: 1 }}
      >
        {props.children}
      </Badge>
      <Icon
        icon="moreHorizontal"
        className="menuButton"
        data-testid="btnHamburger"
        onClick={handleClick}
      />

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        PaperProps={{
          style: {
            transform: 'translateX(40%)'
          }
        }}
      >
        <MenuItem onClick={handleCopy}>
          <Icon icon="borderCopy" data-testid="btnDuplicate" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Icon icon="borderDelete" data-testid="btnDelete" />
        </MenuItem>
      </StyledMenu>
    </ComponentWrapper>
  );
});

export default WorkpageItem;
