import { styled } from "@mui/material";


interface ChatWrapperPropsI {
  isHover: boolean;
  idx: number;
}

export const InfoPanelContainer = styled('div')({
  width: 240,
  padding: 24,
  display: 'flex',
  flexDirection: 'column'
});

export const ChatHistoriesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DiclaimerContent = styled('div')({
  padding: 16,
  borderRadius: 12,
  backgroundColor: '#F0F2F5',
  position: 'absolute',
  marginRight: 24,
  bottom: 24

});

export const ChatWrapper = styled('div')<ChatWrapperPropsI>(({ isHover, idx }) => ({
  padding: 6,
  backgroundColor: isHover ? '#E3F3FF' : '#fff',
  borderRadius: 6,
  marginTop: idx === 0 ? 24 : 12,
}));

export const HistoryHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: "12px 16px 0px 16px",
});

