//TODO: determine which function could be here
import { Menu } from '@mui/material';
import { MenuBox } from '../../../pageElements/composer';
import { contentInput } from '../../../utils/constant';
import { IInputMenu } from './input-menu.type';

export const InputMenu = ({
  open,
  anchorPosition,
  handlerShowInputComponent,
  handlerComponent,
  dataProperties
}: IInputMenu) => (
  <Menu
    open={open}
    onClose={() => handlerShowInputComponent('close')}
    anchorEl={anchorPosition}
    MenuListProps={{
      disablePadding: true
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
        mt: 0.5
      }
    }}
  >
    <MenuBox>
      {contentInput?.map((value: any, index: number) => (
        <div
          key={`input_menu_box_${index}`}
          onClick={() => {
            handlerComponent(
              'SWITCH_INPUT',
              dataProperties.idComponent,
              dataProperties.indexComponent,
              value.type
            );
          }}
        >
          {value.label}
        </div>
      ))}
    </MenuBox>
  </Menu>
);
