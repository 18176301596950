import { ModalInfo } from '../../../../components/molecules/modal/modalInfo';
import React from 'react';
import { Typography } from '@mui/material';

interface IDisclaimerModal {
  modalVisible: boolean;
  onCloseModal: () => void;
  isMobile?: boolean;
}

export const DisclaimerModal = ({
  modalVisible,
  onCloseModal,
  isMobile
}: IDisclaimerModal) => {
  return (
    <ModalInfo
      modalVisible={modalVisible}
      onCloseModal={onCloseModal}
      isMobile={isMobile}
      title={
        <Typography variant="h5" paragraph={true}>
          Disclaimer
        </Typography>
      }
      body={
        <>
          <Typography variant="body3" paragraph={true}>
            This product is an alpha release and is still in the early stages of
            development. It may contain bugs, errors, or other issues that need
            to be resolved. This product is not yet ready for widespread use and
            should only be used by those who understand the risks involved.
          </Typography>

          <Typography variant="body3" paragraph={true}>
            By using this Large Language Mode feature (Ask Ace), you agree that
            we do not guarantee the performance or reliability of this product
            and assume no responsibility for any damages or losses that may
            result from its use.
          </Typography>
        </>
      }
    />
  );
};
