import { useTheme } from "@mui/material";
import React from "react";

import ComposerAtoms from ".";
import Icon from "../Icon";

import { VMCardSection, VMCardText, VMCardWrapper } from "./styles";

import { VMCardPropsI } from "./types";

const VMCard = ({currentFocus, expandTableCellEditing, id, description, unit, variable, notation, pageIndex, tooltip, navigating, type, onMouseDown, onClick, navigateClick}: VMCardPropsI) => {
 const isTable: boolean = type === 'TABLE';
 const theme = useTheme();
  return (
    <VMCardWrapper
      currentFocus={currentFocus}
      expandTableCellEditing={expandTableCellEditing}
      onMouseDown={onMouseDown}
      onClick={onClick}
    >
      <VMCardSection>
        <VMCardText position='left'>
          {isTable ? 'Calculations' : description}
        </VMCardText>
        <VMCardText position={isTable ? 'rightIcon' : 'right'}>
          {isTable ? <Icon icon='table' color={theme.customText.icon_high} /> : unit} 
        </VMCardText>
      </VMCardSection>
      <VMCardSection>
        <VMCardText position='foot'>
          {!isTable && variable + ','} {notation}
        </VMCardText>
        {
          ( tooltip && navigating ) && (
            <ComposerAtoms.NavigatingTooltip
              id={id}
              pageIndex={pageIndex}
              navigateClick={navigateClick}
            />
          )
        }
      </VMCardSection>
    </VMCardWrapper>
  );
};

export default VMCard;