import {
  DividerInfoPanel,
  InfoPanelWrapper,
} from "../molecules/infoPanel/elements";
import {
  getActorName,
  getDate,
  getLabel,
  getValue,
} from "../molecules/infoPanel/utils";
import {
  showHideModalAction,
  submitLoading,
} from "../../store/appState/global/globalActions";

import InfoPanelActionSection from "../molecules/infoPanel/components/InfoPanelActionSection";
import InfoPanelDetailSection from "../molecules/infoPanel/components/InfoPanelDetailSection";
import InfoPanelHeadline from "../molecules/infoPanel/components/InfoPanelHeadline";
import Service from "../../service/generic/generic";
import { formModalSetupActionPopUp } from "../../store/appState/popUp/formModal/formModalAction";
import { toastSetupActionPopUp } from "../../store/appState/popUp/toast/toastAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { getUserId } from '../../utils/storage';

type StateShow = {
  [key: string]: boolean;
};

interface IProps {
  calculation: any;
  info: any;
  isDriveCalculation: boolean;
}
export default function InputFileInfoPanel(props: IProps) {
  const [isDetailShow, setIsDetailShow] = useState<StateShow>({
    details: true,
    actions: true,
    reports: true,
    calculationInfo: true,
  });
  const history = useHistory();
  const { calculation, info, isDriveCalculation } = props;
  const isUserInputFile = Boolean(getUserId() === info.createdBy);

  const handleToggleDetail = (name: string) => () => {
    setIsDetailShow((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const dispatch = useDispatch();
  const handleEdit = () => {};

  const gotoLogger = () => {
    if(!isUserInputFile && isDriveCalculation) {
      return dispatch(toastSetupActionPopUp("ERROR_TERTIARY", "You are not authorized to access this page"));
    }

    history.push({
      pathname: `/logger/${calculation.calculationId}/${info.id}`,
      state: {
        ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
      }
    });
  };

  const renameInputFileHandler = () => {
    const payload = {
      inputFileId: info.id,
      title: info.title,
      calculationId: calculation.calculationId
    };
    dispatch(formModalSetupActionPopUp("RENAME_INPUT_FILE", payload));
  };

  const deleteInputFileHandler = () => {
    const payload = {
      inputFileId: info.id,
      calculationId: calculation.calculationId
    };
    dispatch(formModalSetupActionPopUp("CONFIRMATION_DELETE_INPUT_FILE", payload));
  };

  const printToReportHandler = async () => {
    const newservice = new Service(`report`);
    try {
      dispatch(submitLoading(true));
      const { data: resPost } = await newservice.post({
        inputFileId: info.id,
      });
      dispatch(submitLoading(false));
      history.push({
        pathname: `/print-view/${calculation.calculationId}/${info.id}/${resPost.id}`,
        state: {
          ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
        }
      });
    } catch (error: any) {
      dispatch(submitLoading(false));
      // console.log(error.response.data);
      if (error.response.data?.status === 422) {
        dispatch(
          toastSetupActionPopUp(
            "INFO",
            "Log Progress Have Not Finish in the Input File"
          )
        );
      }
      return Promise.reject(error);
    }
  };

  const basicActions = [
    {
      color: "primary",
      variant: "contained",
      label: "Load Calculation",
      subLabel: "Go to Logger View",
      onClick: gotoLogger,
      dataTestid: "btnLoad",
    },
    {
      color: "primary",
      variant: "contained",
      label: "PRINT TO REPORT",
      subLabel: "Go to Print View",
      onClick: printToReportHandler,
      dataTestid: "btnPrintToReport",
      disabled: !isUserInputFile && isDriveCalculation
    },
    {
      color: "secondary",
      variant: "contained",
      label: "RENAME",
      onClick: renameInputFileHandler,
      dataTestid: "btnRename",
      disabled: !isUserInputFile && isDriveCalculation
    },
    // { color: "secondary", variant: "contained", label: "SHARE", dataTestid: "btnShare" },
    // { color: "secondary", variant: "contained", label: "PUBLISH", dataTestid: "btnPublish" },
    {
      color: "error",
      variant: "contained",
      label: "DELETE",
      onClick: deleteInputFileHandler,
      dataTestid: "btnDelete",
      disabled: !isUserInputFile && isDriveCalculation
    },
  ];

  const detailInfo = [
    // { key: "Input File", value: [getValue(info.title)] },
    // {
      // key: "Created Date",
      // value: [getDate(info.createdTime), getActorName(info.createdBy)], xx
    // },
    // {
    //   key: "Last Modified",
    //   value: [
    //     info.updatedAt ? getDate(info.updatedAt) : getDate(info.createdTime),
    //     info.updatedBy
    //       ? getActorName(info.createdBy)
    //       : getActorName(info.updatedBy),
    //   ],
    // },
    { key: "Completed", value: [getLabel(info.isComplete)] },
  ];

  const reportsInfo: Array<any> = [];
  if (info.reports) {
    info.reports.forEach((report: any) => {
      reportsInfo.push({
        key: getDate(report.printedAt).content,
        value: [getValue(report.title)],
      });
    });
  }

  const calculationInfo = [
    { key: "Originator", value: [getActorName(calculation.creator)] },
    // { key: "Principal", value: [getActorName(calculation.principal)] },
    {
      key: "Last Modified",
      value: [getDate(calculation.updatedTime), getActorName(calculation.updater)],
    },
  ];

  const infoTitle = {
    title: "Input Files Name",
    nameInfoTitle: info.title,
  };

  const detailInfoHead = {
    key: "Calculation Name",
    nameDetailInfo: calculation.title,
  };

  return (
    <div>
      <InfoPanelWrapper>
        <InfoPanelHeadline
          title="INPUT FILE INFO"
          onEdit={handleEdit}
          name={calculation.title}
          description={calculation.description}
          isEditable={false}
          nameTitle={infoTitle}
        />
        <DividerInfoPanel />
        <InfoPanelActionSection
          expanded={isDetailShow.actions}
          onToggle={handleToggleDetail("actions")}
          title="ACTIONS"
          actions={basicActions}
        />
        {/* <InfoPanelDetailSection
          expanded={isDetailShow.reports}
          onToggle={handleToggleDetail("reports")}
          title="REPORTS"
          info={reportsInfo}
        /> */}
        {/* <DividerInfoPanel/>
        <InfoPanelDetailSection
          expanded={isDetailShow.details}
          onToggle={handleToggleDetail("details")}
          title="DETAILS"
          info={detailInfo}
        /> */}
        <DividerInfoPanel />
        <InfoPanelDetailSection
          expanded={isDetailShow.calculationInfo}
          onToggle={handleToggleDetail("calculationInfo")}
          title="CALCULATION INFO"
          info={calculationInfo}
          detailInfoHead={detailInfoHead}
        />
      </InfoPanelWrapper>
    </div>
  );
}
