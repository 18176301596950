import { ContentDetailValueLabel } from "../elements";

interface IStatusLabelProps {
  type: boolean; // update to string if later has more than 2
  value: any;
}
const StatusLabel = (props: IStatusLabelProps) => {
  return (
    <ContentDetailValueLabel isBool={props.type}>{props.value}</ContentDetailValueLabel>
  );
};

export default StatusLabel;
