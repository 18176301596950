import {styled} from '@mui/material/styles';
import {Button as MuiButton, ButtonProps} from '@mui/material';

const Button = styled(MuiButton)<ButtonProps>(({ color }) => ({
	fontSize: 12,
	lineHeight: 1.8,
	borderRadius: 6,
	fontWeight: 700,
	boxShadow: 'none',
	textTransform: 'uppercase',

	'&:hover': {
		boxShadow: 'none',
		backgroundColor: color === 'error' ? 'rgb(251, 229, 234)' : undefined,
	},
}));

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    ghost: true;
  }
}

export default function StyledButton({size, ...props}: ButtonProps) {
	return <Button size={size} {...props} />;
}

StyledButton.defaultProps = {
	size: "medium",
};