import {
  ShowHideModalActionI,
  ShowHideModalInfoI,
  ShowHideModalExplorerI,
  ChangeTypeViewI,
  CHANGE_TYPE_VIEW,
  SHOW_HIDE_MODAL_ACTION,
  SHOW_HIDE_MODAL_INFO,
  SHOW_HIDE_MODAL_EXPLORER,
} from '../types/calcMenuTypes';

export interface CalcMenuI {
  openModalAction: boolean;
  openModalInfo: boolean;
  openModalExplorer: boolean;
  messageInfo: string;
  typeAction: string;
  typeView: string;
}

const initialState: CalcMenuI = {
  openModalAction: false,
  openModalInfo: false,
  openModalExplorer: false,
  messageInfo: '',
  typeAction: '',
  typeView: 'Tiles',
};

type Action =
  | ShowHideModalActionI
  | ShowHideModalInfoI
  | ShowHideModalExplorerI
  | ChangeTypeViewI;

const calcMenuReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_HIDE_MODAL_ACTION:
      return {
        ...state,
        openModalAction: action.value,
        typeAction: action.typeAction,
      };
    case SHOW_HIDE_MODAL_EXPLORER:
      return {
        ...state,
        openModalExplorer: action.value,
        typeAction: action.typeAction,
      };
    case SHOW_HIDE_MODAL_INFO:
      return {
        ...state,
        openModalInfo: action.value,
        messageInfo: action.messageInfo,
      };
    case CHANGE_TYPE_VIEW:
      return {
        ...state,
        typeView: action.typeView,
      };
    default:
      return state;
  }
};

export default calcMenuReducer;
