import * as React from "react";
import { SVGProps } from "react";

const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
  >
    <rect 
      width="24" 
      height="24" 
      rx="4" 
      fill="#118CFF"
    />
    <path 
      d="M10.6318 16.578C10.3208 16.578 10.0128 16.46 9.77675 16.224L6.35475 12.802C5.88175 12.329 5.88175 11.563 6.35475 11.091C6.82675 10.619 7.59275 10.619 8.06475 11.091L10.6318 13.658L15.9358 8.354C16.4067 7.882 17.1728 7.882 17.6458 8.354C18.1178 8.827 18.1178 9.593 17.6458 10.065L11.4868 16.224C11.2508 16.46 10.9418 16.578 10.6318 16.578Z"
      fill="white" 
    />
  </svg>
);

export default Check;
