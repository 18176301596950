import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import SideBar from '../../components/molecules/sidebar';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import {
  CalculationContent,
  ChildWrapper
} from '../../components/organisms/hoc/elements';
import { BillingWrapper } from '../../components/molecules/BillingTable/elements';
import BillingTable from '../../components/molecules/BillingTable';
import {
  handleGetBillingDetails,
  changeBillingPaymentStatus
} from '../../store/actions/userAction';
import { ReduxState } from '../../store';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ContentHeaderSection from './contentHeaderSection';
import ContentFooterSection from './contentFooterSection';
import { LoadingSpinner } from '../../components/atoms/loading';
import { getUserProfile } from '../../utils/storage';
import { useHistory, useParams } from 'react-router-dom';
import { mappingBillingDetails } from '../../utils/helpersFunction';
import HelperButton from '../../components/molecules/HelperButton';

const BillingDetails = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userRole = getUserProfile();
  const { isMinimize, handleInActiveFocus } = props;
  const [errMessage, setErrMessage] = useState('');
  const [billingData, setBillingData] = useState([]);
  const [numSelected, setNumSelected] = useState<number>(0);
  const [selectedData, setSelectedData] = useState<any>([]);
  const { id } = useParams<{ id: string }>();

  const { billingMember, paymentStatus, isLoading } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  useEffect(() => {
    const checkNotChecker = /REKAVA_ADMIN/.test(String(userRole));
    if (!checkNotChecker) {
      history.push('/');
    }
  }, [billingData]);

  useEffect(() => {
    try {
      Promise.all([dispatch(handleGetBillingDetails(id))]).then(response => {
        const billing: any = response[0];
        setBillingData(mappingBillingDetails(billing?.data));
      });
    } catch (error: any) {
      console.error(error);
    }
  }, [id, paymentStatus, billingMember]);

  const basicHeader = [
    { label: 'Date', icon: 'NONE', width: 30 },
    { label: 'Email', icon: 'NONE', width: 50 },
    { label: 'Plan', icon: 'NONE', width: 40 },
    { label: 'Invoice Total', icon: 'NONE', width: 40 },
    { label: 'Payment Status', icon: 'NONE', width: 40 }
  ];

  return (
    <>
      <CalculationContent>
        <SideBar isMinimize={isMinimize} view={false} />
        <Divider orientation="vertical" flexItem />
        <BillingWrapper>
          <ChildWrapper onClick={handleInActiveFocus}>
            {isLoading && <LoadingSpinner />}
            <Box>
              <ContentHeaderSection />
              <BillingTable
                basicHeader={basicHeader}
                tableData={billingData}
                errMessage={errMessage}
                numSelected={numSelected}
                setNumSelected={setNumSelected}
                setSelectedData={setSelectedData}
              />
            </Box>
          </ChildWrapper>

          <Divider orientation="horizontal" flexItem />
          {numSelected > 0 ? (
            <ContentFooterSection
              numSelected={numSelected}
              selectedData={selectedData}
            />
          ) : null}
        </BillingWrapper>
      </CalculationContent>
      <HelperButton numBillingSelected={numSelected} />
    </>
  );
};

export default calculationsNav(BillingDetails);
