// DELETE FILE
export const LOADING_DELETE_FILE = 'LOADING_DELETE_FILE';
export const FAILED_DELETE_FILE = 'FAILED_DELETE_FILE';
export const SUCCESS_DELETE_FILE = 'SUCCESS_DELETE_FILE';
// RENAME FILE
export const LOADING_RENAME_FILE = 'LOADING_RENAME_FILE';
export const FAILED_RENAME_FILE = 'FAILED_RENAME_FILE';
export const SUCCESS_RENAME_FILE = 'SUCCESS_RENAME_FILE';
// MOVE FILE
export const LOADING_MOVE_FILE = 'LOADING_MOVE_FILE';
export const FAILED_MOVE_FILE = 'FAILED_MOVE_FILE';
export const SUCCESS_MOVE_FILE = 'SUCCESS_MOVE_FILE';

export interface ILoadingDeleteFile {
  type: typeof LOADING_DELETE_FILE;
  isLoading: boolean;
}

export interface IFailedDeleteFile {
  type: typeof FAILED_DELETE_FILE;
  messageError: string;
}

export interface ISuccessDeleteFile {
  type: typeof SUCCESS_DELETE_FILE;
  messageSuccess: string;
}

export interface ILoadingRenameFile {
  type: typeof LOADING_RENAME_FILE;
  isLoading: boolean;
}

export interface IFailedRenameFile {
  type: typeof FAILED_RENAME_FILE;
  messageError: string;
}

export interface ISuccessRenameFile {
  type: typeof SUCCESS_RENAME_FILE;
  messageSuccess: string;
}

export interface ILoadingMoveFile {
  type: typeof LOADING_MOVE_FILE;
  isLoading: boolean;
}

export interface IFailedMoveFile {
  type: typeof FAILED_MOVE_FILE;
  messageError: string;
}

export interface ISuccessMoveFile {
  type: typeof SUCCESS_MOVE_FILE;
  messageSuccess: string;
}
