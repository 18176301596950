import React from 'react';

import ExpandIcon from './ExpandIcon';
import { ActionsWrapper, Content } from '../elements';
import Button from '../../../atoms/Button';
import Text from '../../../atoms/Text';

interface IActions {
  color: any;
  label: string;
  subLabel?: string;
  variant: any;
  onClick?: () => void;
  disabled?: boolean;
  dataTestid?: string;
}
interface PropsI {
  title: string;
  actions: Array<IActions>;
  expanded: boolean;
  onToggle: () => void;

}

const InfoPanelActionSection = ({
  title,
  expanded,
  onToggle,
  actions,
}: PropsI) => {
  return (
    <Content isExpanded={expanded}>
      <div onClick={onToggle}>
        <Text variant="h5">{title}</Text>
        <ExpandIcon expanded={expanded} style={{ marginTop: '-4px' }} />
      </div>
      {expanded && (
        <ActionsWrapper>
          {React.Children.toArray(
            actions.map((item) => {
              return (
                <Button
                  key={item?.label}
                  color={item.color}
                  variant={item.variant}
                  {...(item.onClick && { onClick: item.onClick })}
                  data-testid={item.dataTestid}
                  sx={{ flexDirection: 'column' }}
                  disabled={item.disabled}
                >
                  <Text variant="h5" color="inherit">{item.label}</Text>
                  {item.subLabel !== '' && (
                    <Text variant="smallRegular">{item.subLabel}</Text>
                  )}
                </Button>
              );
            })
          )}
        </ActionsWrapper>
      )}
    </Content>
  );
};

export default InfoPanelActionSection;
