import * as React from "react";
import { SVGProps } from "react";

const SvgRadioOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={22} height={22} rx={11} fill="#fff" />
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={11}
      stroke="#BFC9D9"
      strokeWidth={2}
    />
  </svg>
);

export default SvgRadioOff;
