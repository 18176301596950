import {
  ACTION_REPORT,
  CHANGE_TYPE_VIEW,
  ChangeTypeViewI,
  LOGGER_PREVIEW,
  LoggerPreviewI,
  SET_HELPER_PANEL,
  SET_WORKSPACE,
  SHOW_HIDE_MODAL_ACTION,
  SHOW_HIDE_MODAL_EXPLORER,
  SHOW_HIDE_MODAL_INFO,
  SUBMIT_LOADING,
  SetActionReportI,
  SetHelperPanelI,
  SetWorkspaceI,
  ShowHideModalActionI,
  ShowHideModalExplorerI,
  ShowHideModalInfoI,
  SubmitLoadingI,
  GetTeamDrivesI,
  GET_TEAM_DRIVES,
  GetDriveItemsI,
  GET_DRIVE_ITEMS,
} from './globalTypes';

interface ActionReportPropsI {
  types: string;
  payload: any;
}

export enum HelperPanelType {
  PANEL = 'PANEL',
  TUTORIAL = 'TUTORIAL',
  SLA = 'SLA',
  SLA_REGISTER = 'SLA_REGISTER',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  CONVERT_ACE = 'CONVERT_ACE'
}

export interface GlobalMenuI {
  openModalAction: boolean;
  openModalInfo: boolean;
  openModalExplorer: boolean;
  messageInfo: string;
  typeAction: string;
  typeView: string;
  isLoadingSubmit: boolean;
  workspace: string;
  submitMessage: string;
  helperPanel: any;
  actionReport: ActionReportPropsI;
  loggerPreview: any;
  drives: any;
  driveItems: any
}

const initialState: GlobalMenuI = {
  openModalAction: false,
  openModalInfo: false,
  openModalExplorer: false,
  messageInfo: '',
  typeAction: '',
  typeView: 'Tiles',
  isLoadingSubmit: false,
  workspace: "",
  submitMessage: "",
  helperPanel: {
    isOpen: false,
    type: null,
    payload: []
  },
  actionReport: {
    types: "",
    payload: {},
  },
  loggerPreview: null,
  drives: [],
  driveItems: {}
};

type Action =
  | ShowHideModalActionI
  | ShowHideModalInfoI
  | ShowHideModalExplorerI
  | ChangeTypeViewI
  | SubmitLoadingI
  | SetWorkspaceI
  | SetHelperPanelI
  | SetActionReportI
  | LoggerPreviewI
  | GetTeamDrivesI
  | GetDriveItemsI;

const globalReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_HIDE_MODAL_ACTION:
      return {
        ...state,
        openModalAction: action.value,
        typeAction: action.typeAction,
      };
    case SHOW_HIDE_MODAL_EXPLORER:
      return {
        ...state,
        openModalExplorer: action.value,
        typeAction: action.typeAction,
      };
    case SHOW_HIDE_MODAL_INFO:
      return {
        ...state,
        openModalInfo: action.value,
        messageInfo: action.messageInfo,
      };
    case CHANGE_TYPE_VIEW:
      return {
        ...state,
        typeView: action.typeView,
      };
    case SUBMIT_LOADING:
      return {
        ...state,
        isLoadingSubmit: action.payload,
        submitMessage: action.payload === true ? "Saving..." : "All changes saved"
      };
    case SET_WORKSPACE:
      return {
        ...state,
        workspace: action.payload,
      };
    case SET_HELPER_PANEL:
      return {
        ...state,
        helperPanel: {
          isOpen: action.data.isOpen,
          type: action.data.type,
          payload: action.data.payload
        },
      };
    case ACTION_REPORT:
      return {
        ...state,
        actionReport: {
          types: action.types,
          payload: action.payload,
        },
      };

    case LOGGER_PREVIEW:
      return {
        ...state,
        loggerPreview: action.payload,
      };
    case GET_TEAM_DRIVES:
      return {
        ...state,
        drives: action.data,
      };
    case GET_DRIVE_ITEMS:
      return {
        ...state,
        driveItems: action.data,
      };
    default:
      return state;
  }
};

export default globalReducer;
