import { useEffect } from 'react';
import { Divider } from '@mui/material';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import {
  CalculationContent,
  ChildWrapper
} from '../../components/organisms/hoc/elements';
import SideBar from '../../components/molecules/sidebar';
import { BreadCrumbs } from '../../components/atoms/breadCrumbs';
import { LoadingSpinner } from '../../components/atoms/loading';
import { ContentSection } from '../../pageElements/calculations';
import GridView from '../../components/molecules/gridView';
import BasicTable from '../../components/molecules/paginatedTable';
import CalculationInfoPanel from '../../components/organisms/CalculationInfoPanel';
import HelperButton from '../../components/molecules/HelperButton';

const EnterpriseCalculations = (props: any) => {
  const {
    login,
    encal,
    breadCrumbsData,
    isMinimize,
    styleView,
    isActiveFolder,
    handleActiveFocus,
    handleContextMenu,
    renderEnterPriseCalculationMenuContext,
    global,
    activeItem,
    calInfo,
    handleModal,
    handleInActiveFocus,
    fetchResources,
    openPanel
  } = props;
  const { isLoading, data } = encal;
  const { personalWorkspaceId, enterpriseWorkspaceId } = login;
  const { typeView } = global;

  useEffect(() => {
    const unsubscribe = fetchResources(enterpriseWorkspaceId);
    return unsubscribe;
  }, [enterpriseWorkspaceId]);

  const handleDoubleClickItem = (e: any) => {};

  return (
    <>
      <CalculationContent>
        <SideBar isMinimize={isMinimize} />
        <Divider orientation="vertical" flexItem />
        <ChildWrapper onClick={handleInActiveFocus}>
          <BreadCrumbs locations={breadCrumbsData} />
          {isLoading && <LoadingSpinner />}
          {renderEnterPriseCalculationMenuContext()}
          <ContentSection onClick={handleInActiveFocus}>
            <GridView
              data={data}
              typeView={typeView}
              styleView={styleView}
              isActiveFolder={isActiveFolder}
              handleActiveFocus={handleActiveFocus}
              handleContextMenu={handleContextMenu}
              handleDoubleClickItem={handleDoubleClickItem}
              id={enterpriseWorkspaceId}
            />
            {styleView === 'List' && (
              <BasicTable
                isActiveFolder={isActiveFolder}
                data={data}
                onClick={handleActiveFocus}
                onContextMenu={handleContextMenu}
                onDoubleClick={handleDoubleClickItem}
                id={enterpriseWorkspaceId}
              />
            )}
          </ContentSection>
        </ChildWrapper>

        {activeItem?.id && calInfo?.calName && openPanel && (
          <CalculationInfoPanel
            activeItem={activeItem}
            calInfo={calInfo}
            personalWorkspaceId={personalWorkspaceId}
            handleModal={handleModal}
            {...props}
          />
        )}
      </CalculationContent>
      <HelperButton />
    </>
  );
};

export default calculationsNav(EnterpriseCalculations);
