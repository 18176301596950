import { ComponentType, Fragment, PureComponent } from 'react';
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  ExploreOutlined,
  FolderOutlined,
  HelpOutline,
  PersonOutline
} from '@mui/icons-material';

import { CalculationContent } from './elements';
import Icon from '../../atoms/Icon';
import InputFileMenu from '../../molecules/inputFileMenu';
import StyledList from '../../atoms/list';
import { getUserProfile } from '../../../utils/storage';

type NavState = {
  pathName: string;
  isMinimize: boolean;
  isHaveDrive: boolean;
};

const inputFileNav = (ChildComponent: ComponentType<any>) => {
  const styleView = 'LIST';

  const userRole = getUserProfile();
  const checkNotChecker = /REKAVA_ADMIN|COMPOSER_ADMIN/.test(String(userRole));

  return class Wrapper extends PureComponent<any, NavState> {
    constructor(props: any) {
      super(props);
      this.state = {
        pathName: window.location.pathname,
        isMinimize: false,
        isHaveDrive: false
      };
    }

    handleSetActiveTabs = (params: string) => () => {
      this.props.history.push(`/calculations/${params}`);
    };

    handleMinimizeSidebar = () => {
      this.setState({
        isMinimize: !this.state.isMinimize
      });
    };

    renderSideBar = () => (
      <div style={{ minWidth: !this.state.isMinimize ? 270 : 73 }}>
        <StyledList>
          {checkNotChecker && (
            <ListItem
              disablePadding
              selected={this.state.pathName.includes('accountManager')}
              onClick={this.handleSetActiveTabs('accountManager')}
            >
              <ListItemButton>
                <ListItemIcon>
                  <PersonOutline />
                </ListItemIcon>
                {this.state.isMinimize ? (
                  ''
                ) : (
                  <ListItemText primary="Account Manager" />
                )}
              </ListItemButton>
            </ListItem>
          )}

          {/* <ListItem
            disablePadding
            selected={this.state.pathName.includes("enterprise")}
            onClick={this.handleSetActiveTabs("enterprise")}
            data-testid="menuEnterpriseCalculation"
          >
            <ListItemButton>
              <ListItemIcon>
                <FolderOutlined />
              </ListItemIcon>
              {!this.state.isMinimize && (
                <ListItemText primary="Enterprise Calculation" />
              )}
            </ListItemButton>
          </ListItem> */}
          <ListItem
            disablePadding
            selected={this.state.pathName.includes('my-calculation')}
            onClick={this.handleSetActiveTabs('my-calculation')}
            data-testid="menuMyCalculation"
          >
            <ListItemButton>
              <ListItemIcon>
                <FolderOutlined />
              </ListItemIcon>
              {!this.state.isMinimize && (
                <ListItemText primary="My Calculations" />
              )}
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            selected={this.state.pathName.includes('community')}
            onClick={this.handleSetActiveTabs('community')}
            data-testid="menuCommunityCalculation"
          >
            <ListItemButton>
              <ListItemIcon>
                <ExploreOutlined />
              </ListItemIcon>
              {this.state.isMinimize ? (
                ''
              ) : (
                <ListItemText primary="Community Calculations" />
              )}
            </ListItemButton>
          </ListItem>
          {this.state.isHaveDrive && <ListItem
            disablePadding
            selected={this.state.pathName.includes('businessDrives')}
            onClick={this.handleSetActiveTabs('businessDrives')}
            data-testid="businessDrivesPage"
          >
            <ListItemButton>
              <ListItemIcon>
                <Icon icon="driveOutlined" color="currentColor" />
              </ListItemIcon>
              {!this.state.isMinimize && <ListItemText primary="Business Drive" />}
            </ListItemButton>
          </ListItem>}
          <ListItem
            disablePadding
            selected={this.state.pathName.includes('helpPage')}
            onClick={this.handleSetActiveTabs('helpPage')}
            data-testid="menuHelpPage"
          >
            <ListItemButton>
              <ListItemIcon>
                <HelpOutline />
              </ListItemIcon>
              {!this.state.isMinimize && <ListItemText primary="Help" />}
            </ListItemButton>
          </ListItem>
        </StyledList>
      </div>
    );

    handleSetDriveStatus = (isHaveDrive: boolean) => this.setState({ isHaveDrive });

    render() {
      const { ...restProps } = this.props;
      return (
        <Fragment>
          <InputFileMenu
            styleViewEnabled={false}
            styleView={styleView}
            // handleSelectStyle={() => {}}
            handleMinimizeSidebar={this.handleMinimizeSidebar}
            setDriveStatus={this.handleSetDriveStatus}
            {...restProps}
          />

          <CalculationContent>
            {this.renderSideBar()}
            <Divider orientation="vertical" flexItem />
            <ChildComponent />
          </CalculationContent>
        </Fragment>
      );
    }
  };
};

export default inputFileNav;
