import { FC, useEffect, useState } from 'react';
import { roleObj } from '../../../utils/constant';
import { getUserProfile } from '../../../utils/storage';
import NavBar from '../../molecules/navbar';
import { Body } from './elements';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useLocation } from 'react-router-dom';
import {
  reducerModifierDataBodyOfCalculationActionComposer,
  reducerModifierDataPropertiesActionComposer
} from '../../../store/composer/composerPage/composerActions';
import { getDataBodyOfCalculationComposer } from '../../../utils/reduxOutside';
import { inputSwitchActionMenuComposer } from '../../../store/composer/composerMenu/composerMenuActions';

const Layout: FC = ({ children }) => {
  const location = useLocation();
  const userRole = getUserProfile();
  const [currentPict, setCurrentPict] = useState('');
  const dispatch = useDispatch();

  const { dataProperties } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  const { userPhoto } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  useEffect(() => {
    setCurrentPict(userPhoto);
  }, [userPhoto]);

  useEffect(() => {
    const DataBodyOfCalculationComposer = getDataBodyOfCalculationComposer();
    if (
      !location.pathname.includes('composer') &&
      DataBodyOfCalculationComposer.length > 0
    ) {
      dispatch(inputSwitchActionMenuComposer('reset'));
      dispatch(reducerModifierDataBodyOfCalculationActionComposer([]));
      dispatch(
        reducerModifierDataPropertiesActionComposer({
          ...dataProperties,
          showProperties: false
        })
      );
    }
  }, [location]);

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <NavBar userRole={roleObj[String(userRole)]} {...{ currentPict }} />
      <Body>{children}</Body>
    </div>
  );
};

export default Layout;
