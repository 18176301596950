import { Item, ListItems } from "./styles";
import parser from "html-react-parser";
import { ListProps } from "../../logger.types";

const List = ({items, keyValue}: ListProps) => {
    return (
        <ListItems>
            {items.map((item: any) => {
            return (
                <Item key={item.id}>
                  {item.id}. {item[keyValue] ? parser(item[keyValue]): item[keyValue]}
                </Item>
              );
          })}
        </ListItems>
    );
};

export default List;