export const SHOW_HIDE_MODAL_ACTION = 'SHOW_HIDE_MODAL_ACTION';
export const SHOW_HIDE_MODAL_INFO = 'SHOW_HIDE_MODAL_INFO';
export const SHOW_HIDE_MODAL_EXPLORER = 'SHOW_HIDE_MODAL_EXPLORER';
export const CHANGE_TYPE_VIEW = 'CHANGE_TYPE_VIEW';
export const SUBMIT_LOADING = 'SUBMIT_LOADING';
export const SET_WORKSPACE = 'SET_WORKSPACE';
export const SET_BREADCUMBS = 'SET_BREADCUMBS';
export const SET_HELPER_PANEL = 'SET_HELPER_PANEL';
export const ACTION_REPORT = "ACTION_REPORT";
export const LOGGER_PREVIEW = 'LOGGER_PREVIEW';
export const ROUTE_HISTORY = 'ROUTE_HISTORY';
export const GET_TEAM_DRIVES = 'GET_TEAM_DRIVES';
export const GET_DRIVE_ITEMS = 'GET_DRIVE_ITEMS';

export interface ShowHideModalActionI {
  type: typeof SHOW_HIDE_MODAL_ACTION;
  value: boolean;
  typeAction: string;
}

export interface ShowHideModalInfoI {
  type: typeof SHOW_HIDE_MODAL_INFO;
  value: boolean;
  messageInfo: string;
}

export interface ShowHideModalExplorerI {
  type: typeof SHOW_HIDE_MODAL_EXPLORER;
  value: boolean;
  typeAction: string;
}

export interface ChangeTypeViewI {
  type: typeof CHANGE_TYPE_VIEW;
  typeView: string;
}

export interface SubmitLoadingI {
  type: typeof SUBMIT_LOADING;
  payload: boolean;
}

export interface SetWorkspaceI {
  type: typeof SET_WORKSPACE;
  payload: string;
}

export interface setBreadcumbsI {
  type: typeof SET_BREADCUMBS;
  payload: any;
}

export interface SetHelperPanelI {
  type: typeof SET_HELPER_PANEL;
  data: any
}

export interface SetActionReportI {
  type: typeof ACTION_REPORT;
  types: string,
  payload: any
}

export interface LoggerPreviewI {
  type: typeof LOGGER_PREVIEW;
  payload: any;
}

export interface RouteHistoryI {
  type: typeof ROUTE_HISTORY;
  payload: string;
}

export interface GetTeamDrivesI {
  type: typeof GET_TEAM_DRIVES;
  data: any;
}

export interface GetDriveItemsI {
  type: typeof GET_DRIVE_ITEMS;
  data: any;
}