import { useCallback, useEffect, useState } from 'react'
import { getToken } from '../utils/storage';

export type SessionConnectHandler = (ev: Event) => any;
export type SessionMessageHandler = (ev: MessageEvent<any>) => any;
export type SessionDisconnectHandler = (ev: Event) => any;
export type SessionErrorHandler = (ev: Event) => any;

export interface Contextable {
  eventType: string,
  data?: string
}

export type ConnectFN = () => void;

export type SessionHook = [
  ConnectFN,
  <T extends Contextable>(args: T) => void,
  () => void,
  (sessionID: string) => void
];

export type PauseHandlerHook = [(fn: ConnectFN) => void, () => void];

export enum EventType {
  ANSWER_START = 'ANSWER_START',
  ANSWER_TEXT = 'ANSWER_TEXT',
  ANSWER_END = 'ANSWER_END',
  CLASSIFICATION = 'CLASSIFICATION',
  PING = 'PING',
  INIT_START = 'INIT_START', // initialize agent (may take up to 20 seconds)
  INIT_END = 'INIT_END',
  CHAT_HISTORY_ID = 'CHAT_HISTORY_ID',
}

export default function useAceStreaming(
  onOpen: SessionConnectHandler, // ASK ACE
  onMessage: SessionMessageHandler, // ANSWER_START, ANSWER_TEXT, ANSWER_END, CLASIFICATION
  onClose: SessionDisconnectHandler,
): SessionHook {
  const [session, setSession] = useState(null as unknown as WebSocket)
  const userToken = getToken();

  const updateOpenHandler = () => {
    if (!session) return;
    session.addEventListener('open', onOpen);
    return () => {
      session.removeEventListener('open', onOpen)
    }
  }

  const updateMessageHandler = () => {
    if (!session) return;
    session.addEventListener('message', onMessage);
    return () => {
      session.removeEventListener('message', onMessage)
    }
  }

  const updateCloseHandler = () => {
    if (!session) return;
    session.addEventListener('close', onClose);
    return () => {
      session.removeEventListener('close', onClose)
    }
  }

  useEffect(updateOpenHandler, [session, onOpen]);
  useEffect(updateMessageHandler, [session, onMessage]);
  useEffect(updateCloseHandler, [session, onClose]);

  const connect = useCallback(() => {
    const uri = `${process.env.REACT_APP_WEBSOCKET_API}/event/chatbot/answer?token=${userToken}`;
    const ws = new WebSocket(uri);
    setSession(ws);
  }, [])

  const sendMessage = <T extends Contextable>(args: T) => {
    session.send(JSON.stringify(args))
  }

  const close = useCallback(() => {
    if (!!session && session.readyState === session.OPEN) session.close()
  }, [session])

  const initializeAgent = useCallback((sessionId: string) => {
    if (!!session && session.readyState === session.OPEN) session.send(JSON.stringify({
      eventType: 'INIT',
      sessionId
    }))
  }, [session])

  return [connect, sendMessage, close, initializeAgent]
}
