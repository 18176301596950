import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { ReduxState } from '../store';

interface PrivateRouteProps extends RouteProps {
  component?: any;
  children?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, children, ...rest } = props;
  const { token } = useSelector((state: ReduxState) => state.login);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!token ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
