import { ComponentDimensionsI, PrintFormProps, PrintFormationI } from '../../print.types';
import {
  FieldText,
  InputFormulaLatex,
  InputImage,
  InputNumeric,
  InputText,
  LabelText,
  Link,
  Table,
} from '../../../../components/molecules/PrintView';

import { Divider } from '../../../../pageElements/printViewPage';
import { FormWrapper } from './styles';
import { LoadingSpinner } from '../../../../components/atoms/loading';
import { useTheme } from '@mui/material';
import { cellsFilter } from '../../utils';

const PrintForm = ({ data, showLatex, setComponentDimension, setTableDimension }: PrintFormProps) => {
  const theme = useTheme();
  const addDimension = (el: HTMLDivElement, columnId: string, type: string, index: number) => el && setComponentDimension({
    element: el,
    columnId: columnId,
    type: type,
    index: index,
    width: el.offsetWidth,
    height: el.offsetHeight,
    key: `component_${columnId}_0`
  });

  return (
    <>
      {!data ? (
        <LoadingSpinner />
      ) : (
        data.map((el: any) => {
          return (
            <>
              <FormWrapper>
                {Array.isArray(el.components) &&
                  el.components?.map((item: any, index: number) => {
                    const { columnId, type, properties } = item;

                    switch (type) {
                      case 'TEXT':
                        return <LabelText data={item} ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      case 'TEXT_INPUT':
                        return <InputText data={item} />;
                      case 'NUMERICAL_INPUT':
                      case 'CURRENCY_INPUT':
                      case 'DROPDOWN_INPUT':
                      case 'DATE_INPUT':
                      case 'RADIO_BUTTON_INPUT':
                        return <InputNumeric data={item} ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      case 'FIELD':
                        return <FieldText data={item} printType="PrintView" ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      case 'FORMULA':
                        return (
                          <InputFormulaLatex
                            index={index}
                            data={item}
                            columnId={columnId}
                            pages={data}
                            showLatex={showLatex}
                            multiLine={true}
                            theme={theme}
                            ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)}
                          />
                        );
                      case 'DIVIDER':
                        return <Divider ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      case 'IMAGE':
                        return <InputImage data={item} ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      case 'TABLE':
                        return <Table properties={cellsFilter(properties)} ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)}  columnId={columnId} setTableDimension={setTableDimension} isPrint={false} />;
                      case 'LINK':
                        return <Link properties={properties} ref={(el: HTMLDivElement) => addDimension(el, columnId, type, index)} />;
                      default:
                    }
                  })}
              </FormWrapper>
            </>
          );
        })
      )}
    </>
  );
};

export default PrintForm;
