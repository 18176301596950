import "jspreadsheet/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";

import jspreadsheet, { Worksheet } from "jspreadsheet";
import { useRef, useEffect, useState, useImperativeHandle, forwardRef } from "react";

interface PropsI {
  variableList: Array<Variable>;
  name: string;
}

const VariableSheet = (props: PropsI, ref: any) => {
  useImperativeHandle(ref, () => ({
    getDefinedNames: () => getDefinedNames(),
    getVariableList: () => getVariableList(),
    setVariableList: (variableList: Array<Variable>) => setVariableList(variableList),
    updateValue: (name: string, value: VariableContent) => updateValue(name, value)
  }));

  // list of exposed methods
  function setVariableList(variableList: Array<Variable>) {
    if (getDefinedNames()) {
      const data: Array<any> = [];
      const definedNames = variableList.map(variable => {
        const { cell, name, value } = variable;
  
        const cellName = getCellName(cell);
        if (cellName) data.push({ cellName, value });
  
        return { index: name.toUpperCase(), value: cell };
      });
      Object.keys(getDefinedNames()).forEach(name => {
        if (!definedNames.find(variable => variable.index == name)) {
          definedNames.push({ index: name, value: '' });
        }
      });
      worksheet[0].setDefinedNames(definedNames);
      
      const currentTotalRow = worksheet[0].getData().length;
      if (currentTotalRow < data.length) worksheet[0].insertRow(data.length - currentTotalRow);
      setData(data);
      setVariables(variableList);

      const variables: Record<string, number|string> = {};
      variableList.forEach(variable => {
        variables[variable.name.toUpperCase()] = variable.value;
      });
      
      return variables;
    }
  }

  function getVariableList() {
    const variables: Record<string, number|string> = {};
    variableList.forEach(variable => {
      variables[variable.name.toUpperCase()] = variable.value;
    });
    return variables;
  }

  function getDefinedNames() {
    if (worksheet) {
      return worksheet[0].getDefinedNames();
    }
  }

  function updateValue(name: string, value: VariableContent) {
    const variables = getDefinedNames();
    worksheet[0].setValue(getCellName(variables[name.toUpperCase()]), value);

    setVariableList(variableList.map(variable => {
      if (variable.name == name) variable.value = value;
      return variable;
    }));
  }

  const jssRef = useRef(null);

  const [ worksheet, setWorksheet ] = useState<any>();

  const [ data, setData ] = useState<Array<any>>([]);
  const [ variableList, setVariables ] = useState<Array<Variable>>([]);

  useEffect(() => {
    if (jssRef.current !== null) {
      setWorksheet(jspreadsheet(jssRef.current, getConfig()));
    }
  }, []);

  useEffect(() => {
    if (worksheet) setVariableValue();
  }, [worksheet, data]);

  function convertVariable(variableList: Array<Variable>) {
    const definedNames: Record<string, string> = {};
    const data: Array<any> = [];
    variableList.forEach(variable => {
      definedNames[variable.name.toUpperCase()] = variable.cell;

      const cellName = getCellName(variable.cell);
      data.push({ cellName, value: variable.value });
    });

    setData(data);
    setVariables(variableList);

    return definedNames;
  }

  function setVariableValue() {
    data.forEach(content => {
      const { cellName, value } = content;
      worksheet[0].setValue(cellName, value);
    });
  }

  function getCellName(cellDefinition: string) {
    return cellDefinition.split('!')[1];
  }

  function getConfig() {
    const options = {
      definedNames: convertVariable(props.variableList),
      worksheets: [{
        minDimensions: [1, props.variableList.length],
        worksheetName: props.name,
      } as Worksheet],
    };
    return options;
  }
 
  return (
    <div ref={jssRef} style={{display: 'none'}}/>
  );
};

export default forwardRef(VariableSheet);

type VariableContent = string|number;

type Variable = {
  cell: string;
  name: string;
  value: VariableContent;
};
