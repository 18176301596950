import { useEffect, useState } from "react";
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
import "./latex.css";
import { LatexVarWrapper } from "../LatexFormula/element";

interface PropsI {
  formula: string;
}

export default function LatexVar({
  formula,
}: PropsI) {
  const [latex, setLatex] = useState<string>("");

  return (
    <LatexVarWrapper>
      {
        latex !== 'error' ? (
          <TeX>{formula}</TeX>
        ) : (
          <TeX>{'Error'}</TeX>
        )
      }
    </LatexVarWrapper>
  );
}