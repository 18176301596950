import { Button } from '@mui/material';
import TypographyComponent from '../../../atoms/Typography';

export interface PropsI {
  color: any;
  label: string;
  subLabel?: string;
  variant: any;
  onClick?: () => void;
  disabled?: boolean;
  dataTestid?: string;
}
const InfoPanelButton = ({
  color,
  label,
  variant,
  onClick,
  disabled = false,
  dataTestid,
  subLabel = '',
}: PropsI) => {
  return (
    <Button
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestid}
      sx={{ flexDirection: 'column' }}
    >
      <TypographyComponent
        style={{ color: 'inherit', padding: 0 }}
        variant="HEADING_5"
      >
        {label}
      </TypographyComponent>
      {subLabel !== '' && (
        <TypographyComponent style={{ padding: 0 }} variant="SMALL_REGULAR">
          {subLabel}
        </TypographyComponent>
      )}
    </Button>
  );
};

export default InfoPanelButton;
