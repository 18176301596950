import styled from 'styled-components';

import images from '../../assets';

interface Props {
  status?: boolean;
}

export const Wrapper = styled.div({
  height: '100%',
});

export const Container = styled.div({
  backgroundAttachment: 'fixed',
  backgroundImage: `url(${images.errorBackground})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
});

export const Header = styled.div({
  position: 'absolute',
  paddingLeft: '64px',
  paddingTop: '24px',
});

export const Content = styled.div({
  display: 'table',
  height: '100%',
  top: 0,
  width: '100%',
});

export const MainContent = styled.div({
  display: 'table-cell',
  marginTop: '50px',
  textAlign: 'center',
  verticalAlign: 'middle',
  height: '100vh',
});

export const Title = styled.h1({
  fontSize: '120px',
  margin: 'unset',
});

export const Description = styled.p<Props>((props) => ({
  fontSize: !props.status ? '40px' : '20px',
  fontWeight: 700,
  marginBottom: '21px',
}));

export const ActionsWrapper = styled.div({
  flexDirection: 'row',
});

