import {
  ActionsContent,
  CheckboxSection,
  ChildCheckbox,
  ParentCheckbox,
  PrintArea,
  SideBarWrapper
} from '../../../../pageElements/printView';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { ComponentToPrint } from '../ComponentToPrint';
import InfoPanelButton from '../../../../components/molecules/infoPanel/components/InfoPanelButton';
import Input from '../../../../components/atoms/Input';
import { ReportSettingProps } from '../../print.types';
import { SideBar } from '../../../../pageElements/printView';
import { renameReportSchema } from '../../../../utils/validation';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkSubscriptionAndDate } from '../../../../utils/helpersFunction';
import ReportService from '../../../../service/ReportServices';

const ReportSetting = ({
  info,
  isLoadingSubmit,
  pages,
  printPages,
  reportFormat,
  handleSetPagesPrint,
  handleSetReportFormat,
  saveReportHandler,
  reportName,
  setReportName,
  subscription,
  userLimit,
  componentDimensionAll
}: ReportSettingProps) => {
  const dispatch = useDispatch();
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
  const {
    watch,
    clearErrors,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(renameReportSchema),
    reValidateMode: 'onSubmit'
  });

  const watchTitle = watch('title', false);
  const [allPages, setAllPages] = useState<boolean>(false);
  const [isReadyPrint, setIsReadyPrint] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (printPages.length !== pages.length) {
      setAllPages(false);
    } else if (printPages.length === pages.length) {
      setAllPages(true);
    }
  }, [printPages]);

  useEffect(() => {
    if (watchTitle) {
      clearErrors();
    }
  }, [watchTitle]);

  const handleChecked = (e: any) => {
    const { value, checked } = e.target;
    handleSetPagesPrint([...printPages, value]);
    if (!checked) {
      handleSetPagesPrint(printPages.filter(item => item !== value));
    }
  };

  const handleAllPages = (e: any) => {
    setAllPages(!allPages);
    handleSetPagesPrint(pages.map(li => li.pageAlias));
    if (allPages) {
      handleSetPagesPrint([]);
    }
  };

  const handleFormat = (e: any) => {
    const { value, checked } = e.target;
    handleSetReportFormat([...reportFormat, value]);
    if (!checked) {
      handleSetReportFormat(reportFormat.filter(item => item !== value));
    }
  };

  const submitForm = async (e: any) => {
    try {
      clearErrors();
      await handleRenameReport(e);
    } catch (error: any) {
      console.error(error);
    }
  };

  const componentRef = useRef<any>();

  const handlePrint = () => {
    if (isDriveCalculation) {
      printHandler();
      ReportService.reportDownloaded(info.id);
    } else {
      if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
        printHandler();
        ReportService.reportDownloaded(info.id);
      }
    }
  };

  const printHandler = useReactToPrint({
    content: () => componentRef.current
  });

  const printViewActions = [
    {
      color: 'secondary',
      variant: 'contained',
      label: 'SAVE REPORT',
      onClick: () =>
        Object.keys(errors).length === 0 ? handleSaveReport() : null,
      dataTestid: 'btnSaveReport'
    },
    {
      color: 'primary',
      variant: 'contained',
      label: 'PRINT',
      onClick: handlePrint,
      dataTestid: 'btnSaveAsPDF'
    }
  ];

  const handleSaveReport = () => {
    saveReportHandler();
  };

  const handleRenameReport = async (e: any) => {
    try {
      setReportName(e.title);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <SideBar>
      <SideBarWrapper>
        <h1>PRINT SETTINGS</h1>
        <h3>
          Report name<span style={{ color: 'red' }}>*</span>
        </h3>
        <Controller
          name="title"
          control={control}
          defaultValue={reportName}
          render={({ field }) => {
            return (
              <Input
                {...field}
                onBlur={handleSubmit(submitForm)}
                value={watch('title')}
                sx={{ mb: 4 }}
                type="text"
                inputProps={{ maxLength: 60, 'data-testid': 'inputReportName' }}
                variant="outlined"
                size="small"
                error={!!errors.title}
                helperText={!!errors.title && errors.title?.message}
                fullWidth
              />
            );
          }}
        />
        <p>
          Select pages to print<span style={{ color: 'red' }}>*</span>
        </p>
        <CheckboxSection>
          <ParentCheckbox>
            <Checkbox
              onChange={handleAllPages}
              inputProps={
                {
                  'aria-label': 'controlled',
                  'data-testid': 'chkEntireReport'
                } as any
              }
              checked={allPages}
            />
            <p>Entire Report</p>
          </ParentCheckbox>

          {React.Children.toArray(
            pages.map((item: any, i: number) => {
              return (
                <ChildCheckbox>
                  <Checkbox
                    value={item.pageAlias}
                    onChange={handleChecked}
                    inputProps={
                      {
                        'aria-label': 'controlled',
                        'data-testid': item.pageTitle
                      } as any
                    }
                    checked={printPages.includes(item.pageAlias)}
                  />
                  <p>{item.pageTitle}</p>
                </ChildCheckbox>
              );
            })
          )}
        </CheckboxSection>

        <p>Report Format</p>

        <CheckboxSection>
          {/* KPD-1999 Print View - Executive Summary - Hide Feature */}
          {/* <ParentCheckbox>
          <Checkbox
            value={"Executive Summary"}
            onChange={handleFormat}
            checked={reportFormat.includes("Executive Summary")}
            inputProps={{
              // @ts-ignore
              "data-testid": "chkExecutiveSummary",
            }}
          />
          <p>Executive Summary</p>
        </ParentCheckbox> */}
          <ParentCheckbox>
            <Checkbox
              value={'Latex Formulas'}
              onChange={handleFormat}
              checked={reportFormat.includes('Latex Formulas')}
              inputProps={
                {
                  'data-testid': 'chkLatexFormula'
                } as any
              }
            />
            <p>LaTex Formulas</p>
          </ParentCheckbox>
        </CheckboxSection>

        <ActionsContent>
          {React.Children.toArray(
            printViewActions.map(item => {
              return (
                <InfoPanelButton
                  color={item.color}
                  variant={item.variant}
                  label={item.label}
                  {...(item.onClick && { onClick: item.onClick })}
                  disabled={
                    (printPages.length === 0 && item.label !== 'BACK') ||
                    !isReadyPrint
                  }
                  dataTestid={item.dataTestid}
                />
              );
            })
          )}
        </ActionsContent>
        <PrintArea>
          <ComponentToPrint
            ref={componentRef}
            data={info}
            reportFormat={reportFormat}
            selectedPage={printPages}
            componentDimensionAll={componentDimensionAll}
            setReadyToPrint={(value: boolean) => setIsReadyPrint(value)}
          />
        </PrintArea>
      </SideBarWrapper>
    </SideBar>
  );
};

export default ReportSetting;
