import React from "react";
import { useTheme } from "@mui/material";

import Text from "../../../atoms/Text";
import RichTextInput from "../../RichText";
import { TwoValueWrapper, TwoValueDisabledInput } from './styles';

import { TwoValuePropsI } from "./type";

const TwoValue = ({leftValue, rightValue, rightIsRequired, rightPlaceholder, rightHandleSaveChange, ...divProps}: TwoValuePropsI) => {
  const theme = useTheme();

  return (
    <TwoValueWrapper>
      <TwoValueDisabledInput>
        <Text variant="body3" color={theme.customText.disable}>{leftValue}</Text>
      </TwoValueDisabledInput>
      <RichTextInput.RichTextToolbar 
        component='twoValueDescription' 
        source={rightValue} 
        isRequired={rightIsRequired} 
        placeholder={rightPlaceholder}
        handleSaveChange={rightHandleSaveChange}
      />
    </TwoValueWrapper>
  );
};

export default TwoValue;