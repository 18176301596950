import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getFolderTree,
  getTreeNodes,
  setSelectedTreeItem
} from '../../../store/appState/sidebar/action';
import {
  getCommunityWorkspaceId,
  getEnterpriseWorkspaceId,
  getPersonalWorkspaceId
} from '../../../utils/storage';
import StyledList from '../../atoms/list';
import TreeNode from './TreeNode';

interface IProps {
  children?: any;
  isMinimize: boolean;
  selectedItem: string;
  indentLevel: number;
  isLoading?: boolean;
}
const FolderTree = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const SESSION_NAME = 'last_tree_position';
  const lastTreePosition = sessionStorage.getItem(SESSION_NAME)?.toString();

  const { children, indentLevel, isMinimize, selectedItem, isLoading } = props;

  const setSelectedItem = (id: string) => {
    if (id === selectedItem) return;

    dispatch(setSelectedTreeItem(id));

    const tree = getTreeNodes();

    const treeMyCalculations = JSON.stringify(tree.folderTree.find((item: any) => item.title === 'My Calculations'));

    if (!findNode(tree.folderTree, id)) {
      dispatch(getFolderTree(id));
    }

    let params;

    if (id == getCommunityWorkspaceId()) {
      params = 'community';
      sessionStorage.setItem(SESSION_NAME, params);
    } else if (id == getEnterpriseWorkspaceId()) {
      params = 'enterprise';
      sessionStorage.setItem(SESSION_NAME, params);
    } else if (id == getPersonalWorkspaceId()) {
      params = 'my-calculation';
      sessionStorage.setItem(SESSION_NAME, params);
    } else if (id == 'account') {
      params = 'accountManager';
    } else if (id == 'askAce') {
      params = 'askAce';
    } else if (id == 'helpPage') {
      params = 'helpPage';
    } else if (id == 'businessDrives') {
      params = 'businessDrives';
    } else if (id == 'acePublic') {
      return history.push(`/ace/public`);
    } else if (id == 'aceEnterprise') {
      return history.push(`/ace/enterprise`);
    } else {
      if (window.location.pathname.includes('my-calculation') || lastTreePosition === 'my-calculation') {
        params = `my-calculation/${id}`;
      } else if (window.location.pathname.includes('community') || lastTreePosition === 'community') {
        params = `community/${id}`;
      }

      if (treeMyCalculations.includes(id)) {
        params = `my-calculation/${id}`;
      } else {
        params = `community/${id}`;
      }
    }
    if (params === 'helpPage') {
      history.push(`/ace/${params}`);
    } else {
      history.push(`/calculations/${params}`);
    }

  };

  // check if node already exists in tree
  function findNode(folderTree: any, id: string) {
    let found = false;
    folderTree.forEach((item: any) => {
      if (item.id == id) {
        found = item.children != null;
        return;
      }
      if (item.children) return findNode(item.children, id);
    });
    return found;
  }

  return (
    <StyledList
      disablePadding={true}
      style={{
        minWidth: '100%',
        width: 'max-content',
        pointerEvents: 'none'
      }}
    >
      {React.Children.toArray(
        children !== null && (
          children.map((child: any) => {
            return (
              <TreeNode
                item={child}
                indentLevel={indentLevel}
                isMinimize={isMinimize ?? false}
                isSelected={selectedItem == child.id}
                selectedItem={selectedItem}
                onClick={() => isLoading ? null : setSelectedItem(child.id)}
                dataTestid={child.dataTestid}
                isLoading={isLoading}
              />
            );
          })
        )
      )}
    </StyledList>
  );
};

export default FolderTree;
