import * as DOMPurify from 'dompurify';
import { CSSProperties } from 'react';
interface HTMLContentProps {
  text: string;
  style?: CSSProperties;
}

export default function HTMLContent({ text, ...restProps }: HTMLContentProps) {
  const mySafeHTML = DOMPurify.sanitize(text, {
    ALLOWED_ATTR: ['style']
  });

  return (
    <div dangerouslySetInnerHTML={{ __html: mySafeHTML }} {...restProps} />
  );
}
