const DEFAULT_POSITION_START = 0;
const DEFAULT_POSITION_END = 0;

export function indexToChar(index: number) {
  const ASCII_CODE_A = 65;

  const totalAlphabet = 26;
  const firstCharIndex = index % totalAlphabet;

  let char = String.fromCharCode(firstCharIndex + ASCII_CODE_A);
  if (index + 1 > totalAlphabet) {
    const secondCharIndex = index / totalAlphabet - 1;
    char = String.fromCharCode(secondCharIndex + ASCII_CODE_A) + char;
  }

  return char;
}

export function getCellName(col: number, row: number) {
  return indexToChar(col) + (row + 1);
}

export function getCaretPosition(element: any) {
  let pos = 0;
  const s = window.getSelection();
  if (s) {
    if (s.rangeCount !== 0) {
      const r = s.getRangeAt(0);
      const p = r.cloneRange();
      p.selectNodeContents(element);
      p.setEnd(r.endContainer, r.endOffset);
      pos = p.toString().length;
    }
  }
  return pos;
}

export function getSelectedPos() {
  const s = window.getSelection();

  let start = DEFAULT_POSITION_START;
  let end = DEFAULT_POSITION_END;

  if (s?.anchorNode) {
    start = s?.getRangeAt(0).startOffset ? s?.getRangeAt(0).startOffset : 0;
    end = s?.getRangeAt(0).endOffset ? s?.getRangeAt(0).endOffset : 0;
  }

  return { start, end };
}

export function setCaretPosition(element: any, position: any) {
  const range = document.createRange();
  const s = window.getSelection();

  range.setStart(element.childNodes[0], position);
  range.collapse(true);
  if (s) {
    s.removeAllRanges();
    s.addRange(range);
  }

  return position;
}