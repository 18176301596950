export const CALIDNAME = 'DATA_CALIDNAME';
export const CALNAME = 'DATA_CALNAME';
export const UPDATE_CAL_INFO = 'UPDATE_CAL_INFO';

export interface CalIdAction {
  type: typeof CALIDNAME;
  calId: string;
  calName: string;
  calInputFiles: number;
  calApproval: number,
  calDescription: string,
  id: string  //workspace ID
}

export interface CalNameAction {
  type: typeof CALNAME;
  calName: string;
}

export interface UpdateCalInfoI {
  type: typeof UPDATE_CAL_INFO;
  data: any;
}