import { Modal } from '@mui/material';
import { MutableRefObject, useEffect, useState } from 'react';
import StyledButton from '../../../../components/atoms/Button';
import { FormulaTable } from '../../../../components/molecules/FormulaTable';
import { getCellName } from '../../../../components/molecules/FormulaTable/utils';
import { expandTableDefaultCellStyle } from '../../../../utils/constant';
import { theme } from '../../../../utils/theme';
import { resetDisplayedContent } from '../../utils';
import {
  FooterButton,
  TableBox,
  TableBoxWrapper,
  TableContentSection
} from './styles';

type WorksheetProps = {
  worksheet: Record<string, unknown>;
  cell: unknown;
  col: number;
  row: number;
  newValue: string | number;
  oldValue: string | number;
};

interface IModalTable {
  isOpen: boolean;
  expandTableContainerRef: MutableRefObject<HTMLElement>;
  expandTableRef: MutableRefObject<any>;
  dataSource: Record<string, any> | null;
  onCloseModal: (
    action: 'OPEN' | 'CLOSE',
    data: Record<string, unknown>
  ) => void;
}

const DEFAULT_EXPAND_TABLE_WIDTH = 1024;
const DEFAULT_EXPAND_TABLE_HEIGHT = 512;

export const TableExpandModal = ({
  isOpen,
  expandTableContainerRef,
  expandTableRef,
  dataSource,
  onCloseModal
}: IModalTable) => {
  const [tableData, setTableData] = useState<Record<string, any>>({});

  useEffect(() => {
    !!dataSource && setTableData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    const { data, displayedContent, indexHideRowArr } = tableData;
    if (!data || !displayedContent || !expandTableRef.current) return;
    expandTableRef.current.resetStyles(data, displayedContent);
    updateTableStyles(
      displayedContent,
      indexHideRowArr[indexHideRowArr.length - 1],
      expandTableRef.current
    );
  }, [tableData]);

  const updateTableStyles = (
    content: Record<string, Record<string, number>>,
    indexLastHiddenRow: number,
    componentRef: any
  ) => {
    const styles: any = {};
    if (indexLastHiddenRow === content.row.end) {
      for (let i = content.column.start; i <= content.column.end; i++) {
        const cell = getCellName(i, content.row.end + 1);
        styles[cell] = 'border-top: 1px solid black';
      }
      setTimeout(() => {
        if (componentRef && !!styles) componentRef.setStyle(styles);
      });
    }
  };

  // VIEW PORT LOGIC
  const [formulaTableSize, setFormulaTableSize] = useState({
    width: DEFAULT_EXPAND_TABLE_WIDTH,
    height: DEFAULT_EXPAND_TABLE_HEIGHT
  });

  const handleElementResized = () => {
    if (
      expandTableContainerRef?.current &&
      expandTableContainerRef?.current?.offsetHeight !== formulaTableSize.height
    ) {
      setFormulaTableSize({
        ...formulaTableSize,
        height: expandTableContainerRef.current.offsetHeight
      });
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    if (expandTableContainerRef?.current !== null) {
      resizeObserver.observe(expandTableContainerRef.current);
    }
    if (!expandTableContainerRef?.current)
      return function cleanup() {
        resizeObserver.disconnect();
      };
  }, [expandTableContainerRef?.current]);

  // Table Action
  const handleCellChange = (payload: WorksheetProps) => {
    const { worksheet, cell, col, row, newValue, oldValue } = payload;
    setTableData((prevState: any) => {
      const newData = prevState.data;
      newData[row][col] = {
        ...newData[row][col],
        value: newValue
      };
      return {
        ...prevState,
        data: newData
      };
    });
  };

  const handleButtonClickExpand = () => {
    if (!expandTableRef.current) return;
    const { indexHideRowArr, totalRowAdded } = tableData;
    const newTableData = resetDisplayedContent(
      tableData,
      indexHideRowArr.length,
      totalRowAdded.length
    );
    onCloseModal('CLOSE', newTableData);
  };

  if (Object.keys(tableData).length === 0) return null;

  return (
    <Modal
      onBackdropClick={(e: React.SyntheticEvent) => e.stopPropagation()}
      open={isOpen}
      disableEscapeKeyDown={true}
    >
      <TableBox onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
        <TableBoxWrapper>
          <TableContentSection
            ref={expandTableContainerRef as MutableRefObject<HTMLDivElement>}
          >
            <FormulaTable
              size={formulaTableSize}
              overflow={true}
              ref={expandTableRef}
              name={'EXPAND_TABLE'}
              data={tableData?.data}
              columnSetting={tableData?.headers}
              dimensions={[tableData?.data[0].length, tableData?.data.length]}
              cellStyle={expandTableDefaultCellStyle}
              displayedContent={tableData?.displayedContent}
              stickyColumn={tableData?.freeze?.column}
              stickyRow={tableData?.freeze?.row}
              onValueChange={(
                worksheet: Record<string, unknown>,
                cell: any,
                col: number,
                row: number,
                newValue: any,
                oldValue: any
              ) => {
                handleCellChange({
                  worksheet,
                  cell,
                  col,
                  row,
                  newValue,
                  oldValue
                });
              }}
              contextMenu={() => {
                return false;
              }}
              cells={tableData?.format?.cells}
            />
          </TableContentSection>
          <FooterButton>
            <StyledButton
              size="small"
              variant="ghost"
              sx={{
                textTransform: 'none',
                backgroundColor: theme.palette.secondary.main,
                borderWidth: 0,
                color: theme.customText.high
              }}
              onClick={handleButtonClickExpand}
            >
              Close
            </StyledButton>
          </FooterButton>
        </TableBoxWrapper>
      </TableBox>
    </Modal>
  );
};
