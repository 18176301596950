import { SHOWHIDETOAST } from "../types/toastTypes";

export function writeToast (status: boolean, color: string, message: string) {  
  return {
    type: SHOWHIDETOAST,
    status,
    color,
    message
  };
}
