import React from 'react'
import { AceCitationI, CitationType, SqlCitationI } from '../askAce.type'
import Text from '../../../components/atoms/Text'
import Icon from '../../../components/atoms/Icon'
import { CitationList, CitationMoreButton, CitationWrapper, DrawingItem, SourceItem } from '../styles'
import { CitationDetails } from './CitationDetails'
import { SqlCitationDetails } from './SqlCitationDetails'

const DEFAULT_CITATION_EXPAND = 3;

interface AceCitationPropsI {
  selectedCitation: number | null
  citations: AceCitationI[] | null,
  sqlCitations: SqlCitationI[] | null,
  setSelectedCitation: (index: number | null) => void,
  isExpanded?: boolean,
  setIsExpanded?: () => void
  onClickUrl?: (url: string) => void
  onClickImageUrl?: (citation: AceCitationI) => void
  onClickSqlCitation: (id: string) => void;
}

export const AceCitations = ({ selectedCitation, citations, sqlCitations, setSelectedCitation, isExpanded, setIsExpanded, onClickUrl, onClickImageUrl, onClickSqlCitation }: AceCitationPropsI) => {
  if (!citations && !sqlCitations) return null;
  return (
    <CitationWrapper>
      <div id='source'>
        <Text variant="h5">
          Citation {`(${(citations === null ? 0 : citations?.length) + (sqlCitations === null ? 0 : sqlCitations?.length)})`}
        </Text>
      </div>
      <CitationList>
        {citations?.length && citations?.map((item: AceCitationI, index: number) => {
          if (isExpanded || index <= 2) {
            return (
              <React.Fragment key={`ace-citations-${item.source}-${item.subSection}-${index}`}>
                {item.type === CitationType.FIGURE ?
                  (<DrawingItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation}>
                    <Icon icon='drawing' />
                    <Text variant='body3' overflow='ellipsis'>{`${item.source} ${item.sourceYear ?? ''} | ${item.figureNumber} | ${item.subSection}`}</Text>
                  </DrawingItem>) :
                  (<SourceItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation}>
                    <Icon icon='typeBook' />
                    <Text variant='body3' overflow='ellipsis'>{`${item.source} ${item.sourceYear ?? ''} | ${item.subSection}`}</Text>
                  </SourceItem>)
                }
              </React.Fragment>
            )
          }
        })}
        {sqlCitations?.length && sqlCitations?.map((item: SqlCitationI, index: number) => {
          if (isExpanded || index <= 2) {
            return (
              <React.Fragment key={`ace-sqlCitations-${index}`}>
                <DrawingItem key={index} onClick={() => setSelectedCitation(index)} active={index === selectedCitation} isHcss>
                  <Icon icon='hcss' />
                  <Text variant='body3' overflow='ellipsis'>{`HCSS`}</Text>
                </DrawingItem>
              </React.Fragment>
            )
          }
        })}
        {!isExpanded && citations !== null && citations.length > DEFAULT_CITATION_EXPAND ? <CitationMoreButton onClick={setIsExpanded}><Text variant='body3'>{`+${citations !== null && citations?.length - 3} more`}</Text></CitationMoreButton> : null}
      </CitationList >
      <CitationDetails dataSource={selectedCitation !== null && citations !== null ? citations[selectedCitation] : null} onClose={() => setSelectedCitation(null)} onClickUrl={onClickUrl as any} onClickImageUrl={onClickImageUrl as any} />
      <SqlCitationDetails dataSource={selectedCitation !== null && sqlCitations !== null ? sqlCitations[selectedCitation] : null} onClose={() => setSelectedCitation(null)} onClickSqlCitation={onClickSqlCitation} />
    </CitationWrapper>
  )
}
