export const buttonStyle = {
  height: "36px",
  alignItems: "center",
  justifyContent: "center",
};

export const boxContainerStyle = {
  display: "flex",
  //   flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: "24px",
  alignItems: "center",
  paddingTop: "20px",
};

export const boxInnerStyle = {
  //   flexDirection: "row",
  alignItems: "center",
  display: "flex",
  borderRadius: "5px",
  height: "36px",
  justifyContent: "space-between",
};

export const boxInputStyle = {
  display: "flex",
  //   flexDirection: "row",
};

export const inputStyle = {
  width: "220px",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #E0E6EB",
  borderTopLeftRadius: "6px",
  borderBottomLeftRadius: "6px",
  padding: "4px 8px 4px 12px",
  fontFamily: "Inter",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "18px",
};

export const searchIconStyle = {
  alignItems: "center",
  padding: "8px",
  backgroundColor: "#E0E6EB",
  justifyContent: "center",
  borderTopRightRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "2px solid #E0E6EB",
};

export const boxHeader = {
  display: "flex",
  //   flexDirection: "row",
  alignItems: "center",
};

export const boxButtonContainer = {
  display: "flex",
  flexDirection: "row",
  columnGap: '10px',
  alignItems: "center",
};