import { Dispatch } from "redux";
import {
  CREATE_NEW_CALC,
  LOADING_CALCULATIONS,
  FAILED_CALCULATIONS,
  TRIGGER_SAVE_CALCULATIONS,
  SET_CALCULATIONS,
} from "../types/calculationsTypes";
import Service from "../../service/generic/generic";
import { updateCalInfo } from "./calInfoAction";

const service = new Service("calculations");
export function createNewCalc(folderId: string, values: any, history: any) {
  return async (dispatch: Dispatch) => {
    try {
      // dispatch(loadingAction(true));
      const result = await service.post({
        folderId: folderId,
        title: values.title,
        description: values.description,
      });
      if (result.message === "Success") {
        dispatch({
          type: CREATE_NEW_CALC,
          id: result.data?.id,
        });
        history.replace(`/composer/${result.data?.id}/${folderId}`);
      } else {
        // console.log("result ======>", result);
      }
      // dispatch(loadingAction(false));
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function saveCalculations(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(triggerSaveAction(false));
      dispatch(loadingAction(true));
      const variableList: any[] = [];
      data.pages.forEach((e: any) => {
        e.rows?.forEach((i: any) => {
          i.columns.forEach((j: any) => {
            if ("properties" in j && "variable" in j.properties) {
              const { properties } = j;
              variableList.push({
                columnId: j.columnId,
                description: j.description,
                name: properties.variable,
                notation: properties.notation,
                unit: properties.unit,
                cell: "",
              });
            }
          });
        });
      });
      data.variableList = variableList;
      data.location = data.folderId;
      delete data.folderId;
      await service.put(data.id, data);
      dispatch(loadingAction(false));
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error.response.data?.message);
    }
  };
}

export function makeCopyCal(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const result = await service.postResource("saveas", data);
      return Promise.resolve(result);
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function makeMultipleCopy(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const result = await service.postResource("save-as", data);
      return Promise.resolve(result);
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function moveCal(calId: string, folderId: string) {
  return async (dispatch: Dispatch) => {
    try {
      await service.putResource(calId, "move", {
        destinationParentId: folderId,
      });
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function moveMultipleCal(folderId: string, calculationsId: Array<string>) {
  return async (dispatch: Dispatch) => {
    try {
      await service.putAction("move-many", {
        destinationParentId: folderId,
        calculationIds: calculationsId
      });
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function editCalcInfo(calId: string, values: any) {
  return async (dispatch: Dispatch) => {
    try {
      await service.putResource(calId, "info", {
        title: values.title,
        description: values.description,
      });
      dispatch(
        updateCalInfo({
          calName: values.title,
          calDescription: values.description,
        })
      );
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error);
    }
  };
}

export function saveAsCal(values: any, history: any) {
  return async (dispatch: Dispatch) => {
    try {
      const result = await service.postResource("saveas", values);
      history.replace(`/composer/${result.data?.id}/${result.data?.folderId}`);
    } catch (error: any) {
      dispatch(failedAction("failed", false));
      console.error(error);
      return Promise.reject(error.response.data?.message);
    }
  };
}

export function calculationDelete(value: string) {
  return async (dispatch: Dispatch) => {
    const newService = new Service("calculation");
    try {
      const result = await newService.deleteCalculation(value);
      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function setCalculations(dataCalculation: Record<string, unknown>) {
  return { type: SET_CALCULATIONS, dataCalculation };
}

export function triggerSaveAction(isSaveCalculation: boolean) {
  return { type: TRIGGER_SAVE_CALCULATIONS, isSaveCalculation };
}

function loadingAction(isLoading: boolean) {
  return { type: LOADING_CALCULATIONS, isLoading };
}

function failedAction(message: any, isLoading: boolean) {
  return { type: FAILED_CALCULATIONS, message, isLoading };
}

export function setLoadingAction(isLoading: boolean) {
  return { type: LOADING_CALCULATIONS, isLoading };
}

export function overrideCalculation(calculationId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const newService = new Service("calculation");
      const result = await newService.deleteInputFile(calculationId, 'input-files');
      return Promise.resolve(result);
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error);
    }
  };
}
