import api from "./api";

const URL = 'symbols';

export type SymbolType = {
  glyph: string,
  code: string,
  dec: number,
  name: string,
  latex: string
};

const SymbolService = {
  get: async (): Promise<symbol> => {
    const response = await api.get(`${URL}`);
    return response.data.data;
  }
};

export default SymbolService;
