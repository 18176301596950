interface ClickableLogoProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement> , HTMLImageElement>{
    onClick?: () => void
}

export default function Logo(props: ClickableLogoProps) {
    return <img src={props.src} {...props} alt="logo" onClick={props?.onClick}/>;
}

Logo.defaultProps = {
    width: 30,
    height: 'auto'
};
