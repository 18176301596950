import {
  POSITIONTYPECIRCLEATORBREADCRUMB,
  PositionTypeCircleatorBreadcrumb,
} from './circleatorTypes';

interface initialStateI {
  position: string | null;
};

const initialState: initialStateI = {
  position: null
};

type Action = PositionTypeCircleatorBreadcrumb;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case POSITIONTYPECIRCLEATORBREADCRUMB:
      return { ...state, position : action.data };
    default:
      return state;
    }
}