import styled from 'styled-components';

export const HorizontalDiv = styled.div({
  border: '1px solid #E0E6EB',
  display: 'flex',
  alignItems: 'center',
  minHeight: '2.5rem',
  backgroundColor: '#F9F9F9',
  borderRadius: '4px',
  gap: '1.1rem',
  '& > div:nth-child(n+2)': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    p: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
});

export const MainContent = styled.div({
  width: '2.5rem',
  borderRight: '1px solid #E0E6EB',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    cursor: 'pointer',
  },
});
