import {
  LOADING,
  FAILED,
  DATA,
  LoadingAction,
  FailedAction,
  DataAction
} from '../types/encalTypes';

interface initialStateI {
  data: { [key: string]: Record<string, unknown> }[];
  isLoading: boolean;
  message: string;
};

const initialState: initialStateI = {
  data: [],
  isLoading: false,
  message: '',
};

type Action = LoadingAction | FailedAction | DataAction;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case LOADING:
      return { ...state, isLoading: action.isLoading };
    case FAILED:
      return { ...state, message: action.message, isLoading: action.isLoading };
    case DATA:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}