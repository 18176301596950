import StyledButton from '../../../../components/atoms/Button';
import Loading from '../../../../components/atoms/loading';
import SelectDropdown from '../../../../components/molecules/SelectDropdown';
import { useAppSelector } from '../../../../hooks/hooks';
import { CalculationPanelProps } from '../../logger.types';
import { PanelSection, SideBar } from '../../styles';

const CalculationPanel = ({
  handleSelectedInputFile,
  handleSaveInput
}: CalculationPanelProps) => {
  const { isLoading, calculationInfo } = useAppSelector(
    state => state.loggerReducer
  );
  return (
    <SideBar>
      <h2>Calculation Info</h2>
      <PanelSection>
        <p>Calculation Name</p>
        <h4>{calculationInfo.calculationTitle}</h4>
      </PanelSection>

      <PanelSection>
        <p>Input File Name</p>
        <SelectDropdown
          selectedValue={calculationInfo.selectedInputFile}
          items={calculationInfo.inputFiles}
          onSelected={handleSelectedInputFile}
        />
      </PanelSection>

      <PanelSection>
        <p>Last Saved</p>
        <h4>{calculationInfo?.lastSaved}</h4>
      </PanelSection>

      <StyledButton
        variant="contained"
        fullWidth
        onClick={handleSaveInput}
        disabled={isLoading}
        data-testid="btnSaveProgress"
      >
        {isLoading ? <Loading /> : 'Save Input'}
      </StyledButton>
    </SideBar>
  );
};

export default CalculationPanel;
