import * as React from "react";

const SvgRupiah = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
   width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.734 17.118V4.5h3.828c1.013 0 1.83.149 2.45.446.635.297 1.095.722 1.378 1.276.297.54.446 1.18.446 1.924 0 .459-.102.924-.304 1.397a3.608 3.608 0 0 1-.871 1.236 3.474 3.474 0 0 1-1.337.77l2.856 5.569H9.317l-2.714-5.469H4.476v5.469H2.734Zm1.742-6.927h2.248c.81 0 1.404-.182 1.783-.547.378-.378.567-.87.567-1.478 0-.392-.081-.743-.243-1.053a1.765 1.765 0 0 0-.77-.77c-.351-.189-.797-.284-1.337-.284H4.476v4.132ZM12.942 20.5V7.801h1.742v1.661c.162-.432.378-.783.648-1.053.283-.284.6-.486.952-.608.364-.135.75-.202 1.154-.202.513 0 1 .101 1.458.304.46.202.864.506 1.216.91.35.392.627.892.83 1.5.216.594.324 1.296.324 2.106 0 .824-.108 1.546-.324 2.167-.216.621-.513 1.134-.891 1.54a3.877 3.877 0 0 1-1.236.931c-.459.203-.931.304-1.417.304-.351 0-.696-.054-1.033-.162a2.967 2.967 0 0 1-.932-.446 3.533 3.533 0 0 1-.709-.709V20.5h-1.782Zm4.091-4.598c.31 0 .608-.053.891-.161.297-.122.56-.311.79-.568.243-.27.432-.627.567-1.073.135-.446.203-1.006.203-1.681 0-.702-.088-1.296-.264-1.782-.175-.5-.432-.885-.77-1.155-.337-.27-.748-.418-1.235-.445a2.111 2.111 0 0 0-1.012.162c-.297.135-.56.35-.79.648-.23.297-.405.682-.527 1.154-.121.473-.182 1.047-.182 1.722.013.432.054.817.121 1.154.068.338.17.635.304.891.135.257.29.466.466.628.19.162.405.29.648.385.243.081.507.121.79.121Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRupiah;
