import styled from "styled-components";

interface PropsI {
  isActive?: boolean;
  isShowButton?: boolean;
  isList?: boolean;
}

export const Item = styled.div<PropsI>((props: PropsI) => ({
  width: "160px",
  height: "160px",
  margin: "12px",
  borderRadius: "6px",
  cursor: "pointer",
  border: props.isActive ? "1px solid #118CFF" : "1px solid #D6DFEB",
  "& > #body": {
    height: "112px",
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    "& > #image": {
      height: "53px",
      width: "53px"
    },
  },
  "& > #footer": {
    backgroundColor: props.isActive ? '#E3F3FF' : '#fff',
    height: '48px',
    borderBottomRightRadius: "6px",
    borderBottomLeftRadius: "6px",
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px',
    textAlign: 'center'
  },
}));

export const Wrap = styled.div({
  height: "9rem",
  border: "1px solid #E0E6EB",
  boxSizing: "border-box",
  WebkitBoxSizing: "border-box",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#FFFFFF",
  flexDirection: "column",
  position: "relative",
});

export const ActionButton = styled.div<PropsI>((props: PropsI) => ({
  position: "absolute",
  top: 0,
  display: "flex",
  visibility: props.isShowButton ? "visible" : "hidden",
  alignItems: "center",
  justifyContent: "space-between",
  right: 0,
  left: 0,
}));

export const ActionButtonList = styled.div<PropsI>((props: PropsI) => ({
  display: "flex",
  visibility: props.isShowButton ? "visible" : "hidden",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const WrapList = styled.div<PropsI>((props: PropsI) => ({
  border: props.isActive ? "1px solid #E0E6EB" : "none",
  boxSizing: "border-box",
  WebkitBoxSizing: "border-box",
  borderRadius: "4px",
}));

export const ContentSection = styled.div({
  display: "flex",
  columnGap: ".5rem",
  flexWrap: "wrap",
});

export const Container = styled.div({
  display: "flex",
  columnGap: ".5rem",
  flexWrap: "wrap",
});
