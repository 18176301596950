import * as enterpriseAction from "../types/enterprisetTypes";

export interface EnterpriseFileState {
  errorMessage: string;
  isLoading: boolean;
  dataEnterprise: Array<any>;
}

const initialState: EnterpriseFileState = {
  errorMessage: "",
  isLoading: false,
  dataEnterprise: [],
};

const enterpriseReducer = (
  state: EnterpriseFileState = initialState,
  action: any
) => {
  switch (action.type) {
    case enterpriseAction.FETCH_FILE_BY_FOLDER_ID:
      return {
        ...state,
        dataEnterprise: action.data,
        isLoading: action.isLoading,
      };
    case enterpriseAction.LOADING_CALCULATIONS:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default enterpriseReducer;
