import * as React from "react";

export const AskAce = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M32.072 82c-1.96-6.39-4.916-11.156-8.87-14.294C17.272 63 7.85 65.926 4.37 61.07.89 56.214 6.808 47.286 8.884 42.018c2.078-5.268-7.96-7.13-6.788-8.626.78-.998 5.854-3.874 15.218-8.632C19.974 9.588 29.8 2 46.796 2 72.288 2 82 23.612 82 37.36c0 13.744-11.76 28.552-28.512 31.746-1.498 2.18.662 6.48 6.48 12.894"
    ></path>
  </svg>
);
