import React from "react";
import { styled, TextField as MuiTextField, TextFieldProps } from "@mui/material";

const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
	'& .MuiInputBase-root': {
		borderRadius: 6,
		'& fieldset': {
			borderColor: '#E0E6EB',
		},
		'&:hover fieldset': {
			borderColor: '#E0E6EB',
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiInputBase-input': {
		fontFamily: 'Inter',
		fontSize: 12,
		color: 'rgba(53, 60, 104, 1)',
		position: 'relative',
		padding: '8px 16px',
		backgroundColor: theme.palette.common.white,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:focus': {
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiSelect-select': {
		borderRadius: 6,
		minWidth: '7.35rem',
	}
}));

const Input = React.forwardRef(function CustomInput(
	props: TextFieldProps,
	ref: React.ForwardedRef<HTMLDivElement>,
) {
	return (
		<TextField {...props} ref={ref} />
	);
});

export default Input;