import { Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import { styled } from "@mui/material";
import React from "react";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.customText.high,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.customText.high,
        opacity: 1,
        padding: '8px 16px',
        color: theme.palette.common.white
    },
}));

export default BootstrapTooltip;