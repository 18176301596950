import React from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import Input from '../../atoms/Input';

type SelectPropsI = {
    options: any[],
} & TextFieldProps;

const Select = React.forwardRef(function CustomSelect(
    props: SelectPropsI,
    ref: React.ForwardedRef<HTMLDivElement>,
) {
    return (
        <Input select {...props} ref={ref} size={props.size}>
            {props.options.map((option) => (
                <MenuItem key={option.value} value={option.value} dense sx={{fontSize: 12}}>
                    {option.label}
                </MenuItem>
            ))}
        </Input>
    );
});

export default Select;