import Icon from "../../../../components/atoms/Icon";
import Text from "../../../../components/atoms/Text";
import { SideBar, SideBarTop } from "../../../../pageElements/printView";
import { ReportInfoProps } from "../../print.types";





export default function ReportInfo({
  info,
  image,
  leaveButton,
}: ReportInfoProps) {
  return (
    <SideBar>
      <SideBarTop >
          <Icon icon="arrowLeft" styles={{marginRight: 8}} onClick={leaveButton}/>
          <Text variant="body3" fontWeight={700}>Back to Logger View</Text>
      </SideBarTop>
      <Text variant="body3" color="#A3B3C2" fontWeight={700}>CALCULATION INFO</Text>
      <Text variant="h3">Calculation name</Text>
      <Text variant="h5" style={{overflowWrap : "anywhere"}}>{info?.calculationTitle}</Text>
      <Text variant="h3">Input File</Text>
      <Text variant="h5" style={{overflowWrap : "anywhere"}}>{info?.inputFileTitle}</Text>
    </SideBar>
  );
}
