import TeX from '@matejmazur/react-katex';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ReduxState } from '../../../store';
import { LatexEngine } from '../../../utils/latexEngine';
import { FartexEngine } from '../../../utils/fartexEngine';
import LatexVar from './latexVar';
import './latex.css';

interface PropsI {
  formula: string;
  tableName?: string;
  origin?: string;
}

export default function Latex({ formula, tableName, origin }: PropsI) {
  const [latex, setLatex] = useState<any>('');
  const [translation, setTranslation] = useState<any>(null);
  const [engine, setEngine] = useState<LatexEngine>();

  const { symbols } = useSelector(
    (state: ReduxState) => state.utils,
    shallowEqual
  );

  if (!engine && symbols.length > 0) {
    setEngine(new LatexEngine(symbols));
  }

  useEffect(() => {
    const strangeFunction = ['VLOOKUP', 'HLOOKUP', 'INDEX', 'IF'];
    if (strangeFunction.some((v: any) => formula.includes(v))) {
      const engine = new FartexEngine();
      const result: any = engine.exec(formula, strangeFunction, tableName);
      setLatex(result.type);
      if (result.trueCase && result.falseCase) {
        setTranslation({
          trueCase: result.trueCase,
          falseCase: result.falseCase
        });
      }
    } else {
      if (engine) {
        const result = engine.exec(formula);
        setLatex(result);
      }
    }
  }, [formula, engine]);

  return (
    <div className="latexShow">
      {latex === 'error' ? (
        <LatexVar formula={formula.substring(1)} />
      ) : latex === 'nested error' ? (
        <LatexVar formula={formula.substring(1)} />
      ) : latex !== undefined && latex.includes('FUNCTION') ? (
        <div
          style={{
            margin: '0 0'
          }}
        >
          {latex.includes('VLOOKUP') ? (
            <i>{`Value vertically looked up from Table ${tableName}`}</i>
          ) : latex.includes('HLOOKUP') ? (
            <i>{`Value horizontally looked up from Table ${tableName}`}</i>
          ) : latex.includes('INDEX') ? (
            <i>{`Value looked up from Table ${tableName}`}</i>
          ) : (
            <>
              <p style={{ paddingBottom: '2px' }}>
                <strong>Case 1</strong>
              </p>
              <i>{translation.trueCase}</i>

              <p style={{ marginTop: 4, paddingBottom: '2px' }}>
                <strong>else</strong>
              </p>
              <i>{translation.falseCase}</i>
            </>
          )}
        </div>
      ) : (
        <TeX>{latex}</TeX>
      )}
    </div>
  );
}
