import { IVisitor } from "../latexEngine/visitor";
import { mapGlyphToString } from "./glyph-mapper";
import { NotationParser } from "./notation.parser";

export function createVisitor(parser: NotationParser): IVisitor {
    const NotationBaseVisitor = parser.getBaseCstVisitorConstructorWithDefaults();

    class InterpreterVisitor extends NotationBaseVisitor {
        constructor() {
            super();
            this.validateVisitor();
        }

        expression(ctx: Record<string, any>) {
            if (ctx.stringClause) return this.visit(ctx.stringClause);
            if (ctx.subscriptClause) return this.visit(ctx.subscriptClause);
            if (ctx.superscriptClause) return this.visit(ctx.superscriptClause);
            if (ctx.symbolClause) return this.visit(ctx.symbolClause);
        }

        stringClause(ctx: Record<string, any>) {
            let columns = ctx.lhs[0].image;
            if (!ctx.rhs) return columns;
            const rhs = this.visit(ctx.rhs);
            columns = columns.concat(' ', rhs);
            return columns;
        }

        subscriptClause(ctx: Record<string, any>) {
            let columns = ctx.lhs[0].image;
            columns = this.removeHTMLTag(columns);
            columns = '_{' + columns + '}';

            if (!ctx.rhs) return columns;
            const rhs = this.visit(ctx.rhs);
            columns = columns.concat(' ', rhs);
            return columns;
        }

        superscriptClause(ctx: Record<string, any>) {
            let columns = ctx.lhs[0].image;
            columns = this.removeHTMLTag(columns);
            columns = '^{' + columns + '}';

            if (!ctx.rhs) return columns;
            const rhs = this.visit(ctx.rhs);
            columns = columns.concat(' ', rhs);
            return columns;
        }

        symbolClause(ctx: Record<string, any>) {
            let columns = mapGlyphToString.filter((el: any) =>
                el.glyph === ctx.lhs[0].image || el.glyph.includes(ctx.lhs[0].image)
            ).map((obj: any) => obj.latex);

            if (!ctx.rhs) return columns[0];
            const rhs = this.visit(ctx.rhs);
            columns = columns[0].concat(' ', rhs);
            return columns;
        }

        removeHTMLTag(text: string) {
            return text.replaceAll(/<[^>]*>/g, "");
        }
    }

    return new InterpreterVisitor();
};