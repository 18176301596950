import React, { useEffect, useState } from "react";
import images from "../../../assets";
import { useDispatch } from "react-redux";
import { fetchPanelInfoList, formHelperPanel } from "../../../store/appState/global/globalActions";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function HelperButton(props: any) {
    const { numBillingSelected } = props;

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const dispatch = useDispatch();
    const handleOpenPanel = () => {
        Promise.all([
            dispatch(fetchPanelInfoList())
        ]).then(response => {
            dispatch(formHelperPanel(true, "PANEL", response[0]));
        });
    };

    return (
        <div>
            <img src={images.helpCircleIcon} onClick={handleOpenPanel} style={{
                height: 58,
                width: 58,
                left: windowDimensions.width - 96,
                top: windowDimensions.height - (numBillingSelected > 0 ? 155 : 90),
                objectFit: "scale-down",
                position: 'fixed',
                cursor: 'pointer'
            }} />
        </div>
    );
}