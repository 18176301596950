import * as React from "react";

export const Dot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      fill="currentColor"
      d="M12 9.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
    ></path>
  </svg>
);
