import {
  Box,
  Button,
  Grid,
  RadioGroup,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import images from '../assets';
import StyledCheckbox from '../components/atoms/Checkbox';
import Logo from '../components/atoms/Logo';
import Pill from '../components/atoms/Pill';
import StyledRadioButton from '../components/atoms/RadioButton';
import StyledToggle from '../components/atoms/Toggle';
import StyledTooltip from '../components/atoms/Tooltip';
import { TabPanel, TabProps } from '../components/atoms/Tab';
import { BreadcrumbsV2 } from '../components/molecules/Breadcrumbs';
import { breadcrumbsDataSample, roleObj } from '../utils/constant';
import Center from '../components/atoms/Center';
import Topbar from '../components/organisms/Topbar';
import { getUserProfile } from '../utils/storage';
import StyledButton from '../components/atoms/Button';
import Text from '../components/atoms/Text';
import PageHeader from '../components/molecules/Header';
import Input from '../components/atoms/Input';
import Select from '../components/molecules/Select';
import ComboBox from '../components/molecules/ComboBox';
import Icon from '../components/atoms/Icon';
import { IconTextField } from '../components/molecules/IconTextField';
import RichTextInput from '../components/molecules/RichText';

export default function AtomicPage() {
  const [value, setValue] = useState('female');
  const [tab, setTab] = useState(0);
  const userRole = getUserProfile();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const [selected, setSelected] = useState<string>('');
  const handleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
  };

  const options = ['option 1', 'option 2'];
  //combo box
  const [option, setOption] = React.useState<any | null>(options[0]);

  return (
    <div style={{ overflow: 'auto' }}>
      <Center>
        <h2>AtomicPage</h2>
      </Center>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tab}
          onChange={handleTabChange}
          aria-label="default tab"
        >
          <Tab label="Checkbox" {...TabProps(0)} />
          <Tab label="Radio Button" {...TabProps(1)} />
          <Tab label="Toogle" {...TabProps(2)} />
          <Tab label="Logo" {...TabProps(3)} />
          <Tab label="Pills" {...TabProps(4)} />
          <Tab label="Tooltip" {...TabProps(5)} />
          <Tab label="Breadcrumbs" {...TabProps(6)} />
          <Tab label="Topbar" {...TabProps(7)} />
          <Tab label="Button" {...TabProps(8)} />
          <Tab label="Input" {...TabProps(9)} />
          <Tab label="Header" {...TabProps(10)} />
          <Tab label="Search" {...TabProps(11)} />
          <Tab label="RichText" {...TabProps(12)} />
          <Tab label="RichTextEditor" {...TabProps(13)} />
        </Tabs>

        <TabPanel value={tab} index={0}>
          <Center>
            <StyledCheckbox label="Default" />
            <StyledCheckbox checked={true} label="Checked" />
            <StyledCheckbox disabled label="Disabled" />
            <StyledCheckbox checked={true} disabled label="Checked Disabled" />
          </Center>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Center>
            <RadioGroup value={value} onChange={handleChange}>
              <StyledRadioButton value="female" label="Female" />
              <StyledRadioButton value="male" label="Male" />
            </RadioGroup>
          </Center>
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <Center>
            <Box sx={{ padding: '4px 0' }}>
              <StyledToggle />
            </Box>
            <Box sx={{ padding: '4px 0' }}>
              <StyledToggle disabled />
            </Box>
            <Box sx={{ padding: '4px 0' }}>
              <StyledToggle disabled defaultChecked />
            </Box>
          </Center>
        </TabPanel>

        <TabPanel value={tab} index={3}>
          <Center>
            <Box sx={{ padding: 4 }}>
              <Logo src={images.rekavaLogo} width={120} />
            </Box>
            <Box sx={{ padding: 4 }}>
              <Logo src={images.logoIcon} width={40} />
            </Box>
            <Box sx={{ padding: 4 }}>
              <Logo src={images.logoText} width={120} />
            </Box>
          </Center>
        </TabPanel>

        <TabPanel value={tab} index={4}>
          <Grid container justifyContent={'center'} gap={1}>
            <Pill role="REKAVA" />
            <Pill role="ADMIN" />
            <Pill role="PRINCIPAL" />
            <Pill role="COMPOSER" />
            <Pill role="LOGGER" />
            <Pill role="CHECKER" />
          </Grid>
        </TabPanel>

        <TabPanel value={tab} index={5}>
          <Center>
            <StyledTooltip title="Body text to describe the highlighted feature">
              <Button>Hover Me</Button>
            </StyledTooltip>
          </Center>
        </TabPanel>

        <TabPanel value={tab} index={6}>
          <BreadcrumbsV2 dataSource={breadcrumbsDataSample} />
        </TabPanel>

        <TabPanel value={tab} index={7}>
          <Box sx={{ pt: 5, pb: 5 }}>
            <Topbar role={roleObj[String(userRole)]} page="composer" />
          </Box>
          <Box sx={{ pt: 5, pb: 5 }}>
            <Topbar role={roleObj[String(userRole)]} page="logger" />
          </Box>
          <Box sx={{ pt: 5, pb: 5 }}>
            <Topbar role={roleObj[String(userRole)]} page="print" />
          </Box>
        </TabPanel>

        <TabPanel value={tab} index={8}>
          <Grid container gap={2} mb={5}>
            <Text variant="h2">Button Link</Text>
            <Grid container gap={2}>
              <StyledButton size="small">small</StyledButton>
              <StyledButton>medium</StyledButton>
              <StyledButton size="large">large</StyledButton>
              <StyledButton size="large" disabled>
                large
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container gap={2} mb={5} mt={5}>
            <Text variant="h2">Primary</Text>
            <Grid container gap={2}>
              <StyledButton variant="contained" size="small">
                small
              </StyledButton>
              <StyledButton variant="contained">medium</StyledButton>
              <StyledButton variant="contained" size="large">
                large
              </StyledButton>
              <StyledButton variant="contained" size="large" disabled>
                large
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container gap={2} mb={5} mt={5}>
            <Text variant="h2">Ghost</Text>
            <Grid container gap={2}>
              <StyledButton variant="ghost" size="small">
                small
              </StyledButton>
              <StyledButton variant="ghost">medium</StyledButton>
              <StyledButton variant="ghost" size="large">
                large
              </StyledButton>
              <StyledButton variant="ghost" size="large" disabled>
                large
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container gap={2} mb={5} mt={5}>
            <Text variant="h2">Secondary</Text>
            <Grid container gap={2}>
              <StyledButton variant="contained" color="secondary" size="small">
                small
              </StyledButton>
              <StyledButton variant="contained" color="secondary">
                medium
              </StyledButton>
              <StyledButton variant="contained" color="secondary" size="large">
                large
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                size="large"
                disabled
              >
                large
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container gap={2} mb={5} mt={5}>
            <Text variant="h2">Error</Text>
            <Grid container gap={2}>
              <StyledButton variant="contained" color="error" size="small">
                small
              </StyledButton>
              <StyledButton variant="contained" color="error">
                medium
              </StyledButton>
              <StyledButton variant="contained" color="error" size="large">
                large
              </StyledButton>
              <StyledButton
                variant="contained"
                color="error"
                size="large"
                disabled
              >
                large
              </StyledButton>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tab} index={9}>
          <Grid container gap={2}>
            <Input placeholder="Text input" />
            <Select
              placeholder="please select..."
              value={selected}
              onChange={handleSelected}
              options={[
                { label: 'men', value: 'men' },
                { label: 'women', value: 'women' }
              ]}
            />
            <ComboBox
              value={option}
              onChange={(event: any, newValue: any | null) => {
                setOption(newValue);
              }}
              options={options}
              renderInput={params => (
                <TextField {...params} placeholder="Please select" />
              )}
            />
            <Input
              multiline
              placeholder="Text area"
              rows={4}
              sx={{ width: 300 }}
            />
          </Grid>
        </TabPanel>

        <TabPanel value={tab} index={10}>
          <PageHeader
            title="header"
            headerData={{
              originator: 'bambang',
              dateCreated: '20/12/2021',
              principal: '-',
              datePrint: '',
              inputFileId: '123',
              primaryLogo: '',
              secondaryLogo: ''
            }}
            // KPD-2217 Logger View - Hide Feature Upload Logo
            // handleUploadLogo={() => { }} leftImg={images.rekavaLogo} rightImg={images.logoGreyscale}
          />
        </TabPanel>

        <TabPanel value={tab} index={11}>
          <Grid container gap={2}>
            <Input placeholder="input component" fullWidth />
            <IconTextField
              placeholder="Formula"
              iconStart={
                <Center>
                  <Icon icon="formula" />
                </Center>
              }
            />
            <IconTextField
              placeholder="Search"
              iconEnd={
                <Center>
                  <Icon icon="search" />
                </Center>
              }
            />
            <IconTextField
              placeholder="Search Formula"
              iconStart={
                <Center>
                  <Icon icon="formula" />
                </Center>
              }
              iconEnd={
                <Center>
                  <Icon icon="search" />
                </Center>
              }
            />
          </Grid>
        </TabPanel>

        <TabPanel value={tab} index={12}>
          <Grid container gap={2}>
            <RichTextInput.RichText source="apakah ini <sub>x</sub>" />
            <RichTextInput.RichTextToolbar source="notasi <sub>x</sub>" />
            <RichTextInput.RichText source="" placeholder="unit" />
          </Grid>
        </TabPanel>

        <TabPanel value={tab} index={13}>
          <RichTextInput.RichTextEditor />
        </TabPanel>
      </Box>
    </div>
  );
}
