//TODO: determine which function could be here
import Icon from '../../../components/atoms/Icon';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import images from '../../../assets';
import Pill from '../../../components/atoms/Pill';
import ComposerToolkit from '../../../components/molecules/composer/composerToolkit';
import {
  Interaction,
  ComponentContent,
  TheXAtMisc,
  TitleAtComponentContent,
  DataContainerAtComponentContent,
  DNDWrapperDataAtComponentContent,
  DataAtComponentContent,
  TextDataAtComponentContent,
  CloneDataAtComponentContent,
  VarianbleNetworkContent,
  TitleAtVarianbleNetworkContent,
  CommentContent
} from '../../../pageElements/composer';
import { contentComponent } from '../../../utils/constant';
import { IComposerLeft } from './composer-left.type';
import { concatStringHTML } from '../../../utils/helpersFunction';

export const ComposerLeft = ({
  dataSwitch,
  exitSwitch,
  disabledComponent,
  handlerClickAddComponent,
  idCalculation,
  dataVariableManagerMenu,
  currentFocus,
  startInputFocus,
  endInputFocus,
  register,
  setValue,
  getValues,
  notationInputRef,
  removeTableSubsFree
}: IComposerLeft) => {
  const variableManagerItemClicked = (type: string, variable: string) => {
    if (type === 'componentFormula') {
      if (
        startInputFocus !== null &&
        startInputFocus !== undefined &&
        endInputFocus !== null &&
        endInputFocus !== undefined
      ) {
        concatStringHTML(
          currentFocus,
          startInputFocus,
          endInputFocus,
          variable,
          getValues,
          setValue
        );
      }
    }
  };

  return (
    <Interaction>
      {dataSwitch.components ? (
        <ComponentContent>
          <TheXAtMisc>
            <img
              onClick={() => exitSwitch('COMPONENTS')}
              width={10}
              src={images.xButtonComposer}
            />
          </TheXAtMisc>
          <TitleAtComponentContent>
            <p>COMPONENTS</p>
          </TitleAtComponentContent>
          <DataContainerAtComponentContent>
            {contentComponent?.map((value: any, index: any) => {
              if (!removeTableSubsFree(value.type))
                return (
                  <Droppable
                    key={`droppable_left_${index}`}
                    droppableId={`MenuDND_${value.type}`}
                    isDropDisabled={true}
                  >
                    {provided => (
                      <DNDWrapperDataAtComponentContent
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Draggable
                          key={index}
                          draggableId={value.type}
                          index={index}
                          isDragDisabled={disabledComponent(value.type)}
                        >
                          {(provided, snapshot) => (
                            <>
                              <DataAtComponentContent
                                onClick={() =>
                                  !disabledComponent(value.type)
                                    ? handlerClickAddComponent(
                                        idCalculation,
                                        value.type
                                      )
                                    : null
                                }
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                isDragging={snapshot.isDragging}
                                style={{
                                  ...provided.draggableProps.style,
                                  transform: snapshot.isDragging
                                    ? provided.draggableProps.style?.transform
                                    : 'translate(0px, 0px)',
                                  cursor: disabledComponent(value.type)
                                    ? 'inherit'
                                    : 'pointer'
                                }}
                              >
                                <TextDataAtComponentContent
                                  disabled={disabledComponent(value.type)}
                                >
                                  {value.label}
                                </TextDataAtComponentContent>
                                {disabledComponent(value.type) && (
                                  <Pill
                                    role="Coming Soon"
                                    style={{ padding: '0.25rem' }}
                                  />
                                )}
                                {!disabledComponent(value.type) && (
                                  <Icon icon={value.svg} cursor="default" />
                                )}
                              </DataAtComponentContent>
                              {snapshot.isDragging && (
                                <CloneDataAtComponentContent>
                                  <TextDataAtComponentContent>
                                    {value.label}
                                  </TextDataAtComponentContent>
                                  <Icon icon={value.svg} cursor="default" />
                                </CloneDataAtComponentContent>
                              )}
                            </>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </DNDWrapperDataAtComponentContent>
                    )}
                  </Droppable>
                );
            })}
          </DataContainerAtComponentContent>
        </ComponentContent>
      ) : (
        <></>
      )}
      {dataSwitch.variableNetwork ||
      dataSwitch.variableManager ||
      dataSwitch.symbol ||
      dataSwitch.comments ? (
        dataSwitch.variableNetwork ? (
          <VarianbleNetworkContent>
            <TheXAtMisc>
              <img width={10} src={images.xButtonComposer} alt="button-x" />
            </TheXAtMisc>
            <TitleAtVarianbleNetworkContent>
              <p>VARIABLE NETWORK</p>
            </TitleAtVarianbleNetworkContent>
          </VarianbleNetworkContent>
        ) : dataSwitch.variableManager ? (
          <ComposerToolkit.VariableManager
            dataVariableManager={dataVariableManagerMenu}
            currentFocus={currentFocus}
            register={register}
            id="searchVariableManagerMenu"
            setValue={setValue}
            getValues={getValues}
            itemClick={(type: string, variable: string) =>
              variableManagerItemClicked(type, variable)
            }
          />
        ) : dataSwitch.symbol ? (
          <ComposerToolkit.Symbol
            ref={notationInputRef}
            currentFocus={currentFocus}
            startInputFocus={startInputFocus}
            endInputFocus={endInputFocus}
            setValue={setValue}
            getValues={getValues}
          />
        ) : dataSwitch.comments ? (
          <CommentContent></CommentContent>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Interaction>
  );
};
