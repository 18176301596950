import { styled } from '@mui/material';
import NumberFormat from 'react-number-format';
import images from '../../../assets';

interface PropsI {
  error: boolean;
}
interface PropsInputTextI extends PropsI {
  component: string;
  fontSize: string | undefined;
}

interface PropsInputNumberI {
  disabled: boolean;
  typeField?: string;
}

export const InputTextDiv = styled('p', {
  shouldForwardProp: props => props !== 'component'
})<PropsInputTextI>(({ theme, component, fontSize, error }) => ({
  fontFamily: 'Inter',
  fontSize:
    fontSize === 'NORMAL'
      ? 12
      : fontSize === 'HEADING_3'
      ? 12
      : fontSize === 'HEADING_2'
      ? 14
      : fontSize === 'HEADING_1'
      ? 16
      : 12,
  fontWeight:
    fontSize === 'HEADING_3' ||
    fontSize === 'HEADING_2' ||
    fontSize === 'HEADING_1'
      ? 'bold'
      : 'normal',
  color: theme.customText.high,
  padding:
    component === 'inputvalue'
      ? `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(
          0.5
        )} ${theme.spacing(1)}`
      : `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  border:
    component === 'text'
      ? '1px solid white'
      : error
      ? `1px solid #DE294C`
      : `1px solid #D6DFEB`,
  paddingLeft: component === 'options' ? 28 : `${theme.spacing(1)}`,
  borderRadius: 4,
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow'
  ]),

  // vertical center text with dynamic height of element
  minHeight: 18,
  lineHeight: 1.5,

  // set input width
  width:
    component === 'description'
      ? 294
      : component === 'options'
      ? 274
      : component === 'text'
      ? 602
      : component === 'tableDescription'
      ? 496
      : component === 'inputvalue'
      ? 134
      : component === 'tableName'
      ? 92
      : 54,
  cursor: 'text',

  textAlign: component === 'inputvalue' ? 'right' : 'left',

  backgroundColor: component === 'inputvalue' ? '#E3F3FF' : '',

  '&:empty::before': {
    fontWeight: 'normal',
    fontSize: 12
  },

  '&:focus': {
    outline: 0,
    borderColor: component === 'text' ? 'none' : theme.palette.primary.main
  },

  // option
  background:
    component === 'options'
      ? `url("${images.radioOffComposer}") no-repeat`
      : '',
  backgroundPosition: component === 'options' ? 'left 8px center' : 'none'
}));

export const InputField = styled('div')({
  position: 'relative'
});

export const IconMore = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 14,
  transform: 'translateY(-50%)',
  right: theme.spacing(0),

  '& > div': {
    color: `${theme.palette.action.disabled} !important`
  }
}));

export const InputNumber = styled(NumberFormat)<PropsInputNumberI>(
  ({ theme, disabled, typeField }) => ({
    fontFamily: 'Inter',
    fontSize: 12,
    color: theme.customText.high,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(
      0.5
    )} ${theme.spacing(1)}`,
    border: `1px solid #D6DFEB`,
    borderRadius: 4,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),

    // vertical center text
    minHeight: 18,
    lineHeight: 1.5,

    // set input width
    width: typeField === 'numericalField' ? 210 : 134,
    cursor: disabled ? 'default' : 'text',

    '&:focus': {
      outline: 0,
      borderColor: theme.palette.primary.main
    },

    backgroundColor: disabled
      ? theme.palette.action.disabledBackground
      : '#E3F3FF',
    '&::placeholder': {
      color: theme.palette.action.disabled
    },
    textAlign: 'right'
  })
);

export const InputProperties = styled('div')<PropsI>(({ theme, error }) => ({
  height: 26,
  lineHeight: 1.8,
  padding: '4px 8px',
  border: error ? '1px solid #DE294C' : '1px solid #E0E6EB',
  borderRadius: 4,
  fontFamily: 'Inter',
  fontSize: 12,
  letterSpacing: '0.4px',
  fontWeight: 400,

  // overflow behavior like html input element
  overflow: 'auto',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',

  '&:focus': {
    outline: 'none',
    border: error ? '1px solid #D51F3C' : '1px solid #118CFF'
  },

  '&::-webkit-scrollbar': {
    display: 'none' /* Chrome, Safari and Opera */
  },

  scrollbarWidth: 'none' /* Firefox */,
  msOverflowStyle: 'none' /* IE and Edge */
}));
