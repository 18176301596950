import styled from 'styled-components';

interface ElementProps {
  isShow?: boolean;
  underline?: boolean;
  isUploading?: boolean;
}

export const Backdrop = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
});

export const NavBarVeil = styled.div({
  width: '100vw',
  overflow: "auto",
  // height: '64px',
});

export const Body = styled.div({
  height: 'calc(100% - 64px)',
  overflowY: 'hidden',
});

export const ModalBox = styled.div<ElementProps>((props) => ({
  backgroundColor: '#FFFFFF',
  width: '22.5rem',
  boxSizing: 'border-box',
  webKitBoxSizing: 'border-box',
  padding: '1rem',
  borderRadius: 8,
  boxShadow: '0px 3px 6px rgba(120, 138, 155, 0.25)',
  position: 'fixed',
  top: '50%',
  left: '50%',
  margin: 'auto',
  opacity: props.isShow ? 1 : 0,
  transform: 'translate(-50%, -50%)',
  zIndex: props.isShow ? 3 : -1,
}));

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  '& > p': {
    color: '#353C68',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '2rem',
    letterSpacing: 0.15,
  },
  '& > svg': {
    cursor: 'pointer',
  },
});

export const GridContent = styled.div({
  display: 'grid',
  gridTemplateColumns: '1.1fr 0.9fr',
  columnGap: '0.5rem',
});

export const ProfileSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  '& > div:last-child': {
    marginTop: '1rem',
  },
  '& > div > h5': {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.4,
    color: '#9A9DB3',
  },
});

export const InlineContent = styled.div({
  display: 'flex',
  gap: 9,
  alignItems: 'center',
  marginTop: 4,
  '& > p': {
    fontSize: 14,
    fontWeight: 600,
    color: '#353C68',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 160
  },
  '& > img': {
    height: 20,
    width: 20,
    objectFit: 'scale-down',
    cursor: 'pointer',
  },
});

export const ImageSection = styled.div<ElementProps>(({ isUploading }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > div': {
    height: '6.5rem',
    width: '6.5rem',
    textAlign: 'center',
    position: 'relative',
    '& > img': {
      height: '100%',
      width: '100%',
      borderRadius: '50%',
      objectFit: 'scale-down',
      marginBottom: '0.5rem',
      cursor: 'pointer',
      opacity: isUploading ? 0.5 : 1,
      pointerEvents: isUploading ? 'none' : 'auto',
    },
    '& > p': {
      fontSize: 14,
      lineHeight: '16px',
      color: '#9A9DB3',
      letterSpacing: 0.4,
    },
    '& > div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 'auto',
    },
  },
}));

export const ActionWrapper = styled.div({
  display: 'flex',
  gap: 8,
  '& > button': {
    fontWeight: 700,
  },
});

export const Text = styled.p<ElementProps>((props) => ({
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: 0.4,
  color: '#686D8E',
  marginBottom: '1rem',
  textDecoration: props.underline ? 'underline' : 'none',
  cursor: props.underline ? 'pointer' : 'default',
}));

export const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '0.5rem',
});

export const ErrMessage = styled.p({
  fontFamily: 'Inter',
  fontSize: 12,
  letterSpacing: '0.4px',
});

export const LoadingContainer = styled.div({
  width: "100%",
  height: "100%",
  marginTop: "calc(50% - 10px)",
  textAlign: "center",
});
