import { MouseEvent, useEffect, useState } from 'react';
import {
  fetchCalculationInputFileList,
  fetchInputFileList,
  inputFileInfoRename
} from '../../store/actions/inputFileAction';
import {
  setActionReport,
  showHideModalAction
} from '../../store/appState/global/globalActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { BREADCRUMB_PAGE_TYPE } from '../../store/breadCrumb/managerNavigation/reducer';
import { BreadCrumbs } from '../../components/atoms/breadCrumbs';
import { ChildWrapper } from '../../components/organisms/hoc/elements';
import ContentTable from '../../components/molecules/ContentTable';
import HelperButton from '../../components/molecules/HelperButton';
import InputFileInfoPanel from '../../components/organisms/InputFileInfoPanel';
import { LoadingSpinner } from '../../components/atoms/loading';
import ModalAction from '../../components/molecules/modal/modalAction';
import { ReduxState } from '../../store';
import { TableContentSection } from '../../pageElements/calculations';
import ToastInfo from '../../components/molecules/toast';
import { getBreadcrumb } from '../../store/breadCrumb/managerNavigation/action';
import inputFileNav from '../../components/organisms/hoc/inputFileManager';
import { getUserId } from '../../utils/storage';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';

const OUT_OF_RANGE_INDEX = -1;

const InputFile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { calculationId } = useParams<{ calculationId: string }>();
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
  const pathName = location.state?.pathName;

  // state
  const [selectedItemIndex, setSelectedItemIndex] =
    useState<number>(OUT_OF_RANGE_INDEX);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // selector
  const { calculationInfo, data, tableData, isLoading } = useSelector(
    (state: ReduxState) => state.inputFileManager,
    shallowEqual
  );

  const { breadcrumb } = useSelector(
    (state: ReduxState) => state.managerNavigation,
    shallowEqual
  );

  const { status, color, message } = useSelector(
    (state: ReduxState) => state.toast,
    shallowEqual
  );

  const { openModalAction, typeAction, actionReport } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  // effect
  useEffect(() => {
    const unsubscribe = handleFetchResources(calculationId);
    return unsubscribe;
  }, [calculationId]);

  useEffect(() => {
    if (!calculationInfo.folderId) return;
    dispatch(
      getBreadcrumb(calculationInfo.folderId, BREADCRUMB_PAGE_TYPE.INPUT_FILE, {
        id: calculationInfo.calculationId,
        title: calculationInfo.title
      })
    );
  }, [calculationInfo]);

  const handleFetchResources = (calculationId: string) => {
    try {
      Promise.all([
        dispatch(fetchCalculationInputFileList(calculationId))
      ]).then(_ => _);
    } catch (error: any) {
      console.error(error);
    }
  };

  // handler
  const handleSelectItem = (e: MouseEvent, itemIndex: number) => {
    e.stopPropagation();
    setSelectedItemIndex(itemIndex);
    setSelectedItem(data[itemIndex]);
  };

  const handleDoubleClick = (itemIndex: number) => {
    const isUserInputFile = Boolean(getUserId() === data[itemIndex]?.createdBy);

    if(!isUserInputFile && isDriveCalculation) {
      return dispatch(toastSetupActionPopUp("ERROR_TERTIARY", "You are not authorized to access this page"));
    }

    history.push({
      pathname:  `/logger/${calculationId}/${data[itemIndex]?.id}`,
      state: {
        ...(isDriveCalculation && { calculationType: 'BUSINESS' })
      }
    });
  };

  useEffect(() => {
    const unsubscribe = handleActionReport(actionReport);
    return unsubscribe;
  }, [actionReport.types]);

  const handleActionReport = (actionReport: any) => {
    if (actionReport.types === 'DELETE_INPUT_FILE_SUCCESS') {
      handleUnselectItem();
    }
    setTimeout(() => {
      dispatch(setActionReport('', {}));
    }, 500);
  };

  const handleUnselectItem = () => {
    setSelectedItemIndex(OUT_OF_RANGE_INDEX);
    setSelectedItem(null);
  };

  const submitModal = async (e: any) => {
    if (typeAction === 'renInputFile') {
      try {
        const resultInputFileInfoRename: any = await dispatch(
          inputFileInfoRename(selectedItem.id, e)
        );

        if (resultInputFileInfoRename === `Success Rename`) {
          dispatch(showHideModalAction(false, ''));
          dispatch(fetchInputFileList(calculationId));
          setSelectedItem((prev: any) => {
            return {
              ...prev,
              title: e
            };
          });
        } else {
          throw resultInputFileInfoRename;
        }
      } catch (error: any) {
        return error.response.data?.message;
      }
    }
  };

  return (
    <>
      <ChildWrapper>
        <BreadCrumbs locations={breadcrumb} pathName={pathName} />
        {isLoading && <LoadingSpinner />}
        <TableContentSection onClick={handleUnselectItem}>
          <ContentTable
            headers={tableData.headers}
            data={tableData.data}
            selectedRow={selectedItemIndex}
            onRowClick={handleSelectItem}
            onDoubleClick={handleDoubleClick}
          />
        </TableContentSection>
        {openModalAction && (
          <ModalAction
            openModal={true}
            submitModal={submitModal}
            itemName={selectedItem === null ? '' : selectedItem?.title}
            itemDescription={''}
          />
        )}
        <ToastInfo status={status} color={color} message={message} />
      </ChildWrapper>
      {selectedItem && (
        <InputFileInfoPanel info={selectedItem} calculation={calculationInfo} isDriveCalculation={isDriveCalculation} />
      )}
      <HelperButton />
    </>
  );
};

export default inputFileNav(InputFile);
