import { MenuItem } from "@mui/material";

import styled from "styled-components";
import { withStyles } from "@mui/styles";

export const CalculationContent = styled.div({
  display: "flex",
  padding: "0 .5rem 0 0 .5rem 0 .5rem",
  height: "calc(100vh - 4.5rem)",
  "& > #wrapper": {
    flexDirection: 'column',
    padding: "0px 24px",
    display: 'flex',
    width: "100%",

    "& > section#table-wrapper": {
      overflow: 'scroll'
    }
  },
});

export const ChildWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "20px 18px 0",
  overflow: "auto",
});

export const TitleWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "end"
});

export const AccountSettingWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "auto",
});


export const ListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#FBE5EA",
    },
  },
})(MenuItem);

export const ListItemI = withStyles({
  root: {
    "&:hover": {
      color: "#118CFF",
    },
  },
})(MenuItem);
