import { styled } from '@mui/material';
import React from 'react';
import Text from '../../../components/atoms/Text';
import { BAR_CONTENT } from '../../../utils/constant';
import StyledButton from '../../../components/atoms/Button';

const Bar = styled('div')({
  display: 'flex',
  padding: 16,
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F67650',
  color: '#FFF'
});

interface BarPropsI {
  buttonText: string
  onClick: VoidFunction;
}

export const AnnouncementBar = ({ buttonText, onClick }: BarPropsI) => {
  return (
    <Bar>
      <Text variant="body3">{BAR_CONTENT}</Text>
      <StyledButton
        variant="ghost"
        size="small"
        color="secondary"
        style={{ textTransform: 'none' }}
        onClick={onClick}
      >
        {buttonText}
      </StyledButton>
    </Bar>
  );
};
