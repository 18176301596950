import {
  DATATOASTTYPETYPEPOPUP,
  DataToastTypePopUp,
} from './toastType';

interface initialStateI {
  dataToast: any;
}

const initialState: initialStateI = {
  dataToast: {
    isOpen: false,
    type: null,
    label: null,
    position: {
      horizontal: 'center',
      vertical: 'top'
    }
  }
};

type Action = DataToastTypePopUp;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case DATATOASTTYPETYPEPOPUP:
      return { ...state, dataToast: action.data };
    default:
      return state;
  }
}