import React from 'react'
import { ChatDataT } from '../../../store/reducers/aceReducers'
import { UserQuestion, AceAnswer } from '../components';

export interface ChatItemI extends ChatDataT {
  isLoading?: boolean
  error?: boolean
  isOffline: boolean;
}

export const ChatItem = ({
  question,
  ...restProps
}: ChatItemI) => {
  return (
    <>
      <UserQuestion question={question} />
      <AceAnswer {...restProps} />
    </>
  )
}
