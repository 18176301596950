//TODO: determine which function could be here
import { Menu } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { PageBox, PageItem } from '../../../pageElements/composer';
import { ReduxState } from '../../../store';
import { IPageMenu } from './page-menu.type';

export const PageMenu = ({
  open,
  handlerShowPageMenu,
  anchorPosition,
  handlerRenamePage,
  handlerDeletePage,
  handlerShowToLoggerPage
}: IPageMenu) => {
  const { activePage, dataPages } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  return (
    <Menu
      open={open}
      onClose={() => handlerShowPageMenu('close')}
      anchorEl={anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: 0.5
        }
      }}
    >
      <PageBox>
        {dataPages.length > 0 && dataPages[activePage]?.hidden ? (
          <PageItem onClick={() => handlerShowToLoggerPage('UNHIDE')}>
            Unhide for Log View
          </PageItem>
        ) : (
          <PageItem onClick={() => handlerShowToLoggerPage('HIDE')}>
            Hide for Log View
          </PageItem>
        )}
        <PageItem
          onClick={(e: React.SyntheticEvent) => handlerRenamePage('CLICK', e)}
        >
          Rename Page
        </PageItem>
        <PageItem
          onClick={(e: React.SyntheticEvent) => handlerDeletePage('CLICK', e)}
        >
          Delete Page
        </PageItem>
      </PageBox>
    </Menu>
  );
};
