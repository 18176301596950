import { Box } from '@mui/system';
import { isValidURL } from '../../../../utils/helpersFunction';
import Text from '../../../atoms/Text';

import { LineWrapper, VanillaTextArea } from './styles';
import { IColorText, ILinkProps } from './type';

const Link = ({
  register,
  id,
  typeInput,
  defaultValue,
  placeholder,
  error,
  onFocus,
  onBlur,
  dataLink,
  active,
  handleOpenNewTab
}: ILinkProps) => {
  if (defaultValue && !active) {
    const validUrl = isValidURL(defaultValue);
    return (
      <Box
        id={id}
        onClick={() => (validUrl ? handleOpenNewTab(defaultValue) : null)}
        style={{ margin: 8 }}
      >
        <Text
          variant="body3"
          id={id}
          style={{
            color: validUrl ? IColorText.Blue : IColorText.Black,
            overflowWrap: 'anywhere'
          }}
        >
          {dataLink ? dataLink : defaultValue}
        </Text>
      </Box>
    );
  }

  return (
    <LineWrapper>
      <VanillaTextArea
        {...(register && id ? register(id) : undefined)}
        id={id}
        typeInput={typeInput}
        defaultValue={defaultValue}
        placeholder={placeholder}
        // onError={error}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </LineWrapper>
  );
};

export default Link;
