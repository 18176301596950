import { localDatetimeTable } from "../../utils/dateConvert";
import * as inputFileAction from "../types/inputFileTypes";

interface TableDataPropsI {
  headers: Array<string>;
  data: Array<Record<string, unknown>>;
}

export interface InputFileStateI {
  isLoading: boolean;
  calculation: any;
  data: Array<any>;
  tableData: TableDataPropsI;
  errorMessage: string;
  inputFileInfo: any;
  inputFileById: any;
  report: any;
  calculationInfo: any;
}

const initialState: InputFileStateI = {
  isLoading: false,
  calculation: null,
  data: [],
  tableData: {
    headers: [],
    data: [],
  },
  errorMessage: "",
  inputFileInfo: {},
  inputFileById: {},
  report: {},
  calculationInfo: {
    calculationId: '',
    creator: null,
    description: '',
    folderId: '',
    principal: null,
    title: '',
    updatedTime: null,
    updater: null
  }
};

const inputFileReducer = (
  state: InputFileStateI = initialState,
  action: any
) => {
  switch (action.type) {
    case inputFileAction.FETCH_INPUT_FILE_BY_CALCULATION_ID:
      return {
        ...state,
        isLoading: true,
      };
    case inputFileAction.FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case inputFileAction.FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case inputFileAction.FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case inputFileAction.FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_SUCCESS:
      const payload = action.data;
      const { items } = payload;

      // generate table data
      const headers = ["Name", "Date Created", "Notes"];
      const content = items.map((item: any) => {
        return [item.title, localDatetimeTable(item.createdTime), item.notes];
      });
      const tableData = { headers, data: content };

      delete payload.items;

      return {
        ...state,
        isLoading: false,
        data: items,
        tableData,
        calculationInfo: payload
      };
    case inputFileAction.FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case inputFileAction.FETCH_CALCULATION:
      return {
        ...state,
        isLoading: true,
      };
    case inputFileAction.FETCH_CALCULATION_SUCCESS:
      return {
        ...state,
        calculation: action.data,
      };
    case inputFileAction.FETCH_CALCULATION_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case inputFileAction.SET_INPUT_FILE_BY_ID:
      return {
        ...state,
        inputFileById: action.data,
      };

    case inputFileAction.SET_INPUT_FILE:
      return {
        ...state,
        inputFileInfo: action.data,
      };
    case inputFileAction.SET_REPORT_BY_ID:
      return {
        ...state,
        report: action.data,
      };
    default:
      return state;
  }
};

export default inputFileReducer;
