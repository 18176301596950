import styled from "styled-components";
import { windowWidth } from "../../../utils/constant";

interface Props {
  inputValueType: string;
}

interface DirectionI {
  column?: boolean
}

export const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "684px",
  left: 0,
  position: "absolute",
  top: 0,
  right: 0,
  transform: 'translateY(-200%)',
  textAlign: 'center'
});

export const BodyMainContent = styled.div({
  display: "flex",
  flexDirection: "column",
  minHeight: "854px",
  overflow: "hidden",
  padding: "32px",
});


export const LatexSection = styled.div({
  flexDirection: 'row',
  display: 'flex',
  paddingBottom: "4px",
  gap: "8px",
});


export const Header = styled.div({
  flexDirection: 'column',
  display: 'flex',
  textAlign: 'left'
});

export const ContentWrapper = styled.div<DirectionI>((props) => ({
  display: "flex",
  flexDirection: props.column ? 'column' : 'row',
  padding: '4px 0',
  alignItems: props.column ? '' : 'center'
}));

export const Description = styled.div({
  width: "340px",
  minHeight: "18px",
  "& > span": {
    overflowWrap: "anywhere"
  },
  textAlign: 'left',
});

export const Notation = styled.p({
  width: 64,
  paddingLeft: 12,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: "18px",
  textAlign: "left",
});

export const Input = styled.input<Props>((props) => ({
  padding: "4px 8px",
  margin: "0",
  width: "160px",
  fontSize: "12px",
  gap: "4px",
  border: "1px solid #e0e6eb",
  height: "28px",
  borderRadius: "6px",
  textAlign: props.inputValueType.includes('TEXT') ? "left" : "right",
  "&.danger": {
    border: "1px solid red !important",
  },
  "&.warning": {
    border: "1px solid orange !important",
  },
}));

export const BlankInput = styled.div({
  padding: "0px 8px",
  margin: "0",
  width: "160px",
  gap: "4px",
  height: "4px",
});

export const SubHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const RadioNotation = styled.p({
  width: 64,
  paddingLeft: 12,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: "18px",
  textAlign: "left",
});

export const Unit = styled.p({
  width: 64,
  height: "18px",
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: "0px 0px 0px 4px",
});

export const TableContent = styled("div")({
  wordBreak: "break-word",
  maxHeight: 276,
  maxWidth: 580,
  overflow: 'auto',
  'table > tbody': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#29333D',
  }
});
