import { Divider } from '@mui/material';
import { RightSectionWrapper } from './RightSectionWrapper';

interface AceRightSectionPropsI {
  isConsent?: boolean;
  isMobile?: boolean;
}

export const AceRightSection = ({ isConsent, isMobile }: AceRightSectionPropsI) => {
  return (
    <>
      <Divider orientation="vertical" flexItem />
      <RightSectionWrapper isConsent={isConsent} isMobile={isMobile} />
    </>
  );
};
