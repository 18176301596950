import { styled } from "@mui/material";
import Input from "../../atoms/Input";

type IconPropsI = {
    position: "end" | "start"
};

export const InputWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
});

export const TextIcon = styled(Input)({
    '.MuiOutlinedInput-root': {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

export const IconWrapper = styled("div", {
	shouldForwardProp: (props) => props !== "position"
})<IconPropsI>(({theme, position})=>({
    display: "flex",
    alignItems: "center",
    padding: 14.5,
    backgroundColor: theme.palette.action.disabledBackground,
    color: "#8D96AA",
    justifyContent: "center",
    borderTopLeftRadius: position === "start" ? 5 : 0,
    borderBottomLeftRadius: position === "start" ? 5 : 0,
    borderTopRightRadius: position === "end" ? 5 : 0,
    borderBottomRightRadius: position === "end" ? 5 : 0,
    justifyItems: "center",
    border: `2px solid ${theme.palette.action.disabledBackground}`,
    marginRight: position === "start" ? 8 : 0,
    marginLeft: position === "end" ? 8 : 0,
}));