import { styled } from "@mui/material";

export const BlankWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center'

}));

export const ButtonEWrapper = styled('div')(({ theme }) => ({
    display: "flex",
    marginTop: 40
}));
