import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { makeStyles } from "@mui/styles";
const defaultTheme = createTheme();

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
    h0: true;
    body3: true;
    smallRegular: true;
    smallBold: true;
    smallLight: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  title: React.CSSProperties;
  h0: React.CSSProperties;
  body3: React.CSSProperties;
  smallRegular: React.CSSProperties;
  smallBold: React.CSSProperties;
  smallLight: React.CSSProperties;
}

export const theme = createTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      mobile: 0,
      tablet: 768,
      laptop: 1366,
      desktop: 1920,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#FFF",
    },
    primary: {
      main: "#118CFF",
      light: "rgba(17, 140, 255, 0.5)",
      dark: "#118CFF",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#F0F2F5",
      light: "#F0F2F5",
      dark: "#F0F2F5",
      contrastText: "#353C68",
    },
    ghost: {
      main: "#F0F2F5",
      light: "#F0F2F5",
      dark: "#F0F2F5",
      contrastText: "#FFF",
    },
    info: {
      main: '#0288d1',
      light: '#E3F3FF',
      dark: '#01579b',
      contrastText: '#FFF'
    },
    text: {
      primary: "#29333D",
      secondary: "#353C68",
      disabled: "#A3B3C2",
    },
    action: {
      hover: "transparent",
      hoverOpacity: 0.01,
      disabled: "#B3BFCC",
      disabledBackground: "#F0F2F5",
      disabledOpacity: 1,
    },
    // alert
    error: {
      main: "#DE294C",
    },
    // warning
    warning: {
      main: "#FF9800",
    },
    // success
    success: {
      main: "#37D046",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "inter",
      color: "#29333D",
    },
    title: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "30px",
    },
    h3: {
      fontWeight: "bold",
      fontSize: 18,
      lineHeight: "27px",
    },
    h4: {
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "24px",
    },
    h5: {
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "21px",
    },
    h6: {
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: "18px",
    },
    body1: {
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
    },
    body2: {
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "21px",
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: "20px",
      lineHeight: "30px",
    },
    subtitle2: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
    },
    button: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.75px",
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "10px",
      lineHeight: "16px",
    },
    overline: {
      fontSize: "10px",
      lineHeight: "16px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    // injected typography variant
    h0: {
      fontWeight: "bold",
      fontSize: 28,
      lineHeight: "42px",
    },
    body3: {
      fontSize: 12,
      lineHeight: '18px',
    },
    smallRegular: {
      fontSize: 10,
      lineHeight: '15px',
    },
    smallBold: {
      fontWeight: "bold",
      fontSize: 10,
      lineHeight: 1.5,
    },
    smallLight: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 400
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.25px",

          marginTop: 24,
          marginBottom: 4,

          color: "#29333D",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px !important",
          fontSize: 12,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
      variants: [
        {
          props: { variant: "ghost" },
          style: {
            border: "1px solid #118CFF",
            backgroundColor: "#FFF",
            color: "#118CFF",

            "&:hover": {
              backgroundColor: "#E3F3FF",
            },

            "&:disabled": {
              backgroundColor: "#E0E6EB",
              border: "1px solid #D6DFEB",
              color: "#B3BFCC",
            },
          },
        },
        {
          props: { variant: "ghost", color: "secondary" },
          style: {
            border: "1px solid #F67650",
            backgroundColor: "#FEF1EC",
            color: "#F67650",
            textTransform: "none"

            // "&:hover": {
            //   backgroundColor: "#E3F3FF",
            // },

            // "&:disabled": {
            //   backgroundColor: "#E0E6EB",
            //   border: "1px solid #D6DFEB",
            //   color: "#B3BFCC",
            // },
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "7px 16px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "9.5px 16px",
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "12px 16px",
          },
        },
        {
          props: { color: "error" },
          style: {
            color: "#DE294C",
            backgroundColor: "#FBE5EA",
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#E3F3FF",
            borderRadius: 4,
          },
        },
      },
    },
  },
  // Action, Role, Tag
  role: {
    blue: "#189EFF",
    green: "#57D9A3",
    purple: "#A092F0",
    orange: "#F88962",
  },
  subscription: {
    business: "#7363E5",
    pro: "#2CC077",
    basic: "#118CFF",
  },
  component: {
    lineDefault: '1px solid #BFC9D9',
  },
  // Typography color
  customText: {
    high: "#29333D",
    medium: "#47596B",
    low: "#5C738A",
    disable: "#A3B3C2",
    default: "#353C68",
    icon: "#D6DFEB",
    icon_high: '#52667A'
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    ghost: Palette["primary"];
  }
  interface PaletteOptions {
    ghost: PaletteOptions["primary"];
  }
  interface Theme {
    role: {
      blue: string;
      green: string;
      purple: string;
      orange: string;
    };
    customText: {
      high: string;
      medium: string;
      low: string;
      disable: string;
      default: string;
      icon: string;
      icon_high: string;
    };
    subscription: {
      business: string,
      pro: string,
      basic: string,
    };
    component: {
      lineDefault: string;
    }
  }
  interface ThemeOptions {
    role?: {
      blue: string;
      green: string;
      purple: string;
      orange: string;
    };
    customText?: {
      high?: string;
      medium?: string;
      low?: string;
      disable?: string;
      default?: string;
      icon?: string;
      icon_high?: string;
    };
    subscription?: {
      business: string,
      pro: string,
      basic: string,
    };
    component?: {
      lineDefault: string;
    }
  }
}

export const useStyle = makeStyles({
  input: {
    fontSize: "14px",
    lineHeight: "0.8px",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
});

export const menuStyle = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  width: 185,
  "& .Mui-selected": {
    background: "#E3F3FF !important",
  },
};
