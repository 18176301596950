import dayjs from "dayjs";

import images from "../../../../assets";
import Logo from "../../../../components/atoms/Logo";
import Text from "../../../../components/atoms/Text";
import { theme } from "../../../../utils/theme";
import { BoxBreak, Container, FlexBox, InfoContainer, InfoTitle, InfoValue, TitleContainer } from "./styles";

interface PageHeaderInterface {
    data: any
}

export default function PageHeader({ data }: PageHeaderInterface) {


    return (
        <Container>
            <FlexBox>
                <TitleContainer>
                    <Text variant="h4" style={{overflowWrap : "anywhere"}}>{data?.calculationTitle}</Text>
                </TitleContainer>
                <InfoContainer>
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Originator</Text>
                    </InfoTitle>
                    <div>
                        <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{data?.createdBy?.name}</Text>
                    </InfoValue>
                </InfoContainer>
                <InfoContainer style={{ flexWrap: 'wrap' }}>
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Date Created</Text>
                    </InfoTitle>
                    <div>
                        <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{dayjs(data?.createdAt).format("DD/MM/YYYY")}</Text>
                    </InfoValue>
                    <BoxBreak />
                    <InfoTitle>
                        <Text variant="body3" color={theme.palette.text.disabled}>Date Printed</Text>
                    </InfoTitle>
                    <div>
                        <Text variant="body3" color={theme.palette.text.disabled}>:</Text>
                    </div>
                    <InfoValue>
                        <Text variant="body3">{ dayjs(new Date()).format("DD/MM/YYYY") }</Text>
                    </InfoValue>
                </InfoContainer>
            </FlexBox>
            <div style={{
                position: 'absolute',
                top: 10,
                right: 56
            }}>
                <Logo src={images.textLogoGrey} width={64} />
            </div>
        </Container>
    );
}