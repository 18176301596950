import React from 'react'
import Icon from '../../../components/atoms/Icon'
import HTMLContent from '../../../components/molecules/HTMLContent'
import { ChatWrapper } from '../styles'

const ACE_INFO = "Hello, I'm Ace, your Assistant for Construction and Engineering. I am here to streamline your construction document interactions. I've been trained on a wide range of industry documents, so feel free to ask me anything, just like you would to a colleague. Need specifics from a contract, report or submittal? Want to clarify a term from a specification? Just ask. I can provide answers in a conversation format to help you find the information you need swiftly. Let's make construction document management more efficient together."
const ACE_PUBLIC_INFO = "Hello, I'm Ace, your Assistant for Construction and Engineering. I am here to streamline your construction document interactions. I've been trained on a wide range of industry documents, so feel free to ask me anything, just like you would to a colleague. Need specifics from a contract, report or submittal? Want to clarify a term from a specification? Just ask. I can provide answers in a conversation format to help you find the information you need swiftly. Let's make construction document management more efficient together. <br /> <br /> I am a version of Ace that has been trained on a House Remodel Project. To start, you can ask questions like: <ul><li>Tell me about the project.</li><li> Who are the main stakeholders of the project?</li><li>What was the final budget of the remodel?</li></ul>"

export const AceInfo = () => {
	const isPublic = /public/.test(window.location.pathname);
	return (
		<ChatWrapper isAce={true}>
			<div id="chatbox">
				<div id="taylor">
					<Icon icon="talkTaylor" color="#118CFF" size={16} cursor="auto" />
				</div>
				<div id="message">
					<HTMLContent
						text={(isPublic ? ACE_PUBLIC_INFO : ACE_INFO).replace(/\n/g, '<br />')}
					/>
				</div>
			</div>
		</ChatWrapper>
	)
}
