export const FETCHING_DATA = "FETCHING_DATA";
export const SHOW_HIDE_LOGGER_MODAL_ACTION = 'SHOW_HIDE_MODAL_ACTION';
export const FETCH_INPUT_FILE_BY_CALCULATION_ID = 'FETCH_INPUT_FILE_BY_CALCULATION_ID';
export const FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS = 'FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS';
export const FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED = 'FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';
export const FETCHING_INPUT_FILE_SUCCESS = 'FETCHING_INPUT_FILE_SUCCESS';
export const FETCHING_INPUT_FILE_FAILED = 'FETCHING_INPUT_FILE_FAILED';
export const CLEAR_ALL_INPUTS = 'CLEAR_ALL_INPUTS';
export const CLEAR_INPUTS_AT_PAGE = 'CLEAR_INPUTS_AT_PAGE';
export const SET_LOGGER_FORM_VALUES = 'SET_LOGGER_FORM_VALUES';
export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SAVING_INPUT_FILES = "SAVING_INPUT_FILES";
export const LOGGER_ACTION_LOADING = "LOGGER_ACTION_LOADING";
export const REFRESH_INPUT_FILE = "REFRESH_INPUT_FILE";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const FORM_STATE_ERROR = "FORM_STATE_ERROR";
export const SAVING_TABLE = "SAVING_TABLE";
export const TABLE_STATE = "TABLE_STATE";
export const RENDERING_LATEX = "RENDERING_LATEX";
export const LOGGER_ERROR_FORM = "LOGGER_ERROR_FORM";
export const LOGGER_VARIABLE_INPUT = "LOGGER_VARIABLE_INPUT";



export interface FetchingDataI {
  type: typeof FETCHING_DATA;
  payload: boolean;
}

export interface ShowHideLoggerModalActionI {
  type: typeof SHOW_HIDE_LOGGER_MODAL_ACTION;
  isVisible: boolean;
  modalActionType: string;
}

export interface FetchInputFileByCalculationIdI {
  type: typeof FETCH_INPUT_FILE_BY_CALCULATION_ID
}

export interface FetchInputFileByCalculationIdSuccessI {
  type: typeof FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS,
  data: Array<any>,
}

export interface FetchInputFileByCalculationIdFailedI {
  type: typeof FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED,
  error: string,
}

export interface SetErrorMessageI {
  type: typeof SET_ERROR_MESSAGE,
  error: string
}

export interface RemoveErrorMessageI {
  type: typeof REMOVE_ERROR_MESSAGE,
  error: string
}

export interface FetchInputFileSuccessI {
  type: typeof FETCHING_INPUT_FILE_SUCCESS,
  payload: any
}

export interface FetchInputFileFailedI {
  type: typeof FETCHING_INPUT_FILE_FAILED,
  error: string
}

export interface ClearAllInputsI {
  type: typeof CLEAR_ALL_INPUTS,
  clearedInputFile: any
}

export interface ClearInputsAtPageI {
  type: typeof CLEAR_INPUTS_AT_PAGE,
  clearedInputFile: any
}

export interface SetLoggerFormValuesI {
  type: typeof SET_LOGGER_FORM_VALUES,
  payload: Array<any>
}

export interface SavingInputFileI {
  type: typeof SAVING_INPUT_FILES,
  payload: boolean
}

export interface SetSelectedPageI {
  type: typeof SET_SELECTED_PAGE,
  pageIndex: number
}


export interface loggerActionLoadingI {
  type: typeof LOGGER_ACTION_LOADING,
  payload: boolean
}

export interface refreshInputFileI {
  type: typeof REFRESH_INPUT_FILE,
  payload: any
}

export interface logoUploadingI {
  type: typeof UPLOAD_LOGO,
  payload: any,
}

export interface FormStateErrorI {
  type: typeof FORM_STATE_ERROR,
  payload: any,
}

export interface SavingTableI {
  type: typeof SAVING_TABLE,
  payload: any,
}

export interface TableStateI {
  type: typeof TABLE_STATE,
  payload: any,
}

export interface RenderingLatexI {
  type: typeof RENDERING_LATEX,
  payload: any,
}

export interface LoggerErrorFormI {
  type: typeof LOGGER_ERROR_FORM,
  payload: any,
}

export interface LoggerVariableInputI {
  type: typeof LOGGER_VARIABLE_INPUT;
  payload: any;
}

export type LoggerAction = ShowHideLoggerModalActionI | FetchInputFileSuccessI | FetchInputFileFailedI | FetchInputFileByCalculationIdI | FetchInputFileByCalculationIdSuccessI | FetchInputFileByCalculationIdFailedI | SavingInputFileI | ClearAllInputsI | ClearInputsAtPageI | SetSelectedPageI | loggerActionLoadingI | refreshInputFileI | logoUploadingI | FormStateErrorI | SavingInputFileI | SavingTableI | TableStateI | RenderingLatexI | LoggerErrorFormI | LoggerVariableInputI;
