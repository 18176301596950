import ErrorPage from "../pages/error/ErrorPage";

interface ErrorDataI {
  status: string,
  message: string,
  errorMessage: string,
  buttonLabel: string
}

const ErrorFallback = ({ error }: any) => {
  const errorProps: ErrorDataI = {
    status: "",
    message: "Oops... something wrong",
    errorMessage: error.message,
    buttonLabel: "Back to Home"
  };
  return <ErrorPage {...errorProps} />;
};

export default ErrorFallback;