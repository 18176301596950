import { LinearProgress, Box } from '@mui/material';
import {
  BUSINESS,
  BUSINESS_PLUS,
  PRO_PLUS,
  ENTERPRISE,
  ACE
} from '../../../utils/constant';
import Text from '../../atoms/Text';

export interface ILimit {
  current: number;
  limit: number;
}

export interface IUsageBar {
  plan: string;
  personal: ILimit;
  calcPerDrive: ILimit;
  teamDriveMember: ILimit;
}
export const UsageBar = ({
  personal,
  plan,
  teamDriveMember,
  calcPerDrive
}: IUsageBar) => {
  const { current = 0, limit = 0 } = personal;
  const isUnlimited =
    plan === PRO_PLUS ||
    plan === BUSINESS ||
    plan === BUSINESS_PLUS ||
    plan === ENTERPRISE ||
    plan === ACE;
  const progressMenuActionNonBusiness = [
    {
      title: 'Calculation files',
      color: 'inherit',
      variant: 'body3'
    },
    {
      title:
        (limit === null || limit === 0) && isUnlimited
          ? 'Unlimited'
          : `${current}/${limit}`,
      color: 'inherit',
      variant: 'body3'
    }
  ];

  if (plan === BUSINESS || plan === BUSINESS_PLUS || plan === ACE)
    return (
      <Box>
        <LinearProgress
          value={
            ((teamDriveMember?.current ?? 0) * 100) /
            (teamDriveMember?.limit ?? 0)
          }
          variant="determinate"
          style={{ height: 8, borderRadius: 12 }}
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 12
          }}
        >
          <Text style={{ paddingTop: 8 }} variant="body3">
            Business collaborators
          </Text>
          <Text style={{ paddingTop: 8 }} variant="body3">
            {teamDriveMember?.limit === null ||
            teamDriveMember?.limit === 0 ||
            plan === BUSINESS_PLUS
              ? 'Unlimited'
              : `${teamDriveMember?.current ?? 0} / ${
                  teamDriveMember?.limit ?? 0
                }`}
          </Text>
        </Box>

        <LinearProgress
          value={
            ((calcPerDrive?.current ?? 0) * 100) / (calcPerDrive?.limit ?? 0)
          }
          variant="determinate"
          style={{ height: 8, borderRadius: 12 }}
        />
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text style={{ paddingTop: 8 }} variant="body3">
            Calculations per drive
          </Text>
          <Text style={{ paddingTop: 8 }} variant="body3">
            {calcPerDrive?.limit === null ||
            calcPerDrive?.limit === 0 ||
            plan === BUSINESS_PLUS
              ? 'Unlimited'
              : `${calcPerDrive?.current ?? 0} / ${calcPerDrive?.limit ?? 0}`}
          </Text>
        </Box>
      </Box>
    );

  return (
    <Box>
      <LinearProgress
        value={((current ?? 0) * 100) / (limit ?? 0)}
        variant="determinate"
        style={{ height: 8, borderRadius: 12 }}
      />
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        {progressMenuActionNonBusiness.map(item => (
          <Text
            key={item.title}
            style={{ paddingTop: 8, color: item.color }}
            variant="body3"
          >
            {item.title}
          </Text>
        ))}
      </Box>
    </Box>
  );
};
