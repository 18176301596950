import React from 'react';

import { VanillaTextArea } from './styles';

import { TextPropsI } from './type';

const TextArea = ({
  register,
  id,
  defaultValue,
  placeholder,
  // error,
  onFocus,
  onBlur
}: TextPropsI) => {
  return (
    <VanillaTextArea
      {...(register && id ? register(id) : undefined)}
      id={id}
      defaultValue={defaultValue}
      placeholder={placeholder}
      // errorState={error} // VanillaTextArea only accept string not boolean value
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default TextArea;
