import * as React from "react";

export const TalkTaylor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.518 21c-.49-1.598-1.229-2.789-2.217-3.573-1.483-1.177-3.838-.445-4.708-1.66-.87-1.213.609-3.445 1.128-4.762.52-1.318-1.99-1.783-1.697-2.157.195-.25 1.464-.969 3.805-2.158C5.494 2.897 7.95 1 12.199 1 18.572 1 21 6.403 21 9.84c0 3.436-2.94 7.138-7.128 7.936-.374.546.165 1.62 1.62 3.224"
    ></path>
  </svg>
);
