import TwoValue from './TwoValue';
import Select from './Select';
import Radio from './Radio';
import Text from './Text';
import { Formula } from './Formula';
import Link from './Link';

export const ComposerInput = {
  TwoValue,
  Select,
  Radio,
  Text,
  Formula,
  Link
};

export default ComposerInput;
