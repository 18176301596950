import { FormControlLabel, Checkbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/styles';

interface PropsI extends CheckboxProps{
    label: string;
}

const DefaultCheckbox = styled(Checkbox)<CheckboxProps>({
    color: "#8B98B1",
});

export default function StyledCheckbox({label, checked, disabled, ...restProps}: PropsI) {
    return <FormControlLabel control={<DefaultCheckbox checked={checked} disabled={disabled} {...restProps}/>} label={label}/>;
}
