import { useTheme } from "@mui/material";
import React from "react";
import Text from "../../../atoms/Text";

import { Content, ContentDetail, ContentDetailItem } from "../elements";
import ExpandIcon from "./ExpandIcon";
import StatusLabel from "./StatusLabel";

interface PropsI {
  title: string;
  info: Array<InfoDetail>;
  expanded: boolean;
  onToggle: () => void;
  detailInfoHead?: any;
}
interface InfoDetail {
  key: string;
  value: Array<InfoDetailValue>;
  isBoldValue?: boolean;
}
interface InfoDetailValue {
  type: string;
  content: string | number;
  flag?: boolean;
}
export const ValueType = {
  LABEL: "LABEL",
  TEXT: "TEXT",
};
const InfoPanelDetailSection = ({
  title,
  info,
  expanded,
  onToggle,
  detailInfoHead,
}: PropsI) => {
  const theme = useTheme();
  return (
    <Content isExpanded={expanded} isLast>
      <div onClick={onToggle}>
        <Text variant="h5">{title}</Text>
        <ExpandIcon expanded={expanded}  style={{ marginTop: '-4px' }} />
      </div>
      {expanded && (
        <>
          <ContentDetail>
            {detailInfoHead?.key && (
              <ContentDetailItem>
                <Text variant="h5"> {detailInfoHead?.key}</Text>
                <Text
                  variant="body3"
                  sx={{ marginTop: '4px' }}
                >
                  {detailInfoHead?.nameDetailInfo}
                </Text>
              </ContentDetailItem>
            )}
            {React.Children.toArray(
              info.map((item) => {
                return (
                  <ContentDetailItem>
                    <Text variant="body3" color={theme.palette.text.disabled}>
                      {item.key}
                    </Text>
                    {React.Children.toArray(
                      item.value.map((detail) => {
                        return detail.type == ValueType.LABEL ? (
                          <StatusLabel
                            type={detail.flag || false}
                            value={detail.content}
                          />
                        ) : (
                          <Text
                            variant={item.isBoldValue ? 'h5': 'body2'}
                            sx={{ marginTop: '4px' }}
                          >
                            {detail.content}
                          </Text>
                        );
                      })
                    )}
                  </ContentDetailItem>
                );
              })
            )}
          </ContentDetail>
        </>
      )}
    </Content>
  );
};

export default InfoPanelDetailSection;
