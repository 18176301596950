import { ExpandMoreRounded } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { SelectDropdownProps } from '../../../pages/loggerRefactor/logger.types';
import { InputSelect, useStyles } from './styles';

const SelectDropdown = ({
  selectedValue,
  items,
  onSelected
}: SelectDropdownProps) => {
  const [selectState, setSelectState] = useState(selectedValue);

  const handleChange = (event: any) => {
    setSelectState(event.target.value);
    if (onSelected) onSelected(event?.target?.value);
  };

  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    }
  };

  return (
    <InputSelect
      name="control-select"
      fullWidth
      size="small"
      onChange={(e: any) => handleChange(e)}
      disableUnderline
      IconComponent={ExpandMoreRounded}
      displayEmpty
      renderValue={value => selectState?.title}
      MenuProps={menuProps}
      classes={{
        icon: classes.selectIcon
      }}
      value={selectState ?? ''}
      defaultValue=""
    >
      {!!items &&
        items.map((item: any) => (
          <MenuItem key={item?.title} value={item} className={item.id} dense>
            {item?.title}
          </MenuItem>
        ))}
    </InputSelect>
  );
};

export default SelectDropdown;
