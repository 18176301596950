import { Menu, styled } from "@mui/material";
import React from "react";

type PropsIconMenu = {
    showMenu?: boolean
} & React.HTMLAttributes<HTMLDivElement>;

export const ComponentWrapper = styled('div')<PropsIconMenu>((props) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 0',
    '& > .dragButton': {
        paddingRight: 4,
    },
    '& > .menuButton': {
        paddingLeft: 4,
    },
    '& > .dragButton, & > .menuButton': {
        width: 12,
        height: 12,
        visibility: props.showMenu ? 'visible' : 'hidden',
        transition: '10s',
    },
    '&:hover': {
        '& > .dragButton, & > .menuButton': {
            visibility: 'visible',
            transition: '0.25s',
        },
    },
}));

export const StyledMenu = styled(Menu)(({theme}) => ({
    '.MuiPaper-root': {
        boxShadow: 'none',
        padding: `${theme.spacing(0)}`,
        background: 'transparent',
    },
    '.MuiList-root': {
        display: 'flex',
    },

    '.MuiMenuItem-root': {
        padding: `${theme.spacing(0.75)}`,
        borderRadius: 4,

        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}));