export const SET_DATA_LIST_INPUT_FILES = "SET_LIST_INPUT_FILES";
export const SET_DATA_INPUT_FILE = "SET_FORM_FIELD";
export const SET_DATA_CALCULATION_INFO = "SET_CALCULATION_INFO";
export const SET_DATA_LOADING = "SET_LOADING";
export const SAVE_INPUT_FILE = "SAVE_INPUT_FILE";
export const SET_ACTION = "SET_ACTION";
export const RESET_ACTION = "RESET_ACTION";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const TOGGLE_PRINT_BUTTON = "TOGGLE_PRINT_BUTTON";

export type loggerActionType = 'CLEAR_ALL' | 'CLEAR_AT_PAGE' | 'SAVE' | 'PRINT';

export interface SET_DATA_LOADING {
    type: typeof SET_DATA_LOADING,
    payload: boolean
}

export interface SET_DATA_INPUT_FILE {
    type: typeof SET_DATA_INPUT_FILE,
    payload: any
}

export interface SET_DATA_LIST_INPUT_FILES {
    type: typeof SET_DATA_LIST_INPUT_FILES,
    payload: Array<any>
}

export interface SET_DATA_CALCULATION_INFO {
    type: typeof SET_DATA_CALCULATION_INFO,
    payload: any
}

export interface SAVE_INPUT_FILE {
    type: typeof SAVE_INPUT_FILE,
}

export interface SET_ACTION {
    type: typeof SET_ACTION,
    payload: any
}

export interface RESET_ACTION {
    type: typeof RESET_ACTION,
    payload: any
}

export interface SET_ACTIVE_PAGE {
    type: typeof SET_ACTIVE_PAGE,
    payload: number
}

export interface TOGGLE_PRINT_BUTTON {
    type: typeof TOGGLE_PRINT_BUTTON,
    payload: boolean
}