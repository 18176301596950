import { useState, useEffect, useRef } from 'react';
import { ReduxState } from '../../store';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ContentSection } from '../../pageElements/calculations';
import BasicTable from '../../components/molecules/paginatedTable';
import { LoadingSpinner } from '../../components/atoms/loading';
import {
  CalculationContent,
  ChildWrapper,
  TitleWrapper
} from '../../components/organisms/hoc/elements';
import { CalculationsSearchBox } from '../../components/molecules/calculationsSearchBox';
import { CalculationsEmptyResults } from '../../components/molecules/calculationsEmptyResults';
import { Divider } from '@mui/material';
import GridView from '../../components/molecules/gridView';
import CalculationInfoPanel from '../../components/organisms/CalculationInfoPanel';
import SideBar from '../../components/molecules/sidebar';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import HelperButton from '../../components/molecules/HelperButton';
import {
  getUserTutorials,
  getTutorialsList,
  setBusinessDriveStatus,
  getBusinessDriveStatus
} from '../../utils/storage';
import Text from '../../components/atoms/Text';
import { BreadcrumbsV2 } from '../../components/molecules/Breadcrumbs';
import { setSelectedTreeItem } from '../../store/appState/sidebar/action';
import { getFolderTree } from '../../store/appState/sidebar/action';
import { handleSearchCalculation } from '../../utils/helpersFunction';
import { checkIsHaveDrive } from '../../store/actions/userAction';
import { getDriveStatus } from '../../store/types/userTypes';
import TagManagerService from '../../service/tagManagerService';
import { UserDriveStatusI } from '../businessDrives/businessDrives.types';

const Calculations = (props: any) => {
  const {
    encal,
    calInfo,
    global,
    login,
    activeItem,
    breadCrumbsData,
    handleActiveFocus,
    isMinimize,
    handleContextMenu,
    handleInActiveFocus,
    isActiveFolder,
    styleView,
    renderMyCalculationMenuContext,
    handleModal,
    fetchResources,
    openPanel,
    openTutorial,
    clearRecentInputFile,
    handlerDragEnd,
    onBeforeCapture,
    selectedTask,
    handleActiveFocusFolder,
    selectedCommIds,
    users
  } = props;
  const { isLoading, data } = encal;
  const {
    personalWorkspaceId,
    enterpriseWorkspaceId,
    communityWorkspaceId,
    subscription
  } = login;
  const { typeView } = global;
  const { userLimit, isHaveManageDrive } = users;
  const history = useHistory();
  const dispatch = useDispatch();
  const { folderId } = useParams<{ folderId: string }>();
  const id = folderId ? folderId : personalWorkspaceId;
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<any>([]);
  const [value, setValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);
  const [renderCount, setRenderCount] = useState<number>(0);
  const firstRender = useRef(true);
  const TOTAL_RENDER = 3;
  const tagManagerService = TagManagerService();

  useEffect(() => {
    const unsubscribe = fetchResources(id, location);
    return unsubscribe;
  }, [personalWorkspaceId, folderId]);

  const { selectedItem } = useSelector(
    (state: ReduxState) => state.sidebar,
    shallowEqual
  );

  useEffect(() => {
    setSearchResult('');
    setValue('');
    setRenderCount(renderCount + 1);

    // update folder tree on sidebar everytime new folder is added
    if (!firstRender.current) {
      dispatch(setSelectedTreeItem(id));
      dispatch(getFolderTree(id));
      setRenderCount(0);
    }
  }, [isLoading, data]);

  useEffect(() => {
    renderCount >= TOTAL_RENDER
      ? (firstRender.current = false)
      : (firstRender.current = true);
  }, [renderCount]);

  const handleDoubleClickItem = (e: any) => {
    if (e.itemType === 'FOLDER') {
      history.push(`/calculations/my-calculation/${e.id}`);
    } else if (e.itemType === 'CALCULATION') {
      if (!e.isComplete) {
        history.push(`/composer/${e.id}/${id}`, { isStartCalculation: true });
        return;
      }
      Promise.all([clearRecentInputFile()])
        .then(response => {
          tagManagerService.loggerDoubleClick();
          history.push(`/logger/${e.id}`);
        })
        .catch(error => console.error(error));
    }
  };

  const handleSearch = () => {
    handleSearchCalculation(
      data,
      value,
      personalWorkspaceId,
      setFilteredData,
      setSearchResult,
      setSearchError
    );
  };

  const handleClickSidebar = () => {
    if (selectedItem === personalWorkspaceId) {
      setSearchResult('');
      setValue('');
    }
  };

  // TUTORIAL SECTION
  const checkHaveDrive = async () => {
    const response: any = await checkIsHaveDrive();
    dispatch(getDriveStatus(response));
    setBusinessDriveStatus(response);
  };

  useEffect(() => {
    const UserTutorials: any = getUserTutorials();
    const checkUserTutorial = async (UserTutorials: any) => {
      try {
        const tutorialPage: any = await getTutorialsList().find(
          (item: any) => item.title === 'Calculation Manager'
        );
        const tutorialDone =
          (await UserTutorials.hasOwnProperty(tutorialPage.id)) === true;
        !tutorialDone && openTutorial(tutorialPage);
      } catch (error: any) {
        console.error(error);
      }
    };
    checkUserTutorial(UserTutorials).catch(error => console.error(error));
    checkHaveDrive();
  }, []);

  return (
    <>
      <CalculationContent>
        <SideBar
          isMinimize={isMinimize}
          onClick={handleClickSidebar}
          isLoading={isLoading && firstRender.current}
        />
        <Divider orientation="vertical" flexItem />
        <ChildWrapper onClick={handleInActiveFocus}>
          <BreadcrumbsV2 dataSource={breadCrumbsData} customLocation={true} />
          {isLoading && <LoadingSpinner />}
          {renderMyCalculationMenuContext(
            subscription?.plan,
            isHaveManageDrive || getBusinessDriveStatus().isHaveManageDrive
          )}
          <TitleWrapper>
            <Text variant="h2" style={{ paddingTop: 24 }}>
              This is where your personal calculations are saved
            </Text>
            <CalculationsSearchBox
              placeholder="Search calculation"
              value={value}
              error={searchError}
              onChange={(e: any) => setValue(e.target.value)}
              onKeyDown={(e: any) => {
                e.key === 'Enter' && handleSearch();
              }}
              onClick={() => {
                handleSearch();
              }}
            />
          </TitleWrapper>
          <ContentSection onClick={handleInActiveFocus}>
            <GridView
              data={searchResult ? filteredData : data}
              typeView={typeView}
              styleView={styleView}
              isActiveFolder={isActiveFolder}
              handleActiveFocus={handleActiveFocus}
              handleContextMenu={handleContextMenu}
              handleDoubleClickItem={handleDoubleClickItem}
              id={id}
            />
            {styleView === 'List' && (
              <BasicTable
                isActiveFolder={isActiveFolder}
                data={searchResult ? filteredData : data}
                onClick={handleActiveFocus}
                onContextMenu={handleContextMenu}
                onDoubleClick={handleDoubleClickItem}
                handlerDragEnd={handlerDragEnd}
                id={id}
                isAllowDrag={!searchResult && true}
                selectedTask={selectedTask}
                selectedCommIds={selectedCommIds}
                onBeforeCapture={onBeforeCapture}
                handleActiveFocusFolder={handleActiveFocusFolder}
              />
            )}
          </ContentSection>
          {filteredData.length <= 0 && searchResult.length > 0 && !isLoading ? (
            <CalculationsEmptyResults
              value={`No Results for "${searchResult}"`}
            />
          ) : null}
        </ChildWrapper>
        {activeItem?.id && calInfo?.calName && openPanel && (
          <CalculationInfoPanel
            activeItem={activeItem}
            calInfo={calInfo}
            personalWorkspaceId={personalWorkspaceId}
            enterpriseWorkspaceId={enterpriseWorkspaceId}
            communityWorkspaceId={communityWorkspaceId}
            handleModal={handleModal}
            clearRecentInputFIle={clearRecentInputFile}
            userLimit={userLimit}
            subscription={subscription}
            {...props}
          />
        )}
      </CalculationContent>
      <HelperButton />
    </>
  );
};

export default calculationsNav(Calculations);
