import BasicMenu from "../Menu";
import { LoggerFormModalAction } from "./action";
import { MenuItems } from "./constant";
import { MenuContainer } from "./styles";
import { setActions, saveInputFile } from "../../../store/actions/logger.actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { checkSubscriptionAndDate } from "../../../utils/helpersFunction";
import { useHistory, useLocation } from "react-router-dom";

const NewLoggerMenu = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location: Record<string, any> = useLocation();
    const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
    const { 
        calculationInfo, 
        listInputFiles,
        inputFile
    } = useAppSelector(state => state.loggerReducer);

    const { userLimit } = useAppSelector(state => state.users);
    const { subscription } = useAppSelector(state => state.login);

    const handleClickMenuItem = async (e: React.MouseEvent<HTMLElement>) => {
        const menuItem = e.currentTarget.innerText;

        switch (menuItem) {
            case 'Load': {
                dispatch(LoggerFormModalAction(
                    "LOAD_INPUT_FILE",
                    {
                        calculationId: calculationInfo.calculationID,
                        calculationTitle: calculationInfo.calculationTitle,
                        inputFileList: listInputFiles,
                        selectedInputFile: null
                    }
                ));
                break;
            }
            case "Save": {
                Promise.all([
                    dispatch(saveInputFile())
                ])
                .then((response: any) => {
                    if(calculationInfo.selectedInputFile.id !== response[0].id) window.location.href = `/logger/${calculationInfo.calculationID}/${response[0].id}`;
                    else dispatch(setActions("SAVE"));
                });
                
                break;
            }
            case "Save As": {
                if (checkSubscriptionAndDate(subscription, userLimit, dispatch) ){
                    dispatch(
                        LoggerFormModalAction("REFACTOR_SAVE_AS_INPUT_FILE", {
                            inputFileId: calculationInfo.selectedInputFile.id
                        })
                    );
                }
                break;
            }
            case "Exit to Input File Manager": {
                if (inputFile.isModified) {
                    // VALUES CHANGED => SHOW CONFIRMATION MODAL
                    dispatch(LoggerFormModalAction(
                        "REFACTOR_EXIT_TO_INPUT_FILE_MANAGER",
                        {
                            history,
                            loggerId: inputFile.id,
                            calId: calculationInfo.calculationID,
                            isDrive: isDriveCalculation
                        }
                    ));
                } else {
                    // NO CHANGES => REDIRECT TO INPUT FILE MANAGER
                    history.push({
                        pathname: `/input-file/${inputFile.id ?? calculationInfo.calculationID}`,
                        state: {
                          ...(isDriveCalculation && { calculationType: 'BUSINESS' })
                        }
                      });
                }
                break;
            }
            case "Clear All Inputs": {
                dispatch(LoggerFormModalAction("MODAL_CLEAR_ALL_INPUTS", {
                    inputFile: inputFile.fields
                }));
                break;
            }
            case "Clear Inputs at Page": {
                dispatch(LoggerFormModalAction("MODAL_CLEAR_INPUTS_AT_PAGE"));
                break;
            }
        }
    };
    
    return (
        <MenuContainer>
         { MenuItems.map((menu: any) => {
            return (
                <BasicMenu key={menu.name} menu={menu} onClickMenuItem={handleClickMenuItem}/>
            );
        })}
        </MenuContainer>
    );
};

export default NewLoggerMenu;