import TagManager from 'react-gtm-module';
import { getUserId } from "../utils/storage";

export default function TagManagerService() {

    const TagManagerService = {
        tagManagerArgs: {
            dataLayer: {
                user_id: getUserId(),
                event: '',
            }
        },

        login: function () {
            this.tagManagerArgs.dataLayer.event = 'login_success';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        copyCalcCommunity: function () {
            this.tagManagerArgs.dataLayer.event = 'copyCalc_success_fromCommunityCalc';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        createCalcNew: function () {
            this.tagManagerArgs.dataLayer.event = 'createCalc_view_fromNewBtn';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        createCalcTiles: function () {
            this.tagManagerArgs.dataLayer.event = 'createCalc_view_fromTiles';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        composerFromEditCalc: function () {
            this.tagManagerArgs.dataLayer.event = 'editCalc_view_fromEditBtn';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        loggerBreadcrumb: function () {
            this.tagManagerArgs.dataLayer.event = 'logger_view_fromBreadcrumb';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        loggerDoubleClick: function () {
            this.tagManagerArgs.dataLayer.event = 'logger_view_fromDoubleClick';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        loggerStartCalculation: function () {
            this.tagManagerArgs.dataLayer.event = 'logger_view_fromStartCalc';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        printAsPDF: function () {
            this.tagManagerArgs.dataLayer.event = 'downloadPdf_success_fromPrintView';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        printBreadcrumb: function () {
            this.tagManagerArgs.dataLayer.event = 'print_view_fromBreadcrumb';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        printReadyToPrint: function () {
            this.tagManagerArgs.dataLayer.event = 'print_view_fromReadyBtn';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        refreshUserId: function () {
            this.tagManagerArgs.dataLayer.event = 'refresh_userID';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        registerFromAds: function () {
            this.tagManagerArgs.dataLayer.event = 'register_view_fromAds';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        registerFromDirectSales: function () {
            this.tagManagerArgs.dataLayer.event = 'register_view_fromDirectSales';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        registerFromOrganic: function () {
            this.tagManagerArgs.dataLayer.event = 'register_view_fromOrganic';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        registerSuccess: function () {
            this.tagManagerArgs.dataLayer.event = 'register_success';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        saveInputFile: function () {
            this.tagManagerArgs.dataLayer.event = 'saveInput_success';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        saveReport: function () {
            this.tagManagerArgs.dataLayer.event = 'saveReport_success';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        logout: function () {
            this.tagManagerArgs.dataLayer.event = 'logout_success';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        logger_view_fromInputFileInfo: function () {
            this.tagManagerArgs.dataLayer.event = 'logger_view_fromInputFileInfo';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        print_view_fromInputFileInfo: function () {
            this.tagManagerArgs.dataLayer.event = 'print_view_fromInputFileInfo';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        downloadPdf_success_fromReportManager: function () {
            this.tagManagerArgs.dataLayer.event = 'downloadPdf_success_fromReportManager';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        print_view_fromBreadcrumb: function () {
            this.tagManagerArgs.dataLayer.event = 'print_view_fromBreadcrumb';
            TagManager.dataLayer(this.tagManagerArgs);
        },

        print_view_fromReportManager: function () {
            this.tagManagerArgs.dataLayer.event = 'print_view_fromReportManager';
            TagManager.dataLayer(this.tagManagerArgs);
        }

        // editCalc_view_fromEditBtn: function() {
        //     this.tagManagerArgs.dataLayer.event = 'editCalc_view_fromEditBtn';
        //     TagManager.dataLayer(this.tagManagerArgs);
        // },

        // copyCalc_success_fromCommunityCalc: function() {
        //     this.tagManagerArgs.dataLayer.event = 'copyCalc_success_fromCommunityCalc';
        //     TagManager.dataLayer(this.tagManagerArgs);
        // },

        // createCalc_view_fromNewBtn: function() {
        //     this.tagManagerArgs.dataLayer.event = 'createCalc_view_fromNewBtn';
        //     TagManager.dataLayer(this.tagManagerArgs);
        // },

        // createCalc_view_fromTiles: function() {
        //     this.tagManagerArgs.dataLayer.event = 'createCalc_view_fromTiles';
        //     TagManager.dataLayer(this.tagManagerArgs);
        // },

        // logger_view_fromBreadcrumb: function() {
        //     this.tagManagerArgs.dataLayer.event = 'logger_view_fromBreadcrumb';
        //     TagManager.dataLayer(this.tagManagerArgs);
        // }

    };

    return TagManagerService;
}