export const PREVENT_CHANGES = 'PREVENT_CHANGES';
export const ACTION_STATE = 'ACTION_STATE';
export const CALCULATION_TARGET = 'CALCULATION_TARGET';
export const LOGGER_PREVIEW_IMAGE = 'LOGGER_PREVIEW_IMAGE';
export const RESET_IMAGE_STATE = 'RESET_IMAGE_STATE';

export interface PreventChangesI {
    type: typeof PREVENT_CHANGES;
    payload: boolean;
}

export interface ActionStateI {
    type: typeof ACTION_STATE;
    payload: any;
}

export interface CalculationTargetI {
    type: typeof CALCULATION_TARGET;
    payload: any;
}

export interface LoggerPreviewImageI {
    type: typeof LOGGER_PREVIEW_IMAGE;
    payload: any;
}

export interface ResetImageStateI {
    type: typeof RESET_IMAGE_STATE;
}