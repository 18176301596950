import styled from 'styled-components';

interface ContainerModalPropsI {
  width: string;
  height: string;
}

interface ContainerButtonPropsI {
  marginTop?: string;
}

interface FormContainerPropsI {
  custom?: boolean
}

interface ContainerExplorerPropsI {
  top?: string;
  left?: string;
}

export const DeleteInfoText = styled.div({
  color: '#686D8E',
  fontFamily: 'Inter',
  fontSize: '12px',
  letterSpacing: '0.4px',
});

export const ContainerModalStyled = styled.div<ContainerModalPropsI>(
  (props: ContainerModalPropsI) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.width,
    backgroundColor: 'white',
    flexDirection: 'column',
    boxShadow: '24',
    minHeight: props.height,
    height: 'auto',
    display: 'flex',
    borderRadius: '8px',
    padding: '1rem',
    outline: 'none',
  })
);

export const ContainerTitleStyled = styled.div({
  flexDirection: 'row',
  display: 'flex',
  marginBottom: '10px',
  p: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 500,
    flex: 1,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  '&:hover svg': {
    cursor: 'pointer',
  },
});

export const ContainerButtonStyled = styled.div<ContainerButtonPropsI>(
  (props: ContainerButtonPropsI) => ({
    marginTop: props.marginTop ? props.marginTop : 0,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    button: {
      height: '2rem',
      width: '47%',
      borderRadius: '4px',
      padding: '0px, 0.87rem, 0px, 0.87rem',
      fontSize: 12,
      fontFamily: 'Inter',
      fontWeight: 'bold',
    },
    '& button:nth-child(1)': {
      backgroundColor: '#F0F2F5',
    },
  })
);

export const ContainerButtonStyled2 = styled.div({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  button: {
    height: '2rem',
    width: '32%',
    borderRadius: '4px',
    padding: '0px, 0.87rem, 0px, 0.87rem',
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  '.cancel': {
    backgroundColor: '#F0F2F5',
  },
});

export const ContainerFormStyled = styled.form<FormContainerPropsI>((props: FormContainerPropsI) => ({
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  justifyContent: props.custom ? 'space-around' : 'space-between',
  select: {
    flex: 1,
  },
  input: {
    backgroundColor: '#FFFFFF',
    flex: '1',
    display: 'flex',
  },
}));

export const PlaceHolderStyled = styled.div({
  color: '#aaa',
});

export const ContainerExplorerStyled = styled.div<ContainerExplorerPropsI>((props: ContainerExplorerPropsI) => ({
  position: 'fixed',
  top: props.top? props.top : '75%',
  zIndex: 999,
  left: props.left? props.left : '20%',
  width: '22rem',
  borderRadius: '8px',
  backgroundColor: 'white',
  '.containerFolder': {
    display: 'flex',
    height: '15rem',
    flexDirection: 'column',
  },
}));
