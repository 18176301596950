
const cacheDuration = 300;
export const API_CACHE_PREFIX = 'apiCache_';
export const useApi = async (key: string, fnCallback: any, force = false) => {
  let result = {
    error: null,
    data: [],
    lastFetch: new Date(),
  };
  const cachedData: any = localStorage.getItem(`${API_CACHE_PREFIX}_${key}`);
  if (!fnCallback) return;
  let tmpRes = null;
  try {
    tmpRes = JSON.parse(cachedData);
  } catch (err) { }
  let diffSeconds = 0;
  if (tmpRes) {
    if (tmpRes?.lastFetch) {
      tmpRes.lastFetch = new Date(tmpRes.lastFetch);
    }
    diffSeconds =
      (new Date().getTime() - (tmpRes?.lastFetch ?? new Date()).getTime()) /
      60000;
  }
  if (tmpRes && !force && diffSeconds < cacheDuration) {
    result = tmpRes;
  } else {
    try {
      result = await fnCallback();
      result.lastFetch = new Date();
      localStorage.setItem(`${API_CACHE_PREFIX}_${key}`, JSON.stringify(result));
    } catch (error: any) {
      throw error;
    }
  }
  return result;
};