import { FormControlLabel, FormControlLabelProps, Radio, RadioProps } from '@mui/material';
import { styled } from '@mui/styles';
import { CSSProperties } from 'react';
import SvgRadioOff from '../../../assets/icons/RadioOff';
import SvgRadioOn from '../../../assets/icons/RadioOn';

interface PropsI extends RadioProps{
    label: any
    value?: string
    style?: CSSProperties
}

const DefaultFormControl = styled(FormControlLabel)<FormControlLabelProps>({
    marginLeft: 0,

    '& .MuiFormControlLabel-label': {
        color: "#29333d",
        fontSize: 12,
        fontWeight: "normal",
    },

    '& .MuiRadio-root': {
        padding: 4,
    }
});

export default function StyledRadioButton({label, value, ...restProps}: PropsI) {
    return <DefaultFormControl value={value} control={<Radio {...restProps} icon={<SvgRadioOff />} checkedIcon={<SvgRadioOn/>}/>} label={label}/>;
}
