import {
  getBusinessDriveStatus,
  getCommunityWorkspaceId,
  getPersonalWorkspaceId,
  getUserDetails,
  getUserType
} from '../../../utils/storage';
import {
  initTreeState,
  setSelectedTreeItem
} from '../../../store/appState/sidebar/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DashboardPanel from './DashboardPanel';
import FolderTree from './FolderTree';
import { ReduxState } from '../../../store';
import { SubscriptionI } from '../../../store/reducers/loginReducer';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DisclaimerBox } from '../../../pages/askAce/components/rightSectionAce/DisclaimerBox';
import { DisclaimerModal } from '../../../pages/askAce/components/rightSectionAce/DisclaimerModal';
import { BottomSidebarContainer } from '../../../pages/askAce/styles';
import { AceBottomSidebar } from '../../../pages/askAce/components/AceBottomSidebar';

interface PropsI {
  isMinimize: boolean;
  view?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

export default function SideBar({ isMinimize, onClick, isLoading }: PropsI) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const isAceUser = /ACE/.test(getUserType())
  const [modalVisible, setModalVisible] = useState(false);
  const handleToggleClick = () => {
    setModalVisible(!modalVisible);
  };

  const { folderTree, selectedItem } = useSelector(
    (state: ReduxState) => state.sidebar,
    shallowEqual
  );

  const { userLimit, isHaveDrive } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { userPhoto, userDetails, loading } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  const userProfile = getUserDetails();

  const { subscription } = userProfile;

  useEffect(() => {
    let item: any = selectedItem;
    if (location.pathname === '/calculations/my-calculation') {
      item = getPersonalWorkspaceId();
    } else if (location.pathname === '/calculations/askAce') {
      item = 'askAce';
    } else if (location.pathname === '/calculations/accountManager') {
      item = 'account';
    } else if (location.pathname === '/ace/helpPage') {
      item = 'helpPage';
    } else if (location.pathname === '/calculations/community') {
      item = getCommunityWorkspaceId();
    } else if (location.pathname === '/calculations/businessDrives') {
      item = 'businessDrives';
    } else if (location.pathname === '/ace/public') {
      item = 'acePublic';
    } else if (location.pathname === '/ace/enterprise') {
      item = 'aceEnterprise';
    }
    dispatch(initTreeState(getUserType() ?? 'REGULAR', pathname));
    dispatch(setSelectedTreeItem(item));
  }, []);

  const filterFolderTree = (
    data: any,
    isDrive: boolean,
    subscription: SubscriptionI
  ) => {
    let treeMenu = data;
    const isAuthDrive =
      isDrive || /^(BUSINESS|ACE).*$/.test(subscription?.plan);
    // remove business drive menu
    if (!isAuthDrive) {
      treeMenu = treeMenu.filter((item: any) => item.id !== 'businessDrives');
    }
    // remove ask ace menu
    if (!userLimit.chatBot) {
      treeMenu = treeMenu.filter(
        (item: Record<string, unknown>) => item.id !== 'askAce'
      );
    }
    return treeMenu;
  };

  const handleBottomSidebar = (type: string) => {
    if (type === 'help') {
      return history.push(`/ace/helpPage`);
    } else {
      return history.push(`/ace/${type}`);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      onClick={onClick}
    >
      <div
        style={{
          minWidth: isMinimize ? 68 : 270,
          // maxWidth: "20%",
          overflow: 'auto',
          overflowX: 'hidden'
        }}
      >
        <FolderTree
          children={filterFolderTree(
            folderTree,
            isHaveDrive || getBusinessDriveStatus().isHaveDrive,
            subscription
          )}
          isMinimize={isMinimize}
          selectedItem={selectedItem}
          indentLevel={0}
          isLoading={isLoading}
        />
      </div>
      {isAceUser ?
        (
          <BottomSidebarContainer>
            <AceBottomSidebar onClick={handleBottomSidebar} selectedItem={selectedItem} />
            <DisclaimerBox onToggleClick={handleToggleClick} />
          </BottomSidebarContainer>
        )
        :
        (<DashboardPanel
          isMinimize={isMinimize}
          userPhoto={userPhoto}
          userDetails={userDetails}
          loading={loading}
          userName={userProfile.name}
          userLimit={userLimit}
          subscription={subscription}
          userType={getUserType() ?? 'REGULAR'}
        />)}

      <DisclaimerModal
        modalVisible={modalVisible}
        onCloseModal={handleToggleClick}
      />

    </div>
  );
}
