import { DATA, FAILED, LOADING } from '../types/encalTypes';

import { Dispatch } from 'redux';
import Service from '../../service/generic/generic';

const service = new Service('folders');

//todo : checking encalculation data
export function encalDataAction(personalWorkspaceId: string) {
  // using window.location.pathname to get Enterprise Calculation or Personal Calculation data
  const { pathname } = window.location;
  const type = pathname.includes('my-calculation') || pathname.includes('community') ? 'FOLDER,CALCULATION,FILE' : 'CALCULATION';
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true));
      const result = await service.getSubresource(personalWorkspaceId, `view?type=${type}&isActive=true`);
      dispatch(dataAction(result.data.items));
      dispatch(loadingAction(false));
      return Promise.resolve(result.data.items);
    } catch (error) {
      dispatch(failedAction('failed', false));
      return Promise.reject(error);
    }
  };
}

export function dataAction(data: Array<any>) {
  return { type: DATA, data };
}

function loadingAction(isLoading: boolean) {
  return { type: LOADING, isLoading };
}

function failedAction(message: string, isLoading: boolean) {
  return { type: FAILED, message, isLoading };
}
