import * as React from 'react';
import { SVGProps } from 'react';

const Uncheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1" y="1" width="22" height="22" rx="3" fill="white" />
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="3"
      stroke="#8B98B1"
      strokeWidth="2"
    />
  </svg>
);

export default Uncheck;
