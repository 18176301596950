import { isNumeric } from '../../../utils/helpersFunction';
import { BodyInput, Section } from '../../../pageElements/printViewPage';
import parser from 'html-react-parser';
import {
  roundFunction,
  toPlainNumeric
} from '../../../pages/loggerRefactor/utils';
import { forwardRef } from 'react';

export const InputNumeric = forwardRef<any, any>((props, ref) => {
  const setRoundFunction = (value: number) =>
    roundFunction(
      toPlainNumeric(value),
      props.data.properties.format?.value?.decimal ?? 3
    );
  return (
    <BodyInput ref={ref}>
      <Section>
        <div style={{ width: 180 }}>
          {parser(props.data.properties.description)}
        </div>
        <div style={{ width: 12 }} />
        {props.data.type === 'CURRENCY_INPUT' ? (
          <div style={{ width: 64 }}>
            {props.data.properties.currency
              ? parser(props.data.properties.currency)
              : ''}
          </div>
        ) : props.data.type === 'DATE_INPUT' ? null : (
          <div style={{ width: 64 }}>
            {props.data.properties.notation
              ? parser(props.data.properties.notation)
              : ''}
          </div>
        )}
        <div
          style={{
            width: 180,
            justifyContent:
              props.data.type === 'NUMERICAL_INPUT' ||
              props.data.type === 'CURRENCY_INPUT'
                ? 'flex-end'
                : 'flex-start'
          }}
        >
          {props.data.type === 'DATE_INPUT'
            ? props.data.value
            : props.data.value &&
              typeof props.data.value === 'string' &&
              isNumeric(props.data.value)
            ? setRoundFunction(props.data.value)
            : props.data.value &&
              typeof props.data.value === 'string' &&
              !isNumeric(props.data.value)
            ? parser(props.data.value)
            : props.data.value && typeof props.data.value === 'number'
            ? setRoundFunction(props.data.value)
            : ''}
        </div>
        <div style={{ width: 8 }} />
        <div style={{ width: 64 }}>
          {props.data.properties.unit ? parser(props.data.properties.unit) : ''}
        </div>
      </Section>
    </BodyInput>
  );
});
