import {
  ISDRAGGINGTYPECOMPOSER,
  ISEDITINGTYPECOMPOSER,
  ACTIVEPAGETYPECOMPOSER,
  DATAVARIABLEMANAGERMENUTYPECOMPOSER,
  DATAHYPERFORMULATYPECOMPOSER,
  DATAPAGESTYPECOMPOSER,
  DATAPROPERTIESTYPECOMPOSER,
  DATACHEADOFCALCULATIONTYPECOMPOSER,
  DATABODYOFCALCULATIONTYPECOMPOSER,
  DATACURRENCYCOMPOSER,
  DATASYMBOLCOMPOSER,
  EXPORTEVENTCOMPOSER,
  IsDraggingTypeComposer,
  IsEditingTypeComposer,
  ActivePageTypeComposer,
  DataVariableManagerMenuTypeComposer,
  DataHyperFormulaTypeComposer,
  DataPagesTypeComposer,
  DataPropertiesTypeComposer,
  DataHeadOfCalculationTypeComposer,
  DataBodyOfCalculationTypeComposer,
  DataCurrencyComposer,
  DataSymbolComposer,
  ExportEventComposer,
  IsFetchingDataComposer,
  ISFETCHINGDATACOMPOSER,
} from './composerTypes';

interface initialStateI {
  isDragging: boolean;
  isEditing: boolean;
  activePage: number;
  dataVariableManagerMenu: any;
  dataHyperFormula: any;
  dataPages: any;
  dataProperties: any;
  dataHeadOfCalculation: any;
  dataBodyOfCalculation: any;
  dataCurrency: any;
  dataSymbol: { [key: string]: string | number }[]
  exportEvent: any;
  isFetching: boolean;
  addEvent: boolean;
};

const initialState: initialStateI = {
  isDragging: false,
  isEditing: false,
  activePage: 0,
  dataVariableManagerMenu: [],
  dataHyperFormula: {},
  dataPages: [],
  dataProperties: {
    // SUPER IMPORTANT
    showProperties: false,
    activePage: null,
    indexComponent: null,
    propertiesType: null,
    idComponent: null,

    // USED BY MANY COMPONENT
    description: null,
    variable: null,
    notation: null,
    tooltip: null,
    notes: null,
    inSummary: null,
    inputValue: null,

    // NUMERICAL
    hardMax: null,
    hardMin: null,
    softMax: null,
    softMin: null,

    // CURRENCY
    currency: null,

    // FORMULA
    formula: null,
    reference: null,
    error: null,
    isDisplayed: null,

    // TABLE
    isAllowedToAddRow: null,
    tableName: null,
    tableHeader: null,
    tableBody: null,
    displayedContent: null,
    freeze: {
      column: 0,
      row: 0
    },
    format: {
      cells: null,
    },

    // TEXT
    level: null,

    // IMAGE
    isLocked: true,
    imageUrl: null,
    imageWidth: null,
    imageHeight: null,
    imageType: null,
    imageAlignment: null,

    // DROPDOWN AND RADIOBUTTON
    options: null,

    // NUMERICAL AND FORMULA
    unit: null,

    // LINK
    title: null,
    url: null
  },
  dataHeadOfCalculation: {
    idCalculation: null,
    title: null,
    description: null,
    createdBy: null,
    createdAt: null,
  },
  dataBodyOfCalculation: [],
  dataCurrency: [],
  dataSymbol: [],
  exportEvent: {
    value: false,
    type: null,
  },
  isFetching: false,
  addEvent: false,
};

type Action =
  IsDraggingTypeComposer |
  IsEditingTypeComposer |
  ActivePageTypeComposer |
  DataVariableManagerMenuTypeComposer |
  DataHyperFormulaTypeComposer |
  DataPagesTypeComposer |
  DataPropertiesTypeComposer |
  DataHeadOfCalculationTypeComposer |
  DataBodyOfCalculationTypeComposer |
  DataCurrencyComposer |
  DataSymbolComposer |
  ExportEventComposer |
  IsFetchingDataComposer;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case ISDRAGGINGTYPECOMPOSER:
      return { ...state, isDragging: action.data };
    case ISEDITINGTYPECOMPOSER:
      return { ...state, isEditing: action.data };
    case ACTIVEPAGETYPECOMPOSER:
      return { ...state, activePage: action.data };
    case DATAVARIABLEMANAGERMENUTYPECOMPOSER:
      return { ...state, dataVariableManagerMenu: action.data };
    case DATAHYPERFORMULATYPECOMPOSER:
      return { ...state, dataHyperFormula: action.data };
    case DATAPAGESTYPECOMPOSER:
      return { ...state, dataPages: action.data };
    case DATAPROPERTIESTYPECOMPOSER:
      return { ...state, dataProperties: action.data };
    case DATACHEADOFCALCULATIONTYPECOMPOSER:
      return { ...state, dataHeadOfCalculation: action.data };
    case DATABODYOFCALCULATIONTYPECOMPOSER:
      return { ...state, dataBodyOfCalculation: action.data };
    case DATACURRENCYCOMPOSER:
      return { ...state, dataCurrency: action.data };
    case DATASYMBOLCOMPOSER:
      return { ...state, dataSymbol: action.data };
    case EXPORTEVENTCOMPOSER:
      return { ...state, exportEvent: action.data };
    case ISFETCHINGDATACOMPOSER:
      return { ...state, isFetching: action.data };
    default:
      return state;
  }
}
