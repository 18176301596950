import { APIResponse } from "../pages/businessDrives/businessDrives.types";
import { CalculationI, CopyCalculationsI, CreateCalculationI, RenameCalculationI } from "../store/types/calculationsTypes";
import api from "./api";

const URL = 'calculation';
const URLs = 'calculations';

type CalculationFile = any;


const CalculationService = {
    getCalculationById: async (calcID: string): Promise<CalculationFile> => {
        const response = await api.get(`${URLs}/${calcID}`);
        return response.data.data;
    },
    getInputFiles: async (calcID: string): Promise<CalculationFile> => {
        const response = await api.get(`${URL}/${calcID}/input-files`);
        return response.data.data;
    },
    createCalculation: async (data: CreateCalculationI): Promise<CalculationI> => {
        const response = await api.post(`${URLs}`, data);
        return response.data.data;
    },
    copyCalculation: async (data: CopyCalculationsI): Promise<APIResponse<Array<CalculationI>>> => {
        const response = await api.post(`${URLs}/save-as`, data);
        return response.data;
    },
    renameCalculation: async (data: RenameCalculationI): Promise<APIResponse<Array<CalculationI>>> => {
        const response = await api.put(`${URLs}/rename`, data);
        return response.data;
    },
    updateCalculationStatus: async (calculationId: string, payload: { isComplete: boolean }): Promise<APIResponse<Array<CalculationI>>> => {
        const response = await api.put(`${URL}/${calculationId}`, payload);
        return response.data;
    },
    // Calculation Component
    updateComponent: async (calculationId: string, masterType: string, componentType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.put(`${URLs}/${calculationId}/${masterType}/${componentType}`, payload);
        return response.data;
    },
    addNewComponent: async (calculationId: string, masterType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.post(`${URLs}/${calculationId}/${masterType}`, payload);
        return response.data;
    },
    deleteComponent: async (calculationId: string, masterType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.delete(`${URLs}/${calculationId}/${masterType}`, { data: payload });
        return response.data;
    },
    moveComponent: async (calculationId: string, masterType: string, serviceType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.put(`${URLs}/${calculationId}/${masterType}/${serviceType}`, payload);
        return response.data;
    },
    duplicateComponent: async (calculationId: string, masterType: string, serviceType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.post(`${URLs}/${calculationId}/${masterType}/${serviceType}`, payload);
        return response.data;
    },
    duplicateMultipleComponent: async (calculationId: string, masterType: string, serviceType: string, payload: { [key: string]: any }): Promise<APIResponse<any>> => {
        const response = await api.post(`${URLs}/${calculationId}/${masterType}/${serviceType}`, payload);
        return response.data;
    },
};

export default CalculationService;