import RichTextToolbar from './RichTextToolbar';
import RichText from './RichText';
import RichTextToolbarRef from './RichTextToolbarRef';
import { RichTextEditor } from './RichTextEditor';

const RichTextInput = {
  RichText,
  RichTextToolbar,
  RichTextToolbarRef,
  RichTextEditor
};

export default RichTextInput;
