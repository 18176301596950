//TODO: define internal function
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import Icon from '../../../components/atoms/Icon';
import images from '../../../assets';
import { ComposerInput } from '../../../components/molecules/composer/composerInput';
import { FormattedInput } from '../../../components/molecules/FormattedInput';
import { FormulaTable } from '../../../components/molecules/FormulaTable';
import RichTextInput from '../../../components/molecules/RichText';
import WorkpageItem from '../../../components/molecules/WorkpageItem';
import {
  WrapperMainContent,
  HeaderMainContent,
  PagesAtHeaderMainContent,
  Page,
  AddPage,
  HeadAtBodyMainContent,
  HeadAtBodyMainContentWrapper,
  HeadAtBodyMainContentCenter,
  HeadAtBodyMainContentRight,
  BodyAtBodyMainContent,
  EmptyBody,
  BodyAtBodyMainContentWrapper,
  InputTextComp,
  InputDropDownComp,
  InputRadioComp,
  InputNumberComp,
  InputDateComp,
  FormulaComp,
  TableComp,
  TextComp,
  DividerComp,
  ImageComp,
  ComposerMainContent,
  ComposerBodyMainContent
} from '../../../pageElements/composer';
import { expandTableDefaultCellStyle } from '../../../utils/constant';
import { composerDataHeadOfCalculationTime } from '../../../utils/dateConvert';
import {
  checkDuplication,
  notContainSpecialCharacterAndSpace,
  checkOptionsArray,
  reverseMapCurrencyToHtmlEntities,
  isNumeric,
  convertTextLevel,
  isValidURL,
  validateCharAfterNumber,
  isEmptyString
} from '../../../utils/helpersFunction';
import { ExpandTable } from '../expand-table/expand-table.organism';
import { ImageMenu } from '../image-menu/image-menu.organism';
import { InputMenu } from '../input-menu/input-menu.organism';
import { PageMenu } from '../page-menu/page-menu.organism';
import { TextMenu } from '../text-menu/text-menu.organism';
import { IComposerMiddle } from './composer-middle.type';
import { MutableRefObject } from 'react';
import { FieldMenu } from '../field-menu/field-menu.organism';

export const ComposerMiddle = ({
  handlerSwipe,
  headerRef,
  dataPages,
  activePage,
  handlerChangePage,
  isDragging,
  isEditing,
  inputRef,
  handlerRenamePage,
  handlerShowPageMenu,
  handlerAddPage,
  dataHeadOfCalculation,
  handlerPopUpModal,
  dataBodyOfCalculationCloned,
  setDataBodyOfCalculationCloned,
  preventChanges,
  threeDotChanges,
  handlerComponent,
  onClickHandler,
  totalActiveComponent,
  dataProperties,
  handlerComponentTextInput,
  register,
  handlerGlobalComponent,
  handlerComponentDropdownInput,
  handlerComponentRadioButtonInput,
  handlerComponentNumericalInput,
  handleSelectedToolbarMenu,
  handleSelectedFieldToolbarMenu,
  handlerComponentDateInput,
  handlerComponentFormula,
  handlerComponentTable,
  workpageTableRef,
  handlerComponentText,
  handlerComponentDivider,
  handlerComponentImage,
  imageRef,
  expandTable,
  selectedCell,
  expandTableRef,
  expandTableContainerRef,
  handlerExpandTableContextMenu,
  handlerExpandTableAfterChanges,
  handlerExpandTableOnChange,
  handlerExpandTableCellSelection,
  handlerExpandTableColumnResize,
  handlerExpandTableToolbar,
  setValue,
  getValues,
  handlerExpandTableButtonTable,
  openPage,
  handlerDeletePage,
  openText,
  handlerShowTextComponent,
  openImage,
  handlerShowImageComponent,
  openInput,
  handlerShowInputComponent,
  openField,
  handlerShowFieldComponent,
  handlerComponentLink,
  handleOpenNewTab,
  componentTableRef,
  notationInputRef,
  handlerComponentField,
  handlerShowToLoggerPage
}: IComposerMiddle) => (
  <ComposerMainContent>
    <WrapperMainContent>
      <HeaderMainContent>
        <ArrowLeft
          className="arrowIcon"
          onClick={handlerSwipe('left')}
          data-testid="btnPrefPage"
        />
        <PagesAtHeaderMainContent ref={headerRef}>
          {dataPages.map((value: any, index: number) => (
            <Droppable
              key={`middle_droppable_${index}`}
              droppableId={`${index}-page`}
              isDropDisabled={activePage === index}
            >
              {(provided: DroppableProvided) => (
                <Page
                  ref={provided.innerRef}
                  isActive={activePage === index}
                  onClick={() => handlerChangePage(index)}
                  data-testid={value.name}
                  isDragging={isDragging}
                  hidden={value.hidden}
                >
                  {isEditing && activePage === index ? (
                    <input
                      ref={inputRef}
                      onBlur={(e: React.SyntheticEvent) =>
                        handlerRenamePage('ONBLUR', e)
                      }
                      type="text"
                      defaultValue={value.name}
                    />
                  ) : (
                    <>
                      {value.hidden && (
                        <Icon
                          icon="eyeOff"
                          size={14}
                          color={activePage === index ? '#118CFF' : '#A3B3C2'}
                        />
                      )}
                      <p>{value.name}</p>
                      <Icon
                        icon="chevronDown"
                        size={20}
                        color={
                          activePage === index
                            ? '#118CFF'
                            : value.hidden
                            ? '#A3B3C2'
                            : ''
                        }
                        onClick={(e: React.SyntheticEvent) =>
                          handlerShowPageMenu('open', e, index)
                        }
                      />
                    </>
                  )}
                </Page>
              )}
            </Droppable>
          ))}
          <AddPage onClick={() => handlerAddPage()}>
            <p>Add Page</p>
            <img
              width={16}
              src={images.addPageIconComposer}
              data-testid="btnAddPage"
              alt="button-add-page"
            />
          </AddPage>
        </PagesAtHeaderMainContent>
        <ArrowRight
          className="arrowIcon"
          onClick={handlerSwipe('right')}
          data-testid="btnNextPage"
        />
      </HeaderMainContent>
      <ComposerBodyMainContent>
        <HeadAtBodyMainContent>
          <HeadAtBodyMainContentWrapper>
            <HeadAtBodyMainContentCenter>
              <div id="topMainContentCenter">
                <b>{dataHeadOfCalculation.title}</b>
                <img
                  onClick={handlerPopUpModal}
                  src={images.editHeaderIconComposer}
                  data-testid="btnEditCalculationInfo"
                ></img>
              </div>
              <div id="botMainContentCenter">
                <div id="botMainContentCenterWrapperTop">
                  <div>
                    <div className="titleHeadBody">Originator</div>
                    <div className="dotHeadBody">:</div>
                    <div className="dataHeadBody">
                      {dataHeadOfCalculation.createdBy}
                    </div>
                  </div>
                  <div>
                    <div className="titleHeadBody">Date Created</div>
                    <div className="dotHeadBody">:</div>
                    <div className="dataHeadBody">
                      {composerDataHeadOfCalculationTime(
                        dataHeadOfCalculation.createdAt
                      )}
                    </div>
                  </div>
                </div>
                <div id="botMainContentCenterWrapperBot">
                  <div>
                    <div className="titleHeadBody"></div>
                    <div className="dotHeadBody"></div>
                    <div></div>
                  </div>
                  <div>
                    <div className="titleHeadBody">Date Printed</div>
                    <div className="dotHeadBody">:</div>
                    <div></div>
                  </div>
                </div>
              </div>
            </HeadAtBodyMainContentCenter>
            <HeadAtBodyMainContentRight>
              <img src={images.rekavaIconNameComposer} />
            </HeadAtBodyMainContentRight>
          </HeadAtBodyMainContentWrapper>
        </HeadAtBodyMainContent>
        <Droppable droppableId="dropMainContent" isDropDisabled={false}>
          {(provided: DroppableProvided) => (
            <BodyAtBodyMainContent
              ref={provided.innerRef}
              data-testid="workpages"
            >
              {dataBodyOfCalculationCloned[activePage]?.length === 0 &&
              !isDragging ? (
                <EmptyBody>
                  <img
                    style={{
                      pointerEvents: 'none',
                      userSelect: 'none'
                    }}
                    src={images.emptyStateComposer}
                    alt="content-empty"
                  />
                </EmptyBody>
              ) : (
                <BodyAtBodyMainContentWrapper isDragging={isDragging}>
                  {dataBodyOfCalculationCloned[activePage]?.map(
                    (value: any, index: number) => (
                      <Draggable
                        key={`middle_content_${value.id}`}
                        draggableId={value.id}
                        index={index}
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) =>
                          value.type === 'TEXT_INPUT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <InputTextComp
                                {...provided.dragHandleProps}
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.notation ||
                                  checkDuplication(
                                    'notation',
                                    value.notation,
                                    value.id
                                  ) ||
                                  !notContainSpecialCharacterAndSpace(
                                    value.notation
                                  ) ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  )
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentTextInput(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <RichTextInput.RichTextToolbar
                                  component="description"
                                  isRequired={true}
                                  source={value.description}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data?: string
                                  ) =>
                                    handlerComponentTextInput(
                                      'ONBLUR_DESCRIPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Description"
                                  data-testid="inputDescriptionInpText"
                                />
                                <RichTextInput.RichTextToolbarRef
                                  ref={(el: MutableRefObject<HTMLElement>) =>
                                    (notationInputRef.current[
                                      `notation_${value.id}`
                                    ] = el)
                                  }
                                  component="notation"
                                  maxLength={8}
                                  isRequired={true}
                                  source={value.notation}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentTextInput(
                                      'ONBLUR_NOTATION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Notation"
                                  data-testid="inputNotationInpNumeric"
                                  onFocus={() =>
                                    handlerGlobalComponent(
                                      'FOCUS_WORKPAGES_COMPONENT',
                                      {
                                        focusId: `notation_${value.id}`
                                      }
                                    )
                                  }
                                />
                                <ComposerInput.Text
                                  typeInput="inputValue"
                                  defaultValue={value.inputValue}
                                  placeholder="Input value here"
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerComponentTextInput(
                                      'ONBLUR_INPUT_VALUE',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                />
                              </InputTextComp>
                            </WorkpageItem>
                          ) : value.type === 'DROPDOWN_INPUT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <InputDropDownComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.notation ||
                                  checkDuplication(
                                    'notation',
                                    value.notation,
                                    value.id
                                  ) ||
                                  !notContainSpecialCharacterAndSpace(
                                    value.notation
                                  ) ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  ) ||
                                  checkOptionsArray(value.options) ||
                                  !validateCharAfterNumber(value.notation)
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentDropdownInput(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <RichTextInput.RichTextToolbar
                                  component="description"
                                  isRequired={true}
                                  source={value.description}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentDropdownInput(
                                      'ONBLUR_DESCRIPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Description"
                                  data-testid="inputDescriptionInpDropdown"
                                />
                                <RichTextInput.RichTextToolbarRef
                                  ref={(el: MutableRefObject<HTMLElement>) =>
                                    (notationInputRef.current[
                                      `notation_${value.id}`
                                    ] = el)
                                  }
                                  component="notation"
                                  maxLength={8}
                                  isRequired={true}
                                  source={value.notation}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentDropdownInput(
                                      'ONBLUR_NOTATION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Notation"
                                  data-testid="inputNotationInpNumeric"
                                  onFocus={() =>
                                    handlerGlobalComponent(
                                      'FOCUS_WORKPAGES_COMPONENT',
                                      {
                                        focusId: `notation_${value.id}`
                                      }
                                    )
                                  }
                                />
                                <ComposerInput.Select
                                  payload={{
                                    options: value.options,
                                    inputValue: value.inputValue
                                  }}
                                  dataSelect={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentDropdownInput(
                                      'SELECT_OPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                />
                              </InputDropDownComp>
                            </WorkpageItem>
                          ) : value.type === 'RADIO_BUTTON_INPUT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <InputRadioComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.notation ||
                                  checkDuplication(
                                    'notation',
                                    value.notation,
                                    value.id
                                  ) ||
                                  !notContainSpecialCharacterAndSpace(
                                    value.notation
                                  ) ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  ) ||
                                  checkOptionsArray(value.options) ||
                                  !validateCharAfterNumber(value.notation)
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentRadioButtonInput(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                imageComponent={images.radioOffComposer}
                                {...provided.dragHandleProps}
                              >
                                <div className="inputRadioRowWrapper">
                                  <div className="inputRadioColumnWrapper">
                                    <RichTextInput.RichTextToolbar
                                      component="description"
                                      isRequired={true}
                                      source={value.description}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) =>
                                        handlerComponentRadioButtonInput(
                                          'ONBLUR_DESCRIPTION',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Description"
                                      data-testid="inputDescriptionInpRadio"
                                    />
                                    <RichTextInput.RichTextToolbarRef
                                      ref={(
                                        el: MutableRefObject<HTMLElement>
                                      ) =>
                                        (notationInputRef.current[
                                          `notation_${value.id}`
                                        ] = el)
                                      }
                                      component="notation"
                                      maxLength={8}
                                      isRequired={true}
                                      source={value.notation}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) =>
                                        handlerComponentRadioButtonInput(
                                          'ONBLUR_NOTATION',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Notation"
                                      data-testid="inputNotationInpNumeric"
                                      onFocus={() =>
                                        handlerGlobalComponent(
                                          'FOCUS_WORKPAGES_COMPONENT',
                                          {
                                            focusId: `notation_${value.id}`
                                          }
                                        )
                                      }
                                    />
                                  </div>
                                  <ComposerInput.Radio
                                    payload={{
                                      options: value.options,
                                      inputValue: value.inputValue
                                    }}
                                    addNewRadio={() =>
                                      handlerComponentRadioButtonInput(
                                        'ADD_OPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        }
                                      )
                                    }
                                    deleteRadio={(
                                      e: React.SyntheticEvent,
                                      indexRadio: number
                                    ) =>
                                      handlerComponentRadioButtonInput(
                                        'REMOVE_OPTIONS',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index,
                                          indexRadio: indexRadio
                                        },
                                        e
                                      )
                                    }
                                    selectRadio={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentRadioButtonInput(
                                        'SELECT_RADIO',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                    dataChanges={(
                                      e: React.SyntheticEvent,
                                      data: string,
                                      indexRadio: number
                                    ) =>
                                      handlerComponentRadioButtonInput(
                                        'ONBLUR_OPTIONS',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index,
                                          indexRadio: indexRadio
                                        },
                                        e,
                                        data
                                      )
                                    }
                                  />
                                </div>
                              </InputRadioComp>
                            </WorkpageItem>
                          ) : value.type === 'NUMERICAL_INPUT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <InputNumberComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.notation ||
                                  checkDuplication(
                                    'notation',
                                    value.notation,
                                    value.id
                                  ) ||
                                  !notContainSpecialCharacterAndSpace(
                                    value.notation
                                  ) ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  ) ||
                                  !validateCharAfterNumber(value.notation)
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentNumericalInput(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <RichTextInput.RichTextToolbar
                                  component="description"
                                  isRequired={true}
                                  source={value.description}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentNumericalInput(
                                      'ONBLUR_DESCRIPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Description"
                                  data-testid="inputDescriptionInpNumeric"
                                />
                                <RichTextInput.RichTextToolbarRef
                                  ref={(el: MutableRefObject<HTMLElement>) =>
                                    (notationInputRef.current[
                                      `notation_${value.id}`
                                    ] = el)
                                  }
                                  component="notation"
                                  maxLength={8}
                                  isRequired={true}
                                  source={value.notation}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentNumericalInput(
                                      'ONBLUR_NOTATION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Notation"
                                  data-testid="inputNotationInpNumeric"
                                  onFocus={() =>
                                    handlerGlobalComponent(
                                      'FOCUS_WORKPAGES_COMPONENT',
                                      {
                                        focusId: `notation_${value.id}`
                                      }
                                    )
                                  }
                                />
                                <FormattedInput.Number
                                  value={value.inputValue}
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerComponentNumericalInput(
                                      'ONBLUR_INPUT_VALUE',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                  id="userInputNumber"
                                  placeholder="Input value here"
                                  decimalPlaces={
                                    value.format?.value?.decimal ?? 3
                                  }
                                  percentage={value.format?.value?.percentage}
                                  selectedCurrency={
                                    reverseMapCurrencyToHtmlEntities[
                                      value?.unit as keyof typeof reverseMapCurrencyToHtmlEntities
                                    ]
                                  }
                                  onToolbarMenuSelected={(
                                    e: any,
                                    type: any,
                                    data: any
                                  ) =>
                                    handleSelectedToolbarMenu(
                                      e,
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      type,
                                      data
                                    )
                                  }
                                />
                                <RichTextInput.RichTextToolbar
                                  component="unit"
                                  source={value?.unit}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentNumericalInput(
                                      'ONBLUR_UNIT',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Unit"
                                  data-testid="inputUnitNumeric"
                                  maxLength={5}
                                />
                              </InputNumberComp>
                            </WorkpageItem>
                          ) : value.type === 'DATE_INPUT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <InputDateComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  )
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentDateInput(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <RichTextInput.RichTextToolbar
                                  component="description"
                                  isRequired={true}
                                  error={!value.description}
                                  source={value.description}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data: string
                                  ) =>
                                    handlerComponentDateInput(
                                      'ONBLUR_DESCRIPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  placeholder="Description"
                                  data-testid="inputDescriptionInpDate"
                                />
                                <div id="selectInputDate">
                                  <Icon
                                    icon="calendar"
                                    color="#B3BFCC"
                                    size={14}
                                  />
                                  <p>Select date</p>
                                  <Icon icon="chevronDown" color="#B3BFCC" />
                                </div>
                              </InputDateComp>
                            </WorkpageItem>
                          ) : value.type === 'FORMULA' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <FormulaComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.description ||
                                  !value.notation ||
                                  checkDuplication(
                                    'notation',
                                    value.notation,
                                    value.id
                                  ) ||
                                  !notContainSpecialCharacterAndSpace(
                                    value.notation
                                  ) ||
                                  !value.variable ||
                                  checkDuplication(
                                    'variable',
                                    value.variable,
                                    value.id
                                  ) ||
                                  !validateCharAfterNumber(value.notation) ||
                                  value.error
                                }
                                formulaError={Boolean(value.error)}
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentFormula(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <div className="formulaRowWrapper">
                                  <div className="formulaColumnWrapper">
                                    <RichTextInput.RichTextToolbar
                                      component="description"
                                      isRequired={true}
                                      error={!value.description}
                                      source={value.description}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: any
                                      ) =>
                                        handlerComponentFormula(
                                          'ONBLUR_DESCRIPTION',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Description"
                                      data-testid="inputDescriptionFormula"
                                    />
                                    <RichTextInput.RichTextToolbarRef
                                      ref={(
                                        el: MutableRefObject<HTMLElement>
                                      ) =>
                                        (notationInputRef.current[
                                          `notation_${value.id}`
                                        ] = el)
                                      }
                                      component="notation"
                                      maxLength={8}
                                      isRequired={true}
                                      source={value.notation}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) =>
                                        handlerComponentFormula(
                                          'ONBLUR_NOTATION',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Notation"
                                      data-testid="inputNotationInpNumeric"
                                      onFocus={() =>
                                        handlerGlobalComponent(
                                          'FOCUS_WORKPAGES_COMPONENT',
                                          {
                                            focusId: `notation_${value.id}`
                                          }
                                        )
                                      }
                                    />
                                    {isNumeric(value.resultHyperFormula) ? (
                                      <FormattedInput.Number
                                        value={value.resultHyperFormula}
                                        disabled={true}
                                      />
                                    ) : (
                                      <input
                                        value={
                                          isNumeric(value.resultHyperFormula)
                                            ? parseFloat(
                                                value.resultHyperFormula
                                              ).toFixed(
                                                value.format?.value?.decimal ??
                                                  3
                                              )
                                            : value.resultHyperFormula
                                        }
                                        id="userFormula"
                                        placeholder="Calculated Output"
                                        disabled
                                      />
                                    )}
                                    <RichTextInput.RichTextToolbar
                                      component="unit"
                                      source={value.unit}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) =>
                                        handlerComponentFormula(
                                          'ONBLUR_UNIT',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Unit"
                                      data-testid="inputUnitFormula"
                                    />
                                  </div>
                                  <ComposerInput.Formula
                                    register={register}
                                    id={`formula_${value.id}`}
                                    defaultValue={value.formula}
                                    error={value?.error}
                                    onFocus={() =>
                                      handlerGlobalComponent(
                                        'FOCUS_WORKPAGES_COMPONENT',
                                        {
                                          focusId: `formula_${value.id}`
                                        }
                                      )
                                    }
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerComponentFormula(
                                        'ONBLUR_FORMULA',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e
                                      )
                                    }
                                  />
                                </div>
                              </FormulaComp>
                            </WorkpageItem>
                          ) : value.type === 'TABLE' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <TableComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  checkDuplication(
                                    'tableName',
                                    value.tableName,
                                    value.id
                                    ) || isEmptyString(value.description)
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentTable(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <div className="rowTableWrapper">
                                  <ComposerInput.TwoValue
                                    leftValue={value.tableName}
                                    rightValue={value.description}
                                    rightIsRequired={true}
                                    rightPlaceholder="Description"
                                    rightHandleSaveChange={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentTable(
                                        'ONBLUR_DESCRIPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                  />
                                  {componentTableRef ? (
                                    <div
                                      className="tableTableWrapper"
                                      onClick={(e: React.SyntheticEvent) =>
                                        handlerComponentTable(
                                          'EXPAND_TABLE',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e
                                        )
                                      }
                                    >
                                      <FormulaTable
                                        ref={(el: any) =>
                                          (workpageTableRef.current[value.id] =
                                            el)
                                        }
                                        name={
                                          value.tableName
                                            ? value.tableName
                                                .split(' ')
                                                .join('')
                                            : ''
                                        }
                                        data={
                                          value.tableBody ? value.tableBody : []
                                        }
                                        columnSetting={
                                          value.tableHeader
                                            ? value.tableHeader
                                            : []
                                        }
                                        dimensions={[
                                          value.tableBody
                                            ? value.tableBody[0].length
                                            : 0,
                                          value.tableBody
                                            ? value.tableBody.length
                                            : 0
                                        ]}
                                        cellStyle={expandTableDefaultCellStyle}
                                        displayedContent={
                                          value.displayedContent
                                            ? value.displayedContent
                                            : undefined
                                        }
                                        contextMenu={() => {
                                          return false;
                                        }}
                                        hideHiddenCells={true}
                                        nonEditable={true}
                                        stickyColumn={value.freeze?.column}
                                        stickyRow={value.freeze?.row}
                                        cells={value?.format?.cells}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="expandTableWrapper">
                                    <div
                                      onClick={(e: React.SyntheticEvent) =>
                                        handlerComponentTable(
                                          'EXPAND_TABLE',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e
                                        )
                                      }
                                    >
                                      <img
                                        height={12}
                                        src={images.maximizeTableComposer}
                                        alt="maximize-minimize-table"
                                      />
                                      <p>Expand Table</p>
                                    </div>
                                  </div>
                                </div>
                              </TableComp>
                            </WorkpageItem>
                          ) : value.type === 'TEXT' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <TextComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={!value.description}
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentText(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                {/* <TextareaAutosize {...register(`description_${value.id}`)} onBlur={(e: React.SyntheticEvent) => handlerComponentText('ONBLUR_DESCRIPTION', {typeComponent: value.type, idComponent: value.id, indexComponent: index}, e)} id='textText' placeholder='Title or Description' data-testid="inputDescriptionText">{value.description}</TextareaAutosize> */}
                                <RichTextInput.RichTextEditor
                                  // component="text"
                                  isRequired={true}
                                  source={value.description}
                                  handleSaveChange={(
                                    e: React.SyntheticEvent,
                                    data?: string
                                  ) =>
                                    handlerComponentText(
                                      'ONBLUR_DESCRIPTION',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e,
                                      data
                                    )
                                  }
                                  headingLevel={value.level}
                                  placeholder="Title or Description"
                                  data-testid="inputDescriptionText"
                                />
                                {/* <FormattedInput.Text component='text' value={value.description} {...register(`description_${value.id}`)} onBlur={(e: React.SyntheticEvent) => handlerComponentText('ONBLUR_DESCRIPTION', {typeComponent: value.type, idComponent: value.id, indexComponent: index}, e)} fontSize={value.level} placeholder="Title or Description" data-testid="inputDescriptionText"/> */}
                              </TextComp>
                            </WorkpageItem>
                          ) : value.type === 'DIVIDER' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <DividerComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentDivider(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                {...provided.dragHandleProps}
                              >
                                <div className="lineDivider" />
                              </DividerComp>
                            </WorkpageItem>
                          ) : value.type === 'IMAGE' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <ImageComp
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={!value.imageUrl}
                                imageHeight={value.imageHeight}
                                imageWidth={value.imageWidth}
                                alignment={value.imageAlignment}
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentImage(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                                imageIndicator={images.imageTopComposer}
                                {...provided.dragHandleProps}
                              >
                                <div id="imageRowWrapper">
                                  <ComposerInput.TwoValue
                                    leftValue={'Image'}
                                    rightValue={value.description}
                                    rightPlaceholder="Caption"
                                    rightHandleSaveChange={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentImage(
                                        'ONBLUR_DESCRIPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                  />
                                  <div id="imageInsideWrapper">
                                    {Boolean(value.imageUrl) ? (
                                      <>
                                        <Icon
                                          onClick={(e: React.SyntheticEvent) =>
                                            handlerComponentImage(
                                              'DELETE_IMAGE',
                                              {
                                                typeComponent: value.type,
                                                idComponent: value.id,
                                                indexComponent: index
                                              },
                                              e
                                            )
                                          }
                                          className="imageClose"
                                          icon="close"
                                        />
                                        <div id="imageHaveImage">
                                          <img
                                            id="imageShow"
                                            src={value.imageUrl}
                                            alt="static-img"
                                          ></img>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          id="imageNoImageTopIndicator"
                                          onClick={() => {
                                            imageRef.current[value.id].click();
                                          }}
                                        >
                                          <Icon
                                            notclickable={true}
                                            icon="upload"
                                            size={24}
                                            color="#118CFF"
                                          />
                                        </div>
                                        <label
                                          id="imageButton"
                                          onClick={() => {
                                            imageRef.current[value.id].click();
                                          }}
                                          data-testid="btnUploadImage"
                                        >
                                          Upload Image
                                        </label>
                                        <input
                                          ref={(el: HTMLInputElement) =>
                                            (imageRef.current[value.id] = el)
                                          }
                                          id={`imageinput_${value.id}`}
                                          type="file"
                                          style={{ display: 'none' }}
                                          onChange={(e: React.SyntheticEvent) =>
                                            handlerComponentImage(
                                              'UPLOAD_IMAGE',
                                              {
                                                typeComponent: value.type,
                                                idComponent: value.id,
                                                indexComponent: index
                                              },
                                              e
                                            )
                                          }
                                          data-testid="uploadFileImage"
                                        ></input>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </ImageComp>
                            </WorkpageItem>
                          ) : value.type === 'LINK' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              <TextComp
                                {...provided.dragHandleProps}
                                isActive={
                                  dataProperties.idComponent === value.id ||
                                  value.isActive
                                }
                                mandatoryError={
                                  !value.url || !isValidURL(value.url)
                                }
                                onClick={(e: React.SyntheticEvent) =>
                                  handlerComponentLink(
                                    'SHOW_PROPERTIES',
                                    {
                                      typeComponent: value.type,
                                      idComponent: value.id,
                                      indexComponent: index
                                    },
                                    e
                                  )
                                }
                              >
                                <ComposerInput.Link
                                  register={register}
                                  id={`url_${value.id}`}
                                  typeInput="link"
                                  defaultValue={value.url}
                                  placeholder="URL Link"
                                  error={!value.url}
                                  dataLink={value.title}
                                  active={value?.isActive}
                                  handleOpenNewTab={handleOpenNewTab}
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerComponentLink(
                                      'ONBLUR_URL',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                />
                              </TextComp>
                            </WorkpageItem>
                          ) : value.type === 'FIELD' ? (
                            <WorkpageItem
                              ref={provided.innerRef}
                              showMenu={value.showMenu}
                              provided={provided}
                              handleCopy={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'COPY',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              handleDelete={() =>
                                preventChanges
                                  ? threeDotChanges(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                                  : handlerComponent(
                                      'DELETE',
                                      value.id,
                                      index,
                                      value.type
                                    )
                              }
                              isDragging={snapshot.isDragging}
                              onClickHandler={(
                                e: React.MouseEvent<HTMLElement>
                              ) => {
                                onClickHandler(e, value.id);
                              }}
                              totalActiveComponent={totalActiveComponent}
                            >
                              {value.fieldType === 'NUMERICAL' ? (
                                <InputNumberComp
                                  isActive={
                                    dataProperties.idComponent === value.id ||
                                    value.isActive
                                  }
                                  mandatoryError={!value.description}
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerComponentField(
                                      'SHOW_PROPERTIES',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                  {...provided.dragHandleProps}
                                >
                                  <RichTextInput.RichTextToolbar
                                    component="descriptionField"
                                    isRequired={true}
                                    error={!value.description}
                                    source={value.description}
                                    handleSaveChange={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentField(
                                        'ONBLUR_DESCRIPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                    placeholder="Description"
                                    data-testid="inputDescriptionInpNumeric"
                                  />
                                  <FormattedInput.Number
                                    typeField="numericalField"
                                    value={value.inputValue}
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerComponentField(
                                        'ONBLUR_FIELD_VALUE',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e
                                      )
                                    }
                                    id="userInputNumber"
                                    placeholder="Input value here"
                                    decimalPlaces={
                                      value.format?.value?.decimal ?? 3
                                    }
                                    percentage={value.format?.value?.percentage}
                                    selectedCurrency={
                                      reverseMapCurrencyToHtmlEntities[
                                        value?.unit as keyof typeof reverseMapCurrencyToHtmlEntities
                                      ]
                                    }
                                    onToolbarMenuSelected={(
                                      e: any,
                                      type: any,
                                      data: any
                                    ) =>
                                      handleSelectedFieldToolbarMenu(
                                        e,
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        type,
                                        data
                                      )
                                    }
                                  />
                                </InputNumberComp>
                              ) : value.fieldType === 'TEXT' ? (
                                <>
                                  <InputTextComp
                                    {...provided.dragHandleProps}
                                    isActive={
                                      dataProperties.idComponent === value.id ||
                                      value.isActive
                                    }
                                    mandatoryError={!value.description}
                                    onClick={(e: React.SyntheticEvent) =>
                                      handlerComponentField(
                                        'SHOW_PROPERTIES',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e
                                      )
                                    }
                                    {...provided.dragHandleProps}
                                  >
                                    <RichTextInput.RichTextToolbar
                                      component="descriptionField"
                                      isRequired={true}
                                      source={value.description}
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data?: string
                                      ) =>
                                        handlerComponentField(
                                          'ONBLUR_DESCRIPTION',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      placeholder="Description"
                                      data-testid="inputDescriptionInpText"
                                    />
                                    <ComposerInput.Text
                                      typeInput="inputField"
                                      defaultValue={value.inputField}
                                      placeholder="Input text here"
                                      onBlur={(e: React.SyntheticEvent) =>
                                        handlerComponentField(
                                          'ONBLUR_FIELD_VALUE',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e
                                        )
                                      }
                                    />
                                  </InputTextComp>
                                </>
                              ) : value.fieldType === 'DROPDOWN' ? (
                                <InputDropDownComp
                                  isActive={
                                    dataProperties.idComponent === value.id ||
                                    value.isActive
                                  }
                                  mandatoryError={
                                    !value.description ||
                                    checkOptionsArray(value.options)
                                  }
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerComponentField(
                                      'SHOW_PROPERTIES',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                  {...provided.dragHandleProps}
                                >
                                  <RichTextInput.RichTextToolbar
                                    component="descriptionField"
                                    isRequired={true}
                                    source={value.description}
                                    handleSaveChange={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentField(
                                        'ONBLUR_DESCRIPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                    placeholder="Description"
                                    data-testid="inputDescriptionInpDropdown"
                                  />
                                  <ComposerInput.Select
                                    payload={{
                                      options: value.options,
                                      inputValue: value.inputValue,
                                      typeInput: 'FIELD'
                                    }}
                                    dataSelect={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentField(
                                        'SELECT_OPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                  />
                                </InputDropDownComp>
                              ) : value.fieldType === 'RADIO_BUTTON' ? (
                                <InputRadioComp
                                  isActive={
                                    dataProperties.idComponent === value.id ||
                                    value.isActive
                                  }
                                  mandatoryError={
                                    !value.description ||
                                    checkOptionsArray(value.options)
                                  }
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerComponentField(
                                      'SHOW_PROPERTIES',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                  imageComponent={images.radioOffComposer}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="inputRadioRowWrapper">
                                    <div className="inputRadioColumnWrapper">
                                      <RichTextInput.RichTextToolbar
                                        component="descriptionField"
                                        isRequired={true}
                                        source={value.description}
                                        handleSaveChange={(
                                          e: React.SyntheticEvent,
                                          data: string
                                        ) =>
                                          handlerComponentField(
                                            'ONBLUR_DESCRIPTION',
                                            {
                                              typeComponent: value.type,
                                              idComponent: value.id,
                                              indexComponent: index
                                            },
                                            e,
                                            data
                                          )
                                        }
                                        placeholder="Description"
                                        data-testid="inputDescriptionInpRadio"
                                      />
                                    </div>
                                    <ComposerInput.Radio
                                      payload={{
                                        options:
                                          value.options?.length > 0
                                            ? value.options
                                            : [],
                                        inputValue: value.inputValue,
                                        typeInput: 'FIELD'
                                      }}
                                      addNewRadio={() =>
                                        handlerComponentField('ADD_OPTION', {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        })
                                      }
                                      deleteRadio={(
                                        e: React.SyntheticEvent,
                                        indexRadio: number
                                      ) =>
                                        handlerComponentField(
                                          'REMOVE_OPTIONS',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index,
                                            indexRadio: indexRadio
                                          },
                                          e
                                        )
                                      }
                                      selectRadio={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) =>
                                        handlerComponentField(
                                          'SELECT_RADIO',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index
                                          },
                                          e,
                                          data
                                        )
                                      }
                                      dataChanges={(
                                        e: React.SyntheticEvent,
                                        data: string,
                                        indexRadio: number
                                      ) =>
                                        handlerComponentField(
                                          'ONBLUR_OPTIONS',
                                          {
                                            typeComponent: value.type,
                                            idComponent: value.id,
                                            indexComponent: index,
                                            indexRadio: indexRadio
                                          },
                                          e,
                                          data
                                        )
                                      }
                                    />
                                  </div>
                                </InputRadioComp>
                              ) : value.fieldType === 'DATE' ? (
                                <InputDateComp
                                  isActive={
                                    dataProperties.idComponent === value.id ||
                                    value.isActive
                                  }
                                  mandatoryError={!value.description}
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerComponentField(
                                      'SHOW_PROPERTIES',
                                      {
                                        typeComponent: value.type,
                                        idComponent: value.id,
                                        indexComponent: index
                                      },
                                      e
                                    )
                                  }
                                  {...provided.dragHandleProps}
                                >
                                  <RichTextInput.RichTextToolbar
                                    component="descriptionField"
                                    isRequired={true}
                                    error={!value.description}
                                    source={value.description}
                                    handleSaveChange={(
                                      e: React.SyntheticEvent,
                                      data: string
                                    ) =>
                                      handlerComponentField(
                                        'ONBLUR_DESCRIPTION',
                                        {
                                          typeComponent: value.type,
                                          idComponent: value.id,
                                          indexComponent: index
                                        },
                                        e,
                                        data
                                      )
                                    }
                                    placeholder="Description"
                                    data-testid="inputDescriptionInpDate"
                                  />
                                  <div id="selectInputDate">
                                    <Icon
                                      icon="calendar"
                                      color="#B3BFCC"
                                      size={14}
                                    />
                                    <p>Select date</p>
                                    <Icon icon="chevronDown" color="#B3BFCC" />
                                  </div>
                                </InputDateComp>
                              ) : (
                                <></>
                              )}
                            </WorkpageItem>
                          ) : (
                            <></>
                          )
                        }
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </BodyAtBodyMainContentWrapper>
              )}
            </BodyAtBodyMainContent>
          )}
        </Droppable>
      </ComposerBodyMainContent>
    </WrapperMainContent>
    <>
      <ExpandTable
        open={expandTable.value}
        selectedCell={selectedCell}
        expandTableContainerRef={expandTableContainerRef}
        expandTableRef={expandTableRef}
        dataProps={dataProperties}
        handlerExpandTableContextMenu={handlerExpandTableContextMenu}
        handlerExpandTableAfterChanges={handlerExpandTableAfterChanges}
        handlerExpandTableOnChange={handlerExpandTableOnChange}
        handlerExpandTableCellSelection={handlerExpandTableCellSelection}
        handlerExpandTableColumnResize={handlerExpandTableColumnResize}
        handlerExpandTableToolbar={handlerExpandTableToolbar}
        register={register}
        setValue={setValue}
        getValues={getValues}
        handlerExpandTableButtonTable={handlerExpandTableButtonTable}
        dataBodyOfCalculationCloned={dataBodyOfCalculationCloned}
        setDataBodyOfCalculationCloned={setDataBodyOfCalculationCloned}
      />

      {/* MENU */}
      <PageMenu
        open={openPage.value}
        anchorPosition={openPage.anchorPosition}
        handlerShowPageMenu={handlerShowPageMenu}
        handlerRenamePage={handlerRenamePage}
        handlerDeletePage={handlerDeletePage}
        handlerShowToLoggerPage={handlerShowToLoggerPage}
      />
      <TextMenu
        open={openText.value}
        anchorPosition={openText.anchorPosition}
        handlerShowTextComponent={handlerShowTextComponent}
        handlerComponentText={handlerComponentText}
        convertTextLevel={convertTextLevel}
      />
      <ImageMenu
        open={openImage.value}
        handlerShowImageComponent={handlerShowImageComponent}
        anchorPosition={openImage.anchorPosition}
        handlerComponentImage={handlerComponentImage}
      />
      <InputMenu
        open={openInput.value}
        anchorPosition={openInput.anchorPosition}
        handlerShowInputComponent={handlerShowInputComponent}
        handlerComponent={handlerComponent}
        dataProperties={dataProperties}
      />
      <FieldMenu
        open={openField.value}
        anchorPosition={openField.anchorPosition}
        handlerShowFieldComponent={handlerShowFieldComponent}
        handlerComponent={handlerComponent}
        dataProperties={dataProperties}
      />
    </>
  </ComposerMainContent>
);
