import React, { ChangeEvent, RefObject, useEffect, useState } from 'react'
import { InputContainer, InputWrapper } from '../styles'
import Icon from '../../../components/atoms/Icon'
import { useTheme } from '@mui/material'

interface IProps {
  inputRef: RefObject<HTMLInputElement>
  onSubmit: () => void
  isMobile: boolean;
  isDisabled: boolean;
}

export const AceInput = ({ inputRef, onSubmit, isMobile, isDisabled }: IProps) => {
  const theme = useTheme();
  const [isError, setIsError] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputRef.current?.value !== '') {
      onSubmit();
    } else {
      setIsError(true)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setIsError(false)
    }
  }

  const handleSubmit = () => {
    if (isDisabled) return;
    if (!inputRef.current?.value) {
      setIsError(true);
    } else {
      onSubmit()
    }
  }

  useEffect(() => {
    if (inputRef.current && !isDisabled) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100)
    }
  }, [inputRef.current, isDisabled])

  return (
    <InputContainer>
      <InputWrapper isError={isError} isDisabled={isDisabled} isMobile={isMobile} isFocused={isFocused}>
        <Icon icon="talkTaylor" color={theme.palette.primary.main} cursor="auto" />
        <input ref={inputRef} type="text"
          name="ask"
          id="input"
          onChange={handleChange}
          placeholder={'Ask me anything...'}
          onKeyDown={handleKeyDown}
          disabled={isDisabled}
          onFocus={() => {
            setIsError(false);
            setIsFocused(true);
          }}
          onBlur={() => setIsFocused(false)}
          autoFocus />
        <Icon
          icon="sendChat"
          color={
            (isDisabled)
              ? theme.palette.text.disabled
              : isError ? theme.palette.error.main
                : isFocused ? theme.palette.primary.main
                  : '#BFC9D9'
          }
          onClick={handleSubmit}
        />
      </InputWrapper>
    </InputContainer>
  )
}
