import { useTheme } from "@mui/material";
import Icon from "../../../../components/atoms/Icon";
import { HeaderToolbarPropsI } from "../../askAce.type";
import { ToolbarContainer } from "./styles";
import { useAppDispatch } from "../../../../hooks/hooks";
import { toastSetupActionPopUp } from "../../../../store/appState/popUp/toast/toastAction";
import { AceConfidenceLevel } from "../../components";

export const HeaderToolbar = ({
  upvote,
  downvote,
  textToCopy,
  onFeedback,
  confidenceLevel
}: HeaderToolbarPropsI) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleTextCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    dispatch(toastSetupActionPopUp('INFO', 'Answer copy to clipboard'));
  };
  return (
    <ToolbarContainer>
      <div id="rightSection">
        <Icon
          icon='copy'
          color={theme.palette.action.disabled}
          onClick={handleTextCopy} hoverColor={theme.customText.icon_high}
          useHoverFn
        />
        <Icon
          icon='like'
          color={upvote === null || !upvote ? theme.palette.action.disabled : theme.subscription.pro}
          onClick={() => onFeedback('LIKE')}
          hoverColor={theme.customText.icon_high}
          useHoverFn />
        <Icon
          icon='dislike'
          color={downvote === null || !downvote ? theme.palette.action.disabled : theme.palette.error.main}
          onClick={() => onFeedback('DISLIKE')}
          hoverColor={theme.customText.icon_high}
          useHoverFn
          styles={{ paddingTop: 2 }} />
      </div>
    </ToolbarContainer>
  );
};