import { Dispatch } from 'redux';
import Service from '../../service/generic/generic';
import {
  LOADING_DELETE_FILE,
  LOADING_RENAME_FILE,
  LOADING_MOVE_FILE,
} from '../types/fileTypes';

const service = new Service('');

export function deleteFile(fileId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true, LOADING_DELETE_FILE));
      await service.deleteResource('file', fileId);
      dispatch(loadingAction(false, LOADING_DELETE_FILE));
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function renameFile(fileId: string, fileName: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true, LOADING_RENAME_FILE));
      await service.putSubResource('file', fileId, 'filename', {
        fileName
      });
      dispatch(loadingAction(false, LOADING_RENAME_FILE));
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function moveFile(fileId: string, folderId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingAction(true, LOADING_MOVE_FILE));
      await service.putResource('file', fileId, {
        folderId,
      });
      dispatch(loadingAction(false, LOADING_MOVE_FILE));
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

const loadingAction = (isLoading: boolean, typeAction: string) => ({
  type: typeAction,
  isLoading,
});
