import * as React from "react";
import { SVGProps } from "react";

const SvgSubscript = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 19.2h-2v1h3v1h-4v-2c0-.55.45-1 1-1h2v-1h-3v-1h3c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1Zm-16.12 0h2.66l3.4-5.42h.12l3.4 5.42h2.66l-4.65-7.27 4.34-6.73h-2.68l-3.07 4.99h-.12L8.85 5.2H6.19l4.32 6.73-4.63 7.27Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSubscript;
