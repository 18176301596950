import * as React from "react";

export const Like = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.667 7.333l2.667-6a2 2 0 012 2V6h3.773a1.334 1.334 0 011.333 1.534l-.92 6a1.334 1.334 0 01-1.333 1.133h-7.52m0-7.333v7.333m0-7.333h-2a1.333 1.333 0 00-1.333 1.333v4.667a1.333 1.333 0 001.333 1.333h2"
    ></path>
  </svg>
);
