import { Box, Divider } from '@mui/material';
import {
  CalculationContent,
  ChildWrapper
} from '../../components/organisms/hoc/elements';
import UserService, { CompanyInterface, ResponseGetUsersI } from '../../service/UserService';
import { findMatch, mappingUser } from '../../utils/helpersFunction';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { APIResponse } from '../../utils/interfaces';
import AccountTable from '../../components/molecules/AccountTable';
import ContentHeaderSection from './contentHeaderSection';
import HelperButton from '../../components/molecules/HelperButton';
import { LoadingSpinner } from '../../components/atoms/loading';
import { ReduxState } from '../../store';
import SideBar from '../../components/molecules/sidebar';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { getUserProfile } from '../../utils/storage';
import { searchUser } from '../../store/actions/userAction';
import { updateUserList } from '../../store/types/userTypes';
import { useHistory } from 'react-router-dom';

const DEFAULT_PAGE_SIZE = 50;

const AccountManager = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userRole = getUserProfile();
  const {
    isMinimize,
    handleInActiveFocus,
    renderAdditionalFunctionsAccountManager,
    openAdditionalFunctionsAccountManager,
    isActiveItemHandle,
    fetchSubscriptionList
  } = props;

  const [value, setValue] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<string>('');
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [usersData, setUsersData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [companies, setCompanies] = useState<Array<CompanyInterface>>([]);
  const [errMessage, setErrMessage] = useState('');
  const [total, setTotal] = useState<number>(0);
  const [isLoadingfetch, setIsLoadingFetch] = useState<boolean>(false);

  const { dataUsers, isActiveUser, changePlan, isLoading } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  useEffect(() => {
    const checkNotChecker =
      /REKAVA_ADMIN|COMPOSER_ADMIN|COMPOSER|PRINCIPAL|LOGGER/.test(
        String(userRole)
      );
    if (!checkNotChecker) {
      history.push('/');
    }

    (async () => {
      await handleGetUsers(page, DEFAULT_PAGE_SIZE, sort, 'DEFAULT');
    })();
  }, [page]);

  useEffect(() => {
    if (value === '') {
      setSearchMode(false);
    }
    if (dataUsers?.data?.length > 0) {
      setUsersData(mappingUser(dataUsers?.data));
      setTotalPage(dataUsers?.total_pages);
    }
  }, [dataUsers?.data]);

  isActiveItemHandle(isActiveUser);

  const handleGetUsers = async (
    page: any,
    size: any,
    sort?: string,
    type?: string
  ) => {
    try {
      setIsLoadingFetch(true);
      let newPage;
      if (type === 'DEFAULT' || type === 'SORT') {
        page === 0 ? (newPage = 1) : (newPage = page - 1);
      } else if (type === 'EDIT' || type === 'ADD') {
        newPage = page;
      }
      const response: ResponseGetUsersI = await UserService.getAllUsers(
        newPage,
        size,
        'COMPOSER,LOGGER',
        sort
      );
      dispatch(updateUserList(response));
      setUsersData(mappingUser(response.data));
      setTotalPage(response.total_pages);
      setTotal(response.total);
      setIsLoadingFetch(false);
    } catch (error: any) {
      console.error(error);
    }
  };

  const basicHeader = [
    { label: 'Name', icon: 'ARROW', width: 40 },
    { label: 'Email', icon: 'NONE', width: 42 },
    // { label: 'Company', icon: 'ARROW', width: 40 },
    { label: 'Organization/Institution', icon: 'NONE', width: 44 },
    { label: 'Plan', icon: 'NONE', width: 20 },
    { label: 'Payment Status', icon: 'NONE', width: 22 },
    { label: 'Last Active', icon: 'NONE', width: 25 },
    { label: 'Actions', icon: 'NONE', width: 10 }
  ];

  const handlePageChange = (e: any, page: number) => {
    e.preventDefault();

    setPage(page);
  };

  const searchAccount = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  useEffect(() => {
    changePlan && imageOnClick();
  }, [changePlan]);

  const imageOnClick = () => {
    try {
      Promise.all([
        dispatch(searchUser(value, page, DEFAULT_PAGE_SIZE, sort)),
        setSearchMode(true)
      ]).then(response => {
        const str: any = value;
        const searchData: any = response[0];
        if (searchData?.data.length === 0) {
          setErrMessage(`No result for "${value}"`);
          setUsersData([]);
          setTotalPage(0);
        } else {
          const updatedData: any = mappingUser(searchData?.data).map(
            (item: any) => {
              return {
                ...item,
                name: findMatch(str, item.name),
                email: findMatch(str, item.email)
              };
            }
          );
          setUsersData(updatedData);
          setTotalPage(searchData?.total_pages);
        }
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  const arrowOnClick = (e: any, direction: string, rowValue: any) => {
    const sortData: string =
      (direction === 'ASC' ? 'sort_by=%2B' : 'sort_by=-') +
      rowValue.label.toLowerCase() +
      '&';
    Promise.all([setSort(sortData)]).then(() => {
      searchMode
        ? dispatch(searchUser(value, page, DEFAULT_PAGE_SIZE, sortData))
        : handleGetUsers(page, DEFAULT_PAGE_SIZE, sortData, 'SORT');
    });
  };

  const handleAddUser = async () => {
    try {
      const companyList = await fetchCompanyList();
      dispatch(
        formModalSetupActionPopUp('ADD_USER_USER_MANAGEMENT', {
          data: usersData,
          companies: companyList
        })
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleAddCompany = () => dispatch(
    formModalSetupActionPopUp('ADD_COMPANY_USER_MANAGEMENT', {
      companies
    })
  );

  //change triangle color
  const onMouseOver = (event: any) => {
    event.target.style.borderBottomColor = '#118CFF';
    event.target.style.borderTopColor = '#118CFF';
  };

  const onMouseOut = (event: any) => {
    event.target.style.borderBottomColor = '#686D8E';
    event.target.style.borderTopColor = '#686D8E';
  };

  const fetchCompanyList = async () => {
    try {
      const companiesData: APIResponse<Array<CompanyInterface>> = await UserService.getCompanyList();
      setCompanies(companiesData.data);
      return Promise.resolve(companiesData.data);
    } catch (error: any) {

    }
  };

  useEffect(() => {
    fetchSubscriptionList();
    fetchCompanyList();
  }, []);

  const isShowLoading: boolean = isLoading || isLoadingfetch;

  return (
    <>
      <CalculationContent>
        <SideBar isMinimize={isMinimize} view={false} />
        <Divider orientation="vertical" flexItem />
        <ChildWrapper onClick={handleInActiveFocus}>
          {isShowLoading && <LoadingSpinner />}
          {renderAdditionalFunctionsAccountManager()}
          <Box>
            <ContentHeaderSection
              value={value}
              searchAccount={searchAccount}
              page={page}
              totalPage={totalPage}
              handlePageChange={handlePageChange}
              imageOnClick={imageOnClick}
              handleAddUser={handleAddUser}
              size={DEFAULT_PAGE_SIZE}
              data={usersData}
              total={total}
              handleAddCompany={handleAddCompany}
            />
            <AccountTable
              page={page}
              basicHeader={basicHeader}
              tableData={usersData}
              onRowClick={openAdditionalFunctionsAccountManager}
              arrowOnClick={arrowOnClick}
              onMouseOut={onMouseOut}
              onMouseOver={onMouseOver}
              errMessage={errMessage}
            />
          </Box>
        </ChildWrapper>
      </CalculationContent>
      <HelperButton />
    </>
  );
};

export default calculationsNav(AccountManager);
