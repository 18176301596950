import * as React from "react";

const SvgEuro = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.81 20.5c-.982 0-1.88-.208-2.696-.624-.799-.416-1.473-1.04-2.022-1.872-.55-.832-.915-1.847-1.098-3.045H6.496l.4-1.723h.923v-1.173c.017-.216.033-.408.05-.574H6.521l.374-1.722h1.249c.2-.965.499-1.78.898-2.446.4-.666.865-1.207 1.398-1.623a5.23 5.23 0 0 1 1.697-.898c.616-.2 1.232-.3 1.847-.3.7 0 1.34.125 1.922.374.6.233 1.132.6 1.598 1.099l-.699 1.897a1.174 1.174 0 0 1-.374-.325c-.084-.15-.184-.3-.3-.45a1.54 1.54 0 0 0-.424-.448 2.272 2.272 0 0 0-.7-.325c-.282-.083-.656-.125-1.123-.125-.432 0-.848.075-1.248.225-.399.15-.773.366-1.123.649-.35.283-.649.649-.898 1.098-.25.45-.441.982-.574 1.598h6.065l-.624 1.722H9.791l-.05.5V13.235h5.067l-.649 1.723H9.916c.166.832.432 1.53.798 2.096.367.566.824.99 1.373 1.273.55.283 1.157.425 1.822.425.333 0 .658-.042.974-.125.316-.083.616-.208.899-.375.282-.166.549-.374.798-.623l.874 1.497a5.42 5.42 0 0 1-1.672 1.049c-.6.216-1.257.324-1.972.324Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEuro;
