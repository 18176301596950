import { Button, useTheme } from '@mui/material';
import Text from '../../../../components/atoms/Text';
import { BlankWrapper, ButtonEWrapper } from './styles';

interface BlankDrivesI {
  handleModal: (type: string) => void;
}

export const BlankDrives = ({ handleModal }: BlankDrivesI) => {
  const theme = useTheme();
  return (
    <BlankWrapper>
      <Text variant="h1" color={theme.customText.high}>
        A place for teamwork
      </Text>
      <Text variant="body1" color={theme.customText.low}>
        Use Business Drives to organize and collaborate on calculations
      </Text>
      <ButtonEWrapper>
        <Button
          variant="contained"
          color="primary"
          data-testid="btnModalCreateDrive"
          onClick={() => handleModal('CREATE_TEAM_DRIVES')}
        >
          Create a Drive
        </Button>
      </ButtonEWrapper>
    </BlankWrapper>
  );
};
