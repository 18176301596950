import styled from "styled-components";

interface Props {
    latex?: boolean;
    expandFormula?: boolean;
    visible?: boolean;
    multiLine?: boolean;

}

export const Container = styled.div<Props>((props) => ({
    display: "flex",
}));

export const LatexLine = styled.div<Props>((props) => ({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
}));

export const Expand = styled.div<Props>((props) => ({
    zIndex: props.latex ? 2 : 1,
    backgroundColor: "#F9F9F9",
    display: "flex",
    flexDirection: "row",
    minWidth: "52px",
    height: "28px",
    borderRadius: "4px",
    gap: "8px",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& > #expandHandler": {
        cursor: "pointer",
        "& > #arrow": {
            width: "8px",
            height: "6px",
            paddingLeft: "4px"
        },
    },
    "& > #arrow": {
        cursor: "pointer",
    },
    "& > #imgFx": {
        width: "14px",
        height: "12px",
        marginLeft: props.expandFormula ? "" : "26px"
    },

}));

export const LatexSection = styled.div<Props>((props) => ({
    paddingTop: 6,
    display: "flex",
    flexDirection: "column",
    gap: 4,
}));

export const LatexItem = styled.div<Props>((props) => ({
    display: "flex",
    fontSize: "12px",
    columnGap: "4px",
    flexDirection: 'row',
}));

export const LatexItemWrapper = styled.div<Props>((props) => ({
    display: "flex",
    fontSize: "12px",
    columnGap: "4px",
    flexDirection: 'row',
}));

export const LatexExpand = styled.div<Props>((props) => ({
    display: "flex",
    flexDirection: "column",
    visibility: props.visible ? "visible" : "hidden",
    position: props.visible ? 'relative' : 'absolute',
    gap: 4,
}));

export const LatexUnitFont = styled.p({
    fontFamily: "KaTeX_Math",
    fontStyle: "italic",
    fontSize: 14
});

export const LatextUnitDiv = styled.div({
    display: "flex",
    alignItems: "center"
});

export const LatexVarWrapper = styled.div({
    display: "flex",
    textAlign: "left",
});

export const NotationUnit = styled.div({
    display: "flex",
    minWidth: "32px"
});