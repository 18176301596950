import * as React from "react";

const SvgPound = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.293 20.375-.752-1.678c.685-.334 1.22-.668 1.603-1.001.384-.334.66-.743.826-1.227.184-.501.276-1.144.276-1.928 0-.3-.025-.643-.075-1.027a9.663 9.663 0 0 0-.276-1.302H7.268v-1.703H8.52a17.6 17.6 0 0 1-.126-.75c-.016-.251-.025-.493-.025-.727 0-.935.2-1.736.601-2.404.4-.667.943-1.185 1.628-1.552a4.48 4.48 0 0 1 2.228-.576c.651 0 1.252.117 1.803.35.551.218 1.069.551 1.553 1.002l-1.227 1.553a4.076 4.076 0 0 0-.927-.852c-.334-.25-.851-.375-1.552-.375-.418 0-.785.116-1.102.35-.317.234-.568.56-.751.977-.167.417-.25.918-.25 1.502 0 .2.008.426.025.676.033.234.083.51.15.826h3.205v1.703h-2.83c.117.434.192.843.226 1.227.05.384.075.734.075 1.052 0 .4-.034.784-.1 1.151-.05.368-.142.718-.276 1.052-.117.334-.267.66-.45.977.417-.05.818-.026 1.201.075.401.083.785.2 1.152.35.367.134.735.259 1.102.376.367.1.726.15 1.077.15.3 0 .592-.058.876-.175.3-.134.61-.326.926-.576l.727 1.527c-.451.367-.902.643-1.353.827a3.437 3.437 0 0 1-1.352.275c-.25 0-.559-.058-.926-.175a136.7 136.7 0 0 0-1.202-.376 22.3 22.3 0 0 0-1.277-.4 4.036 4.036 0 0 0-1.127-.176c-.4 0-.843.084-1.327.25a11 11 0 0 0-1.602.752Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPound;
