import * as React from "react";
import { SVGProps } from "react";

const SvgSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.241 19.5h-2.706a.188.188 0 0 1-.105-.337 8.952 8.952 0 0 0 3.56-7.564 9.395 9.395 0 0 0-8.635-8.592 8.994 8.994 0 0 0-8.98 11.55 8.997 8.997 0 0 0 3.186 4.606.188.188 0 0 1-.105.338H3.75a.75.75 0 0 0 0 1.499h3.748a.75.75 0 0 0 .75-.75V18.9a.75.75 0 0 0-.353-.629 7.497 7.497 0 1 1 8.2 0 .75.75 0 0 0-.352.63v1.222a.787.787 0 0 0 .75.877h3.748a.75.75 0 1 0 0-1.5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgSymbol;
