import Editor from '@draft-js-plugins/editor';
import { Editor as DraftEditor, EditorState, convertToRaw } from 'draft-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import {
  UnorderedListButton,
  OrderedListButton,
  SupButton,
  SubButton
} from '@draft-js-plugins/buttons';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import editorStyles from './textEditorStyles.module.css';
import buttonStyles from './textButtonStyles.module.css';
import toolbarStyles from './textEditorToolbarStyles.module.css';
import { RichTextPropsI } from './RichTextToolbar';
import draftToHtml from 'draftjs-to-html';
import {
  draftBlockToHtml,
  htmlToDraftBlocks
} from '../../../utils/rich-text-editor.utils';

// const staticToolbarPlugin = createToolbarPlugin({
//   theme: { buttonStyles, toolbarStyles }
// });
// const { Toolbar } = staticToolbarPlugin;
// const plugins = [staticToolbarPlugin];

export const RichTextEditor = ({
  source,
  headingLevel,
  onChange,
  handleSaveChange,
  isRequired,
  ...rest
}: RichTextPropsI) => {
  const editor = useRef<DraftEditor>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [plugins, Toolbar] = useMemo(() => {
    const toolbarPlugins = createToolbarPlugin({
      theme: { buttonStyles, toolbarStyles }
    });
    return [[toolbarPlugins], toolbarPlugins.Toolbar];
  }, []);

  useEffect(() => {
    let editorState;
    if (!source) {
      editorState = EditorState.createEmpty();
    } else {
      editorState = htmlToDraftBlocks(source);
    }
    setEditorState(editorState);
  }, [source]);

  const onHandleChange = (editorState: EditorState) => {
    setEditorState(editorState);
    if (onChange) {
      const html = draftBlockToHtml(editorState);
      onChange(draftToHtml(html as any));
    }
  };

  const onBlur = (e: React.SyntheticEvent) => {
    if (handleSaveChange) {
      const rawContent = convertToRaw(editorState.getCurrentContent());
      const html = draftToHtml(rawContent);
      handleSaveChange(e, html);
    }
  };

  return (
    <div
      className={editorStyles.editor}
      data-text-heading={headingLevel}
      data-empty={isRequired && !source}
      onBlur={onBlur}
      onClick={() => editor.current?.focus()}
      {...rest}
    >
      <Toolbar>
        {
          // may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps: any) => (
            <React.Fragment>
              <SubButton {...externalProps} />
              <SupButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </React.Fragment>
          )
        }
      </Toolbar>
      <Editor
        editorState={editorState}
        onChange={onHandleChange}
        plugins={plugins}
        ref={editor}
        customStyleMap={{
          SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
          SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
        }}
      />
    </div>
  );
};
