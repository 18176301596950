import React from 'react';
import ReactMarkdown from 'react-markdown';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';

const _mapProps = (props) => ({
  ...props,
  remarkPlugins: [
    remarkMath,
  ],
  rehypePlugins: [
    rehypeRaw,
    rehypeKatex,
  ],
  components: {
    ...props.components,
    math: (opts) => <BlockMath math={opts.value} />,
    inlineMath: (opts) => <InlineMath math={opts.value} />,
  },
});

const Markdown = (props) => (
  <ReactMarkdown {..._mapProps(props)} />
);

export default Markdown;