import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import images from "../../../assets";
import { Content, Item, ItemContainer, Wrapper } from "./element";

interface DropDownPropsI {
  value: any;
  dataSource: Array<any>;
  labelValue: string;
  labelName: string;
  onClickItem: (payload: any) => void;
  dataTestid?: string;
}

export default function Dropdown({
  value,
  labelValue,
  labelName,
  dataSource,
  onClickItem,
  dataTestid = ''
}: DropDownPropsI) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickItem = (selectedItem: any) => {
    onClickItem(selectedItem);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <Wrapper ref={ref}>
      <Content onClick={() => setIsMenuOpen(!isMenuOpen)} data-testid={dataTestid}>
        <Typography>
          {value
            ? value[labelName]
            : dataSource[dataSource.length - 1][labelName]}
        </Typography>
        <img
          alt="dropdown-arrow"
          src={
            isMenuOpen ? images.arrowRightComposer : images.arrowDownComposer
          }
        />
      </Content>
      {isMenuOpen && (
        <ItemContainer>
          {React.Children.toArray(
            dataSource.map((item: any) => (
              <Item
                key={`props_version_${item[labelValue]}`}
                value={item[labelValue]}
                onClick={() => handleClickItem(item)}
                data-testid={item[labelName]}
              >
                {item[labelName]}
              </Item>
            ))
          )}
        </ItemContainer>
      )}
    </Wrapper>
  );
}
