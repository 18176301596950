// LIBRARY IMPORT
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import '@silevis/reactgrid/styles.css';

// COMPONENT IMPORT

import './composer.css';
import { Container } from '../../pageElements/composer';
import { LoggerPreview } from './loggerPreview/loggerPreview';
import HelperButton from '../../components/molecules/HelperButton';

// REDUX IMPORT

import { ReduxState } from '../../store';
import {
  reducerModifierIsDraggingActionComposer,
  reducerModifierExportEventActionComposer,
  copyActionComposer,
  reorderActionComposer,
  addPageActionComposer,
  getDataActionComposer,
  twoMenuHandlerActionComposer,
  radioButtonInputActionComposer,
  mainContainerActionComposer,
  exportPropertiesActionComposer,
  textInputActionComposer,
  dropdownInputActionComposer,
  importPropertiesActionComposer,
  numericalInputActionComposer,
  dateInputActionComposer,
  formulaActionComposer,
  textActionComposer,
  imageActionComposer,
  reducerModifierActivePageActionComposer,
  reducerModifierIsEditingActionComposer,
  renamePageActionComposer,
  patchDataVariableManagerMenuActionComposer,
  tableActionComposer,
  changeInputActionComposer,
  changeFieldActionComposer,
  getSymbolActionComposer,
  reorderMultipleActionComposer,
  moveToOtherPage,
  patchDataExpandTableActionComposer,
  actionButtonExpandTableActionComposer,
  contextMenuExpandTableActionComposer,
  patchColumnSizeExpandTableActionComposer,
  updateDataAfterCellChanges,
  LinkActionComposer,
  toolbarExpandTableActionComposer,
  fieldActionComposer,
  unhidePageActionComposer,
  changePageActionComposer
} from '../../store/composer/composerPage/composerActions';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { inputSwitchActionMenuComposer } from '../../store/composer/composerMenu/composerMenuActions';
import {
  fetchCalculation,
  fetchInputFileList
} from '../../store/actions/inputFileAction';
import {
  fetchTutorial,
  formHelperPanel,
  setLoggerPreview
} from '../../store/appState/global/globalActions';
import { changePositionActionCircleatorBreadcrumb } from '../../store/breadCrumb/circleator/circleatorAction';
import {
  resetImageState,
  setActionState,
  setCalculationTarget,
  setPreventChanges
} from '../../store/composer/composerChanges/composerChangesActions';

// UTILITY IMPORT

import { queue } from '../../service/queueManager/queue';
import {
  getPreventChangesCalculation,
  getToken,
  getTutorialsList,
  getUserProfile,
  getUserTutorials,
  setPreventChangesCalculation
} from '../../utils/storage';
import {
  checkVariablePrefix,
  mapCurrencyToHtmlEntities
} from '../../utils/helpersFunction';
import { indexToChar } from '../../components/molecules/FormulaTable/utils';
import { ComposerLeft } from './composer-left/composer-left.organism';
import { ComposerMiddle } from './composer-middle/composer-middle.organism';
import { ComposerRight } from './composer-right/composer-right.organism';
import VariableSheet from '../../components/molecules/VariableSheet';
import useWebSocket from 'react-use-websocket';
import HiddenTable from './hiddenTable';
import { getUserProfileAction } from '../../store/actions/userAction';

const SHOW_PROPERTIES = 'SHOW_PROPERTIES';
const ONBLUR_TITLE = 'ONBLUR_TITLE';
const ONBLUR_URL = 'ONBLUR_URL';
const FORMULA_DISPLAY = 'FORMULA_DISPLAY';

export const CompposerCreate = () => {
  // USEFORM

  const { register, setValue, getValues, control } = useForm<any>();
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const headerRef = useRef<any>(null);
  const imageRef = useRef<any>({});
  const workpageTableRef = useRef<any>({});
  const expandTableRef = useRef<any>(null);
  const expandTableContainerRef = useRef<any>(null);
  const notationInputRef = useRef<any>({});
  const vlcRef = useRef<any>(null);
  const token = getToken();
  const APIKEY = process.env.REACT_APP_WEBSOCKET_API;

  const [componentTableRef, setComponentTableRef] = useState<boolean>(false);

  // STATES AND PARAMS, NEED TO REFACTOR

  //// DND
  const [dataBodyOfCalculationCloned, setDataBodyOfCalculationCloned] =
    useState<Array<any>>([]);

  //// TABLE

  const [selectedCell, setSelectedCell] = useState<string | null>(null);

  //// FOCUS

  const [currentFocus, setCurrentFocus] = useState<string | null>(null);
  const [startInputFocus, setStartInputFocus] = useState<number | null>(null);
  const [endInputFocus, setEndInputFocus] = useState<number | null>(null);

  //// MODAL

  ////// EXPAND TABLE

  const [expandTable, setExpandTable] = useState<any>({
    value: false
  });

  //// MENU

  ////// PAGE

  const [openPage, setOpenPage] = useState<any>({
    value: false,
    anchorPosition: null
  });

  ////// MENU SELECT AT PROPERTIES

  const [openCurrency, setOpenCurrency] = useState<any>({
    value: false,
    anchorPosition: null
  });
  const [openText, setOpenText] = useState<any>({
    value: false,
    anchorPosition: null
  });
  const [openImage, setOpenImage] = useState<any>({
    value: false,
    anchorPosition: null
  });
  const [openInput, setOpenInput] = useState<any>({
    value: false,
    anchorPosition: null
  });
  const [openField, setOpenField] = useState<any>({
    value: false,
    anchorPosition: null
  });
  const [activeId, setActiveId] = useState<any>();

  //// PARAMS

  const { id: idCalculation, folderId: folderIdCalculation } = useParams<{
    id: string;
    folderId: string;
  }>();

  //WEB SOCKET
  const [socketUrl, setSocketUrl] = useState<string>(APIKEY ?? '');
  const { sendMessage, getWebSocket } = useWebSocket(socketUrl, {
    retryOnError: true,
    share: true
  });

  // REDUX USESELECTOR & USEEFFECT PAGE LOAD

  const {
    isDragging,
    isEditing,
    activePage,
    dataVariableManagerMenu,
    dataPages,
    dataProperties,
    dataHeadOfCalculation,
    dataBodyOfCalculation,
    dataSymbol,
    exportEvent
  } = useSelector((state: ReduxState) => state.composer, shallowEqual);

  const { dataSwitch } = useSelector(
    (state: ReduxState) => state.composerMenu,
    shallowEqual
  );

  const { userProfile } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { calculation } = useSelector(
    (state: ReduxState) => state.inputFileManager,
    shallowEqual
  );

  const { subscription } = userProfile;

  const { preventChanges, actionState, calculationTarget, loggerPreviewImage } =
    useSelector((state: ReduxState) => state.composerChanges, shallowEqual);

  useEffect(() => {
    dispatch(getDataActionComposer(idCalculation));
    dispatch(fetchCalculation(idCalculation));
    if (dataSymbol.length < 1) dispatch(getSymbolActionComposer());
    dispatch(changePositionActionCircleatorBreadcrumb('composer'));
    dispatch(getUserProfileAction());
  }, []);

  //// EDIT COMPOSER NEW TAB & CHECK IF THERE ARE CHANGES FROM OTHER TAB WITH THE SAME COMPOSER FUNCTION

  const [renderCount, setRenderCount] = useState(0);
  const { id } = useParams<{ id: string }>();
  const LOCAL_STORAGE_NAME = 'composer_data__' + id;
  const TAB_SESSION_NAME = 'composer_data__' + id;

  const onFocus = () => {
    const SessionStorage = sessionStorage.getItem(TAB_SESSION_NAME)?.toString();
    const LocalStorage = localStorage.getItem(LOCAL_STORAGE_NAME)?.toString();
    if (SessionStorage != LocalStorage) {
      dispatch(
        toastSetupActionPopUp(
          'REFRESH',
          'This file has been updated. Please refresh the browser to see the changes'
        )
      );
    }
  };

  const setLocalSessionItem = () => {
    localStorage.setItem(
      LOCAL_STORAGE_NAME,
      JSON.stringify(dataBodyOfCalculation[activePage]) +
        JSON.stringify(dataPages)
    );
    sessionStorage.setItem(
      TAB_SESSION_NAME,
      JSON.stringify(dataBodyOfCalculation[activePage]) +
        JSON.stringify(dataPages)
    );
  };

  useEffect(() => {
    if (dataBodyOfCalculation) {
      setRenderCount(renderCount + 1);
    }

    if (renderCount >= 2) {
      setLocalSessionItem();

      window.addEventListener('focus', onFocus);

      onFocus();

      return () => {
        window.removeEventListener('focus', onFocus);
      };
    }
  }, [dataBodyOfCalculation[activePage]]);

  //// TUTORIAL FUNCTION

  useEffect(() => {
    const UserTutorials: any = getUserTutorials();
    const checkUserTutorial = async (UserTutorials: any) => {
      try {
        const tutorialPage: any = await getTutorialsList().find(
          (item: any) => item.title === 'Create a Calculation'
        );
        const tutorialDone =
          (await UserTutorials.hasOwnProperty(tutorialPage.id)) === true;
        !tutorialDone && openTutorial(tutorialPage);
      } catch (error: any) {
        console.error(error);
      }
    };
    checkUserTutorial(UserTutorials).catch(error => console.error(error));
  }, []);

  const openTutorial = async (payload: any) => {
    try {
      const result: any = await dispatch(fetchTutorial(payload.id));
      dispatch(formHelperPanel(true, 'TUTORIAL', result.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  //// UPDATED COMPONENT FUNCTION

  const preventChangesStatus = getPreventChangesCalculation();

  useEffect(() => {
    dispatch(resetImageState());
    if (
      preventChangesStatus.status &&
      preventChangesStatus.calculationId === idCalculation
    ) {
      Promise.all([dispatch(fetchInputFileList(idCalculation))]).then(
        response => {
          const inputFileList = response[0];
          inputFileList.length > 0
            ? dispatch(setPreventChanges(true))
            : dispatch(setPreventChanges(false));
        }
      );
    } else {
      setPreventChangesCalculation({});
      dispatch(setPreventChanges(false));
    }

    if (!preventChanges && idCalculation === calculationTarget.calculationId) {
      setTimeout(() => {
        if (
          actionState.typeHandler === 'DELETE' ||
          actionState.typeHandler === 'COPY'
        ) {
          handlerComponent(
            actionState.typeHandler,
            actionState.idComponent,
            actionState.indexComponent,
            actionState.typeComponent
          );
        } else if (actionState.typeHandler === 'DND') {
          dispatch(
            copyActionComposer(
              actionState.idCalculation,
              actionState.draggableId,
              actionState.destinationIndex
            )
          );
        } else if (actionState.typeHandler === 'CHANGE_INPUT') {
          dispatch(
            changeInputActionComposer(
              actionState.idCalculation,
              actionState.idComponent,
              actionState.indexComponent,
              actionState.typeComponent
            )
          );
        } else if (actionState.typeHandler === 'CHANGE_FIELD') {
          dispatch(
            changeFieldActionComposer(
              actionState.idCalculation,
              actionState.idComponent,
              actionState.indexComponent,
              actionState.typeComponent
            )
          );
        }
        dispatch(setActionState({}));
        dispatch(setCalculationTarget({}));
      }, 20);
    }
  }, [preventChanges]);

  const threeDotChanges = async (
    typeHandler: string,
    idComponent: string,
    indexComponent?: number,
    typeComponent?: string
  ) => {
    try {
      await Promise.all([
        dispatch(
          formModalSetupActionPopUp('COMPOSER_DATA_CHANGES_CONFIRMATION', {
            calculationInfo: {
              calculationId: idCalculation,
              folderId: folderIdCalculation
            },
            action: {
              typeHandler: typeHandler,
              idComponent: idComponent,
              indexComponent: indexComponent,
              typeComponent: typeComponent
            }
          })
        )
      ]);
    } catch (error: any) {
      console.error(error);
    }
  };

  const dndChanges = async (
    typeHandler: string,
    idCalculation: string,
    draggableId: string,
    destinationIndex: number
  ) => {
    try {
      await Promise.all([
        dispatch(
          formModalSetupActionPopUp('COMPOSER_DATA_CHANGES_CONFIRMATION', {
            calculation,
            calculationInfo: {
              calculationId: idCalculation,
              folderId: folderIdCalculation
            },
            action: {
              typeHandler: typeHandler,
              idCalculation: idCalculation,
              draggableId: draggableId,
              destinationIndex: destinationIndex
            }
          })
        )
      ]);
    } catch (error: any) {
      console.error(error);
    }
  };

  const inputChanges = async (
    typeHandler: string,
    idCalculation: string,
    idComponent: string,
    indexComponent?: number,
    typeComponent?: string
  ) => {
    try {
      await Promise.all([
        dispatch(
          formModalSetupActionPopUp('COMPOSER_DATA_CHANGES_CONFIRMATION', {
            calculationInfo: {
              calculationId: idCalculation,
              folderId: folderIdCalculation
            },
            action: {
              typeHandler: typeHandler,
              idCalculation: idCalculation,
              idComponent: idComponent,
              indexComponent: indexComponent,
              typeComponent: typeComponent
            }
          })
        )
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoggerPreview = (payload: any) =>
    dispatch(setLoggerPreview(payload));

  //// DND FUNCTION

  ////// PURE DND

  const handlerDragStart = () => {
    blurInput('DRAG_START');
    dispatch(mainContainerActionComposer());
    dispatch(reducerModifierIsDraggingActionComposer(true));
  };

  const handlerDragEnd = (result: any) => {
    dispatch(reducerModifierIsDraggingActionComposer(false));
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === 'dropMainContent' &&
      (source.droppableId === 'MenuDND_INPUT' ||
        source.droppableId === 'MenuDND_FORMULA' ||
        source.droppableId === 'MenuDND_TABLE' ||
        source.droppableId === 'MenuDND_TEXT' ||
        source.droppableId === 'MenuDND_DIVIDER' ||
        source.droppableId === 'MenuDND_IMAGE' ||
        source.droppableId === 'MenuDND_LINK' ||
        source.droppableId === 'MenuDND_FIELD')
    ) {
      if (source.droppableId === 'MenuDND_INPUT') {
        preventChanges
          ? dndChanges(
              'DND',
              idCalculation,
              'NUMERICAL_INPUT',
              destination.index
            )
          : dispatch(
              copyActionComposer(
                idCalculation,
                'NUMERICAL_INPUT',
                destination.index
              )
            );
      } else {
        preventChanges
          ? dndChanges('DND', idCalculation, draggableId, destination.index)
          : dispatch(
              copyActionComposer(idCalculation, draggableId, destination.index)
            );
      }
      return;
    }
    // prepare data for action
    let selection = 0;
    const components: Array<any> = [];
    let idDestination = '';
    const isMoveDown = source.index < destination.index;
    const sources: Array<any> = [];
    const pageIndexDestination =
      source.droppableId === destination.droppableId
        ? activePage
        : Number(destination.droppableId.split('-')[0]);

    dataBodyOfCalculationCloned[activePage].forEach((e: any, index: number) => {
      if (e.isActive) {
        selection += 1;
        const tempData = { ...e };
        delete tempData.isActive;
        components.push(tempData);
        sources.push({
          pageIndex: activePage,
          rowIndex: index
        });
      }

      if (index === destination.index) {
        idDestination = e.id;
      }
    });

    // reorder multiple components
    if (source.droppableId === destination.droppableId && selection > 1) {
      const paramsMultipleReorder = {
        idCalculation,
        components,
        idDestination,
        isMoveDown,
        actualDestinationIndex: destination.index,
        sources
      };
      dispatch(reorderMultipleActionComposer(paramsMultipleReorder));
    }
    // reorder one component
    if (source.droppableId === destination.droppableId && selection <= 1) {
      dispatch(
        reorderActionComposer(idCalculation, source.index, destination.index)
      );
    }
    // move to other page
    if (source.droppableId !== destination.droppableId) {
      const paramsMoveToOtherPage = {
        idCalculation,
        components,
        sources,
        sourceIndex: source.index,
        pageIndexDestination
      };
      dispatch(moveToOtherPage(paramsMoveToOtherPage));
    }
  };

  const onClickHandler = (event: any, id: string) => {
    if (!event) {
      return;
    }
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    // marking the event as used
    if (
      event.target.id === 'imageButton' ||
      event.target.id === 'imageNoImageTopIndicator' ||
      event.target.id.includes('imageinput') ||
      event.target.id.includes('url') ||
      event.target.id === 'linkUrl'
    ) {
      return;
    } else {
      event.preventDefault();
    }
    if (event.metaKey || event.ctrlKey) {
      toggleSelectionInGroup(id);
      return;
    }

    setActiveId(id);
    toggleSelection(id);
  };

  const toggleSelectionInGroup = (id: string) => {
    const tempData: Array<any> = [...dataBodyOfCalculationCloned];
    tempData[activePage] = tempData[activePage].map((e: any) => {
      if (e.id === id) {
        e.isActive = !e.isActive;
      }
      return e;
    });
    setDataBodyOfCalculationCloned(tempData);
  };

  const toggleSelection = (id: string) => {
    const tempData: Array<any> = [...dataBodyOfCalculationCloned];
    tempData[activePage] = tempData[activePage].map((e: any) => {
      if (e.id === id) {
        e.isActive = !e.isActive;
      } else {
        e.isActive = false;
      }
      return e;
    });
    setDataBodyOfCalculationCloned(tempData);
  };

  const totalActiveComponent = useMemo(() => {
    let total = 0;
    dataBodyOfCalculationCloned[activePage]?.forEach((e: any) => {
      if (e.isActive) {
        total += 1;
      }
    });
    return total;
  }, [JSON.stringify(dataBodyOfCalculationCloned)]);

  ////// ETC

  window.onbeforeunload = function () {
    if (queue.size !== 0) {
      return '';
    }
  };

  const disabledComponent = (type: string) => {
    return (
      getUserProfile() !== 'REKAVA_ADMIN' &&
      type === 'TABLE' &&
      subscription?.plan === 'FREE'
    );
  };

  const removeTableSubsFree = (type: string) => {
    return subscription?.plan === 'FREE' && type === 'TABLE';
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handlerClickAddComponent = (idCalculation: any, type: any) => {
    if (type === 'INPUT') {
      type = 'NUMERICAL_INPUT';
    }
    if (preventChanges) {
      dndChanges(
        'DND',
        idCalculation,
        type,
        dataBodyOfCalculation[activePage].length
      );
    } else {
      dispatch(
        copyActionComposer(
          idCalculation,
          type,
          dataBodyOfCalculation[activePage].length
        )
      );
    }
  };

  //// VARIABLE MANAGER FUNCTION

  useEffect(() => {
    if (dataBodyOfCalculation.length > 0) {
      if (!componentTableRef) {
        setTimeout(() => {
          setComponentTableRef(true);
          dispatch(
            patchDataVariableManagerMenuActionComposer(
              vlcRef.current,
              workpageTableRef.current
            )
          );
        }, 10);
      } else {
        dispatch(
          patchDataVariableManagerMenuActionComposer(
            vlcRef.current,
            workpageTableRef.current
          )
        );
      }
    }
  }, [JSON.stringify(dataBodyOfCalculation), activePage]);

  //// TABLE FUNCTION

  const handlerExpandTableOnChange = (payload: { [key: string]: any }) => {
    dispatch(patchDataExpandTableActionComposer(payload));
  };

  const handlerExpandTableToolbar = (typeAction: string, payload: any) => {
    dispatch(toolbarExpandTableActionComposer(typeAction, payload));
  };

  const handlerExpandTableAfterChanges = (
    worksheet: any,
    updatedCells: Array<any>
  ) => {
    dispatch(updateDataAfterCellChanges(worksheet, updatedCells));
  };

  const handlerExpandTableButtonTable = (typeAction: string) => {
    const payload = {
      idCalculation: idCalculation
    };
    dispatch(
      actionButtonExpandTableActionComposer(
        typeAction,
        payload,
        workpageTableRef.current,
        expandTableRef.current
      )
    );
    handlerExpandTable('close');
  };

  const handlerExpandTableCellSelection = (selection: any) => {
    if (selection.length === 1) {
      setSelectedCell(`${indexToChar(selection[0].x)}${selection[0].y + 1}`);
    } else {
      setSelectedCell(
        `${indexToChar(selection[0].x)}${selection[0].y + 1}:${indexToChar(
          selection[selection.length - 1].x
        )}${selection[selection.length - 1].y + 1}`
      );
    }
  };

  const handlerExpandTableContextMenu = (worksheet: any) => {
    const items = [];
    let foundBorder = false;

    if (expandTableRef.current.getBorder()?.active === 1) {
      foundBorder = true;
    }

    if (foundBorder) {
      items.push({
        title: 'Unshow to Logger',
        onclick: () => {
          dispatch(
            contextMenuExpandTableActionComposer(
              'CLEAR_SELECTION',
              {
                worksheet: worksheet
              },
              expandTableRef.current
            )
          );
        }
      });
    } else {
      items.push({
        title: 'Show to Logger',
        onclick: () => {
          dispatch(
            contextMenuExpandTableActionComposer(
              'SHOW_TO_LOGGER',
              {
                worksheet: worksheet
              },
              expandTableRef.current
            )
          );
        }
      });
    }

    items.push({ type: 'line' });

    items.push({
      title: 'Set as Header',
      onclick: () => {
        dispatch(
          contextMenuExpandTableActionComposer(
            'SET_AS_HEADER',
            {
              worksheet: worksheet
            },
            expandTableRef.current
          )
        );
      }
    });
    items.push({
      title: 'Set as Input to Logger',
      onclick: () => {
        dispatch(
          contextMenuExpandTableActionComposer(
            'SET_AS_INPUT_TO_LOGGER',
            {
              worksheet: worksheet
            },
            expandTableRef.current
          )
        );
      }
    });
    items.push({
      title: 'Set as Read Only',
      onclick: () => {
        dispatch(
          contextMenuExpandTableActionComposer(
            'SET_AS_READ_ONLY',
            {
              worksheet: worksheet
            },
            expandTableRef.current
          )
        );
      }
    });

    items.push({ type: 'line' });

    items.push({
      title: 'Insert 1 row',
      onclick: () => {
        dispatch(
          contextMenuExpandTableActionComposer(
            'ADD_ONE_ROW',
            {
              worksheet: worksheet
            },
            expandTableRef.current
          )
        );
      }
    });
    items.push({
      title: 'Insert 1 column',
      onclick: () => {
        dispatch(
          contextMenuExpandTableActionComposer(
            'ADD_ONE_COLUMN',
            {
              worksheet: worksheet
            },
            expandTableRef.current
          )
        );
      }
    });

    return items;
  };

  const handlerExpandTableColumnResize = (payload: { [key: string]: any }) => {
    dispatch(patchColumnSizeExpandTableActionComposer(payload));
  };

  //// OTHERS FUNCTION

  const blurInput = (event?: any) => {
    if (event?.target?.nodeName === 'DIV' && event.target.hasChildNodes()) {
      if (
        document.activeElement instanceof HTMLElement &&
        event?.target?.childNodes[0]?.tagName === 'DIV'
      )
        document.activeElement.blur();
    }
  };

  const resetFocus = () => {
    setCurrentFocus(null);
    setStartInputFocus(null);
    setEndInputFocus(null);
  };

  const handlerMainContainer = (e: React.SyntheticEvent) => {
    if (
      openPage.value === false &&
      openCurrency.value === false &&
      openText.value === false &&
      openImage.value === false &&
      openInput.value === false &&
      openField.value === false &&
      !currentFocus
    ) {
      dispatch(mainContainerActionComposer());
      if (!e.defaultPrevented) {
        const tempDataBodyCalculationCloned = JSON.parse(
          JSON.stringify(dataBodyOfCalculation)
        );
        setDataBodyOfCalculationCloned(tempDataBodyCalculationCloned);
      }
    }
  };

  const handlerMouseUp = (e: React.SyntheticEvent) => {
    if (e && currentFocus?.includes('formula')) {
      const target = document.getElementById(currentFocus) as HTMLInputElement;
      setStartInputFocus(target.selectionStart);
      setEndInputFocus(target.selectionEnd);
    }
  };

  const exitSwitch = (payload: string) => {
    switch (payload) {
      case 'COMPONENTS':
        dispatch(inputSwitchActionMenuComposer('exitComponents'));
        break;
      case 'VARIABLE_MANAGER':
        dispatch(inputSwitchActionMenuComposer('exitVariableManager'));
        break;
    }
  };

  useEffect(() => {
    const tempDataBodyCalculationCloned = JSON.parse(
      JSON.stringify(dataBodyOfCalculation)
    );
    for (const x in tempDataBodyCalculationCloned) {
      tempDataBodyCalculationCloned[x].forEach((component: any) => {
        if (component.type == 'TABLE') {
          setTimeout(() => {
            const { column, row } = component.freeze;
            if (workpageTableRef.current[component.id]) {
              workpageTableRef.current[component.id].setFreeze(row, column);
            }
          }, 1000);
        }
      });
    }
    setDataBodyOfCalculationCloned(tempDataBodyCalculationCloned);
  }, [JSON.stringify(dataBodyOfCalculation)]);

  //// EXPORT FUNCTION MIDDLE TO RIGHT

  useEffect(() => {
    if (exportEvent.value === true) {
      const obj = dataProperties;
      switch (exportEvent.type) {
        case 'TEXT_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('notationSmallProperties', obj.notation);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'FIELD':
          setValue('descriptionSmallProperties', obj.description);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'DROPDOWN_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('notationSmallProperties', obj.notation);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'RADIO_BUTTON_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('notationSmallProperties', obj.notation);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'NUMERICAL_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('notationSmallProperties', obj.notation);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          setValue('unitSmallProperties', obj.unit);
          setValue('hardMaxSmallProperties', obj.hardMax);
          setValue('hardMinSmallProperties', obj.hardMin);
          setValue('softMaxSmallProperties', obj.softMax);
          setValue('softMinSmallProperties', obj.softMin);
          break;
        case 'CURRENCY_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'DATE_INPUT':
          setValue('descriptionSmallProperties', obj.description);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'FORMULA':
          setValue('descriptionSmallProperties', obj.description);
          setValue('variableSmallProperties', obj.variable);
          setValue('notationSmallProperties', obj.notation);
          setValue('unitSmallProperties', obj.unit);
          setValue('formulaSmallProperties', obj.formula);
          setValue('referenceSmallProperties', obj.reference);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'TABLE':
          setValue('tableNameSmallProperties', obj.tableName);
          setValue('descriptionSmallProperties', obj.description);
          setValue('variableSmallProperties', obj.variable);
          setValue('tooltipBigProperties', obj.tooltip);
          setValue('notesBigProperties', obj.notes);
          break;
        case 'TEXT':
          setValue('textBigProperties', obj.description);
          break;
        case 'LINK':
          setValue('titleSmallProperties', obj.title);
          setValue('urlBigProperties', obj.url);
          break;
        case 'DIVIDER':
          // NOTHING TO DO HERE
          break;
        case 'IMAGE':
          setValue('captionBigProperties', obj.description);
          setValue('imageWidthProperties', obj.imageWidth);
          setValue('imageHeightProperties', obj.imageHeight);
          break;
        case 'RESET':
          setValue('descriptionSmallProperties', '');
          setValue('notationSmallProperties', '');
          setValue('variableSmallProperties', '');
          setValue('tooltipBigProperties', '');
          setValue('notesBigProperties', '');
          setValue('unitSmallProperties', '');
          setValue('hardMaxSmallProperties', '');
          setValue('hardMinSmallProperties', '');
          setValue('softMaxSmallProperties', '');
          setValue('softMinSmallProperties', '');
          break;
      }
      dispatch(
        reducerModifierExportEventActionComposer({
          value: false,
          type: null
        })
      );
    }
  }, [exportEvent]);

  // MIDDLE FUNCTION

  //// PAGE FUNCTION

  useEffect(() => {
    if (isEditing) {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [isEditing]);

  const handlerSwipe = (direction: string) => () => {
    if (headerRef.current !== null) {
      if (direction === 'left') {
        headerRef.current.scrollLeft -= 100;
      } else if (direction === 'right') {
        headerRef.current.scrollLeft += 100;
      } else {
        console.error('Wrong direction Input');
      }
    }
  };

  const handlerChangePage = (contextPage: any) => {
    if (contextPage === activePage) return;
    setComponentTableRef(false);
    dispatch(changePageActionComposer(contextPage, workpageTableRef.current));
  };

  const handlerAddPage = () => {
    dispatch(addPageActionComposer(idCalculation, 'Untitled'));
  };

  const handlerShowToLoggerPage = (typeAction: string) => {
    switch (typeAction) {
      case 'HIDE':
        dispatch(
          formModalSetupActionPopUp(
            'CONFIRMATION_SHOWTOLOGGER_PAGE_FROM_COMPOSER',
            {
              idCalculation
            }
          )
        );
        setOpenPage({
          value: false,
          anchorPosition: null
        });
        break;
      case 'UNHIDE':
        dispatch(unhidePageActionComposer(idCalculation));
        setOpenPage({
          value: false,
          anchorPosition: null
        });
        break;
    }
  };

  const handlerRenamePage = (
    typeAction: string,
    event: React.SyntheticEvent
  ) => {
    switch (typeAction) {
      case 'CLICK':
        dispatch(reducerModifierIsEditingActionComposer(true));
        setOpenPage({
          value: false,
          anchorPosition: null
        });
        break;
      case 'ONBLUR':
        dispatch(reducerModifierIsEditingActionComposer(false));
        dispatch(
          renamePageActionComposer(
            idCalculation,
            (event.target as HTMLInputElement).value
          )
        );
        break;
    }
  };

  const handlerDeletePage = (typeAction: string) => {
    switch (typeAction) {
      case 'CLICK':
        dispatch(
          formModalSetupActionPopUp('CONFIRMATION_DELETE_PAGE_FROM_COMPOSER', {
            pageName: dataPages[activePage]?.name
          })
        );
        setOpenPage({
          value: false,
          anchorPosition: null
        });
        break;
    }
  };

  //// HEAD FUNCTION

  const handlerPopUpModal = () => {
    dispatch(formModalSetupActionPopUp('EDIT_CALCULATION_FROM_COMPOSER'));
  };

  //// BODY FUNCTION

  ////// MODAL

  //////// EXPAND TABLE

  const handlerExpandTable = (typeAction: string) => {
    if (typeAction === 'open') {
      setExpandTable({
        value: true
      });
    } else {
      setExpandTable({
        value: false
      });
    }
  };

  ////// MENU

  //////// PAGE

  const handlerShowPageMenu = (
    typeAction: string,
    event?: any,
    indexPage?: any
  ) => {
    if (typeAction === 'open') {
      dispatch(reducerModifierActivePageActionComposer(indexPage));
      setOpenPage({
        value: true,
        anchorPosition: event.target.parentElement
      });
    } else {
      setOpenPage({
        value: false,
        anchorPosition: null
      });
    }
  };

  //////// MENU SELECT AT PROPERTIES

  const handlerShowCurrencyComponent = (typeAction: string, event?: any) => {
    if (typeAction === 'open') {
      setOpenCurrency({
        value: true,
        anchorPosition: event.currentTarget
      });
    } else {
      setOpenCurrency({
        value: false,
        anchorPosition: null
      });
    }
  };

  const handlerShowTextComponent = (typeAction: string, event?: any) => {
    if (typeAction === 'open') {
      setOpenText({
        value: true,
        anchorPosition: event.currentTarget
      });
    } else {
      setOpenText({
        value: false,
        anchorPosition: null
      });
    }
  };

  const handlerShowImageComponent = (typeAction: string, event?: any) => {
    if (typeAction === 'open') {
      setOpenImage({
        value: true,
        anchorPosition: event.currentTarget
      });
    } else {
      setOpenImage({
        value: false,
        anchorPosition: null
      });
    }
  };

  const handlerShowInputComponent = (typeAction: string, event?: any) => {
    if (typeAction === 'open') {
      setOpenInput({
        value: true,
        anchorPosition: event.currentTarget
      });
    } else {
      setOpenInput({
        value: false,
        anchorPosition: null
      });
    }
  };

  const handlerShowFieldComponent = (typeAction: string, event?: any) => {
    if (typeAction === 'open') {
      setOpenField({
        value: true,
        anchorPosition: event.currentTarget
      });
    } else {
      setOpenField({
        value: false,
        anchorPosition: null
      });
    }
  };

  ////// COMPONENT FUNCTION

  //////// THREE DOTS

  const handlerComponent = async (
    typeHandler: string,
    idComponent: string,
    indexComponent?: number,
    typeComponent?: string
  ) => {
    const multipleSelectType: string[] = [];
    const multipleSelectIndex: number[] = [];
    const multipleSelectId: string[] = [];
    dataBodyOfCalculationCloned[activePage].forEach(
      (element: { [key: string]: any }, index: number) => {
        if (element.isActive) {
          multipleSelectIndex.push(index);
          multipleSelectType.push(element.type);
          multipleSelectId.push(element.id);
        }
      }
    );
    const multipleIndexComponent: number[] = dataBodyOfCalculationCloned[
      activePage
    ]
      .map((item: any, idx: number) => {
        return {
          indexComponent: idx,
          isActive: item.isActive
        };
      })
      ?.filter((el: any) => el.isActive)
      ?.map((a: any) => a.indexComponent);

    const multipleTypeComponent: string[] = dataBodyOfCalculationCloned[
      activePage
    ]
      .map((item: any) => {
        return {
          typeComponent: item.type,
          isActive: item.isActive
        };
      })
      ?.filter((el: any) => el.isActive)
      ?.map((a: any) => a.typeComponent);

    const multipleIdComponent: string[] = dataBodyOfCalculationCloned[
      activePage
    ]
      .map((item: any) => {
        return {
          idComponent: item.id,
          isActive: item.isActive
        };
      })
      ?.filter((el: any) => el.isActive)
      ?.map((a: any) => a.idComponent);

    switch (typeHandler) {
      case 'COPY':
        setTimeout(() => {
          dispatch(
            twoMenuHandlerActionComposer(
              idCalculation,
              typeHandler,
              multipleIdComponent.length > 1
                ? multipleIdComponent
                : idComponent,
              multipleIndexComponent.length > 1
                ? multipleIndexComponent
                : indexComponent,
              multipleTypeComponent.length > 1
                ? multipleTypeComponent
                : typeComponent,
              workpageTableRef.current,
              setValue
            )
          );
        }, 10);
        break;
      case 'DELETE':
        setTimeout(() => {
          dispatch(
            twoMenuHandlerActionComposer(
              idCalculation,
              typeHandler,
              multipleSelectId.length > 0 ? multipleSelectId : idComponent,
              multipleSelectIndex.length > 0
                ? multipleSelectIndex
                : indexComponent,
              multipleSelectType.length > 0
                ? multipleSelectType
                : typeComponent,
              workpageTableRef.current,
              setValue
            )
          );
        }, 10);
        break;
      case 'SWITCH_INPUT':
        handlerShowInputComponent('close');
        if (preventChanges) {
          inputChanges(
            'CHANGE_INPUT',
            idCalculation,
            idComponent,
            indexComponent,
            typeComponent
          );
        } else {
          dispatch(
            changeInputActionComposer(
              idCalculation,
              idComponent,
              indexComponent,
              typeComponent
            )
          );
        }
        break;
      case 'SWITCH_FIELD':
        handlerShowFieldComponent('close');
        setActiveId(idComponent);
        dispatch(
          changeFieldActionComposer(
            idCalculation,
            idComponent,
            indexComponent,
            typeComponent
          )
        );
        break;
    }
  };

  //////// INPUT TOOLBAR HANDLER

  const handleSelectedToolbarMenu = (
    e: React.SyntheticEvent,
    properties: any,
    type: any,
    data: any
  ) => {
    switch (type) {
      case 'decimal':
        handlerComponentNumericalInput('ONCHANGE_DECIMAL', properties, e, data);
        break;
      case 'currency':
        handlerComponentNumericalInput(
          'ONCHANGE_CURRENCY',
          properties,
          e,
          data
        );
        break;
      case 'percentage':
        handlerComponentNumericalInput(
          'ONCHANGE_PERCENTAGE',
          properties,
          e,
          data
        );
        break;
      default:
        break;
    }
  };

  const handleSelectedFieldToolbarMenu = (
    e: React.SyntheticEvent,
    properties: any,
    type: any,
    data: any
  ) => {
    switch (type) {
      case 'decimal':
        handlerComponentField('ONCHANGE_DECIMAL', properties, e, data);
        break;
      case 'currency':
        handlerComponentField('ONCHANGE_CURRENCY', properties, e, data);
        break;
      case 'percentage':
        handlerComponentField('ONCHANGE_PERCENTAGE', properties, e, data);
        break;
      default:
        break;
    }
  };

  //////// MAIN COMPONENT FUNCTION

  const handlerGlobalComponent = (
    type: string,
    payload: { [key: string]: any }
  ) => {
    switch (type) {
      case 'FOCUS_WORKPAGES_COMPONENT':
        setCurrentFocus(payload.focusId);
        switch (payload.focusId.split('_')[0]) {
          case 'notation':
            dispatch(inputSwitchActionMenuComposer('showSymbol'));
            break;
          case 'formula':
            dispatch(inputSwitchActionMenuComposer('showVariableManager'));
            break;
        }
        break;
    }
  };

  const handlerComponentTextInput = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction: any = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        // prevent blur on FormattedInput component
        // Because formatted input is a HTML Div Element (not Input nor Textarea)
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(textInputActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
      case 'ONBLUR_NOTATION':
        payloadAction = {
          ...payloadAction,
          notation: data?.replace(/\n/g, '')
        };
        resetFocus();
        dispatch(
          textInputActionComposer(typeAction, payloadAction, setValue, [
            'notationSmallProperties',
            'variableSmallProperties'
          ])
        );
        break;
      case 'ONBLUR_INPUT_VALUE':
        payloadAction = {
          ...payloadAction,
          inputValue: event.target.value
        };
        dispatch(textInputActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentDropdownInput = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction: any = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        payloadAction = {
          ...payloadAction,
          description: data?.replace(/\n/g, '')
        };
        dispatch(dropdownInputActionComposer(typeAction, payloadAction));
        setValue('descriptionSmallProperties', payloadAction.description);
        break;
      case 'ONBLUR_NOTATION':
        payloadAction = {
          ...payloadAction,
          notation: data?.replace(/\n/g, '')
        };
        resetFocus();
        dispatch(
          dropdownInputActionComposer(typeAction, payloadAction, setValue, [
            'notationSmallProperties',
            'variableSmallProperties'
          ])
        );
        break;
      case 'SELECT_OPTION':
        payloadAction = {
          ...payloadAction,
          inputValue: data
        };
        dispatch(dropdownInputActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentRadioButtonInput = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction: any = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(radioButtonInputActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
      case 'ONBLUR_NOTATION':
        payloadAction = {
          ...payloadAction,
          notation: data?.replace(/\n/g, '')
        };
        resetFocus();
        dispatch(
          radioButtonInputActionComposer(typeAction, payloadAction, setValue, [
            'notationSmallProperties',
            'variableSmallProperties'
          ])
        );
        break;
      case 'ADD_OPTION':
        dispatch(radioButtonInputActionComposer(typeAction, payloadAction));
        break;
      case 'REMOVE_OPTIONS':
        dispatch(radioButtonInputActionComposer(typeAction, payloadAction));
        break;
      case 'SELECT_RADIO':
        payloadAction = {
          ...payloadAction,
          inputValue: data
        };
        dispatch(radioButtonInputActionComposer(typeAction, payloadAction));
        break;
      case 'ONBLUR_OPTIONS':
        payloadAction = {
          ...payloadAction,
          option: data?.replace(/\n/g, '')
        };
        dispatch(radioButtonInputActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentNumericalInput = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: any
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(numericalInputActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
      case 'ONBLUR_NOTATION':
        payloadAction = {
          ...payloadAction,
          notation: data?.replace(/\n/g, '')
        };
        resetFocus();
        dispatch(
          numericalInputActionComposer(typeAction, payloadAction, setValue, [
            'notationSmallProperties',
            'variableSmallProperties'
          ])
        );
        break;
      case 'ONBLUR_UNIT':
        if (data) {
          payloadAction = {
            ...payloadAction,
            unit: data.replace(/\n/g, '')
          };
          dispatch(numericalInputActionComposer(typeAction, payloadAction));
          setValue('unitSmallProperties', payloadAction.unit);
        }
        break;
      case 'ONBLUR_INPUT_VALUE':
        payloadAction = {
          ...payloadAction,
          inputValue: event.target.value
        };
        dispatch(numericalInputActionComposer(typeAction, payloadAction));
        break;
      case 'ONCHANGE_CURRENCY':
        payloadAction = {
          ...payloadAction,
          unit:
            mapCurrencyToHtmlEntities[
              data as keyof typeof mapCurrencyToHtmlEntities
            ] ?? ''
        };
        dispatch(numericalInputActionComposer('ONBLUR_UNIT', payloadAction));
        setValue('unitSmallProperties', payloadAction?.unit);
        break;
      case 'ONCHANGE_DECIMAL':
        payloadAction = {
          ...payloadAction,
          format: {
            value: { decimal: data }
          }
        };
        dispatch(numericalInputActionComposer(typeAction, payloadAction));
        break;
      case 'ONCHANGE_PERCENTAGE':
        payloadAction = {
          ...payloadAction,
          format: {
            value: { percentage: data }
          }
        };
        dispatch(numericalInputActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentField = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: any
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };

    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(fieldActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
      case 'ONBLUR_FIELD_VALUE':
        payloadAction = {
          ...payloadAction,
          inputValue: event.target.value
        };
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'ONCHANGE_PERCENTAGE':
        payloadAction = {
          ...payloadAction,
          format: {
            value: { percentage: data }
          }
        };
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'ONCHANGE_CURRENCY':
        payloadAction = {
          ...payloadAction,
          unit:
            mapCurrencyToHtmlEntities[
              data as keyof typeof mapCurrencyToHtmlEntities
            ] ?? ''
        };
        dispatch(fieldActionComposer('ONBLUR_UNIT', payloadAction));
        setValue('unitSmallProperties', payloadAction?.unit);
        break;
      case 'ADD_OPTION':
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'REMOVE_OPTIONS':
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'SELECT_RADIO':
        payloadAction = {
          ...payloadAction,
          inputValue: data
        };
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'ONBLUR_OPTIONS':
        payloadAction = {
          ...payloadAction,
          option: data?.replace(/\n/g, '')
        };
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
      case 'SELECT_OPTION':
        payloadAction = {
          ...payloadAction,
          inputValue: data
        };
        dispatch(fieldActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentDateInput = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(dateInputActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
    }
  };

  // const handlerComponentCurrencyInput = async (typeAction: string, payload?:any, event?: any, data?: any) => {
  //   let payloadAction = {
  //     ...payload,
  //     idCalculation: idCalculation,
  //   }
  //   switch (typeAction) {
  //     case 'SHOW_PROPERTIES':
  //       event.stopPropagation()
  //       blurInput(event)
  //       if (dataProperties.idComponent !== payload.idComponent) {
  //         dispatch(exportPropertiesActionComposer(payloadAction))
  //       }
  //     break;
  //     case 'ONBLUR_DESCRIPTION':
  //       payloadAction = {
  //         ...payloadAction,
  //         description: event.target.innerText
  //       }
  //       dispatch(currencyInputActionComposer(typeAction, payloadAction))
  //       setValue('descriptionSmallProperties', event.target.innerText)
  //     break;
  //     case 'SELECT_CURRENCY':
  //       dispatch(currencyInputActionComposer(typeAction, payloadAction))
  //       handlerShowCurrencyComponent('close')
  //     break;
  //     case 'ONBLUR_INPUT_VALUE':
  //       payloadAction = {
  //         ...payloadAction,
  //         inputValue: event.target.value
  //       }
  //       dispatch(textInputActionComposer(typeAction, payloadAction))
  //     break;
  //   }
  // }

  const handlerComponentFormula = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: any
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        // prevent blur on FormattedInput component
        // Because formatted input is a HTML Div Element (not Input nor Textarea)
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (payload.idComponent !== dataProperties.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(formulaActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', payloadAction.description);
        }
        break;
      case 'ONBLUR_NOTATION':
        payloadAction = {
          ...payloadAction,
          notation: data?.replace(/\n/g, '')
        };
        resetFocus();
        dispatch(
          formulaActionComposer(typeAction, payloadAction, setValue, [
            'notationSmallProperties',
            'variableSmallProperties'
          ])
        );
        break;
      case 'ONBLUR_UNIT':
        if (data) {
          payloadAction = {
            ...payloadAction,
            unit: data.replace(/\n/g, '')
          };
          dispatch(formulaActionComposer(typeAction, payloadAction));
          setValue('unitSmallProperties', payloadAction.unit);
        }
        break;
      case 'ONBLUR_FORMULA':
        payloadAction = {
          ...payloadAction,
          formula: event.target.value,
          workpageTableRef: workpageTableRef.current
        };
        resetFocus();
        dispatch(formulaActionComposer(typeAction, payloadAction));
        setValue('formulaSmallProperties', event.target.value);
        break;
    }
  };

  const handlerComponentTable = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: any
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);

    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        // prevent blur on FormattedInput component
        // Because formatted input is a HTML Div Element (not Input nor Textarea)
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (payload.idComponent !== dataProperties.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          dispatch(tableActionComposer(typeAction, payloadAction));
          setValue('descriptionSmallProperties', data);
        }
        break;
      case 'EXPAND_TABLE':
        setTimeout(() => {
          handlerExpandTable('open');
          setValue('searchVariableManagerTable', '');

          expandTableRef.current.setDefinedNames(
            vlcRef.current.getVariableList()
          );
          expandTableRef.current.setData(
            dataBodyOfCalculation[activePage][payloadAction.indexComponent]
              .tableBody
          );
          // dispatch(patchDataVariableManagerTableActionComposer());
        }, 10);
        break;
    }
  };

  const handlerComponentText = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        payloadAction = {
          ...payloadAction,
          description: data?.replace(/\n/g, '')
        };
        dispatch(textActionComposer(typeAction, payloadAction));
        setValue('textBigProperties', payloadAction.description);
        break;
      case 'SELECT_LEVEL':
        handlerShowTextComponent('close');
        dispatch(textActionComposer(typeAction, payloadAction));
        break;
    }
  };

  const handlerComponentLink = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: string
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case SHOW_PROPERTIES:
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case ONBLUR_TITLE:
        payloadAction = {
          ...payloadAction,
          title: data ? data.replace(/\n/g, '') : event.target.value
        };
        dispatch(LinkActionComposer(typeAction, payloadAction));
        setValue('titleSmallProperties', payloadAction.title);
        break;
      case ONBLUR_URL:
        payloadAction = {
          ...payloadAction,
          url: data ? data.replace(/\n/g, '') : event.target.value
        };
        dispatch(LinkActionComposer(typeAction, payloadAction));
        setValue('urlBigProperties', payloadAction.url);
        break;
    }
  };

  const handlerComponentDivider = async (
    typeAction: string,
    payload?: any,
    event?: any
  ) => {
    const payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
    }
  };

  const handlerComponentImage = async (
    typeAction: string,
    payload?: any,
    event?: any,
    data?: any
  ) => {
    let payloadAction = {
      ...payload,
      idCalculation: idCalculation
    };
    onClickHandler(event, payload?.idComponent);
    switch (typeAction) {
      case 'SHOW_PROPERTIES':
        event.stopPropagation();
        blurInput(event);
        if (event.metaKey || event.ctrlKey) {
          dispatch(mainContainerActionComposer());
          return;
        }
        if (dataProperties.idComponent !== payload.idComponent) {
          dispatch(exportPropertiesActionComposer(payloadAction));
        }
        break;
      case 'ONBLUR_DESCRIPTION':
        if (data) {
          payloadAction = {
            ...payloadAction,
            description: data.replace(/\n/g, '')
          };
          setValue('captionBigProperties', payloadAction.description);
          dispatch(imageActionComposer(typeAction, payloadAction));
        }
        break;
      case 'DELETE_IMAGE':
        dispatch(imageActionComposer(typeAction, payloadAction));
        break;
      case 'UPLOAD_IMAGE':
        payloadAction = {
          ...payloadAction,
          image: event.target.files[0]
        };
        dispatch(imageActionComposer(typeAction, payloadAction));
        break;
      case 'SELECT_ALIGNMENT':
        handlerShowImageComponent('close');
        dispatch(imageActionComposer(typeAction, payloadAction));
        break;
    }
  };

  // PROPERTIES FUNCTION
  const handlerImportProperties = async (
    typeAction: string,
    event?: any,
    payload?: any,
    data?: string
  ) => {
    let typeAPI: any;
    let targetValue = '';
    const ignoreTypeAction: string[] = ['IMAGE_LOCK'];

    if (!ignoreTypeAction.includes(typeAction)) {
      targetValue = Boolean(event.target.nodeName === 'TEXTAREA')
        ? event.target.value
        : event.target.nodeName === 'DIV' &&
          event.target.className.includes('DraftEditor')
        ? data?.replace(/\n/g, '')
        : event.target.nodeName === 'DIV' &&
          !event.target.className.includes('DraftEditor')
        ? event.target.innerHTML
        : event.target.innerText;
    }

    switch (dataProperties.propertiesType) {
      case 'TEXT_INPUT':
        typeAPI = 'text-input';
        break;
      case 'DROPDOWN_INPUT':
        typeAPI = 'dropdown-input';
        break;
      case 'RADIO_BUTTON_INPUT':
        typeAPI = 'radio-button-input';
        break;
      case 'NUMERICAL_INPUT':
        typeAPI = 'numerical';
        break;
      case 'DATE_INPUT':
        typeAPI = 'date-input';
        break;
      case 'CURRENCY_INPUT':
        typeAPI = 'currency-input';
        break;
      case 'FORMULA':
        typeAPI = 'formula';
        break;
      case 'TABLE':
        typeAPI = 'table';
        break;
      case 'TEXT':
        typeAPI = 'text';
        break;
      case 'IMAGE':
        typeAPI = 'static-image';
        break;
      case 'FIELD':
        typeAPI = 'field';
        break;
    }
    switch (typeAction) {
      case 'ONBLUR_DESCRIPTION':
        dispatch(
          importPropertiesActionComposer(typeAction, targetValue, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        setValue(`description_${dataProperties.idComponent}`, targetValue);
        break;
      case 'ONBLUR_NOTATION':
        resetFocus();
        dispatch(
          importPropertiesActionComposer(
            typeAction,
            targetValue,
            {
              typeAPI: typeAPI,
              idCalculation: idCalculation
            },
            setValue
          )
        );
        break;
      case 'ONBLUR_VARIABLE':
        const variable =
          event.target.value && !checkVariablePrefix(event.target.value)
            ? 'var_' + event.target.value
            : event.target.value;
        dispatch(
          importPropertiesActionComposer(
            typeAction,
            variable,
            {
              typeAPI: typeAPI,
              idCalculation: idCalculation
            },
            setValue
          )
        );
        setValue(`variableSmallProperties`, variable);
        break;
      case 'ONBLUR_OPTIONS':
        if (event.target.value !== '') {
          dispatch(
            importPropertiesActionComposer(typeAction, event.target.value, {
              typeAPI: typeAPI,
              idCalculation: idCalculation
            })
          );
        }
        setValue('optionRegisterInputProperties', '');
        break;
      case 'REMOVE_OPTIONS':
        dispatch(
          importPropertiesActionComposer(typeAction, payload, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_TOOLTIP':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_NOTES':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_UNIT':
        dispatch(
          importPropertiesActionComposer(typeAction, targetValue, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        setValue(`unit_${dataProperties.idComponent}`, targetValue);
        break;
      case 'ONBLUR_HARDMAX':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_HARDMIN':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_SOFTMIN':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_SOFTMAX':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_FORMULA':
        resetFocus();
        dispatch(
          importPropertiesActionComposer(
            typeAction,
            event.target.value,
            {
              typeAPI: typeAPI,
              idCalculation: idCalculation
            },
            null,
            workpageTableRef.current
          )
        );
        setValue(`formula_${dataProperties.idComponent}`, event.target.value);
        break;
      case 'ONBLUR_REFERENCE':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case FORMULA_DISPLAY:
        dispatch(
          importPropertiesActionComposer(typeAction, payload, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'FORMULA_DISPLAY_ON':
        dispatch(
          importPropertiesActionComposer(typeAction, '', {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'FORMULA_DISPLAY_OFF':
        dispatch(
          importPropertiesActionComposer(typeAction, '', {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_WIDTH':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_HEIGHT':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'IMAGE_LOCK':
        dispatch(importPropertiesActionComposer(typeAction));
        break;
      case 'SUMMARY_CHECK':
        dispatch(
          importPropertiesActionComposer(typeAction, '', {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'TABLE_LOGGER_CHECK':
        dispatch(
          importPropertiesActionComposer(typeAction, '', {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_COL':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'ONBLUR_ROW':
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case 'TOGGLE_ADD_ROW':
        dispatch(
          importPropertiesActionComposer(typeAction, payload, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        break;
      case ONBLUR_URL:
        dispatch(
          importPropertiesActionComposer(typeAction, targetValue, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        setValue(`url_${dataProperties.idComponent}`, targetValue);
        setValue('urlBigProperties', targetValue);
        break;
      case ONBLUR_TITLE:
        dispatch(
          importPropertiesActionComposer(typeAction, event.target.value, {
            typeAPI: typeAPI,
            idCalculation: idCalculation
          })
        );
        setValue(`title_${dataProperties.idComponent}`, event.target.value);
        setValue('titleSmallProperties', event.target.value);
        break;

      default:
        console.log(`type action : ${typeAction} not exist`);
    }
  };

  // RENDER

  //// MODAL RENDER

  ////// EXPAND TABLE

  //// MENU RENDER

  //// 3 SECTIONS RENDER

  // EXPAND SUB HEADER
  const [expandSubHeader, setExpandSubHeader] = useState<{
    [key: string]: boolean;
  }>({
    TEXT_INPUT_PROPERTIES: true,
    TEXT_INPUT_INPUT: true,
    DROPDOWN_INPUT_INPUT: true,
    DROPDOWN_INPUT_PROPERTIES: true,
    RADIO_BUTTON_INPUT_INPUT: true,
    RADIO_BUTTON_INPUT_PROPERTIES: true,
    NUMERICAL_INPUT_INPUT: true,
    NUMERICAL_INPUT_PROPERTIES: true,
    DATE_INPUT_INPUT: true,
    DATE_INPUT_PROPERTIES: true,
    CURRENCY_INPUT_INPUT: true,
    CURRENCY_INPUT_PROPERTIES: true,
    FORMULA_PROPERTIES: true,
    TABLE_PROPERTIES: true,
    TEXT_PROPERTIES: true,
    IMAGE_PROPERTIES: true,
    FORMULA_FORMULA: true,
    LINK_PROPERTIES: true,
    FIELD_FIELD: true,
    TEXT_FIELD_PROPERTIES: true,
    NUMERICAL_FIELD_FIELD: true,
    FIELD_PROPERTIES: true
  });

  const toggleExpandSubHeader = (key: string) => {
    setExpandSubHeader((prev: any) => {
      const cloneObj = {
        ...prev
      };
      cloneObj[key] = !cloneObj[key];
      return cloneObj;
    });
  };

  useEffect(() => {
    setSocketUrl(
      `${APIKEY}/event/folder/${folderIdCalculation}?token=${token}`
    );

    handleClickSendSocket({
      calculationId: idCalculation,
      eventType: 'CALCULATION_OPEN'
    });
    return () => {
      getWebSocket()?.close();
    };
  }, [folderIdCalculation]);

  const handleClickSendSocket = (props: any) => {
    sendMessage(JSON.stringify(props));
  };

  // COMPONENT CALL
  return (
    <DragDropContext onDragEnd={handlerDragEnd} onDragStart={handlerDragStart}>
      <Container onMouseUp={handlerMouseUp} onClick={handlerMainContainer}>
        <ComposerLeft
          dataSwitch={dataSwitch}
          exitSwitch={exitSwitch}
          disabledComponent={disabledComponent}
          handlerClickAddComponent={handlerClickAddComponent}
          idCalculation={idCalculation}
          dataVariableManagerMenu={dataVariableManagerMenu}
          currentFocus={currentFocus}
          startInputFocus={startInputFocus}
          endInputFocus={endInputFocus}
          register={register}
          setValue={setValue}
          getValues={getValues}
          notationInputRef={notationInputRef}
          removeTableSubsFree={removeTableSubsFree}
        />
        <ComposerMiddle
          handlerSwipe={handlerSwipe}
          headerRef={headerRef}
          dataPages={dataPages}
          activePage={activePage}
          handlerChangePage={handlerChangePage}
          isDragging={isDragging}
          isEditing={isEditing}
          inputRef={inputRef}
          handlerRenamePage={handlerRenamePage}
          handlerShowPageMenu={handlerShowPageMenu}
          handlerAddPage={handlerAddPage}
          dataHeadOfCalculation={dataHeadOfCalculation}
          handlerPopUpModal={handlerPopUpModal}
          dataBodyOfCalculationCloned={dataBodyOfCalculationCloned}
          setDataBodyOfCalculationCloned={setDataBodyOfCalculationCloned}
          preventChanges={preventChanges}
          threeDotChanges={threeDotChanges}
          handlerComponent={handlerComponent}
          onClickHandler={onClickHandler}
          totalActiveComponent={totalActiveComponent}
          dataProperties={dataProperties}
          handlerComponentTextInput={handlerComponentTextInput}
          register={register}
          handlerGlobalComponent={handlerGlobalComponent}
          handlerComponentDropdownInput={handlerComponentDropdownInput}
          handlerComponentRadioButtonInput={handlerComponentRadioButtonInput}
          handlerComponentNumericalInput={handlerComponentNumericalInput}
          handlerComponentField={handlerComponentField}
          handleSelectedToolbarMenu={handleSelectedToolbarMenu}
          handleSelectedFieldToolbarMenu={handleSelectedFieldToolbarMenu}
          handlerComponentDateInput={handlerComponentDateInput}
          handlerComponentFormula={handlerComponentFormula}
          handlerComponentTable={handlerComponentTable}
          workpageTableRef={workpageTableRef}
          handlerComponentText={handlerComponentText}
          handlerComponentLink={handlerComponentLink}
          handlerComponentDivider={handlerComponentDivider}
          handlerComponentImage={handlerComponentImage}
          imageRef={imageRef}
          expandTable={expandTable}
          selectedCell={selectedCell}
          expandTableRef={expandTableRef}
          expandTableContainerRef={expandTableContainerRef}
          handlerExpandTableContextMenu={handlerExpandTableContextMenu}
          handlerExpandTableAfterChanges={handlerExpandTableAfterChanges}
          handlerExpandTableOnChange={handlerExpandTableOnChange}
          handlerExpandTableToolbar={handlerExpandTableToolbar}
          handlerExpandTableCellSelection={handlerExpandTableCellSelection}
          handlerExpandTableColumnResize={handlerExpandTableColumnResize}
          setValue={setValue}
          getValues={getValues}
          handlerExpandTableButtonTable={handlerExpandTableButtonTable}
          openPage={openPage}
          handlerDeletePage={handlerDeletePage}
          openText={openText}
          handlerShowTextComponent={handlerShowTextComponent}
          openImage={openImage}
          handlerShowImageComponent={handlerShowImageComponent}
          openInput={openInput}
          handlerShowInputComponent={handlerShowInputComponent}
          openField={openField}
          handlerShowFieldComponent={handlerShowFieldComponent}
          handleOpenNewTab={handleOpenNewTab}
          notationInputRef={notationInputRef}
          componentTableRef={componentTableRef}
          handlerShowToLoggerPage={handlerShowToLoggerPage}
        />
        <ComposerRight
          dataProperties={dataProperties}
          toggleExpandSubHeader={toggleExpandSubHeader}
          expandSubHeader={expandSubHeader}
          handlerShowCurrencyComponent={handlerShowCurrencyComponent}
          handlerShowTextComponent={handlerShowTextComponent}
          handlerShowImageComponent={handlerShowImageComponent}
          handlerShowInputComponent={handlerShowInputComponent}
          handlerShowFieldComponent={handlerShowFieldComponent}
          register={register}
          handlerImportProperties={handlerImportProperties}
          control={control}
          handlerGlobalComponent={handlerGlobalComponent}
          dataBodyOfCalculationCloned={dataBodyOfCalculationCloned}
          activePage={activePage}
          activeId={activeId}
        />
        <VariableSheet ref={vlcRef} variableList={[]} name="formula" />
      </Container>
      {dataBodyOfCalculation.length > 0 && (
        <LoggerPreview
          data={
            dataBodyOfCalculation[0]
              ? dataBodyOfCalculation[0].slice(0, 20)
              : []
          }
          info={dataHeadOfCalculation}
          setLoggerPreview={handleLoggerPreview}
          loggerPreviewImage={loggerPreviewImage}
        />
      )}
      <HiddenTable
        activePage={activePage}
        dataBodyOfCalculationCloned={dataBodyOfCalculationCloned}
        workpageTableRef={workpageTableRef}
      />
      <HelperButton />
    </DragDropContext>
  );
};
