import axios from "axios";

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

ax.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem("app__token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";

    return config;
  },
  (error: any) => Promise.reject(error)
);

export default ax;
