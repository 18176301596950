import {
  LOADING_FOLDER,
  FAILED_FOLDER,
  GET_DATA_FOLDER,
  FailedActionI,
  LoadingActionI,
  GetDataFolderI,
} from '../types/folderTypes';

interface initialStateI {
  isLoading: boolean;
  message: string;
  data: any;//{}[];
}

const initialState: initialStateI = {
  isLoading: false,
  message: '',
  data: [],
};

type Action = LoadingActionI | FailedActionI | GetDataFolderI;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case LOADING_FOLDER:
      return { ...state, isLoading: action.isLoading };
    case FAILED_FOLDER:
      return { ...state, message: action.message, isLoading: action.isLoading };
    case GET_DATA_FOLDER:
      return { ...state, data: action.data };
    default:
      return state;
  }
}
