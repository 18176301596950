import {
  DATASWITCHTYPEMENUCOMPOSER,
} from './composerMenuTypes';

export function reducerModifierDataSwitchActionMenuComposer(data: boolean) {
  return { type: DATASWITCHTYPEMENUCOMPOSER, data };
}

export function inputSwitchActionMenuComposer(payload: any) {
  return (dispatch: any, getState: any) => {
    let dataSwitch = getState().composerMenu.dataSwitch;
    switch (payload) {
      case 'exitComponents':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            components: false
          }));
        }, 50);
        break;
      case 'exitVariableManager':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            variableManager: false,
          }));
        }, 50);
        break;
      case 'exitSymbol':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            symbol: false,
          }));
        }, 50);
        break;
      case 'showVariableManager':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            variableNetwork: false,
            variableManager: true,
            symbol: false,
            comments: false,
          }));
        }, 50);
        break;
      case 'showSymbol':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            variableNetwork: false,
            variableManager: false,
            symbol: true,
            comments: false,
          }));
        }, 50);
        break;
      case 'components':
        setTimeout(() => {
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            components: dataSwitch.components ? false : true
          }));
        }, 50);
        break;
      case 'variableNetwork':
        setTimeout(() => {
          if (dataSwitch.variableManager || dataSwitch.symbol || dataSwitch.comments) {
            dispatch(reducerModifierDataSwitchActionMenuComposer({
              ...dataSwitch,
              variableManager: false,
              symbol: false,
              comments: false,
            }));
          }
          dataSwitch = getState().composerMenu.dataSwitch;
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            variableNetwork: dataSwitch.variableNetwork ? false : true,
          }));
        }, 50);
        break;
      case 'variableManager':
        setTimeout(() => {
          if (dataSwitch.variableNetwork || dataSwitch.symbol || dataSwitch.comments) {
            dispatch(reducerModifierDataSwitchActionMenuComposer({
              ...dataSwitch,
              variableNetwork: false,
              symbol: false,
              comments: false,
            }));
          }
          dataSwitch = getState().composerMenu.dataSwitch;
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            variableManager: dataSwitch.variableManager ? false : true,
          }));
        }, 50);
        break;
      case 'symbol':
        setTimeout(() => {
          if (dataSwitch.variableNetwork || dataSwitch.variableManager || dataSwitch.comments) {
            dispatch(reducerModifierDataSwitchActionMenuComposer({
              ...dataSwitch,
              variableNetwork: false,
              variableManager: false,
              comments: false,
            }));
          }
          dataSwitch = getState().composerMenu.dataSwitch;
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            symbol: dataSwitch.symbol ? false : true,
          }));
        }, 50);
        break;
      case 'comments':
        setTimeout(() => {
          if (dataSwitch.variableNetwork || dataSwitch.variableManager || dataSwitch.symbol) {
            dispatch(reducerModifierDataSwitchActionMenuComposer({
              ...dataSwitch,
              variableNetwork: false,
              variableManager: false,
              symbol: false,
            }));
          }
          dataSwitch = getState().composerMenu.dataSwitch;
          dispatch(reducerModifierDataSwitchActionMenuComposer({
            ...dataSwitch,
            comments: dataSwitch.comments ? false : true,
          }));
        }, 50);
        break;
      case 'reset':
        dispatch(reducerModifierDataSwitchActionMenuComposer({
          ...dataSwitch,
          components: true,
          variableNetwork: false,
          variableManager: false,
          symbol: false,
          comments: false
        }));
        break;
      // default:
      //   console.log(payload);
      // break;
    }
  };
}