import React, { useState, useEffect, useCallback } from "react";
import EmblaCarouselReact from "embla-carousel-react";
import { DoneButton, NextButton, PrevButton, } from "./CarouselButton";
import "./carousel.css";
import { CarouselFooter, CarouselWrapper } from "../elements";
import { ArticleCount } from "../../../infoPanel/elements";
import HTMLContent from "../../../HTMLContent";

const EmblaCarouselComponent = ({ children, onDone }: any) => {


  const [carousel, initCarousel] = useState<any>(null);
  const [prevBtnEnabled, togglePrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, toggleNextBtnEnabled] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(1);


  const scrollPrev = useCallback(() => carousel.scrollPrev(), [carousel]);
  const scrollNext = useCallback(() => carousel.scrollNext(), [carousel]);

  useEffect(() => {
    const onSelect = () => {
      togglePrevBtnEnabled(carousel.canScrollPrev());
      toggleNextBtnEnabled(carousel.canScrollNext());
    };
    if (carousel) {
      carousel.on("select", onSelect);
      onSelect();
    }
    return () => carousel && carousel.destroy();
  }, [carousel]);

  const next = () => {
    setIndex(index + 1);
    scrollNext();
  };

  const prev = () => {
    setIndex(index - 1);
    scrollPrev();
  };

  return (
    <CarouselWrapper>
      <div className="carousel__wrap">
        <EmblaCarouselReact
          className="carousel__viewport"
          emblaRef={initCarousel}
          options={{ loop: false, draggable: false }}
          htmlTagName="div"
        >
          <div className="carousel__container">
            {children.map((Child: any, index: number) => {
              return (
                <div className="carousel__item" key={index}>
                  {Child}
                  <HTMLContent text={Child.props.description}/>
                </div>
              );
            })}
          </div>
        </EmblaCarouselReact>
        <CarouselFooter>
          <div className="footerCount">
            <ArticleCount>{index} OF {children.length}</ArticleCount>
          </div>
          <div className="footerButton">
            <PrevButton onClick={prev} enabled={prevBtnEnabled} />
            {nextBtnEnabled ? <NextButton onClick={next} enabled={nextBtnEnabled} /> : <DoneButton onDone={onDone} />}

          </div>
        </CarouselFooter>

      </div>
    </CarouselWrapper>
  );

};

export default EmblaCarouselComponent;