import styled from "styled-components";

export const Wrapper = styled.div({
  position: "relative",
  boxSizing: "border-box",
  transition: "all 0.2s ease-in-out",
  borderRadius: 8,
  outline: "none",
  border: '1px solid #E0E6EB'
});

export const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: '10px 12px',
  color: "#353C68",
  fontWeight: "normal",
  fontSize: 12
});

export const ItemContainer = styled.ul({
  backgroundColor: "#FFF",
  position: "absolute",
  padding: 0,
  margin: 0,
  width: "100%",
  border: '1px solid #E0E6EB',
  zIndex: 9,
  borderRadius: 8,
  boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
});

export const Item = styled.li({
  listStyleType: "none",
  cursor: "pointer",
  filter: "brightness(2)",
  padding: "1rem 1.2rem",

  "&:first-of-type": {
    "> button": {
      borderTop: "1px solid #ccc",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    }
  },

  "&:last-of-type": {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  }
});