import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import HeaderUserDasboard from '../../components/molecules/HeaderUserDashboard/HeaderUserDasboard';
import SideBar from '../../components/molecules/sidebar';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import {
  AccountSettingWrapper,
  CalculationContent
} from '../../components/organisms/hoc/elements';
import Text from '../../components/atoms/Text';
import Pill from '../../components/atoms/Pill';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store';
import { handleDisabled } from '../../utils/helpersFunction';
import SubscriptionFooter from './SubscriptionFooter';
import SubscriptionBody from './SubscriptionBody';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { errorSubscription } from '../../utils/genericHandler';
import { BUSINESS, ENTERPRISE, FREE, PRO } from '../../utils/constant';
import { getUserDetails } from '../../utils/storage';
import { useQuery } from 'react-query';
import { AppConfigService } from '../../service/AppConfigService';

const SubscriptionPage = (props: any) => {
  const { isMinimize, handleInActiveFocus } = props;

  const dispatch = useDispatch();
  const [hoverEl, setHoverEl] = useState<string>('');

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const userProfile = getUserDetails();
  const { subscription } = userProfile;
  const { plan } = subscription ?? { plan: '' };

  const handleUpgradeAccount = (upgradeAcc: string) => {
    try {
      if (upgradeAcc !== 'FREE') {
        errorSubscription(new Error('Not supported yet'));
      } else {
        dispatch(toastSetupActionPopUp('ERROR', "Can't downgrade for now."));
      }
    } catch (error: any) {
      errorSubscription(error);
    }
  };

  const subscriptionAction = [
    {
      type: 'For individuals',
      name: 'Basic',
      text: 'Utilizing Rekava core features',
      title: [
        {
          title: '0',
          textDecoration: false,
          variant: 'h1',
          secondTitle: 'IDR per user month'
        }
      ],
      disabled: handleDisabled(plan, FREE),
      currentPlan: FREE,
      subText: 'What’s included',
      included: ['Calculation templates', '3 calculation'],
      colorBorder: '#D6DFEB',
      buttonText: plan === FREE ? 'Current Plan' : 'Select Plan'
    },
    {
      type: 'For individuals',
      name: 'Pro',
      pill: (
        <Pill
          text="Recommended"
          role="RECOMMENDED"
          style={{ height: 20, padding: '7px 9px', borderRadius: 10 }}
        />
      ),
      text: 'Get unlimited access to Rekava Pro tools',
      title: [
        {
          title: '149,000',
          variant: 'h1',
          secondTitle: 'IDR per user per month'
        }
      ],
      disabled: handleDisabled(plan, PRO),
      currentPlan: PRO,
      subText: 'Everything in Basic, plus',
      included: ['Table component', 'Advance component'],
      colorBorder: '#D6DFEB',
      buttonText: plan === PRO ? 'Current Plan' : 'Select Plan'
    },
    {
      type: 'For business',
      name: 'Business',
      text: 'Collaborate with your entier team in one place',
      title: [
        {
          title: '349,000',
          textDecoration: false,
          variant: 'h1',
          secondTitle: 'IDR per user per month'
        }
      ],
      disabled: handleDisabled(plan, BUSINESS),
      currentPlan: BUSINESS,
      subText: 'Everything in Pro, plus',
      included: ['Collaborative workspace', 'Sharing permissions'],
      colorBorder: '#D6DFEB',
      buttonText: plan === BUSINESS ? 'Current Plan' : 'Select Plan'
    },
    {
      type: 'For teams',
      name: 'Enterprise',
      text: 'Limits and assistance in running business',
      title: [
        {
          title: 'Contact Us',
          textDecoration: false,
          variant: 'h1',
          secondTitle: ''
        }
      ],
      disabled: handleDisabled(plan, ENTERPRISE),
      currentPlan: ENTERPRISE,
      subText: 'What’s included',
      included: [
        'Enterprise integration',
        'Dedicated support',
        'Custom features'
      ],
      colorBorder: '#D6DFEB',
      buttonText: 'Contact Us'
    }
  ];

  // subscription related
  const { data, isLoading } = useQuery('users', AppConfigService.get);

  useEffect(() => {
    if (!isLoading && data) {
      localStorage.setItem('appConfig', JSON.stringify(data));
    }
  }, [data]);

  return (
    <>
      <CalculationContent>
        <SideBar isMinimize={isMinimize} view={false} />
        <Divider orientation="vertical" flexItem />
        <AccountSettingWrapper onClick={handleInActiveFocus}>
          <HeaderUserDasboard />
          <Divider style={{ width: '100%' }} />
          <div style={{ padding: '24px 24px 16px 24px', height: '100%' }}>
            <Text
              variant="h2"
              style={{ paddingBottom: 16, textAlign: 'center' }}
            >
              Your subscription is on the {subscription?.pretty} Plan. Upgrade
              to collaborate with more people using Rekava.
            </Text>
            <div
              style={{ display: 'flex', gap: '1rem', paddingBottom: '32px' }}
            >
              {subscriptionAction.map((item: any, index: number) => (
                <SubscriptionBody
                  key={`subscription_body_${index}`}
                  item={item}
                  subscription={subscription}
                  handleUpgradeAccount={handleUpgradeAccount}
                  hoverEl={hoverEl}
                  setHoverEl={setHoverEl}
                />
              ))}
            </div>
            <SubscriptionFooter
              subscription={subscription}
              userLimit={userLimit}
            />
          </div>
        </AccountSettingWrapper>
      </CalculationContent>
    </>
  );
};

export default calculationsNav(SubscriptionPage);
