import api from "./api";

const URL = "config";
export interface IAppConfig {
  trialEndDate: Date;
  rekavaAdminWA: string;
  rekavaWAMessage: string;
  defaultCalculationLimit: number;
}

export const AppConfigService = {
  get: async (): Promise<IAppConfig> => {
    const response = await api.get(`${URL}`);
    const {
      trialEndDate = "1970-01-01",
      rekavaAdminWA = "",
      rekavaWAMessage = "",
      defaultCalculationLimit = 3,
    } = response.data;
    return {
      trialEndDate: new Date(trialEndDate),
      rekavaAdminWA,
      rekavaWAMessage,
      defaultCalculationLimit,
    };
  },
};