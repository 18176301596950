import Text from '../../components/atoms/Text';
import { HelpYellow } from '../../assets/icons/HelpYellow';
import { Box, Button } from '@mui/material';
import { errorSubscription } from '../../utils/genericHandler';

interface SubscriptionFooterNoteI {
  plan: string;
}

export const SubscriptionFooterNote = ({ plan }: SubscriptionFooterNoteI) => {
  const handleSubscription = () => {
    errorSubscription();
  };

  const planUpdate = (plan: string) => {
    switch(plan) {
      case 'Business':
        return 'Business Plus';
        break;
      case 'Pro':
        return 'Pro Plus';
        break;
      default:
        return '';
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box
        style={{
          display: 'inline'
        }}
      >
        <Text style={{ paddingRight: 8 }}>
          You have reach limit calculation files per drive and collaborators.
          Get unlimited with {planUpdate(plan)}.
          <Button
            color="primary"
            onClick={handleSubscription}
            sx={{ padding: 1 }}
          >
            <Text
              color="primary"
              textTransform="capitalize"
              onClick={handleSubscription}
              fontWeight="bold"
            >
              Upgrade now
            </Text>
          </Button>
        </Text>
      </Box>
      {/* <HelpYellow onClick={handleSubscription} /> */}
    </Box>
  );
};
