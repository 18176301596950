import React from 'react'
import { ChatItemI } from './ChatItem'
import { ChatWrapper } from '../styles'
import images from '../../../assets'
import HTMLContent from '../../../components/molecules/HTMLContent'
import { getUserDetails } from '../../../utils/storage'

export const UserQuestion = ({ question }: Partial<ChatItemI>) => {
	return (
		<ChatWrapper isAce={false}>
			<div id="chatbox">
				<img
					src={getUserDetails().profilePictureUrl ?? images.avatar}
					id="avatar"
				/>
				<div id="message">
					<HTMLContent
						text={(question as string).replace(/\n/g, '<br />')}
					/>
				</div>
			</div>
		</ChatWrapper>
	)
}
