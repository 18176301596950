import {
  CalculationName,
  LeftContent,
  List,
  MainContent,
  NavBarStyle,
  RightContent,
  UserAvatar,
} from './elements';
import Loading, { LoadingSpinner } from '../../../components/atoms/loading';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { clearStorage, getUserType } from '../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BreadcrumbsV2 } from '../Breadcrumbs';
import { ComposerMenu } from '../composer/composerMenu';
import { Menu, useMediaQuery, useTheme } from '@mui/material';
import NewLoggerMenu from '../NewLoggerMenu';
import PrintViewMenu from '../PrintViewMenu';
import { ReduxState } from '../../../store';
import TagManagerService from '../../../service/tagManagerService';
import Text from '../../atoms/Text';
import { calLastChanged } from '../../../utils/dateConvert';
import { editCalcInfo } from '../../../store/actions/calculationsActions';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import images from '../../../assets';
import { menuStyle } from '../../../utils/theme';
import { reducerModifierDataHeadOfCalculationActionComposer } from '../../../store/composer/composerPage/composerActions';
import { superPageBreadCrumbData } from '../../../utils/constant';
import { uploadLoggerPreview } from '../../../store/appState/global/globalActions';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import Icon from '../../atoms/Icon';
import { setSelectedTreeItem } from '../../../store/appState/sidebar/action';

interface NavBarProps {
  userRole: string;
  currentPict?: string;
}

export default function NavBar({ userRole, currentPict }: NavBarProps) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = !useMediaQuery('(min-width:1080px)');
  const isAceUser = /ACE/.test(getUserType());
  const checkNotChecker =
    /REKAVA_ADMIN|COMPOSER_ADMIN|COMPOSER|PRINCIPAL|LOGGER|REKAVA/.test(
      String(userRole)
    );
  const { subscription } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );
  const { isLoadingSubmit, submitMessage, loggerPreview } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );
  const { currentReport, reportSaveFormat, reportName } = useSelector(
    (state: ReduxState) => state.reportManager,
    shallowEqual
  );

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { currentInputFile, savingInputFile } = useSelector(
    (state: ReduxState) => state.logger,
    shallowEqual
  );

  const { dataHeadOfCalculation } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  const handleChange = (e: any) => {
    e.preventDefault();
    setMenuHeader(e.target.value);
  };

  const handleBlur = () => {
    setEditingCalculationName(false);
  };

  const [statusSaved, setStatusSaved] = useState<any>('');
  const [menuHeader, setMenuHeader] = useState<string>('');
  const [editingCalculationName, setEditingCalculationName] =
    useState<boolean>(false);
  const [loadingUploadPreview, setLoadingUploadPreview] =
    useState<boolean>(false);

  const calculationNameInputRef =
    useRef() as MutableRefObject<HTMLInputElement>;

  const tagManagerService = TagManagerService();

  useEffect(() => {
    if (pathname.includes('/logger')) {
      if (currentInputFile?.updatedAt == null) {
        setStatusSaved('');
      } else if (isLoadingSubmit == false) {
        setStatusSaved(calLastChanged(currentInputFile?.updatedAt));
      }
    } else if (pathname.includes('/print-view')) {
      if (currentReport?.updatedAt == null) {
        setStatusSaved('');
      } else if (isLoadingSubmit == false) {
        setStatusSaved(calLastChanged(currentReport?.updatedAt));
      }
    }
  }, [currentInputFile, savingInputFile, currentReport]);

  useEffect(() => {
    setStatusSaved(submitMessage);
    setMenuHeader(dataHeadOfCalculation.title);
  }, [submitMessage, dataHeadOfCalculation]);

  useEffect(() => {
    if (editingCalculationName) {
      calculationNameInputRef.current.focus();
    }
  }, [editingCalculationName]);

  const submitFromEnter = async (e: any) => {
    const KEY_CODE_ENTER = 13;
    const KEY_CODE_ESC = 27;

    if (e.keyCode === KEY_CODE_ENTER) {
      e.preventDefault();
      e.stopPropagation();
      submitForm();
      setEditingCalculationName(false);
    } else if (e.keyCode === KEY_CODE_ESC) {
      setEditingCalculationName(false);
      setMenuHeader(dataHeadOfCalculation.title);
    }
  };

  const submitForm = async () => {
    try {
      dispatch(
        editCalcInfo(dataHeadOfCalculation.idCalculation, {
          title: menuHeader ? menuHeader : dataHeadOfCalculation.title,
          description: dataHeadOfCalculation.description
        })
      );
      dispatch(
        reducerModifierDataHeadOfCalculationActionComposer({
          ...dataHeadOfCalculation,
          title: menuHeader ? menuHeader : dataHeadOfCalculation.title,
          description: dataHeadOfCalculation.description
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const [contextMenu, setContextMenu] = useState<any | HTMLElement>(null);

  const handleLogout = () => {
    tagManagerService.logout();
    clearStorage();
    window.location.href = '/';
  };

  const toAccountSettings = () => {
    dispatch(setSelectedTreeItem("accountSettings"))
    handleCloseContextMenu()
    history.push('/calculations/accountSettings');
  }

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: e.clientX + 45,
          mouseY: e.clientY + 15
        }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleSaveReport = async (
    calculationId: string,
    inputFileId: string,
    type: string,
    isDriveCalculation: boolean
  ) => {
    try {
      dispatch(
        formModalSetupActionPopUp('SAVE_REPORT_PRINT_VIEW_BREADCRUMB', {
          currentReport,
          title: reportName,
          notes: '',
          isActive: true,
          format: {
            pages: reportSaveFormat.pages,
            summary: reportSaveFormat.summary,
            latex: reportSaveFormat.latex
          },
          calculationId,
          inputFileId,
          history,
          type,
          subscription,
          userLimit,
          isDriveCalculation
        })
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const toDashboard = async () => {
    try {
      if (isAceUser) return;
      if (window.location.pathname.split('/').reverse()[2] === 'composer') {
        setLoadingUploadPreview(true);
        Promise.all([
          dispatch(
            uploadLoggerPreview(
              window.location.pathname.split('/').reverse()[1],
              loggerPreview
            )
          )
        ])
          .then(() => {
            history.push('/dashboard');
          })
          .catch(error => console.error(error))
          .finally(() => setLoadingUploadPreview(false));
      } else {
        history.push('/dashboard');
      }
    } catch (error: any) { }
  };

  const leftContent = () => (
    <>
      <LeftContent>
        <div className="imageInsideLeftContent">
          <img
            src={images.logoIcon}
            width={32}
            alt="icon"
            onClick={toDashboard}
            style={{ cursor: isAceUser ? 'auto' : 'pointer' }}
          />
        </div>
        {pathname.includes('/calculation') ||
          pathname.includes('/dashboard') ||
          pathname.includes('/ace') ||
          pathname.includes('/report') ||
          pathname.includes('/input-file') ? (
          <div className="EmptyContentInsideLeftContent">
            <img
              src={images.logoText}
              width={85}
              onClick={() => {
                isAceUser ? undefined : history.push('/dashboard');
              }}
              alt="name"
              style={{ cursor: isAceUser ? 'auto' : 'pointer' }}
            />
          </div>
        ) : (
          <div className="contentInsideLeftContent">
            {pathname.includes('/composer') && (
              <>
                <CalculationName>
                  {editingCalculationName ? (
                    <form onBlur={submitForm} onKeyDown={submitFromEnter}>
                      <input
                        ref={calculationNameInputRef}
                        type="text"
                        value={menuHeader}
                        maxLength={60}
                        onChange={e => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </form>
                  ) : (
                    <p onClick={() => setEditingCalculationName(true)}>
                      {menuHeader}
                    </p>
                  )}
                </CalculationName>
                <ComposerMenu />
              </>
            )}
            {pathname.includes('/logger') && checkNotChecker && (
              <>
                <img
                  src={images.logoText}
                  width={85}
                  onClick={() => {
                    history.push('/dashboard');
                  }}
                  style={{ cursor: 'pointer' }}
                  alt="name"
                />
                <NewLoggerMenu />
              </>
            )}
            {pathname.includes('/print-view') && checkNotChecker && (
              <>
                <img
                  src={images.logoText}
                  width={85}
                  onClick={() => {
                    history.push('/dashboard');
                  }}
                  alt="name"
                  style={{ cursor: 'pointer' }}
                />
                <PrintViewMenu />
              </>
            )}
          </div>
        )}
      </LeftContent>
    </>
  );

  const renderMenuList = () => (
    <Menu
      open={Boolean(contextMenu)}
      PaperProps={{
        style: {
          marginLeft: 5,
        },
        elevation: 0,
        sx: menuStyle
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleCloseContextMenu}
    >
      <List data-testid="btnLogout" onClick={toAccountSettings} style={{ padding: "10px 16px" }}>
        <Icon icon="user" size={24} styles={{ marginRight: 8 }} color={theme.customText.icon_high} />
        <Text variant='body2'>Account Settings</Text>
      </List>
      <List data-testid="btnLogout" onClick={handleLogout} style={{ padding: "10px 16px" }}>
        <Icon icon="logOut" size={24} styles={{ marginRight: 8 }} color={theme.customText.icon_high} />
        <Text variant='body2'>Logout</Text>
      </List>
    </Menu>
  );

  const mainContent = () => (
    <>
      {pathname.includes('/composer') ? (
        <MainContent>
          <BreadcrumbsV2
            type="circleator"
            dataSource={superPageBreadCrumbData}
            handleSaveReport={handleSaveReport}
          />
        </MainContent>
      ) : pathname.includes('/logger') ? (
        <MainContent>
          {savingInputFile && (
            <>
              <Loading />
              <p style={{ marginLeft: '0.5rem' }}>Saving input file ...</p>
            </>
          )}
          <BreadcrumbsV2
            type="circleator"
            dataSource={superPageBreadCrumbData}
            handleSaveReport={handleSaveReport}
          />
        </MainContent>
      ) : pathname.includes('/print-view') ? (
        <MainContent>
          <BreadcrumbsV2
            type="circleator"
            dataSource={superPageBreadCrumbData}
            handleSaveReport={handleSaveReport}
          />
        </MainContent>
      ) : null}
    </>
  );

  const rightContent = () =>
  (
    <RightContent>
      <UserAvatar>
        <img src={!!currentPict ? currentPict : images.avatar} alt="avatar" />
        <div data-testid="profileIcon" onClick={e => handleContextMenu(e)}>
          {Boolean(contextMenu) ? <Icon icon="arrowUp" size={22} styles={{ marginLeft: 3, paddingRight: 3 }} /> : <Icon icon="arrowDown" size={22} />}
        </div>
      </UserAvatar>
      {renderMenuList()}
    </RightContent>
  );

  return (
    <>
      {loadingUploadPreview && <LoadingSpinner />}
      <NavBarStyle>
        {leftContent()}
        {mainContent()}
        {rightContent()}
      </NavBarStyle>
    </>
  );
}
