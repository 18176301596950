import React, { useState } from 'react';
import { Menu } from '@mui/material';
import { useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Icon from '../../../atoms/Icon';
import Text from '../../../atoms/Text';
import { SelectMenuBox, SelectMenuList, SelectWrapper } from './styles';

import { SelectPropsI } from './type';

const Select = ({ payload, dataSelect, ...divProps }: SelectPropsI) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function menuHandler(action: string, event?: React.MouseEvent<HTMLElement>) {
    switch (action) {
      case 'close':
        setIsOpen(false);
        setAnchorEl(null);
        break;
      case 'open':
        setIsOpen(true);
        setAnchorEl(event ? event.currentTarget : null);
        break;
    }
  }

  function selectData(event: React.SyntheticEvent, data: string) {
    dataSelect(event, data);
    menuHandler('close');
  }

  const componentRender = () => {
    return (
      <SelectWrapper
        onClick={(e: React.MouseEvent<HTMLElement>) => menuHandler('open', e)}
        typeInput={payload.typeInput}
      >
        {payload.inputValue ? (
          <Text variant="body3">{payload.inputValue}</Text>
        ) : (
          <Text variant="body3" color={theme.customText.disable}>
            Select Option
          </Text>
        )}
        <Icon icon="chevronDown" />
      </SelectWrapper>
    );
  };

  const menuRender = () => {
    return (
      <Menu
        open={isOpen}
        onClose={() => menuHandler('close')}
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
            marginTop: 0.25
          }
        }}
      >
        <SelectMenuBox>
          {payload.options?.map((el: string) => (
            <SelectMenuList
              onClick={(event: React.SyntheticEvent) => selectData(event, el)}
              key={uuidv4()}
            >
              {el}
            </SelectMenuList>
          ))}
        </SelectMenuBox>
      </Menu>
    );
  };

  return (
    <React.Fragment>
      {componentRender()}
      {menuRender()}
    </React.Fragment>
  );
};

export default Select;
