import { Autocomplete, Select } from "@mui/material";

import styled from "styled-components";
import { withStyles } from "@mui/styles";

export const NoPaddingAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: '0px 7px'
    },
    borderRadius: "6px",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "18px",
    fontWeight: 400,
    paddingLeft: 0,
    height: '40px',
    letterSpacing: "0.4px",
    fontSize: "12px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #118CFF",
      // color: "red",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E0E6EB",
      // borderColor: "#",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E0E6EB",
    },
  },

  input: {},
})(Autocomplete);

export const RenderOption = styled.li({
  fontSize: "12px",
  color: "#353C68",
  '&:hover': {
    backgroundColor: '#E3F3FF'
  }

});


export const ErrorInputText = styled.div({
  marginTop: "4px",
  marginBottom: "16px",
  width: "100%",
  color: "#DE294C",
  fontSize: "12px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
});