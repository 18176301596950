import { TextFieldProps } from "@mui/material";
import { IconWrapper, InputWrapper, TextIcon } from "./styles";

type IconTextPropsI = {
    iconStart?: React.ReactNode,
    iconEnd?: React.ReactNode,
} & TextFieldProps;

export const IconTextField = ({iconStart, iconEnd, ...props}: IconTextPropsI) => {
    return (
        <InputWrapper>
            <TextIcon
                {...props}
                InputProps={{
                    startAdornment: iconStart ? (
                        <IconWrapper position="start">
                            {iconStart}
                        </IconWrapper>
                    ) : null,
                    endAdornment: iconEnd ? (
                        <IconWrapper position="end">
                            {iconEnd}
                        </IconWrapper>
                    ) : null
                }}
            />
        </InputWrapper>
    );
};