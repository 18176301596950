import * as React from "react";

export const Dislike = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11.333 8.667l-2.666 6a2 2 0 01-2-2V10H2.893A1.333 1.333 0 011.56 8.467l.92-6a1.333 1.333 0 011.333-1.134h7.52m0 7.334V1.333m0 7.334h1.78a1.54 1.54 0 001.554-1.334V2.667a1.54 1.54 0 00-1.554-1.334h-1.78"
    ></path>
  </svg>
);
