import { createStore, combineReducers, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";

import thunk from "redux-thunk";

import langReducer from "./reducers/langReducer";
import loginReducer from "./reducers/loginReducer";
import calcMenuReducer from "./reducers/calcMenuReducer";
import encalReducer from "./reducers/encalReducer";
import calInfoReducer from "./reducers/calInfoReducer";
import calculationsReducer from "./reducers/calculationsReducer";
import toastReducer from "./reducers/toastReducer";
import folderReducer from "./reducers/folderReducer";
import composerReducer from "./composer/composerPage/composerReducer";
import composerMenuReducer from "./composer/composerMenu/composerMenuReducer";
import loggerReducer from "./reducers/loggerReducer";
import inputFileReducer from "./reducers/inputFileReducer";
import globalReducer from "./appState/global/globalReducer";
import formModalPopUpReducer from "./appState/popUp/formModal/formModalReducer";
import toastPopUpReducer from "./appState/popUp/toast/toastReducer";
import reportReducer from "./reducers/reportReducer";
import enterpriseReducer from "./reducers/enterpriseReducer";
import userReducer from "./reducers/userReducer";
import sidebarReducer from "./appState/sidebar/reducer";
import ComposerChangesReducer from "./composer/composerChanges/composerChangesReducer";
import circleatorReducer from "./breadCrumb/circleator/circleatorReducer";
import faqReducer from "./reducers/faqReducer";
import breadcrumbReducer from "./breadCrumb/managerNavigation/reducer";
import utilReducer from "./appState/utils/reducer";
import newLoggerReducer from "./reducers/logger.reducer";
import aceReducer from "./reducers/aceReducers"

const rootReducer = combineReducers({
  lang: langReducer,
  login: loginReducer,
  calcMenu: calcMenuReducer,
  encal: encalReducer,
  calInfo: calInfoReducer,
  calculations: calculationsReducer,
  inputFileManager: inputFileReducer,
  reportManager: reportReducer,
  managerNavigation: breadcrumbReducer,
  toast: toastReducer,
  folders: folderReducer,
  composer: composerReducer,
  composerMenu: composerMenuReducer,
  logger: loggerReducer,
  global: globalReducer,
  formModalPopUp: formModalPopUpReducer,
  sidebar: sidebarReducer,
  toastPopUp: toastPopUpReducer,
  enterprise: enterpriseReducer,
  users: userReducer,
  composerChanges: ComposerChangesReducer,
  circleator: circleatorReducer,
  faq: faqReducer,
  utils: utilReducer,
  // simple logger reducer
  loggerReducer: newLoggerReducer,
  aceReducer: aceReducer
});

const history = createBrowserHistory();

const reactRouterMiddleware = routerMiddleware(history);
const middlewares = [reactRouterMiddleware, thunk];
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export type AppDispatch = typeof store.dispatch;

export type ReduxState = ReturnType<typeof rootReducer>;

export default store;
