import React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useDispatch } from "react-redux";
import { writeToast } from "../../../store/actions/toastAction";

interface PropsModalI {
  status: boolean;
  color: string;
  message: string;
}

export default function ToastInfo ({ status, color, message }: PropsModalI) {
  const dispatch = useDispatch();

  function openCloseHandler () {
    dispatch(writeToast(false, '', ''));
  }

  return (
    <Snackbar
      open={status}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={openCloseHandler}
    >
      <SnackbarContent style={{
          backgroundColor: color,
          marginTop: '110px'
        }}
        message={<span style={{
          fontFamily: 'Inter',
          fontSize: '12px',
          letterSpacing: '0.4px'
        }}>{message}</span>}
      />
    </Snackbar>
  );
}
