import { HyperFormula } from 'hyperformula';
import { useState } from 'react';

export default function Home() {
  // const dispatch = useDispatch();
  // const { language } = useSelector(
  //   (state: ReduxState) => state.lang,
  //   shallowEqual
  // );
  const [valueFormula, setValueFormula] = useState({
    val1: 0,
    val2: 0,
    formula: '=Panjang+Lebar',
  });
  const [showResult, setShowResult] = useState(false);
  const variableList = [
    { name: 'Panjang', cell: 'A1' },
    { name: 'Lebar', cell: 'A2' },
    { name: 'Tinggi', cell: 'A3' },
  ];
  const varManager = variableList.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.cell }),
    {}
  );

  let formula = valueFormula.formula.replace('Panjang', 'A1');
  formula = formula.replace('Lebar', 'A2');
  // console.log(formula);
  // console.log(varManager);

  const formulaResult = () => {
    const data = [
      // COLUMN A (0)
      [valueFormula.val1], // ROW 0
      [valueFormula.val2], // ROW 1
      [formula], // ROW 2
    ];
    const instanceF = HyperFormula.buildFromArray(data, {
      licenseKey: 'gpl-v3',
    });
    // instanceF.addNamedExpression(
    //   'Result',
    //   `=${formula}(${valueFormula.val1}, ${valueFormula.val2})`
    // );
    return instanceF.getCellValue({ col: 0, row: 2, sheet: 0 });
  };

  const handleOnChange = (e: any, params: string) => {
    setValueFormula({
      ...valueFormula,
      [params]: e.target.value,
    });
  };

  const renderContent = () => (
    <div
      style={{
        display: 'flex',
        paddingLeft: '2rem',
        height: '500px',
        alignItems: 'center',
        gap: '10rem',
      }}>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
        <p>Tambah</p>
        <p>: HF.ADD</p>
        <p>Bagi</p>
        <p>: HF.DIVIDE</p>
        <p>Kurang</p>
        <p>: HF.MINUS</p>
        <p>Kali</p>
        <p>: HF.MULTIPLY</p>
        <p>Sum</p>
        <p>: SUM</p>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '2rem',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <button onClick={() => setShowResult((prevState) => !prevState)}>
          Calculate
        </button>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}>
            <input
              value={valueFormula.val1}
              onChange={(e) => handleOnChange(e, 'val1')}
              placeholder="value1"
              type="number"
            />
            <input
              value={valueFormula.val2}
              onChange={(e) => handleOnChange(e, 'val2')}
              placeholder="value1"
              type="number"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}>
            <input
              value={valueFormula.formula}
              onChange={(e) => handleOnChange(e, 'formula')}
              placeholder="formula"
            />
            Result: {showResult && formulaResult()}
          </div>
        </div>
      </div>
    </div>
  );

  return <>{renderContent()}</>;
}
