import { Box, Button } from '@mui/material';
import TypographyComponent from '../../components/atoms/Typography';
import {
  buttonStyle,
  boxContainerStyle
} from '../../pageElements/accountManager';
import Icon from '../../components/atoms/Icon';
import { useHistory } from 'react-router-dom';
import { handleAddBillingMember } from '../../utils/helpersFunction';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function ContentHeaderSection(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const goBack = () => {
    history.push(`/calculations/accountManager`);
  };

  return (
    <Box sx={boxContainerStyle}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
        <Icon icon={'arrowLeft'} size={25} onClick={() => goBack()} />
        <TypographyComponent variant="HEADING_1">
          Billing Details
        </TypographyComponent>
      </div>
      <Button
        sx={buttonStyle}
        variant="contained"
        onClick={() => {
          handleAddBillingMember({ dispatch, id });
        }}
      >
        Add Member
      </Button>
    </Box>
  );
}
