/**
 * store data to reducer
 * so it will be available to topbar menu
 * @params ListInputFiles: list for input files
 * @params calculationInfo: the information regarding current calculation info
 * @params formFields: the information regarding input file values, values changes, error, etc
 */

import {
  CalculationFileInfo,
  CalculationInfoProps,
  FormChangeDecimalI,
  FormFieldProps
} from './logger.types';
import {
  changeDecimalInputFile,
  saveInputFile,
  saveInputFileAndPrint,
  storeCalculationInfo,
  storeInputFile,
  storeListInputFiles,
  togglePrintButton
} from '../../store/actions/logger.actions';
import {
  calculateFormula,
  checkModifiedFormValue,
  generateTableName,
  getFilteredPage,
  getDecimalState,
  handleUserInput
} from './utils';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CalculationPanel from './components/CalculationPanel';
import CalculationService from '../../service/CalculationServices';
import InputFileService from '../../service/InputFileService';
import { LoadingSpinner } from '../../components/atoms/loading';
import LogProgressPanel from './components/LogProgressPanel';
import { LoggerForm } from './components/Form';
import { PageContainer } from './styles';
import PageHeader from './components/PageHeader';
import PageNavigation from './components/PageNavigation';
import ReportService from '../../service/ReportServices';
import SidePanel from './components/SidePanel';
import Workpages from './components/Workpages';
import { changePositionActionCircleatorBreadcrumb } from '../../store/breadCrumb/circleator/circleatorAction';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { localDatetime } from '../../utils/dateConvert';
import { useAppDispatch } from '../../hooks/hooks';
import { getInputFileValueLogger } from '../../utils/reduxOutside';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from '../../store';
import { getSymbols } from '../../store/appState/utils/action';

export default function LoggerPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isCreatingReport, setCreatingReport] = useState(false);
  const [inputFile, setInputFile] = useState<any | null>(null);
  const [calculationFileInfo, setCalculationFileInfo] =
    useState<CalculationFileInfo>({
      id: '',
      title: '',
      createdBy: null,
      createdAt: '',
      datePrinted: null
    });
  const [calculationInfo, setCalculationInfo] = useState<CalculationInfoProps>({
    calculationID: '',
    calculationTitle: '',
    lastSaved: '',
    selectedInputFile: null,
    inputFiles: [],
    folderId: ''
  });
  const [activePage, setActivePage] = useState<number>(0);
  const { calculationId, inputFileId } = useParams<{
    calculationId: string;
    inputFileId: string;
  }>();
  const [formFields, setFormFields] = useState<FormFieldProps>({
    fields: null,
    errors: {
      errors: [],
      total: 0
    },
    totalUserInputs: 0,
    isModified: false
  });
  const [printEnable, setPrintEnable] = useState(false);
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
  const tableRef = useRef<any>({});
  const expandTableRef = useRef<any>(null);
  const expandTableContainerRef = useRef<any>(null);
  const vlcRef = useRef<any>(null);
  // filtering hidden page
  const [filteredPage, setFilteredPage] = useState([]);

  const { symbols } = useSelector(
    (state: ReduxState) => state.utils,
    shallowEqual
  );

  useLayoutEffect(() => {
    if (symbols.length === 0) dispatch(getSymbols());
  }, [symbols]);

  useEffect(() => {
    dispatch(changePositionActionCircleatorBreadcrumb('logger')); // update breadcrumb positions
    !inputFileId &&
      dispatch(
        storeInputFile({
          id: undefined,
          fields: [],
          errors: {
            errors: [],
            total: 0
          },
          totalUserInputs: 0,
          isModified: false
        })
      );
    fetchData();
    sessionStorage.clear();
  }, [inputFileId]);

  const fetchData = async () => {
    try {
      let response: any;

      /** here we add Create New + button to the array input files */
      const tempInputFiles: any[] = [
        {
          id: 'create-new',
          title: '+ Create New'
        }
      ];

      const inputFiles: any = await CalculationService.getInputFiles(
        calculationId
      );
      const latestUpdated: any = await InputFileService.lastUpdated(
        calculationId
      );

      if (!inputFileId) {
        /** Load temporary input file from pre-launch endpoint */
        response = await InputFileService.getTempInputFile(calculationId);
      } else {
        /** Load existing input file from pre-launch endpoint */
        response = await InputFileService.getInputFileById(inputFileId);
      }

      // response modification to generate table name
      // TABLE ${index}
      const pagesData = generateTableName(response.pages);
      const newData = { ...response, pages: pagesData };

      // filtering hidden page
      const filterPage: any = getFilteredPage(response.pages);
      setActivePage(filterPage[0].idx);
      setFilteredPage(filterPage);

      setInputFile(() => newData);
      setCalculationInfo({
        ...calculationInfo,
        calculationID: response?.calculationId,
        calculationTitle: response?.calculationTitle,
        lastSaved: !Boolean(response?.id)
          ? 'Not Saved'
          : localDatetime(response?.updatedAt) ??
            localDatetime(response?.createdAt),
        selectedInputFile: { id: response?.id, title: response.title },
        inputFiles: tempInputFiles.concat(
          inputFiles.items.length > 0 && [
            {
              ...latestUpdated,
              title: latestUpdated?.title + ' Last Updated'
            },
            ...inputFiles?.items?.filter(
              (item: any) => item.id !== latestUpdated?.id
            )
          ]
        ),
        folderId: newData?.folderId
      });

      /**
       * TODO:
       * (remove this after displayedContent on table exist in get input file API)
       */
      //
      setCalculationFileInfo({
        ...calculationFileInfo,
        id: inputFiles.calculationId,
        title: inputFiles.title,
        createdAt: inputFiles.createdTime,
        createdBy: inputFiles.creator,
        datePrinted: inputFiles?.printedTime
      });

      const [totalInputs, errors] = await handleUserInput(response?.pages);
      setFormFields({
        ...formFields,
        errors,
        totalUserInputs: totalInputs.length
      });

      setLoading(false);

      dispatch(storeListInputFiles(inputFiles.items));
      storeDataInputFile({
        fields: newData.pages,
        errors,
        totalUserInputs: totalInputs.length,
        isModified: false
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const storeDataInputFile = (payload: any) => {
    /**
     * store data to reducer
     * so it will be available for topbar menu
     * @params InputFiles: the information regarding current input files. includes (input file value, value change, error, etc)
     */
    dispatch(storeInputFile(payload));
  };

  useEffect(() => {
    calculationInfo.calculationID &&
      dispatch(storeCalculationInfo(calculationInfo));
  }, [calculationInfo]);

  const handlePageNavClicked = (pageNum: number) => {
    setActivePage(pageNum);
  };

  const formChangeHandler = async (fieldData: any) => {
    let reCalculated;
    const [totalInputs, errors] = await handleUserInput(fieldData);
    const [fieldChanges, cellChanges, replacedValueFormula] =
      await checkModifiedFormValue(
        inputFile.pages,
        fieldData,
        tableRef.current
      );
    const isModified = fieldChanges.length > 0 || cellChanges.length > 0;

    if (cellChanges.length > 0) {
      reCalculated = await calculateFormula(
        replacedValueFormula,
        tableRef.current,
        vlcRef.current
      );
    }

    setFormFields({
      ...formFields,
      errors,
      totalUserInputs: totalInputs.length,
      fields: reCalculated ?? replacedValueFormula,
      isModified
    });

    storeDataInputFile({
      fields: reCalculated ?? replacedValueFormula,
      errors,
      totalUserInputs: totalInputs.length,
      isModified
    });
  };

  const handleSelectInputFile = (selectedItem: any) => {
    /**
     * prevent load input file when:
     * selected input is the current input file
     */
    if (selectedItem?.id === calculationInfo?.selectedInputFile?.id) return;

    /**
     * action triggered when user selected input file from dropdown
     */
    if (selectedItem?.id === 'create-new') {
      window.location.href = `/logger/${calculationInfo.calculationID}`;
    } else {
      window.location.href = `/logger/${calculationInfo.calculationID}/${selectedItem.id}`;
    }
  };

  const handleSave = async () => {
    Promise.all([dispatch(saveInputFile(null, inputFileId ?? null))]).then(
      (response: any) => {
        if (response[0].id !== inputFile.id)
          history.push({
            pathname: `/logger/${calculationInfo.calculationID}/${response[0].id}`,
            state: {
              ...(isDriveCalculation && { calculationType: 'BUSINESS' })
            }
          });
        else {
          // response modification to generate table name
          // TABLE ${index}
          const pagesData = generateTableName(response[0].pages);
          const newData = { ...response[0], pages: pagesData };
          setInputFile(() => newData);
          setFormFields(prevState => {
            return {
              ...prevState,
              fields: newData.pages,
              isModified: false
            };
          });
        }
      }
    );
  };

  const handlePrint = async () => {
    const decimalState: any = getInputFileValueLogger();
    const isDecimalModified = formFields.fields
      ? JSON.stringify(getDecimalState(formFields.fields)) ===
        JSON.stringify(getDecimalState(decimalState.fields))
      : true;
    /**
     * now we can check
     * whether the value of the input changed or not
     * so we can exactly know whether we need to save the input file or not before execute save report handler
     *
     * why?:
     * we call checkModifiedFormValue function on every each change on input field value
     * and store the value to our local state which is formFields.isModified's state
     *
     * how?:
     * get the data from local state (formFields.isModified)
     */
    let response: any;

    setCreatingReport(true);

    if (!inputFile.id || !inputFile.isComplete) {
      /**
       * TODO:
       * Check if user is able to create new input file
       * (based on limit calculation file that user have)
       */

      // force save then generate report
      response = await dispatch(saveInputFileAndPrint());
      generateReport(response.id);
    } else {
      if (formFields.isModified || !isDecimalModified) {
        // show popup modal (current input file is modified but not save yet)
        dispatch(
          formModalSetupActionPopUp('REFACTOR_SAVE_CONFIRMATION_LOGGER', {
            inputFile: inputFile,
            loggerFormValues: formFields.fields,
            latex: '',
            route: inputFile?.isComplete ? 'print' : 'logger',
            history,
            isDriveCalculation: isDriveCalculation
          })
        );
      } else {
        generateReport(inputFile.id);
      }
    }
  };

  function generateReport(inputFileID: string) {
    // Generating Report
    ReportService.createReport(inputFileID)
      .then((report: any) => {
        setCreatingReport(false);
        history.push({
          pathname: `/print-view/${calculationId}/${inputFileID}/${report.id}`,
          state: {
            ...(isDriveCalculation && { calculationType: 'BUSINESS' })
          }
        });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch(e => console.error(e));
  }

  const formChangeDecimal = (payload: FormChangeDecimalI) =>
    dispatch(changeDecimalInputFile(payload));

  useEffect(() => {
    if (!inputFile || !formFields) return;

    const { totalInput } = inputFile;
    const { errors, totalUserInputs } = formFields;
    const isValid = totalUserInputs === totalInput || errors?.total <= 0;

    setPrintEnable(isValid);
    dispatch(togglePrintButton(isValid));
  }, [inputFile?.totalInput, formFields?.errors, formFields?.totalUserInputs]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <PageContainer>
      <SidePanel
        sticky={true}
        top={
          <CalculationPanel
            handleSelectedInputFile={handleSelectInputFile}
            handleSaveInput={handleSave}
          />
        }
        bottom={
          <LogProgressPanel
            totalInputs={inputFile?.totalInput}
            userInputs={formFields.totalUserInputs}
            listErrors={formFields.errors}
            handlePrintButton={handlePrint}
            isCreatingReport={isCreatingReport}
            printEnable={printEnable}
          />
        }
      />

      <Workpages
        pageNav={
          <PageNavigation
            pages={filteredPage}
            selected={activePage}
            onClickHandler={handlePageNavClicked}
          />
        }
        pageHeader={<PageHeader source={calculationFileInfo} />}
        pageContent={
          <LoggerForm
            data={inputFile?.pages}
            selectedPage={activePage}
            onChangeHandler={formChangeHandler}
            onChangeDecimal={formChangeDecimal}
            handlePrint={handlePrint}
            tableRef={tableRef}
            expandTableRef={expandTableRef}
            expandTableContainerRef={expandTableContainerRef}
            vlcRef={vlcRef}
          />
        }
      />
    </PageContainer>
  );
}
