import * as React from "react";
import { SVGProps } from "react";

const SvgIncreaseDecimal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#increase-decimal_svg__a)" fill="currentColor">
      <path d="M2.4 13.513c.021-.048.044-.094.062-.143a1.036 1.036 0 0 1 1.18-.658c.477.086.83.523.825 1.022-.005.503-.364.912-.875.998-.483.082-.958-.2-1.133-.671-.018-.05-.04-.098-.06-.146l.001-.402ZM22.425 8.585v1.988c-.006 2.106-1.447 3.788-3.538 4.132-2.138.352-4.298-1.167-4.67-3.29a5.074 5.074 0 0 1-.078-.838 402.355 402.355 0 0 1-.002-4.037c.008-1.997 1.367-3.645 3.338-4.056 2.334-.487 4.688 1.194 4.904 3.552.077.843.012 1.699.012 2.548h.034Zm-6.219-.014h.003v2.108c0 .073.013.146.022.22.118 1.006.993 1.776 2.034 1.79 1.026.015 1.958-.758 2.052-1.761.059-.631.034-1.27.037-1.905.005-.816.007-1.632 0-2.448-.009-1.193-.9-2.1-2.058-2.108-1.168-.007-2.074.897-2.088 2.098-.008.668-.002 1.338-.002 2.006ZM13.445 8.582c0 .703.006 1.406 0 2.109-.025 2.383-2.11 4.253-4.499 4.037-2.118-.19-3.741-1.893-3.78-4.008-.027-1.432-.03-2.866 0-4.298.046-2.166 1.729-3.808 3.73-3.995a4.126 4.126 0 0 1 4.496 3.427c.04.237.05.48.052.72.007.669.002 1.338.002 2.008Zm-6.203-.007c0 .729-.028 1.46.007 2.187.053 1.099.97 1.935 2.064 1.928 1.106-.008 2.031-.862 2.054-1.964.03-1.43.029-2.864 0-4.295-.021-1.096-.934-1.948-2.03-1.963-1.113-.015-2.033.81-2.088 1.92-.035.727-.006 1.457-.007 2.187ZM18.974 20.23h-.642c-1.03 0-2.063.002-3.094 0-.483-.001-.748-.267-.75-.748a75.138 75.138 0 0 1 0-1.265c.004-.45.283-.725.734-.725 1.167-.002 2.333 0 3.5 0h.252c0-.31-.001-.608.002-.906 0-.08-.003-.164.021-.238.075-.232.345-.292.563-.124.656.503 1.31 1.01 1.964 1.514.352.271.705.54 1.054.813.258.203.257.424-.004.627-.999.774-2 1.544-2.999 2.319-.129.1-.262.139-.411.069-.145-.069-.191-.196-.19-.35.002-.32 0-.64 0-.986Z" />
    </g>
    <defs>
      <clipPath id="increase-decimal_svg__a">
        <path
          fill="#fff"
          transform="translate(2.4 2.4)"
          d="M0 0h20.371v19.2H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIncreaseDecimal;
