import styled from "styled-components";

interface ModalPropsI {
  isMobile?: boolean;
  width?: number;
  align?: 'left' | 'right' | 'center'
}

export const ContainerModalStyled = styled('div')<ModalPropsI>(({ isMobile, width }) => ({
  position: "absolute",
  top: isMobile ? undefined : "50%",
  left: isMobile ? undefined : "50%",
  bottom: isMobile ? 0 : undefined,
  transform: isMobile ? undefined : "translate(-50%, -50%)",
  width: isMobile ? "auto" : width,
  backgroundColor: "white",
  flexDirection: "column",
  display: "flex",
  borderRadius: isMobile ? undefined : "8px",
  padding: "1rem",
  ...(isMobile && {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    height: '80%'
  })
}));

export const ContainerTitleStyled = styled.div<ModalPropsI>(({ align = 'left' }) => ({
  flexDirection: "row",
  display: "flex",
  marginBottom: "10px",
  p: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 700,
    flex: 1,
    lineHeight: "32px",
    letterSpacing: "0.15px",
    textAlign: align,
  },
  "&:hover svg": {
    cursor: "pointer",
  },
}));

export const MessageInfoStyled = styled.div({
  color: '#29333D',
  fontFamily: 'Inter',
  fontSize: '14px',
  letterSpacing: '0.4px',
  fontWeight: 400,
});