export const SHOW_FAQ_ACTION = "SHOW_FAQ_ACTION";
export const LOADING_FAQ = "LOADING_FAQ";

export const fetchFAQ = (data: any) => {
  return {
    type: SHOW_FAQ_ACTION,
    data,
  };
};

export function loadingAction(isLoading: boolean) {
  return { type: LOADING_FAQ, isLoading };
}
