export const mapGlyphToString: { [key: string]: string | number | null }[] = [
    {
        "glyph": "α",
        "code": "U+03B1",
        "dec": 945,
        "name": "alpha",
        "latex": "\\alpha"
    },
    {
        "glyph": "β",
        "code": "U+03B2",
        "dec": 946,
        "name": "beta",
        "latex": "\\beta"
    },
    {
        "glyph": "γ",
        "code": "U+03B3",
        "dec": 947,
        "name": "gamma",
        "latex": "\\gamma"
    },
    {
        "glyph": "δ",
        "code": "U+03B4",
        "dec": 948,
        "name": "delta",
        "latex": "\\delta"
    },
    {
        "glyph": "ϵ",
        "code": "U+03F5",
        "dec": 1013,
        "name": "epsilon",
        "latex": "\\epsilon"
    },
    {
        "glyph": "ε",
        "code": "U+03B5",
        "dec": 949,
        "name": "epsilon",
        "latex": "\\varepsilon"
    },
    {
        "glyph": "ζ",
        "code": "U+03B6",
        "dec": 950,
        "name": "zeta",
        "latex": "\\zeta"
    },
    {
        "glyph": "η",
        "code": "U+03B7",
        "dec": 951,
        "name": "eta",
        "latex": "\\eta"
    },
    {
        "glyph": "θ",
        "code": "U+03B8",
        "dec": 952,
        "name": "theta",
        "latex": "\\theta"
    },
    {
        "glyph": "λ",
        "code": "U+03BB",
        "dec": 955,
        "name": "lambda",
        "latex": "\\lambda"
    },
    {
        "glyph": "μ",
        "code": "U+03BC",
        "dec": 956,
        "name": "mu",
        "latex": "\\mu"
    },
    {
        "glyph": "ν",
        "code": "U+03BD",
        "dec": 957,
        "name": "nu",
        "latex": "\\nu"
    },
    {
        "glyph": "π",
        "code": "U+03C0",
        "dec": 960,
        "name": "pi",
        "latex": "\\pi"
    },
    {
        "glyph": "ρ",
        "code": "U+03C1",
        "dec": 961,
        "name": "rho",
        "latex": "\\rho"
    },
    {
        "glyph": "ς",
        "code": "U+03C2",
        "dec": 962,
        "name": "sigma",
        "latex": "\\varsigma"
    },
    {
        "glyph": "σ",
        "code": "U+03C3",
        "dec": 963,
        "name": "sigma",
        "latex": "\\sigma"
    },
    {
        "glyph": "τ",
        "code": "U+03C4",
        "dec": 964,
        "name": "tau",
        "latex": "\\tau"
    },
    {
        "glyph": "υ",
        "code": "U+03C5",
        "dec": 965,
        "name": "upsilon",
        "latex": "\\upsilon"
    },
    {
        "glyph": "φ",
        "code": "U+03C6",
        "dec": 966,
        "name": "phi",
        "latex": "\\phi"
    },
    {
        "glyph": "ψ",
        "code": "U+03C8",
        "dec": 968,
        "name": "psi",
        "latex": "\\psi"
    },
    {
        "glyph": "ω",
        "code": "U+03C9",
        "dec": 969,
        "name": "omega",
        "latex": "\\omega"
    },
    {
        "glyph": "Δ",
        "code": "U+0394",
        "dec": 916,
        "name": "delta",
        "latex": "\\Delta"
    },
    {
        "glyph": "Σ",
        "code": "U+03A3",
        "dec": 931,
        "name": "sigma",
        "latex": "\\Sigma"
    },
    {
        "glyph": "Ω",
        "code": "U+03A9",
        "dec": 937,
        "name": "omega",
        "latex": "\\Omega"
    },
    // Punctuation
    // [' " ! . / \]
    {
        "glyph": "\'",
        "code": null,
        "dec": null,
        "name": "apostrophe",
        "latex": "\\apostrophe"
    },
    {
        "glyph": "\"",
        "code": null,
        "dec": null,
        "name": "quotation",
        "latex": "\\quotation"
    },
    {
        "glyph": "!",
        "code": null,
        "dec": null,
        "name": "exclamation",
        "latex": "\\exclamation"
    },
    {
        "glyph": ",",
        "code": null,
        "dec": null,
        "name": "comma",
        "latex": "\\comma"
    },
    {
        "glyph": ".",
        "code": null,
        "dec": null,
        "name": "period",
        "latex": "\\period"
    },
    {
        "glyph": "/",
        "code": null,
        "dec": null,
        "name": "slash",
        "latex": "\\slash"
    },
    {
        "glyph": "\\\\",
        "code": null,
        "dec": null,
        "name": "backslash",
        "latex": "\\backslash"
    }
];