import {
    createToken,
    CustomPatternMatcherFunc,
    CustomPatternMatcherReturn,
    Lexer,
    TokenType
} from 'chevrotain';
import { paternsubsup } from '../constant';

enum TokenName {
    PI = "PI",
    Trigonometry = "Trigonometry",
    ASINH = "ASINH",
    ACOSH = "ACOSH",
    ATANH = "ATANH",
    ASIN = "ASIN",
    ACOS = "ACOS",
    ATAN = "ATAN",
    ACOT = "ACOT",
    SINH = "SINH",
    COSH = "COSH",
    TANH = "TANH",
    COTH = "COTH",
    CSCH = "CSCH",
    SIN = "SIN",
    COS = "COS",
    TAN = "TAN",
    COT = "COT",
    CSC = "CSC",
    Degrees = "Degrees",
    Radians = "Radians",
    Exponential = "Exponential",
    Rounding = "Rounding",
    RoundDown = "RoundDown",
    RoundUp = "RoundUp",
    Round = "Round",
    Ceiling = "Ceiling",
    Floor = "Floor",
    Absolute = "Absolute",
    LogNatural = "LogNatural",
    LogNumeric = "LogNumeric",
    Log = "Log",
    Modulus = "Modulus",
    Cript = "Cript",
    Sqrt = "Sqrt",
    Fact = "Fact",
    Identifier = "Identifier",
    PercentageNumber = "PercentageNumber",
    SuperNumber = "SuperNumber",
    Equal = "Equal",
    AdditionOperator = "AdditionOperator",
    Plus = "Plus",
    Substract = "Substract",
    MultiplicationOperator = "MultiplicationOperator",
    ExponentiationOperator = "ExponentiationOperator",
    Multi = "Multi",
    Div = "Div",
    Power = "Power",
    LParen = "LParen",
    RParen = "RParen",
    CommaColon = "CommaColon",
    VlookupFunction = "VlookupFunction",
    HlookupFunction = "HlookupFunction",
    IndexFunction = "IndexFunction",
    FunctionExpression = "FunctionExpression",
    PowerFunction = "PowerFunction",
    SumFunction = "SumFunction",
    AverageFunction = "AverageFunction",
    MaxFunction = "MaxFunction",
    MinFunction = "MinFunction",
    WhiteSpace = "WhiteSpace",
    Constant = "Constant"
}

// function for regex assertion lookbehind alternative in javascript
function lookbehindForSubtract(regex: RegExp): CustomPatternMatcherFunc {
    return (text: string, offset: number): CustomPatternMatcherReturn | RegExpExecArray | null => {
        const re = new RegExp(regex, 'y');
        re.lastIndex = offset;

        const prevChar = (offset > 0 && offset < text.length - 1) ? text.substring(offset - 1, offset) : "";

        let result = null;
        if (prevChar.match(/\d|\w|\%|\)/)) {
            result = re.exec(text);
        }

        return result;
    };
}

// tokens for math
const Trigonometry = createToken({ name: TokenName.Trigonometry, pattern: Lexer.NA });
const ASINH = createToken({ name: TokenName.ASINH, pattern: /-?ASINH/, categories: Trigonometry });
const ACOSH = createToken({ name: TokenName.ACOSH, pattern: /-?ACOSH/, categories: Trigonometry });
const ATANH = createToken({ name: TokenName.ATANH, pattern: /-?ATANH/, categories: Trigonometry });
const ASIN = createToken({ name: TokenName.ASIN, pattern: /-?ASIN/, categories: Trigonometry });
const ACOS = createToken({ name: TokenName.ACOS, pattern: /-?ACOS/, categories: Trigonometry });
const ATAN = createToken({ name: TokenName.ATAN, pattern: /-?ATAN/, categories: Trigonometry });
const ACOT = createToken({ name: TokenName.ACOT, pattern: /-?ACOT/, categories: Trigonometry });
const SINH = createToken({ name: TokenName.SINH, pattern: /-?SINH/, categories: Trigonometry });
const COSH = createToken({ name: TokenName.COSH, pattern: /-?COSH/, categories: Trigonometry });
const TANH = createToken({ name: TokenName.TANH, pattern: /-?TANH/, categories: Trigonometry });
const COTH = createToken({ name: TokenName.COTH, pattern: /-?COTH/, categories: Trigonometry });
const CSCH = createToken({ name: TokenName.CSCH, pattern: /-?CSCH/, categories: Trigonometry });
const SIN = createToken({ name: TokenName.SIN, pattern: /-?SIN/, categories: Trigonometry });
const COS = createToken({ name: TokenName.COS, pattern: /-?COS/, categories: Trigonometry });
const TAN = createToken({ name: TokenName.TAN, pattern: /-?TAN/, categories: Trigonometry });
const COT = createToken({ name: TokenName.COT, pattern: /-?COT/, categories: Trigonometry });
const CSC = createToken({ name: TokenName.CSC, pattern: /-?CSC/, categories: Trigonometry });

const Degrees = createToken({ name: TokenName.Degrees, pattern: /-?DEGREES/ });

const Radians = createToken({ name: TokenName.Radians, pattern: /-?RADIANS/ });

const Exponential = createToken({ name: TokenName.Exponential, pattern: /-?EXP/ });

const Rounding = createToken({ name: TokenName.Rounding, pattern: Lexer.NA });
const RoundDown = createToken({ name: TokenName.RoundDown, pattern: /-?ROUNDDOWN/, categories: Rounding });
const RoundUp = createToken({ name: TokenName.RoundUp, pattern: /-?ROUNDUP/, categories: Rounding });
const Round = createToken({ name: TokenName.Round, pattern: /-?ROUND/, categories: Rounding });
const Ceiling = createToken({ name: TokenName.Ceiling, pattern: /-?CEILING/, categories: Rounding });
const Floor = createToken({ name: TokenName.Floor, pattern: /-?FLOOR/, categories: Rounding });
const Absolute = createToken({ name: TokenName.Absolute, pattern: /-?ABS/, categories: Rounding });

const LogNatural = createToken({ name: TokenName.LogNatural, pattern: /-?LN/ });

const LogNumeric = createToken({ name: TokenName.LogNumeric, pattern: /-?LOG[1-9]+[0-9]*/ });
const Log = createToken({ name: TokenName.Log, pattern: /-?LOG/ });

const Modulus = createToken({ name: TokenName.Modulus, pattern: /-?MOD/ });

const Cript = createToken({ name: TokenName.Cript, pattern: Lexer.NA });
const Sqrt = createToken({ name: TokenName.Sqrt, pattern: /-?SQRT/, categories: Cript });
const Fact = createToken({ name: TokenName.Fact, pattern: /-?FACT/, categories: Cript });

// opperand  
const PercentageNumber: any = createToken({ name: TokenName.PercentageNumber, pattern: /-?(\d+(\.\d+)?%)/ });
const SuperNumber: any = createToken({ name: TokenName.SuperNumber, pattern: /-?(\d+(\.\d+)?)/ });
const PI: any = createToken({ name: TokenName.PI, pattern: /-?(PI[(][)])/ });

// first string
const Equal: any = createToken({ name: TokenName.Equal, pattern: /=/ });

// this is the group for addition (+) and substraction (-)
const AdditionOperator = createToken({ name: TokenName.AdditionOperator, pattern: Lexer.NA });
const Plus = createToken({ name: TokenName.Plus, pattern: /\+/, categories: AdditionOperator });

const Substract = createToken({
    name: TokenName.Substract,
    //pattern: wrap(/(?<=\d|\w|\)|\%)-/),
    pattern: lookbehindForSubtract(/-/),
    line_breaks: false, // custom pattern should have line breaks definition 
    categories: AdditionOperator,
});

// this is the group for multiplication (*) and division (/)
const MultiplicationOperator = createToken({ name: TokenName.MultiplicationOperator, pattern: Lexer.NA });
const Multi = createToken({ name: TokenName.Multi, pattern: /\*/, categories: MultiplicationOperator });
const Div = createToken({ name: TokenName.Div, pattern: /\//, categories: MultiplicationOperator });

// this is the power
const ExponentiationOperator = createToken({ name: TokenName.ExponentiationOperator, pattern: Lexer.NA });
const Power = createToken({ name: TokenName.Power, pattern: /\^/, categories: ExponentiationOperator });

// tokens for parenthesis
const LParen = createToken({ name: TokenName.LParen, pattern: /\(/ });
const RParen = createToken({ name: TokenName.RParen, pattern: /\)/ });

// function expression
const CommaColon = createToken({ name: TokenName.CommaColon, pattern: /[:,]/ });

const FunctionExpression = createToken({ name: TokenName.FunctionExpression, pattern: Lexer.NA });
const PowerFunction = createToken({ name: TokenName.PowerFunction, pattern: /POWER/, categories: FunctionExpression });
const SumFunction = createToken({ name: TokenName.SumFunction, pattern: /SUM/, categories: FunctionExpression });
const AverageFunction = createToken({ name: TokenName.AverageFunction, pattern: /AVERAGE/, categories: FunctionExpression });
const MaxFunction = createToken({ name: TokenName.MaxFunction, pattern: /MAX/, categories: FunctionExpression });
const MinFunction = createToken({ name: TokenName.MinFunction, pattern: /MIN/, categories: FunctionExpression });

//whitespace are ignored
const WhiteSpace = createToken({ name: TokenName.WhiteSpace, pattern: /\s+/, group: Lexer.SKIPPED });

const Constant = createToken({ name: TokenName.Constant, pattern: Lexer.NA });

const tokensByPriority = [
    WhiteSpace,
    FunctionExpression,
    PowerFunction,
    SumFunction,
    AverageFunction,
    MaxFunction,
    MinFunction,
    Trigonometry,
    ASINH,
    ACOSH,
    ATANH,
    ASIN,
    ACOS,
    ATAN,
    ACOT,
    SINH,
    COSH,
    TANH,
    COTH,
    CSCH,
    SIN,
    COS,
    TAN,
    COT,
    CSC,
    PI,
    Degrees,
    Radians,
    Exponential,
    Rounding,
    RoundDown,
    RoundUp,
    Round,
    Ceiling,
    Floor,
    Absolute,
    LogNatural,
    LogNumeric,
    Log,
    Modulus,
    Cript,
    Sqrt,
    Fact,
    LParen,
    RParen,
    MultiplicationOperator,
    Multi,
    Div,
    AdditionOperator,
    Plus,
    Substract,
    ExponentiationOperator,
    Power,
    PercentageNumber,
    SuperNumber,
    Constant,
    CommaColon,
    Equal,
];

export type TokenTypeDict = { [key in any]: TokenType };

export const generateToken = (data: any) => {
    const symbols = data.map((symbol: any) => symbol.glyph).join('');
    const Identifier: any = createToken({
        name: TokenName.Identifier,
        pattern: new RegExp(`-?([\\w${symbols}]|${paternsubsup})+`),
        categories: Constant,
    });
    if (!tokensByPriority.find(token => token.name == TokenName.Identifier)) {
        tokensByPriority.push(Identifier);
    };
    return tokensByPriority;
};

export const getTokenDict = (tokens: any) => {
    const dict: TokenTypeDict = tokens.reduce(
        (acc: any, tokenType: any) => {
            acc[tokenType.name] = tokenType;
            return acc;
        },
        {} as any
    );
    return dict;
};

export const getLexer = (tokens: TokenType[]) => {
    return new Lexer(tokens, {});
};
