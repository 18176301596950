import { ChatDataT } from "../../store/reducers/aceReducers";

export const buildChatHistories = (data: ChatDataT[], max?: number) => {
  if (!data || data.length == 0) return {};
  const arr = data.reverse(); // sort data from the latest
  const obj: Record<string, string> = {};
  arr.forEach((item, index) => {
    const { question, answer } = item;
    if ((!max || max && index < max) && (!!question && !!answer)) {
      return obj[question] = answer
    }
  })
  return obj;
}

// Ace certainty
export const certaintyColors = ['#F67650', '#7363E5', '#2CC077'];
export const certaintyText = ['No matches found. Too general. Consider trying a new prompt', 'Some matches found. Consider refining prompt', 'Found exact match']
export const arrCertaintyRange = [{ min: -1, max: 21 }, { min: 21, max: 80 }, { min: 80, max: 101 }]
export const defaultBarItems = ["#E0E6EB", "#E0E6EB", "#E0E6EB"]

export const getIndexOfCertaintyRange = (value: number) => {
  let result: unknown;
  arrCertaintyRange.forEach(({ max, min }, index) => {
    if (value > min && value < max) {
      result = index
    };
  })
  return result;
}

export const matchReplace = (replacements: any[], str: string) => {
  let result = str;
  for (const [x, y] of replacements) result = result.replace(x, y)
  return result;
}