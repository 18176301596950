import { MutableRefObject } from "react";
import { FormulaTable } from "../../../components/molecules/FormulaTable";
import { expandTableDefaultCellStyle } from "../../../utils/constant";
import { HiddenDiv } from "./styles";
import { HiddenTableI } from "./types";

const HiddenTable = ({
  activePage,
  dataBodyOfCalculationCloned,
  workpageTableRef,
}: HiddenTableI) => {
  return (
    <HiddenDiv>
      {
        dataBodyOfCalculationCloned.map((dataPages: any, index: number) => {
          if (index !== activePage) {
            return (
              dataPages.map((element: any) => {
                return (
                  <FormulaTable
                    ref={(
                      el: MutableRefObject<HTMLElement>
                    ) =>
                      (workpageTableRef.current[element.id] =
                        el)
                    }
                    name={
                      element.tableName
                        ? element.tableName
                            .split(' ')
                            .join('')
                        : ''
                    }
                    data={
                      element.tableBody ? element.tableBody : []
                    }
                    columnSetting={
                      element.tableHeader
                        ? element.tableHeader
                        : []
                    }
                    dimensions={[
                      element.tableBody
                        ? element.tableBody[0].length
                        : 0,
                      element.tableBody
                        ? element.tableBody.length
                        : 0
                    ]}
                    cellStyle={expandTableDefaultCellStyle}
                    displayedContent={
                      element.displayedContent
                        ? element.displayedContent
                        : undefined
                    }
                    contextMenu={() => {
                      return false;
                    }}
                    hideHiddenCells={true}
                    nonEditable={true}
                    stickyColumn={element.freeze?.column}
                    stickyRow={element.freeze?.row}
                  />
                );
              })
            );
          }
        })
      }
    </HiddenDiv>
  );
};

export default HiddenTable;