import styled from 'styled-components';

interface Props {
  type?: any;
}

export const ToastBox = styled.div<Props>(({ type }: Props) => ({
  minWidth: type === 'ERROR_SECONDARY' || type === 'ERROR_TERTIARY' ? 'auto' : '500px',
  paddingLeft: '16px',
  paddingRight: '16px',
  height: '40px',
  display: 'flex',
  justifyContent: type === 'ERROR_SECONDARY' || type === 'ERROR_TERTIARY' ? 'space-between' : 'center',
  borderRadius: '4px',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  letterSpacing: '0.4px',
  color: type === 'ERROR_SECONDARY' || type === 'ERROR_TERTIARY' ? '#EE312B' : '#FFFFFF',
  backgroundColor: type === 'INFO' || type === 'REFRESH' ? '#33404D' : type === 'ERROR' ? '#D51F3C' : type === 'SUCCESS' ? '#37D046' : (type === 'ERROR_SECONDARY' || type === 'ERROR_TERTIARY') ? '#FBE5EA' : 'transparent',
  flexDirection: 'row',
  columnGap: type === 'ERROR_SECONDARY' || type === 'ERROR_TERTIARY' ? '32px' : undefined
}));

export const TextButton = styled.p<Props>(({ type }: Props) => ({
  fontSize: '12px',
  color: type === 'REFRESH' ? '#118CFF' : '#EE312B',
  cursor: 'pointer',
  margin: type === 'REFRESH' ? 12 : 0,
  fontWeight: 700
}));