import {
  CalculationContent,
  ChildWrapper,
  TitleWrapper
} from '../../components/organisms/hoc/elements';
import { CalculationsSearchBox } from '../../components/molecules/calculationsSearchBox';
import { CalculationsEmptyResults } from '../../components/molecules/calculationsEmptyResults';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BasicTable from '../../components/molecules/paginatedTable';
import { BreadcrumbsV2 } from '../../components/molecules/Breadcrumbs';
import CommunityInfoPanel from '../../components/organisms/CommunityInfoPanel';
import { ContentSection } from '../../pageElements/calculations';
import { Divider } from '@mui/material';
import GridView from '../../components/molecules/gridView';
import HelperButton from '../../components/molecules/HelperButton';
import { LoadingSpinner } from '../../components/atoms/loading';
import SideBar from '../../components/molecules/sidebar';
import Text from '../../components/atoms/Text';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { useState, useEffect, useRef } from 'react';
import { getUserProfile } from '../../utils/storage';
import { ReduxState } from '../../store';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserCalculationLimit } from '../../store/actions/userAction';
import { checkSubscriptionAndDate } from '../../utils/helpersFunction';
import { setSelectedTreeItem } from '../../store/appState/sidebar/action';
import { getFolderTree } from '../../store/appState/sidebar/action';
import { handleSearchCalculation } from '../../utils/helpersFunction';

const CommunityCalculation = (props: any) => {
  const {
    login,
    encal,
    isMinimize,
    styleView,
    isActiveFolder,
    handleActiveFocus,
    handleContextMenu,
    breadCrumbsData,
    global,
    activeItem,
    calInfo,
    handleModal,
    handleInActiveFocus,
    fetchResources,
    openPanel,
    renderCommunityMenuContext,
    handlerDragEnd,
    handlePreviewCalculation,
    onBeforeCapture,
    selectedTask,
    handleActiveFocusFolder,
    selectedCommIds
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { folderId } = useParams<{ folderId: string }>();

  const { personalWorkspaceId, communityWorkspaceId } = login;
  const { typeView } = global;
  const { isLoading, data } = encal;
  const id = folderId ? folderId : communityWorkspaceId;
  const [filteredData, setFilteredData] = useState<any>([]);
  const [value, setValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);
  const [renderCount, setRenderCount] = useState<number>(0);
  const firstRender = useRef(true);
  const TOTAL_RENDER = 3;
  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { selectedItem } = useSelector(
    (state: ReduxState) => state.sidebar,
    shallowEqual
  );

  const { subscription } = useSelector((state: ReduxState) => state.login);

  useEffect(() => {
    dispatch(getUserCalculationLimit());
  }, []);

  useEffect(() => {
    setSearchResult('');
    setValue('');
    setRenderCount(renderCount + 1);

    // update folder tree on sidebar everytime new folder is added
    if (!firstRender.current) {
      dispatch(setSelectedTreeItem(id));
      dispatch(getFolderTree(id));
      setRenderCount(0);
    }
  }, [isLoading, data]);

  useEffect(() => {
    renderCount >= TOTAL_RENDER
      ? (firstRender.current = false)
      : (firstRender.current = true);
  }, [renderCount]);

  const handleCopy = () => {
    if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
      dispatch(
        formModalSetupActionPopUp('COPY_TO_MYCALC_FROM_COMMUNITY', {
          location: personalWorkspaceId
        })
      );
    }
  };

  const handleDelete = (payload?: any) => {
    dispatch(
      formModalSetupActionPopUp(
        'CONFIRMATION_DELETE_MULTIPLE_CALCULATION',
        payload
      )
    );
  };

  const handleSearch = () => {
    handleSearchCalculation(
      data,
      value,
      communityWorkspaceId,
      setFilteredData,
      setSearchResult,
      setSearchError
    );
  };

  const handleClickSidebar = () => {
    if (selectedItem === communityWorkspaceId) {
      setSearchResult('');
      setValue('');
    }
  };

  useEffect(() => {
    const id = folderId ? folderId : communityWorkspaceId;
    const unsubscribe = fetchResources(id, location);
    return unsubscribe;
  }, [communityWorkspaceId, folderId]);

  const handleDoubleClickItem = (e: any) => {
    if (e.itemType === 'FOLDER') {
      history.push(`/calculations/community/${e.id}`);
    }
  };

  const userRole = getUserProfile();
  return (
    <>
      <CalculationContent>
        <SideBar
          isMinimize={isMinimize}
          onClick={handleClickSidebar}
          isLoading={isLoading && firstRender.current}
        />
        <Divider orientation="vertical" flexItem />
        <ChildWrapper onClick={handleInActiveFocus}>
          <BreadcrumbsV2 dataSource={breadCrumbsData} />
          <TitleWrapper>
            <Text variant="h2" style={{ paddingTop: 24 }}>
              These are calculations that all users can download and use
            </Text>
            <CalculationsSearchBox
              placeholder="Search calculation"
              value={value}
              error={searchError}
              onChange={(e: any) => setValue(e.target.value)}
              onKeyDown={(e: any) => {
                e.key === 'Enter' && handleSearch();
              }}
              onClick={() => {
                handleSearch();
              }}
            />
          </TitleWrapper>

          {renderCommunityMenuContext()}
          {isLoading && <LoadingSpinner />}
          <ContentSection onClick={handleInActiveFocus}>
            <GridView
              data={searchResult ? filteredData : data}
              typeView={typeView}
              styleView={styleView}
              isActiveFolder={isActiveFolder}
              handleActiveFocus={handleActiveFocus}
              handleContextMenu={handleContextMenu}
              handleDoubleClickItem={handleDoubleClickItem}
              id={id}
              selectedTask={selectedTask}
              selectedCommIds={selectedCommIds}
            />
            {styleView === 'List' && (
              <BasicTable
                isActiveFolder={isActiveFolder}
                data={searchResult ? filteredData : data}
                onClick={handleActiveFocus}
                onContextMenu={handleContextMenu}
                onDoubleClick={handleDoubleClickItem}
                id={id}
                handlerDragEnd={handlerDragEnd}
                onBeforeCapture={onBeforeCapture}
                isAllowDrag={!searchResult && /REKAVA/.test(String(userRole))}
                selectedTask={selectedTask}
                selectedCommIds={selectedCommIds}
                handleActiveFocusFolder={handleActiveFocusFolder}
              />
            )}
          </ContentSection>
          {filteredData.length <= 0 && searchResult.length > 0 && !isLoading ? (
            <CalculationsEmptyResults
              value={`No Results for "${searchResult}"`}
            />
          ) : null}
        </ChildWrapper>
        {activeItem?.id && calInfo?.calName && openPanel && (
          <CommunityInfoPanel
            activeItem={activeItem}
            calInfo={calInfo}
            personalWorkspaceId={personalWorkspaceId}
            communityWorkspaceId={communityWorkspaceId}
            handleModal={handleModal}
            handleCopyToMyCalc={handleCopy}
            handlePreviewCalculation={handlePreviewCalculation}
            handleDelete={handleDelete}
            {...props}
          />
        )}
      </CalculationContent>
      <HelperButton />
    </>
  );
};

export default calculationsNav(CommunityCalculation);
