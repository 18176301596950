import * as React from "react";
import { SVGProps } from "react";

const SvgHeight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 19.546V5h2.195v6.321h7.251V5h2.202v14.546h-2.202v-6.343H8.195v6.343H6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHeight;
