import styled from 'styled-components';

export const DashboardContainer = styled.div({
  backgroundColor: '#F0F2F5',
  display: 'flex',
  height: 'calc(100vh - 8.5rem)',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2.25rem',
  flexWrap: 'wrap',
});


