import * as React from "react";
import { SVGProps } from "react";

const Fx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.619 9.774c-.295.304-.722.455-1.279.455H0l.11-1.094h1.624l.405-3.996H.578L.7 3.996H2.25l.246-2.36c.049-.484.221-.878.516-1.181C3.307.152 3.733 0 4.291 0h1.426l-.123 1.094H3.897L3.59 3.996h1.857l-.111 1.143H3.479l-.344 3.467c-.049.476-.221.865-.516 1.168zM9.16 6.258L7.623 8.582H6.197l2.25-3.234-2.188-3.16h1.549l1.438 2.226h.037l1.476-2.226h1.426L9.996 5.336l2.226 3.246h-1.55L9.197 6.258H9.16z"
      fill="currentColor"
    />

  </svg>
);

export default Fx;
