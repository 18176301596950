import { Content } from "../elements";
import TypographyComponent from "../../../atoms/Typography";
import { ReactNode } from "react";

interface PanelVersionI {
  title: string;
  children: ReactNode;
}

const InfoPanelVersions = ({ title, children }: PanelVersionI) => {
  return (
    <Content>
      <TypographyComponent variant="HEADING_1">{title}</TypographyComponent>
      {children}
    </Content>
  );
};

export default InfoPanelVersions;
