import React from 'react'
import { Typography, useTheme } from '@mui/material';
import StyledButton from '../../../components/atoms/Button';
import { Refresh } from '../../../assets/icons';
import { TEXT_ERROR_MESSAGE, TEXT_NO_INTERNET, TEXT_REFRESH } from '../../../utils/constant';
import { SessionErrorWrapper } from './styles';

export const AceError = ({ isOffline }: { isOffline: boolean }) => {
  const theme = useTheme();
  return (
    <SessionErrorWrapper>
      <Typography variant='body2' color={theme.customText.medium}>
        {isOffline ? TEXT_NO_INTERNET : TEXT_ERROR_MESSAGE}
      </Typography>
      <StyledButton size='medium' variant='outlined' startIcon={<Refresh color={theme.palette.primary.main} />} onClick={() => window.location.reload()}>
        {TEXT_REFRESH}
      </StyledButton>
    </SessionErrorWrapper>
  )
}
