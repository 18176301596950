import {
  AccountSettingWrapper,
  CalculationContent
} from '../../components/organisms/hoc/elements';
import { Box, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorInputText, NoPaddingAutocomplete } from './element';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  editUserProfileAction,
  fetchAllCountries,
  fetchAllIndustries,
  fetchAllProfession,
  getUserProfileAction
} from '../../store/actions/userAction';
import { getUserDetails, getUserType, setUserDetails } from '../../utils/storage';
import {
  loadingProfilePhoto,
  updateProfilePhoto
} from '../../store/actions/loginActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AvatarAccount from './AvatarAccount';
import Divider from '@mui/material/Divider';
import HeaderUserDasboard from '../../components/molecules/HeaderUserDashboard/HeaderUserDasboard';
import Input from '../../components/atoms/Input';
import Loading from '../../components/atoms/loading';
import { LoadingContainer } from '../../components/organisms/layouts/elements';
import { MobileSidebar } from '../../components/molecules/MobileSidebar';
import PersonalInformation from './PersonalInformation';
import { ReduxState } from '../../store';
import Service from '../../service/generic/generic';
import SideBar from '../../components/molecules/sidebar';
import Text from '../../components/atoms/Text';
import UseWindowDimensions from '../../utils/getWindows';
import { accountSetting } from '../../utils/validation';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { getExtension } from '../../utils/fileHandler';
import { makeStyles } from '@mui/styles';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { updateUserPhoto } from '../../store/types/userTypes';
import { useDebounce } from '../../utils/useDebounce';
import { yupResolver } from '@hookform/resolvers/yup';
import { TEXT_ACCOUNT_SETTINGS } from '../../utils/constant';

interface IFormEdit {
  name: string;
  industry: string;
  country: any;
  profession: string;
  company: any;
}



const AccountSetting = (props: any) => {
  const { isMinimize, handleInActiveFocus, isShowMobileSidebar, handleShowMobileSidebar, sidebar, users } = props;
  const [userDetail, setUserDetail] = useState<any>(null);
  const dispatch = useDispatch();
  const service = new Service('user');
  const inputFile = useRef<HTMLInputElement>(null);

  const { userPhoto, userDetails, loading } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  const [countries, setCountries] = useState<any>([]);
  const [profession, setProfession] = useState<any>();
  const [industries, setIndustries] = useState<any>();
  const [password] = useState<any>('*******');

  const [countryDefaultValue, setCountryDefaultValue] =
    React.useState<any>('Indonesia');
  const [currentPict, setCurrentPict] = useState<any>(null);
  const [uploadingState, setUploadingState] = useState({
    isUploading: false,
    isError: false,
    isSuccess: false,
    successMessage: '',
    errorMessage: ''
  });
  const [profileImage, setProfileImage] = useState<any>(null);

  const [param, setParam] = useState<any>(null);

  const debouncedParams = useDebounce(param, 500);
  const isMobile = !useMediaQuery('(min-width:1080px)');
  const { folderTree } = sidebar;
  const { userLimit } = users;
  const { width } = UseWindowDimensions();

  const {
    handleSubmit: handleRegister,
    control,
    setValue,
    formState: { errors },
    trigger
  } = useForm<IFormEdit>({
    resolver: yupResolver(accountSetting),
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    handleRegister(handleRegisterButton)();
  }, [debouncedParams]);

  const handleChange = (name: any, value: any) => {
    setValue(name, value);
    setParam(new Date());
  };

  const handleFetchDropDown = async () => {
    try {
      Promise.all([
        dispatch(fetchAllCountries()),
        dispatch(fetchAllProfession()),
        dispatch(fetchAllIndustries())
      ]).then(response => {
        setCountries(response[0]);
        setProfession(response[1]);
        setIndustries(response[2]);
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchDropDown();
    dispatch(getUserProfileAction());
    setCountryDefaultValue(userDetail?.country);
  }, [profileImage, userDetail?.email, userDetail]);

  useEffect(() => {
    setTimeout(() => {
      setUserDetail(getUserDetails());
    }, 500);
  }, []);

  useEffect(() => {
    if (userDetail) {
      try {
        setProfileImage(
          userDetail.profilePictureUrl
            ? userDetail?.profilePictureUrl +
            '?timestamp=' +
            new Date().getTime()
            : ''
        );
        setValue('name', (userDetail as any)?.name);
        trigger('name');
        setValue('company', userDetail?.organization?.name);
        setValue('industry', userDetail?.industry?.name);
        setValue('profession', userDetail?.profession?.name);
        setCountryDefaultValue(userDetail?.country);
      } catch (error) {
        console.error(error);
      }
    }
  }, [userDetail]);

  const onImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleDeleteImage = async () => {
    service
      .deleteWithoutParams('profile-picture')
      .then(({ data }) => {
        Promise.all([
          setProfileImage(null),
          setUserDetails({
            ...userDetail,
            userPhoto: ''
          }),
          dispatch(updateProfilePhoto(''))
        ]).then(() => {
          setUploadingState({
            ...uploadingState,
            isUploading: false,
            isSuccess: true,
            successMessage: data?.message
          });
        });
      })
      .catch((error: any) => {
        setCurrentPict(null);
        setProfileImage(null);
        setUploadingState({
          ...uploadingState,
          isError: true,
          isUploading: false,
          errorMessage: error.response.data.message
        });
        if (inputFile?.current?.value) {
          inputFile.current.value = '';
        }
      });
  };

  const handleRegisterButton = (data: any) => {
    data.profession = profession?.data.filter(
      (el: any) => el?.name === data.profession
    );
    data.industry = industries?.data.filter(
      (el: any) => el?.name === data.industry
    );
    dispatch(editUserProfileAction(data));
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleRegister(handleRegisterButton);
    }
  };

  const forgotPassword = async () => {
    dispatch(formModalSetupActionPopUp('FORGOT_PASSWORD'));
  };

  const handleEditInformation = () => {
    dispatch(
      formModalSetupActionPopUp('EDIT_PASSWORD_ACCOUNT_SETTING', forgotPassword)
    );
  };

  const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: '#aaa'
    }
  }));

  const Placeholder = (props: any) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{props?.children}</div>;
  };

  const handleSameImage = (e: any) => {
    const element = e.target as HTMLInputElement;
    element.value = '';
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return null;
    const files = e.target.files;
    if (files?.length) {
      // cek if extension file is jpg or png
      const extension = getExtension(files[0].name);
      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
        if (files[0].size < 15000000) {
          try {
            const payload = new FormData();
            payload.append('file', e.target.files[0]);
            setUploadingState({
              ...uploadingState,
              isUploading: true
            });
            service
              .putAction('profile-picture', payload)
              .then(({ data }) => {
                const userPhoto = data.data.profilePictureUrl
                  ? data.data?.profilePictureUrl +
                  '?timestamp=' +
                  new Date().getTime()
                  : '';
                Promise.all([
                  setProfileImage(userPhoto),
                  setUserDetails({
                    ...userDetail,
                    name: data.data?.name,
                    userPhoto: data.data?.profilePictureUrl
                  }),
                  dispatch(updateUserPhoto(userPhoto)),
                  dispatch(loadingProfilePhoto(true))
                ]).then(() => {
                  setUploadingState({
                    ...uploadingState,
                    isUploading: false,
                    isSuccess: true,
                    successMessage: data?.message
                  });
                });
              })
              .catch((error: any) => {
                setCurrentPict(null);
                setUploadingState({
                  ...uploadingState,
                  isError: true,
                  isUploading: false,
                  errorMessage: error.response.data.message
                });
                if (inputFile?.current?.value) {
                  inputFile.current.value = '';
                }
              })
              .finally(() => {
                dispatch(loadingProfilePhoto(false));
                setTimeout(() => {
                  setUploadingState({
                    ...uploadingState,
                    isSuccess: false,
                    isError: false,
                    successMessage: ''
                  });
                }, 1500);
              });
          } catch (error) {
            console.error(error);
          }
        } else {
          dispatch(toastSetupActionPopUp('ERROR', 'File Size is too large'));
        }
      } else {
        dispatch(
          toastSetupActionPopUp(
            'ERROR',
            'Incompatible File Format (Examples of compatible files type are .jpg, .jpeg, .png)'
          )
        );
      }
    }
  };

  if (!userDetail)
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  return (
    <>
      <CalculationContent>
        {!isMobile &&
          <>
            <SideBar isMinimize={isMinimize} view={false} />
            <Divider orientation="vertical" flexItem />
          </>
        }
        <AccountSettingWrapper onClick={handleInActiveFocus}>
          {!/ACE/.test(getUserType()) && <HeaderUserDasboard />}
          <Divider style={{ width: '100%' }} />
          <div style={{ padding: isMobile ? 16 : 24, height: '100%' }}>
            <Text variant="h2" style={{ paddingBottom: 32 }}>
              {TEXT_ACCOUNT_SETTINGS}
            </Text>
            <Text variant="h4" style={{ paddingBottom: 16 }}>
              Avatar
            </Text>
            <div>
              <AvatarAccount
                inputFile={inputFile}
                handleChangeImage={handleChangeImage}
                currentPict={currentPict}
                profileImage={profileImage}
                onImageClick={onImageClick}
                handleDeleteImage={handleDeleteImage}
                handleSameImage={handleSameImage}
                isMobile={isMobile}
              />
              <Text variant="h4" style={{ paddingTop: 32 }}>
                Profile
              </Text>
              <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box style={{ paddingRight: isMobile ? 0 : 16, paddingTop: 16 }}>
                  <Text
                    variant="body3"
                    style={{ paddingTop: 16, paddingBottom: 4 }}
                  >
                    Name
                  </Text>
                  <Box>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            sx={{ width: isMobile ? '100%' : 400, height: 40 }}
                            InputProps={{
                              sx: { height: 40 },
                              inputProps: { maxLength: 60 }
                            }}
                            {...field}
                            onChange={(e: React.SyntheticEvent) => {
                              handleChange(
                                field.name,
                                (e.target as HTMLInputElement)?.value
                              );
                            }}
                            onKeyPress={(e: React.SyntheticEvent) => {
                              handleEnter(e);
                            }}
                            type="text"
                            variant="outlined"
                            size="small"
                            error={!!errors.name}
                            helperText={errors.name?.message}
                          />
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box style={{ flexDirection: 'row', paddingTop: 16 }}>
                  <Text
                    variant="body3"
                    style={{ paddingTop: 16, paddingBottom: 4 }}
                  >
                    Profession
                  </Text>
                  <div
                    style={{ paddingBottom: '16px', width: isMobile ? '100%' : 400 }}
                  >
                    <Controller
                      defaultValue=""
                      name="profession"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <Select
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #E0E6EB'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #E0E6EB'
                                },
                                width: isMobile ? '100%' : 400,
                                height: 40,
                                fontSize: '12px',
                                fontWeight: '400px',
                                borderRadius: '6px',
                                padding: "8px 2px",
                              }}
                              {...field}
                              // onBlur={handleRegister(handleRegisterButton)}
                              onChange={(e: any) => {
                                handleChange(
                                  field.name,
                                  (e.target as HTMLInputElement)?.value
                                );
                              }}
                              displayEmpty={true}
                              renderValue={value =>
                                value?.length ? (
                                  Array.isArray(value) ? (
                                    value.join(', ')
                                  ) : value ? (
                                    value
                                  ) : (
                                    () => value
                                  )
                                ) : (
                                  <Placeholder>Select Profession</Placeholder>
                                )
                              }
                              error={!!errors.profession}
                            >
                              {profession?.data.map(
                                (item: any, index: number) => {
                                  return (
                                    <MenuItem
                                      value={item.name}
                                      key={`menu_item_${index}`}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                            {errors.profession ? (
                              <ErrorInputText>
                                {errors.profession?.message}
                              </ErrorInputText>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                </Box>
              </Box>
              <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box style={{ paddingRight: isMobile ? 0 : 16, flexDirection: 'row' }}>
                  <div
                    style={{ paddingBottom: '16px', width: isMobile ? '100%' : 400 }}
                  >
                    <Text variant="body3" style={{ paddingBottom: 4 }}>
                      Industry
                    </Text>
                    <Controller
                      defaultValue=""
                      name="industry"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <Select
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #E0E6EB'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  border: '1px solid #E0E6EB'
                                },
                                width: isMobile ? '100%' : 400,
                                height: 40,
                                fontSize: '12px',
                                fontWeight: '400px',
                                padding: "8px 2px",
                                borderRadius: '6px',
                              }}
                              {...field}
                              onChange={(e: any) => {
                                handleChange(
                                  field.name,
                                  (e.target as HTMLInputElement)?.value
                                );
                              }}
                              renderValue={value =>
                                value?.length ? (
                                  Array.isArray(value) ? (
                                    value.join(', ')
                                  ) : value ? (
                                    value
                                  ) : (
                                    () => value
                                  )
                                ) : (
                                  <Placeholder>Select Industry</Placeholder>
                                )
                              }
                              displayEmpty={true}
                              error={!!errors.industry}
                            >
                              {industries?.data.map((item: any) => {
                                return (
                                  <MenuItem value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.profession ? (
                              <ErrorInputText>
                                {errors.profession?.message}
                              </ErrorInputText>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                </Box>
                <Box style={{ flexDirection: 'row', paddingTop: isMobile ? 16 : 0 }}>
                  <Text variant="body3" style={{ paddingBottom: 4 }}>
                    Organization/Institution
                  </Text>
                  <div
                    style={{ paddingBottom: '16px', width: isMobile ? '100%' : 400 }}
                  >
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            style={{ width: isMobile ? '100%' : 400 }}
                            InputProps={{
                              sx: { height: 40 },
                              inputProps: { maxLength: 250 }
                            }}
                            {...field}
                            // onBlur={handleRegister(handleRegisterButton)}

                            onChange={(e: React.SyntheticEvent) => {
                              handleChange(
                                field.name,
                                (e.target as HTMLInputElement)?.value
                              );
                            }}
                            onKeyPress={(e: React.SyntheticEvent) => {
                              handleEnter(e);
                            }}
                            type="text"
                            variant="outlined"
                            placeholder="Type your organization/Institution"
                            size="small"
                            error={!!errors.company}
                            helperText={errors.company?.message}
                          />
                        );
                      }}
                    />
                  </div>
                </Box>
              </Box>
              <Box style={{ display: 'flex', paddingBottom: 16 }}>
                <Box style={{ flexDirection: 'row' }}>
                  <Text variant="body3" style={{ paddingBottom: 4 }}>
                    Country
                  </Text>
                  <div
                    style={{ paddingBottom: '16px', width: isMobile ? '100%' : 400, flex: 1 }}
                  >
                    {countryDefaultValue || !userDetail.country ? (
                      <Controller
                        control={control}
                        name="country"
                        render={({ field: { onChange, value } }) => (
                          <NoPaddingAutocomplete
                            onKeyPress={(e: React.SyntheticEvent) => {
                              handleEnter(e);
                            }}
                            onChange={(event, item) => {
                              onChange(item);
                              setCountryDefaultValue(item);
                              setParam(new Date());
                              return item;
                            }}
                            autoComplete={true}
                            defaultValue={countryDefaultValue}
                            options={countries?.data ?? []}
                            getOptionLabel={(option: any) =>
                              option.name ? option.name : ''
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Type your country"
                                variant="outlined"
                                fullWidth
                                error={!!errors.company}
                                helperText={errors.company?.message}
                                required
                                style={{ width: isMobile ? width - 48 : 400 }}
                              />
                            )}
                          />
                        )}
                      />
                    ) : null}
                  </div>
                </Box>
              </Box>
              <PersonalInformation
                error={errors}
                handleEditInformation={handleEditInformation}
                email={userDetail.email}
                userProfile={userDetail}
                password={password}
                isMobile={isMobile}
              />
            </div>
          </div>
        </AccountSettingWrapper>
        {isMobile &&
          <>
            <MobileSidebar
              isOpen={isShowMobileSidebar}
              handleClose={handleShowMobileSidebar}
              headerTitle={'Back to Conversation'}
              data={folderTree}
              selectedItem={''}
              userData={{
                userLimit, userDetails, userPhoto, loading
              }}
            />
          </>}
      </CalculationContent>
    </>
  );
};

export default calculationsNav(AccountSetting);
