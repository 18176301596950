import styled from 'styled-components';

export const NavMenu = styled.ul({
  display: 'flex',
  padding: 0,
  margin: 0,
  listStyle: 'none',
  li: {
    display: 'block',
    padding: '0.3rem',
    color: '##292A31',
    fontSize: '12px',
    cursor: 'pointer',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
});

export const MenuLabel = styled.li({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  userSelect: 'none',
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
  },
  '&:hover': {
    '& > .navMenuStrip': {
      display: 'block',
      borderBottom: '2px solid #118CFF',
      paddingTop: '7px'
    },
  },
  '& > .navMenuStripActivated': {
    borderBottom: '2px solid #118CFF',
    paddingTop: '7px'
  }
});

export const MenuWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  p: {
    fontSize: 12,
    letterSpacing: 0.4,
    fontFamily: 'Inter',
    margin: 0,
  },
});

export const NestedMenu = styled.div({
  width: 160,
});
