import styled from "styled-components";
interface Props {
  imageLeft?: string;
  imageRight?: string;
  isUploading?: boolean;
}
export const HeadAtBodyMainContent = styled.div({
  borderBottom: "1px solid #E0E6EB",
  height: "auto",
  display: "flex",
  flexDirection: "row",
});

export const HeadAtBodyMainContentLeft = styled.div<Props>((props) => ({
  padding: "12.5px 16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const HeadAtBodyMainContentCenter = styled.div({
  width: "70%",
  padding: "8px 0 8px 56px",
});

export const HeadAtBodyMainContentRight = styled.div({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flex: 1,
  padding: 16
});

export const CalculationTittleContainer = styled.div({
  padding: '8px 0 0'
});

export const UploadButtonContainer = styled.div({
  paddingTop: 4
});

export const PrimaryLogo = styled.div<Props>(({ isUploading }) => ({
  display: 'flex',
  borderRadius: '50%',
  backgroundColor: '#F0F3F7',
  '& > div': {
    '& > img': {
    width: "64px",
    height: "64px",
    cursor: "pointer",
    borderRadius: "100%",
    opacity: isUploading ? 0.3 : 1,
    pointerEvents: isUploading ? 'none' : 'auto',
    },
    '& > p': {
      fontSize: 14,
      lineHeight: '16px',
      color: '#9A9DB3',
      letterSpacing: 0.4,
    },
    '& > div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 'auto',
      
    },
  },
}));