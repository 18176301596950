export const LOADING = 'LOADING_ENCAL';
export const FAILED = 'FAILED_ENCAL';
export const DATA = 'DATA_ENCAL';

export interface LoadingAction {
  type: typeof LOADING;
  isLoading: boolean
}

export interface FailedAction {
  type: typeof FAILED;
  message: string;
  isLoading: boolean;
}

export interface DataAction {
  type: typeof DATA;
  data: Array<any>
}