import { DisclaimerContent } from '../../styles';
import React from 'react';
import Text from '../../../../components/atoms/Text';
import { Typography } from '@mui/material';

interface IDisclaimerBox {
  onToggleClick: VoidFunction;
  isMobile?: boolean;
}

export const DisclaimerBox = ({ onToggleClick, isMobile }: IDisclaimerBox) => {
  return (
    <DisclaimerContent isMobile={isMobile}>
      <Typography variant="h5" paragraph={true}>
        Disclaimer
      </Typography>
      <Typography variant="body3" paragraph={true}>
        This product is an alpha release and is still in the early stages of
        development.
      </Typography>
      <Text
        variant="h5"
        style={{ cursor: 'pointer' }}
        color="#118CFF"
        onClick={onToggleClick}
      >
        See More
      </Text>
    </DisclaimerContent>
  );
};
