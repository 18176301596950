import {
  POSITIONTYPECIRCLEATORBREADCRUMB,
} from './circleatorTypes';

export function reducerModifierPositionActionCircleatorBreadcrumb(data: any) {
  return { type: POSITIONTYPECIRCLEATORBREADCRUMB, data };
}

export function changePositionActionCircleatorBreadcrumb(payload: any) {
  return (dispatch: any, getState: any) => {
    const position = getState().circleator.position;
    switch (payload) {
      case 'back':
        if (position) {
          dispatch(reducerModifierPositionActionCircleatorBreadcrumb(null));
        }
        break;
      case 'composer':
        if (position === null || position == 'logger' || position == 'print' || position == 'businessDrives') {
          dispatch(reducerModifierPositionActionCircleatorBreadcrumb('composer'));
        }
        break;
      case 'logger':
        if (position === 'composer' || position == null || position == 'logger' || position == 'print' || position == 'businessDrives') {
          dispatch(reducerModifierPositionActionCircleatorBreadcrumb('logger'));
        }
        break;
      case 'print':
        if (position == null || position == 'logger' || position == 'print' || position == 'composer' || position == 'businessDrives') {
          dispatch(reducerModifierPositionActionCircleatorBreadcrumb('print'));
        }
        break;
      case 'businessDrives':
        dispatch(reducerModifierPositionActionCircleatorBreadcrumb('businessDrives'));
        break;
      default:
        console.error(`NOT YET IMPLEMENTED`);
        break;
    }
  };
}