import {
  FetchingDataI,
  FetchInputFileFailedI,
  FetchInputFileSuccessI,
  FETCHING_INPUT_FILE_FAILED,
  FETCHING_INPUT_FILE_SUCCESS,
  FetchInputFileByCalculationIdFailedI,
  FetchInputFileByCalculationIdI,
  FetchInputFileByCalculationIdSuccessI,
  RemoveErrorMessageI,
  SetErrorMessageI,
  ShowHideLoggerModalActionI,
  SHOW_HIDE_LOGGER_MODAL_ACTION,
  FETCHING_DATA,
  CLEAR_ALL_INPUTS,
  ClearAllInputsI,
  CLEAR_INPUTS_AT_PAGE,
  ClearInputsAtPageI,
  SET_LOGGER_FORM_VALUES,
  SetLoggerFormValuesI,
  SET_SELECTED_PAGE,
  SetSelectedPageI,
  refreshInputFileI,
  REFRESH_INPUT_FILE,
  loggerActionLoadingI,
  LOGGER_ACTION_LOADING,
  logoUploadingI,
  UPLOAD_LOGO,
  FORM_STATE_ERROR,
  FormStateErrorI,
  SAVING_INPUT_FILES,
  SavingInputFileI,
  SAVING_TABLE,
  SavingTableI,
  TABLE_STATE,
  TableStateI,
  RENDERING_LATEX,
  RenderingLatexI,
  LOGGER_ERROR_FORM,
  LoggerErrorFormI,
  LoggerVariableInputI,
  LOGGER_VARIABLE_INPUT
} from "../types/loggerTypes";

export interface LogerStateI {
  isLoading: boolean;
  isModalActionVisible: boolean;
  isModalInfoVisible: boolean;
  errorMessage: string;
  messageInfo: string;
  modalActionType: string;
  isModalLoading: boolean;
  currentInputFile: { [key: string]: Record<string, unknown> };
  loggerFormValues: Array<any>,
  savingInputFile: boolean;
  selectedPage: number;
  actionLoading: boolean;
  actionMessage: string;
  logoUploading: { [key: string]: any };
  formStateError: Array<any>;
  isEditFormValues: boolean;
  savingTable: Array<any>;
  tableState: Array<any>;
  renderingLatex: Array<any>;
  loggerErrorForm: Array<any>;
  loggerVariableInput: { [key: string]: any };
}

const LogerState: LogerStateI = {
  isLoading: false,
  isModalActionVisible: false,
  isModalInfoVisible: false,
  errorMessage: '',
  messageInfo: "",
  modalActionType: "",
  currentInputFile: {},
  isModalLoading: false,
  loggerFormValues: [],
  savingInputFile: false,
  selectedPage: 0,
  actionLoading: false,
  actionMessage: "",
  logoUploading: {
    uploadLoading: "",
    logoType: ""
  },
  formStateError: [],
  isEditFormValues: false,
  savingTable: [],
  tableState: [],
  renderingLatex: [],
  loggerErrorForm: [],
  loggerVariableInput: {
    totalVariable: 0,
    totalInput: 0,
    isComplete: false,
    error: 0
  }
};

type LoggerAction = ShowHideLoggerModalActionI |
  FetchInputFileByCalculationIdI |
  FetchInputFileByCalculationIdSuccessI |
  FetchInputFileByCalculationIdFailedI |
  SetErrorMessageI |
  RemoveErrorMessageI |
  FetchInputFileFailedI |
  FetchInputFileSuccessI |
  FetchingDataI |
  SetLoggerFormValuesI |
  ClearAllInputsI |
  ClearInputsAtPageI |
  SetSelectedPageI |
  refreshInputFileI |
  loggerActionLoadingI |
  logoUploadingI | FormStateErrorI | SavingInputFileI | SavingTableI | TableStateI | RenderingLatexI | LoggerErrorFormI | LoggerVariableInputI;


const loggerReducer = (state: LogerStateI = LogerState, action: LoggerAction) => {
  switch (action.type) {
    case FETCHING_DATA:
      return {
        ...state,
        isLoading: true
      };
    case FETCHING_INPUT_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentInputFile: action.payload,
        savingTable: [],
        tableState: [],
        loggerErrorForm: [],
        renderingLatex: []
      };
    case FETCHING_INPUT_FILE_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error
      };
    case SHOW_HIDE_LOGGER_MODAL_ACTION:
      return {
        ...state,
        isModalActionVisible: action.isVisible,
        modalActionType: action.modalActionType
      };
    case CLEAR_ALL_INPUTS:
      return {
        ...state,
        currentInputFile: action.clearedInputFile
      };
    case CLEAR_INPUTS_AT_PAGE:
      return {
        ...state,
        currentInputFile: action.clearedInputFile
      };
    case SET_LOGGER_FORM_VALUES:
      return {
        ...state,
        loggerFormValues: action.payload,
        isEditFormValues: true
      };
    case SET_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.pageIndex
      };
    case LOGGER_ACTION_LOADING:
      return {
        ...state,
        actionLoading: action.payload,
        actionMessage: action.payload === true ? "Saving..." : "All changes saved"
      };
    case REFRESH_INPUT_FILE:
      return {
        ...state,
        currentInputFile: action.payload
      };
    case UPLOAD_LOGO:
      return {
        ...state,
        logoUploading: {
          uploadLoading: action.payload.uploadLoading,
          logoType: action.payload.logoType
        },
      };
    case FORM_STATE_ERROR:
      return {
        ...state,
        formStateError: action.payload
      };
    case SAVING_INPUT_FILES:
      return {
        ...state,
        savingInputFile: action.payload,
        isEditFormValue: false,
      };
    case SAVING_TABLE:
      let existedTableData = state.savingTable.find((item: any) => item.columnId === action.payload.columnId);
      if (existedTableData) {
        existedTableData = action.payload;
        return {
          ...state,
          savingTable: [...state.savingTable.filter((el: any) => el.columnId !== existedTableData.columnId), existedTableData]
        };
      } else {
        return {
          ...state,
          savingTable: [...state.savingTable, action.payload]
        };
      }

    case TABLE_STATE:
      let existedTableState = state.tableState.find((item: any) => item.columnId === action.payload.columnId);
      if (existedTableState) {
        existedTableState = action.payload;
        return {
          ...state,
          tableState: [...state.tableState.filter((el: any) => el.columnId !== existedTableState.columnId), existedTableState]
        };
      } else {
        return {
          ...state,
          tableState: [...state.tableState, action.payload]
        };
      }

    case RENDERING_LATEX:
      return {
        ...state,
        renderingLatex: action.payload
      };

    case LOGGER_VARIABLE_INPUT:
      return {
        ...state,
        loggerVariableInput: {
          totalVariable: action.payload.totalVariable,
          totalInput: action.payload.totalInput,
          isComplete: action.payload.totalVariable > 0 && action.payload.totalVariable === action.payload.totalInput ? true : false,
          error: action.payload.error
        }
      };

    case LOGGER_ERROR_FORM:
      let existedErrorForm = state.loggerErrorForm.find((item: any) => item.pageIndex === action.payload[action.payload.length - 1].pageIndex);
      if (existedErrorForm) {
        existedErrorForm = action.payload;
        return {
          ...state,
          loggerErrorForm: [...state.loggerErrorForm.filter((el: any) => el.pageIndex !== existedErrorForm[action.payload.length - 1].pageIndex), ...existedErrorForm]
        };
      } else {
        return {
          ...state,
          loggerErrorForm: [...state.loggerErrorForm, ...action.payload]
        };
      }

    default:
      return state;
  }
};

export default loggerReducer;
