import { useHistory } from 'react-router-dom';
import { ListItemText } from '@mui/material';
import { AnyStyledComponent } from 'styled-components';
import { useDispatch } from 'react-redux';

type childrenPropsI = {
  children: string;
  activeItem: any;
};

export const ListItemTextBilling = ({
  children,
  activeItem
}: childrenPropsI) => {
  const history = useHistory();

  const handleBillingPage = () => {
    history.push(`/calculations/billingDetails/${activeItem.id}`);
  };

  return (
    <ListItemText onClick={() => handleBillingPage()}>{children}</ListItemText>
  );
};
