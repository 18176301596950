import { ChevronLeft } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import { HorizontalDiv, MainContent } from './elements';
import images from '../../../assets';

interface PropsI {
  locations: { id: string; name: string }[];
  isFromModal?: boolean;
  pathName?: string;
  onClick?: () => void;
}
export const BreadCrumbs = ({
  locations,
  isFromModal,
  pathName,
  onClick = () => { }
}: PropsI) => {
  const history = useHistory();
  const location: Record<string, any> = useLocation();
  const inputPath = location.pathname.includes('input-file');
  const reportPath = location.pathname.includes('report');
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';


  const handleOnclick = () => {
    if (inputPath || reportPath) {
      if (isDriveCalculation) {
        return history.push({
          pathname: `/calculations/businessDrives/${location.state.driveId}`,
          state: {
            driveId: location.state.driveId
          }
        });
      } else {
        return history.push(pathName || '/calculations/my-calculation');
      }
    }
    if (!isFromModal) {
      history.push('/calculations/my-calculation');
    }
    if (isFromModal) {
      onClick();
    }
  };
  return (
    <HorizontalDiv>
      <MainContent>
        <ChevronLeft onClick={handleOnclick} />
      </MainContent>
      {locations.map((location, i) => (
        <div key={i}>
          {i > 0 && <img src={images.calcSub} alt="subtract" />}
          <p style={{ textTransform: 'capitalize' }}>{location.name}</p>
        </div>
      ))}
    </HorizontalDiv>
  );
};
