import {
  InfoPanelActionSection,
  InfoPanelDetailSection,
  InfoPanelHeadline,
  InfoPanelVersions
} from '../molecules/infoPanel/components';
import {
  checkPath,
  checkSubscriptionAndDate
} from '../../utils/helpersFunction';
import { getActorName, getDate, getValue } from '../molecules/infoPanel/utils';
import {
  getUserProfile,
  getBusinessDriveRole,
  setPreventChangesCalculation
} from '../../utils/storage';
import { useEffect, useState } from 'react';

import Dropdown from '../molecules/DropDown';
import { InfoPanelWrapper } from '../molecules/infoPanel/elements';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import DriveService from '../../service/DriveService';
import { toastSetupActionPopUp } from '../../store/appState/popUp/toast/toastAction';
import { CheckAccessI } from '../../pages/businessDrives/businessDrives.types';

type StateShow = {
  [key: string]: boolean;
};

interface IActiveItem {
  createdAt: string;
  createdBy: { id: string; name: string };
  description: string;
  id: string;
  isActive: boolean;
  isComplete: boolean;
  itemType: string;
  numberOfApproval: number;
  numberOfInputFiles: number;
  numberOfPages: number;
  numberOfVariables: number;
  status: string;
  title: string;
  updatedAt: string;
  updatedBy: { id: string; name: string };
  fromCommunity: boolean;
}

interface IWebSocketMessage {
  calculationId: string;
  eventType: string;
  userName: string;
  userId: string;
}

interface IProps {
  activeItem: IActiveItem;
  calInfo: any;
  personalWorkspaceId: string;
  enterpriseWorkspaceId: string;
  communityWorkspaceId: string;
  handleModal: (type: string, payload?: any) => void;
  calculationDetail: any;
  location: any;
  history: any;
  clearRecentInputFile: () => void;
  userLimit: Record<string, unknown>;
  subscription: Array<any>;
  webSocketMessageHistory: Array<IWebSocketMessage>;
  pathName?: string;
}

export default function CalculationInfoPanel({
  calInfo,
  personalWorkspaceId,
  enterpriseWorkspaceId,
  communityWorkspaceId,
  handleModal,
  calculationDetail,
  location,
  history,
  activeItem,
  clearRecentInputFile,
  userLimit,
  subscription,
  webSocketMessageHistory,
  pathName
}: IProps) {
  const { calId, calName, calDescription } = calInfo;
  const { dataCalculation } = calculationDetail;
  const dispatch = useDispatch();
  useEffect(() => {
    if (calculationDetail & calculationDetail.dataCalculation) {
      setVersionValue(dataCalculation.version.length - 1);
    }
  }, [calculationDetail, dataCalculation]);
  const { folderId } = useParams<{ folderId: string }>();

  const [isDetailShow, setIsDetailShow] = useState<StateShow>({
    openAction: true,
    openData: true,
    openOrganize: true,
    openDetail: true
  });
  const [versionValue, setVersionValue] = useState<any | null>(null);

  const isPersonalCalculation = checkPath(location.pathname, 'my-calculation');
  const isDriveCalculation = checkPath(location.pathname, 'businessDrives');
  const isManageCalc: boolean = /(OWNER|CONTENT_MANAGER|CONTRIBUTOR)/.test(
    getBusinessDriveRole()
  );

  const userRole = getUserProfile();
  const handleToggleDetailShow = (name: string) => () => {
    setIsDetailShow(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const checkNotChecker =
    /REKAVA_ADMIN|COMPOSER_ADMIN|COMPOSER|PRINCIPAL|LOGGER/.test(
      String(userRole)
    );

  const handleEditCalculation = async () => {
    const id = folderId ? folderId : personalWorkspaceId;
    let checkEditCalculation;

    if (isDriveCalculation) {
      const result: CheckAccessI = await DriveService.checkAccess(calId);
      if (!result.isAllowed) {
        return dispatch(
          toastSetupActionPopUp(
            'ERROR_SECONDARY',
            'You don’t have permission to edit this calculation'
          )
        );
      }
      checkEditCalculation = webSocketMessageHistory.some(
        (e: any) => e.calculationId === calId
      );
    }

    if (checkEditCalculation) {
      dispatch(
        formModalSetupActionPopUp('EDIT_CALCULATION_BUSINESS_ON_EDIT', {
          id,
          history
        })
      );
      return;
    }
    setPreventChangesCalculation({
      calculationId: calId,
      status: true
    });

    if (isDriveCalculation) {
      history.push({
        pathname: `/composer/${calId}/${id}`,
        state: {
          calculationType: 'BUSINESS',
          ...(location.state?.driveId && { driveId: location.state.driveId })
        }
      });
    } else {
      window.open(`/composer/${calId}/${id}`, `_blank`);
    }
  };

  const handleStartCalculation = async () => {
    const id =
      isPersonalCalculation || isDriveCalculation ? calId : dataCalculation.id;
    const idFolder = folderId ? folderId : personalWorkspaceId;
    try {
      if (!activeItem.isComplete) {
        const permission: { isAllowed: boolean } =
          await DriveService.checkAccess(calId);
        if (!permission.isAllowed) {
          return dispatch(
            formModalSetupActionPopUp('START_CALCULATION_ERROR', {
              ...(isDriveCalculation && { isDriveCalculation: true }),
              folderId: idFolder
            })
          );
        } else {
          history.push({
            pathname: `/composer/${calId}/${idFolder}`,
            state: {
              isStartCalculation: true,
              ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
              ...(location.state?.driveId && {
                driveId: location.state.driveId
              })
            }
          });
        }
      } else {
        await Promise.all([clearRecentInputFile()])
          .then(() => {
            history.push({
              pathname: `/logger/${id}`,
              state: {
                ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
                ...(location.state?.driveId && {
                  driveId: location.state.driveId
                })
              }
            });
          })
          .catch(error => console.error(error));
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleEditCalculationInfo = () => {
    if (isDriveCalculation) {
      handleModal('EDIT_CALCULATION_INFO_FROM_DRIVE', {
        folderId: folderId ? folderId : personalWorkspaceId
      });
    } else {
      handleModal('EDIT_CALCULATION_INFO_FROM_MYCALCULATION');
    }
  };

  const gotoReportManager = () => {
    history.push({
      pathname: `/report/${calId}`,
      state: {
        ...(isDriveCalculation && {
          calculationType: 'BUSINESS',
          pathName: pathName,
          ...(location.state?.driveId && {driveId: location.state.driveId})
        })
      }
    });
  };

  const gotoInputFileManager = () => {
    history.push({
      pathname: `/input-file/${
        isPersonalCalculation || isDriveCalculation ? calId : dataCalculation.id
      }`,
      state: {
        ...(isDriveCalculation && {
          calculationType: 'BUSINESS',
          pathName: pathName,
          ...(location.state?.driveId && {driveId: location.state.driveId})
        })
      }
    });
  };

  const handleCopyToMyCalc = () => {
    handleModal('COPY_TO_MYCALC_FROM_ENTERPRISE', {
      ...calInfo,
      data: calculationDetail,
      personalWorkspaceId: personalWorkspaceId
    });
  };

  const basicActions =
    isPersonalCalculation || isDriveCalculation
      ? [
          {
            color: 'primary',
            variant: 'contained',
            label: 'START CALCULATION',
            subLabel: 'Go to Logger View',
            onClick: handleStartCalculation,
            dataTestid: 'btnStartCalculation',
            disabled: false
          },
          {
            color: 'primary',
            variant: 'contained',
            label: 'EDIT CALCULATION',
            subLabel: 'Go to Composer View',
            onClick: handleEditCalculation,
            dataTestid: 'btnEditCalculation',
            disabled:
              isDriveCalculation && /VIEWER/.test(getBusinessDriveRole())
          }
          // {
          //   color: "secondary",
          //   variant: "contained",
          //   label: "RENAME",
          //   onClick: renameCalculationHandler,
          //   dataTestid: "btnRenameCalculation ",
          // },
          // {
          //   color: "error",
          //   variant: "contained",
          //   label: "DELETE",
          //   onClick: deleteCalculationHandler,
          //   dataTestid: "btnDeleteCalculation",
          // },
        ]
      : [
          {
            color: 'primary',
            variant: 'contained',
            label: 'START CALCULATION',
            subLabel: 'Go to Logger View',
            onClick: handleStartCalculation,
            dataTestid: 'btnStartCalculation'
          },
          {
            color: 'secondary',
            variant: 'contained',
            label: 'COPY TO MY CALC',
            subLabel: '',
            onClick: handleCopyToMyCalc,
            dataTestid: 'btnCopyToMyCalc'
          }
        ];

  const dataActions = [
    {
      color: 'secondary',
      variant: 'contained',
      label: 'SEE REPORTS',
      subLabel: '',
      onClick: gotoReportManager,
      dataTestid: 'btnSeeReportsCalculation'
    }
  ];
  if (checkNotChecker) {
    dataActions.unshift({
      color: 'secondary',
      variant: 'contained',
      label: 'SEE INPUT FILES',
      subLabel: '',
      onClick: gotoInputFileManager,
      dataTestid: 'btnSeeInputFilesCalculation'
    });
  }

  const detailInfo =
    isPersonalCalculation || isDriveCalculation
      ? [
          { key: 'Originator', value: [getValue(activeItem.createdBy.name)] },
          // { key: "Principal", value: [getValue("Jane Austin")] },
          // { key: "Used", value: [getValue(175)] },
          // { key: "Approval", value: [getValue(15)] },
          // { key: "Definitions", value: [getValue(26)] },
          // { key: "Tabs", value: [getValue(3)] },
          {
            key: 'Last Modified',
            value: [
              activeItem.updatedAt
                ? getDate(activeItem.updatedAt)
                : getDate(activeItem.createdAt),
              activeItem.updatedBy
                ? getActorName(activeItem.createdBy)
                : getActorName(activeItem.updatedBy)
            ]
          }
        ]
      : [
          {
            key: 'Originator',
            value: [getValue(dataCalculation?.createdBy?.name)]
          },
          // {
          //   key: "Principal",
          //   value: [getValue(dataCalculation?.principal?.name)],
          // },
          {
            key: 'Last Modified',
            value: [
              activeItem.updatedAt
                ? getDate(activeItem.updatedAt)
                : getDate(activeItem.createdAt),
              activeItem.updatedBy
                ? getActorName(activeItem.createdBy)
                : getActorName(activeItem.updatedBy)
            ]
          }
        ];

  const handleVersionChange = (selectedItem: any) => {
    setVersionValue(selectedItem);
  };

  return (
    <InfoPanelWrapper>
      {checkNotChecker && (
        <InfoPanelHeadline
          title="CALCULATION INFO"
          onEdit={handleEditCalculationInfo}
          name={
            isPersonalCalculation || isDriveCalculation
              ? calName
              : dataCalculation.title
          }
          description={
            isPersonalCalculation || isDriveCalculation
              ? calDescription
              : dataCalculation.description
          }
          isEditable={isPersonalCalculation || isManageCalc}
        />
      )}
      {!isPersonalCalculation && !isDriveCalculation && (
        <InfoPanelVersions title="VERSIONS">
          <Dropdown
            value={versionValue}
            dataSource={dataCalculation.version}
            labelValue={'id'}
            labelName={'versionName'}
            onClickItem={handleVersionChange}
            dataTestid="dropdownVersions"
          />
        </InfoPanelVersions>
      )}
      {checkNotChecker && (
        <InfoPanelActionSection
          expanded={isDetailShow.openAction}
          onToggle={handleToggleDetailShow('openAction')}
          title="Actions"
          actions={basicActions}
        />
      )}

      <InfoPanelActionSection
        expanded={isDetailShow.openData}
        onToggle={handleToggleDetailShow('openData')}
        title="Data"
        actions={dataActions}
      />

      {/* {checkNotChecker && checkNotLogger && (
        <InfoPanelActionSection
          expanded={isDetailShow.openOrganize}
          onToggle={handleToggleDetailShow("openOrganize")}
          title={isPersonalCalculation ? "Organize" : "Enterprise Tools"}
          actions={isPersonalCalculation ? organizeActions : enterpriseTools}
        />
      )} */}

      <InfoPanelDetailSection
        expanded={isDetailShow.openDetail}
        onToggle={handleToggleDetailShow('openDetail')}
        title="DETAILS"
        info={detailInfo}
      />
    </InfoPanelWrapper>
  );
}
