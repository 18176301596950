import React from "react";

function Calc() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      viewBox="0 0 54 54"
    >
      <rect
        width="50.667"
        height="50.667"
        x="1.667"
        y="1.667"
        fill="#FFE233"
        rx="9.333"
      ></rect>
      <path
        stroke="#030B42"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.333"
        d="M16.333 33.55h21.334M16.333 20.216h21.334"
      ></path>
      <rect
        width="50.667"
        height="50.667"
        x="1.667"
        y="1.667"
        stroke="#030B42"
        strokeWidth="2.667"
        rx="9.333"
      ></rect>
    </svg>
  );
}

export default Calc;