import { TextFieldProps } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { TextArea, useStyles } from '../../../../components/atoms/TextArea';

type ITextArea = {
  value: any;
  placeholder: string;
  register: UseFormRegister<any>;
} & TextFieldProps;

export const FormulaTextArea = ({
  value,
  placeholder,
  ...restProps
}: ITextArea) => {
  const classes = useStyles();
  return (
    <TextArea
      type="text"
      value={value}
      disabled={true}
      placeholder={placeholder}
      inputProps={{
        className: classes.textarea
      }}
      {...restProps}
    />
  );
};
