import { styled } from "@mui/material";

export const PageNavContainer = styled('div')({
    padding: "0 16px",
    display: "flex",
    flex: '0 0 100%',
    alignItems: "center",
    position: "relative",
    boxShadow: "none",
    marginBottom: "12px",
    
    "& > .arrowIcon": {
        position: "absolute",
        cursor: "pointer",
        ":active": {
            transform: "scale(0.8)",
        },
    },
    "& > .left": {
        left: 0,
    },
    "& > .right": {
        right: 0,
    },
});

export const PageButtonContainer = styled('div')({
    display: 'flex',
    gap: '0.5rem',
    overflow: 'hidden'
});