import styled from "styled-components";

interface Props {
  isActivated?: any
}

export const NavMenu = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
});

export const NavMenuList = styled.div<Props>((props) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  userSelect: 'none',
  '& > .navMenuList': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
  },
  '&:hover': {
    '& > .navMenuStrip': {
      display: 'block',
      borderBottom: '2px solid #118CFF'
    },
  },
  '& > .navMenuStripActivated': {
    borderBottom: '2px solid #118CFF'
  }
}));

// MAIN MENU

export const MainMenu = styled.div({
  width: '176px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
});

export const NormalMainMenu = styled.div({
  display: 'flex',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: '#E3F3FF'
  }
});

export const DividerMainMenu = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '2px solid #F0F2F5',
  marginTop: '4px',
  marginBottom: '4px',
});

export const RadioMainMenu = styled.div({
  display: 'flex',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    userSelect: 'none',
  }
});

export const ExtendMenu = styled.div<Props>((props) => ({
  display: 'flex',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: props.isActivated ? '#E3F3FF' : undefined,
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    userSelect: 'none',
  },
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: '#E3F3FF'
  }
}));

// NESTED MENU

export const NestedMenu = styled.div<Props>((props) => ({
  minWidth: '128px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
}));

