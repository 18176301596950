import * as React from "react";
import { SVGProps } from "react";

const SvgDecreaseDecimal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#decrease-decimal_svg__a)" fill="currentColor">
      <path d="M7.2 13.513c.022-.048.044-.094.063-.143a1.036 1.036 0 0 1 1.179-.658c.478.086.83.523.825 1.022-.005.503-.363.912-.875.999-.483.081-.957-.2-1.133-.672-.018-.05-.039-.098-.059-.146v-.402ZM18.246 8.582c0 .703.006 1.406-.001 2.109-.024 2.384-2.109 4.253-4.498 4.037-2.119-.19-3.742-1.893-3.78-4.008-.027-1.432-.03-2.866 0-4.298.045-2.166 1.728-3.808 3.73-3.995a4.126 4.126 0 0 1 4.496 3.427c.039.237.05.48.052.72.007.669.002 1.338.002 2.008h-.001Zm-6.203-.007c0 .73-.029 1.46.006 2.187.054 1.099.971 1.935 2.064 1.928 1.106-.008 2.032-.862 2.055-1.964.029-1.43.028-2.864 0-4.295-.021-1.096-.934-1.948-2.03-1.963-1.114-.015-2.034.81-2.088 1.92-.036.727-.007 1.457-.007 2.187ZM13.397 17.5h.642c1.031 0 2.063-.003 3.094 0 .483 0 .748.266.751.747a76.2 76.2 0 0 1 0 1.265c-.005.45-.283.725-.735.726-1.167.002-2.333 0-3.5 0h-.252c0 .309.002.607-.001.906 0 .08.002.164-.022.238-.074.232-.345.291-.563.124-.656-.503-1.31-1.01-1.964-1.515-.351-.27-.704-.54-1.053-.813-.259-.202-.258-.424.003-.626 1-.774 2-1.545 2.999-2.32.129-.1.263-.139.412-.068.145.068.19.196.19.349-.003.32-.001.64-.001.986Z" />
    </g>
    <defs>
      <clipPath id="decrease-decimal_svg__a">
        <path
          fill="#fff"
          transform="translate(2.4 2.4)"
          d="M0 0h20.371v19.2H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDecreaseDecimal;
