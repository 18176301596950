import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import SideBar from '../../components/molecules/sidebar';
import { CalculationContent } from '../../components/organisms/hoc/elements';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { handlefetchFAQ } from '../../store/actions/faqAction';
import { ReduxState } from '../../store';
import { LoadingSpinner } from '../../components/atoms/loading';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import HelperButton from '../../components/molecules/HelperButton';
import * as DOMPurify from 'dompurify';
import { getUserType } from '../../utils/storage';

const FaqPage = (props: any) => {
  const dispatch = useDispatch();
  const { isMinimize } = props;

  useEffect(() => {
    const unsubscribe = handleFetchResources();
    return unsubscribe;
  }, []);

  const handleFetchResources = () => {
    try {
      dispatch(handlefetchFAQ());
    } catch (error: any) {
      console.error(error);
    }
  };

  const { dataFaq, isLoading } = useSelector(
    (state: ReduxState) => state.faq,
    shallowEqual
  );

  // const DOMPurify = createDOMPurify(window);
  const htmlFAQ = DOMPurify.sanitize(dataFaq);

  return (
    <>
      <CalculationContent>
        <SideBar isMinimize={isMinimize} view={false} />
        <Divider orientation="vertical" flexItem />
        {isLoading && <LoadingSpinner />}
        <Box
          style={{
            overflow: 'auto',
            padding: '24px 412px 24px 36px'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: htmlFAQ.replace(/href/g, "target='_blank' href") }} />
        </Box>
      </CalculationContent>
      {!/ACE/.test(getUserType()) && <HelperButton />}
    </>
  );
};

export default calculationsNav(FaqPage);
