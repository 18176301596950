import { SqlCitationI } from '../askAce.type'
import { CitationDetail, CitationDetailButton, CitationFigureDetail } from '../styles';
import Icon from '../../../components/atoms/Icon';
import Text from '../../../components/atoms/Text';
import { ExternalLink } from '../../../assets/icons';
import { IconButton, Tooltip } from '@mui/material';
import HTMLContent from '../../../components/molecules/HTMLContent';
import { HCSS_URL } from '../../../utils/constant';

interface SqlCitationDetailsPropsI {
  dataSource: SqlCitationI | null,
  onClose: VoidFunction;
  onClickSqlCitation: (id: string) => void
}

export const SqlCitationDetails = ({ dataSource, onClose, onClickSqlCitation }: SqlCitationDetailsPropsI) => {
  if (!dataSource) return null;
  return (
    <>
      <CitationDetail isDrawing={false}>
        <CitationDetailButton onClick={onClose}>
          <Icon icon='times' />
        </CitationDetailButton>
        <Text variant='h5'>
          <span>
            HCSS
          </span>
          <Tooltip title={'Go to HCSS'} onClick={() => window.open(HCSS_URL, "_blank")}>
            <IconButton>
              <ExternalLink onClick={() => undefined} />
            </IconButton>
          </Tooltip>
        </Text>
        <CitationFigureDetail>
          <div id="message"><HTMLContent
            text={`These excerpts provide a concise summary of our comprehensive dataset from <b>${dataSource.tableNames.join(', ')}</b>`}
          /> </div>
          <div id="seeMore" onClick={() => onClickSqlCitation(dataSource?.id as string)}>See more details</div>
        </CitationFigureDetail>
      </CitationDetail>
    </>
  )
}
