import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

const getMappedVariant = (variant: any) => {
  switch (variant) {
    case "HEADING_1":
      return "h1";
    case "HEADING_2":
      return "h2";
    case "HEADING_3":
      return "h3";
    case "HEADING_5":
      return "h5";
    case "HEADING_6":
      return "h6";
    case "TITLE":
      return "subtitle1";
    case "SUBTITLE_2":
      return "subtitle2";
    case "SMALL_REGULAR":
      return "smallRegular";
    default:
      return "body2";
  }
};

const TypographyComponent = (props: any) => {
  return (
    <DefaultTextTypography
      variant={getMappedVariant(props.variant)}
      align={props?.align}
      style={props?.style}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
    >
      {props.children}
    </DefaultTextTypography>
  );
};

const DefaultTextTypography = withStyles({
  root: {
    padding: "4px 0",
  },
})(Typography);

export default TypographyComponent;
