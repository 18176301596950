import { styled } from "@mui/material/styles";
import List from "@mui/material/List";

const StyledList = styled(List)({
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#E3F3FF",
    "&, & .MuiListItemIcon-root": {
      color: "#118CFF"
    },
    "&, & .MuiListItemText-root": {
      color: "#118CFF",
    },
    "&, & .MuiTypography-root": {
      color: "#118CFF",
      fontWeight: 700
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: "unset"
  },
  "& .MuiListItemButton-root": {
    justifyContent: "center"
  },
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#E3F3FF",
    "&, & .MuiListItemIcon-root": {
      color: "inherit"
    },
  },
  "& .MuiListItemText-root": {
    lineHeight: 24,
    letterSpacing: "0.44px",
    fontFamily: "Inter",
    marginLeft: "16px"
  },
});

export default StyledList;
