export interface ChatMessageI {
  id: number;
  isAce: boolean;
  isAsk: boolean;
  message: string;
  sources: Array<AceSourcesI>;
  certainty: null | string;
  downvote: boolean;
  upvote: boolean;
  classification: string;
  chatId: string;
}

export interface AceResponseI {
  answer: string;
  question: string;
  sources: Array<AceSourcesI>;
  certainty: null | string;
  downvote: boolean;
  upvote: boolean;
  classification: string;
  id: string;
}

export interface AceSourcesI {
  source: string;
  excerpts: Array<AceExcerptsI>;
}

export interface AceExcerptsI {
  text: string;
  page: number;
  figure: string;
  table: string;
}

export interface ExcerptsExpandStateI {
  id: string;
  isExpand: boolean;
}

export interface AceInfoPanelPropsI {
  chatHistories: AceResponseI[]
};

export interface HeaderToolbarPropsI {
  textToCopy: string;
  onFeedback: (type: string) => void;
  confidenceLevel?: number
  upvote?: boolean
  downvote?: boolean
}

export const initialChat: ChatMessageI = {
  id: 1,
  chatId: '',
  isAce: true,
  isAsk: false,
  message:
    '👋 Hello! My name is Ace, I’m an AI that can answer engineering questions, explain things, suggest calculation, summarise with text, LaTeX, table and image for you. I’m powered by artificial intelligence and natural language processing, allowing you to have human-like conversations with me. I am constantly learning from huge amounts of books and literatures. My AI is always improving and I will often share sources for my answers.',
  sources: [],
  certainty: null,
  upvote: false,
  downvote: false,
  classification: ''
};

export interface ChatSessionI {
  id: string;
  title: string;
};

export enum CitationType { TEXT = "TEXT", FIGURE = "FIGURE" }
export interface AceCitationI {
  text: string
  subSection: string
  source: string
  sourceYear: number,
  url: string,
  type: CitationType,
  figureNumber: string | null,
  figureDescription: string | null
}

export interface SqlCitationI {
  id?: string;
  tableNames: string[];
  columnNames: string[];
  data: string[][];
}