import { MutableRefObject } from 'react';
import { FormulaTable } from '../../../../components/molecules/FormulaTable';
import { expandTableDefaultCellStyle } from '../../../../utils/constant';
import { generateSpreadsheetData } from '../../utils';
import { HiddenDiv } from './styles';
import { HiddenTableI } from './types';

const HiddenTable = ({ activePage, dataSources, tableRef }: HiddenTableI) => {
  return (
    <HiddenDiv>
      {dataSources.map((pages: Record<string, any>, pageIdx: number) => {
        if (pageIdx !== activePage) {
          return pages?.components?.map((component: Record<string, any>) => {
            const { type, columnId, properties } = component;
            if (type === 'TABLE') {
              const { cells, displayedContent, freeze, headers, tableName } =
                properties;
              const data = generateSpreadsheetData(
                cells,
                displayedContent,
                freeze,
                true,
                headers
              );

              return (
                <FormulaTable
                  key={`hidden_table_${columnId}`}
                  ref={(el: MutableRefObject<HTMLElement>) =>
                    (tableRef.current[columnId] = el)
                  }
                  name={
                    tableName
                      ? `hidden-table_${tableName.split(' ').join('')}`
                      : ''
                  }
                  data={data.data ?? []}
                  columnSetting={headers ?? []}
                  dimensions={[
                    data.data ? data.data[0].length : 0,
                    data.data ? data.data.length : 0
                  ]}
                  cellStyle={expandTableDefaultCellStyle}
                  displayedContent={
                    data.displayedContent ? data.displayedContent : undefined
                  }
                  contextMenu={() => {
                    return false;
                  }}
                  hideHiddenCells={true}
                  nonEditable={true}
                  stickyColumn={data.freeze?.column}
                  stickyRow={data.freeze?.row}
                />
              );
            }
          });
        }
      })}
    </HiddenDiv>
  );
};

export default HiddenTable;
