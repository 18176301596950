import { CstParser } from "chevrotain";
import { TokenTypeDict } from "./notation.lexer";

export class NotationParser extends CstParser {
    tokens: TokenTypeDict;

    constructor(dict: TokenTypeDict) {
        super(dict, {
            maxLookahead: 1
        });
        this.tokens = dict;
        this.performSelfAnalysis();
    }

    expression = this.RULE("expression", () => {
        this.OR([
            { ALT: () => this.SUBRULE(this.stringClause) },
            { ALT: () => this.SUBRULE(this.superscriptClause) },
            { ALT: () => this.SUBRULE(this.subscriptClause) },
            { ALT: () => this.SUBRULE(this.symbolClause) }
        ]);
    });

    stringClause = this.RULE("stringClause", () => {
        this.CONSUME(this.tokens.String, { LABEL: 'lhs' });
        this.OPTION(() => this.SUBRULE2(this.expression, { LABEL: 'rhs' }));
    });

    superscriptClause = this.RULE("superscriptClause", () => {
        this.CONSUME(this.tokens.Superscript, { LABEL: 'lhs' });
        this.OPTION(() => this.SUBRULE2(this.expression, { LABEL: 'rhs' }));
    });

    subscriptClause = this.RULE("subscriptClause", () => {
        this.CONSUME(this.tokens.Subscript, { LABEL: 'lhs' });
        this.OPTION(() => this.SUBRULE2(this.expression, { LABEL: 'rhs' }));
    });

    symbolClause = this.RULE("symbolClause", () => {
        this.CONSUME(this.tokens.Symbol, { LABEL: 'lhs' });
        this.OPTION(() => this.SUBRULE2(this.expression, { LABEL: 'rhs' }));
    });

}