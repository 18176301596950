import axios from "./axiosInstance";

export default class GenericService {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  async getSubresource(
    subresource: number | string,
    resourceId: string
  ): Promise<any> {
    const res = await axios.get(`${this.url}/${subresource}/${resourceId}`);
    return res.data;
  }

  async getResource(resourceId: number | string): Promise<any> {
    const res = await axios.get(`${this.url}/${resourceId}`);
    return res.data;
  }

  async get(action?: string): Promise<any> {
    let res;

    if (action) {
      res = await axios.get(`${this.url}/${action}`);
    } else {
      res = await axios.get(`${this.url}`);
    }
    return res?.data;
  }

  async getQuery(query?: string): Promise<any> {
    const res = await axios.get(`${this.url}${query}`);

    return res.data;
  }

  async getInputFile(action?: string): Promise<any> {
    let res;
    if (action) {
      res = await axios.get(`${this.url}?${action}`);
    } else {
      res = await axios.get(`${this.url}`);
    }
    return res.data;
  }

  async post(payload: any, action?: string): Promise<any> {
    let res;
    if (action) {
      res = await axios.post(`${this.url}/${action}`, payload);
    } else {
      res = await axios.post(this.url, payload);
    }

    return res.data;
  }

  async postSubResource(
    resourceId: number | string,
    subresource: string,
    payload: any
  ): Promise<any> {
    const res = await axios.post(
      `${this.url}/${resourceId}/${subresource}`,
      payload
    );
    return res.data;
  }

  async postResource(resourceId: number | string, payload: any): Promise<any> {
    const res = await axios.post(`${this.url}/${resourceId}`, payload);
    return res.data;
  }

  async postSubResourceAction(
    resourceId: number | string,
    subresource: string,
    action: string,
    payload: any
  ): Promise<any> {
    const res = await axios.post(
      `${this.url}/${resourceId}/${subresource}/${action}`,
      payload
    );
    return res.data;
  }

  async put(id: number | string, payload: any): Promise<any> {
    let res;
    if (!!id) {
      res = await axios.put(`${this.url}/${id}`, payload);
    } else {
      res = await axios.put(`${this.url}`, payload);
    }
    return res.data;
  }

  async putAction(
    action: string,
    payload?: Record<string, string> | FormData | any
  ): Promise<any> {
    return axios.put(`${this.url}/${action}`, payload);
  }

  async putResource(
    id: string | number,
    resource: string,
    payload?: any
  ): Promise<any> {
    const res = await axios.put(`${this.url}/${id}/${resource}`, payload);
    return res.data;
  }

  async putSubResource(
    resourceId: number | string,
    subresource: string,
    action: string,
    payload: any
  ): Promise<any> {
    const res = await axios.put(
      `${this.url}/${resourceId}/${subresource}/${action}`,
      payload
    );
    return res.data;
  }

  async delete(
    id: number | string,
    subresource: string,
    payload: any
  ): Promise<any> {
    const res = await axios.delete(`${this.url}/${id}/${subresource}`, {
      data: payload,
    });
    return res.data;
  }

  async deleteSubResource(
    id: number | string,
    subresource: string,
    index: number | string
  ): Promise<any> {
    const res = await axios.delete(`${this.url}/${id}/${subresource}/${index}`);
    return res.data;
  }

  async deleteResource(resource: number | string, id: string): Promise<any> {
    const res = await axios.delete(`${this.url}/${resource}/${id}`);
    return res.data;
  }


  async deleteBulk(ids: any): Promise<any> {
    const res = await axios.delete(this.url, { data: ids });
    return res.data;
  }

  async getDataWithParams(params: any): Promise<any> {
    const res = await axios.get(this.url, { params: params });
    return res.data;
  }

  async createNewInput(params: any): Promise<any> {
    const res = await axios.post(this.url, params);
    return res.data;
  }

  async deleteWithoutParams(resource: string): Promise<any> {
    const res = await axios.delete(`${this.url}/${resource}`);
    return res.data;
  }

  async putReportAfterDownload(params: any, resource: any): Promise<any> {
    const res = await axios.put(`${this.url}/${params}/${resource}`);
    return res.data;
  }

  async deleteCalculation(params: any): Promise<any> {
    const res = await axios.delete(`${this.url}/${params}`);
    return res.data;
  }

  async getSearchCalculation(params: string, id: string): Promise<any> {
    const response = await axios.get(`${this.url}/search?q=${params}&folderId=${id}`);
    return response.data.data;
  }

  async getBillingDetails(id: string): Promise<any> {
    const response = await axios.get(`${this.url}/invoices?userId=${id}`);
    return response.data;
  }

  async changeBillingPaymentStatus(payload: any): Promise<any> {
    const response = await axios.put(`${this.url}/invoices/status`, {
      data: payload
    });
    return response.data;
  }

  async getBillingMemberEmail(email: string): Promise<any> {
    const response = await axios.get(`${this.url}/exists`, {
      params: {
        email: email
      }
    });
    return response.data;
  }

  async addBillingMember(email: string, parentId: string, plan: string, amount: number): Promise<any> {
    const response = await axios.post(`${this.url}/child`, {
      email: email,
      parentId: parentId,
      plan: plan,
      amount: amount
    });
    return response.data;
  }

  async getDataWithQuary(
    params: any,
    query: any,
    role: any,
    sort?: string
  ): Promise<any> {
    let res;
    if (sort) {
      res = await axios.get(
        `${this.url}?${sort}page=${params}&size=${query}&role=${role}`
      );
    } else {
      res = await axios.get(
        `${this.url}?page=${params}&size=${query}&role=${role}`
      );
    }

    return res.data;
  }

  async getSearchDataWithQuery(
    payload: any,
    params: any,
    query: any,
    role: any,
    sort: any
  ): Promise<any> {
    const res = await axios.get(
      `${this.url}?${sort}q=${payload}&page=${params}&size=${query}&role=${role}`
    );
    return res.data;
  }

  async putResetPassword(payload: any, password: any): Promise<any> {
    const res = await axios.put(`${this.url}/${payload}/${password}`);
    return res.data;
  }

  async deleteInputFile(
    id: number | string,
    subResource: string
  ): Promise<any> {
    const res = await axios.delete(`${this.url}/${id}/${subResource}`);
    return res.data;
  }

  async putCompletedTutorial(
    id: string | number,
  ): Promise<any> {
    const res = await axios.put(`${this.url}/${id}`);
    return res.data;
  }

  async getCountryCode(): Promise<any> {
    const res = await axios.get(`${this.url}`);
    return res.data;
  };
}
