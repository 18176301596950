import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../atoms/Icon';
import { InputToolbar } from '../InputToolbar';
import { IconMore, InputField, InputNumber } from './styles';

interface FormattedNumberPropsI extends React.HTMLAttributes<HTMLInputElement> {
  value: any;
  decimalPlaces?: number;
  percentage?: boolean;
  onChangeHandler?: any;
  disabled?: boolean;
  selectedCurrency?: string;
  onToolbarMenuSelected?: (e: any, item?: any, data?: any) => void;
  typeField?: string;
}

export const Number = ({
  value,
  decimalPlaces = 3,
  percentage,
  onChangeHandler,
  disabled = false,
  selectedCurrency,
  onToolbarMenuSelected,
  typeField,
  ...divProps
}: FormattedNumberPropsI) => {
  const [anchor, setAnchor] = useState<null | HTMLInputElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(value);
  const [decimals, setDecimals] = useState(decimalPlaces);
  const [isPercentage, setIsPercentage] = useState(percentage);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleClickIcon = () => {
    const { current: el } = ref;
    setAnchor(el);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleClickMenu = (e: any, item: string, data?: any) => {
    switch (item) {
      case 'increment':
        if (decimals <= 5) {
          const newValue = decimals + 1;
          setDecimals(newValue);
          setInputValue(value);
          if (onToolbarMenuSelected)
            onToolbarMenuSelected(e, 'decimal', newValue);
        }
        break;
      case 'decrement':
        if (decimals !== 0) {
          const newValue = decimals - 1;
          setDecimals(newValue);
          setInputValue(value);
          if (onToolbarMenuSelected)
            onToolbarMenuSelected(e, 'decimal', newValue);
        }
        break;
      case 'percentage':
        const newValue = !isPercentage;
        setIsPercentage(newValue);
        if (onToolbarMenuSelected)
          onToolbarMenuSelected(e, 'percentage', newValue);
        // onMenuSelected(e, item)
        break;
      case 'currency':
        if (onToolbarMenuSelected) onToolbarMenuSelected(e, item, data);
        break;
      default:
        break;
    }
  };

  const onChange = (values: any) => {
    setInputValue(values.floatValue);
  };

  const actions = [
    {
      type: 'default',
      title: 'increment',
      onClick: (e: React.SyntheticEvent, item?: any) =>
        handleClickMenu(e, item),
      icon: 'increaseDecimal'
    },
    {
      type: 'default',
      title: 'decrement',
      onClick: (e: React.SyntheticEvent, item?: any) =>
        handleClickMenu(e, item),
      icon: 'decreaseDecimal'
    },
    {
      type: 'default',
      title: 'percentage',
      onClick: (e: React.SyntheticEvent, item?: any) =>
        handleClickMenu(e, item),
      icon: 'percent'
    },
    {
      type: 'dropdown',
      title: 'currency',
      onClick: (e: React.SyntheticEvent, item?: any, data?: any) =>
        handleClickMenu(e, item, data),
      icon: 'dropdown'
    }
  ];

  return (
    <React.Fragment>
      <InputField onBlur={divProps.onBlur}>
        {
          <InputNumber
            typeField={typeField}
            value={inputValue}
            type="text"
            displayType="input"
            decimalScale={decimals}
            decimalSeparator="."
            fixedDecimalScale={true}
            suffix={isPercentage ? ' %' : ''}
            onValueChange={values => {
              onChange(values);
            }}
            placeholder={divProps?.placeholder}
            disabled={disabled ?? false}
          />
        }
        <IconMore onClick={handleClickIcon} ref={ref}>
          <Icon icon="moreVertical" />
        </IconMore>
      </InputField>
      <InputToolbar
        showCurrency={!disabled ?? true}
        anchor={anchor}
        onCloseMenu={handleCloseMenu}
        actions={actions}
        selectedCurrency={selectedCurrency}
      />
    </React.Fragment>
  );
};
