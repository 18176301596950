import api from "./api";

const URL = 'report';

const ReportService = {
  createReport: async (id: string): Promise<any> => {
    const response = await api.post(`${URL}`, {
      inputFileId: id
    });
    return response.data.data;
  },
  reportDownloaded: async (id: string): Promise<any> => {
    const response = await api.put(`${URL}/${id}/downloaded`);
    return response.data.data;
  }
};

export default ReportService;