import {
  BlankInput,
  BodyMainContent,
  ContentWrapper,
  Description,
  Header,
  Input,
  LatexSection,
  Notation,
  SubHeader,
  TableContent,
  Unit,
  Wrapper
} from './elements';
import {
  DateInput,
  DividerComp,
  DropdownInput,
  ImageDesc,
  ImageDiv,
  InputLeftMainTextLogger,
  RadioButtonLogger,
  TextLabel
} from '../../../pageElements/logger';
import { Divider, Tooltip, Typography, useTheme } from '@mui/material';
import {
  checkMultiLine,
  loggerPreviewFormulaConvert
} from '../../../utils/helpersFunction';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import Icon from '../../../components/atoms/Icon';
import Latex from '../../../components/molecules/latex';
import LatexVar from '../../../components/molecules/latex/latexVar';
import Text from '../../../components/atoms/Text';
import dayjs from 'dayjs';
import images from '../../../assets';
import parser from 'html-react-parser';
import {
  Expand,
  LatexItem
} from '../../../components/molecules/LatexFormula/element';
import { generateTableHeader } from '../../loggerRefactor/utils';
import { FormulaTable } from '../../../components/molecules/FormulaTable';
import { expandTableDefaultCellStyle } from '../../../utils/constant';
import { LinkComponent } from '../../loggerRefactor/components/Fields';
import TextField from '../../loggerRefactor/components/Fields/TextField';
import { useForm, Controller } from 'react-hook-form';
import { HookFormProps } from '../../loggerRefactor/logger.types';
import {
  DescriptionBoxField,
  FieldWrapper,
  InputBoxField
} from '../../loggerRefactor/components/Fields/styles';
import FormattedInput from '../../loggerRefactor/components/Fields/FormattedInput';
import RadioField from '../../loggerRefactor/components/Fields/RadioButtonField';
import DropdownField from '../../loggerRefactor/components/Fields/DropdownField';
import DateField from '../../loggerRefactor/components/Fields/DateField';

interface LoggerPreviewProps {
  data?: Array<any>;
  info: any;
  setLoggerPreview: (payload: any) => void;
  loggerPreviewImage: Array<any>;
}

export const LoggerPreview = ({
  data,
  info,
  setLoggerPreview,
  loggerPreviewImage
}: LoggerPreviewProps) => {
  const theme = useTheme();
  const latexRefs = useRef<Array<any>>([]);
  latexRefs.current = [];
  const [latexWrapper, setLatexWrapper] = useState<Array<any>>([]);
  const [newMappedImage, setNewMappedImage] = useState<{
    [key: string]: string;
  }>({});
  const loggerRef = useRef<HTMLDivElement>(null);

  const { control } = useForm<HookFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      formElements: data || []
    }
  });

  const addLatexRef = (el: any) => {
    if (el.element && !latexRefs.current.includes(el)) {
      latexRefs.current.push({
        ...el,
        multiLine: el.element.offsetWidth >= 520 ? true : false
      });
    }
  };
  const tableComponentRef = useRef<any>({});
  useEffect(() => {
    if (loggerPreviewImage.length > 0) {
      const mappedImage: any = {};
      loggerPreviewImage.forEach((element: any) => {
        mappedImage[element.columnId] = element.imageUrl;
      });
      setNewMappedImage(mappedImage);
    }
    setTimeout(() => {
      setLatexWrapper(latexRefs.current);
      setLoggerPreview(loggerRef.current);
    }, 50);
  }, [JSON.stringify(data), loggerPreviewImage]);

  const mapLevelToVariant: any = {
    TITLE: 'h3',
    HEADING_1: 'h4',
    HEADING_2: 'h5',
    HEADING_3: 'h6',
    NORMAL: 'body3'
  };

  const FIELDTYPE = {
    TEXT: 'TEXT',
    NUMERICAL: 'NUMERICAL',
    RADIO_BUTTON: 'RADIO_BUTTON',
    DROPDOWN: 'DROPDOWN',
    DATE: 'DATE'
  };

  const renderFieldComponent = (properties: any) => {
    const columnId = properties.id;
    switch (properties.fieldType) {
      case FIELDTYPE.TEXT:
        return (
          <div style={{ textAlign: 'left' }}>
            <TextField
              key={columnId}
              name={`formElements.${columnId}`}
              control={control}
              defaultValue={''}
              properties={properties}
              multiLine={true}
              resize={'none'}
            />
          </div>
        );
      case FIELDTYPE.NUMERICAL:
        return (
          <div style={{ textAlign: 'left' }}>
            <FieldWrapper key={columnId}>
              <DescriptionBoxField contentEditable={false}>
                <Typography variant="body3" component={'span'}>
                  {properties.description
                    ? parser(properties.description)
                    : properties.description}
                </Typography>
              </DescriptionBoxField>

              <InputBoxField>
                <Tooltip
                  title={properties.tooltip === null ? '' : properties.tooltip}
                >
                  <Controller
                    name={`formElements.1.components.${columnId}.value`}
                    control={control}
                    render={() => (
                      <FormattedInput.NumberField
                        value={''}
                        id="userInputNumber"
                        placeholder="Input value here"
                        properties={properties}
                        onChange={() => undefined}
                        onToolbarMenuSelected={() => undefined}
                        sessionName={`input_numerical__page_1__row_${columnId}__decimal_number`}
                      />
                    )}
                    rules={{
                      required: `${properties.description} is required`
                    }}
                  />
                </Tooltip>
              </InputBoxField>
            </FieldWrapper>
          </div>
        );
      case FIELDTYPE.RADIO_BUTTON:
        return (
          <div style={{ textAlign: 'left' }}>
            <RadioField
              key={columnId}
              name={`formElements.1.components.${columnId}.value`}
              control={control}
              defaultValue={''}
              properties={properties}
            />
          </div>
        );
      case FIELDTYPE.DROPDOWN:
        return (
          <div style={{ textAlign: 'left' }}>
            <DropdownField
              key={columnId}
              name={`formElements.1.components.${columnId}.value`}
              control={control}
              defaultValue={''}
              properties={properties}
            />
          </div>
        );
      case FIELDTYPE.DATE:
        return (
          <div style={{ textAlign: 'left' }}>
            <DateField
              key={columnId}
              name={`formElements.1.components.${columnId}.value`}
              control={control}
              defaultValue={''}
              properties={properties}
            />
          </div>
        );
    }
  };

  return (
    <>
      <Wrapper>
        <BodyMainContent ref={loggerRef}>
          <Header>
            <SubHeader>
              <Text variant="h4" style={{ overflowWrap: 'anywhere' }}>
                {info.title}
              </Text>
              <img src={images.textLogoGrey} alt="rekava" />
            </SubHeader>
            <Grid container>
              <Grid item xs={6}>
                <Grid container style={{ padding: '8px 0' }}>
                  <Grid item xs={5}>
                    <Text variant="body3" color="#A3B3C2">
                      Originator
                    </Text>
                  </Grid>
                  <Grid item xs={1}>
                    <Text variant="body3" color="#A3B3C2">
                      :
                    </Text>
                  </Grid>
                  <Grid item xs={6}>
                    <Text variant="body3">{info.createdBy}</Text>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container style={{ padding: '8px 0px 0px 0px' }}>
                  <Grid item xs={5}>
                    <Text variant="body3" color="#A3B3C2">
                      Date Created
                    </Text>
                  </Grid>
                  <Grid item xs={1}>
                    <Text variant="body3" color="#A3B3C2">
                      :
                    </Text>
                  </Grid>
                  <Grid item xs={6}>
                    <Text variant="body3">
                      {dayjs(info?.dateCreated).format('DD/MM/YYYY')}
                    </Text>
                  </Grid>
                </Grid>
                <Grid container style={{ padding: '2px 0' }}>
                  <Grid item xs={5}>
                    <Text variant="body3" color="#A3B3C2">
                      Date Printed
                    </Text>
                  </Grid>
                  <Grid item xs={1}>
                    <Text variant="body3" color="#A3B3C2">
                      :
                    </Text>
                  </Grid>
                  <Grid item xs={6}>
                    <Text variant="body3">{''}</Text>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Header>
          <DividerComp>
            <Divider />
          </DividerComp>

          {Array.isArray(data) &&
            data.map((item: any, i: number) => {
              const { description, notation, type, id } = item;
              const isTable = type === 'TABLE';
              const tableCells = isTable
                ? item.tableBody
                    .filter((item: any) => item[0].alignment)
                    .map((el: any) => el.filter((a: any) => a.alignment))
                : undefined;
              const tableHeaders = isTable
                ? generateTableHeader(
                    tableCells[0],
                    item.tableHeader
                      .filter(
                        (el: any, idx: number) => idx < tableCells[0]?.length
                      )
                      .map((a: any, idx: number) => {
                        return { ...a, colIdx: idx + 1 };
                      }),
                    item.freeze
                  )
                : undefined;
              return type === 'TEXT' ? (
                <div
                  key={`logger_preview_content_${i}`}
                  style={{ paddingTop: 4, paddingBottom: 4, textAlign: 'left' }}
                >
                  <Text variant={mapLevelToVariant[item.level]}>
                    {description ? parser(description) : description}
                  </Text>
                </div>
              ) : type === 'NUMERICAL_INPUT' || type === 'TEXT_INPUT' ? (
                <ContentWrapper key={`logger_preview_content_${id}`}>
                  <Description>
                    <Text variant="body3">
                      {description ? parser(description) : description}
                    </Text>
                  </Description>
                  <Notation>
                    <Text variant="body3">
                      {notation ? parser(notation) : notation}
                    </Text>
                  </Notation>
                  <Input
                    placeholder={
                      type.includes('TEXT')
                        ? 'Input text here'
                        : 'Input value here'
                    }
                    inputValueType={type}
                  />
                  <Unit>
                    <Text variant="body3">
                      {item?.unit ? parser(item?.unit) : item.unit}
                    </Text>
                  </Unit>
                </ContentWrapper>
              ) : type === 'DROPDOWN_INPUT' ? (
                <ContentWrapper key={`logger_preview_content_${id}`}>
                  <Description>
                    <Text variant="body3">
                      {description ? parser(description) : description}
                    </Text>
                  </Description>
                  <Notation>
                    <Text variant="body3">
                      {notation ? parser(notation) : notation}
                    </Text>
                  </Notation>
                  <DropdownInput style={{ color: '#353C68' }}>
                    <option value="" hidden>
                      Select Option
                    </option>
                  </DropdownInput>
                  <Unit />
                </ContentWrapper>
              ) : type === 'RADIO_BUTTON_INPUT' ? (
                <ContentWrapper column key={`logger_preview_content_${id}`}>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      paddingBottom: '4px'
                    }}
                  >
                    <Description>
                      <Text variant="body3">
                        {description ? parser(description) : description}
                      </Text>
                    </Description>
                    <Notation>
                      <Text variant="body3">
                        {notation ? parser(notation) : notation}
                      </Text>
                    </Notation>
                    <BlankInput />
                    <Unit />
                  </div>
                  <RadioButtonLogger>
                    {item.options?.map((field: any, idx: number) => (
                      <TextLabel
                        key={`logger_preview_radio_${idx}`}
                        style={{
                          padding: '4px 0',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Tooltip
                          title={item.tooltip === null ? '' : item.tooltip}
                        >
                          <div>
                            <input
                              type="radio"
                              style={{
                                height: '14px',
                                width: '14px',
                                marginRight: '4px'
                              }}
                              value={field}
                            />
                          </div>
                        </Tooltip>
                        <p>{field ? parser(field) : field}</p>
                      </TextLabel>
                    ))}
                  </RadioButtonLogger>
                </ContentWrapper>
              ) : type === 'DATE_INPUT' ? (
                <ContentWrapper key={`logger_preview_content_${id}`}>
                  <Description>
                    <Text variant="body3">
                      {description ? parser(description) : description}
                    </Text>
                  </Description>
                  <Notation>
                    <Text variant="body3">
                      {notation ? parser(notation) : notation}
                    </Text>
                  </Notation>
                  <DateInput type="date" />
                  <Unit />
                </ContentWrapper>
              ) : type === 'FORMULA' ? (
                <ContentWrapper column key={`logger_preview_content_${id}`}>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      paddingBottom: '4px',
                      alignItems: 'center'
                    }}
                  >
                    <Description>
                      <Text variant="body3">
                        {description ? parser(description) : description}
                      </Text>
                    </Description>
                    <Notation>
                      <Text variant="body3">
                        {notation ? parser(notation) : notation}
                      </Text>
                    </Notation>
                    <InputLeftMainTextLogger
                      placeholder="Calculated output"
                      disabled={true}
                      style={{
                        backgroundColor: '#dddd'
                      }}
                    />
                    <Unit>
                      <Text variant="body3">
                        {item?.unit ? parser(item?.unit) : item.unit}
                      </Text>
                    </Unit>
                  </div>
                  {!item.formula?.includes('IF') && (
                    <LatexSection>
                      <Expand
                        expandFormula={
                          item?.formula?.length > 1 &&
                          !item?.formula?.includes('VLOOKUP') &&
                          !item?.formula?.includes('HLOOKUP') &&
                          !item?.formula?.includes('INDEX')
                            ? true
                            : false
                        }
                      >
                        {item?.formula?.length > 1 &&
                          !item?.formula?.includes('VLOOKUP') &&
                          !item?.formula?.includes('HLOOKUP') &&
                          !item?.formula?.includes('INDEX') && (
                            <Icon
                              icon={'arrowUp'}
                              color={theme.customText.default}
                            />
                          )}
                        <Icon icon="fx" color={theme.customText.default} />
                      </Expand>
                      {item?.formula?.length > 1 && (
                        <div style={{ paddingTop: 4 }}>
                          {item?.formula?.includes('IF') ? (
                            <LatexItem>
                              <LatexVar formula={item.notation + '='} />
                              <LatexVar formula={item.formula} />
                              {item.unit && (
                                <div id="latex">
                                  <LatexVar formula={item.unit} />
                                </div>
                              )}
                            </LatexItem>
                          ) : (
                            <>
                              <LatexItem
                                multiLine={checkMultiLine(latexWrapper, id)}
                                ref={(el: any) =>
                                  addLatexRef({ element: el, columnId: id })
                                }
                              >
                                <LatexVar formula={item.notation + '='} />{' '}
                                {item.formula?.includes('IF') ? (
                                  <LatexVar
                                    formula={item.formula.substring(1)}
                                  />
                                ) : (
                                  <Latex
                                    formula={loggerPreviewFormulaConvert(
                                      data,
                                      item.formula
                                    )}
                                    tableName={'table X'}
                                  />
                                )}{' '}
                                {item?.formula?.includes('MOD') && (
                                  <LatexVar formula={'=' + item.notation} />
                                )}
                              </LatexItem>
                            </>
                          )}
                        </div>
                      )}
                    </LatexSection>
                  )}
                </ContentWrapper>
              ) : type === 'DIVIDER' ? (
                <DividerComp key={`logger_preview_content_${id}`}>
                  <Divider />
                </DividerComp>
              ) : type === 'IMAGE' && item.imageUrl ? (
                <ImageDiv
                  justify={item.imageAlignment}
                  key={`logger_preview_content_${id}`}
                >
                  <div>
                    <img
                      src={newMappedImage[id] ?? item.imageUrl}
                      alt="image"
                      style={{
                        width: item?.imageWidth
                          ? item?.imageWidth + 'px'
                          : '400px',
                        height: item?.imageHeight
                          ? item?.imageHeight + 'px'
                          : '264px'
                      }}
                    />
                    <ImageDesc>
                      <Text variant="body3">
                        {description ? parser(description) : description}
                      </Text>
                    </ImageDesc>
                  </div>
                </ImageDiv>
              ) : type === 'TABLE' ? (
                <ContentWrapper key={`logger_preview_content_${id}`} column>
                  <div style={{ alignSelf: 'flex-start' }}>
                    <Description>
                      <Text variant="body3">
                        {`${item.tableName} ${
                          description ? parser(description) : description
                        }`}
                      </Text>
                    </Description>
                    <TableContent>
                      <FormulaTable
                        ref={(el: MutableRefObject<HTMLElement>) =>
                          (tableComponentRef.current[id] = el)
                        }
                        name={
                          item?.tableName?.split(' ').join('') + 'preview' ?? ''
                        }
                        data={tableCells}
                        dimensions={[tableCells[0]?.length, tableCells?.length]}
                        cellStyle={expandTableDefaultCellStyle}
                        columnSetting={tableHeaders}
                        hideHiddenCells={true}
                        displayedContent={item.displayedContent}
                        stickyColumn={item.freeze?.column}
                        stickyRow={item.freeze?.row}
                        contextMenu={() => {
                          return false;
                        }}
                      />
                    </TableContent>
                  </div>
                </ContentWrapper>
              ) : type === 'LINK' ? (
                <ContentWrapper key={`logger_preview_content_${id}`} column>
                  <div style={{ alignSelf: 'flex-start', textAlign: 'left' }}>
                    <LinkComponent
                      properties={{ ...item, description: item.title }}
                      handleOpenNewTab={() => undefined}
                    />
                  </div>
                </ContentWrapper>
              ) : type === 'FIELD' ? (
                renderFieldComponent(item)
              ) : null;
            })}
        </BodyMainContent>
      </Wrapper>
    </>
  );
};
