import { FormControl, MenuItem, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  dataCurrency,
  mapCurrencyToSymbol
} from '../../../utils/helpersFunction';
import Icon from '../../atoms/Icon';
import Input from '../../atoms/Input';
import Text from '../../atoms/Text';
import ComboBox from '../ComboBox';
import { HorizontalList } from './styles';

type ToolbarProps = {
  anchor: HTMLDivElement | null;
  actions: any[];
  onCloseMenu: () => void;
  selectedCurrency?: string | null;
  showCurrency?: boolean;
};

export const InputToolbar = ({
  selectedCurrency,
  anchor,
  actions,
  onCloseMenu,
  showCurrency
}: ToolbarProps) => {
  const [currency, setCurrency] = useState<any | null>(selectedCurrency);

  useEffect(() => {
    const unsubscribe = setCurrency(selectedCurrency);
    return unsubscribe;
  }, [setCurrency, selectedCurrency]);

  return (
    <HorizontalList
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onCloseMenu}
    >
      {actions.map((action: any, index: number) => {
        return action.type === 'default' ? (
          <Tooltip
            title={action.title}
            key={`toolbar-menu-${action.title}-${index}`}
          >
            <MenuItem onClick={e => action.onClick(e, action.title)}>
              <Icon icon={action.icon} />
            </MenuItem>
          </Tooltip>
        ) : showCurrency ? (
          <FormControl key={`currency-dropdown-menu-${action.title}-${index}`}>
            <ComboBox
              value={dataCurrency.includes(currency) ? currency : null}
              onChange={(e: any, newValue: any | null) => {
                action.onClick(e, action.title, newValue);
              }}
              options={dataCurrency}
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    component="li"
                    sx={{ '& div > svg': { mr: 1.25, flexShrink: 0 } }}
                    {...props}
                  >
                    <Icon
                      icon={
                        mapCurrencyToSymbol[
                          option as keyof typeof mapCurrencyToSymbol
                        ]
                      }
                    />
                    <Text variant="body3" sx={{ marginLeft: 1 }}>
                      {option}
                    </Text>
                  </MenuItem>
                );
              }}
              renderInput={params => (
                <Input
                  {...params}
                  size="small"
                  placeholder="Select country"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Icon
                        icon={
                          mapCurrencyToSymbol[
                            currency as keyof typeof mapCurrencyToSymbol
                          ]
                        }
                      />
                    )
                  }}
                />
              )}
              sx={{ width: 130 }}
            />
          </FormControl>
        ) : null;
      })}
    </HorizontalList>
  );
};
