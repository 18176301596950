import { Dialog, Menu, Slide, useTheme } from "@mui/material";
import { List, RightContent, UserAvatar } from "../../../../components/molecules/navbar/elements";
import { MobileInfoPanelWrapper } from "../../styles";
import React, { useState } from "react";
import Icon from "../../../../components/atoms/Icon";
import TagManagerService from "../../../../service/tagManagerService";
import Text from "../../../../components/atoms/Text";
import { TransitionProps } from "@mui/material/transitions";
import { clearStorage } from "../../../../utils/storage";
import images from "../../../../assets";
import { menuStyle } from "../../../../utils/theme";
import { useHistory } from "react-router-dom";
import { setSelectedTreeItem } from "../../../../store/appState/sidebar/action";
import { useDispatch } from "react-redux";
import { RightSectionWrapper } from "./RightSectionWrapper";

interface MobileSidebarProps {
  isOpen: boolean;
  handleClose: () => void;
  headerTitle: string;
  userPhoto: string;
  isConsent?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide style={{ width: "100%" }} direction="left" ref={ref} {...props} />
  );
});

export const MobileInfoPanel = ({ isOpen, handleClose, headerTitle, userPhoto }: MobileSidebarProps) => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [contextMenu, setContextMenu] = useState<any | HTMLElement>(null);
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  const tagManagerService = TagManagerService();
  const handleLogout = async () => {
    tagManagerService.logout();
    clearStorage();
    window.location.href = '/';
  };

  const toAccountSettings = () => {
    dispatch(setSelectedTreeItem("accountSettings"));
    handleCloseContextMenu()
    history.push('/calculations/accountSettings');
  }

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: e.clientX + 45,
          mouseY: e.clientY + 15
        }
        : null
    );
  };
  const renderMenuList = () => (
    <Menu
      open={Boolean(contextMenu)}
      PaperProps={{
        style: {
          marginLeft: 5,
          padding: 4
        },
        elevation: 0,
        sx: menuStyle
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleCloseContextMenu}
    >
      <List data-testid="btnLogout" onClick={toAccountSettings} style={{ padding: "10px 16px" }}>
        <Icon icon="user" size={24} styles={{ marginRight: 8 }} color={theme.customText.icon_high} />
        <Text variant='body2'>Account Settings</Text>
      </List>
      <List data-testid="btnLogout" onClick={handleLogout} style={{ padding: "10px 16px" }}>
        <Icon icon="logOut" size={24} styles={{ marginRight: 8 }} color={theme.customText.icon_high} />
        <Text variant='body2'>Logout</Text>
      </List>
    </Menu>
  );


  const rightContent = () =>
  (
    <div style={{ marginRight: 16, paddingBottom: 12 }}>
      <RightContent>
        <UserAvatar>
          <img src={!!userPhoto ? userPhoto : images.avatar} alt="avatar" />
          <div data-testid="profileIcon" onClick={e => handleContextMenu(e)}>
            {Boolean(contextMenu) ? <Icon icon="arrowUp" size={22} styles={{ marginLeft: 3, paddingRight: 3 }} /> : <Icon icon="arrowDown" size={22} />}
          </div>
        </UserAvatar>
        {renderMenuList()}
      </RightContent>
    </div>
  );

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <MobileInfoPanelWrapper>
        <div id="headerWrapper">
          <div id="logo">
            <img
              src={images.logoIcon}
              width={32}
              alt="icon"
              onClick={() => undefined}
            />
            <img
              src={images.logoText}
              width={85}
              onClick={() => undefined}
              alt="name"
            />
          </div>
          {rightContent()}
        </div>
        <div id="header">
          <Icon
            icon='arrowLeft'
            onClick={handleClose}
            data-testid="menuViewIcon"
            size={24}
          />
          <Text variant="body1">{headerTitle}</Text>
        </div>
        <div id="content">
          <RightSectionWrapper isMobile isConsent />
        </div>
      </MobileInfoPanelWrapper>
    </Dialog >
  )
}