import { styled } from "@mui/material";

export const PageContainer = styled('div')(({ theme }) => ({
  padding: "24px 0",
  display: "flex",
  backgroundColor: "#F0F0F1",
  alignItems: "flex-start",
  gap: "10px",
  justifyContent: "center",
  position: "absolute",
  width: "calc(100vw - 18px)",
}));