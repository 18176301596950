import { FormChangeDecimalI } from "./logger.types";
import { isNumeric } from "../../utils/helpersFunction";
import { FROZEN_COLUMN_LIMIT } from "../../components/molecules/FormulaTable/toolbar";
import FormulaHandler from "../../utils/formulaHandler";
import { copyCellFormula } from "./helper";
import { TableHeaderI } from "../printView/utils";
import { cleanupCurrencyFormat, formatTableData } from "../../utils/tableFunction";
import { getCellName } from "../../components/molecules/FormulaTable/utils";

const MAX_ROW = 39;
const MAX_COL = 39;

export const mapLevelToVariant: any = {
    TITLE: 'h3',
    HEADING_1: 'h4',
    HEADING_2: 'h5',
    HEADING_3: 'h6',
    NORMAL: 'body3',
};

export async function handleUserInput(fields: any) {
    const validInput: any[] = [];
    const inValidInput: any = {
        errors: [],
        total: 0
    };

    fields.map((page: any) => {
        page.components.filter((component: any) => {
            if (component.type === "NUMERICAL_INPUT" ||
                component.type === "TEXT_INPUT" ||
                component.type === "DROPDOWN_INPUT" ||
                component.type === "RADIO_BUTTON_INPUT" ||
                component.type === "FIELD" ||
                component.type === "DATE_INPUT") {
                if (component.value) validInput.push(component.value);
                else {
                    inValidInput.total++;
                    inValidInput.errors.push({
                        type: 'required',
                        message: `${component?.properties?.description} is required`,
                        id: inValidInput.total
                    });
                }
            }
        });
    });

    return [validInput, inValidInput];
}

export async function checkModifiedFormValue(oldVal: any[], newVal: any[], tableRef?: any) {
    const arrFieldChanges: any = [];
    const arrCellChanges: any = [];

    const replacedData = newVal.map((page, idx) => {
        page.components
            .map((field: any, subIdx: number) => {
                if (field.value !== oldVal[idx]?.components[subIdx]?.value && (field?.value && field?.value !== '') && field.type !== 'FORMULA') {
                    arrFieldChanges.push({
                        newVal: {
                            id: field.columnId,
                            value: field.value
                        },
                        oldVal: {
                            id: oldVal[idx]?.components[subIdx]?.columnId,
                            value: oldVal[idx]?.components[subIdx]?.value
                        }
                    });
                }
                /**
                 * Checking if there are changes in table cell data
                 */
                if (field.type === "TABLE" && tableRef[field.columnId]) {
                    const table = tableRef[field.columnId];
                    const { row, column } = field?.properties?.freeze;

                    field.properties.cells.map((cell: Record<string, unknown>, cellIdx: number) => {
                        const { colIdx, rowIdx, value } = cell;
                        // check modified value
                        if (cell.isAdded && !oldVal[idx].components[subIdx].properties.cells[cellIdx]?.value) {
                            arrCellChanges.push({
                                colIdx, rowIdx, oldVal: undefined, newVal: value
                            });
                        } else if (oldVal[idx].components[subIdx].properties?.cells[cellIdx]?.value !== undefined && value !== oldVal[idx].components[subIdx].properties.cells[cellIdx].value) {
                            arrCellChanges.push({
                                colIdx, rowIdx, oldVal: oldVal[idx].components[subIdx].properties?.cells[cellIdx]?.value, newVal: value
                            });
                        }
                    });

                    if (!!table && (!!row || !!column)) table.setFreeze(row, column);
                }

                return { ...field };
            });
        return { ...page };
    });

    return [arrFieldChanges.filter((field: any) => field.newVal.value !== field.oldVal.value), arrCellChanges, replacedData];
}

export const clearInputsByPageIndex = async (
    clearedInputFile: any,
    pageIndex: number
) => {
    for (let j = 0; j < clearedInputFile[pageIndex].components.length; j++) {
        clearedInputFile[pageIndex].components[j].value = '';
    }
    return clearedInputFile;
};

export async function modifyDecimalInputFileFields(payload: FormChangeDecimalI, fields: any) {
    return fields.map((page: any) => {
        return {
            ...page,
            components: page.components.map((field: any) => {
                if (field.type === 'NUMERICAL_INPUT' || field.type === 'FORMULA') {
                    return {
                        ...field,
                        properties: {
                            ...field.properties,
                            format: field.columnId === payload.columnId ? {
                                value: {
                                    ...field.properties?.format?.value,
                                    decimal: payload.decimal
                                }
                            } : {
                                ...field.properties.format
                            }
                        }
                    };
                } else {
                    return {
                        ...field
                    };
                }
            })
        };

    });
}

export const countDecimal = (num: any) => {
    const numStr = String(num);
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length;
    };
    return 0;
};


export function addFormatValueFormula(fields: any, loggerValues: any) {
    return fields.map((page: any, idx: number) => {
        return {
            ...page,
            components: page.components.map((field: any, subIdx: number) => {
                if (field.type === 'FORMULA') {
                    return {
                        ...field,
                        properties: {
                            ...field.properties,
                            format: loggerValues[idx].components[subIdx].properties.format
                        }
                    };
                } else {
                    return {
                        ...field
                    };
                }
            })
        };

    });
}

export function addFormatInput(newVal: any, oldVal: any) {
    if (oldVal.length > 0) {
        return newVal.map((page: any, idx: number) => {
            return {
                ...page,
                components: page.components.map((field: any, subIdx: number) => {
                    if (field.type === 'NUMERICAL_INPUT' || field.type === 'FORMULA') {
                        const newFormat: any = field.properties.format;
                        const oldFormat: any = oldVal[idx].components[subIdx]?.properties?.format;
                        const newDecimal: any = field.properties.format?.value?.decimal;
                        const oldDecimal: any = oldVal[idx].components[subIdx]?.properties.format?.value?.decimal;
                        return {
                            ...field,
                            properties: {
                                ...field.properties,
                                format: newDecimal !== undefined && oldDecimal === undefined ? newFormat :
                                    isNumeric(newDecimal) && isNumeric(oldDecimal) && newDecimal !== oldDecimal ? newFormat :
                                        newDecimal === undefined && isNumeric(oldDecimal) ? oldFormat :
                                            oldFormat
                            }
                        };
                    } else {
                        return {
                            ...field
                        };
                    }
                })
            };

        });
    } else return newVal;

}

export function roundFunction(value: any, decimals: number) {
    return Number(value).toFixed(decimals);
}

export const checkDecimalPercentage = (item: any) => {
    if (item.properties?.format?.value?.percentage && item.properties.format?.value?.decimal === undefined) {
        return 4;
    } else if (item.properties?.format?.value?.percentage && item.properties.format?.value?.decimal !== undefined) {
        return item.properties.format?.value?.decimal + 2;
    }
};

export function findLargest(arr: Array<number>) {
    let i;

    let max = arr[0];

    for (i = 1; i < arr.length; i++) {
        if (arr[i] > max) max = arr[i];
    }

    return max;
}

export function findSmallest(arr: Array<number>) {
    let i;

    let min = arr[0];

    for (i = 1; i < arr.length; i++) {
        if (arr[i] < min) min = arr[i];
    }

    return min;
}

export function generateSpreadsheetData(arr: any[], content: any, freezeConfig: any, isLogger?: boolean, headers?: Array<TableHeaderI>) {
    const tableContent = generateTableDynamicContent(arr, isLogger);
    const totalRowAdded: Array<number> = [];
    const indexHideRowArr: Array<number> = [];
    const indexHideColArr: Array<number> = [];

    arr.forEach((cell: any) => {
        /**
         * generating table data based on BE response
         */

        tableContent[cell.rowIdx][cell.colIdx] = {
            alignment: cell.alignment,
            value: isLogger ? cell.value : cell.valueFormula ?? cell.value,
            type: cell.isHeader ? 'header' : cell.readOnly ? 'readonly' : 'default',
            isDisabled: cell.isHeader || cell.readOnly ? true : false,
        };

        /**
         * increase displayContent row end based on addedRow by user
         */
        if (cell.isAdded && (!(totalRowAdded.includes(cell.rowIdx)))) totalRowAdded.push(cell.rowIdx);

        // generate hidden row
        if (cell.rowIdx > content.row.end && !cell.isAdded && !(indexHideRowArr.includes(cell.rowIdx))) indexHideRowArr.push(cell.rowIdx);

        // generate hidden column
        if (cell.colIdx > content.column.end && !cell.isAdded && !(indexHideColArr.includes(cell.colIdx))) indexHideColArr.push(cell.colIdx);
    });

    const freeze = {
        column: freezeConfig.column > FROZEN_COLUMN_LIMIT ? 0 : freezeConfig.column,
        row: freezeConfig.row
    };

    const generatedHeader: any = tableContent[0].map((cell: any, idx: number) => {
        const selectedHeader: undefined | TableHeaderI = headers?.find((item: TableHeaderI) => item.colIdx === idx);
        const isFreeze: boolean = freezeConfig.column ? idx + 1 <= freezeConfig.column : false;
        if (selectedHeader) {
            return {
                width: isFreeze ? 100 : selectedHeader.width,
                type: 'text'
            };
        } else {
            return {
                width: isFreeze ? 100 : 150,
                type: 'text'
            };
        }

    });

    const tableContentPrintview = tableContent.filter((item: any) => item[0].alignment);

    const indexRowsPrintView = Array.from({ length: tableContentPrintview.length }, (v: any, k: number) => {
        return {
            title: arr[0].rowIdx + 1 + k
        };
    });

    if (!isLogger) content.row.end = content.row.end + indexHideRowArr.length + totalRowAdded.length;

    return {
        data: isLogger ? tableContent : tableContentPrintview,
        displayedContent: content,
        freeze,
        totalRowAdded: totalRowAdded,
        indexHideRowArr,
        ...(headers && {
            headers: generatedHeader
        }),
        indexRows: isLogger ? [] : indexRowsPrintView,
        indexHideColArr
    };
}

export function isNegative(number: any) {
    return !Object.is(Math.abs(number), +number);
}

function generateTableDimension(data: any) {
    let maxRow = Number.MIN_VALUE;
    let maxCol = Number.MIN_VALUE;

    for (const item of data) {
        const { colIdx, rowIdx } = item;

        if (rowIdx > maxRow) {
            maxRow = rowIdx;
        }

        if (colIdx > maxCol) {
            maxCol = colIdx;
        }

    }

    return { maxRow, maxCol };
}

export function generateTableDynamicContent(data?: any, isLogger?: boolean) {
    let columnLength: number;
    let rowlength: number;
    const tableContent = [];

    if (!isLogger) {
        const { maxCol, maxRow } = generateTableDimension(data);
        columnLength = maxCol;
        rowlength = maxRow;
    } else {
        columnLength = MAX_COL;
        rowlength = MAX_ROW;
    }


    for (let i = 0; i <= rowlength; i++) {
        const row = [];
        for (let j = 0; j <= columnLength; j++) {
            row.push({
                alignment: "",
                value: "",
                type: "readonly",
                isDisabled: true
            });
        }
        tableContent[i] = row;
    }

    return tableContent;

}

export const mappingRowsExpandTable = (table: any) => {
    const existingRows: any = table.data?.map((item: any) => {
        const columnLength: number = item.length;
        const generatedColumn = Array.from({ length: 40 - columnLength }, () => {
            const isAdded = Boolean(item[0].isAdded);
            if (isAdded) {
                return {
                    value: '',
                    type: 'readonly',
                    isDisabled: true,
                    isAdded: true,
                };
            } else {
                return {
                    value: '',
                    type: 'readonly',
                    isDisabled: true

                };
            }

        });
        return [...item, ...generatedColumn];
    });

    const newRows: any = Array.from({ length: 40 - table.data.length }, () => {
        return Array.from({ length: 40 }, () => {
            return {
                value: '',
                type: 'readonly',
                isDisabled: true

            };
        });
    });

    return {
        data: [...existingRows, ...newRows],
        displayedContent: table.displayedContent,
        freeze: table.freeze,
        totalRowAdded: table.totalRowAdded,
        indexHideRowArr: table.indexHideRowArr,
        headers: table.headers,
        indexHideColArr: table.indexHideColArr,
    };
};

const generateNewRowData = (arr: any[], columnLength?: number, lastIndex?: number) => {
    const lastRow = arr[lastIndex ?? arr.length - 1];
    const newRow = Array.from({ length: columnLength ?? arr[0].length }, (v, k) => {
        const { value, type, isDisabled, alignment } = lastRow[k];
        return {
            value: /^=/.test(value as string) ? copyCellFormula(value) : '',
            type,
            isDisabled,
            isAdded: true,
            alignment,
        };
    });
    return newRow;
};

export const addRowData = (arr: any) => {
    const newRow = generateNewRowData(arr);
    return [...arr, ...[newRow]];
};

export const addRowTableExpand = (arr: any, columnLength: any) => {
    const lastIndex = findLastRow(arr);

    const existingColumn = generateNewRowData(arr, columnLength, lastIndex);

    const readOnlyColumn = Array.from({ length: 40 - columnLength }, () => {
        return {
            value: '',
            type: 'readonly',
            isDisabled: true,
            isAdded: false
        };
    });

    return arr.map((item: any, idx: number) => {
        if (idx === lastIndex + 1) {
            return [...existingColumn, ...readOnlyColumn];
        } else {
            return [...item];
        }
    });
};

export const deleteRowTableExpand = (arr: any, rowIndex?: number) => {
    const readOnlyColumn = Array.from({ length: 40 }, () => {
        return {
            value: '',
            type: 'readonly',
            isDisabled: true

        };
    });

    const lastIndex = rowIndex ?? arr.map((el: any) => {
        const allEqual = (arr: any) => arr.every((v: any) => JSON.stringify(v) === JSON.stringify(arr[0]));
        return allEqual(el);
    }).lastIndexOf(false);

    return arr.map((item: any, idx: number) => {
        if (idx === lastIndex) {
            return [...readOnlyColumn];
        } else {
            return [...item];
        }
    });
};

export const convertDataFromTableExpand = (arr: any, columnLength: any) => {
    const lastIndex = arr.map((el: any) => {
        const allEqual = (arr: any) => arr.every((v: any) => JSON.stringify(v) === JSON.stringify(arr[0]));
        return allEqual(el);
    }).lastIndexOf(false);

    return arr.slice(0, lastIndex + 1).map((item: any) => {
        return [...item].slice(0, columnLength);
    });
};

export const findLastRow = (arr: any) => {
    const lastIndex = arr.map((el: any) => {
        const allEqual = (arr: any) => arr.every((v: any) => JSON.stringify(v) === JSON.stringify(arr[0]));
        return allEqual(el);
    }).lastIndexOf(false);

    return lastIndex;
};

export const convertTableDataToCells = async (newData: any, oldData: any) => {
    const dataConvert: any = [];
    await newData.forEach((a: any, idx: number) => {
        a.map((b: any, subIdx: number) => {
            const existingData: any = oldData.find((item: any) => item.colIdx === subIdx && item.rowIdx === idx);
            dataConvert.push({
                colIdx: subIdx,
                rowIdx: idx,
                value: b.value,
                valueFormula: b.value,
                isAdded: Boolean(b.isAdded),
                isHeader: Boolean(existingData) ? existingData.isHeader : false,
                readOnly: Boolean(existingData) ? existingData.readOnly : false,
                showToLogger: Boolean(existingData) ? existingData.showToLogger : true
            });
        });
    });
    return dataConvert;

};

export const toPlainNumeric = (exponential: number) => {
    if (!exponential) {
        return exponential;
    }
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes('e+')) {
        const exponentialSplitted = decimal.split('e+');
        let postfix = '';
        for (
            let i = 0; i <
            +exponentialSplitted[1] -
            (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0); i++
        ) {
            postfix += '0';
        }
        const addCommas = (text: string) => {
            let j = 3;
            let textLength = text.length;
            while (j < textLength) {
                text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
                textLength++;
                j += 3 + 1;
            }
            return text;
        };
        decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
    }
    if (decimal.toLowerCase().includes('e-')) {
        const exponentialSplitted = decimal.split('e-');
        let prefix = '0.';
        for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
            prefix += '0';
        }
        decimal = prefix + exponentialSplitted[0].replace('.', '');
    }
    return decimal;
};

export const generateTableName = (data: []) => {
    let tableCount = 0;
    data.forEach((pages: Record<string, any>) => {
        pages.components.forEach((elem: Record<string, unknown>, index: number, arr: Record<string, Record<string, unknown>>[]) => {
            if (elem.type === "TABLE") {
                tableCount++;
                arr[index].properties.tableName = `Table ${tableCount}`;
            }
        });
    });

    return data;
};

export const getDecimalState = (arr: any[]) => {
    return arr.map((a: any) => {
        return {
            components: a.components.map((b: any) => {
                if ((/NUMERICAL_INPUT|FORMULA/).test(b.type)) {
                    return {
                        decimal: b.properties.format
                    };
                }
            })
        };
    });
};

// JSPREADSHEET & FORMULA INTEGRATION
export async function calculateFormula(formData: any, tableRef: any, vlcRef: any) {
    const variableList: {
        name: string;
        cell: string;
        value: string | number;
    }[] = [];
    let sources: Record<string, any> = {};
    let getVariableList: any = {};
    let count = 1;

    FormulaHandler.resetVarList();

    // Collecting All Variable
    formData.forEach((pages: any) => {
        pages.components.forEach((field: any) => {
            if (field?.properties?.variable) {
                switch (field.type) {
                    case 'NUMERICAL_INPUT':
                        FormulaHandler.setVar(
                            field?.properties?.variable,
                            field.value ?? 0
                        );
                        break;
                    case 'TABLE':
                        break;
                    default:
                        variableList.push({
                            name: field?.properties?.variable ?? '',
                            cell: `formula!A${count}`,
                            value: !!field.value ? `\"${field.value}\"` : ''
                        });
                        count++;
                }
            }
        });
    });

    getVariableList = vlcRef.setVariableList(variableList);

    // Collecting table source
    formData.forEach((pages: any) => {
        pages.components.forEach((field: any) => {
            if (field.type === 'TABLE' && tableRef[field.columnId]) {
                sources[field?.properties?.tableName.split(' ').join('')] = cleanupCurrencyFormat(formatTableData(tableRef[field.columnId].getData(true)));
            }
        });
    });

    // Merge all data sources before calculate formula
    sources = { ...sources, ...getVariableList };

    // Calculating Formula Component
    formData.map((pages: any) => {
        pages.components.map((field: any) => {
            if (field.type === 'FORMULA' &&
                field?.properties?.formula &&
                field?.properties?.variable) {
                const result = FormulaHandler.calculate(
                    field?.properties.formula.substring(1),
                    sources
                );
                // here the formula value probably change, we need to update:
                // 1. component value it self
                field.value = result;
                // 2. the data sources
                sources[field?.properties?.variable] = result;
                // 3. the variable list before use it as a define name in next iteration
                variableList.forEach((variable) => {
                    if (variable.name === field?.properties?.variable) {
                        variable.value = `\"${field.value}\"`;
                    }
                    return variable;
                });
            }
        });
    });

    getVariableList = await vlcRef.setVariableList(variableList);

    // Trigger change on Table
    formData.forEach((page: any) => {
        page.components.forEach((field: any) => {
            if (field.type === 'TABLE' && tableRef[field.columnId]) {
                const { cells, displayedContent, freeze } = field.properties;
                const { data } = generateSpreadsheetData(cells, displayedContent, freeze, true);

                tableRef[field.columnId].setDefinedNames(getVariableList);
                tableRef[field.columnId].setData(data);

                // save cell processed value to valueFormula
                cells.map((cell: Record<string, unknown>, cellIdx: number) => {
                    const { colIdx, rowIdx } = cell;
                    const processedValue = tableRef[field.columnId].getCellProcessedValue(colIdx, rowIdx);
                    cells[cellIdx].valueFormula = processedValue;
                });
            }
        });
    });

    return formData;
};

export const generateTableHeader = (arr: any, headers: any, freezeConfig: any) => {
    return arr?.map((cell: any, idx: number) => {
        const selectedHeader: undefined | TableHeaderI = headers?.find((item: TableHeaderI) => item.colIdx === idx);
        const isFreeze: boolean = freezeConfig.column ? idx + 1 <= freezeConfig.column : false;
        if (selectedHeader) {
            return {
                width: isFreeze ? 100 : selectedHeader.width,
                type: 'text'
            };
        } else {
            return {
                width: isFreeze ? 100 : 150,
                type: 'text'
            };
        }

    });
};

export async function validateDecimalChanges(arr: any) {
    const result = arr?.map((a: any) => {
        return {
            ...a,
            components: a.components.map((b: any) => {
                if (b.type === 'NUMERICAL_INPUT') {
                    const decimal = sessionStorage.getItem(`input_numerical__id_${b.columnId}_decimal_number`);
                    return {
                        ...b,
                        properties: {
                            ...b.properties,
                            format: decimal !== null ? {
                                ...b.format,
                                value: {
                                    ...b.format?.value,
                                    decimal: parseInt(decimal)
                                }
                            } : b.format
                        }
                    };
                } else return b;
            })
        };
    });

    return result;
}

export const toggleShowHideTableRow = (data: any, tableRef: any, lastIdx: number, maxCol: number, readOnly: boolean) => {
    for (let i = 0; i <= maxCol; i++) {
        const { isDisabled } = data[i];
        if (!isDisabled) {
            const cellName = getCellName(i, lastIdx);
            tableRef.setCellReadOnly(cellName, readOnly);
        }
    }
    tableRef.showRow(lastIdx);
};

export const resetColumnSetting = (data: []) => {
    const columnSettings = data.map(
        (el: Record<string, unknown>) => {
            if (el?.visible === false) {
                el.visible = !el.visible;
            }
            return el;
        }
    );
    return columnSettings;
};

export const resetDisplayedContent = (data: any, totalHideRow: number, totalRowAdded: number) => {
    if (totalRowAdded > 0 && (data.displayedContent.row.end < totalHideRow)) {
        return {
            ...data,
            displayedContent: {
                ...data.displayedContent,
                row: {
                    ...data.displayedContent.row,
                    end: data.displayedContent.row.end - totalHideRow - totalRowAdded
                }
            },
        };
    } else {
        return data;
    }
};

export const getFilteredPage = (data: Record<string, unknown>[]) => {
    const filteredPage: any = [];
    data.forEach((page: Record<string, unknown>, idx: number) => {
        if (!page.isHidden) {
            filteredPage.push({
                idx,
                pageTitle: page.pageTitle
            });
        }
    });

    return filteredPage;
};

export const tableLengthData = (arr: any) => {
    return arr.map((item: any) => {
        if (item[0].alignment) {
            return item;
        } else {
            return undefined;
        }
    }).filter((el: any) => el !== undefined).length;
};