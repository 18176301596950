//TODO: determine which function could be here
import { Menu } from '@mui/material';
import { MenuBox } from '../../../pageElements/composer';
import { contentText } from '../../../utils/constant';
import { ITextMenu } from './text-menu.type';

export const TextMenu = ({
  open,
  handlerShowTextComponent,
  anchorPosition,
  handlerComponentText,
  convertTextLevel
}: ITextMenu) => (
  <Menu
    open={open}
    onClose={() => handlerShowTextComponent('close')}
    anchorEl={anchorPosition}
    MenuListProps={{
      disablePadding: true
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
        mt: 0.5
      }
    }}
  >
    <MenuBox>
      {contentText?.map((value: any, index: number) => (
        <div
          key={`text_menu_box_${index}`}
          onClick={(e: React.SyntheticEvent) => {
            handlerComponentText('SELECT_LEVEL', { level: value }, e);
          }}
          data-testid={convertTextLevel(value)}
        >
          {convertTextLevel(value)}
        </div>
      ))}
    </MenuBox>
  </Menu>
);
