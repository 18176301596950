export const FETCH_INPUT_FILE_BY_CALCULATION_ID = "FETCH_INPUT_FILE_BY_CALCULATION_ID";
export const FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS = "FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS";
export const FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED = "FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED";
export const FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST = "FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST";
export const FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_SUCCESS = "FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_SUCCESS";
export const FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_FAILED = "FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_FAILED";
export const FETCH_CALCULATION = "FETCH_CALCULATION";
export const FETCH_CALCULATION_SUCCESS = "FETCH_CALCULATION_SUCCESS";
export const FETCH_CALCULATION_FAILED = "FETCH_CALCULATION_FAILED";
export const SET_INPUT_FILE = "SET_INPUT_FILE";
export const SET_INPUT_FILE_BY_ID = "SET_INPUT_FILE_BY_ID";
export const SET_REPORT_BY_ID = "SET_REPORT_BY_ID";

export const fetchInputFileByCalculationId = () => {
  return {
    type: FETCH_INPUT_FILE_BY_CALCULATION_ID,
  };
};

export const fetchInputFileByCalculationIdSuccess = (data: any) => {
  return {
    type: FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS,
    data,
  };
};

export const fetchInputFileByCalculationIdFailed = (error: string) => {
  return {
    type: FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED,
    error,
  };
};

export const fetchCalculationInfoAndInputFileList = () => {
  return {
    type: FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST,
  };
};

export const fetchCalculationInfoAndInputFileListSuccess = (data: any) => {
  return {
    type: FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_SUCCESS,
    data,
  };
};

export const fetchCalculationInfoAndInputFileListFailed = (error: string) => {
  return {
    type: FETCH_CALCULATION_INFO_AND_INPUT_FILE_LIST_FAILED,
    error,
  };
};

export const fetchCalculation = () => {
  return {
    type: FETCH_CALCULATION,
  };
};

export const fetchCalculationSuccess = (data: any) => {
  return {
    type: FETCH_CALCULATION_SUCCESS,
    data,
  };
};

export const fetchCalculationFailed = (error: string) => {
  return {
    type: FETCH_CALCULATION_FAILED,
    error,
  };
};

export const setInputFile = (data: any) => {
  return {
    type: SET_INPUT_FILE,
    data,
  };
};

export const setInputFileById = (data: any) => {
  return {
    type: SET_INPUT_FILE_BY_ID,
    data,
  };
};

export const setReportById = (data: any) => {
  return {
    type: SET_REPORT_BY_ID,
    data,
  };
};
