import * as React from "react";
import { SVGProps } from "react";

const SvgArea = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="area_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={8}
      y={8}
      width={16}
      height={16}
    >
      <path fill="#E1E1E3" stroke="#E1E1E3" d="M8.5 8.5h15v15h-15z" />
    </mask>
    <g
      mask="url(#area_svg__a)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path d="m10.343 17.414 7.071-7.071" />
      <path transform="rotate(-45 31.228 -8.692)" d="M1-1h4" />
    </g>
  </svg>
);

export default SvgArea;
