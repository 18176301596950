import {
  CalculationContent,
  ChildWrapper,
  TitleWrapper
} from '../../components/organisms/hoc/elements';
import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import HelperButton from '../../components/molecules/HelperButton';
import { LoadingSpinner } from '../../components/atoms/loading';
import SideBar from '../../components/molecules/sidebar';
import { calculationsNav } from '../../components/organisms/hoc/calculationsNav';
import { BlankDrives } from './components/BlankDrive';
import { BreadcrumbsV2 } from '../../components/molecules/Breadcrumbs';
import { drivesBreadcumb } from '../../utils/constant';
import { ListView } from './components/ListView';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changePositionActionCircleatorBreadcrumb } from '../../store/breadCrumb/circleator/circleatorAction';
import CalculationInfoPanel from '../../components/organisms/CalculationInfoPanel';
import useWebSocket from 'react-use-websocket';
import {
  getBusinessDriveRole,
  getToken,
  setBusinessDriveRole
} from '../../utils/storage';
import { handleSetDriveRole } from '../../store/actions/userAction';
import { DriveI, FolderDriveItemsI } from './businessDrives.types';
import DriveService from '../../service/DriveService';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { CalculationsSearchBox } from '../../components/molecules/calculationsSearchBox';
import { CalculationsEmptyResults } from '../../components/molecules/calculationsEmptyResults';
import { handleSearchCalculation } from '../../utils/helpersFunction';

interface IMessageHistory {
  calculationId: string;
  eventType: string;
  userId: string;
  userName: string;
}

enum CalculationType {
  CALCULATION_CLOSE = 'CALCULATION_CLOSE',
  CALCULATION_OPEN = 'CALCULATION_OPEN'
}

const BusinessDrives = (props: any) => {
  const {
    isMinimize,
    fetchTeamDrives,
    global,
    handleModal,
    handleInActiveFocus,
    handleSetActiveDrive,
    isActiveFolder,
    renderTeamDrivesMenuContext,
    handleCalculationMenuTeamDrives,
    handleContextMenuTeamDrives,
    breadCrumbsData,
    activeItem,
    openPanel,
    calInfo,
    clearRecentInputFile,
    login,
    users
  } = props;
  const {
    personalWorkspaceId,
    enterpriseWorkspaceId,
    communityWorkspaceId,
    subscription
  } = login;
  const APIKEY = process.env.REACT_APP_WEBSOCKET_API;
  const { drives, isLoadingSubmit, driveItems } = global;
  const { userLimit } = users;
  const [value, setValue] = useState('');

  const [messageHistory, setMessageHistory] = useState<IMessageHistory[]>([]);
  const [socketUrl, setSocketUrl] = useState(APIKEY ?? '');
  const { folderId } = useParams<{ folderId: string }>();
  const history = useHistory();
  const id: string = folderId ?? '';
  const dispatch = useDispatch();
  const location: Record<string, any> = useLocation();
  const token = getToken();
  const isManageFolder: boolean = /(OWNER|CONTENT_MANAGER)/.test(
    getBusinessDriveRole()
  );
  const isManageCalc: boolean = /(OWNER|CONTENT_MANAGER|CONTRIBUTOR)/.test(
    getBusinessDriveRole()
  );
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResult, setSearchResult] = useState<string>('');
  const [searchError, setSearchError] = useState<boolean>(false);

  useEffect(() => {
    !id && handleInActiveFocus();
    dispatch(changePositionActionCircleatorBreadcrumb('businessDrives')); // update breadcrumb positions
    const unsubscribe = fetchTeamDrives(id, location);
    return unsubscribe;
  }, [folderId]);

  const { getWebSocket, lastJsonMessage } = useWebSocket(socketUrl, {
    retryOnError: true,
    share: true
  });

  useEffect(() => {
    if (lastJsonMessage?.eventType === CalculationType.CALCULATION_OPEN) {
      const concatArr = messageHistory.concat(lastJsonMessage);

      if (concatArr.length > 0) {
        const newArr = concatArr.filter(
          (v, i, a) =>
            a.findIndex(v2 => v2?.calculationId === v?.calculationId) === i
        );
        setMessageHistory(newArr);
      } else {
        setMessageHistory(concatArr);
      }
    } else if (
      lastJsonMessage?.eventType === CalculationType.CALCULATION_CLOSE
    ) {
      setMessageHistory(
        messageHistory.filter(
          (el: any) => el.calculationId !== lastJsonMessage.calculationId
        )
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setSocketUrl(`${APIKEY}/event/folder/${folderId}?token=${token}`);
    return () => {
      getWebSocket()?.close();
    };
  }, [folderId]);

  useEffect(() => {
    setSearchResult('');
    setSearchValue('');
  }, [isLoadingSubmit, driveItems]);

  const handleStartCalculation = async (
    calculationId: string,
    isComplete: boolean
  ) => {
    try {
      if (!isComplete) {
        const permission: { isAllowed: boolean } =
          await DriveService.checkAccess(calculationId);
        if (!permission.isAllowed) {
          return dispatch(
            formModalSetupActionPopUp('START_CALCULATION_ERROR', {
              isDriveCalculation: true,
              folderId: id
            })
          );
        } else {
          history.push({
            pathname: `/composer/${calculationId}/${id}`,
            state: {
              isStartCalculation: true,
              // added drive id to router state
              calculationType: 'BUSINESS',
              ...(location.state?.driveId && {
                driveId: location.state.driveId
              })
            }
          });
        }
      } else {
        await Promise.all([clearRecentInputFile()])
          .then(() => {
            history.push({
              pathname: `/logger/${calculationId}`,
              state: {
                calculationType: 'BUSINESS',
                // added drive id to router state
                ...(location.state?.driveId && {
                  driveId: location.state.driveId
                })
              }
            });
          })
          .catch(error => console.error(error));
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleDoubleClick = (row: DriveI | FolderDriveItemsI) =>
    row.itemType === 'CALCULATION'
      ? handleStartCalculation(row.id, row.isComplete)
      : setDriveRole(row);

  const setDriveRole = (row: DriveI | FolderDriveItemsI) => {
    const memberRole: { type: string } = row?.memberRole;
    memberRole &&
      dispatch(handleSetDriveRole(memberRole.type)) &&
      setBusinessDriveRole(memberRole.type);
    history.push({
      pathname: `/calculations/businessDrives/${row.id}`,
      state: {
        ...(row?.countMember && { driveId: row.id }),
        // added drive id to router state
        ...(location.state?.driveId && { driveId: location.state.driveId })
      }
    });
  };

  const handleSearch = () => {
    handleSearchCalculation(
      driveItems.items,
      searchValue,
      id,
      setFilteredData,
      setSearchResult,
      setSearchError
    );
  };

  return (
    <>
      <CalculationContent>
        {isLoadingSubmit && <LoadingSpinner />}
        <SideBar isMinimize={isMinimize} />
        <Divider orientation="vertical" flexItem />
        <ChildWrapper onClick={handleInActiveFocus}>
          {renderTeamDrivesMenuContext()}
          {drives.length === 0 && isLoadingSubmit ? null : drives.length ===
              0 &&
            !isLoadingSubmit &&
            !Boolean(id) ? (
            <BlankDrives handleModal={(type: string) => handleModal(type)} />
          ) : (
            <>
              <BreadcrumbsV2
                dataSource={id ? breadCrumbsData : drivesBreadcumb}
              />
              {folderId && (
                <TitleWrapper>
                  <div />
                  <CalculationsSearchBox
                    placeholder="Search calculation"
                    value={searchValue}
                    error={searchError}
                    onChange={(e: any) => setSearchValue(e.target.value)}
                    onKeyDown={(e: any) => {
                      e.key === 'Enter' && handleSearch();
                    }}
                    onClick={() => {
                      handleSearch();
                    }}
                    isDrive={true}
                  />
                </TitleWrapper>
              )}
              <ListView
                data={
                  searchResult ? filteredData : id ? driveItems.items : drives
                }
                onSelect={handleSetActiveDrive}
                isActive={isActiveFolder}
                handleContextMenuTeamDrives={handleContextMenuTeamDrives}
                handleCalculationMenuTeamDrives={
                  handleCalculationMenuTeamDrives
                }
                onDoubleClick={handleDoubleClick}
                isDrive={!Boolean(id)}
                folderId={id ? driveItems.id : ''}
                isManageFolder={isManageFolder}
                isManageCalc={isManageCalc}
              />
              {filteredData.length <= 0 &&
              searchResult.length > 0 &&
              !isLoadingSubmit ? (
                <CalculationsEmptyResults
                  value={`No Results for "${searchResult}"`}
                  isDrive={true}
                />
              ) : null}
            </>
          )}
        </ChildWrapper>
        {activeItem?.id && calInfo?.calName && openPanel && (
          //todo: anjing
          <CalculationInfoPanel
            activeItem={activeItem}
            calInfo={calInfo}
            personalWorkspaceId={personalWorkspaceId}
            enterpriseWorkspaceId={enterpriseWorkspaceId}
            communityWorkspaceId={communityWorkspaceId}
            handleModal={handleModal}
            clearRecentInputFIle={clearRecentInputFile}
            userLimit={userLimit}
            subscription={subscription}
            webSocketMessageHistory={messageHistory}
            pathName={window.location.pathname}
            {...props}
          />
        )}
      </CalculationContent>
      <HelperButton />
    </>
  );
};

export default calculationsNav(BusinessDrives);
