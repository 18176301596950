import React from 'react';
import { EmptyResultsWrapper } from './elements';
import { ReactComponent as EmptySearchIcon } from '../../../assets/calculations/emptySearch.svg';
import Text from '../../atoms/Text';

type EmptyResultsProps = {
  value: string;
  isDrive?: boolean;
};

export const CalculationsEmptyResults = ({ value, isDrive }: EmptyResultsProps) => {
  return (
    <EmptyResultsWrapper style={{ paddingBottom: isDrive ? 200 : 0 }} >
      <EmptySearchIcon />
      <Text variant="h2">{value}</Text>
    </EmptyResultsWrapper>
  );
};
