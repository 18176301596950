import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Icon from '../../../atoms/Icon';
import Text from '../../../atoms/Text';
import {
  HeadSection,
  SymbolItem,
  SymbolSection,
  ToolkitWrapper
} from './styles';

import { ReduxState } from '../../../../store';
import { inputSwitchActionMenuComposer } from '../../../../store/composer/composerMenu/composerMenuActions';
import { concatStringHTML } from '../../../../utils/helpersFunction';

import { SymbolPropsI } from './types';

const Symbol = (
  {
    currentFocus,
    startInputFocus,
    endInputFocus,
    setValue,
    getValues
  }: SymbolPropsI,
  ref?: any
) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { dataSymbol } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  function handlerClickGlyph(
    event: React.SyntheticEvent,
    glyph: string | number
  ) {
    if (currentFocus?.includes('notation')) {
      if (
        startInputFocus !== null &&
        startInputFocus !== undefined &&
        endInputFocus !== null &&
        endInputFocus !== undefined
      ) {
        concatStringHTML(
          currentFocus,
          startInputFocus,
          endInputFocus,
          glyph,
          getValues,
          setValue
        );
      }
    }
  }

  return (
    <ToolkitWrapper type="SYMBOL">
      <HeadSection>
        <Text variant="smallBold" color="#A3B3C2">
          SYMBOLS
        </Text>
        <Icon
          icon="closeNoBorder"
          color={theme.palette.action.disabled}
          size={20}
          onClick={() => dispatch(inputSwitchActionMenuComposer('exitSymbol'))}
        />
      </HeadSection>
      <SymbolSection>
        {dataSymbol.map((value: { [key: string]: string | number }) => (
          <SymbolItem
            currentFocus={currentFocus}
            onMouseDown={(event: React.SyntheticEvent) =>
              currentFocus?.includes('notation') ? event.preventDefault() : null
            }
            onClick={(event: React.SyntheticEvent) => {
              ref.current[currentFocus].insertSymbol(value.glyph);
              handlerClickGlyph(event, value.glyph);
            }}
          >
            <Text>{value.glyph}</Text>
          </SymbolItem>
        ))}
      </SymbolSection>
    </ToolkitWrapper>
  );
};

export default forwardRef(Symbol);
