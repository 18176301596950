import styled, { CSSProperties } from "styled-components";

interface Props {
  error?: any;
  align?: string;
  footerForm?: boolean;
  disabled?: boolean;
  isMobile?: boolean;
  height?: any;
  width?: number;
  style?: CSSProperties
}

export const FlyingContainer = styled.div({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  outline: "none",
});

export const Container = styled.div<Props>((props) => ({
  width: props.width ? props.width : props.isMobile ? window.innerWidth - 48 : "576px",
  display: "flex",
  flexDirection: "column",
  padding: props.footerForm ? "24px 0px 0px" : "16px",
  fontFamily: "Inter",
  fontStyle: "normal",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: "0px 3px 6px rgba(120, 138, 155, 0.25)",
  height: props.height ?? undefined,
  ...props.style
}));

export const XButton = styled.div({
  position: "absolute",
  top: "0px",
  right: "0px",
  marginTop: "16px",
  marginRight: "16px",
  cursor: "pointer",
});

export const Head = styled.div<Props>((props) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: "32px",
  color: "#353C68",
  fontSize: "20px",
  fontWeight: 800,
  letterSpacing: "0.15px",
  marginBottom: "16px",
  alignSelf: props.align === "center" ? "center" : "flex-start",
  padding: "0 16px 16px"
}));

export const Body = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "12px",
  " > img": {
    height: 362,
    width: 544,
    objectFit: 'cover',
    borderRadius: '4px',
    alignSelf: 'center',
  },
  h1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0/75px",
    textTransform: "uppercase",
    color: "#686D8E",
    width: 544,
    marginBottom: "19.5px",
  },
  "& > #description": {
    display: "flex",
    padding: "16px"
  },
  "& > #title": {
    display: "flex",
    padding: "16px"
  },
});

export const LazyImageWrapper = styled.div({
  "& > #lazyImage": {
    position: "relative",
    height: 0,
  },
  "& > #lazyImage_img": {
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

export const CarouselWrapper = styled.div({
  marginRight: "8px",
  marginLeft: "16px",
  width: "556px",
  "& > .carousel__wrap": {
    position: "relative"
  },
  "& > .carousel__viewport": {
    width: "100%",
    overflow: "hidden"
  },
  "& > .carousel__viewport.is-dragging": {
    cursor: "grabbing",
  },
});

export const CarouselFooter = styled.div({
  width: "100%",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: "row",
  columnGap: "18px",
  height: "32px",
  "& > .footerCount": {
    paddingTop: '24px',
    display: "flex",
  },
  "& > .footerButton": {
    display: "flex",
    marginRight: "24px",
    flexDirection: "row",
  },
});

export const Header = styled.div<Props>((props) => ({
  display: "flex",
  flexDirection: 'column',
  alignSelf: props.align === "center" ? "center" : "flex-start",
  padding: "0 24px 24px"
}));

export const Footer = styled.div<Props>((props) => ({
  display: "flex",
  flexDirection: 'row',
  alignSelf: props.align === "center" ? "center" : "flex-start",
  width: '100%',
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  borderTop: "1px solid #D6DFEB",
  padding: "12px 0px 12px 0px",
  "& > #form": {
    width: '100%',
    display: "flex",
    flexDirection: 'row',
    padding: "0px 24px 0px",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const CloseButton = styled.div<Props>((props) => ({
  display: "flex",
  padding: "0px 12px",
  height: "32px",
  backgroundColor: props.disabled ? "#D6DFEB" : "#118CFF",
  borderRadius: "6px",
  justifyContent: 'center',
  alignItems: 'center',
  cursor: props.disabled ? "not-allowed" : "pointer"
}));

export const Content = styled.div<Props>((props) => ({
  display: "flex",
  flexDirection: 'column',
  alignSelf: props.align === "center" ? "center" : "flex-start",
  padding: "0 24px 24px",
  maxHeight: props.height ? props.height : props.isMobile ? props.height - 150 : "623px",
  overflow: "auto",
  ...props.style,
}));







