import { addFormatInput } from "../../pages/loggerRefactor/utils";
import { RESET_ACTION, SET_ACTION, SET_ACTIVE_PAGE, SET_DATA_CALCULATION_INFO, SET_DATA_INPUT_FILE, SET_DATA_LIST_INPUT_FILES, SET_DATA_LOADING, TOGGLE_PRINT_BUTTON } from "../types/logger.types";
interface LoggerStateI {
    isLoading: boolean
    calculationInfo: any
    inputFile: any
    listInputFiles: any[]
    loggerAction: string | null
    activePage: number
    enablePrint: boolean
}

const LoggerState: LoggerStateI = {
    isLoading: false,
    calculationInfo: {},
    inputFile: {
        id: undefined,
        fields: [],
        errors: {
            errors: [],
            total: 0,
        },
        totalUserInputs: 0,
        isModified: false
    },
    listInputFiles: [],
    loggerAction: null,
    activePage: 0,
    enablePrint: false
};

type LoggerAction = SET_DATA_INPUT_FILE | SET_DATA_LIST_INPUT_FILES | SET_DATA_CALCULATION_INFO | SET_DATA_LOADING | SET_ACTION | SET_ACTIVE_PAGE | RESET_ACTION | TOGGLE_PRINT_BUTTON;

const newLoggerReducer = (state: LoggerStateI = LoggerState, action: LoggerAction) => {
    switch (action.type) {
        case SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload
            };
        case SET_DATA_CALCULATION_INFO:
            return {
                ...state,
                calculationInfo: action.payload
            };
        case SET_DATA_INPUT_FILE:
            /**
             * insert decimal to new action payload, because existing decimal store at redux state
             * @params compare old decimal and new decimal
             */
            const data: any = addFormatInput(action.payload.fields, state.inputFile.fields);
            const newPayload: any = {
                ...action.payload,
                fields: data
            };
            return {
                ...state,
                inputFile: newPayload
            };
        case SET_DATA_LIST_INPUT_FILES:
            return {
                ...state,
                listInputFiles: action.payload
            };
        case SET_DATA_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case SET_ACTION:
            return {
                ...state,
                loggerAction: action.payload
            };
        case RESET_ACTION:
            return {
                ...state,
                loggerAction: null
            };
        case TOGGLE_PRINT_BUTTON:
            return {
                ...state,
                enablePrint: action.payload
            };
        default:
            return state;
    }
};

export default newLoggerReducer;