/**
 * @params table ID: string
 * @params table Description: string
 * @params table Data: any {
 *  data: Array<Array<CellContent>>
 *  cellStyles: CellStyle
 * }
 * @params cellStyle
 * @params dimensions
 * @params displayedContent (properties displayedContent not yet available in pre-launch endpoint)
 * @params onValueChange
 */

import {
  ButtonWrapper,
  IconButton,
  TableContent,
  TableDescription,
  TableWrapper
} from './styles';
import {
  addRowTableExpand,
  deleteRowTableExpand,
  findLastRow,
  generateSpreadsheetData,
  tableLengthData,
  toggleShowHideTableRow
} from '../../utils';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { FormulaTable } from '../../../../components/molecules/FormulaTable';
import Icon from '../../../../components/atoms/Icon';
import Loading from '../../../../components/atoms/loading';
import StyledButton from '../../../../components/atoms/Button';
import { Typography } from '@mui/material';
import { expandTableDefaultCellStyle } from '../../../../utils/constant';
import parser from 'html-react-parser';
import { theme } from '../../../../utils/theme';
import { GeneratedTableHeaderI } from '../../../printView/utils';

export const Table = forwardRef(
  (
    {
      columnId,
      properties,
      onChange,
      onDeleteRow,
      onInsertRow,
      tableRef,
      onToggleExpand
    }: any,
    ref?: any
  ) => {
    useImperativeHandle(ref, () => {
      return {
        updateTableData: (data: any) => updateDataAfterCloseExpand(data)
      };
    });

    const updateDataAfterCloseExpand = (data: any) => {
      tableRef.current[columnId].setData(data?.data);
      setTable(() => data);
    };

    const [table, setTable] = useState<any>({
      data: [],
      displayedContent: {},
      freeze: null,
      totalRowAdded: [],
      indexHideRowArr: [],
      indexHideColArr: [],
      headers: Array<GeneratedTableHeaderI>
    });

    function onInit() {
      const { cells, displayedContent, freeze, headers } = properties;
      const data = generateSpreadsheetData(
        cells,
        displayedContent,
        freeze,
        true,
        headers
      );
      if (data.indexHideRowArr.length > 0) {
        data.indexHideRowArr.forEach((rowIdx: number) => {
          setTimeout(() => {
            tableRef.current[columnId]?.hideRow(rowIdx);
          }, 10);
        });
      }
      if (data.totalRowAdded.length > 0) {
        data.totalRowAdded.forEach((rowIdx: number) => {
          setTimeout(() => {
            tableRef.current[columnId]?.showRow(rowIdx);
          }, 10);
        });
      }
      setTable(() => data);
    }

    useEffect(() => {
      !!properties.cells && properties.cells?.length > 0 && onInit();
    }, []);

    useEffect(() => {
      const { data, freeze } = table;
      if (!data || !tableRef.current[columnId]) return;
      setTableFreeze(freeze);
      handleElementResized();
    }, [table]);

    const handleButtonClick = (type: 'add' | 'delete' | 'closeExpand') => {
      if (!tableRef.current[columnId]) return;

      switch (type) {
        case 'add':
          handleChangeState('ADD_ROW');
          break;
        case 'delete':
          handleChangeState('DELETE_ROW');
          break;
        default:
          break;
      }
    };

    const handleChangeState = (typeAction: string, payload?: any) => {
      let tableLastIndex: number;
      switch (typeAction) {
        case 'ONCHANGE':
          const { worksheet, cell, col, row, newValue, oldValue } = payload;
          if (!!table.data[row][col]) {
            setTable((prevState: any) => {
              const newData = prevState.data;
              newData[row][col] = {
                ...newData[row][col],
                value: newValue
              };
              return {
                ...prevState,
                data: newData
              };
            });
            onChange(worksheet, cell, col, row, newValue, oldValue);
          }
          break;
        case 'ADD_ROW':
          tableLastIndex = findLastRow(table?.data);
          const newDataAfterInsert: any = addRowTableExpand(
            table?.data,
            table?.displayedContent.column.end + 1
          );
          setTable({
            ...table,
            data: newDataAfterInsert,
            totalRowAdded: [...table.totalRowAdded, tableLastIndex + 1],
            displayedContent: {
              ...table.displayedContent,
              row: {
                ...table.displayedContent.row,
                end: findLastRow(newDataAfterInsert)
              }
            }
          });
          // show added row on workpage
          const latestAddedRow = tableLastIndex + 1;
          toggleShowHideTableRow(
            newDataAfterInsert[latestAddedRow],
            tableRef.current[columnId],
            latestAddedRow,
            table.displayedContent.column.end,
            false
          );
          // todo: trigger onInsertRow to update input file data
          onInsertRow(newDataAfterInsert, columnId);
          break;
        case 'DELETE_ROW':
          tableLastIndex = findLastRow(table?.data);
          const newDataAfterDelete: any = deleteRowTableExpand(
            table?.data,
            tableLastIndex
          );
          const newTotalRowAdd = table.totalRowAdded.filter(
            (el: number) => el !== tableLastIndex
          );
          setTable({
            ...table,
            data: newDataAfterDelete,
            totalRowAdded: newTotalRowAdd,
            displayedContent: {
              ...table.displayedContent,
              row: {
                ...table.displayedContent.row,
                end:
                  newTotalRowAdd.length > 0
                    ? table.displayedContent.row.end - 1
                    : properties.displayedContent.row.end
              }
            }
          });
          // hide latest added row on workpage
          tableRef.current[columnId].hideRow(tableLastIndex);
          // store data to parent component
          onDeleteRow(columnId, tableLastIndex);
          break;
        default:
          break;
      }
    };

    const setTableFreeze = (freeze: { column: number; row: number }) => {
      if (freeze) {
        setTimeout(() => {
          const { column, row } = freeze;
          tableRef.current[columnId].setFreeze(row, column);
        }, 10);
      }
    };

    // VIEW PORT LOGIC
    const [formulaTableSize, setFormulaTableSize] = useState({
      width: 622,
      height: 160
    });
    const handleElementResized = () => {
      setFormulaTableSize({
        ...formulaTableSize,
        height: tableLengthData(table.data) * 24 + 26
      });
    };

    if (table?.data?.length === 0) return <Loading />;

    return (
      <TableWrapper ref={ref}>
        <TableDescription>
          <Typography
            variant="body3"
            component={'span'}
            color={theme.customText.disable}
          >
            {properties.tableName}
          </Typography>
          <Typography variant="body3" component={'span'}>
            {properties.description
              ? parser(properties.description)
              : properties.description}
          </Typography>
        </TableDescription>

        <TableContent>
          <FormulaTable
            ref={(el: any) => (tableRef.current[columnId] = el)}
            size={formulaTableSize}
            name={properties?.tableName?.split(' ').join('') ?? ''}
            data={table.data}
            dimensions={[table.data[0].length, table.data.length]}
            cellStyle={expandTableDefaultCellStyle}
            columnSetting={table.headers}
            hideHiddenCells={true}
            displayedContent={table.displayedContent}
            stickyColumn={table.freeze?.column}
            stickyRow={table.freeze?.row}
            onValueChange={(
              worksheet: Record<string, unknown>,
              cell: any,
              col: any,
              row: any,
              newValue: any,
              oldValue: any
            ) => {
              handleChangeState('ONCHANGE', {
                worksheet,
                cell,
                col,
                row,
                newValue,
                oldValue
              });
            }}
            contextMenu={() => {
              return false;
            }}
            // showHeader={false}
            // showIndex={false}
            onInsertRow={onInsertRow}
            onDeleteRow={onDeleteRow}
            cells={properties.format?.cells}
          />
        </TableContent>

        <ButtonWrapper>
          <IconButton
            sx={{ padding: '6px 10px' }}
            onClick={() =>
              onToggleExpand(
                'OPEN',
                table,
                properties,
                columnId,
                tableRef.current[columnId]
              )
            }
          >
            <Icon icon="expand" color={theme.palette.primary.main} />
          </IconButton>
          <StyledButton
            size="small"
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={() => handleButtonClick('add')}
            disabled={!properties.isAllowedToAddRow}
          >
            Add Row
          </StyledButton>
          <StyledButton
            size="small"
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => handleButtonClick('delete')}
            disabled={table.totalRowAdded.length === 0}
          >
            Delete Row
          </StyledButton>
        </ButtonWrapper>
      </TableWrapper>
    );
  }
);
