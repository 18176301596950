import { styled } from "@mui/material";

export const SidebarWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '15px 0 12px 0',
    "& > #logo": {
        display: 'flex',
        paddingLeft: '16px',
        flexDirection: 'row',
        marginBottom: '12px',
        alignItems: 'center',
        columnGap: '16px'
    },
    "& > #header": {
        display: 'flex',
        backgroundColor: '#F0F2F5',
        borderTop: '1px solid #D6DFEB',
        borderBottom: '1px solid #D6DFEB',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 16px',
        columnGap: '16px'
    },
}));