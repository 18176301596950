import { useCallback, useEffect, useRef, useState } from 'react'
import { AceInfo, ChatItem } from '../components'
import { useAppSelector } from '../../../hooks/hooks'
import { ChatDataT } from '../../../store/reducers/aceReducers'
import { AceError } from './AceError'
import useResizeObserver from '../../../hooks/useResizeObserver'
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2';
import { useMediaQuery } from '@mui/material'
import useCheckConnection from '../../../hooks/useCheckConnection'

interface CustomScrollElement extends HTMLDivElement {
  saveUserScroll?: boolean
}

export const ChatList = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<any>(null);
  const { chatHistories, isLoading, error, isStreaming }: any = useAppSelector(state => state.aceReducer);
  const [isOnline] = useCheckConnection();
  // Start observing the element when the component is mounted
  const onResize = useCallback((target: HTMLDivElement) => {
    const chat = target.parentNode as CustomScrollElement;
    if (!chat.saveUserScroll) {
      chat.scrollTop = chat.scrollHeight - chat.clientHeight
    }
  }, [])
  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);
  const isMobile = !useMediaQuery('(min-width:1080px)');
  const elementRef = useResizeObserver(onResize);

  // use flags to distinguish between user and programatic scroll
  const handleScrollPosition = useCallback((ev: Event) => {
    const element = ev.target as HTMLDivElement;

    element.addEventListener('scroll', (e: any) => {
      if (e.target.scrollTop != e.target.scrollHeight - e.target.clientHeight) {
        e.target.saveUserScroll = true;
      } else {
        e.target.saveUserScroll = false;
      }
    })
  }, [])

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', handleScrollPosition)

    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScrollPosition)
    }
  }, [])

  useEffect(() => {
    if (isStreaming || isLoading) {
      isScrollBottom && scrollBarRef.current.scrollToBottom();
    }
  }, [chatHistories]);

  return (
    <Scrollbars
      style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', width: isMobile ? '100%' : 728 }}
      autoHide
      autoHideTimeout={500}
      ref={scrollBarRef}
      onUpdate={(values: positionValues) => {
        if (values.top > 0.98) {
          setIsScrollBottom(true)
        } else {
          setIsScrollBottom(false)
        }
      }}
    >
      <div ref={scrollRef}>
        <div ref={elementRef}>
          <AceInfo />
          {
            !!chatHistories && chatHistories.map((item: ChatDataT) => <ChatItem key={item.id} isLoading={isLoading} error={error}{...item} isOffline={!isOnline} />)
          }
        </div>
      </div>
      {error || !isOnline ? <AceError isOffline={!isOnline} /> : null}
    </Scrollbars>
  )
}
