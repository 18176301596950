import React from 'react';
import PropTypes from 'prop-types';
import Markdown from './Markdown';

const Answer = ({ content }: Record<string, unknown>) => {
  return (
    <Markdown children={(content as string).replaceAll('<br>', '\n')} />
  );
};

Answer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Answer;
