export const FETCH_REPORT_BY_CALCULATION_ID = "FETCH_REPORT_BY_CALCULATION_ID";
export const FETCH_REPORT_BY_CALCULATION_ID_SUCCESS = "FETCH_REPORT_BY_CALCULATION_ID_SUCCESS";
export const FETCH_REPORT_BY_CALCULATION_ID_FAILED = "FETCH_REPORT_BY_CALCULATION_ID_FAILED";
export const FETCH_CALCULATION_REPORT_LIST = "FETCH_CALCULATION_REPORT_LIST";
export const FETCH_CALCULATION_REPORT_LIST_SUCCESS = "FETCH_CALCULATION_REPORT_LIST_SUCCESS";
export const FETCH_CALCULATION_REPORT_LIST_FAILED = "FETCH_CALCULATION_REPORT_LIST_FAILED";
export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILED = "CREATE_REPORT_FAILED";
export const LOAD_REPORT_LOADING = "LOAD_REPORT_LOADING";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT_FAILED = "LOAD_REPORT_FAILED";
export const RENAME_REPORT = "RENAME_REPORT";
export const FETCH_PAGES = "FETCH_PAGES";
export const UPDATED_REPORT = "UPDATED_REPORT";
export const FETCH_SUMMARY_REPORT = "FETCH_SUMMARY_REPORT";
export const ACTION_REPORT = "ACTION_REPORT";
export const REPORT_CHANGES = "REPORT_CHANGES";
export const REPORT_SAVE_FORMAT = "REPORT_SAVE_FORMAT";
export const REPORT_LOADING = "REPORT_LOADING";
export const UPDATE_REPORT_NAME = "UPDATE_REPORT_NAME";

export const fetchReportByCalculationId = () => {
  return {
    type: FETCH_REPORT_BY_CALCULATION_ID,
  };
};

export const fetchReportByCalculationIdSuccess = (data: any) => {
  return {
    type: FETCH_REPORT_BY_CALCULATION_ID_SUCCESS,
    data,
  };
};

export const fetchReportByCalculationIdFailed = (error: string) => {
  return {
    type: FETCH_REPORT_BY_CALCULATION_ID_FAILED,
    error,
  };
};

export const fetchCalculationReportList = () => {
  return {
    type: FETCH_CALCULATION_REPORT_LIST,
  };
};

export const fetchCalculationReportListSuccess = (data: any) => {
  return {
    type: FETCH_CALCULATION_REPORT_LIST_SUCCESS,
    data,
  };
};

export const fetchCalculationReportListFailed = (error: string) => {
  return {
    type: FETCH_CALCULATION_REPORT_LIST_FAILED,
    error,
  };
};

export const createReport = () => {
  return {
    type: CREATE_REPORT,
  };
};

export const createReportSuccess = (payload: any) => {
  return {
    type: CREATE_REPORT_SUCCESS,
    payload,
  };
};

export const createReportFailed = (error: string) => {
  return {
    type: CREATE_REPORT_FAILED,
    error,
  };
};

export const loadReportLoading = () => {
  return {
    type: LOAD_REPORT_LOADING,
  };
};

export const loadReportSuccess = (payload: any) => {
  return {
    type: LOAD_REPORT_SUCCESS,
    payload,
  };
};

export const loadReportFailed = (error: any) => {
  return {
    type: LOAD_REPORT_FAILED,
    error,
  };
};

export const reportTitle = (payload: any) => {
  return {
    type: RENAME_REPORT,
    payload,
  };
};

export const reportUpdatedAt = (payload: any) => {
  return {
    type: UPDATED_REPORT,
    payload,
  };
};

export const fetchPages = (
  pages: any,
  primaryLogo: string | null,
  secondaryLogo: string | null
) => {
  return {
    type: FETCH_PAGES,
    pages,
    primaryLogo,
    secondaryLogo,
  };
};

export const fetchSummaryReport = (payload: any) => {
  return {
    type: FETCH_SUMMARY_REPORT,
    payload,
  };
};

export const actionReport = (types: string, payload: any) => {
  return {
    type: ACTION_REPORT,
    types,
    payload,
  };
};

export const setReportChanges = (payload: boolean) => {
  return {
    type: REPORT_CHANGES,
    payload,
  };
};

export const setReportSaveFormat = (payload: any) => {
  return {
    type: REPORT_SAVE_FORMAT,
    payload,
  };
};

export const loadingReport = (payload: boolean) => {
  return {
    type: REPORT_LOADING,
    payload,
  };
};

export const updateReportName = (payload: string) => {
  return {
    type: UPDATE_REPORT_NAME,
    payload,
  };
};
