import { useState } from 'react'
import { ChatDataT } from '../../../store/reducers/aceReducers'
import { AceLoading } from './AceLoading'
import { ChatWrapper } from '../styles'
import Icon from '../../../components/atoms/Icon'
import { useAppDispatch } from '../../../hooks/hooks'
import { upsertUserFeedback } from '../../../store/actions/aceActions'
import { HeaderToolbar } from '../components'
import Answer from '../../../components/atoms/MarkDown'
import { matchReplace } from '../ace.utils'
import { AceCitations } from './AceCitations'
import { AceCitationI } from '../askAce.type'

interface AceAnswerPropsI extends Partial<ChatDataT> {
  isLoading?: boolean
  error?: boolean
  isOffline: boolean;
}

export const AceAnswer = ({ id, answer, certainty, upvote, downvote, isLoading, citations, isOffline, sqlCitations }: AceAnswerPropsI) => {
  const dispatch = useAppDispatch();
  const [selectedCitationIdx, setSelectedCitationIdx] = useState<number | null>(null)
  const [isExpanded, setIsExpanded] = useState(false);
  const handleFeedBack = (type: string) => {
    const isUpVote = type === 'LIKE' && !upvote;
    const isDownVote = type === 'DISLIKE' && !downvote;
    dispatch(upsertUserFeedback((id as string), type, isUpVote, isDownVote))
  }

  const handleExpandButton = () => {
    setIsExpanded(prevState => (!prevState));
    setSelectedCitationIdx(null)
  }

  const handleClickUrl = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const handleClickDrawing = (citation: AceCitationI) => {
    const image_window = window.open("", "_blank");
    image_window?.document?.write(`
        <html>
          <head>
          </head>
          <body>
            <img src=${citation.url} alt="${citation.subSection} ${citation.figureNumber}">
          </body>
        </html>
      `)
  }

  const handleClickSqlCitation = (id: string) => {
    window.open(`/ace/citation/${id}`, "_blank");
  }


  if (isLoading && !answer && !isOffline) return <AceLoading />

  return (
    <>
      {!!answer && (<ChatWrapper isAce={true}>
        <HeaderToolbar
          upvote={upvote}
          downvote={downvote}
          textToCopy={answer}
          onFeedback={handleFeedBack}
          confidenceLevel={certainty}
        />
        <div id="chatbox">
          <div id="taylor">
            <Icon icon="talkTaylor" color="#118CFF" size={16} cursor='auto' />
          </div>
          <div id="message">
            <Answer
              content={`${matchReplace([
                [/\\{2}/g, `\\`]
              ], answer)}`}
            />
          </div>
        </div>
        <AceCitations
          citations={citations ?? null}
          sqlCitations={sqlCitations ?? null}
          selectedCitation={selectedCitationIdx}
          setSelectedCitation={(citation: number | null) => setSelectedCitationIdx(citation)}
          isExpanded={isExpanded}
          setIsExpanded={handleExpandButton}
          onClickUrl={handleClickUrl}
          onClickImageUrl={handleClickDrawing}
          onClickSqlCitation={handleClickSqlCitation}
        />
      </ChatWrapper>)}
    </>
  )
}
