import { Dispatch } from "redux";
import SymbolService from "../../../service/SymbolService";

import * as actions from './type';

export const getSymbols = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.GetSymbols());
    try {
      const data = await SymbolService.get();
      dispatch(actions.GetSymbolsSuccess(data));
    } catch (error: any) {
      dispatch(actions.GetSymbolsFailed(error));
    }
  };
};
