import * as React from "react";

export const Copy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="16"
    fill="none"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path d="M9.6665 2.6665H10.9998C11.3535 2.6665 11.6926 2.80698 11.9426 3.05703C12.1927 3.30708 12.3332 3.64622 12.3332 3.99984V13.3332C12.3332 13.6868 12.1927 14.0259 11.9426 14.276C11.6926 14.526 11.3535 14.6665 10.9998 14.6665H2.99984C2.64622 14.6665 2.30708 14.526 2.05703 14.276C1.80698 14.0259 1.6665 13.6868 1.6665 13.3332V3.99984C1.6665 3.64622 1.80698 3.30708 2.05703 3.05703C2.30708 2.80698 2.64622 2.6665 2.99984 2.6665H4.33317"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round" />

    <path d="M9.00016 1.3335H5.00016C4.63197 1.3335 4.3335 1.63197 4.3335 2.00016V3.3335C4.3335 3.70169 4.63197 4.00016 5.00016 4.00016H9.00016C9.36835 4.00016 9.66683 3.70169 9.66683 3.3335V2.00016C9.66683 1.63197 9.36835 1.3335 9.00016 1.3335Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
);


