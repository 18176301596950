import { Lexer } from "chevrotain";
import { generateToken, getLexer, getTokenDict } from "./notation.lexer";
import { NotationParser } from "./notation.parser";
import { IVisitor } from "../latexEngine/visitor";
import { createVisitor as toVariableVisitor } from "./notation-variable.visitor";
import { createVisitor as toLatexVisitor } from './notation-latex.visitor';

export class NotationEngine {
    private parser: NotationParser;
    private toVariableVisitor: IVisitor;
    private toLatexVisitor: IVisitor;
    private lexer: Lexer;

    constructor(data: { [key: string]: string | number | null }[]) {
        const tokens = generateToken(data);
        const dict = getTokenDict(tokens);

        this.parser = new NotationParser(dict);
        this.toVariableVisitor = toVariableVisitor(this.parser);
        this.toLatexVisitor = toLatexVisitor(this.parser);
        this.lexer = getLexer(tokens);
    }

    toVariable(notation: string) {
        const cst = this.getCst(notation);
        const result = this.toVariableVisitor.visit(cst);

        return result;
    };

    toLatex(notation: string) {
        const cst = this.getCst(notation);
        const result = this.toLatexVisitor.visit(cst);

        return result;
    };

    private getCst(formula: string) {
        const lexRes = this.tokenize(formula);
        const cst = this.parse(lexRes);
        return cst;
    }

    private tokenize(formula: string) {
        const lexRes = this.lexer.tokenize(formula);

        return lexRes;
    }

    private parse(lexRes: any) {
        this.parser.reset();
        this.parser.input = lexRes.tokens;
        const cst = this.parser.expression();

        return cst;
    }
};