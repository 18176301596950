import { styled } from "@mui/material";
import { LegendItemPropsI, SymbolItemStylePropsI, ToolkitPropsI } from "./types";

export const ToolkitWrapper = styled('div')<ToolkitPropsI>(({ theme, type }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  gap: '24px',
  padding: '24px',
  backgroundColor: '#FFFFFF',
  border: '0.5px #D6DFEB solid',
  overflowY: 'auto',
  ...(type === 'SYMBOL' && {
    border: 'unset',
    borderBottomRightRadius: '6px',
    borderTopRightRadius: '6px',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)',
  }),
  ...(type === 'VARIABLE_MANAGER' && {
    flex: 2
  })
}));

export const HeadSection = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '20px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const SymbolSection = styled('div')(({ theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '7px',
}));

export const SymbolItem = styled('button')<SymbolItemStylePropsI>(({ theme, currentFocus }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32px',
  width: '32px',
  border: 'none',
  borderRadius: '6px',
  cursor: currentFocus?.includes('notation') ? 'pointer' : 'default',
  transition: 'box-shadow 1s',
  ...(currentFocus?.includes('notation') && 
  {
    "&:hover": {
      backgroundColor: '#E3F3FF',
    },
    "&:active": {
      boxShadow: '0 0 10px #6495ED',
      transitionDuration: '0.05s',
    },
  }
  )
}));

export const LegendSection = styled('div')(({ theme}) => ({
  gap: '7px',
}));

export const Legend = styled('div')(({ theme}) => ({
  display: 'flex',
  marginBottom: '12px'
}));

export const LegendItem = styled('button')<LegendItemPropsI>(({ theme, borderWeight, borderColor, color }) => ({
  alignItems: 'center',
  backgroundColor: color,
  border: borderColor && borderWeight ? `${borderWeight} ${borderColor} solid` : 'none',
  borderRadius: '6px',
  display: 'flex',
  height: '18px',
  justifyContent: 'center',
  marginRight: '12px',
  transition: 'box-shadow 1s',
  width: '18px',
}));

export const SearchWrapper = styled('div')(( { theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 12px',
  border: '1px solid #E0E6EB',
  borderRadius: '6px',
}));

export const CardsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));
