import React from "react";

import Icon from "../../../atoms/Icon";
import { BooleanCheck, BooleanText, BooleanWrapper } from "./styles";

import { BooleanPropsI } from "./type";

const Boolean = ({value, text, clicked}: BooleanPropsI) => {
  function click (e: React.SyntheticEvent) {
    value === true ? clicked(e, false) : clicked(e, true);
  }

  return (
    <BooleanWrapper>
      <BooleanCheck onClick={(e: React.SyntheticEvent) => click(e)}>
        {
          value === true ? (
            <Icon icon="check" size={24} />
          ) : (
            <Icon icon="uncheck" size={24} />
          )
        }
      </BooleanCheck>
      <BooleanText onClick={(e: React.SyntheticEvent) => click(e)}>
        {text}
      </BooleanText>
    </BooleanWrapper>
  );
};

export default Boolean;