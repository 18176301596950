import styled from "styled-components";
interface Props {
  type?: string;
}

export const tableRowStyle = {
  marginTop: "20px",
  display: "flex",
  // flexDirection: "row",
  backgroundColor: "#F5F7FA",
};

export const headerIconStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "10px",
} as const;

export const moreVertStyle = {
  backgroundColor: "#F0F2F5",
  width: "32px",
  height: "32px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderRadius: "5px",
  alignSelf: "center",
};

export const triangleShapeUp = {
  width: "0",
  height: "0",
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderBottom: "5px solid #686D8E",
  marginBottom: "2px",
};

export const triangleShapeDown = {
  width: "0",
  height: "0",
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderTop: "5px solid #686D8E",
};

export const TextRole = styled.h1({
  fontWeight: 700,
  fontSize: '14px',
});

export const WrapperCenterCell = styled.div<Props>((props) => ({
  margin: "auto",
  width: props.type === "ROLE"? "75%" : props.type === "ICON" ? "40%" : props.type === "NO_RESULT" ? "10%" : "0%"
}));


