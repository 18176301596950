import styled from "styled-components";

interface IContainerProps {
  showHeader: boolean;
}
export const Container = styled.div((props: IContainerProps) => ({
  '& .jss > thead > tr > td': {
    padding: props.showHeader ? '6px' : '0px !important',
    borderTop: props.showHeader ? '1px solid #ccc' : 'none !important',
    borderLeft: props.showHeader ? '1px solid #ccc' : 'none !important',
    borderRight: props.showHeader ? '1px solid transparent' : 'none !important',
    borderBottom: props.showHeader ? '1px solid transparent' : 'none !important',
  },
}));
