import { styled } from "@mui/material";

interface ContainerProps {
    sticky: boolean
}

export const SidebarContainer = styled('div')({
    display: 'flex',
});

export const StickyWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== "sticky"
})<ContainerProps>(({ sticky }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: sticky ? 'fixed' : 'inherit',
    gap: 8
}));