export function mapExpression({ lhs, operator, rhs }: any) {
  const operatorMapper: any = {
    "*": ({ lhs, rhs }: any) => `${lhs}\\times ${rhs}`,
    "*p": ({ lhs, rhs }: any) => `${lhs}\\cdot ${rhs}`,
    "/": ({ lhs, rhs }: any) => `\\frac{${lhs}}{${rhs}}`,
    "^": ({ lhs, rhs }: any) => `${lhs}^{${rhs}}`
  };

  if (!operatorMapper[operator]) return lhs + operator + rhs;
  return operatorMapper[operator]({ lhs, rhs });
}

export function mapFunction({ name, args, operator }: any) {
  const funcMapper: any = {
    "SUM": (args: any) => `\\sum(${args})`,
    "AVERAGE": (args: any) => `avg(${args})`,
    "MAX": (args: any) => `max(${args})`,
    "MIN": (args: any) => `min(${args})`,
  };

  if (operator === ':') {
    return 'error';
  }

  if ((name === 'SQRT' || name === 'FACT') && args.length > 1) {
    return 'error';
  } else if ((name === 'SUM' || name === 'AVERAGE' || name === 'MAX' || name === 'MIN') && args.length < 2) {
    return 'error';
  }

  if (funcMapper[name]) return funcMapper[name](args);
}

export function mapMathOperandExpression({ math, args, operator, ext }: any) {
  const funcMapper: any = {
    // PLUS
    "ASINH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{asinh}\\,(${args[0]})` : `\\mathsf{asinh}\\,${args[0]}`,
    "ACOSH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{acosh}\\,(${args[0]})` : `\\mathsf{acosh}\\,${args[0]}`,
    "ATANH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{atanh}\\,(${args[0]})` : `\\mathsf{atanh}\\,${args[0]}`,
    "ASIN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{asin}\\,(${args[0]})` : `\\mathsf{asin}\\,${args[0]}`,
    "ACOS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{acos}\\,(${args[0]})` : `\\mathsf{acos}\\,${args[0]}`,
    "ATAN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{atan}\\,(${args[0]})` : `\\mathsf{atan}\\,${args[0]}`,
    "ACOT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{acot}\\,(${args[0]})` : `\\mathsf{acot}\\,${args[0]}`,
    "SINH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{sinh}\\,(${args[0]})` : `\\mathsf{sinh}\\,${args[0]}`,
    "COSH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{cosh}\\,(${args[0]})` : `\\mathsf{cosh}\\,${args[0]}`,
    "TANH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{tanh}\\,(${args[0]})` : `\\mathsf{tanh}\\,${args[0]}`,
    "COTH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{coth}\\,(${args[0]})` : `\\mathsf{coth}\\,${args[0]}`,
    "CSCH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{csch}\\,(${args[0]})` : `\\mathsf{csch}\\,${args[0]}`,
    "SIN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{sin}\\,(${args[0]})` : `\\mathsf{sin}\\,${args[0]}`,
    "COS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{cos}\\,(${args[0]})` : `\\mathsf{cos}\\,${args[0]}`,
    "TAN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{tan}\\,(${args[0]})` : `\\mathsf{tan}\\,${args[0]}`,
    "COT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{cot}\\,(${args[0]})` : `\\mathsf{cot}\\,${args[0]}`,
    "CSC": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathsf{csc}\\,(${args[0]})` : `\\mathsf{csc}\\,${args[0]}`,

    "DEGREES": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `(180/\\pi).(${args[0]})^\\circ` : `(180/\\pi).${args[0]}^\\circ`,

    "RADIANS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `(\\pi/180).(${args[0]})` : `(\\pi/180).${args[0]}`,

    "EXP": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathrm{e}^(${args[0]})` : `\\mathrm{e}^${args[0]}`,

    "ROUNDDOWN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\lfloor (${args[0]})\\rfloor ` : `\\lfloor ${args[0]}\\rfloor `,
    "ROUNDUP": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\lceil (${args[0]})\\rceil ` : `\\lceil ${args[0]}\\rceil `,
    "ROUND": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\lfloor (${args[0]})\\rceil ` : `\\lfloor ${args[0]}\\rceil `,
    "CEILING": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\lceil (${args[0]})\\rceil ` : `\\lceil ${args[0]}\\rceil `,
    "FLOOR": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\lfloor (${args[0]})\\rfloor ` : `\\lfloor ${args[0]}\\rfloor `,
    "ABS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\vert (${args[0]})\\vert ` : `\\vert ${args[0]}\\vert `,

    "LN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathrm{e}^{ln\\,(${args[0]})}` : `\\mathrm{e}^{ln\\,${args[0]}}`,
    "LOGNUM": (args: any, ext: any) => /[\+\-\*\/]/.test(args[0]) ? `\\log_{${ext}}(${args[0]})` : `\\log_{${ext}}(${args[0]})`,
    "LOG": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\log_{(${args[0]})}` : `\\log_{${args[0]}}`,

    "MOD": (args: any) => `${args[0]}\\,\\bmod\\,${args[1]}\\,\\equiv `,

    "SQRT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\sqrt{(${args})}` : `\\sqrt{${args}}`,
    "FACT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `(${args})!` : `${args}!`,

    // MINUS
    "-ASINH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{asinh}\\,(${args[0]})` : `-\\mathsf{asinh}\\,${args[0]}`,
    "-ACOSH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{acosh}\\,(${args[0]})` : `-\\mathsf{acosh}\\,${args[0]}`,
    "-ATANH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{atanh}\\,(${args[0]})` : `-\\mathsf{atanh}\\,${args[0]}`,
    "-ASIN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{asin}\\,(${args[0]})` : `-\\mathsf{asin}\\,${args[0]}`,
    "-ACOS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{acos}\\,(${args[0]})` : `-\\mathsf{acos}\\,${args[0]}`,
    "-ATAN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{atan}\\,(${args[0]})` : `-\\mathsf{atan}\\,${args[0]}`,
    "-ACOT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{acot}\\,(${args[0]})` : `-\\mathsf{acot}\\,${args[0]}`,
    "-SINH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{sinh}\\,(${args[0]})` : `-\\mathsf{sinh}\\,${args[0]}`,
    "-COSH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{cosh}\\,(${args[0]})` : `-\\mathsf{cosh}\\,${args[0]}`,
    "-TANH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{tanh}\\,(${args[0]})` : `-\\mathsf{tanh}\\,${args[0]}`,
    "-COTH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{coth}\\,(${args[0]})` : `-\\mathsf{coth}\\,${args[0]}`,
    "-CSCH": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{csch}\\,(${args[0]})` : `-\\mathsf{csch}\\,${args[0]}`,
    "-SIN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{sin}\\,(${args[0]})` : `-\\mathsf{sin}\\,${args[0]}`,
    "-COS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{cos}\\,(${args[0]})` : `-\\mathsf{cos}\\,${args[0]}`,
    "-TAN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{tan}\\,(${args[0]})` : `-\\mathsf{tan}\\,${args[0]}`,
    "-COT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{cot}\\,(${args[0]})` : `-\\mathsf{cot}\\,${args[0]}`,
    "-CSC": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathsf{csc}\\,(${args[0]})` : `-\\mathsf{csc}\\,${args[0]}`,

    "-DEGREES": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-(180/\\pi).(${args[0]})^\\circ` : `-(180/\\pi).${args[0]}^\\circ`,

    "-RADIANS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-(\\pi/180).(${args[0]})` : `-(\\pi/180).${args[0]}`,

    "-EXP": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `\\mathrm{e}^(${args[0]})` : `-\\mathrm{e}^${args[0]}`,

    "-ROUNDDOWN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\lfloor (${args[0]})\\rfloor ` : `-\\lfloor ${args[0]}\\rfloor `,
    "-ROUNDUP": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\lceil (${args[0]})\\rceil ` : `-\\lceil ${args[0]}\\rceil `,
    "-ROUND": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\lfloor (${args[0]})\\rceil ` : `-\\lfloor ${args[0]}\\rceil `,
    "-CEILING": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\lceil (${args[0]})\\rceil ` : `-\\lceil ${args[0]}\\rceil `,
    "-FLOOR": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\lfloor (${args[0]})\\rfloor ` : `-\\lfloor ${args[0]}\\rfloor `,
    "-ABS": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\vert (${args[0]})\\vert ` : `-\\vert ${args[0]}\\vert `,

    "-LN": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\mathrm{e}^{ln\\,(${args[0]})}` : `-\\mathrm{e}^{ln\\,${args[0]}}`,
    "-LOGNUM": (args: any, ext: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\log_{${ext}}(${args[0]})` : `-\\log_{${ext}}(${args[0]})`,
    "-LOG": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\log_{(${args[0]})}` : `-\\log_{${args[0]}}`,

    "-MOD": (args: any) => `-${args[0]}\\,\\bmod\\,${args[1]}\\,\\equiv `,

    "-SQRT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-\\sqrt{(${args})}` : `-\\sqrt{${args}}`,
    "-FACT": (args: any) => /[\+\-\*\/]/.test(args[0]) ? `-(${args})!` : `-${args}!`,
  };

  if (operator === ':') {
    return 'error';
  } else if (operator === ',') {
    if (math === "LOG") {
      if (args.length > 2) {
        return 'error';
      } else {
        funcMapper["LOG"] = (args: any) => `^${args[1]}\\log ${args[0]}`;
      }
    } else if (math === 'MOD') {
      if (args.length > 2 || args.length < 2) {
        return 'error';
      }
    } else {
      return 'error';
    }
  }

  if (funcMapper[math]) return funcMapper[math](args, ext);
}

export function mapPercentage(text: any, type?: string) {
  return type === 'minus' ? text.substring(1, text.length - 1) + '\\' + text.substring(text.length - 1) : text.substring(0, text.length - 1) + '\\' + text.substring(text.length - 1);
}

export function mapNegative(text: any) {
  console.log('\\lparen' + text + '\\rparen');

  return text[0] == '-' ? '\\lparen' + text + '\\rparen' : text;
}