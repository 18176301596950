import React from 'react';
import { Button } from '@mui/material';
import { buttonStyle } from '../../pageElements/accountManager';
import TypographyComponent from '../../components/atoms/Typography';
import {
  BillingFooterWrapper,
  BillingFooterTextWrapper
} from '../../components/molecules/BillingTable/elements';
import { useDispatch } from 'react-redux';
import { handleChangeBillingPaymentStatus } from '../../utils/helpersFunction';

const ContentFooterSection = (props: any) => {
  const { numSelected, selectedData } = props;
  const dispatch = useDispatch();

  return (
    <BillingFooterWrapper>
      <BillingFooterTextWrapper>
        <TypographyComponent
          variant={'SUBTITLE_2'}
          style={{ fontWeight: 'bold', fontSize: 16 }}
        >
          {numSelected}
        </TypographyComponent>
        <TypographyComponent variant={'SUBTITLE_2'} style={{ fontSize: 16 }}>
          billing{numSelected > 1 ? 's' : ''} selected
        </TypographyComponent>
      </BillingFooterTextWrapper>
      <Button
        sx={buttonStyle}
        variant="contained"
        style={{ marginRight: 35, marginTop: 5 }}
        onClick={() =>
          handleChangeBillingPaymentStatus({ dispatch, selectedData })
        }
      >
        Change Payment Status
      </Button>
    </BillingFooterWrapper>
  );
};

export default ContentFooterSection;
