import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = ({ children, index, value, ...restProps }: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`default-tabpanel-${index}`}
            aria-labelledby={`default-tab-${index}`}
            {...restProps}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                {children}
                </Box>
            )}
        </div>
  );
};

export const TabProps = (index: number) => {
    return {
        id: `default-tab-${index}`,
        'aria-controls': `default-tabpanel-${index}`,
    };
};