import { CstParser } from "chevrotain";
import { TokenTypeDict } from "./lexer";

export class FormulaParser extends CstParser {
  tokens: TokenTypeDict;

  constructor(dict: TokenTypeDict) {
    super(dict, {
      maxLookahead: 1
    });
    this.tokens = dict;
    this.performSelfAnalysis();
  }

  expression = this.RULE("expression", () => {
    this.CONSUME(this.tokens.Equal);
    this.OR([
      { ALT: () => this.SUBRULE(this.additionExpression) },
      { ALT: () => this.SUBRULE(this.functionExpression) },
    ]);
  });

  additionExpression = this.RULE("additionExpression", () => {
    this.SUBRULE(this.multiplicationExpression, { LABEL: "lhs" });
    this.MANY(() => {
      this.CONSUME(this.tokens.AdditionOperator, { LABEL: "operator" });
      this.SUBRULE2(this.multiplicationExpression, { LABEL: "rhs" });
    });
  });

  multiplicationExpression = this.RULE("multiplicationExpression", () => {
    this.SUBRULE(this.exponentiationExpression, { LABEL: "lhs" });
    this.MANY(() => {
      this.CONSUME(this.tokens.MultiplicationOperator, { LABEL: "operator" });
      this.SUBRULE2(this.exponentiationExpression, { LABEL: "rhs" });
    });
  });

  exponentiationExpression = this.RULE("exponentiationExpression", () => {
    this.SUBRULE(this.operandExpression, { LABEL: "lhs" });
    this.MANY(() => {
      this.CONSUME(this.tokens.ExponentiationOperator, { LABEL: "operator" });
      this.SUBRULE2(this.operandExpression, { LABEL: "rhs" });
    });
  });

  parenthesisExpression = this.RULE("parenthesisExpression", () => {
    this.CONSUME(this.tokens.LParen);
    this.SUBRULE(this.additionExpression);
    this.CONSUME(this.tokens.RParen);
  });

  mathOperandExpression = this.RULE("mathOperandExpression", () => {
    this.OR([
      { ALT: () => this.CONSUME(this.tokens.Trigonometry) },
      { ALT: () => this.CONSUME(this.tokens.Degrees) },
      { ALT: () => this.CONSUME(this.tokens.Radians) },
      { ALT: () => this.CONSUME(this.tokens.Exponential) },
      { ALT: () => this.CONSUME(this.tokens.Rounding) },
      { ALT: () => this.CONSUME(this.tokens.LogNatural) },
      { ALT: () => this.CONSUME(this.tokens.LogNumeric) },
      { ALT: () => this.CONSUME(this.tokens.Log) },
      { ALT: () => this.CONSUME(this.tokens.Modulus) },
      { ALT: () => this.CONSUME(this.tokens.Cript) },
    ]);
    this.CONSUME(this.tokens.LParen);
    this.MANY_SEP({
      SEP: this.tokens.CommaColon,
      DEF: () => {
        this.SUBRULE(this.additionExpression, { LABEL: "arguments" });
      }
    });
    this.CONSUME(this.tokens.RParen);
  });

  operandExpression = this.RULE("operandExpression", () => {
    this.OR([
      { ALT: () => this.SUBRULE(this.parenthesisExpression) },
      { ALT: () => this.SUBRULE(this.mathOperandExpression) },
      { ALT: () => this.CONSUME(this.tokens.PI) },
      { ALT: () => this.CONSUME(this.tokens.Constant) },
      { ALT: () => this.CONSUME(this.tokens.PercentageNumber) },
      { ALT: () => this.CONSUME(this.tokens.SuperNumber) },
    ]);
  });

  functionExpression = this.RULE("functionExpression", () => {
    this.CONSUME(this.tokens.FunctionExpression, { LABEL: "functionName" });
    this.CONSUME(this.tokens.LParen);
    this.MANY_SEP({
      SEP: this.tokens.CommaColon,
      DEF: () => {
        this.SUBRULE(this.operandExpression, { LABEL: "arguments" });
      }
    });
    this.CONSUME(this.tokens.RParen);
  });

}
