import api from "./api";

const NEW_URL = 'pre-launch/input-file';
const URL = 'input-file';
const URLs = 'input-files';
// TODO: define structure data for the entity
type InputFile = any;

const InputFileService = {
  getById: async (id: string): Promise<InputFile> => {
    const response = await api.get(`${URL}/${id}`);
    return response.data.data;
  }, 
  getByIdNew: async (id: string): Promise<InputFile> => {
    const response = await api.get(`${URL}/${id}`);
    return response.data.data;
  }, 
  filterByCalculationId: async (calculationId: string): Promise<Array<InputFile>> => {
    const response = await api.get(URL, { params: { calculationId } });
    return response.data.data;
  },
  create: async (data: InputFile) => {
    const response = await api.post(URL, data);
    return response.data.data;
  },
  saveAs: async (data: InputFile) => {
    const response = await api.post(`${URL}/save-as`, data);
    return response.data.data;
  },
  update: async (id: string, data: InputFile) => {
    const response = id === 'temp' ? await api.put(`${URL}`, data) : await api.put(`${URL}/${id}`, data);
    return response.data.data;
  },
  rename: async (id: string, title: string) => {
    const response = await api.put(`${URL}/${id}/title`, { title });
    return response.data.data;
  },
  uploadLogo: async (id: string, payload: FormData) => {
    const response = await api.put(`${URL}/${id}/logo`, payload);
    return response.data.data;
  },
  softDelete: async (id: string) => {
    await api.delete(`${URL}/softdelete/${id}`);
  },
  lastUpdated: async (calcId: string): Promise<Array<InputFile>> => {
    const response = await api.get(`${URLs}/last-updated`, { params: { calcId } });
    return response.data.data;
  },
  checkValueChange: async (id: string, data: InputFile) => {
    const response = await api.post(`${URL}/${id}/check-value-change`, data);
    return response.data.data;
  },
  temp: async (calcId: string): Promise<InputFile> => {
    const response = await api.get(`${URL}/sample/${calcId}`);
    return response.data.data;
  },

  // PRE LAUNCH URL
  getInputFileById: async (id: string): Promise<InputFile> => {
    const response = await api.get(`${URL}/${id}`);
    return response.data.data;
  }, 
  getTempInputFile: async (calcId: string): Promise<InputFile> => {
    const response = await api.get(`${URL}/sample/${calcId}`);
    return response.data.data;
  },
  saveInputFile: async (data: any): Promise<InputFile> => {
    const response = await api.put(URL, data);
    return response.data.data;
  },
  saveAsInputFile: async (data: any): Promise<InputFile> => {
    const response = await api.post(`${URL}/save-as`, data);
    return response.data.data;
  }
};

export default InputFileService;
