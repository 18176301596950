import FormulaHandler from "../pages/logger/components/TableComponent/formulaHandler";
import { tableDataSample } from "./constant";
import { Row } from "@silevis/reactgrid";
import {
  tableHeaderStyle,
  tableRowStyleDisabled,
  tableRowStyle,
} from "../pages/logger/components/TableComponent/elements";
import { isNumeric } from "./helpersFunction";

//conversion from BE output to reactgrid format easy to read
export const splitRow = (arr: any) => {
  const output = [];
  let last = 0;
  for (let i = 1; i <= arr.length; i++) {
    if (arr[i]?.rowIdx !== arr[i - 1]?.rowIdx) {
      output.push(arr.slice(last, i));
      last = i;
    }
  }
  return output;
};

//for testing dummy data
export const dataTable = {
  name: tableDataSample?.properties?.description,
  columns: [
    { label: "Ayam", width: 100 },
    { label: "Sapi", width: 76 },
  ],
  rows: splitRow(tableDataSample.properties.cells),
  properties: tableDataSample?.properties,
};

//convert to reactgrid data
export const convertDataTable = (columnId: string, properties: any) => {
  const maxColumn = splitRow(properties.cells).map((a: any) =>
    Math.max(...a.map((o: any) => o.colIdx), 0)
  );
  const columnLabel = Array.from(Array(Math.max(...maxColumn) + 1).keys()).map(
    (a: any) => {
      return {
        label: String.fromCharCode(a + 1 + 64),
        width: properties?.headers.find((b: any, i: number) => i === a).width, //{width: 76}
      };
    }
  );

  const headerCell = properties.cells.filter((element: any) => element.isHeader); //find header row (isHeader = true)
  const dataCell = properties.cells.filter((element: any) => !element.isHeader); //find data cell (isHeader = false)
  const headerTrue = splitRow(headerCell)[0];
  const headerFalse = splitRow(properties.cells)[0].filter((el: any) => el.showToLogger).map(
    (a: any) => {
      //when there is no header
      return {
        ...a,
        value: "",
      };
    }
  );
  const headerFalseMapping = headerFalse.map((a: any, index: number) => {
    //when there is no header
    return {
      label: "",
      width: properties?.headers.find((b: any, i: number) => i === index).width,
    };
  });

  return {
    columnId: columnId,
    name: properties?.description,
    columns: columnLabel,
    rows: splitRow(dataCell),
    properties: properties,
    header:
      headerCell.length > 0
        ? headerTrue.map((a: any, index: number) => {
          return {
            label: a.value,
            width: properties?.headers.find(
              (b: any, i: number) => i === index
            ).width,
          };
        })
        : headerFalseMapping,
    headerRow: headerCell.length > 0 ? headerTrue : headerFalse,
    isHeader: headerCell.length > 0 ? true : false
  };

};


// TABLE LOGGER FUNCTION

export const filterShowLogger = (arr: any) => {
  const result = arr.filter((item: any) => {
    if (item.some((c: any) => c.showToLogger)) {
      return item;
    }
  });
  return filterShowEachRow(result);
};

export const filterHideLogger = (arr: any) => {
  const result = arr.filter((item: any) => {
    if (item.some((c: any) => !c.showToLogger)) {
      return item;
    }
  });
  return result;
};

//modify showToLogger,isAdded = true
export function modifyRows(dataRowsAll: any, dataRows: any, type?: string) {
  const showingRow: number = dataRows[0].length;
  try {
    if (type === "ADD" && dataRowsAll.length !== dataRows.length) {
      //ADD ROW FOR SHOWING HIDDEN ROW
      const editedRow = dataRowsAll[dataRows.length].map((item: any) =>
        !item.showToLogger && item.colIdx < showingRow
          ? { ...item, showToLogger: true, isAdded: true }
          : item
      );
      return Promise.resolve(editedRow);
    } else if (type === "DELETE") {
      //DELETE ROW FOR HIDING SHOW ROW
      const editedRow = dataRowsAll[dataRows.length - 1].map(
        (item: any) =>
          item.showToLogger
            ? { ...item, showToLogger: false, isAdded: false }
            : item
      );
      return Promise.resolve(editedRow);
    } else {
      const newRow = dataRowsAll[dataRows.length - 1].map((a: any) => {
        //ADD REAL ROW
        return {
          ...a,
          rowIdx: a.rowIdx + 1,
          value: "",
          readOnly: false,
          isAdded: true,
        };
      });
      return Promise.resolve(newRow);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const minusTable = (arr: any) => { //REMOVE TABLE COMPONENT FROM PAGES
  const result = arr?.map((a: any) => {
    return {
      pageTitle: a.pageTitle,
      rows: a.rows.filter((obj: any) => obj.columns[0].type !== "TABLE"),
    };
  });
  return result;
};

export const addTableData = (arr: any, savingTableData: any) => { //REMOVE AND REPLACE TABLE COMPONENT WITH NEW TABLE DATA
  const result = arr?.map((a: any) => {
    return {
      pageTitle: a.pageTitle,
      rows: a.rows.filter((obj: any) => obj.columns[0].type !== "TABLE"),
    };
  });

  result?.map((item: any, index: number) => {
    const findTable = savingTableData.filter((a: any) => a.pageIndex === index);
    if (findTable.length === 0) {
      return {
        pageTitle: item.pageTitle,
        rows: item.rows,
      };
    } else if (findTable.length === 1) {
      return {
        pageTitle: item.pageTitle,
        rows: item.rows.splice(findTable[0].rowIndex, 0, findTable[0].rows),
      };
    } else if (findTable.length > 1) {
      findTable.map((a: any) => {
        return {
          pageTitle: item.pageTitle,
          rows: item.rows.splice(a.rowIndex, 0, a.rows),
        };
      });
    }
  });
  return result;
};

//create data structure for save table
export const savingTableProgress = (
  activePage: number,
  rowIndex: number,
  dataRowsAll: any,
  columnId: string
) => {
  const cells: any = [];
  dataRowsAll.forEach((item: any) => {
    item?.map((b: any) => {
      cells.push({ ...b });
    });
  });

  return {
    columnId: columnId,
    pageIndex: activePage,
    rowIndex: rowIndex,
    rows: {
      columns: [
        {
          columnId: columnId,
          properties: { cells: cells },
        },
      ],
    },
  };
};

//check the table need horizontal scrolling or not

export const checkScrollingTable = (columns: any) => {
  const result: any = columns
    ?.map((item: any) => item.width)
    .reduce((prev: any, curr: any) => prev + curr, 0);
  let resultCheck = false;
  if (result < 636) {
    resultCheck = false;
  } else {
    resultCheck = true;
  }
  return resultCheck;
};

export const checkVerticalScroll = (rows: any): boolean => {
  const numberOfMaxRow = 11;
  return rows.length >= numberOfMaxRow;
};

export const getColumns = async (header: any) => {
  try {
    const columns = header?.map((column: any, idx: number) => {
      return {
        columnId: idx,
        width: column.width,
      };
    });

    return Promise.resolve(columns);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const renderTableContent = async (
  id: any,
  payload?: any,
  type?: string
) => {
  try {
    const sheet: any = [];
    //Filter showToLogger True only
    let processedDataRows;

    if (type === "COLUMN") {
      processedDataRows = filterShowLogger(payload).map((rowData: any) => {
        const row: Array<string> = [];
        const data = rowData.map((data: any) => {
          row.push(data.value?.text); //add text object
          return data;
        });

        const rowMapping: Array<string> = [];
        rowData.map((data: any) => {
          rowMapping.push(data.value); //add text object
        });
        const rowMappingResult = [...columnMapper(payload), ...rowMapping];
        sheet.push(rowMappingResult);
        return data;
      });
    } else {
      processedDataRows = filterShowLogger(payload).map((rowData: any) => {
        const row: Array<string> = [];
        const data = rowData.map((data: any) => {
          row.push(data.value); //add text object
          return data;
        });

        const rowMapping: Array<string> = [];
        rowData.map((data: any) => {
          rowMapping.push(data.value); //add text object
        });
        const rowMappingResult = [...columnMapper(payload), ...rowMapping];
        sheet.push(rowMappingResult);
        return data;
      });
    }
    FormulaHandler.setSheet(id, [...rowMapper(payload), ...sheet]);
    return Promise.resolve(processedDataRows);
  } catch (error: any) {
    console.error(error);
  }
};

const getHeaderRow = (
  header: any,
  originalHeader?: any,
  isHeader?: any
): Row => {
  return {
    rowId: "header",
    cells: header.map((column: any, idx: number) => {
      if (idx > originalHeader?.length - 1) {
        return isHeader
          ? { type: "formula", text: column.label, style: tableHeaderStyle }
          : { type: "header", text: column.label, style: tableHeaderStyle };
      } else {
        return { type: "header", text: column.label, style: tableHeaderStyle };
      }
    }),
  };
};

export const getRows = (
  payload?: any,
  headerRow?: any,
  originalHeader?: string,
  isHeader?: boolean,
) => {
  const header = getHeaderRow(headerRow, originalHeader, isHeader);

  const rows = [
    header,
    ...payload.map((row: any, idx: number) => {
      const rowData = {
        rowId: idx,
        cells: row.map((rowData: any) => {
          const { value, readOnly } = rowData;
          return {
            type: "formula",
            text: value,
            nonEditable: !!readOnly,
            style: readOnly ? tableRowStyleDisabled : tableRowStyle,
          };
        }),
      };
      return rowData;
    }),
  ];


  return Promise.resolve(rows);
};

const endSelectedCell = (changes: any, direction: any) => {
  if (direction == 'horizontal') {
    return {
      rowId: Math.max(changes.map((item: any) => { return item.rowId; })),
      columnId: Math.min(changes.map((item: any) => { return item.columnId; })) - 1
    };
  } else {
    return {
      rowId: Math.min(changes.map((item: any) => { return item.rowId; })) - 1,
      columnId: Math.max(changes.map((item: any) => { return item.columnId; }))
    };
  }
};

const getDirection = (start: any, changes: any) => {
  const minRowId = Math.min(changes.map((item: any) => { return item.rowId; }));
  const minColumnId = Math.min(changes.map((item: any) => { return item.columnId; }));

  if (minRowId == start.rowId) return 'horizontal';
  if (minColumnId == start.columnId) return 'vertical';

  return null;
};

const checkIfContainFormula = (rows: any, start: any, end: any): boolean => {
  for (let i: any = start.rowId; i <= end.rowId; i++) {
    for (let j: any = start.columnId; j <= end.columnId; j++) {
      if (FormulaHandler.validate(rows[i][j].value)) return true;
    }
  }
  return false;
};

function relativeUpdate(sheetId: any, rows: any, rowsAll: any, changes: any, startingCopy: any, startingPaste: any) {
  changes.forEach((change: any) => {
    const { rowId, columnId } = change;

    const toCopyColumnId = columnId - startingPaste.columnId + startingCopy.columnId;
    const toCopyRowId = rowId - startingPaste.rowId + startingCopy.rowId;

    const pointCopy = {
      sheet: sheetId,
      col: toCopyColumnId,
      row: toCopyRowId
    };

    FormulaHandler.copy(pointCopy, pointCopy);

    const dest = { sheet: sheetId, col: columnId, row: rowId };
    const updatedCells = FormulaHandler.paste(dest);

    updatedCells.forEach((change: any) => {
      const { row, col, value } = change;

      const formula = FormulaHandler.getCellFormula({ sheet: sheetId, col, row });
      const content = typeof formula == 'undefined' ? value.toString() : formula;

      rows[row][col] = { ...rows[row][col], value: content };
      rowsAll[row][col] = { ...rowsAll[row][col], value: content };
    });
  });
  return { rows, rowsAll };
}

export const handleChangesHandler = (
  changes: any,
  dataRows: any,
  dataRowsAll: any,
  header: any,
  sheetId: any,
  selectedCell: any,
  isPasting: boolean,
  startCopy: any,
  newHeader?: any,
) => {
  const headers = header;

  const changeNewHeader = newHeader;
  if (changes[0].rowId === "header") {
    const newDatarows = dataRows;
    const newDatarowsAll = dataRowsAll;
    changes.forEach((change: any) => {
      const { rowId, columnId } = change;
      headers[columnId].label = change.newCell?.text;
    });

    //make header with column
    changes.forEach((change: any) => {
      const { rowId, columnId } = change;

      changeNewHeader.forEach((item: any) => {
        if (item.colIdx === columnId) {
          item.value = change.newCell?.text;
        }
      });
    });

    return Promise.resolve({
      rows: dataRows,
      rowsAll: newDatarowsAll,
      header: headers,
      newHeader: changeNewHeader,
    });
  }

  let rows = dataRows;
  let rowsAll = dataRowsAll;

  const fillHandler = typeof changes.find((change: any) => change.columnId == selectedCell?.columnId
    && change.rowId == selectedCell.rowId) == 'undefined';

  let start: any = selectedCell;
  let end: any = selectedCell;

  const direction = getDirection(selectedCell, changes);
  if (fillHandler && !!direction) {
    end = endSelectedCell(changes, direction);
  }

  const fillHandlerContainFormula = fillHandler && end && checkIfContainFormula(rows, start, end);

  if (fillHandlerContainFormula || isPasting) {
    const startingPaste = {
      columnId: parseInt(changes[0].columnId.toString()),
      rowId: parseInt(changes[0].rowId.toString())
    };

    if (isPasting) {
      start = startCopy;
    }

    const updated = relativeUpdate(sheetId, rows, rowsAll, changes, start, startingPaste);
    rows = updated.rows;
    rowsAll = updated.rowsAll;
  } else {
    changes.forEach((change: any) => {
      const { rowId, columnId } = change;
      rows[rowId][columnId] = {
        ...rows[rowId][columnId],
        value: change.newCell.text,
      };

      rowsAll[rowId][columnId] = {
        ...rowsAll[rowId][columnId],
        value: change.newCell.text,
      };
    });
  }

  return Promise.resolve({
    rows,
    rowsAll,
    header,
    newHeader: changeNewHeader,
  });
  // }
};

// if ((props.data.header.length = header.length)) {
//   setHeader(header);
//   renderRow(result, editedRows, header);
// } else {
//   let deletedHeader = header.slice(0, -1);
//   setHeader(deletedHeader);
//   renderRow(result, editedRows, deletedHeader);
//   columns.pop();
// }

const columnMapper = (payload: any) => {
  const column: number = payload[0][0].colIdx;
  const addColumn: any = addFakeValue(column, '');
  return addColumn;
};

function addFakeValue(num: number, string: string) {
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(string);
  }
  return arr;
}

const rowMapper = (payload: any) => {
  const row: number = payload[0][0].rowIdx;
  const column: number = payload[0][0].colIdx + payload[0].length;
  return createFakeRow(row, column);

};

function createFakeRow(a: number, b: number) {
  const arr: any = [];

  for (let i = 0; i < a; i++) {
    for (let j = 0; j < b; j++) {
      arr[i] = [];
    }
  }

  for (let i = 0; i < a; i++) {
    for (let j = 0; j < b; j++) {
      arr[i][j] = '';
    }
  }
  return arr;
}

const filterShowEachRow = (arr: any) => {
  const result = arr.map((el: any) => {
    const response = el.filter((a: any) => a.showToLogger);
    return response;
  });
  return result;
};

export const setTableName = (arr: any, columnId: string) => {
  const data: any = [];

  arr.forEach((el: any) => {
    el.rows.map((a: any, i: number) => {
      if (a.columns[0].type === 'TABLE') {
        data.push({
          ...a.columns[0]
        });
      }
    });
  });
  const mappingTableName: any = data.map((el: any, i: number) => {
    return {
      ...el, tableName: `Table_${i + 1}`
    };
  });
  return mappingTableName.find((item: any, i: number) => item.columnId === columnId)?.tableName;
};

export const includeString = (formula: string, arr: Array<string>) => {
  return arr.some((el: any) => formula.includes(el));
};

// convert table cell value to \"Value\"
export function formatTableData(tableData: any) {
  if (tableData === undefined) return;
  return tableData.map((row: any) => {
    return row.map((cell: any) => {
      return /=/.test(cell) || isNumeric(cell) ? cell : `\"${cell}\"`;
    });
  });
}

export function cleanupCurrencyFormat(data: any) {
  if (data === undefined) return;
  const currencyPattern = /(?:[$€£¥]|Rp|CN¥)/;
  return data.map((row: any) => {
    return row.map((cell: any) => {
      return cell.replace(currencyPattern, '');
    });
  });
}