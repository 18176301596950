import { UserDriveStatusI } from "../../pages/businessDrives/businessDrives.types";
import { UPDATE_USER_PHOTO } from "./loginTypes";

export const FETCH_ALL_USER = "FETCH_INPUT_FILE_BY_CALCULATION_ID";
export const LOADING_CALCULATIONS = "LOADING_CALCULATIONS";
export const IS_ACTIVE = "IS_ACTIVE";
export const FETCH_ALL_COMPANIES = "FETCH_ALL_COMPANIES";
export const IS_ACTIVE_ITEM = "IS_ACTIVE_ITEM";
export const FETCH_ALL_INDUSTRIES = "FETCH_ALL_INDUSTRIES";
export const FETCH_ALL_PROFESSION = "FETCH_ALL_PROFESSION";
export const FETCH_ALL_COUNTRIES = "FETCH_ALL_COUNTRIES";
export const FETCH_PROFILE_USER = "FETCH_PROFILE_USER";
export const FETCH_USER_CALCULATION_LIMIT = "FETCH_USER_CALCULATION_LIMIT";
export const FETCH_SOFTWARE_LICENSE = "FETCH_SOFTWARE_LICENSE";
export const GET_DRIVE_STATUS = 'GET_DRIVE_STATUS';
export const SET_DRIVE_ROLE = 'SET_DRIVE_ROLE';
export const SET_CHANGE_PLAN = 'SET_CHANGE_PLAN';
export const GET_BILLING_DETAILS = 'GET_BILLING_DETAILS';
export const SET_CHANGE_PAYMENT_STATUS = 'SET_CHANGE_PAYMENT_STATUS';
export const GET_BILLING_MEMBER_EMAIL = 'GET_BILLING_MEMBER_EMAIL';
export const ADD_BILLING_MEMBER = 'ADD_BILLING_MEMBER';

export const fetchAllUsers = (data: any) => {
  return {
    type: FETCH_ALL_USER,
    data,
  };
};

export function loadingAction(isLoading: boolean) {
  return { type: LOADING_CALCULATIONS, isLoading };
}


export function isActiveUser(data: string) {
  return { type: IS_ACTIVE, data };
}

export function isActiveItem(data: string) {
  return { type: IS_ACTIVE_ITEM, data };
}

export function fetchAllCompany(data: any) {
  return {
    type: FETCH_ALL_COMPANIES,
    data,
  };
}

export function fetchAllIndustries(data: any) {
  return {
    type: FETCH_ALL_INDUSTRIES,
    data,
  };
}

export function fetchAllProfession(data: any) {
  return {
    type: FETCH_ALL_PROFESSION,
    data,
  };
}


export function fetchAllCountries(data: any) {
  return {
    type: FETCH_ALL_COUNTRIES,
    data,
  };
}


export function fetchProfileUser(data: any) {
  return {
    type: FETCH_PROFILE_USER,
    data,
  };
}


export function fetchUserCalculationLimit(data: any) {
  return {
    type: FETCH_USER_CALCULATION_LIMIT,
    data,
  };
}


export function fetchSoftwareLicense(data: any) {
  return {
    type: FETCH_SOFTWARE_LICENSE,
    data,
  };
}

export const getDriveStatus = (data: UserDriveStatusI) => {
  return {
    type: GET_DRIVE_STATUS,
    data
  };
};

export const setDriveRole = (data: string) => {
  return {
    type: SET_DRIVE_ROLE,
    data
  };
};

export const setChangePlan = (data: boolean) => {
  return {
    type: SET_CHANGE_PLAN,
    data
  };
};
export const getBillingDetails = (data: string) => {
  return {
    type: GET_BILLING_DETAILS,
    data
  };
};

export const changeBillingPaymentStatus = (data: string) => {
  return {
    type: SET_CHANGE_PAYMENT_STATUS,
    data
  };
};

export const getBillingMemberEmail = (data: string) => {
  return {
    type: GET_BILLING_MEMBER_EMAIL,
    data
  };
};

export const addBillingMember = (data: string) => {
  return {
    type: ADD_BILLING_MEMBER,
    data
  };
};

export const updateUserPhoto = (url: string) => {
  return {
    type: UPDATE_USER_PHOTO,
    url,
  };
};

export const updateUserList = (data: any) => {
  return {
    type: FETCH_ALL_USER,
    data,
  };
};
