import {
  LOADING_CALCULATIONS,
  FAILED_CALCULATIONS,
  CREATE_NEW_CALC,
  TRIGGER_SAVE_CALCULATIONS,
  SET_CALCULATIONS,
  FailedActionI,
  LoadingActionI,
  CreateNewActionI,
  TriggerSaveActionI,
  SetActionI,
} from "../types/calculationsTypes";

interface initialStateI {
  id: string;
  isLoading: boolean;
  message: string;
  isSaveCalculation: boolean;
  dataCalculation: Record<string, unknown>;
}

const initialState: initialStateI = {
  id: "",
  isLoading: false,
  message: "",
  isSaveCalculation: false,
  dataCalculation: {},
};

type Action =
  | LoadingActionI
  | FailedActionI
  | CreateNewActionI
  | TriggerSaveActionI
  | SetActionI;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case CREATE_NEW_CALC:
      return {
        ...state,
        id: action.id,
        message: "",
      };
    case LOADING_CALCULATIONS:
      return { ...state, isLoading: action.isLoading };
    case FAILED_CALCULATIONS:
      return { ...state, message: action.message, isLoading: action.isLoading };
    case TRIGGER_SAVE_CALCULATIONS:
      return { ...state, isSaveCalculation: action.isSaveCalculation };
    case SET_CALCULATIONS:
      return { ...state, dataCalculation: action.dataCalculation };
    default:
      return state;
  }
}
