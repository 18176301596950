import { Menu } from '@mui/material';
import { MenuBox } from '../../../pageElements/composer';
import { contentField } from '../../../utils/constant';
import { IFieldMenu } from './field-menu.type';

export const FieldMenu = ({
  open,
  anchorPosition,
  handlerShowFieldComponent,
  handlerComponent,
  dataProperties
}: IFieldMenu) => {
  return (
    <Menu
      open={open}
      onClose={() => handlerShowFieldComponent('close')}
      anchorEl={anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: 0.5
        }
      }}
    >
      <MenuBox>
        {contentField?.map((value: any, index: number) => (
          <div
            key={`field_menu_box_${index}`}
            onClick={() => {
              handlerComponent(
                'SWITCH_FIELD',
                dataProperties.idComponent,
                dataProperties.indexComponent,
                value.type
              );
            }}
          >
            {value.label}
          </div>
        ))}
      </MenuBox>
    </Menu>
  );
};
