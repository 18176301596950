import { useRef, ChangeEvent } from 'react';
import images from '../../../assets';
import {
  CalculationTittleContainer,
  HeadAtBodyMainContent,
  HeadAtBodyMainContentCenter,
  HeadAtBodyMainContentLeft,
  HeadAtBodyMainContentRight,
  PrimaryLogo,
  UploadButtonContainer
} from './element';
import { Spinner } from '../../atoms/loading/elements';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { ImageComponent } from '../components';
import Text from '../../atoms/Text';
import { Grid } from '@mui/material';

type PageHeaderProps = {
  leftImg?: string;
  rightImg?: string;
  title: string;
  headerData: any;
  logo?: any;
  // KPD-2217 Logger View - Hide Feature Upload Logo
  // handleUploadLogo: (
  //   e: ChangeEvent<HTMLInputElement>,
  //   logoType: string,
  //   inputFileId: string
  // ) => void;
};
export default function PageHeader({
  title,
  headerData,
  leftImg,
  rightImg,
  logo,
  // handleUploadLogo,
  ...restProps
}: PageHeaderProps) {
  const logoPrimary = useRef<HTMLInputElement>(null);

  const { pathname } = useLocation();

  const onLogoClickPrimary = () => {
    if (logoPrimary.current) {
      logoPrimary.current.click();
    }
  };

  return (
    <HeadAtBodyMainContent>
      {/* KPD-2217 Logger View - Hide Feature Upload Logo 
        <HeadAtBodyMainContentLeft>
          <PrimaryLogo isUploading={logo?.logoType === "PRIMARY"}>
            <>
              {pathname.includes("/logger") && (
                <input
                  type="file"
                  hidden
                  ref={logoPrimary}
                  onChange={(e) =>
                    handleUploadLogo(e, "PRIMARY", headerData?.inputFileId)
                  }
                />
              )}
              {logo?.logoType === "PRIMARY" && (
                <div>
                  <Spinner />
                </div>
              )}
              <ImageComponent
                round={true}
                src={
                  !headerData?.primaryLogo || headerData?.primaryLogo === ""
                    ? images.iconUpload
                    : headerData?.primaryLogo +
                      "?random=" +
                      `${Math.random().toString(36).substring(7)}`
                }
                alt="profile-left"
                onClick={
                  pathname.includes("/logger") ? onLogoClickPrimary : undefined
                }
              data-testid="btnUploadProfileLeft"
              size={64}
              />
            </>
        </PrimaryLogo>
        <UploadButtonContainer>
          <Text variant="smallBold" color="primary">{
            !headerData?.primaryLogo || headerData?.primaryLogo === "" ? "Upload Logo" : "Edit Logo"
          }</Text>
        </UploadButtonContainer>
        </HeadAtBodyMainContentLeft> */}
      <HeadAtBodyMainContentCenter>
        <CalculationTittleContainer>
          <Text variant="h4" style={{ overflowWrap: 'anywhere' }}>
            {title}
          </Text>
        </CalculationTittleContainer>
        <Grid container>
          <Grid item xs={6}>
            <Grid container style={{ padding: '8px 0' }}>
              <Grid item xs={5}>
                <Text variant="body3" color="#A3B3C2">
                  Originator
                </Text>
              </Grid>
              <Grid item xs={1}>
                <Text variant="body3" color="#A3B3C2">
                  :
                </Text>
              </Grid>
              <Grid item xs={6}>
                <Text variant="body3">{headerData.originator}</Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid container style={{ padding: '8px 0px 0px 0px' }}>
              <Grid item xs={5}>
                <Text variant="body3" color="#A3B3C2">
                  Date Created
                </Text>
              </Grid>
              <Grid item xs={1}>
                <Text variant="body3" color="#A3B3C2">
                  :
                </Text>
              </Grid>
              <Grid item xs={6}>
                <Text variant="body3">
                  {dayjs(headerData?.dateCreated).format('DD/MM/YYYY')}
                </Text>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '2px 0' }}>
              <Grid item xs={5}>
                <Text variant="body3" color="#A3B3C2">
                  Date Printed
                </Text>
              </Grid>
              <Grid item xs={1}>
                <Text variant="body3" color="#A3B3C2">
                  :
                </Text>
              </Grid>
              <Grid item xs={6}>
                <Text variant="body3">
                  {headerData.datePrint
                    ? dayjs(headerData?.datePrint).format('DD/MM/YYYY')
                    : ''}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HeadAtBodyMainContentCenter>
      <HeadAtBodyMainContentRight>
        <img src={images.textLogoGrey} alt="rekava" />
      </HeadAtBodyMainContentRight>
    </HeadAtBodyMainContent>
  );
}
