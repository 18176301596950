import Icon from '../../../components/atoms/Icon'
import Text from '../../../components/atoms/Text';
import { TEXT_DEMO, TEXT_HELP } from '../../../utils/constant';
import { BottomSidebarWrapper, SidebarItem } from '../styles'
import { useTheme } from '@mui/material'

interface Props {
  onClick: (value: string) => void;
  selectedItem: string;
}

export const AceBottomSidebar = ({ onClick, selectedItem }: Props) => {
  const theme = useTheme();
  return (
    <BottomSidebarWrapper>
      <SidebarItem id="acePublic" onClick={() => onClick('public')} selected={selectedItem === 'acePublic'}>
        <Icon icon="playOutlined" color={theme.customText.icon_high} />
        <Text variant='body1' color={theme.customText.high}>{TEXT_DEMO}</Text>
      </SidebarItem>
      <SidebarItem id="helpPage" onClick={() => onClick('help')} selected={selectedItem === 'helpPage'}>
        <Icon icon="helpOutlined" color={theme.customText.icon_high} />
        <Text variant='body1' color={theme.customText.high}>{TEXT_HELP}</Text>
      </SidebarItem>
    </BottomSidebarWrapper>
  )
}
