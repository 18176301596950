import { Dispatch } from "redux";
import * as actions from "../types/userTypes";
import Service from "../../service/generic/generic";
import { setHelperPanel } from "../appState/global/globalActions";
import { APIResponse, DriveI, UserDriveStatusI } from "../../pages/businessDrives/businessDrives.types";
import GenericService from "../../service/generic/generic";
import { useApi } from "../../utils/useAPI";
import { getUserDetails, setUserDetails } from "../../utils/storage";

const service = new Service("users");
const userService = new Service("user");
const companiesService = new Service("companies");
const companyService = new Service("company");
const industriesService = new Service("industries");
const professionsService = new Service("professions");
const invoiceService = new Service("invoice");

export const fetchAllUsers = (
  page: number,
  size: number,
  sort?: string,
  type?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      let newPage;
      if (type === "DEFAULT" || type === "SORT") {
        page === 0 ? (newPage = 1) : (newPage = page - 1);
      } else if (type === "EDIT" || type === "ADD") {
        newPage = page;
      }
      const response = await service.getDataWithQuary(
        newPage,
        size,
        "COMPOSER,LOGGER",
        sort
      );

      dispatch(actions.fetchAllUsers(response));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const deactivateUser = (id: string) => {
  return async (dispatch: Dispatch) => {
    const Userservice = new Service("user");
    try {
      dispatch(actions.loadingAction(true));
      const response = await Userservice.put(id, { isActive: false });
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const activateUser = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const Userservice = new Service("user");
      dispatch(actions.loadingAction(true));
      const response = await Userservice.put(id, { isActive: true });
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const fetchAllCompany = (query?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      query = query ? query : "?size=1000000";
      const response = await companiesService.getQuery(query);
      dispatch(actions.fetchAllCompany(response.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const fetchAllIndustries = (query?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await industriesService.get();
      dispatch(actions.fetchAllIndustries(response.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const fetchAllProfession = (query?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await professionsService.get();
      dispatch(actions.fetchAllProfession(response.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};


export const fetchAllCountries = (query?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await userService.get('countries');
      dispatch(actions.fetchAllCountries(response.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};


export const postCompany = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await companyService.post(data);
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const postAddUser = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.post(payload);
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const editUser = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await userService.put(payload.id, payload);
      return Promise.resolve(res);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const isActiveUser = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.isActiveUser(payload));
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const isActiveItem = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.isActiveItem(payload));
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const searchUser = (
  payload: any,
  page: number,
  size: number,
  sort?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      let newPage;
      page === 0 ? (newPage = 1) : (newPage = page - 1);
      const response = await service.getSearchDataWithQuery(
        payload,
        newPage,
        size,
        "COMPOSER,LOGGER",
        sort
      );
      dispatch(actions.fetchAllUsers(response));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};


export const resetPassword = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await userService.putResetPassword(payload, "password");
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
};

const getUserDetail = async (force = false) => {
  const userDetails = getUserDetails();
  const response = await useApi(
    `user_profile`,
    () => userService.get("profile"),
    force
  );

  setUserDetails({
    ...userDetails,
    ...response?.data
  });
  return response?.data;
};

export const getUserProfileAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await getUserDetail(true);
      dispatch(actions.updateUserPhoto((response as any)?.profilePictureUrl ? (response as any)?.profilePictureUrl + "?timestamp=" + new Date().getTime() : ''));
      dispatch(actions.fetchProfileUser(response));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const getUserCalculationLimit = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await userService.get('calculation-limit');
      dispatch(actions.fetchUserCalculationLimit(response?.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};


export const editUserProfileAction = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await service.put('',
        {
          name: data?.name,
          countryCode: data.country?.code,
          personalUserCompany: data?.company,
          professionId: data?.profession?.[0]?.id,
          industryId: data?.industry?.[0]?.id
        });
      getUserDetail(true);
      dispatch(actions.fetchProfileUser(response.data));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      console.error(error?.response);
      return Promise.reject(error);
    }
  };
};

export const getLicenseAgreement = () => {
  return async (dispatch: Dispatch) => {
    try {
      const service = new Service(`policy/latest`);
      const response: any = await service.get();
      const payload: any = response.data;
      const data = {
        isOpen: true,
        type: "SLARegister",
        payload: {
          ...payload,
          policyId: response.data.id
        }
      };
      return dispatch(setHelperPanel(data));
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const getLicenseAgreementId = () => {
  return async (dispatch: Dispatch) => {
    try {
      const service = new Service(`policy/latest`);
      const response: any = await service.get();
      dispatch(actions.fetchSoftwareLicense(response.data));
      return response;
    } catch (error: any) {
      return Promise.resolve(error);
    }
  };
};

export const handleGetDriveStatus = (data: UserDriveStatusI) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.getDriveStatus(data));
  };
};

export const handleChangeUserPlan = (payload: any) => {
  return async (dispatch: Dispatch) => {
    await invoiceService.post({ subscriptionId: payload.subscriptionUser.subscriptionId, plan: payload.payload.plan.toUpperCase().replace(/ /g, "_") });
  };
};

export const checkIsHaveDrive = () => {
  return new Promise(async (resolved, rejected) => {
    try {
      const drivesService = new GenericService(`drives?isActive=true`);
      drivesService.get()
        .then((response: APIResponse<Array<DriveI>>) => {
          resolved({
            isHaveDrive: Boolean(response.data.length),
            isHaveManageDrive: response.data.length ? Boolean(response.data?.filter((item: DriveI) => !/VIEWER/.test(item.memberRole.type)).length) : false,
          });
        });
    } catch (error: any) {
      rejected(error);
    };
  });
};

export const handleSetDriveRole = (data: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.setDriveRole(data));
  };
};

export const handleGetBillingDetails = (id: string) => {
  const billingDetails = new Service(``);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingAction(true));
      const response = await billingDetails.getBillingDetails(id);
      dispatch(actions.getBillingDetails(response));
      dispatch(actions.loadingAction(false));
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
};

export const changeBillingPaymentStatus = (payload: any) => {
  const paymentStatus = new Service(``);
  return async (dispatch: Dispatch) => {
    try {
      const response = await paymentStatus.changeBillingPaymentStatus(payload);
      dispatch(actions.changeBillingPaymentStatus(response));
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
};

export const addBillingMember = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await invoiceService.addBillingMember(
        payload.email,
        payload.parentId,
        payload?.plan,
        payload.amount
      );
      dispatch(actions.addBillingMember(response));
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
};