import { Editor as DefaultEditor, EditorState } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import Editor from '@draft-js-plugins/editor';
import editorStyles from './editorStyles.module.css';
import {
  draftBlockToHtml,
  getContentLength,
  getLengthOfSelectedText,
  htmlToDraftBlocks
} from '../../../utils/rich-text-editor.utils';

interface RichTextPropsI extends React.HTMLAttributes<HTMLDivElement> {
  source: any;
  onChange?: (source: any) => void;
  component?: string;
  error?: boolean;
  handleSaveChange?: (e: React.SyntheticEvent, data: string) => void;
  headingLevel?: string;
  maxLength?: number;
  readonly?: boolean;
}

const RichText = (props: RichTextPropsI) => {
  const {
    source,
    onChange,
    error,
    handleSaveChange,
    headingLevel,
    maxLength,
    readonly,
    ...rest
  } = props;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let editorState;
    if (!source) {
      editorState = EditorState.createEmpty();
    } else {
      editorState = htmlToDraftBlocks(source);
    }
    setEditorState(editorState);
  }, [source]);

  const editor = useRef<DefaultEditor>(null);

  const onHandleChange = (editorState: EditorState): void => {
    setEditorState(editorState);
    if (onChange) {
      const html = draftBlockToHtml(editorState);
      onChange(draftToHtml(html as any));
    }
  };

  const onBlur = (e: React.SyntheticEvent) => {
    if (handleSaveChange) {
      const html = draftBlockToHtml(editorState);
      handleSaveChange(e, html);
    }
  };

  const handleBeforeInput = (_: string, editorState: EditorState) => {
    if (maxLength) {
      const currentContentLength = getContentLength(editorState);
      const selectedTextLength = getLengthOfSelectedText(editorState);

      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return 'handled';
      }
    }
  };

  const handlePastedText = (
    pastedText: string,
    html: unknown,
    editorState: EditorState
  ) => {
    if (maxLength) {
      const currentContentLength = getContentLength(editorState);
      const selectedTextLength = getLengthOfSelectedText(editorState);

      if (
        currentContentLength + pastedText.length - selectedTextLength >
        maxLength
      ) {
        return 'handled';
      }
    }
  };

  return (
    <div
      id="lala"
      className={editorStyles.editor}
      data-error={error}
      data-text-heading={headingLevel}
      onBlur={onBlur}
      onClick={() => editor.current?.focus()}
      {...rest}
    >
      <Editor
        editorState={editorState}
        onChange={onHandleChange}
        ref={editor}
        // superscript and subscript config
        customStyleMap={{
          SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
          SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
        }}
        placeholder={rest?.placeholder ?? ''}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        readOnly={readonly}
      />
    </div>
  );
};

export default RichText;
