import styled from "styled-components";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
interface Props {
    type?: string;
    headerWidth?: number;
    bodyWidth?: number;
}

export const HeaderTableRow = {
    display: "flex",
    backgroundColor: "#F5F7FA",
};

export const BodyTableRow = {
    display: 'flex',
    flexDirection: 'row'
};

export const headerIconStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
} as const;

export const moreVertStyle = {
    backgroundColor: "#F0F2F5",
    width: 32,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 5,
    alignSelf: "center",
};

export const triangleShapeUp = {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid #686D8E",
    marginBottom: 2,
};

export const triangleShapeDown = {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: "5px solid #686D8E",
};

export const TextRole = styled.h1({
    fontWeight: 700,
    fontSize: 14,
});

export const WrapperCenterCell = styled.div<Props>((props) => ({
    margin: "auto",
    width: props.type === "ROLE" ? "75%" : props.type === "ICON" ? "40%" : props.type === "NO_RESULT" ? "10%" : "0%"
}));

export const TableWrapper = styled.div({
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
});

export const HeaderTableCell = styled(TableCell)<Props>((props) => ({
    width: props.headerWidth + '%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto'
}));

export const BodyTableCell = styled(TableCell)<Props>((props) => ({
    width: props.bodyWidth + '%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    overflowWrap: 'anywhere'
}));

export const ErrorTableCell = styled(TableCell)({
    border: '1px solid #E0E6EB',
    display: 'flex'
});

export const BillingWrapper = styled.div({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
});

export const BillingFooterWrapper = styled.div({
    position: 'sticky',
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
});

export const BillingFooterTextWrapper = styled.div({
    paddingLeft: 35,
    display: 'flex',
    flexDirection: 'row',
    gap: 5
});