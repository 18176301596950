export const GET_BREADCRUMB = "GET_BREADCRUMB";
export const SET_BREADCRUMB = "SET_BREADCRUMB";

export const GetBreadcrumb = () => {
  return {
    type: GET_BREADCRUMB,
  };
};

export const SetBreadcrumb = (data: any) => {
  return {
    type: SET_BREADCRUMB,
    data,
  };
};
