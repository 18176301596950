import TableLegend from "./TableLegend";
import Symbol from "./Symbol";
import VariableManager from "./VariableManager";

const ComposerToolkit = {
  TableLegend,
  Symbol,
  VariableManager,
};

export default ComposerToolkit;