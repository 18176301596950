import dayjs from "./dayjs";

import isBetween from 'dayjs/plugin/isBetween';


const DATE_FORMAT = "DD/MM/YYYY hh:mm A";
const STRIP_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const composerDataHeadOfCalculationTime = (dateTime: any) => {
  const localDate = new Date(dayjs(dateTime).utc(true).format());
  const newLocalDate = dayjs(localDate).format("DD/MM/YYYY");
  return newLocalDate;
};

export const localDatetime = (dateTime?: any) => {
  const localDate = new Date(dayjs(dateTime).utc(true).format());
  const getNow = new Date(dayjs().utc(true).format());
  const newLocalDate = dayjs(dateTime ? localDate : getNow).format("YYYY-MM-DD hh:mm:ss A");
  return newLocalDate;
};

export const localDatetimeTable = (dateTime: any) => {
  const localDate = new Date(dayjs(dateTime).utc(true).format());
  const newLocalDate = dayjs(localDate).format("YYYY-MM-DD hh:mm A");
  return newLocalDate;
};

export const isoToLocalDateTime = (dateTime: any) => {
  return dayjs(dateTime).utc(true).local().format(DATE_FORMAT);
};

export const isoToLocalDateTimeStrip = (dateTime: any) => {
  return dayjs(dateTime).utc(true).format(STRIP_DATE_FORMAT);
};

export const isNowAddDay = (nowDate: any, dateTime: any) => {
  return dayjs(nowDate).add(dateTime, 'day').format(STRIP_DATE_FORMAT);
};

export const isNowSubstractDay = (nowDate: any, dateTime: any) => {
  return dayjs(nowDate).subtract(dateTime, 'day').format(STRIP_DATE_FORMAT);
};

export const isBeforeDate = (nowDate: any, dateTime: any) => {
  return dayjs(nowDate).isBefore(dateTime);
};

export const isAfterDate = (nowDate: any, dateTime: any) => {
  return dayjs(nowDate).isAfter(dateTime);
};

export const isBetweenDate = (nowDate: any, startTime: any, endTime: any) => {
  dayjs.extend(isBetween);
  return dayjs(nowDate).isBetween(startTime, dayjs(endTime));
};

export const calLastChanged = (dateTime: any) => {
  const currentDateTime: any = new Date(dayjs().utc(false).format());
  const lastChanged: any = new Date(dayjs(dateTime).utc(true).format());
  const diffMs: any = currentDateTime - lastChanged; // milliseconds
  const diffDays: any = Math.floor(diffMs / 86400000); // days
  const diffHrs: any = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins: any = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  let showChanged: string;

  if (diffDays >= 1) {
    showChanged = "Last changed " + diffDays + " days ago";
  } else if (diffDays === 0 && diffHrs > 0) {
    showChanged = "Last changed " + diffHrs + " hours ago";
  } else if (diffHrs === 0) {
    showChanged = "Last changed " + diffMins + " minuted ago";
  } else {
    showChanged =
      diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes ";
  }

  return showChanged;
};

export const toLocalDateTime = (dateTime: string) => {
  return dayjs.utc(dateTime).local().format(DATE_FORMAT);
};
