import * as React from "react";
import { SVGProps } from "react";

const TypeBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="type=book">
      <path id="Vector" d="M4.008 12H14V2.66666C14 2.31304 13.8595 1.9739 13.6095 1.72385C13.3594 1.4738 13.0203 1.33333 12.6667 1.33333H4C3.196 1.33333 2 1.86599 2 3.33333V12.6667C2 14.134 3.196 14.6667 4 14.6667H14V13.3333H4.008C3.7 13.3253 3.33333 13.2033 3.33333 12.6667C3.33333 12.13 3.7 12.008 4.008 12ZM5.33333 3.99999H11.3333V5.33333H5.33333V3.99999Z" fill="#189EFF" />
    </g>
  </svg>
);

export default TypeBook;
