import styled from "styled-components";

export const OptionBox = styled.div({
    width: "280px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
});

export const Item = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: "6px 16px",
    right: 0,
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: "#E3F3FF",
      },
});

export const Description = styled.div({
    flexDirection: 'column',
});
