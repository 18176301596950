import { Autocomplete, AutocompleteProps } from "@mui/material";

interface ComboBoxPropsI<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  myProp?: string;
}

export default function ComboBox<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> (props: ComboBoxPropsI<T, Multiple, DisableClearable, FreeSolo>) {
    return (
        <Autocomplete {...props}/>
    );
};