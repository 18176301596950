import { Number } from './Number';
import { Formula } from './FormulaInput';
import { Percentage } from './Percentage';
import { NumberField } from './NumberField';

const FormattedInput = {
  Number,
  Formula,
  Percentage,
  NumberField
};

export default FormattedInput;
