import { Typography } from "@mui/material";
import images from "../../../../assets";
import { CommonProps } from "../../logger.types";
import parser from "html-react-parser";
import { ImageBox, ImageDesc } from "./styles";

export default function ImageComponent({properties}: CommonProps) {
    return (
        <ImageBox
            alignment={properties.alignment}
            >
            <div>
                <img
                src={properties.imageUrl ? properties.imageUrl : images.noImage}
                alt="image"
                style={{
                    width: properties?.width ? properties?.width + 20 + "px" : "400px",
                    height: properties?.height ? properties?.height + "px" : "264px",
                }}
                />
                <ImageDesc alignment={properties.alignment}>
                    <Typography variant="body3" component={'span'}>{properties.description ? parser(properties.description) : properties.description}</Typography>
                </ImageDesc>
            </div>
        </ImageBox>
    );
}