import formula from "@jspreadsheet/formula-pro";

function calculate(expr: string, sources?: Record<string, any>) {
  return sources ? formula(expr, sources) : formula(expr);
}

function setVar(name: string, value: number) {
  formula.setConst({ [name]: value });
}

function resetVarList() {
  formula.resetConst();
}

const FormulaHandler = {
  calculate,
  resetVarList,
  setVar,
};

export default FormulaHandler;
