import {
  getToken,
  getPersonalWorkspaceId,
  getEnterpriseWorkspaceId,
  getUserDetails,
  getCommunityWorkspaceId,
} from '../../utils/storage';
import {
  SIGN_IN,
  SIGN_OUT,
  LoginAction,
  LogOutAction,
  ERROR_EMAIL,
  ErrorEmail,
  ERROR_PASS,
  ErrorPassword,
  FLUSH_EMAIL,
  FlushEmail,
  FlushPassword,
  FLUSH_PASSWORD,
  UpdateUserName,
  UPDATE_USER_NAME,
  UPDATE_USER_PHOTO,
  UpdateUserPhoto,
  LoadingValidateEmail,
  SuccessValidateEmail,
  ErrorValidateEmail,
  IS_LOADING_VALIDATE_EMAIL,
  SUCCESS_VALIDATE_EMAIL,
  ERROR_VALIDATE_EMAIL,
  FLUSH_MESSAGE_VALIDATE_EMAIL,
  FlushMessageValidateEmail,
  LoadingRecoverPassword,
  SuccessRecoverPassword,
  ErrorRecoverPassword,
  IS_LOADING_RECOVER_PASSWORD,
  SUCCESS_RECOVER_PASSWORD,
  ERROR_RECOVER_PASSWORD,
  FLUSH_MESSAGE_RECOVER_PASSWORD,
  FlushMessageRecoverPassword,
  IS_LOADING_UPDATE_USER_PHOTO,
  LoadingUpdateUserPhoto,
  UPDATE_SUBSCRIPTION,
  UpdateSubscription
} from '../types/loginTypes';

export interface SubscriptionI {
  expiredTime: string;
  id: string;
  plan: string;
  status: string;
}

interface initialStateI {
  token: any;
  personalWorkspaceId: any;
  enterpriseWorkspaceId: any;
  communityWorkspaceId: any;
  failedEmailMessage: string;
  isErrorEmail: boolean;
  failedPasswordMessage: string;
  isErrorPass: boolean;
  userDetails: string;
  userPhoto: string;
  isLoadingValidateEmail: boolean;
  messageSuccessValidateEmail: string;
  messageErrorValidateEmail: string;
  isLoadingRecoverPassword: boolean;
  messageSuccessRecoverPassword: string;
  messageErrorRecoverPassword: string;
  isLoadingRegister: boolean;
  messageSuccessRegister: string;
  messageErrorRegister: string;
  email: string,
  loading: boolean,
  subscription: Array<any>,
  userType: string;
}

const initialState: initialStateI = {
  token: getToken(),
  personalWorkspaceId: getPersonalWorkspaceId(),
  enterpriseWorkspaceId: getEnterpriseWorkspaceId(),
  communityWorkspaceId: getCommunityWorkspaceId(),
  failedEmailMessage: '',
  isErrorEmail: false,
  failedPasswordMessage: '',
  isErrorPass: false,
  userDetails: getUserDetails()?.name,
  userPhoto: getUserDetails()?.profilePictureUrl,
  email: getUserDetails()?.email,
  isLoadingValidateEmail: false,
  messageSuccessValidateEmail: '',
  messageErrorValidateEmail: '',
  isLoadingRecoverPassword: false,
  messageSuccessRecoverPassword: '',
  messageErrorRecoverPassword: '',
  isLoadingRegister: false,
  messageSuccessRegister: '',
  messageErrorRegister: '',
  loading: false,
  subscription: getUserDetails()?.subscription,
  userType: ''
};

type Action =
  | LoginAction
  | LogOutAction
  | ErrorEmail
  | ErrorPassword
  | FlushEmail
  | FlushPassword
  | UpdateUserName
  | UpdateUserPhoto
  | LoadingValidateEmail
  | SuccessValidateEmail
  | ErrorValidateEmail
  | FlushMessageValidateEmail
  | LoadingRecoverPassword
  | SuccessRecoverPassword
  | ErrorRecoverPassword
  | FlushMessageRecoverPassword
  | LoadingUpdateUserPhoto
  | UpdateSubscription
  ;

export default function reducer(
  state: initialStateI = initialState,
  action: Action
) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload
      };
    case SIGN_OUT:
      return { ...state, token: null };
    case ERROR_EMAIL:
      return {
        ...state,
        failedEmailMessage: action.message,
        isErrorEmail: action.isErrorEmail,
      };
    case FLUSH_EMAIL:
      return {
        ...state,
        failedEmailMessage: action.message,
        isErrorEmail: action.isErrorEmail,
      };
    case ERROR_PASS:
      return {
        ...state,
        failedPasswordMessage: action.message,
        isErrorPass: action.isErrorPass,
      };
    case FLUSH_PASSWORD:
      return {
        ...state,
        failedPasswordMessage: action.message,
        isErrorPass: action.isErrorPass,
      };
    case UPDATE_USER_NAME:
      return {
        ...state,
        userDetails: action.name,
      };
    case UPDATE_USER_PHOTO:
      return {
        ...state,
        userPhoto: action.url,
      };
    case IS_LOADING_UPDATE_USER_PHOTO:
      return {
        ...state,
        loading: action.loading
      };
    case IS_LOADING_VALIDATE_EMAIL:
      return {
        ...state,
        isLoadingValidateEmail: action.isLoading,
      };
    case SUCCESS_VALIDATE_EMAIL:
      return {
        ...state,
        messageSuccessValidateEmail: action.messageSuccess,
        isLoadingValidateEmail: false,
      };
    case ERROR_VALIDATE_EMAIL:
      return {
        ...state,
        messageErrorValidateEmail: action.messageError,
        isLoadingValidateEmail: false,
      };
    case FLUSH_MESSAGE_VALIDATE_EMAIL:
      return {
        ...state,
        messageErrorValidateEmail: '',
        messageSuccessValidateEmail: '',
      };
    case IS_LOADING_RECOVER_PASSWORD:
      return {
        ...state,
        isLoadingRecoverPassword: action.isLoading,
      };
    case SUCCESS_RECOVER_PASSWORD:
      return {
        ...state,
        messageSuccessRecoverPassword: action.messageSuccess,
        isLoadingRecoverPassword: false,
      };
    case ERROR_RECOVER_PASSWORD:
      return {
        ...state,
        messageErrorRecoverPassword: action.messageError,
        isLoadingRecoverPassword: false,
      };
    case FLUSH_MESSAGE_RECOVER_PASSWORD:
      return {
        ...state,
        messageErrorRecoverPassword: '',
        messageSuccessRecoverPassword: '',
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription
      };
    default:
      return state;
  }
}
