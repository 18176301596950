import styled from 'styled-components';

export const TableContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  marginTop: '1.5rem',
});

export const styledTable = {
  minWidth: 650,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
};

export const styledHeaderTable = {
  height: 48,
  '& .MuiTableCell-root:nth-child(1)': {
  },
  '& .MuiTableCell-root:nth-child(2)': {
    padding: '3px 0',
    paddingLeft: '16px'
  },
  '& .MuiTableCell-root': {
    padding: '8px 0 8px 16px',
    borderBottom: '1px solid #E0E6EB',
  },
};

export const styledCellTable = {
  '& .MuiTableCell-root:nth-child(1)': {
    padding: '3px 0',
    paddingRight: '0px',
    width: "68px"
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  backgroundColor: '#E3F3FF',
};

export const cellStyle = {
  '& .MuiTableCell-root:nth-child(1)': {
    padding: '3px 0',
    paddingRight: '0px',
    width: "68px"
  },
  '& .MuiTableCell-root:nth-child(2)': {
    paddingLeft: '0px',
  },
  borderBottom: '1px solid #E0E6EB',
};


