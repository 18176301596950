import React from 'react';

import { VanillaInput } from './styles';

import { TextPropsI } from './type';

const Text = ({
  register,
  id,
  typeInput,
  defaultValue,
  placeholder,
  error,
  onFocus,
  onBlur
}: TextPropsI) => {
  return (
    <VanillaInput
      {...(register && id ? register(id) : undefined)}
      id={id}
      typeInput={typeInput}
      defaultValue={defaultValue}
      placeholder={placeholder}
      errorState={error}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default Text;
