import { useState } from 'react';
import Text from '../../atoms/Text';
import MenuList from '../MenuList';
import { DefaultMenu, StyledMenu } from './styles';

interface MenuPropsI {
    menu: any
    onClickMenuItem?: (e: React.MouseEvent<HTMLElement>) => void
}

export default function BasicMenu({menu, onClickMenuItem}: MenuPropsI) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div key={menu.name}>
        <DefaultMenu
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <Text variant='h6'>{menu.label}</Text>
        </DefaultMenu>
        <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
                style: {
                    padding: 4,
                },
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
                    mt: 0.725,
                },
            }}
        >
            <MenuList key={menu.name} content={menu.content} open={open} handleClose={handleClose} handleClick={onClickMenuItem}/>
        </StyledMenu>
    </div>
  );
}