import * as React from "react";
import { SVGProps } from "react";

const DrawingSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16" height="16" viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.00001 1.33333C3.26667 1.33333 2.66667 1.93333 2.66667 2.66666V13.3333C2.66667 14.0667 3.26667 14.6667 4.00001 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3333V5.33333L9.33334 1.33333M8.66667 2.33333L12.3333 6H8.66667M6.58667 6.16666H7.41334V6.79333C7.87334 6.78666 8.25334 7.16666 8.25334 7.62666V9L8.17334 9.08666L8.76667 10.1133C8.98 9.77999 9.08667 9.39333 9.08001 9H9.92001C9.92001 9.69333 9.66667 10.3667 9.22001 10.9L10.3333 12.8333V13.6667L9.61334 13.2533L8.58001 11.46C8.11334 11.7667 7.56001 11.9267 7.00001 11.9267C6.44001 11.9267 5.89334 11.7667 5.42001 11.46L4.38667 13.2533L3.66667 13.6667V12.8333L5.82667 9.08666L5.74667 9V7.62666C5.74667 7.16666 6.12667 6.78666 6.58667 6.79333M7.00001 7.62666C6.54001 7.62666 6.30667 8.18666 6.63334 8.51333C6.96001 8.84 7.52001 8.61333 7.52001 8.14666C7.52001 7.86 7.28667 7.62666 7.00001 7.62666ZM6.44001 9.69333L5.84001 10.74C6.54001 11.2133 7.46001 11.2133 8.16001 10.74L7.56001 9.69333C7.24667 10 6.75334 10 6.44001 9.69333Z" fill="#DE294C" />
  </svg>
);

export default DrawingSvg;
