import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  DateInput,
  DividerComponent,
  DropdownInput,
  ImageComponent,
  LinkComponent,
  RadioButtonInput,
  TextInput,
  TextLabel
} from '../Fields';
import {
  DescriptionBox,
  DescriptionBoxField,
  FieldWrapper,
  InputBox,
  InputBoxField,
  InputWrapper,
  NotationBox,
  UnitBox
} from '../Fields/styles';
import { FormWrapper, ReferenceWrapper } from './styles';
import { HookFormProps, LoggerFormProps } from '../../logger.types';
import { Tooltip, Typography, useTheme } from '@mui/material';
import {
  checkFormulaValueNew,
  checkMultiLine,
  isNumeric,
  handleChangePercentage
} from '../../../../utils/helpersFunction';
import {
  clearInputsByPageIndex,
  toPlainNumeric,
  isNegative,
  calculateFormula,
  findLastRow,
  resetColumnSetting,
  generateTableHeader
} from '../../utils';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import FormattedInput from '../Fields/FormattedInput';
import { LatexFormula } from '../../../../components/molecules/LatexFormula';
import { Table } from '../Fields/Table';
import Text from '../../../../components/atoms/Text';
import { debounce } from 'lodash';
import parser from 'html-react-parser';
import { resetAction } from '../../../../store/actions/logger.actions';
import TextField from '../Fields/TextField';
import DropdownField from '../Fields/DropdownField';
import RadioField from '../Fields/RadioButtonField';
import DateField from '../Fields/DateField';
import VariableSheet from '../../../../components/molecules/VariableSheet';
import { FormulaTextArea } from '../Fields/FormulaTextArea';
import { TableExpandModal } from '../Fields/TableExpandModal';
import { getCellName } from '../../../../components/molecules/FormulaTable/utils';
import HiddenTable from '../hidden-table';

export const LoggerForm = ({
  data,
  selectedPage,
  onChangeHandler,
  onChangeDecimal,
  handlePrint,
  tableRef,
  expandTableRef,
  expandTableContainerRef,
  vlcRef
}: LoggerFormProps) => {
  const theme = useTheme();
  const latexRefs = useRef<Array<any>>([]);
  latexRefs.current = [];
  const [isResetted, setReset] = useState<boolean>(false);
  const [latexWrapper, setLatexWrapper] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  const { loggerAction, inputFile } = useAppSelector(
    state => state.loggerReducer
  );
  const tableComponentRef = useRef<any>({});

  const { control, watch, reset, trigger, register } = useForm<HookFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      formElements: data || []
    }
  });

  const { fields: formElements } = useFieldArray({
    control,
    name: 'formElements'
  });

  useEffect(() => {
    if (!data) return;
    reset({
      formElements:
        data /** reset and push data to form from api call response */
    });
    setReset(true);
  }, [data]);

  const formulaStatus: any = checkFormulaValueNew(formElements);

  useEffect(() => {
    if (isResetted) {
      trigger('formElements'); /** trigger form validation on load */
      handleChange({ formElements }, 'load');
    }
  }, [trigger, isResetted]);

  useEffect(() => {
    const subscriptionEvent = watch((value, { type }) =>
      handleChange(value, type)
    );
    return () => subscriptionEvent.unsubscribe();
  }, [watch]);

  function handleChange(fields: any, type: any) {
    if (type) debouncedFormEl(fields);
  }

  const handleFormChange = async (fields: any) => {
    const tempData = await calculateFormula(
      fields.formElements,
      tableRef.current,
      vlcRef.current
    );
    register('formElements', tempData);
    onChangeHandler(tempData);
    setLatexElement();
  };

  //LATEX SECTION

  const formulaState = async () => {
    try {
      const result = formElements[selectedPage]?.components.map(
        (item: any, i: number) => {
          return {
            index: i,
            expand: true
          };
        }
      );
      return Promise.resolve(result);
    } catch (error: any) {}
  };

  const [expandLatex, setExpandLatex] = useState<any>([]);
  const handleExpandLatex = (e: any, index: number) => {
    setExpandLatex(
      expandLatex.map((el: any, i: number) => {
        if (i === index) {
          el.expand = !el.expand;
        }
        return el;
      })
    );
  };

  useEffect(() => {
    async function setExpandState() {
      const data = await formulaState();
      handleFormChange({ formElements });
      setExpandLatex(data);
    }

    formElements.length > 0 && setExpandState();
  }, [selectedPage]);

  const addLatexRef = (el: any) => {
    if (el.element && !latexRefs.current.includes(el)) {
      latexRefs.current.push({
        ...el,
        multiLine: el.element.offsetWidth >= 520 ? true : false
      });
    }
  };

  const debouncedFormEl = useRef(
    debounce(async fields => {
      await handleFormChange(fields);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedFormEl.cancel();
    };
  }, [debouncedFormEl]);

  useEffect(() => {
    loggerAction && handleAction();
  }, [loggerAction]);

  const handleAction = async () => {
    let clearedInputFile = formElements;
    switch (loggerAction) {
      case 'CLEAR_ALL':
        for (let i = 0; i < clearedInputFile.length; i++) {
          clearedInputFile = await clearInputsByPageIndex(clearedInputFile, i);
        }
        onChangeHandler(clearedInputFile);
        break;
      case 'CLEAR_AT_PAGE':
        clearedInputFile = await clearInputsByPageIndex(
          clearedInputFile,
          selectedPage
        );
        break;
      case 'PRINT':
        handlePrint();
        break;
      default:
        return;
    }
    reset({
      formElements: clearedInputFile /** reset data (clear inputs) */
    });
    dispatch(resetAction());
    setTimeout(() => {
      trigger('formElements');
    }, 1000);
  };

  const handleTableChange = (
    componentIdx: number,
    worksheet: any,
    cell: any,
    col: any,
    row: any,
    newValue: any
  ) => {
    const spredsheet = formElements[selectedPage].components[componentIdx];
    const data = spredsheet.properties.cells;

    const cellIdx: number = data.findIndex(
      (el: any) => el.colIdx === parseInt(col) && el.rowIdx === parseInt(row)
    );

    if (cellIdx > 0) data[cellIdx].value = newValue;

    spredsheet.properties.cells = data;
    formElements[selectedPage].components[componentIdx] = spredsheet;
    /**
     * called OnChange Handler from parent component
     * why? to optimize cpu & memory usage
     *
     * this direct call will prevent the page to:
     * 1. calculated HF formula
     * 2. register data to react useHook (since the table render not using hook form, I think it will be ok to skipped that)
     */
    onChangeHandler(formElements);
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const setLatexElement = () => {
    setTimeout(() => {
      setLatexWrapper(latexRefs.current);
    }, 300);
  };

  useEffect(() => {
    return () => {
      debouncedFormEl.cancel();
    };
  }, [debouncedFormEl]);

  const FIELDTYPE = {
    TEXT: 'TEXT',
    NUMERICAL: 'NUMERICAL',
    RADIO_BUTTON: 'RADIO_BUTTON',
    DROPDOWN: 'DROPDOWN',
    DATE: 'DATE'
  };

  const [toggleExpand, setToggleExpand] = useState(false);
  const [expandTableData, setExpandTableData] = useState<Record<
    string,
    any
  > | null>(null);

  const toggleExpandTable = (
    action: 'OPEN' | 'CLOSE',
    data?: any,
    properties?: any,
    columnId?: string
  ) => {
    switch (action) {
      case 'OPEN':
        const expandTableColumnSettings = resetColumnSetting(
          properties.headers
        );
        setExpandTableData(() => ({
          ...data,
          displayedContent: {
            ...data.displayedContent,
            row: {
              ...data.displayedContent.row,
              end:
                data.totalRowAdded.length > 0
                  ? data.totalRowAdded[data.totalRowAdded.length - 1]
                  : data.displayedContent.row.end
            }
          },
          columnId,
          isAllowedToAddRow: properties.isAllowedToAddRow,
          format: properties.format,
          headers: generateTableHeader(
            data.data[0],
            expandTableColumnSettings,
            data.freeze
          )
        }));
        setToggleExpand(true);
        // need to find the best way to know when the table is mount on DOM then do the hideRow handler
        // instead of using setTimeout
        setTimeout(() => {
          data?.indexHideRowArr.forEach((rowIdx: number) => {
            (expandTableRef.current as any)?.hideRow(rowIdx);
          });
          data?.indexHideColArr.forEach((rowIdx: number) => {
            (expandTableRef.current as any)?.hideColumn(rowIdx);
          });
          (expandTableRef.current as any).setDefinedNames(
            (vlcRef.current as any)?.getVariableList()
          );
          (expandTableRef.current as any).setData(data.data);
          if (
            data.indexHideRowArr[data.indexHideRowArr.length - 1] ===
            data.displayedContent.row.end
          ) {
            const styles: any = {};
            for (
              let i = data.displayedContent.column.start;
              i <= data.displayedContent.column.end;
              i++
            ) {
              const cell = getCellName(i, data.displayedContent.row.end + 1);
              styles[cell] = 'border-top: 1px solid black';
            }
            (expandTableRef.current as any).setStyle(styles);
          }
        }, 10);
        break;
      case 'CLOSE':
        setExpandTableData(null);
        setToggleExpand(false);

        tableComponentRef.current[data?.columnId]?.updateTableData(data);
    }
  };

  const newInsertRowHandler = (rowData: any, componentId: string) => {
    const inputField = formElements;
    // 1. find component idx based component id
    const componentIdx = inputField[selectedPage].components.findIndex(
      el => el.columnId === componentId
    );
    // 2. find newly added row then remove unnecessary column on newly added row
    const newlyAddedRowIdx = findLastRow(rowData);
    const newlyAddedRow = rowData[newlyAddedRowIdx].filter(
      (col: any) => col.isAdded === true
    );
    // 3. store new row data to table
    newlyAddedRow.forEach((col: any, idx: number) => {
      const { alignment, value, type, isAdded } = col;
      inputField[selectedPage].components[componentIdx].properties.cells.push({
        /**
         * @params colIdx Number
         * @params rowIdx Number
         * @params value String
         * @params valueFormula String
         * @params showToLogger Boolean
         * @params readOnly Boolean
         * @params isAdded Boolean
         * @params isHeader Boolean
         */
        colIdx: idx,
        rowIdx: newlyAddedRowIdx,
        value,
        valueFormula: value,
        showToLogger: true,
        readOnly: type === 'readonly' ? true : false,
        isAdded,
        isHeader: false,
        alignment
      });
    });

    onChangeHandler(inputField);
  };

  const newDeleteRowHandler = (componentId: string, rowNumber: number) => {
    const inputField = formElements;
    // 1. find component idx based component id
    const componentIdx = inputField[selectedPage].components.findIndex(
      el => el.columnId === componentId
    );
    // 2. remove cells item
    const data = inputField[selectedPage].components[
      componentIdx
    ].properties.cells.filter(
      (el: Record<string, unknown>) => el.rowIdx !== rowNumber
    );

    inputField[selectedPage].components[componentIdx].properties.cells = data;

    onChangeHandler(inputField);
  };

  if (!data) return null;

  return (
    <>
      <FormWrapper>
        {formElements[selectedPage]?.components.map(
          (item: any, idx: number) => {
            const { columnId, type, properties, value } = item;
            switch (type) {
              case 'TEXT':
                return <TextLabel key={columnId} properties={properties} />;
              case 'NUMERICAL_INPUT':
                return (
                  <InputWrapper key={columnId}>
                    <DescriptionBox contentEditable={false}>
                      <Typography variant="body3" component={'span'}>
                        {properties.description
                          ? parser(properties.description)
                          : properties.description}
                      </Typography>
                    </DescriptionBox>

                    <NotationBox>
                      <Typography variant="body3">
                        {properties.notation
                          ? parser(properties.notation)
                          : properties.notation}
                      </Typography>
                    </NotationBox>

                    <InputBox>
                      <Tooltip
                        title={
                          properties.tooltip === null ? '' : properties.tooltip
                        }
                      >
                        {properties.format?.value?.percentage ? (
                          <Controller
                            name={`formElements.${selectedPage}.components.${idx}.value`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormattedInput.Percentage
                                value={value ? value : ''}
                                // onBlur={(e: React.SyntheticEvent) => { }}
                                id="userInputNumber"
                                placeholder="Input value here"
                                properties={properties}
                                onChange={(values: any) =>
                                  parseFloat(values.value) === 0
                                    ? onChange(values.value)
                                    : handleChangePercentage(values, onChange)
                                }
                                onToolbarMenuSelected={(
                                  e: any,
                                  type: any,
                                  data: any
                                ) =>
                                  onChangeDecimal({
                                    selectedPage,
                                    columnId,
                                    decimal: data
                                  })
                                }
                                sessionName={`input_numerical__id_${columnId}_decimal_number`}
                              />
                            )}
                            rules={{
                              required: `${properties.description} is required`
                            }}
                          />
                        ) : (
                          <Controller
                            name={`formElements.${selectedPage}.components.${idx}.value`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <FormattedInput.Number
                                value={value ? value : ''}
                                // onBlur={(e: React.SyntheticEvent) => {}}
                                id="userInputNumber"
                                placeholder="Input value here"
                                properties={properties}
                                onChange={(values: any) => {
                                  onChange(values.value);
                                }}
                                onToolbarMenuSelected={(
                                  e: any,
                                  type: any,
                                  data: any
                                ) =>
                                  onChangeDecimal({
                                    selectedPage,
                                    columnId,
                                    decimal: data
                                  })
                                }
                                sessionName={`input_numerical__id_${columnId}_decimal_number`}
                              />
                            )}
                            rules={{
                              required: `${properties.description} is required`
                            }}
                          />
                        )}
                      </Tooltip>
                    </InputBox>

                    <UnitBox>
                      <Typography variant="body3" component={'span'}>
                        {properties.unit
                          ? parser(properties.unit)
                          : properties.unit}
                      </Typography>
                    </UnitBox>
                  </InputWrapper>
                );
              case 'FORMULA':
                return (
                  /**
                   * TODO:
                   * 1. render with round decimal places
                   * 2. count Formula
                   * 3. render Latex component
                   */
                  <div key={columnId}>
                    <InputWrapper>
                      <DescriptionBox contentEditable={false}>
                        <Typography variant="body3" component={'span'}>
                          {properties?.description
                            ? parser(properties?.description)
                            : properties?.description}
                        </Typography>
                      </DescriptionBox>

                      <NotationBox>
                        <Typography variant="body3">
                          {properties?.notation
                            ? parser(properties?.notation)
                            : properties?.notation}
                        </Typography>
                      </NotationBox>

                      <InputBox>
                        <Tooltip
                          title={
                            properties?.tooltip === null
                              ? ''
                              : properties?.tooltip
                          }
                        >
                          {isNumeric(toPlainNumeric(value)) ||
                          isNegative(value) ? (
                            <FormattedInput.Formula
                              register={register}
                              id="userFormula"
                              placeholder={'Waiting for input..'}
                              properties={properties}
                              onToolbarMenuSelected={(
                                e: any,
                                type: any,
                                data: any
                              ) =>
                                onChangeDecimal({
                                  selectedPage,
                                  columnId,
                                  decimal: data
                                })
                              }
                              value={value}
                            />
                          ) : (
                            <FormulaTextArea
                              id="formula-text-area"
                              register={register}
                              value={value}
                              placeholder={'Waiting for input..'}
                              size="small"
                              fullWidth
                              sx={{
                                input: { textAlign: 'right' }
                              }}
                            />
                          )}
                        </Tooltip>
                      </InputBox>

                      <UnitBox>
                        <Typography variant="body3" component={'span'}>
                          {properties?.unit
                            ? parser(properties?.unit)
                            : properties?.unit}
                        </Typography>
                      </UnitBox>
                    </InputWrapper>
                    {properties.formula && (
                      <LatexFormula
                        columnId={columnId}
                        properties={properties}
                        theme={theme}
                        handleExpandLatex={handleExpandLatex}
                        expandLatex={expandLatex}
                        index={idx}
                        loggerValues={
                          inputFile?.fields.length > 0
                            ? inputFile.fields
                            : formElements
                        }
                        isCompleted={formulaStatus[columnId]}
                        multiLine={checkMultiLine(latexWrapper, columnId)}
                        addLatexRef={addLatexRef}
                        isRefactor
                      />
                    )}
                    <div>
                      {properties.reference && (
                        <ReferenceWrapper>
                          <div className="reference">
                            <Text
                              variant="body3"
                              color={theme.customText.disable}
                            >
                              Reference
                            </Text>
                          </div>
                          <div className="referenceValue">
                            <Text variant="body3" color={theme.customText.high}>
                              {properties.reference}
                            </Text>
                          </div>
                        </ReferenceWrapper>
                      )}
                    </div>
                  </div>
                );
              case 'TEXT_INPUT':
                return (
                  <TextInput
                    key={columnId}
                    name={`formElements.${selectedPage}.components.${idx}.value`}
                    control={control}
                    defaultValue={''}
                    properties={properties}
                  />
                );
              case 'DROPDOWN_INPUT':
                return (
                  <DropdownInput
                    key={columnId}
                    name={`formElements.${selectedPage}.components.${idx}.value`}
                    control={control}
                    defaultValue={''}
                    properties={properties}
                  />
                );
              case 'DATE_INPUT':
                return (
                  <DateInput
                    key={columnId}
                    name={`formElements.${selectedPage}.components.${idx}.value`}
                    control={control}
                    defaultValue={''}
                    properties={properties}
                  />
                );
              case 'RADIO_BUTTON_INPUT':
                return (
                  <RadioButtonInput
                    key={columnId}
                    name={`formElements.${selectedPage}.components.${idx}.value`}
                    control={control}
                    defaultValue={''}
                    properties={properties}
                  />
                );
              case 'IMAGE':
                return (
                  <ImageComponent key={columnId} properties={properties} />
                );
              case 'LINK':
                return (
                  <LinkComponent
                    properties={properties}
                    handleOpenNewTab={handleOpenNewTab}
                  />
                );
              case 'DIVIDER':
                return <DividerComponent key={columnId} />;
              case 'TABLE':
                return (
                  <Table
                    ref={tableComponentRef}
                    tableRef={tableRef}
                    key={columnId}
                    properties={properties}
                    onChange={(
                      worksheet: any,
                      cell: any,
                      col: any,
                      row: any,
                      newValue: any
                    ) =>
                      handleTableChange(
                        idx,
                        worksheet,
                        cell,
                        col,
                        row,
                        newValue
                      )
                    }
                    onInsertRow={newInsertRowHandler}
                    onDeleteRow={newDeleteRowHandler}
                    columnId={columnId}
                    onToggleExpand={toggleExpandTable}
                  />
                );
              case 'FIELD':
                switch (properties.fieldType) {
                  case FIELDTYPE.TEXT:
                    return (
                      <TextField
                        key={columnId}
                        name={`formElements.${selectedPage}.components.${idx}.value`}
                        control={control}
                        defaultValue={''}
                        properties={properties}
                        multiLine={true}
                        resize={'none'}
                      />
                    );
                  case FIELDTYPE.NUMERICAL:
                    return (
                      <FieldWrapper key={columnId}>
                        <DescriptionBoxField contentEditable={false}>
                          <Typography variant="body3" component={'span'}>
                            {properties.description
                              ? parser(properties.description)
                              : properties.description}
                          </Typography>
                        </DescriptionBoxField>

                        <InputBoxField>
                          <Tooltip
                            title={
                              properties.tooltip === null
                                ? ''
                                : properties.tooltip
                            }
                          >
                            <Controller
                              name={`formElements.${selectedPage}.components.${idx}.value`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <FormattedInput.NumberField
                                  value={value ? value : ''}
                                  // onBlur={(e: React.SyntheticEvent) => {}}
                                  id="userInputNumber"
                                  placeholder="Input value here"
                                  properties={properties}
                                  onChange={(values: any) => {
                                    onChange(values.value);
                                  }}
                                  onToolbarMenuSelected={(
                                    e: any,
                                    type: any,
                                    data: any
                                  ) =>
                                    onChangeDecimal({
                                      selectedPage,
                                      columnId,
                                      decimal: data
                                    })
                                  }
                                  sessionName={`input_numerical__page_${selectedPage}__row_${idx}__decimal_number`}
                                />
                              )}
                              rules={{
                                required: `${properties.description} is required`
                              }}
                            />
                          </Tooltip>
                        </InputBoxField>
                      </FieldWrapper>
                    );
                  case FIELDTYPE.RADIO_BUTTON:
                    return (
                      <RadioField
                        key={columnId}
                        name={`formElements.${selectedPage}.components.${idx}.value`}
                        control={control}
                        defaultValue={''}
                        properties={properties}
                      />
                    );
                  case FIELDTYPE.DROPDOWN:
                    return (
                      <DropdownField
                        key={columnId}
                        name={`formElements.${selectedPage}.components.${idx}.value`}
                        control={control}
                        defaultValue={''}
                        properties={properties}
                      />
                    );
                  case FIELDTYPE.DATE:
                    return (
                      <DateField
                        key={columnId}
                        name={`formElements.${selectedPage}.components.${idx}.value`}
                        control={control}
                        defaultValue={''}
                        properties={properties}
                      />
                    );
                }
                break;
              default:
                return null;
            }
          }
        )}
      </FormWrapper>

      <TableExpandModal
        isOpen={toggleExpand}
        expandTableContainerRef={expandTableContainerRef}
        expandTableRef={expandTableRef}
        dataSource={expandTableData}
        onCloseModal={toggleExpandTable}
      />

      <VariableSheet ref={vlcRef} variableList={[]} name="formula" />

      <HiddenTable
        activePage={selectedPage}
        dataSources={formElements}
        tableRef={tableRef}
      />
    </>
  );
};

export default LoggerForm;
