import * as React from "react";

const SvgDollar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.392 20.5v-1.354a8.282 8.282 0 0 1-1.538-.276 6.22 6.22 0 0 1-1.378-.62 6.573 6.573 0 0 1-1.147-.918l1.056-1.584c.091.046.137.13.137.253a.583.583 0 0 0 .115.298c.245.245.505.46.78.643.291.183.597.329.919.436.336.107.68.184 1.033.23v-4.752c-.796-.276-1.462-.582-1.997-.918-.536-.352-.942-.75-1.217-1.194-.275-.444-.413-.957-.413-1.538 0-.582.16-1.102.482-1.561a3.74 3.74 0 0 1 1.308-1.17 5.209 5.209 0 0 1 1.86-.598V4.5h1.538c.03.015.046.03.046.046v.046a.393.393 0 0 1-.07.183.763.763 0 0 0-.091.299v.78c.474.046.918.146 1.331.299.414.153.796.352 1.148.597.367.245.689.535.964.872l-1.056 1.331c-.107-.015-.168-.084-.183-.206a.399.399 0 0 0-.138-.276 3.355 3.355 0 0 0-.413-.39 1.707 1.707 0 0 0-.482-.298 4.602 4.602 0 0 0-.551-.207 2.445 2.445 0 0 0-.597-.16v4.154c.888.291 1.607.605 2.158.941.566.322.987.704 1.262 1.148.276.429.414.972.414 1.63 0 .566-.161 1.125-.483 1.676-.306.55-.75 1.025-1.331 1.423-.566.383-1.247.627-2.043.735V20.5h-1.423Zm1.446-2.938c.444-.077.819-.222 1.125-.437.321-.229.566-.497.734-.803a1.93 1.93 0 0 0 .253-.941c0-.337-.077-.627-.23-.872-.137-.245-.367-.467-.688-.666-.306-.2-.704-.375-1.194-.528v4.247Zm-1.47-6.52v-3.65a2.646 2.646 0 0 0-1.032.322 1.81 1.81 0 0 0-.643.574c-.153.23-.23.482-.23.757 0 .276.07.536.207.78.153.23.367.452.643.666.29.2.643.383 1.056.551Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDollar;
