import { Box } from '@mui/material';
import Button from '../../components/atoms/Button';
import React from 'react';
import UseWindowDimensions from '../../utils/getWindows';
import images from '../../assets';
import { TEXT_REMOVE, TEXT_UPLOAD } from '../../utils/constant';

export default function AvatarAccount(props: any) {
  const {
    inputFile,
    handleChangeImage,
    profileImage,
    onImageClick,
    handleDeleteImage,
    handleSameImage,
    isMobile
  } = props;

  const { width } = UseWindowDimensions();
  return (
    <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      <div style={{ alignSelf: 'center' }}>
        <input
          type="file"
          hidden
          ref={inputFile}
          onChange={e => handleChangeImage(e)}
          onClick={e => handleSameImage(e)}
        />
        <img
          style={{
            height: isMobile ? 160 : 80,
            width: isMobile ? 160 : 80,
            objectFit: 'scale-down',
            borderRadius: 50
          }}
          src={profileImage ? profileImage : images.avatar}
          alt="avatar"
        />
      </div>
      <div style={{ alignSelf: 'center', flexDirection: 'row', display: 'flex' }}>
        <Button
          onClick={onImageClick}
          color="primary"
          variant="contained"
          size="small"
          style={{ marginRight: 8, marginLeft: isMobile ? 0 : 16, padding: isMobile ? '12px 16px' : '7px 19px', width: isMobile ? width / 2 - 20 : undefined, textTransform: 'none' }}
        >
          {TEXT_UPLOAD}
        </Button>
        <Button
          onClick={handleDeleteImage}
          color="error"
          variant="contained"
          size="small"
          style={{ padding: isMobile ? '12px 16px' : '7px 19px', width: isMobile ? width / 2 - 20 : undefined, textTransform: 'none' }}
        >
          {TEXT_REMOVE}
        </Button>
      </div>
    </Box>
  );
}
