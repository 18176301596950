type MenuType = 'normal' | 'divider' | 'radio' | 'extend';
type ContentType = {
    name: string
    label: string;
    type: MenuType;
    dataTestid: string;
    content?: Array<ContentType>
};
interface MenuItemsI {
    dataTestid: string;
    name: string;
    label: string;
    content: Array<ContentType>; //added functionality for nested menu
}

export const MenuItems: MenuItemsI[] = [
    {
        name: 'file',
        label: 'File',
        dataTestid: 'menuInputFile',
        content: [
            {
                name: 'loadInputFile',
                label: 'Load',
                type: "normal",
                dataTestid: 'subMenuLoad'
            },
            {
                label: 'Save',
                name: 'saveInputFile',
                type: 'normal',
                dataTestid: 'subMenuSave'
            },
            {
                label: 'Save As',
                name: 'SaveAsInputFile',
                type: 'normal',
                dataTestid: 'subMenuSaveAs'
            },
            {
                label: 'Exit to Input File Manager',
                name: 'exitToInputFileManager',
                type: 'normal',
                dataTestid: 'subMenuExit'
            },
        ],
    },
    {
        name: 'edit',
        label: 'Edit',
        dataTestid: 'menuEdit',
        content: [
            {
                type: 'normal',
                label: 'Clear All Inputs',
                name: 'clearAllInputFile',
                dataTestid: 'subMenuClearAllInputs'
            },
            {
                label: 'Clear Inputs at Page',
                name: 'clearInputFileAtPage',
                type: 'normal',
                dataTestid: 'subMenuClearInputsAtPage'
            },
            /**
             * Example top bar menu with nested items
             */
            // {
            //     name: 'publish',
            //     label: 'Publish',
            //     type: 'extend',
            //     dataTestid: 'subMenuClearInputsAtPage',
            //     content: [
            //       {
            //         name: 'publishEnterprise',
            //         label: 'Publish to Enterprise',
            //         type: 'normal',
            //         dataTestid: 'subMenuClearInputsAtPage' 
            //       },
            //       {
            //         name: 'publishCommunity',
            //         label: 'Publish to Community',
            //         type: 'normal',
            //         dataTestid: 'subMenuClearInputsAtPage' 
            //       },
            //     ],
            // },
        ],
    },
];