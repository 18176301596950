import { WorkpagesProps } from "../../print.types";
import { WorkpagesContainer } from "./styles";

export default function Workpages({ pageContent, pageHeader }: WorkpagesProps) {
    return (
        <WorkpagesContainer>
            {!!pageHeader && pageHeader}
            {!!pageContent && pageContent}
        </WorkpagesContainer>
    );
}