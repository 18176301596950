import React, { Fragment } from 'react';

import CalculationsMenu from '../../molecules/calculationsMenu';
import { ReduxState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import { getIsConsents, getUserProfile } from '../../../utils/storage';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';
import AceServices from '../../../service/AceService';
import { APIResponse } from '../../../utils/interfaces';
import { initTreeState } from '../../../store/appState/sidebar/action';
import { resetChatHistories } from '../../../store/actions/aceActions';

// These props will be injected into the wrapped component
interface ConnectedProps {
  handleToastSetupActionPopUp: (type: string, label: string) => void;
  handleModalPopup: (type: string, payload?: any) => void;
  handleInitTreeState: (type: string, pathname?: string) => void;
  handleResetChatHistories: () => void;
}

export const aceNav = <BaseProps extends ConnectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: ReduxState) => ({
    login: state.login,
    users: state.users,
    sidebar: state.sidebar,
    aceReducer: state.aceReducer
  });

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): ConnectedProps => {
    return {
      handleToastSetupActionPopUp: (type: string, label: string) =>
        dispatch(toastSetupActionPopUp(type, label)),
      handleModalPopup: (type: string, payload?: any) =>
        dispatch(formModalSetupActionPopUp(type, payload)),
      handleInitTreeState: (type: string, pathname?: string) =>
        dispatch(initTreeState(type, pathname)),
      handleResetChatHistories: () => dispatch(resetChatHistories()),
    };
  };

  type HocProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
      // extend ConnectedHoc with new loading state props
      // no need to access to logger.isloading to trigger page loading
      loading?: boolean;
      // state active item need to be injected because the modal dialog component need it
      activeItem?: any;
    };

  class Hoc extends React.Component<HocProps> {
    // Enhance component name for debugging and React-Dev-Tools
    static displayName = `aceNav(${BaseComponent.name})`;
    // reference to original wrapped component
    static readonly WrappedComponent = BaseComponent;

    state = {
      isMinimize: false,
      isShowMobileSidebar: false,
      isShowMobileInfoPanel: false,
      isConsent: false,
      policyId: '',
    };


    componentDidMount = () => {
      const isConsentsStatus: any = getIsConsents();
      const isConsent = isConsentsStatus.isConsent;
      this.setState({
        isConsent: isConsent,
        policyId: isConsentsStatus.policyId,
      });
    };


    userRole = getUserProfile();

    isComposer = /COMPOSER/.test(String(this.userRole));
    isRekava = /REKAVA/.test(String(this.userRole));

    handleMinimizeSidebar = () => {
      this.setState({
        isMinimize: !this.state.isMinimize
      });
    };

    handleVoteAnswer = async (id: string, upvote: boolean, downvote: boolean) => {
      return new Promise((resolved, rejected) => {
        const service = new AceServices();
        service
          .voteAnswer(id, upvote, downvote)
          .then((res: APIResponse<null>) => {
            resolved(res.message);
          }).catch((error: any) => {
            rejected(error);
          });
      });
    };

    handleShowMobileSidebar = () => {
      this.setState({
        isShowMobileSidebar: !this.state.isShowMobileSidebar
      });
    };

    handleShowMobileInfoPanel = () => {
      this.setState({
        isShowMobileInfoPanel: !this.state.isShowMobileInfoPanel
      });
    };

    render() {
      const {
        login,
        users,
        sidebar,
        aceReducer,
        handleModalPopup,
        handleToastSetupActionPopUp,
        handleInitTreeState,
        ...restProps
      } = this.props;

      const {
        isMinimize,
        isShowMobileSidebar,
        isShowMobileInfoPanel,
        isConsent,
        policyId
      } = this.state;

      return (
        <Fragment>
          <CalculationsMenu
            styleViewEnabled={true}
            handleSelectStyle={() => undefined}
            styleView={undefined}
            handleMinimizeSidebar={this.handleMinimizeSidebar}
            handleModal={handleModalPopup}
            handleShowMobileSidebar={this.handleShowMobileSidebar}
            handleShowMobileInfoPanel={this.handleShowMobileInfoPanel}
            {...restProps}
          />
          <BaseComponent
            login={login}
            users={users}
            sidebar={sidebar}
            isMinimize={isMinimize}
            handleModal={handleModalPopup}
            handleToast={handleToastSetupActionPopUp}
            handleVoteAnswer={this.handleVoteAnswer}
            isShowMobileSidebar={isShowMobileSidebar}
            handleShowMobileSidebar={this.handleShowMobileSidebar}
            handleTreeState={handleInitTreeState}
            isShowMobileInfoPanel={isShowMobileInfoPanel}
            handleShowMobileInfoPanel={this.handleShowMobileInfoPanel}
            isConsent={isConsent}
            policyId={policyId}
            aceReducer={aceReducer}
            {...(restProps as BaseProps)}
          />
        </Fragment>
      );
    }
  }

  const ConnectedHoc = connect(mapStateToProps, mapDispatchToProps)(Hoc);

  return ConnectedHoc;
};
