import axios from 'axios';
import GenericService from '../service/generic/generic';

export const uploadFile = async (file: File | null, folderId: string) => {
  if (!file) return null;
  const service = new GenericService('fs/upload/file');

  const payload = new FormData();
  payload.append('file', file);
  payload.append('folderId', folderId);

  const res = await service.post(payload);
  return res;
};

export const downloadFile = (uri: string, name: string) => {
  axios({
    url: uri,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const getExtension = (fileName: string) => {
  const splitName = fileName.split('.');
  return splitName[splitName.length - 1];
};

export const uploadSpreadSheet = async (
  file: File | null,
  folderId: string
) => {
  if (!file) return null;
  const service = new GenericService('file/spreadsheet');

  const payload = new FormData();
  payload.append('file', file);
  payload.append('id', folderId);

  const res = await service.post(payload);
  return res;
};

export const downloadPdf = (url: any, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  const blobF: any = blob;
  blobF.name = fileName;
  return blobF as File;
};

export const createFileFromUrl = async (url: string) => {
  const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: 'application/pdf'
    };
    const file = new File([data], "test.jpg", metadata);
    return file;
};