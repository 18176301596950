import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  FormHelperText,
  Select
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ReduxState } from '../../../../store';
import { showHideModalAction } from '../../../../store/appState/global/globalActions';
import { yupResolver } from '@hookform/resolvers/yup';
import Explorer from '../modalExplorer/Explorer';
import {
  addFolderSchema,
  renameSchema,
  newCalSchema,
  renameFolderSchema,
  makeCopyCalSchema,
  saveAsCalSchema,
  renameInputFileSchema
} from '../../../../utils/validation';
import {
  ContainerModalStyled,
  ContainerTitleStyled,
  ContainerButtonStyled,
  ContainerFormStyled,
  DeleteInfoText,
  ContainerButtonStyled2,
  ContainerExplorerStyled
} from './elements';
import Loading from '../../../atoms/loading';
import StyledButton from '../../../atoms/Button';

interface PropsModalI {
  openModal: boolean;
  submitModal: (_?: any) => any;
  itemName?: string;
  itemDescription?: string;
}

interface FormI {
  textField: string;
}

interface FormNewCalI {
  calculationName: string;
  description: string;
  name: string;
  principal: number;
}

interface SaveAsCalI {
  calculationName: string;
  folderId: string;
  folderName: string;
}

export default function ModalAction({
  openModal,
  submitModal,
  itemName,
  itemDescription
}: PropsModalI) {
  const {
    handleSubmit: handleSubmit1,
    watch: watch1,
    control: control1,
    formState: { errors: errors1 },
    setError
  } = useForm<FormI>({
    resolver: yupResolver(addFolderSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit2,
    watch: watch2,
    control: control2,
    formState: { errors: errors2 },
    setError: setError2
  } = useForm<FormI>({
    resolver: yupResolver(renameSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit3,
    watch: watch3,
    control: control3,
    formState: { errors: errors3 },
    setError: setError3
  } = useForm<FormNewCalI>({
    resolver: yupResolver(newCalSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit4,
    watch: watch4,
    control: control4,
    formState: { errors: errors4 },
    setError: setError4
  } = useForm<FormI>({
    resolver: yupResolver(renameFolderSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit5,
    watch: watch5,
    control: control5,
    formState: { errors: errors5 },
    setError: setError5
  } = useForm<FormI>({
    resolver: yupResolver(makeCopyCalSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit6,
    watch: watch6,
    control: control6,
    formState: { errors: errors6 },
    setError: setError6,
    setValue: setValue6
  } = useForm<SaveAsCalI>({
    resolver: yupResolver(saveAsCalSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit7,
    watch: watch7,
    control: control7,
    formState: { errors: errors7, isSubmitting: isSubmitting7 },
    setError: setError7
  } = useForm<FormI>({
    resolver: yupResolver(renameInputFileSchema),
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit: handleSubmit8,
    watch: watch8,
    control: control8,
    formState: { errors: errors8, isSubmitting: isSubmitting8 },
    setError: setError8
  } = useForm<FormI>({
    reValidateMode: 'onChange'
  });

  const { typeAction } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  const { calInputFiles } = useSelector(
    (state: ReduxState) => state.calInfo,
    shallowEqual
  );

  const { userDetails }: any = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );

  const [openExplorer, setOpenExplorer] = useState(false);

  const dispatch = useDispatch();

  const submitForm = async (e: any) => {
    if (typeAction === 'delCal' || typeAction === 'delFolder') {
      e.preventDefault();
      await submitModal();
    } else if (typeAction === 'delInputFile') {
      await submitModal(e);
    } else if (typeAction === 'exitCal') {
      await submitModal(e);
    } else if (typeAction === 'newCal' || typeAction === 'editCalInfo') {
      const errorMessage = await submitModal({
        title: watch3('calculationName'),
        description: watch3('description') || ''
      });
      if (errorMessage) {
        setError3('calculationName', { type: 'manual', message: errorMessage });
      }
    } else if (typeAction === 'saveAsCal') {
      const errorMessage = await submitModal({
        title: watch6('calculationName'),
        location: watch6('folderId')
      });
      if (errorMessage) {
        setError6('calculationName', { type: 'manual', message: errorMessage });
      }
    } else {
      const value =
        typeAction === 'addFolder'
          ? watch1('textField')
          : typeAction === 'renCal'
          ? watch2('textField')
          : typeAction === 'renFolder'
          ? watch4('textField')
          : typeAction === 'renInputFile'
          ? watch7('textField')
          : watch5('textField');
      const errorMessage = await submitModal(value);
      if (errorMessage) {
        if (typeAction === 'addFolder') {
          setError('textField', { type: 'manual', message: errorMessage });
        }
        if (typeAction === 'renCal') {
          setError2('textField', { type: 'manual', message: errorMessage });
        }
        if (typeAction === 'renFolder') {
          setError4('textField', { type: 'manual', message: errorMessage });
        }
        if (typeAction === 'makeCopyCal') {
          setError5('textField', { type: 'manual', message: errorMessage });
        }
        if (typeAction === 'renInputFile') {
          setError7('textField', { type: 'manual', message: errorMessage });
        }
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(showHideModalAction(false, ''));
  };

  const handleOpenExplorer = () => {
    setOpenExplorer(true);
  };

  const handleCloseExplorer = () => {
    setOpenExplorer(false);
  };

  const onSubmitExplorer = async (e: any) => {
    setValue6('folderId', e.id);
    setValue6('folderName', e.name);
    setOpenExplorer(false);
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={handleCloseModal}
    >
      <ContainerModalStyled
        width={typeAction === 'exitCal' ? '25.87rem' : '21.87rem'}
        height={
          typeAction === 'newCal' || typeAction === 'editCalInfo'
            ? '18.87rem'
            : typeAction === 'saveAsCal'
            ? '12rem'
            : '10.44rem'
        }
      >
        <ContainerTitleStyled>
          {typeAction === 'addFolder' ? (
            <p>Add Folder</p>
          ) : typeAction === 'delFolder' ? (
            <p>Delete Folder</p>
          ) : typeAction === 'renFolder' ? (
            <p>Rename Folder</p>
          ) : typeAction === 'delCal' ? (
            <p>Delete Calculation</p>
          ) : typeAction === 'renCal' ? (
            <p>Rename Calculation</p>
          ) : typeAction === 'exitCal' ? (
            <p>Save Document</p>
          ) : typeAction === 'newCal' ? (
            <p>Create Calculation</p>
          ) : typeAction === 'editCalInfo' ? (
            <p>Edit Calculation Info</p>
          ) : typeAction === 'makeCopyCal' ? (
            <p>Calculation Name</p>
          ) : typeAction === 'saveAsCal' ? (
            <p>Save To</p>
          ) : typeAction === 'createNewInputFile' ? (
            <p>New input File</p>
          ) : typeAction === 'loadInputFile' ? (
            <p>Load</p>
          ) : typeAction === 'saveInputFile' ? (
            <p>Saving Input File...</p>
          ) : typeAction === 'saveAsInputFile' ? (
            <p>Save As</p>
          ) : typeAction === 'shareInputFile' ? (
            <p>Share</p>
          ) : typeAction === 'renInputFile' ? (
            <p>Rename Input File</p>
          ) : typeAction === 'delInputFile' ? (
            <p>Delete Input File</p>
          ) : null}
          <CloseIcon onClick={handleCloseModal} />
        </ContainerTitleStyled>

        {typeAction === 'addFolder' ? (
          <ContainerFormStyled onSubmit={handleSubmit1(submitForm)}>
            <Controller
              defaultValue=""
              name="textField"
              control={control1}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                    size="small"
                    error={!!errors1.textField}
                    helperText={
                      !!errors1.textField && errors1.textField?.message
                    }
                  />
                );
              }}
            />
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                CREATE
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'renCal' ? (
          <ContainerFormStyled onSubmit={handleSubmit2(submitForm)}>
            <Controller
              defaultValue={itemName}
              name="textField"
              control={control2}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                    size="small"
                    error={!!errors2.textField}
                    helperText={
                      !!errors2.textField && errors2.textField?.message
                    }
                  />
                );
              }}
            />
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                SUBMIT
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'delCal' ? (
          <ContainerFormStyled onSubmit={submitForm}>
            {calInputFiles > 0 ? (
              <DeleteInfoText>
                {itemName} have data in the input file. Are you sure to delete
                this file?
              </DeleteInfoText>
            ) : (
              <DeleteInfoText>
                {itemName} will be deleted. Are you sure to delete this file?
              </DeleteInfoText>
            )}
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                DELETE
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'delFolder' ? (
          <ContainerFormStyled onSubmit={submitForm}>
            <DeleteInfoText>
              This {itemName} will be deleted. Are you sure to delete this
              folder?
            </DeleteInfoText>
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                DELETE
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'delInputFile' ? (
          <ContainerFormStyled onSubmit={handleSubmit8(submitForm)}>
            <DeleteInfoText>
              This {itemName} have data in the reports. Are you sure you want to
              delete this file?
            </DeleteInfoText>
            <ContainerButtonStyled>
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                data-testid="btnCancel"
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting8}
                data-testid="btnDeleteFile"
              >
                {isSubmitting8 ? <Loading /> : 'DELETE'}
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'renFolder' ? (
          <ContainerFormStyled onSubmit={handleSubmit4(submitForm)}>
            <Controller
              defaultValue={itemName}
              name="textField"
              control={control4}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                    size="small"
                    error={!!errors4.textField}
                    helperText={
                      !!errors4.textField && errors4.textField?.message
                    }
                  />
                );
              }}
            />
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                SAVE
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'exitCal' ? (
          <ContainerFormStyled>
            Save changes to {itemName} before closing? Your Changes will be lost
            if you don't save them
            <ContainerButtonStyled2>
              <Button
                variant="outlined"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </Button>
              <Button
                variant="outlined"
                className="cancel"
                onClick={() => {
                  submitForm('dontSave');
                }}
              >
                DON'T SAVE
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  submitForm('save');
                }}
              >
                SAVE
              </Button>
            </ContainerButtonStyled2>
          </ContainerFormStyled>
        ) : typeAction === 'newCal' || typeAction === 'editCalInfo' ? (
          <ContainerFormStyled onSubmit={handleSubmit3(submitForm)}>
            <Controller
              defaultValue={itemName}
              name="calculationName"
              control={control3}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    inputProps={{
                      maxLength: 20,
                      'data-testid': 'inputCalculationName'
                    }}
                    variant="outlined"
                    placeholder="Calculation Name"
                    size="small"
                    error={!!errors3.calculationName}
                    helperText={
                      !!errors3.calculationName &&
                      errors3.calculationName?.message
                    }
                  />
                );
              }}
            />
            <Controller
              defaultValue={itemDescription}
              name="description"
              control={control3}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    inputProps={{
                      maxLength: 140,
                      'data-testid': 'inputCalculationDescription'
                    }}
                    type="text"
                    minRows={3}
                    multiline={true}
                    variant="outlined"
                    placeholder="Description"
                    size="small"
                    error={!!errors3.description}
                    helperText={
                      !!errors3.description && errors3.description?.message
                    }
                  />
                );
              }}
            />
            <Controller
              defaultValue={userDetails}
              name="name"
              control={control3}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    disabled
                    type="text"
                    variant="outlined"
                    size="small"
                    error={!!errors3.name}
                    helperText={!!errors3.name && errors3.name?.message}
                  />
                );
              }}
            />
            <Controller
              defaultValue={0}
              name="principal"
              control={control3}
              render={({ field }) => {
                return (
                  <FormControl size="small">
                    <Select
                      {...field}
                      value={0}
                      disabled
                      displayEmpty
                      inputProps={{
                        'aria-label': 'Without label',
                        'data-testid': 'dropdownPrincipal'
                      }}
                    >
                      <MenuItem value={0}>
                        <em>Principal</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                    {!!errors3.principal && (
                      <FormHelperText>
                        {errors3.principal?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            <ContainerButtonStyled marginTop="1rem">
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={handleCloseModal}
                data-testid="btnCancel"
              >
                CANCEL
              </StyledButton>
              <Button variant="contained" type="submit" data-testid="btnCreate">
                {typeAction === 'newCal' ? 'CREATE' : 'SAVE'}
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'renInputFile' ? (
          <ContainerFormStyled onSubmit={handleSubmit7(submitForm)}>
            <Controller
              defaultValue={itemName}
              name="textField"
              control={control7}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    inputProps={{
                      maxLength: 20,
                      'data-testid': 'inputFileName'
                    }}
                    variant="outlined"
                    size="small"
                    error={!!errors7.textField}
                    helperText={
                      !!errors7.textField && errors7.textField?.message
                    }
                  />
                );
              }}
            />
            <ContainerButtonStyled>
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                data-testid="btnCancel"
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting7}
                data-testid="btnConfirm"
              >
                {isSubmitting7 ? <Loading /> : 'SUBMIT'}
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'makeCopyCal' ? (
          <ContainerFormStyled onSubmit={handleSubmit5(submitForm)}>
            <Controller
              defaultValue=""
              name="textField"
              control={control5}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    placeholder="Calculation Name"
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                    size="small"
                    error={!!errors5.textField}
                    helperText={
                      !!errors5.textField && errors5.textField?.message
                    }
                  />
                );
              }}
            />
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button variant="contained" type="submit">
                OK
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : typeAction === 'saveAsCal' ? (
          <ContainerFormStyled onSubmit={handleSubmit6(submitForm)}>
            <Controller
              defaultValue=""
              name="calculationName"
              control={control6}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    placeholder="Calculation Name"
                    inputProps={{ maxLength: 20 }}
                    variant="outlined"
                    size="small"
                    error={!!errors6.calculationName}
                    helperText={
                      !!errors6.calculationName &&
                      errors6.calculationName?.message
                    }
                  />
                );
              }}
            />
            <Controller
              defaultValue=""
              name="folderName"
              control={control6}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    onClick={handleOpenExplorer}
                    disabled
                    placeholder="Folder"
                    variant="outlined"
                    size="small"
                    error={!!errors6.folderName}
                    helperText={
                      !!errors6.folderName && errors6.folderName?.message
                    }
                  />
                );
              }}
            />
            {openExplorer && (
              <ContainerExplorerStyled>
                <div className="containerFolder">
                  <Explorer
                    handleClose={handleCloseExplorer}
                    type="myCalculation"
                    onSubmit={onSubmitExplorer}
                  />
                </div>
              </ContainerExplorerStyled>
            )}
            <ContainerButtonStyled>
              <Button variant="outlined" onClick={handleCloseModal}>
                CANCEL
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting7}
              >
                SAVE
              </Button>
            </ContainerButtonStyled>
          </ContainerFormStyled>
        ) : null}
      </ContainerModalStyled>
    </Modal>
  );
}
